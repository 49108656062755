"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _spauiUtils = require("@tencent/spaui-utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview slider坐标节点
 * @author kirkzheng
 */
var _default = function _default() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _props$showStepNode = props.showStepNode,
      showStepNode = _props$showStepNode === void 0 ? false : _props$showStepNode,
      _props$stepNodes = props.stepNodes,
      stepNodes = _props$stepNodes === void 0 ? [] : _props$stepNodes,
      _props$valueNodes = props.valueNodes,
      valueNodes = _props$valueNodes === void 0 ? [] : _props$valueNodes,
      min = props.min,
      max = props.max,
      setStepNodeLabel = props.setStepNodeLabel,
      _props$sliderWidth = props.sliderWidth,
      sliderWidth = _props$sliderWidth === void 0 ? 0 : _props$sliderWidth,
      _props$classNameMap = props.classNameMap,
      classNameMap = _props$classNameMap === void 0 ? {} : _props$classNameMap;
  var result = null; // 节点

  if (showStepNode && stepNodes.length > 0) {
    result = _react["default"].createElement("div", {
      className: classNameMap.SliderStepWrapClassName
    }, _react["default"].createElement("div", {
      className: classNameMap.SliderStepClassName,
      style: {
        width: sliderWidth
      }
    }, stepNodes.map(function (item) {
      return _react["default"].createElement("span", {
        className: classNameMap.SliderNodeClassName,
        key: item,
        style: {
          left: "".concat(item * sliderWidth, "px")
        }
      });
    })), _react["default"].createElement("div", {
      className: classNameMap.SliderMaskClassName,
      style: {
        width: sliderWidth
      }
    }, stepNodes.map(function (item, index) {
      var label = '';

      if (typeof valueNodes[index] !== 'undefined') {
        label = valueNodes[index];
      } else {
        label = item * (max - min) + min;
      } // 支持自定义文案


      if ((0, _spauiUtils.isFunction)(setStepNodeLabel)) {
        label = setStepNodeLabel(label, item, min, max);
      }

      return _react["default"].createElement("span", {
        className: classNameMap.SliderMaskNodeClassName,
        key: item,
        style: {
          left: "".concat(item * sliderWidth, "px")
        }
      }, label);
    })));
  }

  return result;
};

exports["default"] = _default;