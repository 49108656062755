"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.removeItemFromArray = exports.haveJQuery = exports.isEqualForObject = exports.removeFocusClassName = exports.getDataBySearchValue = exports.getDataByValue = exports.inDataIndex = exports.cloneArray = exports.itemIsObjectByValue = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

var looseIndexOf = _spauiUtils.utils.looseIndexOf; // 数组中的元素是否存在object

var itemIsObjectByValue = function itemIsObjectByValue() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var result = false;

  if ((0, _spauiUtils.isArray)(data)) {
    data.map(function (item) {
      if (item && _typeof(item) === 'object') {
        result = true;
      }

      return item;
    });
  }

  return result;
}; // 复制数组


exports.itemIsObjectByValue = itemIsObjectByValue;

var cloneArray = function cloneArray() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var result = [];

  if ((0, _spauiUtils.isArray)(data)) {
    data.map(function (item) {
      result.push(item);
      return item;
    });
  }

  return result;
}; // 在data中的索引


exports.cloneArray = cloneArray;

var inDataIndex = function inDataIndex() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var item = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var itemKey = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  var index = -1;

  if ((0, _spauiUtils.isArray)(data)) {
    index = looseIndexOf(data, item[itemKey] || '');

    if (index < 0) {
      data.map(function () {
        var dataItem = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        var itemIndex = arguments.length > 1 ? arguments[1] : undefined;

        // 如果dataItem是object的话，就对比其中一个字段的值
        if (_typeof(dataItem) === 'object') {
          if ((dataItem[itemKey] || typeof dataItem[itemKey] === 'number') && dataItem[itemKey] === item[itemKey]) {
            index = itemIndex;
          }
        } else if ((dataItem || typeof dataItem === 'number') && dataItem === item[itemKey]) {
          index = itemIndex;
        }

        return item;
      });
    }
  }

  return index;
}; // 根据value与data来获取原始data中已在value中的数据


exports.inDataIndex = inDataIndex;

var getDataByValue = function getDataByValue() {
  var config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _config$value = config.value,
      value = _config$value === void 0 ? [] : _config$value,
      _config$data = config.data,
      data = _config$data === void 0 ? [] : _config$data,
      valueKey = config.valueKey;
  var result = [];

  if (value.length > 0) {
    // 将value统一专程object并放置到数组中
    value.map(function (item) {
      var tempData = {};

      if (_typeof(item) === 'object') {
        result.push((0, _spauiUtils.objectAssign)(item));
      } else {
        // 统一下格式
        tempData[valueKey] = item;
        result.push(tempData);
      }

      return item;
    });

    if ((0, _spauiUtils.isArray)(data) && data.length > 0) {
      data.map(function () {
        var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        var index = -1;

        if (item[valueKey] || typeof item[valueKey] === 'number') {
          index = inDataIndex(value, item, valueKey);

          if (index > -1) {
            result[index] = (0, _spauiUtils.objectAssign)(item);
          }
        }

        return item;
      });
    }
  }

  return result;
}; // 根据搜索信息从原始data中筛选出数据


exports.getDataByValue = getDataByValue;

var getDataBySearchValue = function getDataBySearchValue() {
  var searchValue = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var convertPYs = config.convertPYs,
      _config$data2 = config.data,
      data = _config$data2 === void 0 ? [] : _config$data2,
      _config$searchFields = config.searchFields,
      searchFields = _config$searchFields === void 0 ? [] : _config$searchFields,
      _config$useDefaultSea = config.useDefaultSearchLogic,
      useDefaultSearchLogic = _config$useDefaultSea === void 0 ? true : _config$useDefaultSea,
      searchFilter = config.searchFilter;
  var result = [];
  var searchValuePinYin = '';
  var valueKeyPinYin = '';

  if (searchValue && useDefaultSearchLogic) {
    if ((0, _spauiUtils.isArray)(data)) {
      data.map(function () {
        var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        var matchSearchString = '';
        var inData = false;

        if ((0, _spauiUtils.isFunction)(searchFilter)) {
          inData = searchFilter(searchValue, item, config);
        } else {
          // 将所有筛选字段汇集成一个字符串
          searchFields.map(function (searchField) {
            matchSearchString += item[searchField] || '';
            return item;
          });

          if (matchSearchString) {
            // 先直接看下字符串是否包括
            if (matchSearchString.indexOf(searchValue) > -1) {
              inData = true;
            } else if ((0, _spauiUtils.isFunction)(convertPYs)) {
              // 看下拼音是否匹配
              searchValuePinYin = convertPYs(searchValue)[0];
              valueKeyPinYin = convertPYs(matchSearchString)[0];

              if (valueKeyPinYin.indexOf(searchValuePinYin) > -1) {
                inData = true;
              }
            }
          }
        }

        if (inData) {
          result.push(item);
        }

        return item;
      });
    }
  } else {
    result = data;
  }

  return result;
}; // 去除classname中的focus


exports.getDataBySearchValue = getDataBySearchValue;

var removeFocusClassName = function removeFocusClassName() {
  var className = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var classNameArr = className.split(' ') || [];
  var result = []; // 不是焦点classname才添加

  classNameArr.map(function (item) {
    if (item !== 'focus') {
      result.push(item);
    }

    return item;
  });
  return result.join(' ');
}; // object数据是相等的


exports.removeFocusClassName = removeFocusClassName;

var isEqualForObject = function isEqualForObject(fromData, toData) {
  var result = true;
  var key = '';

  if (fromData) {
    for (key in fromData) {
      if (fromData[key] !== toData[key]) {
        result = false;
      }
    }
  } else {
    result = false;
  }

  return result;
};

exports.isEqualForObject = isEqualForObject;
var haveJQuery = (typeof window === "undefined" ? "undefined" : _typeof(window)) === 'object' && typeof window.jQuery === 'function';
/**
 * 从数组中移除某项
 * @param {array} data 数组数据
 * @param {*} value 待移除的元素值
 * @param {function} findFunc findIndex 的查找函数
 */

exports.haveJQuery = haveJQuery;

var removeItemFromArray = function removeItemFromArray() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var value = arguments.length > 1 ? arguments[1] : undefined;
  var findFunc = arguments.length > 2 ? arguments[2] : undefined;
  var index = -1;

  if (!findFunc) {
    index = data.indexOf(value);
  } else {
    index = data.findIndex(findFunc);
  }

  if (index > -1) {
    data.splice(index, 1);
  }
};

exports.removeItemFromArray = removeItemFromArray;
var _default = {
  itemIsObjectByValue: itemIsObjectByValue,
  cloneArray: cloneArray,
  inDataIndex: inDataIndex,
  getDataByValue: getDataByValue,
  getDataBySearchValue: getDataBySearchValue,
  removeFocusClassName: removeFocusClassName,
  isEqualForObject: isEqualForObject,
  haveJQuery: haveJQuery,
  removeItemFromArray: removeItemFromArray
};
exports["default"] = _default;