"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultProps = exports.propTypes = exports.config = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件参数配置
 */

/**
 * Space 接口参数
 * @alias SpaceProps
 * @property {titleline} 默认支持参数
 * @property {blankline} className;style;id;data-*;accessKey;dir;draggable;tabIndex;title;htmlRef;
 * @property {titleline} 基础属性
 * @property {string} [display='inline-flex'] *可选值:inline-flex,flex,grid,inline-grid,none,block,inline-block*
 * @property {string|number} gap 间距，单位为px
 * @property {string|number} [size='small'] 尺寸*可选值:small(8), middle(16), large(24), 2, 4, 6, 8, 12, 32*
 * @property {string} [direction='horizontal'] 方向*可选值: horizontal, vertical*
 * @property {string} [align] 对齐*可选值: center, normal, start, end, baseline, stretch*
 * @property {string} [justify='normal'] 对齐*可选值: center, normal, start, end, between, around*
 * @property {string} [baseClassName='spaui-space'] 类名前缀
 * @property {string|element} split 拆分元素
 * @property {string} [itemClassName=''] 子元素类名
 * @property {string} [tagType='div'] 标签类型
 * @property {string} [itemTagType='div'] 子级标签类型
 * @example
 * 尺寸: small 8,middle 16,large 24,其他 2, 4, 6, 8, 12, 32
 */
var config = {
  display: {
    type: _spauiUtils.PropTypes.oneOf(['inline-flex', 'flex', 'grid', 'inline-grid', 'none', 'block', 'inline-block']),
    defaultValue: 'inline-flex'
  },
  align: {
    type: _spauiUtils.PropTypes.oneOf(['normal', 'start', 'end', 'center', 'baseline', 'stretch', _spauiUtils.PropTypes.string])
  },
  justify: {
    type: _spauiUtils.PropTypes.oneOf(['normal', 'start', 'end', 'center', 'between', 'around', _spauiUtils.PropTypes.string]),
    defaultValue: 'normal'
  },
  direction: {
    type: _spauiUtils.PropTypes.oneOf(['horizontal', 'vertical']),
    defaultValue: 'horizontal'
  },
  gap: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number])
  },
  size: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number]),
    defaultValue: 'small'
  },
  baseClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'spaui-space'
  },
  itemClassName: {
    type: _spauiUtils.PropTypes.string
  },
  tagType: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'div'
  },
  itemTagType: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'div'
  }
}; // 转化成PureComponent组件的配置

exports.config = config;
var configData = (0, _spauiUtils.formatProps)(config); // 导出

var propTypes = configData.propTypes;
exports.propTypes = propTypes;
var defaultProps = configData.defaultProps;
exports.defaultProps = defaultProps;
var _default = {
  config: config,
  propTypes: propTypes,
  defaultProps: defaultProps
};
exports["default"] = _default;