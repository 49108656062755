"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2022, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件对应结构的默认className
 */
var _default = {
  Menu: 'spaui-menu',
  MenuItem: 'spaui-menuitem',
  MenuOpen: 'spaui-menu-open',
  MenuSvg: 'spaui-menu-svg',
  MenuToggle: 'spaui-menu-toggle',
  MenuMiniText: 'spaui-menu-mini-text',
  MenuArrow: 'spaui-menu-arrow',
  MenuText: 'spaui-menu-text',
  MenuGroup: 'spaui-menu-group',
  MenuSelected: 'spaui-menu-selected',
  MenuChildSelected: 'spaui-menu-child-selected',
  MenuTitle: 'spaui-menu-title',
  MenuIcon: 'spaui-menu-icon',
  MenuMini: 'spaui-menu-mini',
  MenuFixed: 'spaui-menu-fixed',
  MenuWithShortLabel: 'spaui-menu-with-shortlabel',
  HasSubMenu: 'has-submenu',
  SubMenuOpen: 'spaui-sub-menu-open',
  SubMenu: 'spaui-submenu',
  SubMenuLast: 'spaui-submenu-last',
  SubMenuChild: 'spaui-submenu-child',
  unMarginBottom: 'un-margin-bottom',
  CustomWidth: 'custom-width',
  IsMini: 'is-mini',
  PopMenu: 'spaui-submenu-popup spaui-menu-dark',
  PopMenuTooltip: ' spaui-menu-tooltip',
  PopMenuFixed: 'spaui-submenu-popup-fixed'
};
exports["default"] = _default;