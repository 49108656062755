"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

var _default = function _default() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _props$label = props.label,
      label = _props$label === void 0 ? '' : _props$label;
  var result = null;

  if (_typeof(label) === 'object') {
    result = _react["default"].createElement("span", {
      className: "spaui-rating-text"
    }, label);
  } else {
    result = _react["default"].createElement("span", {
      className: "spaui-rating-text",
      dangerouslySetInnerHTML: {
        __html: label
      }
    });
  }

  return result;
};

exports["default"] = _default;