"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _index = require("../../utils/index");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var _default = function _default(props) {
  var _props$data = props.data,
      data = _props$data === void 0 ? {} : _props$data,
      type = props.type,
      controls = props.controls,
      _props$dragEventMap = props.dragEventMap,
      dragEventMap = _props$dragEventMap === void 0 ? {} : _props$dragEventMap;
  var result = null;

  if (!data.url && data.name) {
    result = _react["default"].createElement("span", null, data.name);
  } else {
    if (type === 'video' || (0, _index.isVideo)(data.url)) {
      result = _react["default"].createElement("video", _extends({}, dragEventMap, {
        src: data.url,
        controls: controls
      }));
    } else {
      result = _react["default"].createElement("img", _extends({}, dragEventMap, {
        src: data.url,
        alt: data.name
      }));
    }
  }

  return result;
};

exports["default"] = _default;