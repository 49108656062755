"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultLocale = void 0;

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
var defaultLocale = {
  totalSizeLabel: '共 {value} 条记录，',
  pageSizeChangeLabel: '每页显示',
  pageSizeLabel: '每页显示 {value} 条',
  totalPageButton: '共 {value} 页',
  size: '条',
  goToLabel: '跳转'
};
exports.defaultLocale = defaultLocale;
var _default = {
  defaultLocale: defaultLocale
};
exports["default"] = _default;