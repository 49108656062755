"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
// 获取key
var objectKeys = function objectKeys(object) {
  var result = [];
  var key = '';

  for (key in object) {
    if (typeof key !== 'undefined') {
      result.push(key);
    }
  }

  return result;
}; // 通过JSON判断是否相等


var equalByJSON = function equalByJSON(fromValue, toValue) {
  var valueEqual = true;

  if ((0, _spauiUtils.isObject)(JSON) && (0, _spauiUtils.isFunction)(JSON.stringify)) {
    try {
      if (JSON.stringify(fromValue) !== JSON.stringify(toValue)) {
        valueEqual = false;
      }
    } catch (e) {
      valueEqual = false;
    }
  } else {
    valueEqual = false;
  }

  return valueEqual;
}; // 数组判断是否相等


var isEqualForArray = function isEqualForArray(fromValue, toValue) {
  var result = false;

  if (fromValue.length !== toValue.length) {
    result = false;
  } else {
    result = equalByJSON(fromValue, toValue);
  }

  return result;
}; // 类型相同判断是否相等


var isEqualForType = function isEqualForType(fromValue, toValue, valueType) {
  var result = false;

  if (valueType === 'string' || valueType === 'number' || valueType === 'boolean' || valueType === 'undefined') {
    if (fromValue !== toValue) {
      result = false;
    }
  } else if (valueType === 'array') {
    result = isEqualForArray(fromValue, toValue);
  } else {
    result = equalByJSON(fromValue, toValue);
  }

  return result;
};

var isEqual = function isEqual() {
  var fromObject = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var toObject = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var result = false;
  var key = '';
  var haveAllKey = true;
  var valueEqual = true;
  var fromValue = '';
  var toValue = '';
  var fromValueType = '';
  var toValueType = ''; // 1、key数量一致

  if (objectKeys(fromObject).length === objectKeys(toObject).length) {
    for (key in fromObject) {
      // 2、每个key在另外个对象都能够找到，如果不能够找到就不需要对比了
      if (typeof toObject[key] === 'undefined' && haveAllKey) {
        haveAllKey = false;
      }
    } // 3、如果key都有的话，再对比具体的值


    if (haveAllKey) {
      for (key in fromObject) {
        if (valueEqual) {
          fromValue = fromObject[key];
          toValue = toObject[key];
          fromValueType = (0, _spauiUtils.type)(fromValue);
          toValueType = (0, _spauiUtils.type)(toValue);

          if (fromValueType === toValueType) {
            valueEqual = isEqualForType(fromValue, toValue, fromValueType);
          } else {
            valueEqual = false;
          }
        }
      }

      if (valueEqual) {
        result = true;
      }
    }
  }

  return result;
};

var _default = isEqual;
exports["default"] = _default;