"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _spauiIcon = _interopRequireDefault(require("@tencent/spaui-icon"));

var _spauiUtils = require("@tencent/spaui-utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var ValueAreaComponent = function ValueAreaComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var onOpen = props.onOpen,
      placeholder = props.placeholder,
      valueLabel = props.valueLabel,
      clear = props.clear,
      multiple = props.multiple,
      value = props.value,
      propsOnClear = props.onClear,
      _props$valueAreaClass = props.valueAreaClassName,
      valueAreaClassName = _props$valueAreaClass === void 0 ? '' : _props$valueAreaClass;
  var showClearIcon = clear && (multiple ? value && value.length > 0 : value || typeof value === 'number');
  var className = (0, _classnames["default"])('selection-single', valueAreaClassName, {
    placeholder: !valueLabel,
    'spaui-select-clearable': showClearIcon
  });
  var label = valueLabel || placeholder || '请选择';

  var arrowDom = _react["default"].createElement("div", {
    className: "selection-arrow"
  }, _react["default"].createElement("svg", {
    width: "16px",
    height: "16px",
    viewBox: "0 0 16 16"
  }, _react["default"].createElement("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    strokeLinecap: "square"
  }, _react["default"].createElement("polyline", {
    stroke: "#B8BCC2",
    points: "4 6 8.5 10.5 13 6"
  }))));

  var onClear = function onClear(event) {
    event.stopPropagation();

    if ((0, _spauiUtils.isFunction)(propsOnClear)) {
      propsOnClear(event);
    }
  };

  var title;

  if (typeof label === 'string' && label && label !== placeholder) {
    title = label;
  }

  return _react["default"].createElement("div", {
    onClick: onOpen,
    className: className
  }, _react["default"].createElement("span", {
    title: title,
    className: "selection-single-text"
  }, label), showClearIcon ? _react["default"].createElement("div", {
    className: "spaui-select-clear",
    role: "button",
    onClick: onClear
  }, _react["default"].createElement(_spauiIcon["default"], {
    name: "clear-outlined"
  })) : null, arrowDom);
};

var _default = ValueAreaComponent;
exports["default"] = _default;