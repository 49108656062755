"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultProps = exports.propTypes = exports.config = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件参数配置
 */

/**
 * Echarts 接口参数
 * @alias EchartsProps
 * @description 基于 echarts 封装的图表组件。
 * @property {titleline} 默认支持参数
 * @property {blankline} className;style;id;data-*;accessKey;dir;draggable;tabIndex;title;htmlRef;
 * @property {titleline} 基础属性
 * @property {func} [chartCallback] 初始化完成之后的callback
 * @property {string|object} [theme] 主题
 * @property {string} [renderer="canvas"] 渲染器，默认为 canvas，可选 svg
 * @property {object} [opts={}] 其他属性
 */
var config = {
  chartCallback: {
    type: _spauiUtils.PropTypes.func
  },
  theme: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.object])
  },
  renderer: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'canvas'
  },
  opts: {
    type: _spauiUtils.PropTypes.object
  },
  notMerge: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  lazyUpdate: {
    type: _spauiUtils.PropTypes.bool
  },
  replaceMerge: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.array])
  }
}; // 转化成PureComponent组件的配置

exports.config = config;
var configData = (0, _spauiUtils.formatProps)(config); // 导出

var propTypes = configData.propTypes;
exports.propTypes = propTypes;
var defaultProps = configData.defaultProps;
exports.defaultProps = defaultProps;
var _default = {
  config: config,
  propTypes: propTypes,
  defaultProps: defaultProps
};
exports["default"] = _default;