"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.genTimeRoutine = exports.timeTranslate = void 0;

var _index = require("../config/index");

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * 翻译4位数字时间，如1250 => 12:30
 * @param {string} digit - 4位数字字符串
 * @return {string} 翻译后的时间字符串
 * @private
 */
var timeTranslate = function timeTranslate() {
  var digit = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

  if (digit && digit.length === 4) {
    return digit.replace(/(\d{2})50/, '$130').replace(/(\d{2})(\d{2})/, '$1:$2');
  }

  return digit;
};

exports.timeTranslate = timeTranslate;

var genTimeRoutine = function genTimeRoutine(routineKeys, routineList, weekDayNames) {
  // 渲染选中的时间段
  var retObj = {}; // 结果对象

  var isContinue = false;
  var curStart;
  var curEnd;
  routineKeys.forEach(function (item, index) {
    var curWeekIndex = item.slice(0, 1) - 1; // 当前星期几的索引

    if (routineList[item] === _index.SELECT_STATUS.SELECTED) {
      // 选中
      var curTime = item.slice(1) - 0;

      if (!isContinue) {
        // 上个已断
        curStart = "0000".concat(curTime - 50).slice(-4); // 若为纯数字可能为0

        curEnd = "0000".concat(curTime).slice(-4);
        isContinue = true;
      } else {
        // 延续上一个
        curEnd = "0000".concat(curTime).slice(-4); // 更新结束时间
      }
    } else {
      // 未选中
      isContinue = false;

      if (curStart && curEnd) {
        // 收集时间
        if (!retObj[curWeekIndex]) {
          retObj[curWeekIndex] = [];
        }

        retObj[curWeekIndex].push([curStart, curEnd]);
        curStart = null;
        curEnd = null;
      }
    }

    if ((index + 1) % 48 === 0) {
      // 一天最后的半小时，当前一天有48个半小时
      if (isContinue) {
        // 最后半小时已选中，收集时间
        if (!retObj[curWeekIndex]) {
          retObj[curWeekIndex] = [];
        }

        retObj[curWeekIndex].push([curStart, curEnd]);
        curStart = null;
        curEnd = null;
        isContinue = false; // 强制结束
      }
    }
  });
  var showArr = [];

  for (var i in retObj) {
    if (retObj[i] && retObj[i].length > 0) {
      var timeArr = retObj[i].map(function (item) {
        return "".concat(timeTranslate(item[0]), "-").concat(timeTranslate(item[1]));
      });
      var weekShow = weekDayNames[i] ? weekDayNames[i] : '';
      var tipShow = "".concat(weekShow, ":").concat(timeArr.join(','));
      showArr.push(tipShow);
    }
  } // 得到二进制字符串


  var digitsArr = [];

  for (var _i in routineList) {
    // 考虑兼容性隐患，暂时不用Object.values()
    if (typeof routineList[_i] !== 'undefined') {
      digitsArr.push(routineList[_i]);
    }
  }

  var digitsStr = digitsArr.join('');
  return [digitsStr, showArr];
};

exports.genTimeRoutine = genTimeRoutine;
var _default = {
  timeTranslate: timeTranslate
};
exports["default"] = _default;