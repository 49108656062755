"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var NoValueComponent = function NoValueComponent(props) {
  var uploadText = props.uploadText;
  var divProps = {};
  var key = '';

  for (key in props) {
    if (key !== 'useFileAPI' && key !== 'uploadText') {
      divProps[key] = props[key];
    }
  }

  return _react["default"].createElement("div", _extends({
    className: "spaui-upfile-inner"
  }, divProps), _react["default"].createElement("i", {
    className: "spaui-upfile-icon"
  }), _react["default"].createElement("span", {
    className: "spaui-upfile-label"
  }, uploadText));
};

var _default = NoValueComponent;
exports["default"] = _default;