"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
var compactProps = function compactProps() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var asyncLoading = typeof props.asyncLoadingAble !== 'undefined' ? props.asyncLoadingAble : props.asyncLoading;
  var canImport = typeof props.importable !== 'undefined' ? props.importable : props.canImport;
  return {
    asyncLoading: asyncLoading,
    canImport: canImport
  };
};

var _default = compactProps;
exports["default"] = _default;