"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var YearPanelComponent = function YearPanelComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var propsYear = props.year,
      onChange = props.onChange,
      _props$optionalStartD = props.optionalStartDateData,
      optionalStartDateData = _props$optionalStartD === void 0 ? {} : _props$optionalStartD,
      _props$optionalEndDat = props.optionalEndDateData,
      optionalEndDateData = _props$optionalEndDat === void 0 ? {} : _props$optionalEndDat,
      _props$classNameMap = props.classNameMap,
      classNameMap = _props$classNameMap === void 0 ? {} : _props$classNameMap,
      _props$locale = props.locale,
      locale = _props$locale === void 0 ? {} : _props$locale,
      setLabel = props.setLabel,
      picker = props.picker,
      displayedStartYear = props.displayedStartYear,
      displayedEndYear = props.displayedEndYear,
      _props$dateData = props.dateData,
      dateData = _props$dateData === void 0 ? {} : _props$dateData,
      PanelListComponent = props.PanelListComponent;
  var len = optionalEndDateData.year || 0;
  var result = [];
  var i = optionalStartDateData.year || 0;
  var year = picker === 'year' ? dateData.year : propsYear; // 如果是年份选择器是需要分页的

  if (picker === 'year') {
    for (var j = displayedStartYear; j <= displayedEndYear; j++) {
      result.push({
        value: j,
        label: j + locale.yearLabel,
        disabled: !(j >= optionalStartDateData.year && j <= optionalEndDateData.year)
      });
    }
  } else {
    for (; i <= len; i++) {
      result.push({
        value: i,
        label: i + locale.yearLabel
      });
    }
  }

  return _react["default"].createElement(PanelListComponent, {
    className: (0, _classnames["default"])(classNameMap.DatePickerYearPanelClassName, _defineProperty({}, classNameMap.DatePickerYearPanelScrollClassName, result.length > 12)),
    classNameMap: classNameMap,
    data: result,
    value: year,
    onChange: onChange,
    locale: locale,
    setLabel: setLabel,
    panelType: "year"
  });
};

var _default = YearPanelComponent;
exports["default"] = _default;