"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultProps = exports.propTypes = exports.config = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件参数配置
 */

/**
 * CheckboxGroup 接口参数
 * @alias CheckboxGroupProps
 * @description 复选框组。
 * @property {titleline} 默认支持参数
 * @property {blankline} className='simple-check'复选框为打勾样式
 * @property {blankline} className;style;id;data-*;accessKey;dir;draggable;tabIndex;title;htmlRef;
 * @property {titleline} 基本属性
 * @property {array} value=[]] 值
 * @property {array} data=[]] 选项数据
 * @property {func} onChange 切换状态回调函数<br />onChange = (event, value)
 * @property {string} [name=''] 字段名
 * @property {bool} [disabled=false] 是否禁用
 * @property {bool} [vertical=false] 是否垂直
 * @property {bool} [checkAll=false] 是否支持全选（支持的话，第一个值会是全选按钮）
 * @property {string} [checkAllLabel='全选'] 全选文案
 * @property {object} [CheckboxProps={}] 应用于Checkbox的props属性
 * @property {string} [tipFieldName] 指定提示字段名
 *
 * @see
 *
 * onChange typings:
 *     (event: any, value: any) => any
 */
var config = {
  useNewUI: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  value: {
    type: _spauiUtils.PropTypes.array.isRequired,
    defaultValue: [],
    visualData: {
      type: 'array',
      description: '选中值'
    }
  },
  data: {
    type: _spauiUtils.PropTypes.array,
    defaultValue: [],
    visualData: {
      type: 'array',
      description: '选项数据'
    }
  },
  onChange: {
    type: _spauiUtils.PropTypes.func
  },
  name: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: '',
    visualData: {
      type: 'input',
      description: '字段名'
    }
  },
  disabled: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false,
    visualData: {
      type: 'switch',
      description: '是否禁用'
    }
  },
  vertical: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false,
    visualData: {
      type: 'switch',
      description: '是否垂直'
    }
  },
  checkAll: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false,
    visualData: {
      type: 'switch',
      description: '是否垂直'
    }
  },
  checkAllLabel: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: '全选',
    visualData: {
      type: 'input'
    }
  },
  CheckboxProps: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {},
    visualData: {
      type: 'object',
      description: '应用于Checkbox的props属性'
    }
  },
  tipFieldName: {
    type: _spauiUtils.PropTypes.string
  }
}; // 转化成PureComponent组件的配置

exports.config = config;
var configData = (0, _spauiUtils.formatProps)(config); // 导出

var propTypes = configData.propTypes;
exports.propTypes = propTypes;
var defaultProps = configData.defaultProps;
exports.defaultProps = defaultProps;
var _default = {
  config: config,
  propTypes: propTypes,
  defaultProps: defaultProps
};
exports["default"] = _default;