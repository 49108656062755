"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultProps = exports.propTypes = exports.config = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件参数配置
 */

/**
 * TabPanel 接口参数
 * @alias TabPanelProps
 * @description 标签面板组件。
 * @property {string} name='' 字段名
 * @property {string|element|func} [tab=''] 按钮内容
 * @property {bool} [disabled=false] 是否禁用
 * @property {string} [className] tab 对应元素的类名
 */
var config = {
  tab: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.element, _spauiUtils.PropTypes.func]),
    defaultValue: ''
  },
  name: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number]),
    defaultValue: ''
  },
  disabled: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  className: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  }
}; // 转化成PureComponent组件的配置

exports.config = config;
var configData = (0, _spauiUtils.formatProps)(config); // 导出

var propTypes = configData.propTypes;
exports.propTypes = propTypes;
var defaultProps = configData.defaultProps;
exports.defaultProps = defaultProps;
var _default = {
  config: config,
  propTypes: propTypes,
  defaultProps: defaultProps
};
exports["default"] = _default;