"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * TODO 因为Icon组件不兼容, 暂时用旧版的组件替代
 */
var Icon = function Icon() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var className = (0, _classnames["default"])('icon spaui-icon', {
    'ico-help': props.type === 'help',
    'ico-arrow-r': props.type === 'rightarrow',
    'ico-strongwarn-mid': props.type === 'strongwarn',
    'ico-warn-mid': props.type === 'warn',
    'ico-success-mid': props.type === 'success',
    'ico-confirm-mid': props.type === 'confirm',
    'ico-tips-mid': props.type === 'tips',
    'ico-wrong-mid': props.type === 'wrong' || props.type === 'error',
    'ico-loading': props.type === 'loading',
    'ico-loading-blue': props.type === 'loading-blue',
    'ico-search-m': props.type === 'search'
  }, props.className || '');
  return _react["default"].createElement("i", {
    className: className,
    title: props.title || ''
  }, _react["default"].createElement("i", null));
};

var _default = Icon;
exports["default"] = _default;