"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultProps = exports.propTypes = exports.config = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件参数配置
 */

/**
 * Coder 接口参数
 * @alias CoderProps
 * @description 用于批量数据导入或是代码展示。
 * @property {titleline} 默认支持参数
 * @property {blankline} className;style;id;data-*;accessKey;dir;draggable;tabIndex;title;htmlRef;
 * @property {titleline} 基础属性
 * @property {string} value='' 内容
 * @property {func} onChange 输入回调*onChange = (event, value)*
 * @property {string} [placeholder=''] 提示
 * @property {bool} [disabled=false] 是否禁用
 * @property {string} [footerClassName=''] 尾部结构父元素className
 * @property {func|element} [FooterComponent] 尾部结构*(具体参数看案例)*
 * @example
 * &lt;FooterComponent
    placeholder={placeholder}
    value={value}
    disabled={disabled}
/&gt;
 */
var config = {
  value: {
    type: _spauiUtils.PropTypes.string.isRequired,
    defaultValue: ''
  },
  placeholder: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  onChange: {
    type: _spauiUtils.PropTypes.func.isRequired
  },
  disabled: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  FooterComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.element, _spauiUtils.PropTypes.func])
  },
  footerClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  }
}; // 转化成PureComponent组件的配置

exports.config = config;
var configData = (0, _spauiUtils.formatProps)(config); // 导出

var propTypes = configData.propTypes;
exports.propTypes = propTypes;
var defaultProps = configData.defaultProps;
exports.defaultProps = defaultProps;
var _default = {
  config: config,
  propTypes: propTypes,
  defaultProps: defaultProps
};
exports["default"] = _default;