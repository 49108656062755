"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _index = require("../utils/index");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var ChildComponent = function ChildComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var data = props.data,
      value = props.value,
      onChange = props.onChange,
      onMenuItemMouseEnter = props.onMenuItemMouseEnter,
      onMenuItemMouseLeave = props.onMenuItemMouseLeave,
      onMenuItemTransitionEnd = props.onMenuItemTransitionEnd,
      nodeIsSelected = props.nodeIsSelected,
      type = props.type,
      _props$classNameMap = props.classNameMap,
      classNameMap = _props$classNameMap === void 0 ? {} : _props$classNameMap;
  return _react["default"].createElement("ul", {
    className: (0, _classnames["default"])(classNameMap.SubMenu, classNameMap.SubMenuChild)
  }, data.map(function () {
    var _classNames;

    var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var index = arguments.length > 1 ? arguments[1] : undefined;
    var label = item.label,
        href = item.href;
    var dataAttribute = {};
    var attrKey = ''; // 保留data-*的属性

    for (attrKey in item) {
      if (attrKey.indexOf('data-') === 0) {
        dataAttribute[attrKey] = item[attrKey];
      }
    } // 点击回调


    var onClick = function onClick(event) {
      // 2 是指这是第三层，从0开始算的，0，1，2
      onChange(event, item, 2);
    };

    return _react["default"].createElement("li", {
      key: index,
      className: (0, _classnames["default"])(classNameMap.MenuItem, (_classNames = {}, _defineProperty(_classNames, classNameMap.MenuSelected, nodeIsSelected(value, item, type)), _defineProperty(_classNames, classNameMap.MenuChildSelected, (0, _index.childIsSelect)(value, item, nodeIsSelected)), _classNames)),
      onMouseEnter: onMenuItemMouseEnter,
      onMouseLeave: onMenuItemMouseLeave,
      onTransitionEnd: onMenuItemTransitionEnd
    }, _react["default"].createElement("a", _extends({
      href: href,
      onClick: onClick
    }, dataAttribute), label));
  }));
};

var _default = ChildComponent;
exports["default"] = _default;