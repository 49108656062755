"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultProps = exports.propTypes = exports.config = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

var _slider_track = _interopRequireDefault(require("./component/slider_track"));

var _slider_handle = _interopRequireDefault(require("./component/slider_handle"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件参数配置
 */

/**
 * Slider 接口参数
 * @alias SliderProps
 * @description 用于滑动选择数值或区间的输入条。
 * @property {titleline} 默认支持参数
 * @property {blankline} className;style;id;data-*;accessKey;dir;draggable;tabIndex;title;htmlRef;
 * @property {titleline} 基础属性
 * @property {number} min=0 范围最小值
 * @property {number} max=100 范围最大值
 * @property {number} step=1 步长（最小刻度值）
 * @property {number} value=0 当前值
 * @property {func} onChange 改变中回调<br />onChange = function(event, value)
 * @property {bool} [disabled=false] 是否禁用
 * @property {string} [size=''] 尺寸*可选值：small, large, extralarge*
 * @property {string} [type='point'] 类型*可选值：point, range*
 * @property {bool} [canTranslationRange=false] 是否可以平移range范围
 * @property {bool} [exceed=true] 区间时，拖动开始或结束节点时是否能够越过另外一个节点<br />type为range时才有效
 * @property {array} [valueNodes=[]] valueNodes可选的数据节点
 * @property {bool} [showStepButton=false] 显示步骤按钮
 * @property {func} [onChangeStart] 开始改变回调
 * @property {func} [onChangeComplete] 改变结束回调<br />onChangeComplete = function(event, value)
 * @property {object} [classNameMap={}] 组件内对应结构的默认className(可以针对对应位置进行替换,具体参数见案例)
 * @property {titleline} 展示文案属性
 * @property {string|number|Element} [prefix=''] 前缀
 * @property {string|number|Element} [suffix=''] 后缀
 * @property {any} [infoContent=''] 信息内容（一般为含单位的当前值）
 * @property {titleline} 提示属性
 * @property {object} [popOver = {}] 单个滑动的popOver配置
 * @property {object} [leftPopOver={}] 左侧popOver配置
 * @property {object} [rightPopOver={}] 左侧popOver配置
 * @property {titleline} 显示坐标属性
 * @property {bool} [showStepNode=false] 显示坐标节点
 * @property {array} [stepNodes = [0, 0.5, 1]] 显示坐标
 * @property {string} [stepNodePlacement='bottom'] 坐标文案位置*可选值: bottom，top*
 * @property {func} [setStepNodeLabel] 转化文案文字<br />setStepNodeLabel = (label, stepNode, min, max) => return label
 * @property {titleline} 自定义结构属性
 * @property {func|element} [SliderTrack] 选中的范围展示结构
 * @property {func|element} [SliderHandle] 滑动拖动的节点结构
 * @example
 * classNameMap配置可见于：
 * https://git.woa.com/ads/spaui-basic_ui_components/tree/master/src/spaui-slider/src/js/config/classname_map.js
 */
var config = {
  min: {
    type: _spauiUtils.PropTypes.number.isRequired,
    defaultValue: 0
  },
  max: {
    type: _spauiUtils.PropTypes.number.isRequired,
    defaultValue: 100
  },
  step: {
    type: _spauiUtils.PropTypes.number.isRequired,
    defaultValue: 1
  },
  value: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.number, _spauiUtils.PropTypes.array]).isRequired,
    defaultValue: 0
  },
  valueNodes: {
    type: _spauiUtils.PropTypes.array,
    defaultValue: []
  },
  exceed: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  prefix: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number, _spauiUtils.PropTypes.element]),
    defaultValue: ''
  },
  suffix: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number, _spauiUtils.PropTypes.element]),
    defaultValue: ''
  },
  showStepButton: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  onChange: {
    type: _spauiUtils.PropTypes.func
  },
  disabled: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  type: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'point'
  },
  canTranslationRange: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  size: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  showStepNode: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  stepNodes: {
    type: _spauiUtils.PropTypes.array,
    defaultValue: [0, 0.5, 1]
  },
  stepNodePlacement: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'bottom'
  },
  setStepNodeLabel: {
    type: _spauiUtils.PropTypes.func
  },
  popOver: {
    type: _spauiUtils.PropTypes.object
  },
  leftPopOver: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  rightPopOver: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  infoContent: {
    type: _spauiUtils.PropTypes.any,
    defaultValue: ''
  },
  onChangeStart: {
    type: _spauiUtils.PropTypes.func
  },
  onChangeComplete: {
    type: _spauiUtils.PropTypes.func
  },
  SliderTrack: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _slider_track["default"]
  },
  SliderHandle: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _slider_handle["default"]
  },
  classNameMap: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  }
}; // 转化成PureComponent组件的配置

exports.config = config;
var configData = (0, _spauiUtils.formatProps)(config); // 导出

var propTypes = configData.propTypes;
exports.propTypes = propTypes;
var defaultProps = configData.defaultProps;
exports.defaultProps = defaultProps;
var _default = {
  config: config,
  propTypes: propTypes,
  defaultProps: defaultProps
};
exports["default"] = _default;