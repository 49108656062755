"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _spauiUtils = require("@tencent/spaui-utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

var _default = function _default() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var data = props.data,
      value = props.value,
      LabelComponent = props.LabelComponent,
      AdditionComponent = props.AdditionComponent,
      onClickItem = props.onClickItem,
      itemHeight = props.itemHeight,
      _props$itemStyle = props.itemStyle,
      itemStyle = _props$itemStyle === void 0 ? {} : _props$itemStyle,
      _props$imgStyle = props.imgStyle,
      imgStyle = _props$imgStyle === void 0 ? {} : _props$imgStyle,
      index = props.index,
      onTransitionEnd = props.onTransitionEnd,
      _props$className = props.className,
      className = _props$className === void 0 ? '' : _props$className;
  var currentItemHeight = parseInt(itemHeight, 10) || 0;
  var dataAttribute = {};
  var style = {};
  var aProps = {};
  var label = '';
  var imgUrl = ''; // 元素的style

  style = (0, _spauiUtils.objectAssign)({}, itemStyle);

  if (typeof itemHeight !== 'undefined' && currentItemHeight > 0) {
    style.lineHeight = "".concat(currentItemHeight, "px");
  } // a标签的相关属性


  if (_typeof(data) === 'object' && data.href) {
    aProps.href = data.href;
    aProps.target = data.target || '_blank';
  } // 如果需要回调事件，就加上去


  if ((0, _spauiUtils.isFunction)(onClickItem)) {
    aProps.onClick = function (event) {
      return onClickItem(event, data);
    };
  }

  if (typeof data !== 'undefined') {
    // 根据不同的值来获取对应的链接与文案
    if (_typeof(data) === 'object' && data) {
      label = data.label || '';
      imgUrl = data.value || '';
    } else {
      label = data;
      imgUrl = data;
    }
  } // 将 data- 属性存储到组件根节点


  if (_typeof(data) === 'object') {
    Object.keys(data).forEach(function (item) {
      if (item.startsWith('data-')) {
        dataAttribute[item] = data[item];
      }
    });
  }

  return _react["default"].createElement("div", _extends({
    key: index,
    className: className,
    style: style,
    onTransitionEnd: onTransitionEnd
  }, dataAttribute), _react["default"].createElement("a", aProps, _react["default"].createElement("img", {
    alt: label,
    src: imgUrl,
    style: imgStyle
  }), _typeof(data) === 'object' && data.label && LabelComponent ? _react["default"].createElement(LabelComponent, {
    index: index,
    value: value,
    data: data
  }) : null, typeof AdditionComponent !== 'undefined' && AdditionComponent ? _react["default"].createElement(AdditionComponent, {
    index: index,
    value: value,
    data: data
  }) : null));
};

exports["default"] = _default;