"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports["int"] = exports.setButtonLabel = exports.setArrowLabel = void 0;

var _react = _interopRequireDefault(require("react"));

var _index = require("../config/index");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var setArrowLabel = function setArrowLabel() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: 16,
    height: 16,
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    clipRule: "evenodd",
    fill: "currentColor",
    fillRule: "evenodd",
    d: "M 11.707 2 L 11 1.293 L 4.293 8 L 11 14.707 l 0.707 -0.707 l -6 -6 l 6 -6 Z"
  }));
};

exports.setArrowLabel = setArrowLabel;

var setButtonLabel = function setButtonLabel(type, value) {
  var result = '';

  switch (type) {
    case 'prevPageButton':
      result = setArrowLabel();
      break;

    case 'nextPageButton':
      result = setArrowLabel();
      break;

    case 'totalPageButton':
      result = _index.defaultLocale.totalPageButton.replace('{value}', value);
      break;

    case 'ellipsisButton':
      result = '...';
      break;

    default:
      result = value;
      break;
  }

  return result;
};

exports.setButtonLabel = setButtonLabel;

var _int = function _int() {
  var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  return parseInt(value, 10) || defaultValue;
};

exports["int"] = _int;
var _default = {
  setArrowLabel: setArrowLabel,
  setButtonLabel: setButtonLabel,
  "int": _int
};
exports["default"] = _default;