"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _index = require("../../utils/index");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
// 图片展示结构组件
var DisplayComponent = function DisplayComponent(props) {
  var _props$data = props.data,
      data = _props$data === void 0 ? {} : _props$data,
      className = props.className,
      style = props.style,
      width = props.width,
      height = props.height,
      htmlRef = props.htmlRef;
  return _react["default"].createElement("img", {
    className: className,
    src: (0, _index.getItemUrl)(data),
    style: style,
    width: width,
    height: height,
    ref: htmlRef
  });
};

var _default = DisplayComponent;
exports["default"] = _default;