"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _index = require("../../utils/index");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
var _default = function _default(props) {
  var _props$data = props.data,
      data = _props$data === void 0 ? {} : _props$data,
      _props$type = props.type,
      type = _props$type === void 0 ? '' : _props$type,
      controls = props.controls;
  var result = null;

  if (type === 'video' || (0, _index.isVideo)(data.url)) {
    if (data.url) {
      result = _react["default"].createElement("video", {
        src: data.url,
        controls: controls
      });
    }
  } else {
    if (data.url) {
      result = _react["default"].createElement("img", {
        src: data.url || '',
        alt: data.name || ''
      });
    }
  }

  return result;
};

exports["default"] = _default;