"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件对应结构的默认className
 */
var _default = {
  CascadeTree: 'spaui-cascade-tree',
  TreeTitle: 'spaui-cascade-tree-title',
  TreeNode: 'spaui-cascade-tree-treenode',
  TreeNodeOpen: 'spaui-cascade-tree-treenode-open',
  TreeChild: 'spaui-cascade-tree-child',
  TreeChildSelected: 'spaui-cascade-tree-child-selected',
  TreeSelected: 'spaui-cascade-tree-selected',
  TreeCanChoose: 'spaui-cascade-tree-can-choose',
  TreeCanExpand: 'spaui-cascade-tree-can-exapnd',
  TreeCheckbox: 'spaui-cascade-tree-checkbox',
  TreeRadio: 'spaui-cascade-tree-radio',
  TreeSwitcher: 'spaui-cascade-tree-switcher',
  TreeSwitcherOpen: 'spaui-cascade-tree-switcher-open',
  TreeSwitcherHide: 'spaui-cascade-tree-switcher-hide'
};
exports["default"] = _default;