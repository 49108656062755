"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.getLastWeekByNumber = exports.getHideWeekDays = exports.getRangeWeekDays = exports.getMonthOfYearByDate = exports.getWeekOfYearByDate = exports.getWeekOfDayByDateNoConfig = exports.getCurrentWeekDays = exports.uniqueArray = exports.differenceArray = exports.getAllDays = exports.formatDateAlias = exports.formatDate = exports.isSafari = exports.getElementViewTop = exports.getElementTop = exports.formatDateString = exports.timeInRange = exports.addMonths = exports.throttle = exports.getTwoDateDifference = exports.compareDate = exports.removeResize = exports.addResize = exports.isNotEqualByObject = exports.getRowDataByTimeList = exports.getStringTimeByData = exports.addZero = exports.toInt = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

// 转化为数字
var toInt = function toInt(value) {
  var defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  return parseInt(value, 10) || defaultValue;
};

exports.toInt = toInt;

var addZero = function addZero() {
  var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var tempValue = toInt(value);

  if (tempValue < 10) {
    tempValue = "0".concat(tempValue);
  } else {
    tempValue = "".concat(tempValue);
  }

  if (typeof value === 'string' && value.length < 1) {
    tempValue = '';
  }

  return tempValue;
}; // 获取字符串的时间


exports.addZero = addZero;

var getStringTimeByData = function getStringTimeByData() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var result = [];

  if (!(0, _spauiUtils.isUndefined)(data.hour)) {
    result.push(addZero(data.hour));

    if (!(0, _spauiUtils.isUndefined)(data.minute)) {
      result.push(addZero(data.minute));
    }

    if (!(0, _spauiUtils.isUndefined)(data.second)) {
      result.push(addZero(data.second));
    }
  }

  return result.join(':');
}; // 从时分秒列表中获取原始数据
// 如果有timeList，初始化时会用第一个做数据转化，所以这里替换成原来的item数据


exports.getStringTimeByData = getStringTimeByData;

var getRowDataByTimeList = function getRowDataByTimeList() {
  var timeList = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var value = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var result = value;

  if ((0, _spauiUtils.isArray)(timeList) && timeList.length > 0) {
    timeList.map(function () {
      var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var temp = item.split(':');
      var i = 0;

      for (; i < 3; i++) {
        temp[i] = temp[i] || '00';
      }

      if (temp.join(':') === value) {
        result = item;
      }

      return item;
    });
  }

  return result;
}; // 判断两个对象是否相等


exports.getRowDataByTimeList = getRowDataByTimeList;

var isNotEqualByObject = function isNotEqualByObject(fromObject, toObject) {
  var result = false;

  if ((typeof JSON === "undefined" ? "undefined" : _typeof(JSON)) === 'object' && JSON.stringify && _typeof(fromObject) === 'object' && _typeof(toObject) === 'object' && JSON.stringify(fromObject) !== JSON.stringify(toObject) || typeof toObject === 'undefined') {
    result = true;
  }

  return result;
};

exports.isNotEqualByObject = isNotEqualByObject;
var haveJQuery = (typeof window === "undefined" ? "undefined" : _typeof(window)) === 'object' && typeof window.jQuery === 'function'; // 添加resize

var addResize = function addResize(resizeCallback) {
  var target = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : window;

  if (target) {
    if (haveJQuery) {
      jQuery(target).on('resize', resizeCallback);
    } else if (_typeof(target) === 'object' && (0, _spauiUtils.isFunction)(target.addEventListener)) {
      target.addEventListener('resize', resizeCallback);
    }
  }
}; // 去除resize


exports.addResize = addResize;

var removeResize = function removeResize(resizeCallback) {
  var target = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : window;

  if (target) {
    if (haveJQuery) {
      jQuery(target).off('resize', resizeCallback);
    } else if (_typeof(target) === 'object' && (0, _spauiUtils.isFunction)(target.removeEventListener)) {
      target.removeEventListener('resize', resizeCallback);
    }
  }
}; // 比较日期，dateA > dateB 返回正数； dateA = dateB 返回0; dateA < dateB 返回负数；


exports.removeResize = removeResize;

var compareDate = function compareDate() {
  var dateA = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var dateB = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var _dateA$year = dateA.year,
      dateAYear = _dateA$year === void 0 ? 0 : _dateA$year,
      _dateA$month = dateA.month,
      dateAMonth = _dateA$month === void 0 ? 0 : _dateA$month,
      _dateA$date = dateA.date,
      dateADate = _dateA$date === void 0 ? 1 : _dateA$date;
  var _dateB$year = dateB.year,
      dateBYear = _dateB$year === void 0 ? 0 : _dateB$year,
      _dateB$month = dateB.month,
      dateBMonth = _dateB$month === void 0 ? 0 : _dateB$month,
      _dateB$date = dateB.date,
      dateBDate = _dateB$date === void 0 ? 1 : _dateB$date;
  var result = 0;
  var dateATime = 0;
  var dateBTime = 0;

  if (dateAYear > 0 && dateBYear > 0) {
    dateATime = new Date(dateAYear, dateAMonth, dateADate).getTime();
    dateBTime = new Date(dateBYear, dateBMonth, dateBDate).getTime();
    result = dateATime - dateBTime;
  }

  return result;
}; // 计算两个日期的时间差（单位为天）


exports.compareDate = compareDate;

var getTwoDateDifference = function getTwoDateDifference() {
  var dateA = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var dateB = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var _dateA$year2 = dateA.year,
      dateAYear = _dateA$year2 === void 0 ? 0 : _dateA$year2,
      _dateA$month2 = dateA.month,
      dateAMonth = _dateA$month2 === void 0 ? 0 : _dateA$month2,
      _dateA$date2 = dateA.date,
      dateADate = _dateA$date2 === void 0 ? 1 : _dateA$date2;
  var _dateB$year2 = dateB.year,
      dateBYear = _dateB$year2 === void 0 ? 0 : _dateB$year2,
      _dateB$month2 = dateB.month,
      dateBMonth = _dateB$month2 === void 0 ? 0 : _dateB$month2,
      _dateB$date2 = dateB.date,
      dateBDate = _dateB$date2 === void 0 ? 1 : _dateB$date2;
  var result = 0;

  if (dateAYear > 0 && dateBYear > 0) {
    var dateATime = new Date(dateAYear, dateAMonth, dateADate);
    var dateBTime = new Date(dateBYear, dateBMonth, dateBDate);
    var diffTime = Math.abs(dateATime - dateBTime);
    result = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  }

  return result;
};
/**
 * 函数节流
 * @param {function} fn 需要节流的函数
 * @param {number} [delay=100] 延迟的时间
 */


exports.getTwoDateDifference = getTwoDateDifference;

var throttle = function throttle(fn) {
  var delay = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 100;
  var timer = null;
  var timeStart;
  return function () {
    var context = this;
    var args = arguments;
    var timeCurrent = +new Date();
    clearTimeout(timer);

    if (!timeStart) {
      timeStart = timeCurrent;
    }

    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
};
/**
 * 日期 + 指定的月份，并返回一个新的日期
 * @param {*} date
 * @param {*} months
 */


exports.throttle = throttle;

var addMonths = function addMonths(date, months) {
  var newDate = new Date(typeof date === 'number' ? date : date.getTime());
  newDate.setMonth(newDate.getMonth() + months);
  return newDate;
};
/**
 * 判断该日期是否是在指定日期范围内
 * @param {*} beginTime
 * @param {*} endTime
 */


exports.addMonths = addMonths;

var timeInRange = function timeInRange() {
  var currentDateTime = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var beginTime = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var endTime = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  return currentDateTime >= beginTime && currentDateTime <= endTime;
};
/**
 * 格式化日期（修复IOS下部分日期格式不支持的问题）
 */


exports.timeInRange = timeInRange;

var formatDateString = function formatDateString() {
  var dateString = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return dateString.replace(/-/g, '/');
};
/**
 * 获取元素在页面的top位置
 * zale TODO: 后续放到 spaui-utils 里面
 * @param {*} element
 */


exports.formatDateString = formatDateString;

var getElementTop = function getElementTop(element) {
  var result = 0;
  var current;

  if (element) {
    if (typeof element.getBoundingClientRect === 'function') {
      // 用这个方式需要加上滚动条的位置
      result = element.getBoundingClientRect().top;
      result += document.documentElement.scrollTop || document.body.scrollTop;
    } else {
      // 遍历上去
      result = element.offsetTop;
      current = element.offsetParent;

      while (current !== null) {
        result += current.offsetTop;
        current = current.offsetParent;
      }
    }
  }

  return result;
};
/**
 * 获取元素相当于与当前视口的 top 位置
 * zale TODO: 后续放到 spaui-utils 里面
 * @param {*} element
 */


exports.getElementTop = getElementTop;

var getElementViewTop = function getElementViewTop(element) {
  if (element.getBoundingClientRect) {
    return element.getBoundingClientRect().top;
  }

  var offset = element.offsetTop;

  if (element.offsetParent != null) {
    offset += getElementViewTop(element.offsetParent);
  }

  return offset;
}; // 浏览器类型是否是safari


exports.getElementViewTop = getElementViewTop;

var isSafari = function isSafari() {
  return (// 取得浏览器的userAgent字符串
    /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)
  );
}; // 格式化日期为YYYY-MM-DD 格式


exports.isSafari = isSafari;

var formatDate = function formatDate(strDate, pure) {
  var d = new Date(strDate);
  var month = "".concat(d.getMonth() + 1);
  var day = "".concat(d.getDate());
  var year = d.getFullYear();
  if (month.length < 2) month = "0".concat(month);
  if (day.length < 2) day = "0".concat(day);
  var time = [year, month, day].join('-');

  if (isSafari()) {
    time = time.split('-').join('/'); // 把'-'转成'/' yyyy/mm/dd
  } // 是否返回无符号的日期格式


  if (pure) {
    time = year + month + day;
  }

  return time;
}; // formatDate的别名函数


exports.formatDate = formatDate;

var formatDateAlias = function formatDateAlias(strDate, pure) {
  return formatDate(strDate, pure);
};
/**
 * 获取两个日期中间的天数
 * @param begin 开始日期
 * @param end 结束日期
 * @param disableWeekDays 屏蔽周的日期
 */


exports.formatDateAlias = formatDateAlias;

var getAllDays = function getAllDays(begin, end, disableWeekDays) {
  var arr = [];
  var ab = begin.split(isSafari() ? '/' : '-');
  var ae = end.split(isSafari() ? '/' : '-');
  var db = new Date();
  db.setUTCFullYear(ab[0], ab[1] - 1, ab[2]);
  var de = new Date();
  de.setUTCFullYear(ae[0], ae[1] - 1, ae[2]);
  var unixDb = db.getTime() - 24 * 60 * 60 * 1000;
  var unixDe = de.getTime() - 24 * 60 * 60 * 1000;

  for (var k = unixDb; k <= unixDe;) {
    k = k + 24 * 60 * 60 * 1000;
    var dateItem = formatDate(new Date(parseInt(k, 10)));

    if (Date.parse(dateItem) !== Date.parse(begin) && Date.parse(dateItem) !== Date.parse(end)) {
      if (disableWeekDays) {
        if (!disableWeekDays.includes(dateItem)) {
          arr.push(dateItem);
        }
      } else {
        arr.push(dateItem);
      }
    }
  }

  return arr;
}; // 返回两个数组的不同项


exports.getAllDays = getAllDays;

var differenceArray = function differenceArray(arrA, arrB) {
  return arrA.concat(arrB).filter(function (value, index, arr) {
    return arr.indexOf(value) === arr.lastIndexOf(value);
  });
}; // 数组去重


exports.differenceArray = differenceArray;

var uniqueArray = function uniqueArray(arr) {
  return Array.from(new Set(arr));
};
/**
 * 获取选中日期当前一周内所有其他日期
 * @param date 当前日期
 * @param firstDayOfWeek 从周几开始
 * @param endDayOfWeek 从周几结束
 * @param disableWeekDays 屏蔽周的日期
 */


exports.uniqueArray = uniqueArray;

var getCurrentWeekDays = function getCurrentWeekDays(date, firstDayOfWeek, endDayOfWeek, disableWeekDays) {
  if (firstDayOfWeek > 7 || firstDayOfWeek < -7) {
    // 超出边界恢复默认值
    firstDayOfWeek = 1;
  }

  var currentDate = new Date(date);
  var timesStamp = currentDate.getTime();
  var dayOfWeek = currentDate.getDay();
  var currenDay = dayOfWeek === 0 ? 7 : dayOfWeek; // 0 周日

  var dates = [];
  var startWeek = firstDayOfWeek;
  var endWeek = endDayOfWeek; // 有endDayOfWeek字段默认去掉从周几开始

  if (endDayOfWeek) {
    // 恢复默认值
    if (endWeek < startWeek) {
      startWeek = 1;
      endWeek = 7;
    }

    for (var i = 0; i < 7; i++) {
      if (currenDay >= startWeek && currenDay <= endWeek) {
        var dateItem = formatDate(new Date(timesStamp + 24 * 60 * 60 * 1000 * (i - (currenDay + 6) % 7)).toLocaleDateString());

        if (disableWeekDays && disableWeekDays.length > 0) {
          if (!disableWeekDays.includes(dateItem)) {
            dates.push(formatDate(dateItem));
          }
        } else {
          dates.push(formatDate(dateItem));
        }
      }
    }
  } else {
    // 没有endDayOfWeek字段，执行firstDayOfWeek到firstDayOfWeek+6 时间范围
    firstDayOfWeek = firstDayOfWeek < 0 ? firstDayOfWeek : firstDayOfWeek - 7;
    startWeek = firstDayOfWeek;
    endWeek = firstDayOfWeek + 6;

    for (var _i = startWeek - 1; _i < endWeek; _i++) {
      var offset = _i - (currenDay + 6) % 7; // 超出结束日期推算到下周

      if (currenDay > endWeek) {
        offset = 7 - currenDay + 1 + _i;
      }

      var _dateItem = formatDate(new Date(timesStamp + 24 * 60 * 60 * 1000 * offset).toLocaleDateString());

      if (disableWeekDays && disableWeekDays.length > 0) {
        if (!disableWeekDays.includes(_dateItem)) {
          dates.push(formatDate(_dateItem));
        }
      } else {
        dates.push(formatDate(_dateItem));
      }
    }
  }

  return dates;
}; // 获取传入日期是当年年份第几周
// 如果今年1月前几天是去年12月的最后一周，按今年的第一周算


exports.getCurrentWeekDays = getCurrentWeekDays;

var getWeekOfDayByDateNoConfig = function getWeekOfDayByDateNoConfig(date) {
  var nowDate = new Date(formatDate(date));
  var currenDay = nowDate.getDay();
  var weekOfDay = (currenDay + 6) % 7; // 算出这周的周几

  var weekOfDays = 7 - weekOfDay - 1;
  var temptTime = new Date(date); // 周几

  var weekday = temptTime.getDay() || 7; // 周1+5天=周六

  temptTime.setDate(temptTime.getDate() - weekday + 1 + 5);
  var firstDay = new Date(temptTime.getFullYear(), 0, 1);
  var dayOfWeek = firstDay.getDay();
  var spendDay = 1;

  if (dayOfWeek !== 0) {
    spendDay = 7 - dayOfWeek + 1;
  }

  firstDay = new Date(temptTime.getFullYear(), 0, 1 + spendDay);
  var d = Math.ceil((temptTime.valueOf() - firstDay.valueOf()) / 86400000);
  var week = Math.ceil(d / 7) + 1;
  var startDate = nowDate.setDate(nowDate.getDate() + weekOfDays);
  var year = new Date(startDate).getFullYear();
  return {
    year: year,
    week: week
  };
}; // 处理自定义起止日期所在年份和周


exports.getWeekOfDayByDateNoConfig = getWeekOfDayByDateNoConfig;

var getWeekOfYearByDate = function getWeekOfYearByDate(date, firstDayOfWeek, endDayOfWeek) {
  var _getWeekOfDayByDateNo = getWeekOfDayByDateNoConfig(date),
      year = _getWeekOfDayByDateNo.year,
      week = _getWeekOfDayByDateNo.week;

  if (!endDayOfWeek) {
    // 获取高亮部分日期数组
    var highLightDays = getCurrentWeekDays(date, firstDayOfWeek, endDayOfWeek);

    if (highLightDays.length > 0) {
      var firstDay = highLightDays[0]; // 高亮部分左边界

      var lastDay = highLightDays[highLightDays.length - 1]; // 高亮部分右边界

      var _getWeekOfDayByDateNo2 = getWeekOfDayByDateNoConfig(firstDay),
          yearOfFirstDay = _getWeekOfDayByDateNo2.year,
          weekOfFirstDay = _getWeekOfDayByDateNo2.week;

      var _getWeekOfDayByDateNo3 = getWeekOfDayByDateNoConfig(lastDay),
          yearOfLastDay = _getWeekOfDayByDateNo3.year,
          weekOfLastDay = _getWeekOfDayByDateNo3.week; // 如果高亮的边界在当前选择日期所在年最后一天的时间范围内，取左边界；如果超出到下一年，取右边界


      if (new Date(lastDay).getTime() < new Date(new Date(date).getFullYear(), 12, 31).getTime()) {
        return "".concat(yearOfFirstDay || '', "-").concat(weekOfFirstDay || '');
      }

      return "".concat(yearOfLastDay || '', "-").concat(weekOfLastDay || '');
    }
  }

  return "".concat(year || '', "-").concat(week || '');
}; // 处理自定义日期所在年份和月


exports.getWeekOfYearByDate = getWeekOfYearByDate;

var getMonthOfYearByDate = function getMonthOfYearByDate(date) {
  if (!date) return '';
  return "".concat(new Date(date).getFullYear() || '', "-").concat(addZero(new Date(date).getMonth() + 1) || '');
};
/**
 * 获取选中日期前n周的所有天数
 * @param startDate 开始日期
 * @param endDate 结束日期
 * @param start 从周几开始
 * @param end 从周几结束
 * @param hideAll 是否返回包含起止日期和截止日期所在的所有天数
 * @param disableWeekDays 屏蔽周的日期
 *
 */


exports.getMonthOfYearByDate = getMonthOfYearByDate;

var getRangeWeekDays = function getRangeWeekDays(startDate, endDate, start, end, hideAll, disableWeekDays) {
  var startWeekDays = getCurrentWeekDays(formatDate(new Date(startDate)), start, end, disableWeekDays);
  var endWeekDays = getCurrentWeekDays(formatDate(new Date(endDate)), start, end, disableWeekDays);
  var rangeWeekDays = getAllDays(formatDate(startWeekDays[startWeekDays.length - 1]), formatDate(endWeekDays[0]), disableWeekDays);
  return hideAll ? rangeWeekDays : startWeekDays.concat(rangeWeekDays).concat(endWeekDays);
};
/**
 * 获取需要屏蔽隐藏的天数
 * @param startDate 开始日期
 * @param endDate 结束日期
 * @param firstDayOfWeek 从周几开始
 * @param endDayOfWeek 从周几结束
 *
 */


exports.getRangeWeekDays = getRangeWeekDays;

var getHideWeekDays = function getHideWeekDays(startDate, endDate, firstDayOfWeek, endDayOfWeek) {
  var hideWeekDays = [];
  var rangeWeekDays = getRangeWeekDays(startDate, endDate, firstDayOfWeek, endDayOfWeek);
  rangeWeekDays.map(function (item) {
    var dayOfWeek = new Date(item).getDay();
    var currentDay = dayOfWeek === 0 ? 7 : dayOfWeek; // 0 周日

    var hideWeek = endDayOfWeek >= firstDayOfWeek && (currentDay > endDayOfWeek || currentDay < firstDayOfWeek);

    if (hideWeek) {
      hideWeekDays.push(item);
    }
  });
  return hideWeekDays;
}; // 获取前n周的天数


exports.getHideWeekDays = getHideWeekDays;

var getLastWeekByNumber = function getLastWeekByNumber(date, n) {
  return formatDate(new Date(date).getTime() + n * 7 * 24 * 3600 * 1000);
};

exports.getLastWeekByNumber = getLastWeekByNumber;
var _default = {
  addZero: addZero,
  toInt: toInt,
  getStringTimeByData: getStringTimeByData,
  getRowDataByTimeList: getRowDataByTimeList,
  isNotEqualByObject: isNotEqualByObject,
  compareDate: compareDate,
  addResize: addResize,
  removeResize: removeResize,
  throttle: throttle,
  getTwoDateDifference: getTwoDateDifference,
  addMonths: addMonths,
  timeInRange: timeInRange,
  formatDateString: formatDateString,
  getElementTop: getElementTop,
  getElementViewTop: getElementViewTop,
  uniqueArray: uniqueArray,
  differenceArray: differenceArray,
  isSafari: isSafari,
  formatDate: formatDate,
  formatDateAlias: formatDateAlias,
  getCurrentWeekDays: getCurrentWeekDays,
  getWeekOfDayByDateNoConfig: getWeekOfDayByDateNoConfig,
  getWeekOfYearByDate: getWeekOfYearByDate,
  getMonthOfYearByDate: getMonthOfYearByDate,
  getAllDays: getAllDays,
  getRangeWeekDays: getRangeWeekDays,
  getHideWeekDays: getHideWeekDays,
  getLastWeekByNumber: getLastWeekByNumber
};
exports["default"] = _default;