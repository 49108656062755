"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var _default = function _default() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var status = props.status,
      progress = props.progress,
      setUploader = props.setUploader,
      onDelete = props.onDelete,
      onPreview = props.onPreview,
      canDelete = props.canDelete,
      _props$id = props.id,
      id = _props$id === void 0 ? '' : _props$id,
      style = props.style,
      _props$value = props.value,
      value = _props$value === void 0 ? {} : _props$value,
      ActionComponent = props.ActionComponent,
      _props$ActionComponen = props.ActionComponentProps,
      ActionComponentProps = _props$ActionComponen === void 0 ? {} : _props$ActionComponen,
      triggerUpload = props.triggerUpload,
      FooterComponent = props.FooterComponent,
      _props$FooterComponen = props.FooterComponentProps,
      FooterComponentProps = _props$FooterComponen === void 0 ? {} : _props$FooterComponen,
      DisplayComponent = props.DisplayComponent,
      _props$retry = props.retry,
      retry = _props$retry === void 0 ? false : _props$retry,
      _props$disabled = props.disabled,
      disabled = _props$disabled === void 0 ? false : _props$disabled,
      useNewUI = props.useNewUI,
      _props$onlyActionComp = props.onlyActionComponent,
      onlyActionComponent = _props$onlyActionComp === void 0 ? true : _props$onlyActionComp;
  var isError = status === 'fail' || status === 'error';

  var retryClick = function retryClick() {};

  var retryClassName = '';
  var successAction = null;

  if (retry && (isError || status === 'success') && !disabled) {
    retryClick = triggerUpload;
    retryClassName = 'with-retry';
  }

  if (status === 'success') {
    if (typeof ActionComponent !== 'undefined') {
      successAction = _react["default"].createElement(ActionComponent, _extends({
        onPreview: onPreview
      }, ActionComponentProps, {
        className: "spaui-upmedia-action",
        triggerUpload: triggerUpload,
        value: value,
        onDelete: onDelete
      }));
    } else {
      successAction = _react["default"].createElement("div", {
        className: "spaui-upmedia-action"
      }, _react["default"].createElement("a", {
        role: "button",
        className: "spaui-upmedia-preview",
        onClick: onPreview
      }), typeof canDelete === 'undefined' || canDelete ? _react["default"].createElement("a", {
        role: "button",
        className: "spaui-upmedia-remove",
        onClick: onDelete
      }) : null);
    }
  }

  return _react["default"].createElement("div", {
    className: "spaui-upmedia-inner",
    id: id,
    style: style
  }, _react["default"].createElement("div", {
    className: "spaui-upmedia-media"
  }, _react["default"].createElement(DisplayComponent, _extends({}, props, {
    data: value
  }))), // 失败显示的结构
  isError ? _react["default"].createElement("div", {
    className: (0, _classnames["default"])('spaui-upmedia-status', retryClassName),
    onClick: retryClick
  }, _react["default"].createElement("i", {
    className: "spaui-upmedia-icon"
  }), _react["default"].createElement("span", {
    className: "spaui-upmedia-label"
  }, value.label || '上传失败')) : null, // 失败显示的操作
  isError && (typeof canDelete === 'undefined' || canDelete) ? _react["default"].createElement("div", {
    className: "spaui-upmedia-action"
  }, _react["default"].createElement("a", {
    role: "button",
    className: "spaui-upmedia-remove",
    onClick: onDelete
  })) : null, // 上传中
  status === 'uploading' ? _react["default"].createElement("div", {
    className: "spaui-upmedia-status"
  }, value.label ? _react["default"].createElement("span", {
    className: "spaui-upmedia-label"
  }, value.label) : null, value.status === 'uploading' ? _react["default"].createElement("progress", {
    className: "spaui-upmedia-progress",
    value: progress,
    max: "100"
  }) : null) : null, // 上传中
  status === 'loading' ? _react["default"].createElement("div", {
    className: "spaui-upmedia-status"
  }, value.status === 'loading' ? _react["default"].createElement("i", {
    className: "spaui-upmedia-icon"
  }) : null) : null, // 上传成功自定义组件
  status === 'success' && typeof ActionComponent !== 'undefined' && onlyActionComponent ? _react["default"].createElement(ActionComponent, _extends({
    onPreview: onPreview
  }, ActionComponentProps, {
    className: "spaui-upmedia-action",
    triggerUpload: triggerUpload,
    value: value,
    onDelete: onDelete
  })) : null, successAction, // 上传成功尾部组件
  status === 'success' && typeof FooterComponent !== 'undefined' ? _react["default"].createElement(FooterComponent, _extends({}, FooterComponentProps, {
    triggerUpload: triggerUpload,
    className: "spaui-upmedia-footer",
    value: value,
    onDelete: onDelete
  })) : null, // 上传成功且支持上传
  (status === 'success' || retry && isError) && setUploader ? setUploader(_react["default"].createElement("span", null)) : null, // 可以删除
  !useNewUI && canDelete ? _react["default"].createElement("a", {
    className: "spaui-upmedia-del",
    onClick: onDelete
  }, _react["default"].createElement("i", {
    className: "spaui-upmedia-icon-del"
  })) : null);
};

exports["default"] = _default;