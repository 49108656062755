"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
var EmptyComponent = function EmptyComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var label = props.label,
      _props$iconClassName = props.iconClassName,
      iconClassName = _props$iconClassName === void 0 ? 'icon-selection-guide' : _props$iconClassName,
      _props$type = props.type,
      type = _props$type === void 0 ? '' : _props$type;

  if (!label) {
    return null;
  }

  return _react["default"].createElement("div", {
    className: "user-picker-body spaui-userpicker-body spaui-empty"
  }, _react["default"].createElement("i", {
    className: "icon ".concat(iconClassName)
  }), _react["default"].createElement("div", {
    className: "spaui-empty-inner"
  }, _react["default"].createElement("p", {
    className: "spaui-empty-desc"
  }, label)), type === 'selection-list' ? _react["default"].createElement("div", {
    className: "spaui-empty-footer icon icon-selection-line"
  }) : null);
};

var _default = EmptyComponent;
exports["default"] = _default;