"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultProps = exports.propTypes = exports.config = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件参数配置
 */

/**
 * Watermark 接口参数
 * @alias WatermarkProps
 * @description 设置区域水印的组件。
 * @property {titleline} 默认支持参数
 * @property {blankline} className;style;id;data-*;accessKey;dir;draggable;tabIndex;title;htmlRef;
 * @property {titleline} 基础属性
 * @property {string} [url=''] 可以通过图片地址或者通过style.backgroundImage自行设置水印图片
 * @property {array} [text=[{color{文本颜色}, font{字体样式}, content{字体内容}, rotate{旋转角度}}] 可以通过传入指定文本字段绘制水印, 与url属性不可同时使用
 * @property {string} [style=''] 组件样式
 */
var config = {
  url: {
    type: _spauiUtils.PropTypes.string.isRequired,
    defaultValue: ''
  },
  text: {
    type: _spauiUtils.PropTypes.Array
  },
  style: {
    type: _spauiUtils.PropTypes.Object
  }
}; // 转化成PureComponent组件的配置

exports.config = config;
var configData = (0, _spauiUtils.formatProps)(config); // 导出

var propTypes = configData.propTypes;
exports.propTypes = propTypes;
var defaultProps = configData.defaultProps;
exports.defaultProps = defaultProps;
var _default = {
  config: config,
  propTypes: propTypes,
  defaultProps: defaultProps
};
exports["default"] = _default;