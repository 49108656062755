"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _spauiUtils = require("@tencent/spaui-utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

var ItemComponent = function ItemComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var className = props.className,
      _props$type = props.type,
      type = _props$type === void 0 ? '' : _props$type,
      _props$href = props.href,
      href = _props$href === void 0 ? '' : _props$href,
      _onClick = props.onClick,
      _props$iconClassName = props.iconClassName,
      iconClassName = _props$iconClassName === void 0 ? '' : _props$iconClassName,
      _props$nodeData = props.nodeData,
      nodeData = _props$nodeData === void 0 ? {} : _props$nodeData,
      label = props.label,
      _props$shortLabel = props.shortLabel,
      shortLabel = _props$shortLabel === void 0 ? '' : _props$shortLabel,
      _props$data = props.data,
      data = _props$data === void 0 ? [] : _props$data,
      _props$dataAttribute = props.dataAttribute,
      dataAttribute = _props$dataAttribute === void 0 ? {} : _props$dataAttribute,
      IconComponent = props.IconComponent,
      ArrowComponent = props.ArrowComponent,
      itemKey = props.itemKey,
      itemHref = props.itemHref,
      value = props.value,
      LinkComponent = props.LinkComponent,
      inPortal = props.inPortal,
      _props$selected = props.selected,
      selected = _props$selected === void 0 ? false : _props$selected,
      _props$classNameMap = props.classNameMap,
      classNameMap = _props$classNameMap === void 0 ? {} : _props$classNameMap;

  var menuComponentProps = props.menuComponentProps,
      otherProps = _objectWithoutProperties(props, ["menuComponentProps"]);

  var _nodeData$icon = nodeData.icon,
      icon = _nodeData$icon === void 0 ? null : _nodeData$icon,
      _nodeData$target = nodeData.target,
      target = _nodeData$target === void 0 ? '' : _nodeData$target;
  var haveArrow = data.length > 0;
  var aProps = {
    onClick: _onClick,
    className: className
  };
  var iconDom = null;

  if (href) {
    aProps.href = href;
  }

  if (target) {
    aProps.target = target;
  }

  if (type === 'normal' || href) {
    aProps.className = "".concat(aProps.className, " spaui-cursor-pointer");
  } // 如果数据带了icon，以数据的为优先


  if (icon) {
    // 如果是Icon组件的svg图标，要加多一个标签
    if (_typeof(icon) === 'object' && icon.props && icon.props.type === 'confirm' && icon.props.name) {
      iconDom = _react["default"].createElement("i", {
        className: classNameMap.MenuSvg
      }, icon);
    } else {
      iconDom = icon;
    } // 图标结构，支持jsx

  } else if (typeof IconComponent !== 'undefined' && !inPortal) {
    if ((0, _spauiUtils.isReactNode)(IconComponent)) {
      iconDom = IconComponent;
    } else {
      iconDom = _react["default"].createElement(IconComponent, {
        type: type,
        theKey: itemKey,
        href: itemHref,
        value: value,
        label: label,
        data: data,
        iconClassName: iconClassName,
        selected: selected
      });
    }
  }

  return _react["default"].createElement(LinkComponent, _extends({
    menuComponentProps: menuComponentProps,
    itemProps: otherProps
  }, aProps, dataAttribute), iconDom, _react["default"].createElement("span", {
    className: classNameMap.MenuText
  }, label), haveArrow ? _react["default"].createElement(ArrowComponent, {
    className: "".concat(classNameMap.MenuIcon, " ").concat(classNameMap.MenuArrow) // 如果有箭头且有链接，为避免有冲突，点击箭头的时候，不响应选中态
    ,
    onClick: function onClick(event) {
      event.preventDefault();
      event.stopPropagation();

      if ((0, _spauiUtils.isFunction)(_onClick)) {
        _onClick(event, true);
      }
    }
  }) : null, shortLabel ? _react["default"].createElement("span", {
    className: classNameMap.MenuMiniText
  }, shortLabel) : null);
};

var _default = ItemComponent;
exports["default"] = _default;