"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultProps = exports.propTypes = exports.config = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2020, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件参数配置
 */

/**
 * Drawer 接口参数
 * @alias DrawerProps
 * @property {titleline} 默认支持参数
 * @property {blankline} className;style;id;data-*;accessKey;dir;draggable;tabIndex;title;htmlRef;
 * @property {titleline} 基础属性
 * @property {bool} [escapeKeyClosable=true] 是否支持ESC关闭
 * @property {bool} [bodyScrollable=true] 是否保留body可滚动
 * @property {bool} [hideHeaderDivider=false] 是否隐藏头部间隔线
 * @property {bool} [hideFooter=false] 是否隐藏尾部
 * @property {bool} [hideFooterDivider=false] 是否隐藏尾部间隔线
 * @property {bool} [maskVisible=false] 是否显示遮罩
 * @property {bool} [maskClosable=false] 是否点击遮罩可关闭
 * @property {bool} [show=false] 是否可见
 * @property {string|element} [title] 标题
 * @property {string} [placement='right'] 方向*可选值：top, right, bottom, left*
 * @property {string} [size='medium'] 尺寸*可选值：mini, medium, large, small*
 * @property {string|number} [width] 宽度
 * @property {string|number} [height] 高度
 * @property {number} [zIndex] z-index 层级
 * @property {string} [submitLabel='确定'] 提交按钮文案
 * @property {string} [cancelLabel='取消'] 取消按钮文案
 * @property {object} [submitButtonProps={}] 确定按钮props参数
 * @property {object} [cancelButtonProps={}] 取消按钮props参数
 * @property {bool} [disabled=false] 是否禁用确定按钮
 * @property {bool} [loading=false] 确定按钮是否处理中
 * @property {func|element} [FooterComponent] 自定义尾部
 * @property {func|element} [FooterLeftComponent] 自定义尾部左侧
 * @property {func|element} [HeaderComponent] 自定义头部
 * @property {string|element} [headerContent] 头部内容
 * @property {object} [headerStyle] 头部样式
 * @property {string} [bodyClassName] body 额外类名
 * @property {object} [bodyStyle] body 额外样式
 * @property {bool} [destroyOnClose=true] 是否在关闭时销毁
 * @property {string} [leavedClassName='!spaui-hide'] 关闭不销毁时 mask 及 inner 元素上添加的类名
 * @property {func} [afterClose] 关闭后执行
 * @property {func} [onClose] 关闭事件
 * @property {func} [onSubmit] 确定按钮点击事件
 * @property {func} [onCancel] 取消按钮点击事件
 * @property {string|element} [AdditionComponent] 附加内容
 * @property {string} [maskMotionName] 遮罩动效名称
 * @property {string} [maskLeavedClassName] 关闭不销毁时，遮罩动效结束后添加的 className
 * @property {bool} [motionAppear] 是否有动画，默认true
 * @property {bool} [motionEnter] 是否有动画，默认true
 * @property {bool} [motionLeave] 是否有动画，默认true
 * @property {string} [contentMotionName] 内容动效名称
 * @property {string} [contentLeavedClassName] 关闭不销毁时，内容动效结束后添加的 className
 * @example
 *  尺寸可选值：mini 25vw~400,medium 70vw~1280,large 90vw~1440,small 40vw~960
 */
var config = {
  escapeKeyClosable: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  bodyScrollable: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  hideHeaderDivider: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  hideFooter: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  hideFooterDivider: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  show: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  maskVisible: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  maskClosable: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  title: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.element]),
    defaultValue: null
  },
  placement: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'right',
    visualData: {
      type: 'radio',
      description: '方向',
      value: ['right', 'bottom', 'left', 'top']
    }
  },
  submitLabel: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: '确定'
  },
  cancelLabel: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: '取消'
  },
  submitButtonProps: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  cancelButtonProps: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  disabled: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  loading: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  destroyOnClose: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  leavedClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: '!spaui-hide'
  },
  size: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'medium',
    visualData: {
      type: 'radio',
      description: '尺寸',
      value: ['medium', 'small', 'large', 'mini']
    }
  },
  width: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number]),
    defaultValue: null
  },
  height: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number]),
    defaultValue: null
  },
  zIndex: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: null
  },
  FooterComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: null
  },
  FooterLeftComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: null
  },
  HeaderComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element])
  },
  headerContent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.element]),
    defaultValue: null
  },
  headerStyle: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  bodyStyle: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  bodyClassName: {
    type: _spauiUtils.PropTypes.string
  },
  otherProps: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  afterClose: {
    type: _spauiUtils.PropTypes.func
  },
  onClose: {
    type: _spauiUtils.PropTypes.func
  },
  onSubmit: {
    type: _spauiUtils.PropTypes.func
  },
  onCancel: {
    type: _spauiUtils.PropTypes.func
  },
  AdditionComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: null
  },
  maskMotionName: {
    type: _spauiUtils.PropTypes.string
  },
  maskLeavedClassName: {
    type: _spauiUtils.PropTypes.string
  },
  motionAppear: {
    type: _spauiUtils.PropTypes.bool
  },
  motionEnter: {
    type: _spauiUtils.PropTypes.bool
  },
  motionLeave: {
    type: _spauiUtils.PropTypes.bool
  },
  contentMotionName: {
    type: _spauiUtils.PropTypes.string
  },
  contentLeavedClassName: {
    type: _spauiUtils.PropTypes.string
  },
  onBodyScroll: {
    type: _spauiUtils.PropTypes.func
  }
}; // 转化成PureComponent组件的配置

exports.config = config;
var configData = (0, _spauiUtils.formatProps)(config); // 导出

var propTypes = configData.propTypes;
exports.propTypes = propTypes;
var defaultProps = configData.defaultProps;
exports.defaultProps = defaultProps;
var _default = {
  config: config,
  propTypes: propTypes,
  defaultProps: defaultProps
};
exports["default"] = _default;