"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultProps = exports.propTypes = exports.config = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

var _link_component = _interopRequireDefault(require("./component/link_component"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件参数配置
 */

/**
 * DropMenu 接口参数
 * @alias DropMenuProps
 * @description 向下弹出的列表。
 * @property {titleline} 默认支持参数
 * @property {blankline} className;style;id;data-*;accessKey;dir;draggable;tabIndex;title;htmlRef;
 * @property {string} [className=''] className='with-checkmark'时样式上选中带勾
 * @property {titleline} 基础属性
 * @property {object} value={} 选中的值
 * @property {array} data=[]] 数据
 * @property {func} onChange 数据变更回调<br />onChange(event, value, itemValue, name)*itemValue 为此次点击项的数据*
 * @property {string} [title=''] 标题提示
 * @property {string} [titleClassName=''] title选项li的className
 * @property {string} [titleItemClassName=''] title选项li的className
 * @property {string} [preContent=''] 前置内容
 * @property {string} [postContent=''] 后置内容
 * @property {string} [single=false] 是否只能单选一类（只适用于非多选）
 * @property {bool} [multiple=false] 是否可以多选
 * @property {bool} [disabled=false] 是否禁用
 * @property {bool} [show] 是否展示
 * @property {string|element} [footer=''] 提示
 * @property {string} [footerItemClassName=''] footer选项li的className
 * @property {func} [setItemClassName=noop] 根据选项数据设置li的className*setItemClassName = itemData => return className*
 * @property {element|func} [LinkComponent] 自定义点击按钮的结构内容*(具体参数看案例)*
 * @property {object} [fieldNames={}] 对应数据的节点字段名
 * @property {titleline} 组件属性
 * @property {object} [LinkComponentProps={}] LinkComponent 组件的 props
 * @property {titleline} 其他属性
 * @property {number|string} [width] 菜单宽度
 * @property {bool} [horizontal] 是否水平放置
 * @see
 *
 * onChange typings:
 *     (event: any, result: any, itemValue: any) => any
 * @example
 *
 * // 默认fieldNames
 * fieldNames = {
    labelFieldName: 'label',
    valueFieldName: 'value',
    nodeFieldName: 'list',
    titleFieldName: 'title',
    nameFieldName: 'name'
}
 *
 * data = [{
 *      name: 'buy',
 *      title: '购买类型',
 *      list: [{
 *          value: 1,
 *          label: '竞价',
 *          disabled: false
 *      }]
 * }];
 *
 * value = {
 *      buy: 1  // 多选就是buy: [1]
 * }
 *
 * &lt;LinkComponent
 name={name}
 value={nameValue}
 data={subItem}
 className={className}
 onClick={(event) => {}}
 &gt;{children}&lt;/LinkComponent&gt;
 *
 */
var config = {
  value: {
    // type: PropTypes.object.isRequired,
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.object, _spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number]).isRequired,
    defaultValue: {}
  },
  data: {
    type: _spauiUtils.PropTypes.array.isRequired,
    defaultValue: []
  },
  onChange: {
    type: _spauiUtils.PropTypes.func
  },
  title: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  titleClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  titleItemClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  preContent: {
    type: _spauiUtils.PropTypes.any,
    defaultValue: ''
  },
  postContent: {
    type: _spauiUtils.PropTypes.any,
    defaultValue: ''
  },
  single: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  multiple: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  disabled: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  show: {
    type: _spauiUtils.PropTypes.bool
  },
  footer: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: ''
  },
  footerItemClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  setItemClassName: {
    type: _spauiUtils.PropTypes.func,
    defaultValue: function defaultValue() {
      return '';
    }
  },
  LinkComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _link_component["default"]
  },
  fieldNames: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  LinkComponentProps: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  width: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.number, _spauiUtils.PropTypes.string])
  }
}; // 转化成PureComponent组件的配置

exports.config = config;
var configData = (0, _spauiUtils.formatProps)(config); // 导出

var propTypes = configData.propTypes;
exports.propTypes = propTypes;
var defaultProps = configData.defaultProps;
exports.defaultProps = defaultProps;
var _default = {
  config: config,
  propTypes: propTypes,
  defaultProps: defaultProps
};
exports["default"] = _default;