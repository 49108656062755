"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
var compactProps = function compactProps() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var canDelete = typeof props.deleteable !== 'undefined' ? props.deleteable : props.canDelete;
  return {
    canDelete: canDelete
  };
};

var _default = compactProps;
exports["default"] = _default;