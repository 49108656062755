"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultProps = exports.propTypes = exports.config = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

var _display_text = _interopRequireDefault(require("./component/display_text"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件参数配置
 */

/**
 * 属性说明
 * @alias ButtonGroupProps
 * @description 按钮组用于开始多个即时操作。
 * @property {titleline} 默认支持参数
 * @property {blankline} className;style;id;data-*;accessKey;dir;draggable;tabIndex;title;htmlRef;
 * @property {titleline} 基础属性
 * @property {string} [theme="normal"] 主题色*可选值: normal, light*
 * @property {array} [data=[]] 对应的按钮选项数据（有值的时候会忽略children，改成向Button透传数据）
 * @property {bool} [vertical=false] 是否垂直
 * @property {bool} [undisabledInFront=false] 非禁用是否放置在前排（只对data参数有效）
 * @property {titleline} 选项切换属性
 * @property {bool} [show=true] 是否展示
 * @property {bool} [disabled=false] 是否禁用
 * @property {string} [value=''] 选中的按钮
 * @property {bool} [multiple=false] 是否多选
 * @property {number} [numberOfRow] 每行数量（设置后会为各个边角加上对应的className，必须大于1才有效）
 * @property {bool} [autoFillRow=false] 是否自动补充行数据（必须开启numberOfRow）
 * @property {bool} [turnTipIntoDisplayText=false] 如果每个Button的按钮tip有值且一样就转化成文案展示
 * @property {function} [DisplayTextComponent] 当turnTipIntoDisplayText为true且有值时展示内容的组件
 * @property {function} [LabelComponent] 通过 ButtonGroup 自定义 Button 组件的展示内容
 * @property {func} [onChange] 点击回调<br /> onChange=(event, value, buttonProps)
 * @property {string} [activeDisplayType='primary'] 点击触发焦点的样式<br />对应spaui-button的displayType
 * @property {object} [ButtonProps={}] 可用于传公共的Button属性(使用data时可用)
 * @property {object} [classNameMap={}] 组件内对应结构的默认className(可以针对对应位置进行替换,具体参数见案例)
 * @property {string} [bsClass='spaui-button-group'] 默认的类名
 * @property {bool} [hasSpan=false] 文案是否有span标签包裹
 *
 * @see
 * onChange typings:
 *     (event: any, value: any, buttonProps: any) => any
 *
 * @example
 * classNameMap配置可见于：
 * https://git.woa.com/ads/spaui-basic_ui_components/tree/master/src/spaui-buttongroup/src/js/config/classname_map.js
 *
 * DisplayTextComponent = (props) => {
 *  return props.children
 * }
 *
 * LabelComponent = (props) => {
 *  return props.children
 * }
 */
var config = {
  data: {
    type: _spauiUtils.PropTypes.array
  },
  theme: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  value: {
    type: _spauiUtils.PropTypes.any,
    defaultValue: ''
  },
  disabled: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  show: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  multiple: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  numberOfRow: {
    type: _spauiUtils.PropTypes.number
  },
  autoFillRow: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  onChange: {
    type: _spauiUtils.PropTypes.func
  },
  activeDisplayType: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'primary'
  },
  ButtonProps: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  vertical: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  undisabledInFront: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  justified: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  block: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  turnTipIntoDisplayText: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  bsClass: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'spaui-button-group'
  },
  classNameMap: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  needButtonWrap: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  DisplayTextComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.element, _spauiUtils.PropTypes.func]),
    defaultValue: _display_text["default"]
  },
  LabelComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.element, _spauiUtils.PropTypes.func])
  },
  hasSpan: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  }
}; // 转化成PureComponent组件的配置

exports.config = config;
var configData = (0, _spauiUtils.formatProps)(config); // 导出

var propTypes = configData.propTypes;
exports.propTypes = propTypes;
var defaultProps = configData.defaultProps;
exports.defaultProps = defaultProps;
var _default = {
  config: config,
  propTypes: propTypes,
  defaultProps: defaultProps
};
exports["default"] = _default;