"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

var _filter = _interopRequireDefault(require("./utils/filter"));

var _autocomplete_props = require("./autocomplete_props");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

// 兼容props，因为有些名称被废弃了但是要兼容
var _default = function _default() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var clearable = props.clearable,
      clear = props.clear,
      filterable = props.filterable,
      propsFilter = props.filter,
      htmlable = props.htmlable,
      html = props.html,
      onSubmit = props.onSubmit,
      onClickSearchIcon = props.onClickSearchIcon,
      _props$fieldNames = props.fieldNames,
      propsFieldNames = _props$fieldNames === void 0 ? {} : _props$fieldNames;
  var filter = typeof filterable !== 'undefined' ? filterable : propsFilter; // 如果为true，但不是函数，就用默认筛选函数替换

  if (filter && !(0, _spauiUtils.isFunction)(filter)) {
    filter = _filter["default"];
  }

  return _objectSpread({}, props, {
    fieldNames: _objectSpread({}, _autocomplete_props.defaultFieldNames, {}, propsFieldNames),
    clear: typeof clearable !== 'undefined' ? clearable : clear,
    filter: filter,
    html: typeof htmlable !== 'undefined' ? htmlable : html,
    onClickSearchIcon: typeof onSubmit !== 'undefined' ? onSubmit : onClickSearchIcon
  });
};

exports["default"] = _default;