"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultProps = exports.propTypes = exports.config = exports.defaultFieldNames = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

var _emphasize = _interopRequireDefault(require("./utils/emphasize"));

var _value_area_component = _interopRequireDefault(require("./component/value_area_component"));

var _input_component = _interopRequireDefault(require("./component/input_component"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件参数配置
 */
var defaultFieldNames = {
  valueFieldName: 'value',
  labelFieldName: 'label'
};
/**
 * AutoComplete 接口参数
 * @alias AutoCompleteProps
 * @description 输入框自动完成功能。
 * @property {titleline} 默认支持参数
 * @property {blankline} className;style;id;data-*;accessKey;dir;draggable;tabIndex;title;htmlRef;
 * @property {titleline} 普通输入框属性
 * @property {string} [size] 尺寸*可选值: default(默认), small(小尺寸)*
 * @property {string|number} value='' 输入框的内容
 * @property {func} onChange 输入内容回调函数*onChange=(event, value, valueData)\nvalue:输入框的值;valueData:value对应的data数据*
 * @property {string} [placeholder='请输入'] 输入框没有内容时的提示文案
 * @property {bool} [disabled=false] 是否禁用
 * @property {bool} [focus=false] 是否处于焦点
 * @property {bool} [clear=true] 是否可清空*(旧名称为clearable)*
 * @property {func} [onClear] 清空回调*onClear = (event)\n event: 响应事件*
 * @property {func} [onKeyPress] 输入框键盘监听*onKeyPress=(event, selectedValue = [])\nseletedValue: 输入框内容按照delimiter拆分的数据*
 * @property {object} [classNameMap={}] 组件内对应结构的默认className(可以针对对应位置进行替换,具体参数见案例)
 * @property {titleline} 下拉补充选项相关属性
 * @property {array} [data=[]] 可选择的提示数据*(同Select组件的data)*
 * @property {string} [fieldNames={}] 原始数据字段映射*fieldNames={valueFieldName: 'value', labelFieldName: 'label'}*
 * @property {bool} [multiple=false] 是否多选
 * @property {bool|func} [filter=true] 单选时是否根据输入框内容进行筛选选项*(旧名称为filterable)*
 * @property {func} [onSelect] 选中数据时的回调函数*onSelect = (event, value, label, valueData)*
 * @property {any} [selectValue] 选中数据的值*（默认是通过value字段进行delimiter之后判断选中数据，如果不通过value可通过该字段进行选中数据的传递）*
 * @property {func} [emphasize] 对数据展示进行强调转化*（html=true默认搜索会高亮字符）\n emphasize = (item, props)*
 * @property {bool} [open] 是否展开选项
 * @property {func} [onOpen] 展开关闭选项时的回调函数*onOpen = (event, open)\n event: 响应事件; open: 是否打开 *
 * @property {func} [onClickSearchIcon] 点击搜索图标时的响应函数
 * @property {bool} [html=false] 是否支持data中的label进行html转化*(旧名称为htmlable)*
 * @property {string} [delimiter=','] 输入框多选时的数据分隔符
 * @property {func} [convertPYs] 拼音转化函数(@tencent/spa-pinyin方法)
 * @property {object} [SelectProps={}] 下拉提示模块的参数*（下拉提示模块为Select组件）*
 * @property {object} [inputProps={}] input组件的props
 * @property {object} [IconProps={}] input组件中的Icon图标的props
 * @property {titleline} 自定义组件相关属性
 * @property {func|element} [SearchIconComponent] 自定义搜索图标组件
 * @property {func|element} [MoreDetailComponent] 输入框后添加附加内容组件*(具体参数看案例)*
 * @property {func} [ValueAreaComponent] 输入框组件*(同Select，默认就是输入框Input)*
 * @property {title} 输入框suffix相关属性
 * @property {func|element} [suffix=""] 右侧扩展信息
 * @property {number} [suffixSpace=20] suffix的间隙宽度
 * @property {bool} [autoSuffixWidth=true] 自适应suffix的宽度
 *
 * @see
 * onChange typings:
 *     (event: any, value: string, valueData: any[]) => any
 *
 * ====
 *
 * onClear typings:
 *     (event: any) => any
 *
 * ====
 *
 * onSelect typings:
 *     (event: any, value: any, label: any, valueData: any) => any
 *
 * ====
 *
 * onOpen typings:
 *     (event: any, isOpen: any) => any
 *
 *
 * @example
 *
 * &lt;MoreDetailComponent
    name={name}
    placeholder={placeholder}
    value={value}
    disabled={disabled}
    clear={clear}
    onClear={onClear}
/&gt;
MoreDetailComponent = (&lt;div&gt;内容&lt;/div&gt;)
 */

exports.defaultFieldNames = defaultFieldNames;
var config = {
  size: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'default'
  },
  value: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number]).isRequired,
    defaultValue: ''
  },
  onChange: {
    type: _spauiUtils.PropTypes.func.isRequired
  },
  data: {
    type: _spauiUtils.PropTypes.array,
    defaultValue: []
  },
  fieldNames: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: defaultFieldNames
  },
  onSelect: {
    type: _spauiUtils.PropTypes.func
  },
  name: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  placeholder: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: '请输入'
  },
  disabled: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  multiple: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  focus: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  clear: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  open: {
    type: _spauiUtils.PropTypes.bool
  },
  onOpen: {
    type: _spauiUtils.PropTypes.func
  },
  onKeyPress: {
    type: _spauiUtils.PropTypes.func
  },
  onClear: {
    type: _spauiUtils.PropTypes.func
  },
  onClickSearchIcon: {
    type: _spauiUtils.PropTypes.func
  },
  convertPYs: {
    type: _spauiUtils.PropTypes.func
  },
  delimiter: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ','
  },
  filter: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.bool, _spauiUtils.PropTypes.func]),
    defaultValue: true
  },
  emphasize: {
    type: _spauiUtils.PropTypes.func,
    defaultValue: _emphasize["default"]
  },
  html: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  SelectProps: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  selectValue: {
    type: _spauiUtils.PropTypes.any
  },
  ValueAreaComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.element, _spauiUtils.PropTypes.func]),
    defaultValue: _value_area_component["default"]
  },
  InputComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.element, _spauiUtils.PropTypes.func]),
    defaultValue: _input_component["default"]
  },
  MoreDetailComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.element, _spauiUtils.PropTypes.func])
  },
  SearchIconComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.element, _spauiUtils.PropTypes.func])
  },
  classNameMap: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  inputProps: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  IconProps: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  suffix: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.element]),
    defaultValue: ''
  },
  suffixSpace: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: 10
  },
  autoSuffixWidth: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  clearPropagation: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  }
}; // 转化成PureComponent组件的配置

exports.config = config;
var configData = (0, _spauiUtils.formatProps)(config); // 导出

var propTypes = configData.propTypes;
exports.propTypes = propTypes;
var defaultProps = configData.defaultProps;
exports.defaultProps = defaultProps;
var _default = {
  defaultFieldNames: defaultFieldNames,
  config: config,
  propTypes: propTypes,
  defaultProps: defaultProps
};
exports["default"] = _default;