"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.boxSize = exports.SELECT_STATUS = exports.getLocaleData = exports.getWeekDayNames = void 0;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
var LOCALE = {
  Monday: '星期一',
  Tuesday: '星期二',
  Wednesday: '星期三',
  Thursday: '星期四',
  Friday: '星期五',
  Saturday: '星期六',
  Sunday: '星期日',
  week: '星期',
  time: '时间',
  unselected: '未选',
  selected: '已选',
  operationTip: '可拖动鼠标选择时间段',
  empty: '清空'
};

var getWeekDayNames = function getWeekDayNames() {
  var localeData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return [localeData.Monday, localeData.Tuesday, localeData.Wednesday, localeData.Thursday, localeData.Friday, localeData.Saturday, localeData.Sunday];
};

exports.getWeekDayNames = getWeekDayNames;

var getLocaleData = function getLocaleData() {
  var userLocaleData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread({}, LOCALE, {}, userLocaleData);
};
/**
 * 状态类型定义
 * @type {object}
 * @examplet
 * {SELECTED: number, UNSELECTED: number}
 */


exports.getLocaleData = getLocaleData;
var SELECT_STATUS = {
  SELECTED: 1,
  UNSELECTED: 0
};
exports.SELECT_STATUS = SELECT_STATUS;
var boxSize = {
  width: 13,
  height: 35
};
exports.boxSize = boxSize;
var _default = {
  SELECT_STATUS: SELECT_STATUS,
  getLocaleData: getLocaleData,
  getWeekDayNames: getWeekDayNames
};
exports["default"] = _default;