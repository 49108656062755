"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var HeaderComponent = function HeaderComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var closeButton = props.closeButton,
      onClose = props.onClose,
      _props$title = props.title,
      title = _props$title === void 0 ? '' : _props$title,
      _props$subTitle = props.subTitle,
      subTitle = _props$subTitle === void 0 ? '' : _props$subTitle,
      _props$headerAddition = props.headerAdditionalContent,
      headerAdditionalContent = _props$headerAddition === void 0 ? '' : _props$headerAddition,
      _props$type = props.type,
      type = _props$type === void 0 ? '' : _props$type,
      _props$iconType = props.iconType,
      iconType = _props$iconType === void 0 ? '' : _props$iconType,
      _props$closeButtonPro = props.closeButtonProps,
      closeButtonProps = _props$closeButtonPro === void 0 ? {} : _props$closeButtonPro,
      useNewUI = props.useNewUI,
      headerClassName = props.headerClassName,
      headerStyle = props.headerStyle,
      hideHeaderDivider = props.hideHeaderDivider,
      closeButtonClassName = props.closeButtonClassName;
  var iconDom = null;

  if (type === 'normal') {
    switch (iconType) {
      case 'success':
        iconDom = _react["default"].createElement("div", {
          className: "spaui-icon spaui-icon-big spaui-icon-big-success"
        });
        break;

      case 'tip':
        iconDom = _react["default"].createElement("div", {
          className: "spaui-icon spaui-icon-big spaui-icon-big-tip"
        });
        break;

      case 'warning':
        iconDom = _react["default"].createElement("div", {
          className: "spaui-icon spaui-icon-big spaui-icon-big-warning"
        });
        break;

      case 'error':
        iconDom = _react["default"].createElement("div", {
          className: "spaui-icon spaui-icon-big spaui-icon-big-error"
        });
        break;

      case 'remind':
        iconDom = _react["default"].createElement("div", {
          className: "spaui-icon spaui-icon-big spaui-icon-big-remind"
        });
        break;
    }
  }

  if (useNewUI) {
    return _react["default"].createElement("div", {
      className: (0, _classnames["default"])('spaui-dialog-header', headerClassName, {
        'no-divider': hideHeaderDivider,
        'has-subtitle': subTitle
      }),
      style: headerStyle
    }, title ? _react["default"].createElement("div", {
      className: "spaui-dialog-title"
    }, title) : null, subTitle || typeof subTitle === 'number' ? _react["default"].createElement("h5", {
      className: "spaui-dialog-subtitle"
    }, subTitle) : null, headerAdditionalContent);
  }

  return _react["default"].createElement("div", null, closeButton ? _react["default"].createElement("button", _extends({
    type: "button",
    className: (0, _classnames["default"])('close', closeButtonClassName),
    onClick: onClose
  }, closeButtonProps), _react["default"].createElement("span", {
    "aria-hidden": "true"
  }, "\xD7")) : null, title || typeof title === 'number' || iconType ? _react["default"].createElement("h4", {
    className: "modal-title"
  }, iconDom ? _react["default"].createElement("div", null, iconDom, title) : title) : null, subTitle || typeof subTitle === 'number' ? _react["default"].createElement("h5", {
    className: "modal-sub-title"
  }, subTitle) : null, headerAdditionalContent);
};

var _default = HeaderComponent;
exports["default"] = _default;