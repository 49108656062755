"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _spauiUtils = require("@tencent/spaui-utils");

var _get_node = require("../utils/get_node");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview loading
 */
var LoadingTbodyComponent = function LoadingTbodyComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var className = props.className,
      onClick = props.onClick,
      onMouseOut = props.onMouseOut,
      onMouseOver = props.onMouseOver,
      columns = props.columns,
      LoadingComponent = props.LoadingComponent,
      _props$TableProps = props.TableProps,
      TableProps = _props$TableProps === void 0 ? {} : _props$TableProps,
      htmlRef = props.htmlRef,
      _props$fixedLoadingCo = props.fixedLoadingComponent,
      fixedLoadingComponent = _props$fixedLoadingCo === void 0 ? false : _props$fixedLoadingCo;
  var loadingComponentInstance = null;

  if (typeof LoadingComponent !== 'undefined') {
    if ((0, _spauiUtils.isReactNode)(LoadingComponent)) {
      loadingComponentInstance = LoadingComponent;
    } else {
      loadingComponentInstance = _react["default"].createElement(LoadingComponent, TableProps);
    }
  }

  return _react["default"].createElement("tbody", null, (0, _get_node.getPreContent)(columns, TableProps), _react["default"].createElement("tr", {
    "data-spaui-table-tr": "1",
    className: className,
    onClick: onClick,
    onMouseOver: onMouseOut,
    onMouseOut: onMouseOver
  }, _react["default"].createElement("td", {
    colSpan: columns.length,
    "data-fixed": fixedLoadingComponent ? 1 : ''
  }, _react["default"].createElement("div", {
    ref: htmlRef
  }, loadingComponentInstance))));
};

var _default = LoadingTbodyComponent;
exports["default"] = _default;