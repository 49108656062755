"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _spauiUtils = require("@tencent/spaui-utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var LeftPanelComponent = function LeftPanelComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var panelClassName = props.panelClassName,
      searchComponent = props.searchComponent,
      titleComponent = props.titleComponent,
      data = props.data,
      EmptyComponent = props.EmptyComponent,
      optionalEmptyLabel = props.optionalEmptyLabel,
      checkAllComponent = props.checkAllComponent,
      setListRef = props.setListRef,
      ItemComponent = props.ItemComponent,
      getItemStatus = props.getItemStatus,
      InfoComponent = props.InfoComponent,
      ImgComponent = props.ImgComponent,
      disabled = props.disabled,
      searchValue = props.searchValue,
      convertPYs = props.convertPYs,
      onItemChange = props.onItemChange,
      ellipsisCount = props.ellipsisCount,
      addonContent = props.addonContent,
      asnycLoadComponent = props.asnycLoadComponent;
  return _react["default"].createElement("div", {
    className: panelClassName
  }, searchComponent, titleComponent, (0, _spauiUtils.isArray)(data) && data.length > 0 ? _react["default"].createElement("div", {
    className: "user-picker-body spaui-userpicker-body user-list"
  }, checkAllComponent, _react["default"].createElement("ul", {
    className: "feeds spaui-userpicker-feeds",
    ref: setListRef
  }, data.map(function () {
    var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var index = arguments.length > 1 ? arguments[1] : undefined;
    var status = getItemStatus(item);
    return _react["default"].createElement(ItemComponent, _extends({}, props, {
      type: "user-list",
      InfoComponent: InfoComponent,
      ImgComponent: ImgComponent,
      disabled: item.disabled || disabled,
      key: index,
      data: item,
      searchValue: searchValue,
      convertPYs: convertPYs,
      status: status,
      onClick: function onClick(event) {
        onItemChange(event, status, item);
      },
      ellipsisCount: ellipsisCount
    }));
  })), addonContent, asnycLoadComponent) : _react["default"].createElement(EmptyComponent, _extends({}, props, {
    type: "user-list",
    label: optionalEmptyLabel
  })));
};

var _default = LeftPanelComponent;
exports["default"] = _default;