"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultProps = exports.propTypes = exports.config = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件参数配置
 */

/**
 * Clipper 接口参数
 * @alias ClipperProps
 * @description 裁剪图片区域。
 * @property {titleline} 默认支持参数
 * @property {blankline} className;style;id;data-*;accessKey;dir;draggable;tabIndex;title;htmlRef;
 * @property {titleline} 基础属性
 * @property {object} data={} 初始化数据
 * @property {string} [src=''] 图片地址
 * @property {number} [clipWidth=0] 裁剪宽度
 * @property {number} [clipHeight=0] 裁剪高度
 * @property {number} [containerWidth=470] 裁剪容器宽度
 * @property {number} [containerHeight=450] 裁剪容器高度
 * @property {bool} [notScale=false] 是否可缩放
 * @property {bool} [limitMinScale=true] 是否限制最小缩放（最小缩放必须适应裁剪窗口）
 * @property {func} [setImagePosition] 设置调整图片的拖动范围
 * @property {number} [minScale=0] 最小缩放
 * @property {number} [maxScale=1] 最大缩放
 * @property {number} [perScalePixel=10] 缩放条每次变更多少像素
 * @property {number} [maxScaleTimes=20] 最大多少次缩放完
 * @property {func} onChange 切换状态回调函数<br />onChange = (event, data)
 * @property {boolean} initChange 是否初始化时响应change获取图片数据信息
 * @property {number} [previewWidth=0] 预览图片区域宽度
 * @property {func} [onPreviewData] 预览数据变更<br />onPreviewData = (event, data)
 * @example
 * setImagePosition = (config) => { X: 10, Y: 10 }
 * config = {
 *      // 以裁剪区域左上角、图片左上角为原点
        X: X,                                                   // 图片在裁剪区域的X坐标
        Y: Y,                                                   // 图片在裁剪区域的Y坐标
        width: this.width,                                      // 展示图宽
        height: this.height,                                    // 展示图高
        areaWidth: this.areaWidth,                              // 裁剪区域宽
        areaHeight: this.areaHeight,                            // 裁剪区域高
        clipWindowWidth: this.clipWindowWidth,                  // 裁剪视窗展示宽
        clipWindowHeight: this.clipWindowHeight,                // 裁剪视窗展示高
        clipWindowTopLeftX: this.clipWindowTopLeftX,            // 裁剪视窗在裁剪区域的位置
        clipWindowTopLeftY: this.clipWindowTopLeftY,            // 裁剪视窗在裁剪区域的位置
        clipWindowTopRightX: this.clipWindowTopRightX,          // 裁剪视窗在裁剪区域的位置
        clipWindowTopRightY: this.clipWindowTopRightY,          // 裁剪视窗在裁剪区域的位置
        clipWindowBottomLeftX: this.clipWindowBottomLeftX,      // 裁剪视窗在裁剪区域的位置
        clipWindowBottomLeftY: this.clipWindowBottomLeftY,      // 裁剪视窗在裁剪区域的位置
        clipWindowBottomRightX: this.clipWindowBottomRightX,    // 裁剪视窗在裁剪区域的位置
        clipWindowBottomRightY: this.clipWindowBottomRightY,    // 裁剪视窗在裁剪区域的位置
 * }
 */
var config = {
  data: {
    type: _spauiUtils.PropTypes.object.isRequired,
    defaultValue: {}
  },
  src: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  clipWidth: {
    type: _spauiUtils.PropTypes.number
  },
  clipHeight: {
    type: _spauiUtils.PropTypes.number
  },
  notScale: {
    type: _spauiUtils.PropTypes.bool
  },
  limitMinScale: {
    type: _spauiUtils.PropTypes.bool
  },
  setImagePosition: {
    type: _spauiUtils.PropTypes.func
  },
  minScale: {
    type: _spauiUtils.PropTypes.number
  },
  maxScale: {
    type: _spauiUtils.PropTypes.number
  },
  perScalePixel: {
    type: _spauiUtils.PropTypes.number
  },
  maxScaleTimes: {
    type: _spauiUtils.PropTypes.number
  },
  onChange: {
    type: _spauiUtils.PropTypes.func.isRequired
  },
  previewWidth: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: 0
  },
  onPreviewData: {
    type: _spauiUtils.PropTypes.func
  },
  initChange: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  containerWidth: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: 470
  },
  containerHeight: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: 450
  }
}; // 转化成PureComponent组件的配置

exports.config = config;
var configData = (0, _spauiUtils.formatProps)(config); // 导出

var propTypes = configData.propTypes;
exports.propTypes = propTypes;
var defaultProps = configData.defaultProps;
exports.defaultProps = defaultProps;
var _default = {
  config: config,
  propTypes: propTypes,
  defaultProps: defaultProps
};
exports["default"] = _default;