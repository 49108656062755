"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultLocale = void 0;

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
var defaultLocale = {
  imageLoadFailLabel: '图片加载失败',
  downloadLabel: '下载',
  totalLabel: '共计{value}张',
  rotateLabel: '旋转',
  zoomoutLabel: '缩小',
  zoominLabel: '放大',
  lookPDFLabel: '查看原PDF',
  lookImageLabel: '查看原图'
};
exports.defaultLocale = defaultLocale;
var _default = {
  defaultLocale: defaultLocale
};
exports["default"] = _default;