"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _spauiButton = _interopRequireDefault(require("@tencent/spaui-button"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var FooterRightComponent = function FooterRightComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var cancelButton = props.cancelButton,
      cancelBsStyle = props.cancelBsStyle,
      onCancel = props.onCancel,
      cancelLabel = props.cancelLabel,
      submitButton = props.submitButton,
      submitBsStyle = props.submitBsStyle,
      onSubmit = props.onSubmit,
      disabled = props.disabled,
      submitLabel = props.submitLabel,
      _props$submitButtonPr = props.submitButtonProps,
      submitButtonProps = _props$submitButtonPr === void 0 ? {} : _props$submitButtonPr,
      _props$cancelButtonPr = props.cancelButtonProps,
      cancelButtonProps = _props$cancelButtonPr === void 0 ? {} : _props$cancelButtonPr,
      _props$loading = props.loading,
      loading = _props$loading === void 0 ? false : _props$loading;
  var result = [];

  if (cancelButton) {
    result.push(_react["default"].createElement(_spauiButton["default"], _extends({
      key: "cancelbutton",
      displayType: cancelBsStyle,
      onClick: onCancel
    }, cancelButtonProps), cancelLabel));
  }

  if (submitButton) {
    result.push(_react["default"].createElement(_spauiButton["default"], _extends({
      key: "submitbutton",
      displayType: submitBsStyle,
      onClick: onSubmit,
      disabled: disabled || loading,
      loading: loading
    }, submitButtonProps), submitLabel));
  }

  return _react["default"].createElement("div", {
    className: "modal-footer-right-inner"
  }, result);
};

var _default = FooterRightComponent;
exports["default"] = _default;