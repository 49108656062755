"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultProps = exports.propTypes = exports.config = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件参数配置
 */

/**
 * OverlayTrigger 接口参数
 * @alias OverlayTriggerProps
 * @description 弹出卡片浮层的触发器。
 * @property {titleline} 默认支持参数
 * @property {blankline} className;style;id;data-*;accessKey;dir;draggable;tabIndex;title;htmlRef;
 * @property {titleline} 基本属性
 * @property {element} overlay=null 提示内容
 * @property {bool} [show] 是否展示
 * @property {func} [onShow] 展示切换<br />onShow = (event, show)
 * @property {string} [placement='auto'] 位置*可选值：left, right, bottom, top, auto*
 * @property {string} [defaultPlacement='top'] 当placement为auto时默认的位置*可选值：left, right, bottom, top*
 * @property {string} [autoPlacementBasic='document'] 面板自动展开的依据*可选值：window（窗口）、document（文档流）*
 * @property {string} [alignment='auto'] 对齐*可选值：left, center, right, top, bottom, auto*
 * @property {string} [trigger='hover'] 触发方式
 * @property {number} [hideDelayTime=0] type=hover消失延迟时间（单位毫秒）
 * @property {number} [showDelayTime] 展示延迟时间（单位毫秒）
 * @property {bool} [followMouse=false] 是否跟随鼠标(仅trigger='hover'有效)
 * @property {bool} [fixed=false] 浮层是否采用position=fixed(主要是用于浮层放置在fixed的结构上)
 * @property {bool} [autoResize=false] 用于如果浮层展示着可以自动调整位置
 * @property {object|string} [target] 往对应位置添加展示的结构（原生浏览器节点结构）可以是原生结构或者target='parentNode'指明放在OverlayTrigger的父元素上
 * @property {string} [positionTarget] 浮层定位时相对于触发元素的DOM节点位置*可选值：parentNode(相对于触发元素的父节点)*
 * @property {bool} [documentClickAutoHide=true] 点击document自动关闭
 * @property {bool} [hideWhenScrolling=false] 当滚动条滚动时进行隐藏
 * @property {object} [triggerTarget=null] 如果没有指定children的时候，可以通过这个指定响应的节点（必须为dom结构）
 * @property {object} [scrollbarTarget] 滚动条对象（dom结构，默认为body）*支持函数形式，返回dom结构*
 * @property {object} [offsetData={}] 浮层偏移位置 offsetData = {left, top}
 * @property {string} [stringTagName='span'] 当children为字符串时，默认添加的标签名
 * @property {number} [popoverAnimateTime=0] popover结构的动画时间（用于popover内容变化时计算位置可用，若放置的是img需要设置popoverAnimateTime=250）
 * @property {bool} [disabled=false] 是否禁用（禁用后只渲染children部分的内容，不开启trigger能力）
 *
 * @see
 *
 * onShow typings:
 *     (event: any, isShow: any) => any
 */
var config = {
  show: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.bool, _spauiUtils.PropTypes.string]),
    visualData: {
      type: 'switch',
      description: '是否展示'
    }
  },
  onShow: {
    type: _spauiUtils.PropTypes.func
  },
  placement: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'auto',
    visualData: {
      type: 'radio',
      value: ['left', 'right', 'bottom', 'top', 'auto'],
      description: '展开位置'
    }
  },
  defaultPlacement: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'top'
  },
  autoPlacementBasic: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'document'
  },
  alignment: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'auto'
  },
  defaultAlignment: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'center'
  },
  trigger: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'hover',
    visualData: {
      type: 'radio',
      value: ['hover', 'click'],
      description: '触发方式'
    }
  },
  overlay: {
    type: _spauiUtils.PropTypes.element,
    defaultValue: null
  },
  hideDelayTime: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: 0,
    visualData: {
      type: 'number',
      description: '鼠标移开多久后消失（ms）'
    }
  },
  goToPopOverDelayTime: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: 50
  },
  showDelayTime: {
    type: _spauiUtils.PropTypes.number,
    visualData: {
      type: 'number',
      description: '鼠标hover多久后出现（ms）'
    }
  },
  fixed: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  autoResize: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  followMouse: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  target: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.object, _spauiUtils.PropTypes.string])
  },
  positionTarget: {
    type: _spauiUtils.PropTypes.string
  },
  documentClickAutoHide: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true,
    visualData: {
      type: 'switch',
      description: '点击空白是否关闭'
    }
  },
  offsetData: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {},
    visualData: {
      type: 'object',
      description: '偏移距离{left, top}'
    }
  },
  hideWhenScrolling: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false,
    visualData: {
      type: 'switch',
      description: '滚动条滚动是否就消失'
    }
  },
  triggerTarget: {
    type: _spauiUtils.PropTypes.object
  },
  scrollbarTarget: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.object, _spauiUtils.PropTypes.string, _spauiUtils.PropTypes.func])
  },
  stringTagName: {
    tpye: _spauiUtils.PropTypes.string,
    defaultValue: 'span'
  },
  className: {
    type: _spauiUtils.PropTypes.string,
    visualData: {
      type: 'input'
    }
  },
  style: {
    type: _spauiUtils.PropTypes.object,
    visualData: {
      type: 'object'
    }
  },
  id: {
    type: _spauiUtils.PropTypes.string,
    visualData: {
      type: 'input'
    }
  },
  popoverAnimateTime: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.number, _spauiUtils.PropTypes.string]),
    defaultValue: 0
  },
  disabled: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  }
}; // 转化成PureComponent组件的配置

exports.config = config;
var configData = (0, _spauiUtils.formatProps)(config); // 导出

var propTypes = configData.propTypes;
exports.propTypes = propTypes;
var defaultProps = configData.defaultProps;
exports.defaultProps = defaultProps;
var _default = {
  config: config,
  propTypes: propTypes,
  defaultProps: defaultProps
};
exports["default"] = _default;