"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultProps = exports.propTypes = exports.config = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件参数配置
 */

/**
 * Chart 接口参数
 * @alias ChartProps
 * @property {titleline} 默认支持参数
 * @description 基于 highcharts 封装的图表组件。
 * @property {blankline} className;style;id;data-*;accessKey;dir;draggable;tabIndex;title;htmlRef;
 * @property {titleline} 基本属性
 * @property {string} type='' 图类型，对应于highchart
 * @property {bool} [inverted=false] 是否相反
 * @property {string|object} [title=''] 图标题
 * @property {string|object} [subtitle=''] 图子标题
 * @property {array} [xAxisCategories=[]] x轴系列名字
 * @property {array} [yAxisCategories=[]] y轴系列名字
 * @property {string} [xAxisTitle=''] x轴标题
 * @property {string} [xAxisReversed=false] x轴反转
 * @property {string} [yAxisTitle=''] y轴标题
 * @property {number} [lineWidth=2] 线宽
 * @property {string} [stacking=''] 堆叠方式
 * @property {bool} [dataLabelsEnabled=true] 是否显示数据标签
 * @property {string} [legendAlign='center'] legend 水平位置
 * @property {string} [legendLayout='horizontal'] legend 整体位置
 * @property {string} [legendVerticalAlign='bottom']  legend 垂直位置
 * @property {number} [legendX=0] legend x坐标
 * @property {number} [legendY=0] legend y坐标
 * @property {number} [legendWidth=0] legend width
 * @property {object} [chart={}] 图表配置
 * @property {object} [pane={}] 面板配置
 * @property {object|array} [xAxis={}] x轴配置
 * @property {object|array} [yAxis={}] y轴配置
 * @property {array} [series=[]] 图数据
 * @property {object} [legend={}] 标题配置
 * @property {object} [credits={}] logo配置
 * @property {array} [colors=[]] 设置图颜色
 * @property {number} [pointStart=null] x轴开始数值
 * @property {number} [pointInterval=null] x轴开始间距
 * @property {object} [tooltip={}] tooltip格式
 * @property {func} [chartCallback] highcharts初始化完成之后的callback
 * @example
 * 需要提前引入highcharts（//i.gtimg.cn/qzone/biz/gdt/lib/highcharts-5.0.10/highcharts.js）
 */
var config = {
  type: {
    type: _spauiUtils.PropTypes.string.isRequired,
    defaultValue: ''
  },
  inverted: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  title: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.object]),
    defaultValue: ''
  },
  subtitle: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.object]),
    defaultValue: ''
  },
  xAxisCategories: {
    type: _spauiUtils.PropTypes.array,
    defaultValue: []
  },
  yAxisCategories: {
    type: _spauiUtils.PropTypes.array,
    defaultValue: []
  },
  xAxisTitle: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  xAxisReversed: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  yAxisTitle: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  lineWidth: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: 2
  },
  stacking: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  dataLabelsEnabled: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  legendAlign: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'center'
  },
  legendLayout: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'horizontal'
  },
  legendVerticalAlign: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'bottom'
  },
  legendX: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: 0
  },
  legendY: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: 0
  },
  legendWidth: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: 0
  },
  chart: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  pane: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  xAxis: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.object, _spauiUtils.PropTypes.array]),
    defaultValue: {}
  },
  yAxis: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.object, _spauiUtils.PropTypes.array]),
    defaultValue: {}
  },
  series: {
    type: _spauiUtils.PropTypes.array,
    defaultValue: []
  },
  legend: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  credits: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  colors: {
    type: _spauiUtils.PropTypes.array,
    defaultValue: []
  },
  pointStart: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: null
  },
  pointInterval: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: null
  },
  tooltip: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  chartCallback: {
    type: _spauiUtils.PropTypes.func,
    defaultValue: _spauiUtils.noop
  }
}; // 转化成PureComponent组件的配置

exports.config = config;
var configData = (0, _spauiUtils.formatProps)(config); // 导出

var propTypes = configData.propTypes;
exports.propTypes = propTypes;
var defaultProps = configData.defaultProps;
exports.defaultProps = defaultProps;
var _default = {
  config: config,
  propTypes: propTypes,
  defaultProps: defaultProps
};
exports["default"] = _default;