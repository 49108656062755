"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isInActiveData = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

var isInActiveData = function isInActiveData() {
  var activeDataParam = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var item = arguments.length > 1 ? arguments[1] : undefined;
  var activeFieldName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  var activeData = (0, _spauiUtils.isArray)(activeDataParam) ? activeDataParam : [activeDataParam];
  var result = -1;
  var key = '';

  if (activeData.length > 0 && typeof item !== 'undefined') {
    activeData.forEach(function (activeItem, index) {
      if (activeFieldName) {
        if (_typeof(item) === 'object' && item && activeItem === item[activeFieldName]) {
          result = index;
        }
      } else if ((activeItem || typeof activeItem === 'number') && activeItem === item) {
        result = index;
      } else if (_typeof(activeItem) === 'object' && activeItem) {
        var activeItemKeys = Object.keys(activeItem);
        var activeItemKeysLen = activeItemKeys.length;

        if (_typeof(item) === 'object' && item && activeItemKeysLen > 0 && activeItemKeysLen === Object.keys(item).length && result < 0) {
          result = index;

          for (key in activeItem) {
            if (activeItem[key] != item[key]) {
              result = -1;
            }
          }
        }
      }
    });
  }

  return result;
};

exports.isInActiveData = isInActiveData;