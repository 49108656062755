"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

// checkbox radio的文案显示结构
var TextComponent = function TextComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var children = props.children,
      _props$data = props.data,
      data = _props$data === void 0 ? {} : _props$data,
      _props$fieldNames = props.fieldNames,
      fieldNames = _props$fieldNames === void 0 ? {} : _props$fieldNames;
  var descriptionFieldName = fieldNames.descriptionFieldName;
  var treeNameProps = {};

  if (children && typeof children === 'string') {
    treeNameProps.title = children;
  }

  return _react["default"].createElement("div", {
    className: "spaui-tree-info"
  }, _react["default"].createElement("div", _extends({
    className: "spaui-tree-name"
  }, treeNameProps), children), data && data[descriptionFieldName] ? _react["default"].createElement("p", {
    className: "spaui-tree-desc"
  }, data[descriptionFieldName]) : null);
};

var _default = TextComponent;
exports["default"] = _default;