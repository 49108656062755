"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件对应结构的默认className
 */
var _default = {
  Tree: 'spaui-tree',
  TreeMoreScopeClassName: 'more-scope',
  TreeListClassName: 'select-list',
  TreeListInnerClassName: 'select-list-inner',
  TreeScopeClassName: 'scope',
  TreeScopeTitleClassName: 'spaui-tree-scope-title',
  TreeScopeHaveTitleClassName: 'with-scope-title',
  TreeHideScopeClassName: 'spaui-tree-hide-scope',
  TreeLoadingClassName: 'loading',
  TreeEmptyBranchClassName: 'empty-scope'
};
exports["default"] = _default;