"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _index = require("../../utils/index");

var _spauiUtils = require("@tencent/spaui-utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var ImgContentComponent = function ImgContentComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var drag = props.drag,
      disabled = props.disabled,
      className = props.className,
      data = props.data,
      canDelete = props.canDelete,
      onDelete = props.onDelete,
      _props$controls = props.controls,
      controls = _props$controls === void 0 ? true : _props$controls,
      upload = props.upload,
      _props$onClick = props.onClick,
      onClick = _props$onClick === void 0 ? _spauiUtils.noop : _props$onClick,
      onDragLeave = props.onDragLeave,
      onDrop = props.onDrop,
      onDragEnter = props.onDragEnter,
      onDragOver = props.onDragOver,
      MaskContentComponent = props.MaskContentComponent,
      DisplayComponent = props.DisplayComponent,
      ImgSortComponent = props.ImgSortComponent,
      ItemAppendComponent = props.ItemAppendComponent,
      required = props.required,
      type = props.type,
      useFileAPI = props.useFileAPI,
      _props$onMouseOver = props.onMouseOver,
      onMouseOver = _props$onMouseOver === void 0 ? _spauiUtils.noop : _props$onMouseOver,
      _props$onMouseOut = props.onMouseOut,
      onMouseOut = _props$onMouseOut === void 0 ? _spauiUtils.noop : _props$onMouseOut,
      canReupload = props.canReupload,
      _props$useNewUI = props.useNewUI,
      useNewUI = _props$useNewUI === void 0 ? false : _props$useNewUI;
  var defaultEventMap = {
    onClick: onClick,
    onDragLeave: onDragLeave,
    onDrop: onDrop,
    onDragEnter: onDragEnter,
    onDragOver: onDragOver,
    onMouseOver: onMouseOver,
    onMouseOut: onMouseOut
  }; // 不能上传是没有事件的

  var eventMap = !upload ? {
    onMouseOver: onMouseOver,
    onMouseOut: onMouseOut
  } : defaultEventMap;
  var dragEventMap = drag && !disabled && upload ? {
    onDragLeave: onDragLeave,
    onDrop: onDrop,
    onDragEnter: onDragEnter,
    onDragOver: onDragOver
  } : {};
  var markerStyle = {}; // 上传过程且有进度，默认是设置遮罩层的宽高

  if (data && data.status === 'uploading' && typeof data.progress !== 'undefined') {
    markerStyle.height = "".concat(100 - data.progress, "%");
    markerStyle.width = '100%';
  }

  return _react["default"].createElement(ImgSortComponent, _extends({
    className: (0, _classnames["default"])(className, {
      'spaui-uploader-uploading': data.status === 'uploading',
      'img-li-video-controls': (type === 'video' || (0, _index.isVideo)(data.url)) && controls
    })
  }, props, {
    ImgSortComponent: null
  }), _react["default"].createElement(DisplayComponent, {
    drag: drag,
    disabled: disabled,
    useNewUI: useNewUI,
    type: type,
    data: data,
    controls: controls,
    dragEventMap: dragEventMap
  }), canDelete ? // 可以删除才有遮罩层
  _react["default"].createElement("div", _extends({
    className: "marker"
  }, eventMap, {
    style: markerStyle
  }), typeof MaskContentComponent !== 'undefined' ? _react["default"].createElement(MaskContentComponent, {
    useNewUI: useNewUI,
    type: type,
    upload: upload,
    canReupload: canReupload,
    useFileAPI: useFileAPI,
    onDelete: onDelete,
    data: data,
    canDelete: canDelete
  }) : null, !required ? // 非必须才可以删除
  _react["default"].createElement("a", {
    className: "del spaui-cursor-pointer",
    onClick: onDelete
  }) : null) : null, typeof ItemAppendComponent !== 'undefined' ? _react["default"].createElement(ItemAppendComponent, {
    useNewUI: useNewUI,
    data: data
  }) : null);
};

var _default = ImgContentComponent;
exports["default"] = _default;