"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _index = require("../utils/index");

var _checkmark_icon_component = _interopRequireDefault(require("./checkmark_icon_component"));

var _classnames = _interopRequireDefault(require("classnames"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _default = function _default(props) {
  var _props$data = props.data,
      data = _props$data === void 0 ? {} : _props$data,
      checked = props.checked,
      _props$classNameMap = props.classNameMap,
      classNameMap = _props$classNameMap === void 0 ? {} : _props$classNameMap,
      _props$changeTriggerE = props.changeTriggerEvent,
      changeTriggerEvent = _props$changeTriggerE === void 0 ? 'onClick' : _props$changeTriggerE,
      CheckItemIconComponent = props.CheckItemIconComponent;

  var _getTheContentOfTheIt = (0, _index.getTheContentOfTheItemComponent)(_objectSpread({}, props, {
    disabled: data.disabled || false
  })),
      className = _getTheContentOfTheIt.className,
      labelDom = _getTheContentOfTheIt.labelDom,
      label = _getTheContentOfTheIt.label,
      onClick = _getTheContentOfTheIt.onClick;

  var liProps = {};

  if (changeTriggerEvent) {
    liProps[changeTriggerEvent] = onClick;
  }

  return _react["default"].createElement("li", _extends({
    className: (0, _classnames["default"])(className, classNameMap.SelectCheckallItemClassName, {
      'checked selected': checked
    }),
    "data-reverse-check": "1" // 用于标记该选项是反选标记

  }, liProps), _react["default"].createElement(_checkmark_icon_component["default"], {
    checked: checked,
    IconComponent: CheckItemIconComponent
  }), _react["default"].createElement("div", {
    className: "selection-name",
    title: label
  }, _react["default"].createElement("p", null, labelDom)));
};

exports["default"] = _default;