"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
var LabelComponent = function LabelComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _props$data = props.data,
      data = _props$data === void 0 ? {} : _props$data;
  return _react["default"].createElement("p", {
    className: "spaui-carousel-label"
  }, data.label);
};

var _default = LabelComponent;
exports["default"] = _default;