"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultProps = exports.propTypes = exports.config = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

var _loading_component = _interopRequireDefault(require("./component/loading_component"));

var _tree_node_component = _interopRequireDefault(require("./component/tree_node_component"));

var _tree_branch_component = _interopRequireDefault(require("./component/tree_branch_component"));

var _placeholder_branch_component = _interopRequireDefault(require("./component/placeholder_branch_component"));

var _check_all_component = _interopRequireDefault(require("./component/check_all_component"));

var _icon_component = _interopRequireDefault(require("./component/icon_component"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var defaultFieldNames = _spauiUtils.nodeTree.defaultFieldNames;

/**
 * Tree 接口参数
 * @alias TreeProps
 * @description 扩展树组件。
 * @property {titleline} 默认支持参数
 * @property {blankline} className;style;id;data-*;accessKey;dir;draggable;tabIndex;title;htmlRef;
 * @property {titleline} 基础属性
 * @property {array} data=[]] 树状的全部展示数据
 * @property {array} value=[]] 选中数据（数组数据）
 * @property {array} expandNodeIds=[]] 当前展开的数据节点,expandNodeIds为空时, 默认显示第一层, expandNodeIds = [1,710000]展示:国内>台湾省>台北,屏东
 * @property {func} onChange 回调函数<br />onChange(event, checkedIds, treeNode, valueTreeNodeMap)
 * @property {func} onClick  切换目录点击函数<br />onClick(event, checkedIds, treeNode, valueTreeNodeMap)
 * @property {string} [valueType='parent'] 选中的节点
 * @property {string} [selectTarget='default'] 选中目标，默认为default即有数据就可选，child就只能选中末端节点*可选值：default, child*
 * @property {bool} [disabled=false] 是否禁用
 * @property {func} [isDisabledNode] 是否禁用节点<br />isDisabledNode = (treeNode, defaultAllowSelectd, parentListData)
 * @property {bool} [multiple=true] 是否多选（一般非多选只用于selectTarget='child'的时候）
 * @property {func} [loadingIds=[]] 正在加载数据的节点
 * @property {number} [loadingBranches=[]] 正在加载的branch *最左边一列为0*
 * @property {bool} [canExpandDisabledNode=true] 禁止选择的节点是否能够展开
 * @property {bool} [asyncGetNodeData=true] 是否异步获取节点数据
 * @property {number} [minWidth=200] 每列最小宽度
 * @property {number} [theNumberOfDisplay=0] 每列显示的条数（可用于数据量多的时候显示，不能小于一屏幕完整显示的数量）
 * @property {number} [displayItemHeight=55] 每列的高度（用于数据量多时占位计算使用）
 * @property {number} [displayTimes=2] 展示次数（theNumberOfDisplay大于0时可以指定当数据滚动到几分之一时开始展示下部分内容）
 * @property {string} [fieldNames={xxx}] 原始数据字段映射(相关字段名称可看案例)
 * @property {string} [labelFormat=''] 文案显示格式，通过${字段名}的方式，例如 labelFormat='${value}-${desc}'
 * @property {bool} [isAutoHeight=true] 自适应调节branch的高度
 * @property {bool} [isAutoCheckedParent=true] 子节点全选时自动选上父节点, 并从checkedIds删除子节点
 * @property {bool} [unrelated=false] 是否父子节点不关联
 * @property {bool} [unRelatedDisabled = false] 是否不进行父子关联禁用关系
 * @property {bool} [autoAlignment=true] 选项是否自动对齐
 * @property {bool} [useCss=false] 是否使用css来设置branch的宽度，如果true，就不调用setBranchStyle
 * @property {bool} [scopeTransitionTimeByUseCss=0] 使用useCss时，scope是否有过度动画时间，该动画会影响滚动向右的宽度计算
 * @property {func} [setBranchClassName] 设置每一列的className
 * @property {func} [setBranchStyle] 设置每一列的style(宽度建议按照总和100%的方式处理)
 * @property {func} [setBranchTitle] 设置每一列的title*setBranchTitle=(index, data, parentData)*
 * @property {func} [setBranchBottomContent] 设置每一列的底部内容*setBranchBottomContent=(index, data, parentData)*
 * @property {number} [minDisplayBranch] 设置最少显示 branch 数量*站位的branch可以通过 setPlaceholderBranchContent 来设置自定义内容*
 * @property {func} [setPlaceholderBranchContent] 设置空列的内容*setPlaceholderBranchContent=(index, data, parentData)*
 * @property {string} [branchTitleClassName=''] 设置列title时生效，可用于设置title的className
 * @property {bool} [enableAddonEllipsisStyle=true] 多列时，每行的附加内容是否使用溢出省略的样式
 * @property {func} [setNodeClassName] 设置每一选项的className*setNodeClassName=(treeData) => return string*
 * @property {func} [setNodeText] 设置每一选项的文案展示内容*setNodeText=(treeData, filedName) => return string | JSX.Element*
 * @property {bool} [onColumnScroll] 每一列单独的滚动事件<br />(event,parentNode,[offsetTop,offsetButtom],totalHeight)=>void
 * @property {bool} [setSelectedValueToView=false] 设置选中的值滚动到可视区域内*用于在某些情况下，选中的值没有出现在可视区域内*
 * @property {number} [setSelectedValueToViewTimeout=0] 设置setSelectedValueToView延迟时间
 * @property {func} [canSelected] 是否能够选中<br />canSelected = (treeNode, defaultAllowSelectd)
 * @property {string} [nodeClassName=''] 每个节点的className
 * @property {string} [nodeCheckedClassName=''] 节点选中的className
 * @property {bool} [nodeCanExpand=false] 是否节点都是可以扩展的
 * @property {bool} [nodeNotAllowSelected=false] 是否节点不允许选中（为true的时候就没有单选或复选框）
 * @property {bool} [showNodeTitle=true] 是否展示选项的title属性
 * @property {object} [classNameMap={}] 组件内对应结构的默认className(可以针对对应位置进行替换,具体参数见案例)
 * @property {bool} [checkAll=false] 是否全选（只用于multiple=true的情况）*目前只会在第一列加，若要单独设置每一列需要同时设置setBranchCheckAll参数*
 * @property {func} [setBranchCheckAll] 单独设置每一列是否可以全选*setBranchCheckAll=(index, data, parentData)*
 * @property {string} [checkAllLabel='全选'] 全选文案
 * @property {object} [checkAllDataSet={}] 全选节点上的dataSet数据
 * @property {func|element} [CheckAllComponent] 全选结构
 * @property {bool} [isAutoAdjustCheckedValue=false] 是否自动调整选中的值*在搜索的场景下可能需要用到，用于删除子节点，选中父节点*
 * @property {bool} [showArrowOnEmptyOptions=false] 当子选项为空时，是否显示右侧箭头
 * @property {titleline} 自定义属性
 * @property {func|element} [NodeAdditionalComponent] 每个节点附加的结构
 * @property {bool} [showAdditionalComponent=false] 是否展示附加结构
 * @property {titleline} 自定义组件
 * @property {func|element} [AdditionalComponent] 附加结构
 * @property {func} [NodeItemComponent] 自定义选项结构
 * @example
 *
 * // 原始数据字段映射
 * fieldNames={labelFieldName:'desc',titleFieldName:'tips',nodeFieldName: 'options',disabledFieldName: 'disabled'}
 *
 * // type为branch的类型，值为branch(原始数据生成的列)，addition附加列；index为当type=branch时对应的列；showColumnsCount: 总共显示几列
 * setBranchClassName = (type, index, showColumnsCount)
 *
 * // showColumnsCount: 总共显示几列；index: 当前为第几列；treeDom： tree组件的最外层节点（render时为null）
 * setBranchStyle = (showColumnsCount, index, treeDom)
 *
 * &lt;NodeAdditionalComponent {not_allow_selected, canExpand, data } /&gt;
 *
 * &lt;AdditionalComponent {...this.props} /&gt;
 *
 * classNameMap配置可见于：
 * https://git.woa.com/ads/spaui-basic_ui_components/tree/master/src/spaui-tree/src/js/config/tree_classname_map.js
 */
var config = {
  data: {
    type: _spauiUtils.PropTypes.array.isRequired,
    defaultValue: [],
    visualData: {
      type: 'array',
      description: '选项数据'
    }
  },
  value: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.array, _spauiUtils.PropTypes.number, _spauiUtils.PropTypes.string]).isRequired,
    defaultValue: [],
    visualData: {
      setType: function setType(stateData) {
        return stateData.multiple ? 'array' : 'input';
      },
      description: '选中值（多选时为数组）'
    }
  },
  expandNodeIds: {
    type: _spauiUtils.PropTypes.array.isRequired,
    defaultValue: [],
    visualData: {
      type: 'array',
      description: '当前展开的数据节点'
    }
  },
  loadingIds: {
    type: _spauiUtils.PropTypes.array,
    defaultValue: []
  },
  loadingBranches: {
    type: _spauiUtils.PropTypes.array,
    defaultValue: []
  },
  canExpandDisabledNode: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  asyncGetNodeData: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  sameValueNotTriggerChange: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  onChange: {
    type: _spauiUtils.PropTypes.func
  },
  onClick: {
    type: _spauiUtils.PropTypes.func
  },
  valueType: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'parent'
  },
  keepOldChildData: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  selectTarget: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'default'
  },
  multiple: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  disabled: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  isDisabledNode: {
    type: _spauiUtils.PropTypes.func
  },
  minWidth: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: 200
  },
  theNumberOfDisplay: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: 0
  },
  displayItemHeight: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: 55
  },
  displayTimes: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: 2
  },
  fieldNames: {
    type: _spauiUtils.PropTypes.shape({
      labelFieldName: _spauiUtils.PropTypes.string,
      titleFieldName: _spauiUtils.PropTypes.string,
      disabledFieldName: _spauiUtils.PropTypes.string,
      nodeFieldName: _spauiUtils.PropTypes.string,
      descriptionFieldName: _spauiUtils.PropTypes.string
    }),
    defaultValue: _objectSpread({}, defaultFieldNames, {
      descriptionFieldName: 'infomation'
    })
  },
  labelFormat: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  isAutoHeight: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  defaultHeight: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number]),
    defaultValue: 272
  },
  isAutoCheckedParent: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  unrelated: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  unRelatedDisabled: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  autoAlignment: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  isLoading: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  singleColumnHeight: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number]),
    defaultValue: ''
  },
  CheckboxProps: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  RadioProps: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  canSelected: {
    type: _spauiUtils.PropTypes.func
  },
  onColumnScroll: {
    type: _spauiUtils.PropTypes.func
  },
  nodeClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  nodeCheckedClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  nodeCanExpand: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  nodeNotAllowSelected: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  showNodeTitle: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  IconComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _icon_component["default"]
  },
  LoadingComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _loading_component["default"]
  },
  TreeNodeComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _tree_node_component["default"]
  },
  TreeBranchComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _tree_branch_component["default"]
  },
  PlaceholderBranchComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _placeholder_branch_component["default"]
  },
  NodeAdditionalComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element])
  },
  showAdditionalComponent: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  AdditionalComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element])
  },
  NodeItemComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func])
  },
  useCss: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  scopeTransitionTimeByUseCss: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.number, _spauiUtils.PropTypes.string]),
    defaultValue: 0
  },
  setBranchClassName: {
    type: _spauiUtils.PropTypes.func,
    defaultValue: function defaultValue() {
      return '';
    }
  },
  setBranchStyle: {
    type: _spauiUtils.PropTypes.func,
    defaultValue: function defaultValue(showColumnsCount) {
      return {
        width: "".concat(1 / showColumnsCount * 100, "%")
      };
    }
  },
  setBranchTitle: {
    type: _spauiUtils.PropTypes.func
  },
  setBranchBottomContent: {
    type: _spauiUtils.PropTypes.func
  },
  setPlaceholderBranchContent: {
    type: _spauiUtils.PropTypes.func
  },
  minDisplayBranch: {
    type: _spauiUtils.PropTypes.number
  },
  branchTitleClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  enableAddonEllipsisStyle: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  setNodeClassName: {
    type: _spauiUtils.PropTypes.func,
    defaultValue: function defaultValue() {
      return '';
    }
  },
  setNodeText: {
    type: _spauiUtils.PropTypes.func,
    defaultValue: function defaultValue(treeData, filedName) {
      return treeData[filedName];
    }
  },
  classNameMap: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  checkAll: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  setBranchCheckAll: {
    type: _spauiUtils.PropTypes.func
  },
  checkAllLabel: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: '全选'
  },
  checkAllDataSet: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  CheckAllComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _check_all_component["default"]
  },
  setSelectedValueToView: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  setSelectedValueToViewTimeout: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: 0
  },
  isAutoAdjustCheckedValue: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  showArrowOnEmptyOptions: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  }
}; // 转化成PureComponent组件的配置

exports.config = config;
var configData = (0, _spauiUtils.formatProps)(config); // 导出

var propTypes = configData.propTypes;
exports.propTypes = propTypes;
var defaultProps = configData.defaultProps;
exports.defaultProps = defaultProps;
var _default = {
  config: config,
  propTypes: propTypes,
  defaultProps: defaultProps
};
exports["default"] = _default;