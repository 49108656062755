"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 兼容参数
 */
// 兼容props，因为有些名称被废弃了但是要兼容
var _default = function _default() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _props$switchdots = props.switchdots,
      switchdots = _props$switchdots === void 0 ? false : _props$switchdots;
  var prevAndNext = typeof props.prevAndNextAble !== 'undefined' ? props.prevAndNextAble : props.prevAndNext;
  var download = typeof props.downloadable !== 'undefined' ? props.downloadable : props.download;
  var tool = typeof props.toolable !== 'undefined' ? props.toolable : props.tool;
  var scroll = typeof props.scrollable !== 'undefined' ? props.scrollable : props.scroll;
  var thumb = typeof props.thumbable !== 'undefined' ? props.thumbable : props.thumb;
  var total = typeof props.totalable !== 'undefined' ? props.totalable : props.total; // 如果开启圆点，小图跟总计就没有了

  if (switchdots) {
    total = false;
    thumb = false;
  }

  return _objectSpread({}, props, {
    prevAndNext: prevAndNext,
    download: download,
    tool: tool,
    thumb: thumb,
    total: total,
    scroll: scroll
  });
};

exports["default"] = _default;