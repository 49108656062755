"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _spauiUtils = require("@tencent/spaui-utils");

var _spauiEmpty = _interopRequireDefault(require("@tencent/spaui-empty"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var Wrap = function Wrap() {
  var _classNames;

  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var children = props.children,
      _props$classNameMap = props.classNameMap,
      classNameMap = _props$classNameMap === void 0 ? {} : _props$classNameMap,
      _props$useCheckMark = props.useCheckMark,
      useCheckMark = _props$useCheckMark === void 0 ? false : _props$useCheckMark;
  var haveLabel = props.haveLabel,
      haveDescription = props.haveDescription,
      haveImg = props.haveImg,
      haveTime = props.haveTime;
  var multiple = props.multiple,
      dropClassName = props.dropClassName,
      placement = props.placement,
      dropDownHtmlRef = props.dropDownHtmlRef,
      unClose = props.unClose,
      _props$dropStyle = props.dropStyle,
      dropStyle = _props$dropStyle === void 0 ? {} : _props$dropStyle,
      loading = props.loading;
  var style = loading ? {} : dropStyle;
  return _react["default"].createElement("div", {
    onClick: function onClick() {
      return unClose();
    },
    className: (0, _classnames["default"])(classNameMap.SelectDropClassName, (_classNames = {}, _defineProperty(_classNames, classNameMap.SelectDropMultipleClassName, multiple || useCheckMark), _defineProperty(_classNames, classNameMap.SelectDropMoreInfoClassName, haveLabel && haveDescription), _defineProperty(_classNames, classNameMap.SelectDropWithImgClassName, haveImg), _defineProperty(_classNames, classNameMap.SelectDropWithTimeClassName, haveImg && haveTime), _defineProperty(_classNames, classNameMap.SelectDropTopClassName, placement === 'top'), _classNames), dropClassName),
    style: style,
    ref: dropDownHtmlRef
  }, children);
};

var SelectionAreaComponent = function SelectionAreaComponent(props) {
  var SelectionSearchComponent = props.SelectionSearchComponent,
      SelectionDataComponent = props.SelectionDataComponent,
      SelectionTipComponent = props.SelectionTipComponent,
      AdditionComponent = props.AdditionComponent,
      LoadingComponent = props.LoadingComponent,
      classNameMap = props.classNameMap;
  var data = props.data,
      tip = props.tip,
      searchPlaceholder = props.searchPlaceholder,
      onSearch = props.onSearch,
      searchValue = props.searchValue,
      search = props.search,
      loading = props.loading,
      emptyTip = props.emptyTip,
      _props$showTip = props.showTip,
      showTip = _props$showTip === void 0 ? true : _props$showTip,
      _props$dataStyle = props.dataStyle,
      dataStyle = _props$dataStyle === void 0 ? {} : _props$dataStyle,
      searchClear = props.searchClear,
      onSearchClear = props.onSearchClear,
      onSearchKeyDown = props.onSearchKeyDown,
      removeDataWhenNotOpen = props.removeDataWhenNotOpen,
      open = props.open,
      showDataComponent = props.showDataComponent,
      _props$showDataCompon = props.showDataComponentWithSearchValue,
      showDataComponentWithSearchValue = _props$showDataCompon === void 0 ? false : _props$showDataCompon,
      mode = props.mode,
      emptyType = props.emptyType,
      emptyTitle = props.emptyTitle,
      emptyDesc = props.emptyDesc,
      emptyProps = props.emptyProps;
  var haveData = data.length > 0;
  var additionComponentInstance = null;
  var loadingComponentInstance = null;
  var selectionTipComponentInstance = null;
  var showData = true;

  if (typeof removeDataWhenNotOpen !== 'undefined' && removeDataWhenNotOpen && !open) {
    showData = false;
  } // 如果强制指定为false，就不展示了


  if (typeof showDataComponent !== 'undefined' && !showDataComponent) {
    showData = false;
  } // 指定了但是又没有数据，那就还是不显示


  if (showDataComponent && !searchValue && data.length < 1 && !showDataComponentWithSearchValue) {
    showData = false;
  } // 加载中


  if (loading) {
    if (typeof LoadingComponent !== 'undefined') {
      if ((0, _spauiUtils.isReactNode)(LoadingComponent)) {
        loadingComponentInstance = LoadingComponent;
      } else {
        loadingComponentInstance = _react["default"].createElement(LoadingComponent, {
          classNameMap: classNameMap
        });
      }
    }
  } else {
    if (typeof AdditionComponent !== 'undefined') {
      if ((0, _spauiUtils.isReactNode)(AdditionComponent)) {
        additionComponentInstance = AdditionComponent;
      } else {
        additionComponentInstance = _react["default"].createElement(AdditionComponent, props);
      }
    }

    if (showTip && typeof SelectionTipComponent !== 'undefined') {
      if ((0, _spauiUtils.isReactNode)(SelectionTipComponent)) {
        selectionTipComponentInstance = SelectionTipComponent;
      } else {
        selectionTipComponentInstance = _react["default"].createElement(SelectionTipComponent, _extends({}, props, props.SelectionTipComponentProps || {}, {
          classNameMap: classNameMap,
          tip: tip ? tip : !haveData ? emptyTip : ''
        }));
      }
    }
  }

  return _react["default"].createElement(Wrap, props, additionComponentInstance, search && mode !== 'tags' ? _react["default"].createElement(SelectionSearchComponent, _extends({}, props.SelectionSearchComponentProps || {}, {
    classNameMap: classNameMap,
    value: searchValue,
    placeholder: searchPlaceholder,
    onChange: onSearch,
    clear: searchClear,
    onClear: onSearchClear,
    onKeyDown: onSearchKeyDown
  })) : null, loadingComponentInstance, (haveData || typeof showDataComponent !== 'undefined' && showDataComponent) && !loading && showData ? _react["default"].createElement(SelectionDataComponent, _extends({}, props, props.SelectionDataComponentProps || {}, {
    style: dataStyle
  })) : null, emptyTitle && data.length === 0 ? _react["default"].createElement(_spauiEmpty["default"], _extends({
    size: "small",
    title: emptyTitle,
    desc: emptyDesc,
    className: "selection-empty",
    type: emptyType || 'data'
  }, emptyProps)) : null, selectionTipComponentInstance);
};

var _default = SelectionAreaComponent;
exports["default"] = _default;