"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/**
 * 展开收起图标
 */
var _default = function _default() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _props$open = props.open,
      open = _props$open === void 0 ? false : _props$open,
      onOpen = props.onOpen,
      _props$haveData = props.haveData,
      haveData = _props$haveData === void 0 ? false : _props$haveData,
      classNameMap = props.classNameMap;
  var commonClassName = classNameMap.TreeSwitcher;
  var result = null;

  if (haveData) {
    var _classNames;

    result = _react["default"].createElement("span", {
      className: (0, _classnames["default"])(commonClassName, (_classNames = {}, _defineProperty(_classNames, classNameMap.TreeSwitcherOpen, open), _defineProperty(_classNames, classNameMap.TreeSwitcherHide, !open), _classNames)),
      onClick: onOpen
    }, _react["default"].createElement("svg", {
      viewBox: "0 0 1024 1024",
      width: "1em",
      height: "1em",
      fill: "currentColor"
    }, _react["default"].createElement("path", {
      d: "M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"
    })));
  } else {
    result = _react["default"].createElement("span", {
      className: commonClassName
    });
  }

  return result;
};

exports["default"] = _default;