"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.getPreContent = exports.getNextTh = exports.getTableDOM = exports.getTableColNodes = exports.getTableCellNodes = exports.getColumns = exports.getColumnMaxWidth = void 0;

var _react = _interopRequireDefault(require("react"));

var _spauiUtils = require("@tencent/spaui-utils");

var _number = require("./number");

var _get_size = require("./get_size");

var _bool = require("./bool");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

// 获取列的最大宽度
var getColumnMaxWidth = function getColumnMaxWidth() {
  var columnProps = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _columnProps$adjustWi = columnProps.adjustWidth,
      adjustWidth = _columnProps$adjustWi === void 0 ? false : _columnProps$adjustWi,
      _columnProps$width = columnProps.width,
      width = _columnProps$width === void 0 ? 0 : _columnProps$width,
      _columnProps$maxWidth = columnProps.maxWidth,
      maxWidth = _columnProps$maxWidth === void 0 ? 0 : _columnProps$maxWidth,
      _columnProps$minWidth = columnProps.minWidth,
      minWidth = _columnProps$minWidth === void 0 ? 0 : _columnProps$minWidth;
  var result = maxWidth; // 如果是自适应的列，列指定的width大于0 && width大于maxWidth，就得调整下maxWidth

  if (adjustWidth && width > 0 && width > maxWidth) {
    result = width;
  } // 如果最大宽度小于最小宽度的话，最大宽度也要调整下


  if (result > 0 && result < minWidth) {
    result = minWidth;
  }

  return result;
};
/**
 * 从children里面筛选只能是Column的子节点作为新的children
 * @property {string} children=null 子结构
 */


exports.getColumnMaxWidth = getColumnMaxWidth;

var getColumns = function getColumns() {
  var children = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

  var addColumn = function addColumn() {
    var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

    if (item) {
      var _item$type = item.type,
          type = _item$type === void 0 ? null : _item$type,
          _item$props = item.props,
          props = _item$props === void 0 ? {} : _item$props;
      var show = props.show;

      if (type && props && ((0, _spauiUtils.isUndefined)(show) || show)) {
        if (props.name || props.type === 'optional' && props.target) {
          result.push(item);
        }
      }
    }
  };

  var result = [];

  if ((0, _spauiUtils.isArray)(children)) {
    children.map(function () {
      var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

      if ((0, _spauiUtils.isArray)(item)) {
        item.map(function () {
          var subItem = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
          addColumn(subItem);
          return subItem;
        });
      } else {
        addColumn(item);
      }

      return item;
    });
  } else if (children) {
    addColumn(children);
  }

  return result;
};
/**
 * 获取单元格（td/th）
 * @property {object} parentNode=null tr以上的父节点
 * @property {string} cellName='td' 单元格名称
 * @property {func} [getIndex] 获取单元格的索引
 * @property {bool} [firstRow=true] 是否只返回第一行的数据
 */


exports.getColumns = getColumns;

var getTableCellNodes = function getTableCellNodes() {
  var parentNode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  var cellName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'td';
  var getIndex = arguments.length > 2 ? arguments[2] : undefined;
  var firstRow = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
  var result = [];
  var index = 0;

  if (!parentNode) {
    return result;
  }

  var trResult = parentNode.getElementsByTagName('tr') || []; // 只返回第一行数据

  if (firstRow) {
    if (trResult.length > 0) {
      if ((0, _spauiUtils.isFunction)(getIndex)) {
        index = getIndex(trResult);
      }

      result = trResult[index].getElementsByTagName(cellName) || [];
    }
  } else {
    // 返回所有数据，按行分组
    _toConsumableArray(trResult).forEach(function (item) {
      result.push(item.getElementsByTagName(cellName) || []);
    });
  }

  return result;
};
/**
 * 获取第一行的col
 * @property {object} parentNode=null tr以上的父节点
 */


exports.getTableCellNodes = getTableCellNodes;

var getTableColNodes = function getTableColNodes() {
  var parentNode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  var result = [];

  if (parentNode) {
    result = parentNode.getElementsByTagName('col') || [];
  }

  return result;
}; // 获取table相关的DOM变量或内容


exports.getTableColNodes = getTableColNodes;

var getTableDOM = function getTableDOM(tableInstance) {
  var props = tableInstance.props || {};
  var _props$data = props.data,
      data = _props$data === void 0 ? [] : _props$data;
  var fixedRows = (0, _bool.getFixedRows)(props);
  var fixedHeight = (0, _bool.getFixedHeight)(props);
  var clientWidth = 0;
  var colThead = [];
  var colTbody = [];
  var colTfoot = [];
  var thThead = [];
  var tdTbody = [];
  var trTbody = [];
  var tdTfoot = [];
  var len = 0;
  var scrollBarVisibleWidth = 0;
  var tableWrapHeight = 0;

  if (tableInstance.tableWrap) {
    clientWidth = tableInstance.tableWrap.clientWidth;
  }

  colThead = getTableColNodes(tableInstance.theadColGroup);
  colTbody = getTableColNodes(tableInstance.tbodyColGroup);
  colTfoot = getTableColNodes(tableInstance.tfootColGroup);

  if ((0, _bool.isFixedTableThead)(tableInstance.props)) {
    if (tableInstance.theadTable) {
      thThead = tableInstance.theadTable.getElementsByTagName('th');
    }
  } else {
    if (tableInstance.tbodyTable) {
      thThead = tableInstance.tbodyTable.getElementsByTagName('th');
    }
  }

  if (tableInstance.tbodyTable) {
    trTbody = tableInstance.tbodyTable.getElementsByTagName('tr');
    tdTbody = tableInstance.tbodyTable.getElementsByTagName('td');
  }

  if (tableInstance.tfootTable) {
    tdTfoot = tableInstance.tfootTable.getElementsByTagName('td');
  }

  len = colTbody.length || colThead.length || colTfoot.length || 0;

  if ((0, _bool.isFixedTableThead)(props) && !(0, _bool.isFixedTableTheadByCss)(props)) {
    scrollBarVisibleWidth = (0, _get_size.getScrollBarVisibleWidth)();

    if (fixedRows > 0 && data.length <= fixedRows) {
      scrollBarVisibleWidth = 0;
    } else if (fixedHeight > 0) {
      tableWrapHeight = (0, _number.toInt)((0, _spauiUtils.getStyle)(tableInstance.tableWrap, 'height'));

      if (tableWrapHeight > 0) {
        if (tableWrapHeight < fixedHeight) {
          scrollBarVisibleWidth = 0;
        }
      }
    }
  }

  return {
    clientWidth: clientWidth - scrollBarVisibleWidth,
    len: len,
    colThead: colThead,
    colTbody: colTbody,
    colTfoot: colTfoot,
    thThead: thThead,
    trTbody: trTbody,
    tdTbody: tdTbody,
    tdTfoot: tdTfoot,
    tableWrap: tableInstance.tableWrap,
    theadWrap: tableInstance.theadWrap,
    tbodyWrap: tableInstance.tbodyWrap,
    tfootWrap: tableInstance.tfootWrap,
    tableTheadWraper: tableInstance.tableTheadWraper,
    tableTbodyWraper: tableInstance.tableTbodyWraper,
    tableTfootWraper: tableInstance.tableTfootWraper,
    theadTable: tableInstance.theadTable,
    tbodyTable: tableInstance.tbodyTable,
    tfootTable: tableInstance.tfootTable,
    thead: tableInstance.thead,
    tbody: tableInstance.tbody,
    tfoot: tableInstance.tfoot
  };
}; // 获取下个表格头节点


exports.getTableDOM = getTableDOM;

var getNextTh = function getNextTh(node, theadThs) {
  var result = null;
  var index = -1;
  var name = '';
  var i = 0;
  var len = 0;

  if (node && theadThs && theadThs.length > 1) {
    name = node.getAttribute('data-name');

    if (name) {
      len = theadThs.length;

      for (; i < len; i++) {
        if (theadThs[i] && theadThs[i].getAttribute('data-name') === name) {
          index = i + 1;
          result = theadThs[index] || null;
        }
      }
    }
  }

  return {
    nextTh: result,
    index: index
  };
};

exports.getNextTh = getNextTh;

var getPreContent = function getPreContent() {
  var columns = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var props = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var _props$showPreContent = props.showPreContent,
      showPreContent = _props$showPreContent === void 0 ? false : _props$showPreContent,
      PreContentComponent = props.PreContentComponent,
      _props$preContent = props.preContent,
      preContent = _props$preContent === void 0 ? null : _props$preContent,
      _props$preContentFixe = props.preContentFixed,
      preContentFixed = _props$preContentFixe === void 0 ? false : _props$preContentFixe,
      _props$preContentTdCl = props.preContentTdClassName,
      preContentTdClassName = _props$preContentTdCl === void 0 ? '' : _props$preContentTdCl,
      _props$preContentTrCl = props.preContentTrClassName,
      preContentTrClassName = _props$preContentTrCl === void 0 ? '' : _props$preContentTrCl;
  var result = null;
  var preContentResult = null;

  if (showPreContent) {
    if (typeof PreContentComponent !== 'undefined') {
      if ((0, _spauiUtils.isReactNode)(PreContentComponent)) {
        preContentResult = PreContentComponent;
      } else {
        preContentResult = _react["default"].createElement(PreContentComponent, props);
      }
    } else {
      preContentResult = preContent;
    }

    result = _react["default"].createElement("tr", {
      "data-spaui-table-tr": "1",
      className: preContentTrClassName
    }, _react["default"].createElement("td", {
      className: preContentTdClassName,
      colSpan: columns.length,
      "data-fixed": preContentFixed ? 1 : '',
      "data-index": 0
    }, _react["default"].createElement("div", null, preContentResult)));
  }

  return result;
};

exports.getPreContent = getPreContent;
var _default = {
  getColumns: getColumns,
  getColumnMaxWidth: getColumnMaxWidth,
  getTableCellNodes: getTableCellNodes,
  getTableColNodes: getTableColNodes,
  getTableDOM: getTableDOM,
  getNextTh: getNextTh,
  getPreContent: getPreContent
};
exports["default"] = _default;