"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview loading
 */
var LoadingComponent = function LoadingComponent() {
  return _react["default"].createElement("div", {
    className: "spaui-loading",
    style: {
      height: '50px',
      position: 'relative'
    }
  });
};

var _default = LoadingComponent;
exports["default"] = _default;