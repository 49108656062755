"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件对应结构的默认className
 */
var _default = {
  DatePicker: 'spaui-datepicker',
  // 组件顶级className
  DateTimePicker: 'spaui-datetimepicker',
  // 选日期+时间组件 顶级className
  DatePickerRangeClassName: 'date-range',
  // type=range时带上的className
  DatePickerRangeButSinglePanelClassName: 'date-range-single-panel',
  // type === 'range' && hideEndDatePanel时带上的className
  DatePickerRangeAndTimePickerClassName: 'date-range-time',
  // type === 'range' && timePicker时带上的className
  DatePickerOpenClassName: 'spaui-datepicker-open',
  // open=true时带上的className,
  DatePickerDropdownClassName: 'date-dropdown',
  // 下拉面板className
  DatePickerDropdownFastControlClassName: 'with-fast-control',
  DatePickerDropdownWithLeftFastControlClassName: 'with-left-fast-control',
  DatePickerDropdownLeftFastControlClassName: 'left-fast-control',
  DatePickerDropdownDataPanelClassName: 'data-panel',
  DatePickerDropdownTimePickerClassName: 'with-timepicker',
  DatePickerValueholderClassName: 'spaui-datepicker-valueholder',
  DatePickerValueholderDeleteClassName: 'spaui-datepicker-delete',
  DatePickerValueholderPlaceholderClassName: 'placeholder',
  DatePickerValueholderDisabledClassName: 'disabled',
  DatePickerValueholderChooseDivClassName: 'datechoose',
  DatePickerValueholderIconDivClassName: 'date-icon',
  DatePickerPortalClassName: 'spaui-datepicker-portal',
  DatePickerPlayClassName: 'date-play',
  DatePickerFastControlClassName: 'fast-control',
  DatePickerFastControlListClassName: 'fast-list',
  DatePickerTopFastControlClassName: 'top-fast-list',
  DatePickerBottomFastControlClassName: 'bottom-fast-list',
  DatePickerRightPanelClassName: 'date-right-panel',
  DatePickerLeftPanelClassName: 'date-left-panel',
  DatePickerDateareasClassName: 'dateareas',
  DatePickerDataFootClassName: 'data-foot',
  DatePickerLeftDataFootClassName: 'date-foot-left',
  DatePickerRightDataFootClassName: 'data-foot-right',
  DatePickerTimeareasClassName: 'timeareas',
  DatePickerTimePickerClassName: 'date-timepicker',
  DatePickerTimePickerTipClassName: 'timepicker-tip',
  DatePickerSingleDateTimePickerTipClassName: 'singledate-timepicker-tip',
  DatePickerTimePickerCurrentDateClassName: 'timpicker-current-date',
  DatePickerDateAreaClassName: 'datearea monthlist',
  DatePickerYearPanelClassName: 'yearpanel',
  DatePickerYearPanelScrollClassName: 'yearpanel_scroll yearpanel-scroll',
  DatePickerTitlePanelClassName: 'ymchoose',
  DatePickerRollClassName: 'roll',
  DatePickerRollBackwardClassName: 'roll-backward',
  DatePickerRollForwardClassName: 'roll-forward',
  DatePickerTitlePanelLabelClassName: 'ymselect',
  DatePickerTitlePanelYearLabelClassName: 'yselect',
  DatePickerDateItemClassName: 'dateitem',
  DatePickerLastMonthDateItemClassName: 'spaui-datepicker-lastmonth-date',
  DatePickerNextMonthDateItemClassName: 'spaui-datepicker-nextmonth-date',
  DatePickerDateItemDisabledClassName: 'disabled',
  DatePickerDateItemDisabledCustomClassName: 'disabled-custom',
  DatePickerDateItemSelectedClassName: 'on',
  DatePickerStartDateItemClassName: 'date-star',
  DatePickerEndDateItemClassName: 'date-end',
  DatePickerAmongDateItemClassName: 'among',
  DatePickerDateItemTodayClassName: 'today',
  DatePickerWeekListClassName: 'weeklist',
  DatePickerDateListClassName: 'datelist',
  DatePickerMonthItemClassName: 'monthitem',
  DatePickerMonthListClassName: 'monthlist',
  DatePickerNoFooterClassName: 'nofooter',
  DatePickerHideNoCurrentMonthDateClassName: 'with-hide-no-current-month-date'
};
exports["default"] = _default;