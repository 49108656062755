"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.hasSameArrayItem = exports.arrayDistinct = exports.getLabel = exports.getComponentInstance = void 0;

var _react = _interopRequireDefault(require("react"));

var _spauiUtils = require("@tencent/spaui-utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

// 根据组件是Function还是实例进行处理，以便获得对应的实例内容
var getComponentInstance = function getComponentInstance() {
  var Component = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  var props = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var result = null; // 根据附加组件类型来定义内容

  if (typeof Component !== 'undefined') {
    if ((0, _spauiUtils.isReactNode)(Component)) {
      result = Component;
    } else {
      result = _react["default"].createElement(Component, props);
    }
  }

  return result;
}; // 获取文案


exports.getComponentInstance = getComponentInstance;

var getLabel = function getLabel() {
  var treeNode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var key = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var format = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  var setNodeText = arguments.length > 3 ? arguments[3] : undefined;
  var props = arguments.length > 4 ? arguments[4] : undefined;
  var label = ''; // 如果指定了格式，需要处理一下展示文案格式

  if (format) {
    label = format.replace(/\$\{([0-9a-zA-Z_\-.]*)\}/gi, function (str) {
      var matchKey = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      var result = '';

      if (matchKey) {
        result = setNodeText(treeNode, matchKey, props) || '';
      }

      return result;
    });
  } else {
    label = setNodeText(treeNode, key, props) || '';
  }

  return label;
}; // 数组去重（仅适用于数据元素包含值类型的数据）


exports.getLabel = getLabel;

var arrayDistinct = function arrayDistinct(variable) {
  var result = [];
  var obj = {};

  if (!(0, _spauiUtils.isArray)(variable)) {
    return variable;
  }

  var _iteratorNormalCompletion = true;
  var _didIteratorError = false;
  var _iteratorError = undefined;

  try {
    for (var _iterator = variable[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
      var value = _step.value;
      var key = "".concat(_typeof(value), ":").concat(value);

      if (!obj[key]) {
        result.push(value);
        obj[key] = 1;
      }
    }
  } catch (err) {
    _didIteratorError = true;
    _iteratorError = err;
  } finally {
    try {
      if (!_iteratorNormalCompletion && _iterator["return"] != null) {
        _iterator["return"]();
      }
    } finally {
      if (_didIteratorError) {
        throw _iteratorError;
      }
    }
  }

  return result;
};
/**
 * 判断两个数组的元素是否相同（忽略顺序）
 */


exports.arrayDistinct = arrayDistinct;

var hasSameArrayItem = function hasSameArrayItem() {
  var arrayA = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var arrayB = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

  if (!arrayA || !arrayB || arrayA.length !== arrayB.length) {
    return false;
  }

  var result = true;

  for (var i = 0; i < arrayA.length; i++) {
    if (!arrayB.includes(arrayA[i])) {
      result = false;
      break;
    }
  }

  return result;
};

exports.hasSameArrayItem = hasSameArrayItem;
var _default = {
  getComponentInstance: getComponentInstance,
  getLabel: getLabel,
  arrayDistinct: arrayDistinct,
  hasSameArrayItem: hasSameArrayItem
};
exports["default"] = _default;