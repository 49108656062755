"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
var _default = function _default() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _props$iconClassName = props.iconClassName,
      iconClassName = _props$iconClassName === void 0 ? '' : _props$iconClassName,
      _props$label = props.label,
      label = _props$label === void 0 ? '' : _props$label,
      _props$type = props.type,
      type = _props$type === void 0 ? '' : _props$type,
      inPortal = props.inPortal,
      _props$classNameMap = props.classNameMap,
      classNameMap = _props$classNameMap === void 0 ? {} : _props$classNameMap;
  var result = null;

  if (iconClassName) {
    result = _react["default"].createElement("i", {
      className: iconClassName
    });
  } else if (label && type === 'mini' && (typeof label === 'string' || typeof label === 'number') && !inPortal) {
    result = _react["default"].createElement("span", {
      className: classNameMap.MenuIcon
    }, label.toString().slice(0, 1));
  }

  return result;
};

exports["default"] = _default;