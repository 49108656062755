"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

var _default = function _default(_onload) {
  var url = '//public.gdtimg.com/qzone/biz/gdt/lib/jquery/jquery-1.12.2.js?max_age=31536000';
  var url1 = '//public.gdtimg.com/qzone/biz/gdt/lib/spa-clipper/1.3.8/spa-clipper.min.js?max_age=31536000';

  if ((typeof window === "undefined" ? "undefined" : _typeof(window)) === 'object' && typeof window.SPAClipper === 'undefined') {
    if (location.protocol === 'file:') {
      url = "http:".concat(url);
      url1 = "http:".concat(url1);
    }

    if ((typeof window === "undefined" ? "undefined" : _typeof(window)) === 'object' && typeof window.jQuery !== 'undefined') {
      (0, _spauiUtils.loadJs)({
        url: url1,
        onload: _onload
      });
    } else {
      (0, _spauiUtils.loadJs)({
        url: url,
        onload: function onload() {
          (0, _spauiUtils.loadJs)({
            url: url1,
            onload: _onload
          });
        }
      });
    }
  } else {
    _onload();
  }
};

exports["default"] = _default;