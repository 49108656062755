"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _spauiUtils = require("@tencent/spaui-utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var _default = function _default() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _props$status = props.status,
      status = _props$status === void 0 ? 'add' : _props$status,
      onClick = props.onClick,
      disabled = props.disabled,
      InfoComponent = props.InfoComponent,
      ImgComponent = props.ImgComponent; // 获取图标

  var getIcon = function getIcon() {
    var result = null;

    switch (status) {
      // 删除图标可点击
      case 'delete':
        result = _react["default"].createElement("button", {
          type: "button",
          onClick: onClick
        }, _react["default"].createElement("i", {
          className: "icon icon-plus icon-close"
        }));
        break;

      case 'checked':
        result = _react["default"].createElement("i", {
          className: "icon icon-checked"
        });
        break;

      default:
        result = _react["default"].createElement("i", {
          className: "icon icon-plus"
        });
        break;
    }

    return result;
  };

  return _react["default"].createElement("li", {
    className: (0, _classnames["default"])('feed-item spaui-userpicker-feed-item', {
      'feed-item-delete spaui-userpicker-feed-item-delete': status === 'delete'
    }) // 删除的时候只能点删除按钮才有响应
    ,
    onClick: status !== 'delete' ? onClick : _spauiUtils.noop
  }, status !== 'delete' ? _react["default"].createElement("input", {
    type: "checkbox",
    className: "aria-checkbox"
  }) : null, _react["default"].createElement(ImgComponent, _extends({}, props, {
    className: "".concat(props.className || '', " spaui-userpicker-feed-img"),
    InfoComponent: null,
    ImgComponent: null
  })), _react["default"].createElement(InfoComponent, _extends({}, props, {
    className: "".concat(props.className || '', " spaui-userpicker-feed-info"),
    InfoComponent: null,
    ImgComponent: null
  })), !disabled ? _react["default"].createElement("span", {
    className: "feed-action spaui-userpicker-feed-action"
  }, getIcon()) : null);
};

exports["default"] = _default;