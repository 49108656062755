"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
var compactProps = function compactProps() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var active = props.active,
      open = props.open,
      onActiveChange = props.onActiveChange,
      propsOnOpenChange = props.onOpenChange,
      propsOnOpen = props.onOpen,
      toggleable = props.toggleable,
      toggle = props.toggle;
  var onOpenChange = typeof onActiveChange !== 'undefined' ? onActiveChange : propsOnOpenChange;
  var onOpen = typeof onOpenChange !== 'undefined' ? onOpenChange : propsOnOpen;
  return {
    open: typeof active !== 'undefined' ? active : open,
    onOpen: onOpen,
    toggle: typeof toggleable !== 'undefined' ? toggleable : toggle
  };
};

var _default = compactProps;
exports["default"] = _default;