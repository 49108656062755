"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultProps = exports.propTypes = exports.config = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

var _header_component = _interopRequireDefault(require("./component/header_component"));

var _footer_right_component = _interopRequireDefault(require("./component/footer_right_component"));

var _body_component = _interopRequireDefault(require("./component/body_component"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
var getSpauiDefualtProps = function getSpauiDefualtProps() {
  if (window.SPAUI_CONFIG && window.SPAUI_CONFIG.Dialog && window.SPAUI_CONFIG.Dialog.defualtProps) {
    return window.SPAUI_CONFIG.Dialog.defualtProps;
  }

  return {};
};

var getDefaultPostion = function getDefaultPostion() {
  var props = getSpauiDefualtProps();

  if (props && props.position !== undefined) {
    return props.position;
  }

  return '';
};
/**
 * Dialog 接口参数
 * @alias DialogProps
 * @description 模态弹窗，会打断用户操作，告知用户信息或强制用户需要进行操作的弹窗。
 * @property {titleline} 默认支持参数
 * @property {blankline} className;style;id;data-*;accessKey;dir;draggable;tabIndex;title;htmlRef;
 * @property {titleline} 基础属性
 * @property {string} [title=''] 弹框标题
 * @property {string} [subTitle=''] 弹框副标题
 * @property {string|element} [headerAdditionalContent=''] 头部附加内容
 * @property {any} children 弹框内容
 * @property {string} [type=''] 弹框风格类型*可选值：normal, simple, confirm, tip*
 * @property {bool} [show] 是否展示*（默认show为undefined，声明后就会打开弹框，不想展示的话需要自传show）*
 * @property {func} [subscribeChange] 订阅弹框的展示状态*didmount,update,unmount的时候会调用(props, instance) => {}*
 * @property {func} [onClose] 点击关闭按钮x回调*onClose=(event)*
 * @property {func} [onSubmit] 点击确认回调*onSubmit=(event)*
 * @property {func} [onCancel] 点击取消按钮回调*onCancel=(event)*
 * @property {bool} [hideScrollBar=true] 弹框出现的时候是否隐藏滚动条*（旧名称为scrollBarHidden）*
 * @property {bool} [disabled=false] 是否禁用确定按钮
 * @property {bool} [loading=false] 确定按钮是否处理中
 * @property {string} [position=''] 默认是组件计算的位置（居中）会带上style，可以设置position='close'进行关闭，这样位置由业务侧的CSS进行控制
 * @property {bool} [animation=false] 是否需要动画*（目前主要针对position=right有效）*
 * @property {string} [animationName="bounceIn"] 动画名称*（目前主要针对position=right有效），可选值：bounceIn, smoothIn*
 * @property {number} [topSpace=10] 用于设置当弹框高度比屏幕高度大时距离顶部的距离
 * @property {number} [bottomSpace=10] 用于设置当弹框高度比屏幕高度大时距离底部的距离
 * @property {bool} [useNewUI=false] 是否使用新版 UI
 * @property {bool} [forceInView=true] 是否让弹窗在可视范围里完整显示(仅useNewUI有效)
 * @property {string} [transitionName='spaui-dialog'] 自定义动效名称 遵循 od-animate 规范
 * @property {string} [size='middle'] 宽度 仅useNewUI有效*可选值：small,middle(400),large(600),extraLarge,xl(800),xxl,xxxl(1200)*
 * @property {string} [footerRightPosition='right'] footer right的位置*可选值：center, right（1200 useNewUI下生效）*
 * @property {bool} [hideHeaderDivider=false] 是否隐藏弹框头部与 body 之间的分割线
 * @property {bool} [hideFooterDivider=false] 是否隐藏弹框尾部与 body 之间的分割线
 * @property {titleline} 提示类型弹框属性
 * @property {string} [iconType=''] 弹框为提示类型的图标*对应spaui-icon的type，比如：error, info, loading, strongwarn, success, warn*
 * @property {bool} [enabledBackgroundColor=false] 是否开启提示类型的背景颜色*默认关闭是白色，开启后每种提示都有自己的背景颜色*
 * @property {bool} [enabledTipAnimation=false] 是否开启显示和消失动画
 * @property {string} [tipAnimationType='fadein'] 显示的动画类型*可选值：fadein（渐入), trans（变换）*
 * @property {string} [tipAnimationDirection='center'] 动画加载的方向*可选值：up, down, center（仅支持 fadein 类型）*
 * @property {number} [time=0] type='tip'时自动关闭弹框的时间*为0的时候不关闭弹框*
 * @property {func} [timeoutCallback] type='tip'且time!=0时关闭弹框时的回调(等同旧名称timeCallback)
 * @property {titleline} 遮罩层属性
 * @property {bool} [backdrop=false] 是否有遮罩层(true, false, 'static')static时遮罩不可点击
 * @property {bool} [enabledBackdropClose=false] 遮罩层是否可点击
 * @property {titleline} 设置按钮属性
 * @property {object} [closeButtonProps={}] 关闭按钮props参数
 * @property {string} [submitLabel='确定'] 确定按钮文案
 * @property {object} [submitButtonProps={}] 确定按钮props参数
 * @property {string} [cancelLabel='取消'] 取消按钮文案
 * @property {object} [cancelButtonProps={}] 取消按钮props参数
 * @property {string} [submitBsStyle='primary'] 确定按钮类型*(值参考spaui-button的displayType)*
 * @property {string} [cancelBsStyle='default'] 取消按钮类型*(值参考spaui-button的displayType)*
 * @property {titleline} 隐藏对应结构属性
 * @property {bool} [closeButton=true] 是否需要关闭按钮
 * @property {string} [closeButtonClassName=''] 关闭按钮的额外className
 * @property {bool} [escHide=false] 弹框展开时是否支持按下esc关闭弹框
 * @property {bool} [cancelButton=true] 是否需要取消按钮
 * @property {bool} [submitButton=true] 是否需要确定按钮
 * @property {bool} [hideHeader=false] 是否隐藏头部
 * @property {bool} [hideFooter=false] 是否隐藏尾部
 * @property {bool} [uncover=false] 打开的话，弹框不会遮住底下的内容
 * @property {titleline} 弹框跟随点击位置属性
 * @property {object} [eventTarget=null] 弹框紧跟的节点
 * @property {bool} [eventTargetIsFixed=false] 开启滚动条时eventTarget如果是fixed的话滚动方式是不一样的需要设置下
 * @property {object} [eventTargetPosition=null] 弹框紧跟节点的页面位置*设置的数据有：{top, left, width, height}*
 * @property {object} [eventTargetLeftDeviation=0] 弹框紧跟的点击节点左偏差
 * @property {object} [eventTargetTopDeviation=0] 弹框紧跟的点击节点上偏差
 * @property {string} [eventTargetPlacement='bottom'] 弹框出来的方向*可选值: top, bottom, left, right, auto*
 * @property {titleline} 设计对应结构className属性
 * @property {string} [portalClassName=''] portal className
 * @property {string} [modalClassName=''] modal className*modalClassName='no-cover'的时候遮罩层不覆盖页面内容区域*
 * @property {object} [modalStyle={}] modal style
 * @property {string} [contentClassName=''] content className
 * @property {object} [contentStyle={}] content style
 * @property {string} [headerClassName=''] modal-header的className
 * @property {object} [headerStyle={}] modal-header style
 * @property {string} [bodyClassName=''] modal-body的className
 * @property {object} [bodyStyle={}] modal-body style
 * @property {string} [footerClassName=''] modal-footer的className
 * @property {object} [footerStyle={}] modal-footer style
 * @property {titleline} 弹框拖动相关属性
 * @property {bool} [moveable=false] 是否可拖动(需要有header)
 * @property {func} [onMove] move响应变化margin*onMove=(event, marginTop, marginLeft)*
 * @property {titleline} 弹框resize相关属性
 * @property {bool} [resize=false] 是否开启弹框resize特性
 * @property {bool} [resizeDialogPosition=true] 监听页面resize调整弹框位置
 * @property {number} [resizeTriggerPadding=5] 与弹框4个边界相距多少间距时触发resize
 * @property {number} [resizeMinWidth=0] 弹框resize最小宽度
 * @property {number} [resizeMinHeight=0] 弹框resize最小高度
 * @property {func} [onResize] resize响应变化宽高*onResize=(event, width, height)*
 * @property {titleline} 自定义组件属性
 * @property {element} [HeaderComponent] 自定义头部组件结构*(具体参数看案例)*
 * @property {element} [BodyComponent] 自定义弹框Body组件结构*(具体参数看案例)*
 * @property {element} [FooterLeftComponent] 自定义尾部左组件结构*(具体参数看案例)*
 * @property {element} [FooterRightComponent] 自定义尾部右组件结构*(具体参数看案例)*
 * @property {element} [FooterComponent] 自定义尾部组件结构*(具体参数看案例)*
 *
 * @see
 *
 * onClose typings:
 *     (event: any, hideCallback: any) => any
 *
 * ====
 *
 * onSubmit typings:
 *     (event: any, hideCallback: any) => any
 *
 * ====
 *
 * onCancel typings:
 *     (event: any, hideCallback: any) => any
 *
 * ====
 *
 * onMove typings:
 *     (event: any, marginTop: any, marginLeft: any) => any
 *
 * ====
 *
 * onResize typings:
 *     (event: any, resizeWidth: any, resizeHeight: any) => any
 *
 * @example
 * &lt;HeaderComponent
    {...this.props}
/&gt;
 *
 * &lt;FooterLeftComponent
    {...this.props}
    onCancel={onCancel}
    onSubmit={onSubmit}
/&gt;
 *
 * &lt;FooterRightComponent
    {...this.props}
    onCancel={onCancel}
    onSubmit={onSubmit}
/&gt;
 *
 * &lt;FooterComponent
    {...this.props}
/&gt;
尺寸说明(useNewUI下生效)：small 320, middle 400, large 600, xl 800, xxl 1000, xxxl 1200。
 */


var config = {
  show: {
    type: _spauiUtils.PropTypes.bool
  },
  subscribeChange: {
    type: _spauiUtils.PropTypes.func
  },
  children: {
    type: _spauiUtils.PropTypes.any.isRequired,
    defaultValue: null
  },
  onClose: {
    type: _spauiUtils.PropTypes.func
  },
  onSubmit: {
    type: _spauiUtils.PropTypes.func
  },
  onCancel: {
    type: _spauiUtils.PropTypes.func
  },
  title: {
    type: _spauiUtils.PropTypes.any,
    defaultValue: ''
  },
  subTitle: {
    type: _spauiUtils.PropTypes.any,
    defaultValue: ''
  },
  headerAdditionalContent: {
    type: _spauiUtils.PropTypes.any,
    defaultValue: ''
  },
  closeButtonProps: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  submitLabel: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: '确定'
  },
  submitButtonProps: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  cancelLabel: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: '取消'
  },
  cancelButtonProps: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  submitBsStyle: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'primary'
  },
  cancelBsStyle: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'default'
  },
  closeButton: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  closeButtonClassName: {
    type: _spauiUtils.PropTypes.string
  },
  escHide: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  cancelButton: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  submitButton: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  backdrop: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.bool, _spauiUtils.PropTypes.string]),
    defaultValue: false
  },
  enabledBackdropClose: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  useNewUI: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  forceInView: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  transitionName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'spaui-dialog'
  },
  footerRightPosition: {
    type: _spauiUtils.PropTypes.oneOf(['center', 'right']),
    defaultValue: 'right'
  },
  hideHeaderDivider: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  hideFooterDivider: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  size: {
    type: _spauiUtils.PropTypes.oneOf(['small', 'middle', 'large', 'extraLarge', 'xl', 'xxl', 'xxxl']),
    defaultValue: 'middle'
  },
  type: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  tipContentStyle: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  iconType: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  enabledBackgroundColor: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  enabledTipAnimation: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  tipAnimationType: {
    type: _spauiUtils.PropTypes.oneOf(['fadein', 'trans']),
    defaultValue: 'fadein'
  },
  tipAnimationDirection: {
    type: _spauiUtils.PropTypes.oneOf(['up', 'down', 'center']),
    defaultValue: 'center'
  },
  time: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.number, _spauiUtils.PropTypes.string]),
    defaultValue: 0
  },
  timeoutCallback: {
    type: _spauiUtils.PropTypes.func
  },
  modalClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  modalStyle: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  portalClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  contentClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  contentStyle: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  headerClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  headerStyle: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  bodyClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  bodyStyle: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  footerClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  footerStyle: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  hideHeader: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  hideFooter: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  uncover: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  eventTarget: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: null
  },
  eventTargetIsFixed: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  eventTargetPosition: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: null
  },
  eventTargetLeftDeviation: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: 0
  },
  eventTargetTopDeviation: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: 0
  },
  eventTargetPlacement: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'bottom'
  },
  topSpace: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.number, _spauiUtils.PropTypes.string]),
    defaultValue: 10
  },
  bottomSpace: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.number, _spauiUtils.PropTypes.string]),
    defaultValue: 10
  },
  hideScrollBar: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  disabled: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  loading: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  position: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: getDefaultPostion()
  },
  animation: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  animationName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'bounceIn'
  },
  moveable: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  onMove: {
    type: _spauiUtils.PropTypes.func
  },
  resize: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  resizeTriggerPadding: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: 5
  },
  resizeMinWidth: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: 100
  },
  resizeMinHeight: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: 40
  },
  onResize: {
    type: _spauiUtils.PropTypes.func
  },
  HeaderComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _header_component["default"]
  },
  BodyComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _body_component["default"]
  },
  FooterLeftComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element])
  },
  FooterRightComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _footer_right_component["default"]
  },
  FooterComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element])
  }
}; // 转化成PureComponent组件的配置

exports.config = config;
var configData = (0, _spauiUtils.formatProps)(config); // 导出

var propTypes = configData.propTypes;
exports.propTypes = propTypes;
var defaultProps = configData.defaultProps;
exports.defaultProps = defaultProps;
var _default = {
  config: config,
  propTypes: propTypes,
  defaultProps: defaultProps
};
exports["default"] = _default;