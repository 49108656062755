"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
var SliderTrack = function SliderTrack() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var onMouseDown = props.onMouseDown,
      onMouseOver = props.onMouseOver,
      onMouseOut = props.onMouseOut,
      style = props.style,
      canTranslationRange = props.canTranslationRange,
      _props$classNameMap = props.classNameMap,
      classNameMap = _props$classNameMap === void 0 ? {} : _props$classNameMap;
  return _react["default"].createElement("div", {
    style: style,
    "data-translation": canTranslationRange ? 1 : '',
    className: classNameMap.SliderTrackClassName,
    onMouseDown: onMouseDown,
    onMouseOver: onMouseOver,
    onMouseOut: onMouseOut
  });
};

var _default = SliderTrack;
exports["default"] = _default;