"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.MediaUploader = exports.FileUploader = exports.ImageUploader = exports.Uploader = void 0;

var _uploader = _interopRequireDefault(require("./uploader"));

var _image_uploader = _interopRequireDefault(require("./image_uploader"));

var _file_uploader = _interopRequireDefault(require("./file_uploader"));

var _media_uploader = _interopRequireDefault(require("./media_uploader"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
var Uploader = _uploader["default"];
exports.Uploader = Uploader;
var ImageUploader = _image_uploader["default"];
exports.ImageUploader = ImageUploader;
var FileUploader = _file_uploader["default"];
exports.FileUploader = FileUploader;
var MediaUploader = _media_uploader["default"]; // 调试信息
// if (process.env.NODE_ENV !== 'production') {
//     const {whyDidYouUpdate} = require('why-did-you-update');
//     whyDidYouUpdate(React);
// }

exports.MediaUploader = MediaUploader;
var _default = {
  Uploader: Uploader,
  ImageUploader: ImageUploader,
  FileUploader: FileUploader,
  MediaUploader: MediaUploader
};
exports["default"] = _default;