"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileOverview spaui下拉框结构
 * @author kirkzheng
 * @time 2017-12-25
 * @module @tencent/spaui-select
 */
var _default = function _default(props) {
  var tip = props.tip;
  var result = null;

  if (typeof tip === 'string') {
    result = _react["default"].createElement("div", {
      className: "selection-tips",
      dangerouslySetInnerHTML: {
        __html: tip
      }
    });
  } else {
    result = _react["default"].createElement("div", {
      className: "selection-tips"
    }, tip);
  }

  return result;
};

exports["default"] = _default;