"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultProps = exports.propTypes = exports.config = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件参数配置
 */

/**
 * Empty 接口参数
 * @alias EmptyProps
 * @property {titleline} 默认支持参数
 * @property {blankline} className;style;id;data-*;accessKey;dir;draggable;tabIndex;title;htmlRef;
 * @property {titleline} 基础属性
 * @property {string} [type='search'] 类型*可选值: normal,search,data*
 * @property {string} [size='normal'] 尺寸*可选值: normal,small*
 * @property {string|element} [title] 文案
 * @property {string|element} [desc] 描述
 * @property {bool} [showIcon=true] showIcon 是否显示 icon
 * @property {string} [iconClassName]
 * @property {element} [icon] 自定义 icon
 * @property {element} [additionalElement] 附加的元素
 */
var config = {
  type: {
    type: _spauiUtils.PropTypes.oneOf(['normal', 'search', 'data']),
    defaultValue: 'search'
  },
  size: {
    type: _spauiUtils.PropTypes.oneOf(['normal', 'small']),
    defaultValue: 'normal'
  },
  title: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.element])
  },
  desc: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.element])
  },
  showIcon: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  iconClassName: {
    type: _spauiUtils.PropTypes.string // defaultValue: 'spaui-icon-empty-default',

  },
  icon: {
    type: _spauiUtils.PropTypes.node
  },
  additionalElement: {
    type: _spauiUtils.PropTypes.element
  }
}; // 转化成PureComponent组件的配置

exports.config = config;
var configData = (0, _spauiUtils.formatProps)(config); // 导出

var propTypes = configData.propTypes;
exports.propTypes = propTypes;
var defaultProps = configData.defaultProps;
exports.defaultProps = defaultProps;
var _default = {
  config: config,
  propTypes: propTypes,
  defaultProps: defaultProps
};
exports["default"] = _default;