"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultProps = exports.propTypes = exports.config = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

var _spauiLoading = _interopRequireDefault(require("@tencent/spaui-loading"));

var _selection_area_component = _interopRequireDefault(require("./component/selection_area_component"));

var _selection_search_component = _interopRequireDefault(require("./component/selection_search_component"));

var _selection_data_component = _interopRequireDefault(require("./component/selection_data_component"));

var _selection_tip_component = _interopRequireDefault(require("./component/selection_tip_component"));

var _selection_checkall_component = _interopRequireDefault(require("./component/selection_checkall_component"));

var _selection_reverse_check_component = _interopRequireDefault(require("./component/selection_reverse_check_component"));

var _index = require("./utils/index");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件参数配置
 */

/**
  * Select 接口参数
  * @alias SelectProps
  * @description 下拉选择器。
  * @property {titleline} 默认支持参数
  * @property {blankline} className;style;id;data-*;accessKey;dir;draggable;tabIndex;title;htmlRef;
  * @property {titleline} 基础属性
  * @property {string} [size] 尺寸*可选值: default(默认), small(小尺寸)*
  * @property {bool|number|string|array} value='' 选中值（多选时为数组）
  * @property {array} [requiredValue=[]] 必选项（多选时可用，设置后对应数据变更时会存在value中且实际操作时无法删除）
  * @property {string} [mode='default'] 模式 *可选值：default, tags*
  * @property {bool} [tagsInput=true] mode=tags是是否可输入tag
  * @property {array} data=[]] 选项数据
  * @property {func} onChange 数据变更回调*onChange = (event, value, valueMap) \n value: 值, valueMap：value对应的原始数据*
  * @property {bool} [sameValueNotTriggerChange=false] 选中的值与上次的结果一样是否进行回调
  * @property {bool} [disabled=false] 是否禁用
  * @property {string} [placeholder='请选择'] 占位提示
  * @property {string} [placement='bottom'] 展开面板位置*可选: top, bottom, auto*
  * @property {string} [placementAlign] 展开的面板对齐方向*可选: left, right, auto*
  * @property {string} [autoPlacementBasic='document'] 面板自动展开的依据*可选值：window（窗口）、document（文档流）*
  * @property {bool} [loading=false] 是否数据loading中
  * @property {bool} [html=false] 是否label可转化成html
  * @property {bool} [clear=false] 是否可清空
  * @property {bool} [clearSearchValueWhenChange=false] 选中选项时自动清空输入的搜索值（用于作为非受控组件时）
  * @property {bool} [show=true] 是否展示
  * @property {func} [onClear] 清空回调*onClear=(event)*
  * @property {func} [onScroll] 下拉面板内容区域滚动回调*onScroll = (event, [offsetTop, offsetButtom], totalHeight)*
  * @property {bool} [selectedValueAutoToView=false] 选中的值自动滚动到可视区域内*用于在某些情况下，选中的值没有出现在可视区域内*
  * @property {string|element} [tip=''] 提示
  * @property {string} [emptyTip='没有更多内容'] 空数据的时候提示文案
  * @property {boolean} [showTip=true] 是否展示tip结构
  * @property {boolean} [showLabelAreaType=false] 是否固定展示label区域
  * @property {boolean} [showImgAreaType=false] 是否固定展示img区域
  * @property {boolean} [showDataComponent=false] 是否data为空也渲染data组件
  * @property {string} [valueAreaType='string'] 展示区域类型*可选值: string, labels*
  * @property {object} [LabelsProps={}] valueAreaType='labels'给Labels组件添加props
  * @property {string} [valueFieldName='value'] 值在data中对应的字段名
  * @property {string} [labelFieldName='label'] 文案在data中对应的字段名
  * @property {bool} [light=false] 是否作为轻量下拉框使用
  * @property {bool} [headless=false] 是否作为无头下拉框使用
  * @property {object} [dataTipTriggerProps={}] 数据项提示对应的 OverlayTrigger 组件的 props *data数据项的 tip 属性为提示的内容*
  * @property {object} [dataTipPopOverProps={}] 数据项提示对应的 PopOver 组件的 props *data数据项的 tip 属性为提示的内容*
  * @property {titleline} 多选相关属性
  * @property {bool} [multiple=false] 是否多选
  * @property {number} [max] 多选时，最多可选择多少个数据*指定max的时候没有全选按钮*
  * @property {string} [separator='，'] 多选展示分隔符
  * @property {bool} [checkAll=false] 是否需要全选按钮*（只有多选的时候有）*
  * @property {string} [checkAllLabel='全选'] 全选的提示文案
  * @property {bool} [stickCheckAll=false] 是否一直展示全选按钮(主要是在有max时)
  * @property {bool} [reverseCheck=false] 是否需要反选按钮*（只有多选的时候有）*
  * @property {string} [reverseCheckLabel='反选'] 反选的提示文案
  * @property {titleline} 文案展示属性
  * @property {array} [valueLabelField] 选中的数据文案中要展示的数据字段
  * @property {func} [setValueLabel] 转化展示的选中数据文案*setValueLabel = (data) => {}      // data: 选中值的object数据*
  * @property {array} [labelData=[]] 用于匹配value中已不在data里的数据显示
  * @property {titleline} 数据补充属性
  * @property {bool} [supplementaryData=false] 对于value中有些数据是不在data的是否补充显示*（补充后可作为选项）*
  * @property {func} [setSupplementaryData] 设置补充数据*setSupplementaryData = (value) => { return {value: '', label: ''}}*
  * @property {titleline} 对应位置style与class属性
  * @property {string} [valueAreaClassName=''] 展示区域的className
  * @property {object} [valueAreaStyle={}] 展示区域的style
  * @property {string} [containerClassName=''] container框className
  * @property {object} [containerStyle={}] container框的style
  * @property {object} [portalStyle={}] 下拉框 portal的style
  * @property {string} [dropClassName=''] drop框className
  * @property {object} [dropStyle={}] drop框style
  * @property {object} [dataStyle={}] 下拉框results列表的style
  * @property {object} [classNameMap={}] 组件内对应结构的默认className(可以针对对应位置进行替换,具体参数见案例)
  * @property {bool} [useCheckMark=false] 是否使用打勾样式
  * @property {titleline} 搜索相关属性
  * @property {bool} [search] 是否搜索
  * @property {number} [searchCount=6] data长度大于改值时出现搜索框
  * @property {string} [searchValue=''] 搜索值
  * @property {array} [searchFields=['label']] 搜索字段
  * @property {string} [searchPlaceholder='搜索'] 搜索占位符
  * @property {bool} [searchCheckAll=false] 是否需要全选搜索结果
  * @property {func} [convertPYs] 拼音转化，对应@tencent/spa-pinyin
  * @property {func} [emphasize] 对数据展示进行强调转化*（html=true默认搜索会高亮字符）\n emphasize = (item, props)*
  * @property {bool} [starMatch=false] 是否使用 '*'（星号）来匹配一个字符或汉字
  * @property {bool} [searchClear=true] 搜索是否可清空
  * @property {bool} [closeFilter=false] 是否关闭筛选逻辑（关闭后输入搜索词不做筛选）
  * @property {func} [onSearchClear] 搜索清空回调*onSearchClear=(event)*
  * @property {func} [onSearchKeyDown] 搜索输入框keyDown事件
  * @property {func} [onSearch] 搜索回调*onSearch = (event, value) \n value: 搜索关键词*
  * @property {bool} [useSearchValue=false] 当searchValue没有匹配data中的数据时，开启可以将搜索词作为选项
  * @property {bool} [useSearchValueData=false] 为true时，只展示searchValueData
  * @property {object|array} [searchValueData] 使用搜索词自己进行匹配展示的数据
  * @property {element|string|number} [searchEmptyTip] 使用搜索词没有数据时的提示
  * @property {titleline} tags模式属性
  * @property {bool} [enabledDeleteKey=true] 是否启用 delete 按键*当输入的搜索值为空字符串时，如果按下 delete 按键，会删除最后一个选中的值*
  * @property {titleline} 面板展开属性
  * @property {bool} [open] 是否打开
  * @property {bool} [removeDataWhenNotOpen=false] open为false时是否去除data组件结构
  * @property {bool} [closeWhenTheValueChange=true] 当值改变的时候默认进行关闭（只针对单选有效果）
  * @property {func} [onOpen] 打开响应回调*onOpen = (event, open) \n open: 是否展开*
  * @property {bool} [underline=false] 是否为单线下拉框
  * @property {titleline} 挂载结构属性
  * @property {string|object} [mountTarget='component'] 选择面板挂载的目标在哪里*可选值: component, body*
  * @property {string} [mountTargetClassName=''] 针对mountTarget != 'component'
  * @property {titleline} 自定义结构属性
  * @property {func|element} [LoadingComponent=null] 自定义Loading结构*(具体参数看案例)*
  * @property {func} [ValueAreaComponent] 选中的数据显示区域结构*(具体参数看案例)*
  * @property {func|element} [ArrowComponent] 自定义下拉箭头结构
  * @property {func|element} [AdditionComponent] 下拉面板头部附加自定义结构*(具体参数看案例)*
  * @property {func|element} [AppendDataComponent] 下拉面板数据列尾部附加自定义结构*(具体参数看案例)*
  * @property {func} [SelectionSearchComponent] 搜索结构*(具体参数看案例)*
  * @property {func} [SelectionCheckAllComponent] 全选结构*(具体参数看案例)*
  * @property {func} [SelectionReverseCheckComponent] 反选结构*(具体参数看案例)*
  * @property {func} [SelectionItemComponent] 选项结构*(具体参数看案例)*
  * @property {func} [SelectionItemDataComponent] 选项内容结构*(具体参数看案例)*
  * @property {object} [SelectionItemDataComponentProps] 选项内容结构额外参数*
  * @property {func|element} [SelectionTipComponent] 提示结构*(具体参数看案例)*
  * @property {func|element} [CheckItemIconComponent] 多选时的勾选图标结构
  * @property {func|element} [SelectionDetailComponent] data数据下可以放置细节的结构
  * @property {func|element} [SelectionDataComponent] SelectionDataComponent结构
  * @property {func|element} [SelectionAreaComponent] SelectionAreaComponent结构
  * @property {func|element} [ImgComponent] ImgComponent结构
  * @property {string|element} [valuePrefix] Value前置内容
  * @property {string|element} [valueSuffix] Value后置内容
  * @property {titleline} 性能属性
  * @property {number} [theNumberOfDisplay=0] 每列显示的条数（可用于数据量多的时候显示，不能小于一屏幕完整显示的数量）
  * @property {number} [displayItemHeight=55] 每列的高度（用于数据量多时占位计算使用）
  * @property {number} [displayTimes=2] 展示次数（theNumberOfDisplay大于0时可以指定当数据滚动到几分之一时开始展示下部分内容）
  * @property {titleline} 空状态相关
  * @property {string} [emptyType='data'] 当选中值的背景
  * @property {element|string|number} [emptyTitle] 当选中值的背景
  * @property {element|string|number} [emptyDesc] 当选中值的背景
  * @property {object} [emptyProps={}] 当选中值的背景
  * @property {titleline} CSS 相关属性
  * @property {bool} [useValueFlex] value区域是否使用flex布局
  * @property {string|number} [width] 宽度
  * @property {string|number} [height] 高度
  * @property {string|number} [lineHeight] 行高
  * @property {string|number} [minWidth] 最小宽度
  * @property {string|number} [maxWidth] 最大宽度
  * @property {string|number} [valuePadding] 选中值的内边距
  * @property {string|number} [valueFontSize] 选中值的字体大小
  * @property {string|number} [infoPadding] 选项信息的内边距
  * @property {string|number} [infoHeight] 选项信息的高度
  * @property {string|number} [infoLineHeight] 选项信息的行高
  * @property {string|number} [dropWidth] 下拉面板的宽度，设置为 auto 时为自适应
  * @property {string|number} [dropMinWidth] 下拉面板的最小宽度
  * @property {string|number} [dropMaxWidth] 下拉面板的最大宽度
  * @property {string|number} [dropOffsetY=4] 下拉面板的垂直偏移量
  * @property {string} [valueBg] 当选中值的背景
  * @property {string|number} [valueBorder] 当选中值的边框
  * @property {string|number} [valueGap] 当选中值的文本与下拉箭头之间的间距
  * @property {string|number} [searchHeight] 搜索框的高度
  * @property {string|number} [resultsPadding="7px 0"] 下拉项的内边距
  * @property {string|number} [resultsMaxHeight=252] 下拉项的最大高度
  * @property {string|number} [itemTitleWidth] 下拉项的标题的默认宽度260（仅在数据中指定了description时生效）
  * @example
  * &lt;LoadingComponent /&gt;
  *
  * &lt;ValueAreaComponent
     {...this.props},
     open,
     onOpen: this.handleOpen,
     onChange: this.handleChange,
     onClick: this.handleClick,
     data={this.getValueDataMap()}
     unClose={this.unClose} // 调用这个点击的时候不进行下拉选项关闭
 /&gt;
  *
  &lt;AdditionComponent
     {...props}
 /&gt;
  *
  &lt;AppendDataComponent
     {...props}
 /&gt;
  *
  &lt;SelectionSearchComponent
     value={searchValue}
     placeholder={searchPlaceholder}
     onChange={onSearch}
 /&gt;
  *
  &lt;SelectionCheckAllComponent
     multiple={multiple}
     checked={checkAllIsChecked}
     data={checkAllData}
     value={value}
     html={html}
     onClick={onCheckAllChange}
 /&gt;
  *
  &lt;SelectionReverseCheckComponent
     multiple={multiple}
     checked={checkAllIsChecked}
     data={reverseCheckData}
     value={value}
     html={html}
     onClick={onReverseCheckChange}
 /&gt;
  *
  &lt;SelectionItemComponent
     {...props}
     data-index={index}
     value={value}
     data={emphasize(item, props)}
     onClick={(event) => {}}
 /&gt;
  *
  &lt;SelectionItemDataComponent
     {...props}
     data={data}
     checkbox={checkbox}
     labelDom={labelDom}
     title={title}
 /&gt;
  *
  &lt;SelectionTipComponent
     tip={tip ? tip : (data.length < 1 ? emptyTip : '')}
 /&gt;
  *
  * classNameMap配置可见于：
  * https://git.woa.com/ads/spaui-basic_ui_components/tree/master/src/spaui-select/src/js/config/classname_map.js
  */
var config = {
  size: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'default',
    visualData: {
      type: 'radio',
      description: '尺寸',
      value: ['default', 'small']
    }
  },
  value: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.bool, _spauiUtils.PropTypes.number, _spauiUtils.PropTypes.string, _spauiUtils.PropTypes.array, _spauiUtils.PropTypes.object]).isRequired,
    defaultValue: '',
    visualData: {
      setType: function setType() {
        var stateData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        var result = ''; // 多选的时候是数组

        if (stateData.multiple) {
          result = 'array';
        } else {
          result = 'input';
        }

        return result;
      },
      description: '选中值'
    }
  },
  requiredValue: {
    type: _spauiUtils.PropTypes.array,
    defaultValue: []
  },
  data: {
    type: _spauiUtils.PropTypes.array.isRequired,
    defaultValue: [],
    visualData: {
      type: 'array',
      description: '选项数据'
    }
  },
  // 是否使用data数据中元素的key作为SelectionItemComponent的key，默认不提供所以不在参数文档中体现，只作为开关或特殊需要
  useDatakey: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  mode: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'default'
  },
  tagsInput: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  enabledDeleteKey: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  onChange: {
    type: _spauiUtils.PropTypes.func.isRequired
  },
  changeTriggerEvent: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'onClick'
  },
  sameValueNotTriggerChange: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  disabled: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false,
    visualData: {
      type: 'switch',
      description: '是否禁用'
    }
  },
  show: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  placeholder: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: '请选择',
    visualData: {
      type: 'input',
      description: '没有数据时的提示文案'
    }
  },
  multiple: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false,
    visualData: {
      type: 'switch',
      description: '是否多选'
    }
  },
  max: {
    type: _spauiUtils.PropTypes.number,
    visualData: {
      setType: function setType(stateData) {
        var result = '';

        if (stateData.multiple) {
          result = 'number';
        }

        return result;
      },
      description: '多选时最大选择数量'
    }
  },
  placement: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'bottom',
    visualData: {
      type: 'radio',
      value: ['bottom', 'top', 'auto'],
      description: '下拉面板展开位置'
    }
  },
  placementAlign: {
    type: _spauiUtils.PropTypes.string
  },
  autoPlacementBasic: {
    type: _spauiUtils.PropTypes.oneOf(['document', 'window']),
    defaultValue: 'document'
  },
  valueAreaType: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'string',
    visualData: {
      type: 'radio',
      value: ['string', 'labels'],
      description: '数据展示类型'
    }
  },
  showLabelAreaType: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  LabelsProps: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  showImgAreaType: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  onClickValueArea: {
    type: _spauiUtils.PropTypes.func
  },
  onClickItem: {
    type: _spauiUtils.PropTypes.func
  },
  onMouseOverItem: {
    type: _spauiUtils.PropTypes.func
  },
  onMouseOutItem: {
    type: _spauiUtils.PropTypes.func
  },
  onMouseOverData: {
    type: _spauiUtils.PropTypes.func
  },
  onMouseOutData: {
    type: _spauiUtils.PropTypes.func
  },
  tip: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: '',
    visualData: {
      type: 'input',
      description: '下拉面板提示条'
    }
  },
  emptyTip: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: '没有更多内容',
    visualData: {
      type: 'input',
      description: '没有数据的提示文案'
    }
  },
  showTip: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  valueAreaClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: '',
    visualData: {
      type: 'input',
      description: '选中数据展示区域className'
    }
  },
  valueAreaStyle: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {},
    visualData: {
      type: 'object',
      description: '选中数据展示区域style'
    }
  },
  containerClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: '',
    visualData: {
      type: 'input',
      description: 'container结构的className'
    }
  },
  containerStyle: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {},
    visualData: {
      type: 'object',
      description: 'container结构的style'
    }
  },
  dropClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: '',
    visualData: {
      type: 'input',
      description: '下拉面板结构的className'
    }
  },
  dropStyle: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {},
    visualData: {
      type: 'object',
      description: 'container结构的style'
    }
  },
  portalStyle: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  dataStyle: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {},
    visualData: {
      type: 'object',
      description: '数据结构的className'
    }
  },
  closeWhenTheValueChange: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true,
    visualData: {
      setType: function setType(stateData) {
        var result = '';

        if (!stateData.multiple) {
          result = 'switch';
        }

        return result;
      },
      description: '当值改变的时候默认进行关闭'
    }
  },
  open: {
    type: _spauiUtils.PropTypes.bool,
    visualData: {
      type: 'switch',
      description: '下拉面板是否展开'
    }
  },
  removeDataWhenNotOpen: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  onOpen: {
    type: _spauiUtils.PropTypes.func
  },
  search: {
    type: _spauiUtils.PropTypes.bool,
    visualData: {
      type: 'switch',
      description: '是否可搜索'
    }
  },
  searchCount: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: 6,
    visualData: {
      type: 'number',
      description: '多少条数据自动开启搜索'
    }
  },
  searchValue: {
    type: _spauiUtils.PropTypes.string,
    visualData: {
      type: 'input',
      description: '搜索内容'
    }
  },
  onSearchFilter: {
    type: _spauiUtils.PropTypes.func,
    defaultValue: _index.filter
  },
  emphasize: {
    type: _spauiUtils.PropTypes.func,
    defaultValue: _index.emphasize
  },
  useValue: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  useValueFlex: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  useSearchValue: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false,
    visualData: {
      type: 'switch',
      description: '没有匹配中数据时，搜索词是否可选中'
    }
  },
  useSearchValueData: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false,
    visualData: {
      type: 'switch',
      description: '是否只展示searchValueData'
    }
  },
  searchValueData: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.object, _spauiUtils.PropTypes.array]),
    visualData: {
      type: 'object',
      description: '使用搜索词自行展示匹配的数据'
    }
  },
  searchEmptyTip: {
    type: _spauiUtils.PropTypes.any
  },
  valueFieldName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'value',
    visualData: {
      type: 'input',
      description: 'value字段在data中对应的字段名'
    }
  },
  labelFieldName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'label',
    visualData: {
      type: 'input',
      description: '文案字段在data中对应的字段名'
    }
  },
  theme: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: '',
    visualData: {
      type: 'radio',
      description: '下拉框主题',
      value: ['', 'light']
    }
  },
  headless: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false,
    visualData: {
      type: 'switch',
      description: '是否作为无头下拉框使用'
    }
  },
  underline: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  supplementaryData: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false,
    visualData: {
      type: 'switch',
      description: '对于value中有些数据是不在data的是否补充显示'
    }
  },
  setSupplementaryData: {
    type: _spauiUtils.PropTypes.func
  },
  searchFields: {
    type: _spauiUtils.PropTypes.array,
    visualData: {
      type: 'input',
      description: '可搜索字段'
    }
  },
  searchPlaceholder: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: '搜索',
    visualData: {
      type: 'input',
      description: '搜索输入框提示文案'
    }
  },
  convertPYs: {
    type: _spauiUtils.PropTypes.func
  },
  starMatch: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  searchClear: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.bool]),
    defaultValue: true,
    visualData: {
      type: 'switch'
    }
  },
  closeFilter: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  onSearchClear: _spauiUtils.PropTypes.func,
  onSearchKeyDown: _spauiUtils.PropTypes.func,
  onSearch: {
    type: _spauiUtils.PropTypes.func
  },
  html: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false,
    visualData: {
      type: 'switch',
      description: '是否支持文案为html字符串'
    }
  },
  clear: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false,
    visualData: {
      type: 'switch',
      description: '是否可清空'
    }
  },
  clearSearchValueWhenChange: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  onClear: {
    type: _spauiUtils.PropTypes.func
  },
  separator: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: '，',
    visualData: {
      setType: function setType(stateData) {
        return stateData.multiple ? 'input' : '';
      },
      description: '多选时的分隔符'
    }
  },
  mountTarget: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.object]),
    defaultValue: 'component',
    visualData: {
      type: 'radio',
      value: ['component', 'body'],
      description: '下拉面板挂在的位置'
    }
  },
  mountTargetClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: '',
    visualData: {
      type: 'input',
      description: '针对mountTarget != component挂载结构的className'
    }
  },
  checkAll: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false,
    visualData: {
      setType: function setType(stateData) {
        return stateData.multiple ? 'switch' : '';
      },
      description: '是否支持全选'
    }
  },
  stickCheckAll: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  checkAllLabel: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: '全选',
    visualData: {
      type: 'input',
      description: '全选文案'
    }
  },
  reverseCheck: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  reverseCheckLabel: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: '反选'
  },
  searchCheckAll: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false,
    visualData: {
      type: 'switch',
      description: '是否全选搜索匹配的所有数据'
    }
  },
  setValueLabel: {
    type: _spauiUtils.PropTypes.func
  },
  valueLabelField: {
    type: _spauiUtils.PropTypes.array
  },
  labelData: {
    type: _spauiUtils.PropTypes.array,
    defaultValue: [],
    visualData: {
      type: 'array',
      description: '映射value中不在data的数据'
    }
  },
  loading: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false,
    visualData: {
      type: 'switch',
      description: '是否加载中'
    }
  },
  needArrowIcon: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true,
    visualData: {
      type: 'switch',
      description: '是否需要箭头'
    }
  },
  titleable: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  customTitle: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  AdditionComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element])
  },
  AppendDataComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element])
  },
  LoadingComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _spauiLoading["default"]
  },
  ValueAreaComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element])
  },
  ArrowComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element])
  },
  SelectionAreaComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _selection_area_component["default"]
  },
  ImgComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element])
  },
  valuePrefix: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.element])
  },
  valueSuffix: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.element])
  },
  SelectionDataComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _selection_data_component["default"]
  },
  showDataComponent: {
    type: _spauiUtils.PropTypes.bool
  },
  SelectionDetailComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element])
  },
  SelectionCheckAllComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _selection_checkall_component["default"]
  },
  SelectionReverseCheckComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _selection_reverse_check_component["default"]
  },
  SelectionItemComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element])
  },
  SelectionItemDataComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element])
  },
  SelectionItemDataComponentProps: {
    type: _spauiUtils.PropTypes.object
  },
  SelectionTipComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _selection_tip_component["default"]
  },
  SelectionSearchComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _selection_search_component["default"]
  },
  CheckItemIconComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element])
  },
  selectedValueAutoToView: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  classNameMap: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  dataTipTriggerProps: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  dataTipPopOverProps: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  useCheckMark: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  // 用于兼容旧版本的Select，不在参数里面体现，主要是作为隐藏功能，优先让使用者升级
  compactOldSelect: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  theNumberOfDisplay: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: 0
  },
  displayItemHeight: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: 36
  },
  displayTimes: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: 2
  },
  width: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number])
  },
  height: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number])
  },
  lineHeight: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number])
  },
  minWidth: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number])
  },
  maxWidth: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number])
  },
  valueFontSize: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number])
  },
  valuePadding: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number])
  },
  infoPadding: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number])
  },
  infoHeight: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number])
  },
  infoLineHeight: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number])
  },
  dropWidth: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number])
  },
  dropMinWidth: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number])
  },
  dropMaxWidth: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number])
  },
  dropOffsetY: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number])
  },
  valueBg: {
    type: _spauiUtils.PropTypes.string
  },
  valueBorder: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number])
  },
  valueGap: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number])
  },
  searchHeight: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number])
  },
  resultsPadding: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number])
  },
  resultsMaxHeight: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number])
  },
  itemTitleWidth: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number])
  },
  emptyTitle: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.element])
  },
  emptyDesc: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.element])
  },
  emptyType: {
    type: _spauiUtils.PropTypes.oneOf(['normal', 'search', 'data']),
    defaultValue: 'data'
  },
  emptyProps: {
    type: _spauiUtils.PropTypes.object
  }
}; // 转化成PureComponent组件的配置

exports.config = config;
var configData = (0, _spauiUtils.formatProps)(config); // 导出

var propTypes = configData.propTypes;
exports.propTypes = propTypes;
var defaultProps = configData.defaultProps;
exports.defaultProps = defaultProps;
var _default = {
  config: config,
  propTypes: propTypes,
  defaultProps: defaultProps
};
exports["default"] = _default;