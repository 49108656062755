"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
// 分隔符组件
var SeparatorComponent = function SeparatorComponent() {
  return _react["default"].createElement("span", {
    className: "sp"
  }, _react["default"].createElement("svg", {
    width: "11",
    height: "12",
    xmlns: "http://www.w3.org/2000/svg"
  }, _react["default"].createElement("path", {
    d: "M10.45.516L.55 31.484",
    stroke: "#DCDEE3",
    fill: "none",
    fillRule: "evenodd",
    strokeLinecap: "square"
  })));
};

var _default = SeparatorComponent;
exports["default"] = _default;