"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _spauiPopover = require("@tencent/spaui-popover");

var _radio_props = require("../radio_props");

var _spauiUtils = require("@tencent/spaui-utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var _default = function _default() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _props$tip = props.tip,
      tip = _props$tip === void 0 ? '' : _props$tip,
      _props$OverlayTrigger = props.OverlayTriggerProps,
      OverlayTriggerProps = _props$OverlayTrigger === void 0 ? {} : _props$OverlayTrigger,
      _props$tipStyle = props.tipStyle,
      tipStyle = _props$tipStyle === void 0 ? {} : _props$tipStyle,
      TipIconComponent = props.TipIconComponent;
  var result = null;
  var tipIconComponent = null;

  if (typeof TipIconComponent !== 'undefined' && TipIconComponent) {
    if ((0, _spauiUtils.isReactNode)(TipIconComponent)) {
      tipIconComponent = TipIconComponent;
    } else {
      tipIconComponent = _react["default"].createElement(TipIconComponent, props);
    }
  }

  if (tip) {
    result = _react["default"].createElement(_spauiPopover.OverlayTrigger, _extends({
      overlay: _react["default"].createElement(_spauiPopover.PopOver, null, tip)
    }, _radio_props.defaultOverlayTriggerProps, OverlayTriggerProps), _react["default"].createElement("span", {
      style: tipStyle,
      className: "spaui-radio-tip",
      onClick: function onClick(event) {
        event.preventDefault();
      }
    }, tipIconComponent));
  }

  return result;
};

exports["default"] = _default;