"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _spauiIcon = _interopRequireDefault(require("@tencent/spaui-icon"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
var SortIconComponent = function SortIconComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var sortOrder = props.sortOrder;
  var result = null;

  if (sortOrder === 'asc') {
    result = _react["default"].createElement(_spauiIcon["default"], {
      type: "sort-asc"
    });
  } else if (sortOrder === 'desc') {
    result = _react["default"].createElement(_spauiIcon["default"], {
      type: "sort-desc"
    });
  }

  return result;
};

var _default = SortIconComponent;
exports["default"] = _default;