"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _spauiUtils = require("@tencent/spaui-utils");

var _classnames = _interopRequireDefault(require("classnames"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _default = function _default(props) {
  var _props$type = props.type,
      type = _props$type === void 0 ? '' : _props$type,
      show = props.show,
      stroke = props.stroke,
      circleSize = props.circleSize,
      display = props.display,
      devProps = props.devProps,
      _props$useNewUI = props.useNewUI,
      useNewUI = _props$useNewUI === void 0 ? true : _props$useNewUI,
      theme = props.theme; // 统一获取全局样式

  var getGlobalProps = function getGlobalProps() {
    var customProps = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return (0, _spauiUtils.globalProps)(props, customProps, devProps[0], devProps[1]);
  };

  var result = null;
  var loaderStyle = {};
  var svgLoading = null;

  if (type === 'page') {
    // 整个页面的loading
    if (show) {
      result = _react["default"].createElement("div", getGlobalProps({
        className: 'module-loading spaui-loading loading-new'
      }), _react["default"].createElement("div", {
        className: "spin-loader"
      }, _react["default"].createElement("svg", {
        className: "spin-circular",
        viewBox: "25 25 50 50"
      }, _react["default"].createElement("circle", {
        className: "spin-path",
        cx: "50",
        cy: "50",
        r: "20",
        fill: "none"
      }))));
    }
  } else if (type === 'svg') {
    // svg的loading
    svgLoading = _react["default"].createElement("svg", {
      className: "spin-circular",
      viewBox: "25 25 50 50"
    }, _react["default"].createElement("circle", {
      className: "spin-path",
      style: {
        stroke: stroke
      },
      cx: "50",
      cy: "50",
      r: "20",
      fill: "none"
    })); // svg的loading

    if (typeof show === 'undefined' || show) {
      if (display === 'inline-block' || display === 'center') {
        // 居中的时候要设置下边距
        if (display === 'center') {
          loaderStyle = {
            marginTop: -circleSize / 2,
            marginLeft: -circleSize / 2
          };
        }

        result = _react["default"].createElement("div", getGlobalProps({
          className: (0, _classnames["default"])("spin-loader spaui-loading-spin-loader-".concat(display), _defineProperty({}, "spaui-loading-".concat(theme), theme)),
          style: _objectSpread({
            width: "".concat(circleSize, "px")
          }, loaderStyle)
        }), svgLoading);
      } else {
        result = _react["default"].createElement("div", getGlobalProps({
          className: 'spaui-loading loading-new'
        }), _react["default"].createElement("div", {
          className: "spin-loader",
          style: {
            width: "".concat(circleSize, "px")
          }
        }, svgLoading));
      }
    }
  } else {
    // 图片loading
    if (typeof show === 'undefined' || show) {
      // 根据要展示的不一样，设置不一样的classname
      if (display === 'inline-block' || display === 'center') {
        result = useNewUI ? _react["default"].createElement("div", getGlobalProps({
          className: (0, _classnames["default"])("spaui-loading ".concat(display), _defineProperty({}, "spaui-loading-".concat(theme), theme))
        })) : _react["default"].createElement("i", getGlobalProps({
          className: "icon ico-loading-blue spaui-loading-blue-icon-".concat(display)
        }), _react["default"].createElement("i", null));
      } else {
        result = _react["default"].createElement("div", getGlobalProps({
          className: "spaui-loading".concat(useNewUI ? '' : ' loading-new').concat(theme === 'light' ? ' spaui-loading-light' : '')
        }), !useNewUI ? _react["default"].createElement("i", {
          className: "icon ico-loading-blue"
        }, _react["default"].createElement("i", null)) : null);
      }
    }
  }

  return result;
};

exports["default"] = _default;