"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultProps = exports.propTypes = exports.config = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

var _value_area_component = _interopRequireDefault(require("./component/value_area_component"));

var _list_component = _interopRequireDefault(require("./component/list_component"));

var _cell_component = _interopRequireDefault(require("./component/cell_component"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件参数配置
 */

/**
 * DatePicker 接口参数
 * @alias DatePickerProps
 * @description 输入或选择日期的控件。
 * @property {titleline} 默认支持参数
 * @property {blankline} className;style;id;data-*;accessKey;dir;draggable;tabIndex;title;htmlRef;
 * @property {titleline} 基础属性
 * @property {string} [size='default'] 尺寸 *可选值: default(默认), small(小尺寸)*
 * @property {string} [type='single'] 日历类型 *可选值: single(单日期), range(日期范围)*
 * @property {string} [picker="date"] 选择器类型 *可选值: date, week, month, year*
 * @property {bool} [hideEndDatePanel=false] type=range时可以选择是否隐藏结束日期面板（也就是只在一个日期面板选择开始结束日期）
 * @property {bool} [hideSubmitButton=false] 适用于没有开启时分秒的情况
 * @property {bool} [input=false] 是否支持输入日期
 * @property {string|array} [inputDateFormat="YYYYMMDD"] 日期格式*比如:'YYYY/MM/DD'（传递数组可以支持多种格式）*
 * @property {string} [inputDateSeparator='   -   '] 输入的双日期中，起始日期和结束日期见的分隔符
 * @property {string|number} date='' 日期*（单日期的时候使用，也可通过 value 进行传值）*
 * @property {string|number} startDate='' 开始日期*（双日期的时候使用，也可通过 value={start: startDate} 进行传值）*
 * @property {string|number} endDate='' 结束日期*（双日期的时候使用，也可通过 value={end: endDate} 进行传值）*
 * @property {bool} [useTimeStamp=false] date,startDate,endDate是否使用时间戳的格式
 * @property {func} onChange 切换状态回调函数*单日期：onChange(event, date){} \n 双日期：onChange(event, startDate, endDate)*
 * @property {string} [format='XXXX-XX-XX’] 日历格式*(使用的数据格式)*
 * @property {string} [showFormat] 日历展示格式*(只用于组件展示)*
 * @property {string} [placeholder='请选择日期'] 没有数据时的提示文案
 * @property {string} [currentTime=''] 当前时间，默认为本地时间*格式如：2019/1/9*
 * @property {string|object} [mountTarget='component'] 选择面板挂载的目标在哪里*可选值: component, body*
 * @property {string} [bodyDropDownClassName] 用于dropdown从body添加后可以另外加classname来设置样式
 * @property {bool} [onInputChange] 临时选择数据的回调*单日期：onChange(event, date){} \n 双日期：onChange(event, startDate, endDate)*
 * @property {object} [classNameMap={}] 组件内对应结构的默认className(可以针对对应位置进行替换,具体参数见案例)
 * @property {bool} [hideNoCurrentMonthDate=false] 是否隐藏上个月日期的展示
 * @property {bool} [autoSelectEndDate=false] 是否自动选择结束时间（当前仅在设置了时间跨度下才生效，且 shortestRange 等于 longestRange）
 * @property {bool} [useNewUI=false] 是否使用新版 UI
 * @property {string} [defaultShowDatePosition] 默认展示的日期面板的靠近日期*可选值：start, end*
 * @property {object} [inputProps={}] 输入框的props
 * @property {titleline} 面板属性
 * @property {number} [panelWeekStart=0] 指定面板中开始于周几*可选值: 1（周一）, 0（周日）*
 * @property {bool} [showPanelFooter=true] 默认显示日期下拉面板的底部 (仅新版 UI 下生效)
 * @property {bool} [showDatePlay=true] 是否展示日期展示面板
 * @property {element} [dropdownPrefix] 下拉面板前置内容
 * @property {titleline} 自定义属性
 * @property {func|element} [CellComponent] 单个日期项的自定义组件
 * @property {titleline} 清空属性
 * @property {bool} [clear=false] 是否清空选择
 * @property {func} [onClear=function(event){}] 清空临时选择时候回调
 * @property {func} [onInputValueClear=function(event){}] 清空输入的日期值时的回调
 * @property {titleline} 监听控制面板打开属性
 * @property {bool} [open] 是否打开
 * @property {func} [onOpen] 打开回调*onOpen(event, open)*
 * @property {titleline} 禁止属性
 * @property {bool} [startDateDisabled=false] 开始日期禁止更改
 * @property {bool} [endDateDisabled=false] 结束日期禁止更改
 * @property {func} [disableDate] 禁用面板中的特定日期*disableDate(date: Date)=>boolean*
 * @property {bool} [disabled=false] 是否禁用
 * @property {titleline} 快捷选择属性
 * @property {bool} [useShortcut=false] 是否开启快捷选择按钮
 * @property {string} [shortcuts=[{startDate: '', endDate: '', label: ''}]] 快捷选择*(具体参数看案例)*
 * @property {func} [setShortcuts] 设置快捷日期配置（参数为当前面板选中的临时值）
 * @property {titleline} 时分秒相关属性
 * @property {bool} [timePicker=false] 是否需要时刻表
 * @property {object} [timePickerProps={}] time组件的props（单日期的时候使用）
 * @property {object} [startTimePickerProps={}] time组件的props（双日期的时候使用）
 * @property {object} [endTimePickerProps={}] time组件的props（双日期的时候使用）
 * @property {number} [maxHour=23] 默认小时最大值
 * @property {titleline} 选择面板位置属性
 * @property {bool} [positionSetByCss=false] 日期面板的位置是否由css设置
 * @property {string} [autoPlacementBasic='document'] 面板自动展开的依据*可选值：window（窗口）、document（文档流）*
 * @property {string} [horizontalPosition='right'] 水平方向*可选值: left, right*
 * @property {string} [verticalPosition='bottom'] 垂直方向*可选值: top, bottom, auto*
 * @property {object} [positionDeviation={}] 面板位置偏差调整*值可为{top, left, bottom, right}*
 * @property {titleline} 选择所选择时间跨度长短属性
 * @property {number} [shortestRange=0] 最短的时间跨度*如果 picker 参数为 month, 则单位为月*
 * @property {number} [longestRange=180] 最长的时间跨度（这里算法如5月1日到31日，31-1=30所以range是30）如果picker参数为month则单位为月
 * @property {number} [minTimeRange] 最短的时间宽度（毫秒级别）
 * @property {number} [maxTimeRange] 最长的时间宽度（毫秒级别）
 * @property {titleline} 选择面板默认展示时间属性
 * @property {number} [year] 默认展示的年份*（单日期的时候使用）*
 * @property {number} [month] 默认展示的月份*（单日期的时候使用）*
 * @property {number} [startYear] 默认展示的年份*（双日期的时候使用）*
 * @property {number} [startMonth] 默认展示的月份*（双日期的时候使用）*
 * @property {number} [endYear] 默认展示的年份*（双日期的时候使用）*
 * @property {number} [endMonth] 默认展示的月份*（双日期的时候使用）*
 * @property {number} [firstDayOfWeek] 默认选中的日期从周几开始 *（周选择器用）*
 * @property {number} [fixedNumber] 固定选中前几周或后几周, 为正数代表前几周，为负数代表后几周 *（周选择器用）*
 * @property {number} [fixedTips] 固定周时的提示文案 *（周选择器用）*
 * @property {titleline} 设置可选日期范围属性
 * @property {number} [optionalStartRelativeDays=-365] 可选开始相对时间
 * @property {number} [optionalEndRelativeDays=0] 可选结束相对时间
 * @property {string} [optionalReferenceTime] 相对时间的参照时间*默认为今天*
 * @property {string} [optionalStartDate] 可选开始日期
 * @property {string} [optionalEndDate] 可选结束日期
 * @property {bool} [fulfilStartDateAndRelativeDays=false] 可选开始时间是否要同时满足相对与绝对时间*值为false时以optionalStartDate为准*
 * @property {bool} [fulfilEndDateAndRelativeDays=false] 可选结束时间是否要同时满足相对与绝对时间*值为false时以optionalEndDate为准*
 * @property {titleline} 无限日期相关参数（只可用于type=range）
 * @property {string|number} [unlimitedDateValue=''] 如果发现传入的日期的值等同于该值，就说明是无限日期
 * @property {string} [unlimitedDateValueLabel='无限'] 无限日期文案
 * @property {bool} [startDateCanBeUnlimited=false] 开始日期是否可以无限
 * @property {bool} [endDateCanBeUnlimited=false] 结束日期是否可以无限
 * @property {titleline} 文案相关
 * @property {func} [setLabel] 设置文案*setLabel=(type, data)=> return string*
 * @property {func} [setDateLabel] 设置选择日期后展示的文案
 * @property {string} [singleDateInputFormatErrorLabel] 单日期输入格式错误提示文案
 * @property {string} [rangeDateInputFormatErrorLabel] 双日期输入格式错误提示文案
 * @property {titleline} 动效相关
 * @property {string} [transitionName='slide-up'] 自定义动效名称 遵循 od-animate 规范
 * @property {func|element} [MonthPanelListComponent] 自定义月份选择面板
 * @property {func|element} [YearPanelListComponent] 自定义年选择面板
 *
 * @see
 *
 * onChange typings:
 *     (event: any, dateOrStartDate: any, endDate: any) => any
 *
 * ====
 *
 * onClear typings:
 *     (event: any) => any
 *
 * ====
 *
 * onInputValueClear typings:
 *     (event: any) => any
 *
 * ====
 *
 * onOpen typings:
 *     (event: any, isOpen: any) => any
 *
 * ====
 *
 * setLabel typings:
 *     (type: any, data: any) => any
 *
 * @example
// 单日期默认快捷选择配置
export const singleDefaultShortcuts = [
    { relativeDate: 0, label: '今天' },
    { relativeDate: -1, label: '昨天' },
    { relativeDate: -6, label: '7天前' },
    { relativeDate: -29, label: '30天前' }
];

// 双日期默认快捷选择配置
export const rangeDefaultShortcuts = [
    { startRelativeDate: 0, endRelativeDate: 0, label: '今天' },
    { startRelativeDate: -1, endRelativeDate: -1, label: '昨天' },
    { startRelativeDate: -6, endRelativeDate: 0, label: '最近7天' },
    { startRelativeDate: -29, endRelativeDate: 0, label: '最近30天' },
    { startRelativeDate: -89, endRelativeDate: 0, label: '最近90天' },
    // 设置了dynamicDate为true 会在选择了startDate但endDate没选时生效，并以startDate为相对日期
    { startRelativeDate: 0, endRelativeDate: 6, dynamicDate: true, label: '往后7天' },
    { startRelativeDate: 0, endRelativeDate: 29, dynamicDate: true, label: '往后30天' },
    { startRelativeDate: 0, endRelativeDate: 89, dynamicDate: true, label: '往后90天' }
];
    */
var config = {
  size: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'default'
  },
  value: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number, _spauiUtils.PropTypes.object])
  },
  date: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number]),
    defaultValue: '',
    visualData: {
      setType: function setType(stateData) {
        return stateData.type !== 'range' ? 'input' : '';
      }
    }
  },
  startDate: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number]),
    defaultValue: '',
    visualData: {
      setType: function setType(stateData) {
        return stateData.type === 'range' ? 'input' : '';
      }
    }
  },
  endDate: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number]),
    defaultValue: '',
    visualData: {
      setType: function setType(stateData) {
        return stateData.type === 'range' ? 'input' : '';
      }
    }
  },
  useTimeStamp: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  onChange: {
    type: _spauiUtils.PropTypes.func.isRequired
  },
  canChooseOneDateByRange: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  timePickerIsOptional: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  resetTimeWhenDateChange: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  startDateDisabled: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false,
    visualData: {
      setType: function setType(stateData) {
        return stateData.type === 'range' ? 'switch' : '';
      }
    }
  },
  endDateDisabled: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false,
    visualData: {
      setType: function setType(stateData) {
        return stateData.type === 'range' ? 'switch' : '';
      }
    }
  },
  disableDate: {
    type: _spauiUtils.PropTypes.func
  },
  format: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'xxxx-xx-xx',
    visualData: {
      type: 'input'
    }
  },
  showFormat: {
    type: _spauiUtils.PropTypes.string
  },
  timePicker: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false,
    visualData: {
      type: 'switch'
    }
  },
  timePickerProps: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {},
    visualData: {
      setType: function setType(stateData) {
        return stateData.type !== 'range' ? 'object' : '';
      }
    }
  },
  startTimePickerProps: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {},
    visualData: {
      setType: function setType(stateData) {
        return stateData.type === 'range' ? 'object' : '';
      }
    }
  },
  endTimePickerProps: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {},
    visualData: {
      setType: function setType(stateData) {
        return stateData.type === 'range' ? 'object' : '';
      }
    }
  },
  maxHour: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: 23
  },
  disabled: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false,
    visualData: {
      type: 'switch'
    }
  },
  open: {
    type: _spauiUtils.PropTypes.bool,
    visualData: {
      type: 'switch'
    }
  },
  onOpen: {
    type: _spauiUtils.PropTypes.func
  },
  type: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'single',
    visualData: {
      type: 'radio',
      value: ['single', 'range']
    }
  },
  defaultShowDatePosition: {
    type: _spauiUtils.PropTypes.oneOf(['start', 'end'])
  },
  picker: {
    type: _spauiUtils.PropTypes.oneOf(['date', 'month', 'year', 'week']),
    defaultValue: 'date'
  },
  hideEndDatePanel: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false,
    visualData: {
      type: 'switch'
    }
  },
  hideSubmitButton: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false,
    visualData: {
      type: 'switch'
    }
  },
  placeholder: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: '请选择日期',
    visualData: {
      type: 'input'
    }
  },
  input: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false,
    visualData: {
      type: 'switch'
    }
  },
  inputDateFormat: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.array, _spauiUtils.PropTypes.string]),
    defaultValue: 'YYYYMMDD'
  },
  inputDateSeparator: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: '   -   '
  },
  horizontalPosition: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'right',
    visualData: {
      type: 'radio',
      value: ['left', 'right']
    }
  },
  verticalPosition: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'bottom',
    visualData: {
      type: 'radio',
      value: ['bottom', 'top', 'auto']
    }
  },
  positionDeviation: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {},
    visualData: {
      type: 'object'
    }
  },
  positionSetByCss: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false,
    visualData: {
      type: 'switch'
    }
  },
  autoPlacementBasic: {
    type: _spauiUtils.PropTypes.oneOf(['document', 'window']),
    defaultValue: 'document'
  },
  shortestRange: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.number, _spauiUtils.PropTypes.string]),
    defaultValue: 0,
    visualData: {
      type: 'number'
    }
  },
  minTimeRange: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.number, _spauiUtils.PropTypes.string]),
    visualData: {
      type: 'number'
    }
  },
  longestRange: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.number, _spauiUtils.PropTypes.string]),
    defaultValue: 180,
    visualData: {
      type: 'number'
    }
  },
  maxTimeRange: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.number, _spauiUtils.PropTypes.string]),
    visualData: {
      type: 'number'
    }
  },
  optionalStartRelativeDays: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.number, _spauiUtils.PropTypes.string]),
    defaultValue: -365,
    visualData: {
      type: 'number'
    }
  },
  optionalEndRelativeDays: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.number, _spauiUtils.PropTypes.string]),
    defaultValue: 0,
    visualData: {
      type: 'number'
    }
  },
  optionalReferenceTime: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: '',
    visualData: {
      type: 'input'
    }
  },
  optionalStartDate: {
    type: _spauiUtils.PropTypes.string,
    visualData: {
      type: 'input'
    }
  },
  optionalEndDate: {
    type: _spauiUtils.PropTypes.string,
    visualData: {
      type: 'input'
    }
  },
  fulfilStartDateAndRelativeDays: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  fulfilEndDateAndRelativeDays: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  useShortcut: {
    type: _spauiUtils.PropTypes.bool,
    visualData: {
      type: 'switch'
    }
  },
  shortcuts: {
    type: _spauiUtils.PropTypes.array,
    visualData: {
      type: 'array'
    }
  },
  setShortcuts: {
    type: _spauiUtils.PropTypes.func
  },
  clear: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false,
    visualData: {
      type: 'switch'
    }
  },
  onClear: {
    type: _spauiUtils.PropTypes.func,
    defaultValue: _spauiUtils.noop
  },
  onInputValueClear: {
    type: _spauiUtils.PropTypes.func,
    defaultValue: _spauiUtils.noop
  },
  year: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.number, _spauiUtils.PropTypes.string]),
    visualData: {
      setType: function setType(stateData) {
        return stateData.type !== 'range' ? 'input' : '';
      }
    }
  },
  month: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.number, _spauiUtils.PropTypes.string]),
    visualData: {
      setType: function setType(stateData) {
        return stateData.type !== 'range' ? 'input' : '';
      }
    }
  },
  startYear: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.number, _spauiUtils.PropTypes.string]),
    visualData: {
      setType: function setType(stateData) {
        return stateData.type === 'range' ? 'input' : '';
      }
    }
  },
  startMonth: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.number, _spauiUtils.PropTypes.string]),
    visualData: {
      setType: function setType(stateData) {
        return stateData.type === 'range' ? 'input' : '';
      }
    }
  },
  endYear: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.number, _spauiUtils.PropTypes.string]),
    visualData: {
      setType: function setType(stateData) {
        return stateData.type === 'range' ? 'input' : '';
      }
    }
  },
  endMonth: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.number, _spauiUtils.PropTypes.string]),
    visualData: {
      setType: function setType(stateData) {
        return stateData.type === 'range' ? 'input' : '';
      }
    }
  },
  onInputChange: {
    type: _spauiUtils.PropTypes.func
  },
  currentTime: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: '',
    visualData: {
      type: 'input'
    }
  },
  mountTarget: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.object]),
    defaultValue: 'component',
    visualData: {
      type: 'radio',
      value: ['component', 'body']
    }
  },
  bodyDropDownClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: '',
    visualData: {
      type: 'input'
    }
  },
  ValueAreaComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _value_area_component["default"]
  },
  MonthPanelListComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _list_component["default"]
  },
  YearPanelListComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _list_component["default"]
  },
  CellComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _cell_component["default"]
  },
  dropdownPrefix: {
    type: _spauiUtils.PropTypes.element,
    defaultValue: null
  },
  unlimitedDateValue: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number]),
    defaultValue: ''
  },
  unlimitedDateValueLabel: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: '无限'
  },
  startDateCanBeUnlimited: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  endDateCanBeUnlimited: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  classNameMap: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  inputProps: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  hideNoCurrentMonthDate: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  autoSelectEndDate: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  useNewUI: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  panelWeekStart: {
    type: _spauiUtils.PropTypes.oneOf([0, 1]),
    defaultValue: 0
  },
  showPanelFooter: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  showDatePlay: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  locale: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  setLabel: {
    type: _spauiUtils.PropTypes.func
  },
  setDateLabel: {
    type: _spauiUtils.PropTypes.func
  },
  singleDateInputFormatErrorLabel: {
    type: _spauiUtils.PropTypes.string
  },
  rangeDateInputFormatErrorLabel: {
    type: _spauiUtils.PropTypes.string
  },
  transitionName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'slide-up'
  }
}; // 转化成PureComponent组件的配置

exports.config = config;
var configData = (0, _spauiUtils.formatProps)(config); // 导出

var propTypes = configData.propTypes;
exports.propTypes = propTypes;
var defaultProps = configData.defaultProps;
exports.defaultProps = defaultProps;
var _default = {
  config: config,
  propTypes: propTypes,
  defaultProps: defaultProps
};
exports["default"] = _default;