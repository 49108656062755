"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultProps = exports.propTypes = exports.config = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件参数配置
 */

/**
 * Badge 接口参数
 * @alias BadgeProps
 * @property {titleline} 默认支持参数
 * @property {blankline} className;style;id;data-*;accessKey;dir;draggable;tabIndex;title;htmlRef;
 * @property {titleline} 基础属性
 * @property {string} [type="number"] 类型*可选值: dot(不展示数字且只有一个小红点), number*
 * @property {string} [color] 自定义小圆点的颜色
 * @property {ReactNode} [count] 展示的数字
 * @property {bool} [showZero=true] 当数值为 0 时，是否展示 Badge
 * @property {number} [overflowCount=99] 展示封顶的数字值
 * @property {string} [status=""] 设置 Badge 为状态点*可选值: info, success, warning, error, danger, disabled*
 * @property {string|element} [text=""] 在设置了 status 的前提下有效，设置状态点的文本
 * @property {string} [textSize="normal"] 文本大小*可选值: normal, small*
 * @property {string} [textColor] 文本颜色
 * @property {object} [badgeStyle] 自定义 badge 的样式
 * @property {bool} [processing=false] 状态点是否是进行中的状态
 */
var config = {
  type: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'number'
  },
  color: {
    type: _spauiUtils.PropTypes.string
  },
  count: {
    type: _spauiUtils.PropTypes.node
  },
  status: {
    type: _spauiUtils.PropTypes.oneOf(['info', 'success', 'warning', 'error', 'danger', 'disabled'])
  },
  text: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.element])
  },
  textSize: {
    type: _spauiUtils.PropTypes.oneOf(['normal', 'small']),
    defaultValue: 'normal'
  },
  textColor: {
    type: _spauiUtils.PropTypes.string
  },
  processing: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  showZero: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  overflowCount: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: 99
  },
  badgeStyle: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  }
}; // 转化成PureComponent组件的配置

exports.config = config;
var configData = (0, _spauiUtils.formatProps)(config); // 导出

var propTypes = configData.propTypes;
exports.propTypes = propTypes;
var defaultProps = configData.defaultProps;
exports.defaultProps = defaultProps;
var _default = {
  config: config,
  propTypes: propTypes,
  defaultProps: defaultProps
};
exports["default"] = _default;