"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

var StatusComponent = function StatusComponent(props) {
  var name = props.name,
      status = props.status,
      progress = props.progress,
      setUploader = props.setUploader,
      onDelete = props.onDelete,
      reupload = props.reupload,
      canDelete = props.canDelete,
      _props$id = props.id,
      id = _props$id === void 0 ? '' : _props$id,
      style = props.style,
      _props$reuploadText = props.reuploadText,
      reuploadText = _props$reuploadText === void 0 ? '' : _props$reuploadText,
      _props$uploadingText = props.uploadingText,
      uploadingText = _props$uploadingText === void 0 ? '' : _props$uploadingText,
      onReuploadClick = props.onReuploadClick;
  var labelId = id ? "".concat(id, "-label") : '';
  var label = status === 'uploading' ? uploadingText : name;
  var reuploadDom = null;
  var labelDom = null;

  if (reupload) {
    if (setUploader) {
      reuploadDom = setUploader(_react["default"].createElement("a", {
        className: "spaui-upfile-reset spaui-cursor-pointer",
        onClick: onReuploadClick
      }, reuploadText));
    } else {
      reuploadDom = _react["default"].createElement("a", {
        className: "spaui-upfile-reset spaui-cursor-pointer",
        id: "".concat(id, "-upload"),
        onClick: onReuploadClick
      }, reuploadText);
    }
  }

  if (_typeof(label) === 'object') {
    labelDom = _react["default"].createElement("span", {
      className: "spaui-upfile-label",
      id: labelId
    }, label);
  } else {
    labelDom = _react["default"].createElement("span", {
      className: "spaui-upfile-label",
      id: labelId,
      dangerouslySetInnerHTML: {
        __html: label
      }
    });
  }

  return _react["default"].createElement("div", {
    className: "spaui-upfile-inner",
    id: id,
    style: style
  }, _react["default"].createElement("i", {
    className: "spaui-upfile-icon"
  }), labelDom, _react["default"].createElement("span", {
    className: "spaui-upfile-action"
  }, reuploadDom, canDelete ? _react["default"].createElement("a", {
    className: "spaui-upfile-del spaui-cursor-pointer",
    onClick: onDelete
  }, _react["default"].createElement("i", {
    className: "spaui-upfile-icon-del"
  })) : null, status === 'uploading' ? _react["default"].createElement("progress", {
    className: "spaui-upfile-progress",
    value: progress || 0,
    max: "100"
  }) : null));
};

var _default = StatusComponent;
exports["default"] = _default;