"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
var SwitchDotsComponent = function SwitchDotsComponent(props) {
  var _props$data = props.data,
      data = _props$data === void 0 ? [] : _props$data,
      index = props.index,
      onCurrent = props.onCurrent; // 小于2个就不展示

  if (data.length < 2) {
    return null;
  }

  return _react["default"].createElement("div", {
    className: "viewer-switchdots"
  }, data.map(function (item, itemIndex) {
    return _react["default"].createElement("a", {
      onClick: function onClick(event) {
        onCurrent(event, itemIndex);
      },
      key: itemIndex,
      className: (0, _classnames["default"])('dots', {
        on: itemIndex === index
      })
    }, _react["default"].createElement("span", null));
  }));
};

var _default = SwitchDotsComponent;
exports["default"] = _default;