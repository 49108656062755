"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _spauiCheckbox = _interopRequireDefault(require("@tencent/spaui-checkbox"));

var _spauiRadio = _interopRequireDefault(require("@tencent/spaui-radio"));

var _spauiUtils = require("@tencent/spaui-utils");

var _text_component = _interopRequireDefault(require("./text_component"));

var _index = require("../utils/index");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var INDETERMINATE = _spauiUtils.nodeTree.INDETERMINATE,
    CHECKED = _spauiUtils.nodeTree.CHECKED;

var arrowComponent = _react["default"].createElement("i", {
  className: "spaui-icon spaui-icon-right-arrow"
}); // 获取可选表单结构


var getSelectedInput = function getSelectedInput() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var labelStr = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var canExpand = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var isAllowSelected = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  var isChecked = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
  var treeNode = props.treeNode,
      fieldNames = props.fieldNames,
      disabled = props.disabled,
      onChange = props.onChange,
      CheckboxProps = props.CheckboxProps,
      RadioProps = props.RadioProps,
      multiple = props.multiple,
      unrelated = props.unrelated,
      _props$unRelatedDisab = props.unRelatedDisabled,
      unRelatedDisabled = _props$unRelatedDisab === void 0 ? false : _props$unRelatedDisab;
  var _fieldNames$disabledF = fieldNames.disabledFieldName,
      disabledFieldName = _fieldNames$disabledF === void 0 ? 'disabled' : _fieldNames$disabledF;
  var result = null;
  var checkButtonProps = {};
  var indeterminate = false;
  var inputDisabled = false;
  var parentNode = treeNode.parentNode;

  if (Boolean(parentNode && parentNode[disabledFieldName] && !unRelatedDisabled) || Boolean(treeNode[disabledFieldName]) || disabled) {
    inputDisabled = true;
  } // 可选结构


  if (isAllowSelected) {
    // 可选结构的公共属性
    checkButtonProps = {
      className: (0, _classnames["default"])('spaui-tree-checkbox', {
        'spaui-tree-checkbox-disabled': inputDisabled
      }),
      value: treeNode.value,
      disabled: inputDisabled,
      checked: isChecked,
      onChange: onChange,
      textChecked: !canExpand,
      TextComponent: _text_component["default"],
      data: treeNode,
      fieldNames: fieldNames
    };

    if (multiple) {
      if (unrelated) {
        indeterminate = treeNode.status === INDETERMINATE || !isChecked && treeNode.status === CHECKED;
      } else {
        indeterminate = treeNode.status === INDETERMINATE;
      }

      result = _react["default"].createElement(_spauiCheckbox["default"], _extends({}, CheckboxProps, {
        indeterminate: indeterminate
      }, checkButtonProps), labelStr);
    } else {
      result = _react["default"].createElement(_spauiRadio["default"], _extends({}, RadioProps, checkButtonProps), labelStr);
    }
  }

  return result;
}; // 获取图标


var getIcon = function getIcon() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var canExpand = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var treeNode = props.treeNode,
      IconComponent = props.IconComponent,
      showArrowOnEmptyOptions = props.showArrowOnEmptyOptions;
  var iconDom = null; // 图标结构

  if (canExpand) {
    // 加载中的图标
    if (treeNode.isLoading) {
      iconDom = _react["default"].createElement(IconComponent, {
        type: "loading-blue"
      });
    } else if (!treeNode.hideArrow) {
      iconDom = arrowComponent;
    }
  } else {
    // 强制显示展开的图标 (前提是当前节点是不可选择的)
    if (showArrowOnEmptyOptions && !treeNode.isAllowSelected) {
      iconDom = arrowComponent;
    }
  }

  return iconDom;
};
/**
 * 默认的树状选择器节点组件
 * @param props
 * @returns {XML}
 * @constructor
 */


var TreeNodeComponent = function TreeNodeComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var treeNode = props.treeNode,
      fieldNames = props.fieldNames,
      labelFormat = props.labelFormat,
      onClick = props.onClick,
      canSelected = props.canSelected,
      selectTarget = props.selectTarget,
      multiple = props.multiple,
      NodeAdditionalComponent = props.NodeAdditionalComponent,
      asyncGetNodeData = props.asyncGetNodeData,
      _props$nodeClassName = props.nodeClassName,
      nodeClassName = _props$nodeClassName === void 0 ? '' : _props$nodeClassName,
      _props$nodeCheckedCla = props.nodeCheckedClassName,
      nodeCheckedClassName = _props$nodeCheckedCla === void 0 ? '' : _props$nodeCheckedCla,
      treeValue = props.treeValue,
      setNodeClassName = props.setNodeClassName,
      setNodeText = props.setNodeText,
      unrelated = props.unrelated,
      unRelatedDisabled = props.unRelatedDisabled,
      _props$nodeCanExpand = props.nodeCanExpand,
      nodeCanExpand = _props$nodeCanExpand === void 0 ? false : _props$nodeCanExpand,
      _props$nodeNotAllowSe = props.nodeNotAllowSelected,
      nodeNotAllowSelected = _props$nodeNotAllowSe === void 0 ? false : _props$nodeNotAllowSe,
      showNodeTitle = props.showNodeTitle,
      _props$selectAlignmen = props.selectAlignment,
      selectAlignment = _props$selectAlignmen === void 0 ? false : _props$selectAlignmen,
      NodeItemComponent = props.NodeItemComponent,
      disabled = props.disabled,
      onChange = props.onChange,
      IconComponent = props.IconComponent;
  var nodeFieldName = fieldNames.nodeFieldName,
      labelFieldName = fieldNames.labelFieldName,
      descriptionFieldName = fieldNames.descriptionFieldName,
      _fieldNames$disabledF2 = fieldNames.disabledFieldName,
      disabledFieldName = _fieldNames$disabledF2 === void 0 ? 'disabled' : _fieldNames$disabledF2; // 是否可展开

  var canAsyncLoad = treeNode.load_option === 1 && asyncGetNodeData;
  var haveChildrenNode = treeNode[nodeFieldName] && treeNode[nodeFieldName].length > 0;
  var canExpand = canAsyncLoad || haveChildrenNode || treeNode.can_expand || nodeCanExpand; // 是否选中

  var isChecked = treeNode.status === CHECKED; // 文案

  var labelStr = (0, _index.getLabel)(treeNode, labelFieldName, labelFormat, setNodeText, props); // 只显示最末端节点的Checkbox来选

  var childrenNodes = treeNode && treeNode.childrenNodes ? treeNode.childrenNodes : [];
  var notAllowSelected = selectTarget === 'child' && childrenNodes.length > 0;
  var isAllowSelected = false;
  var additionalContent = null;
  var iconContent = null; // 是否可勾选

  isAllowSelected = !(nodeNotAllowSelected || treeNode.not_allow_selected || notAllowSelected || typeof treeNode.value === 'string' && treeNode.value.length < 1); // 可以通过函数进行二次判断

  if ((0, _spauiUtils.isFunction)(canSelected)) {
    isAllowSelected = canSelected(treeNode, isAllowSelected);
  }

  treeNode.isAllowSelected = isAllowSelected; // 如果是单选那就只是选中的那个节点选中

  if (!multiple) {
    if ((0, _spauiUtils.isArray)(treeValue)) {
      if (treeValue.length > 0 && (0, _spauiUtils.indexOf)(treeValue, treeNode.value) > -1) {
        isChecked = true;
      } else {
        isChecked = false;
      }
    } else {
      if ((treeNode.value || typeof treeNode.value === 'number') && treeNode.value === treeValue) {
        isChecked = true;
      } else {
        isChecked = false;
      }
    }
  } // 如果不关联，就只看treeValue有没有选中


  if (unrelated) {
    if ((0, _spauiUtils.indexOf)(treeValue, treeNode.value) > -1) {
      isChecked = true;
    } else {
      isChecked = false;
    }
  }

  additionalContent = (0, _index.getComponentInstance)(NodeAdditionalComponent, {
    not_allow_selected: isAllowSelected,
    canExpand: canExpand,
    data: treeNode
  });
  iconContent = getIcon(props, canExpand);
  var nodeTitle = '';

  if (showNodeTitle && _typeof(labelStr) !== 'object') {
    nodeTitle = labelStr;

    if (treeNode && typeof treeNode[descriptionFieldName] === 'string') {
      nodeTitle += "\n".concat(treeNode[descriptionFieldName]);
    }
  }

  var treeNameProps = {};

  if (treeNode[labelFieldName] && typeof treeNode[labelFieldName] === 'string') {
    treeNameProps.title = treeNode[labelFieldName];
  }

  var dataSet = {};
  var key = ''; // dom节点支持dataSet数据

  for (key in treeNode) {
    if (key.indexOf('data-') === 0) {
      dataSet[key] = treeNode[key];
    }
  }

  var nodeKey = "".concat(treeNode.key, "____").concat(treeNode.siblingIndex || ''); // 自定义选项节点

  if (NodeItemComponent && !(0, _spauiUtils.isReactNode)(NodeItemComponent)) {
    var extraProps = {};

    if (isAllowSelected) {
      var parentNode = treeNode ? treeNode.parentNode || {} : {};
      var selectDisabled = Boolean(parentNode[disabledFieldName] && !unRelatedDisabled || treeNode[disabledFieldName] || disabled);
      var indeterminate = false;

      if (multiple) {
        if (unrelated) {
          indeterminate = treeNode.status === INDETERMINATE || !isChecked && treeNode.status === CHECKED;
        } else {
          indeterminate = treeNode.status === INDETERMINATE;
        }
      }

      extraProps = {
        disabled: selectDisabled,
        onChange: onChange,
        indeterminate: indeterminate
      };
    }

    return _react["default"].createElement(NodeItemComponent, _extends({
      key: nodeKey,
      nodeKey: nodeKey,
      nodeClassName: nodeClassName,
      treeNode: treeNode,
      canExpand: canExpand,
      isChecked: isChecked,
      onClick: onClick,
      isAllowSelected: isAllowSelected,
      labelFieldName: labelFieldName,
      descriptionFieldName: descriptionFieldName,
      additionalContent: additionalContent,
      iconContent: iconContent,
      labelStr: labelStr,
      IconComponent: IconComponent
    }, extraProps));
  }

  return _react["default"].createElement("li", _extends({
    title: nodeTitle,
    key: nodeKey
  }, dataSet, {
    "data-value": treeNode.key,
    "data-deeplevel": treeNode.deepLevel,
    className: (0, _classnames["default"])(nodeClassName, treeNode.className || '', {
      exapnd: canExpand,
      active: canExpand && treeNode.isExpand,
      checked: isChecked,
      multiline: treeNode[descriptionFieldName]
    }, isChecked ? nodeCheckedClassName : '', setNodeClassName(treeNode, {
      exapnd: canExpand,
      active: canExpand && treeNode.isExpand,
      checked: isChecked,
      multiline: treeNode[descriptionFieldName]
    })),
    onClick: onClick
  }), !isAllowSelected ? _react["default"].createElement("div", {
    className: (0, _classnames["default"])('spaui-tree-info', {
      'spaui-tree-select-alignment': selectAlignment
    })
  }, _react["default"].createElement("div", _extends({
    className: "spaui-tree-name"
  }, treeNameProps), setNodeText(treeNode, labelFieldName, props)), treeNode[descriptionFieldName] ? _react["default"].createElement("p", {
    className: "spaui-tree-desc"
  }, setNodeText(treeNode, descriptionFieldName, props)) : null) : getSelectedInput(props, labelStr, canExpand, isAllowSelected, isChecked), additionalContent || iconContent ? _react["default"].createElement("span", {
    className: "spaui-tree-addon"
  }, additionalContent, iconContent) : null);
};

var _default = TreeNodeComponent;
exports["default"] = _default;