"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.getValueByName = exports.getRowAndColSpanData = exports.getTotalData = exports.getDataByFilterValue = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

var _number = require("./number");

var _get_node = require("./get_node");

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

// 根据条件获取数据
var getDataByFilterValue = function getDataByFilterValue() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var filterName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var filterValue = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  // 单个值筛选
  var isSingleFilter = !(0, _spauiUtils.isArray)(filterValue) && (typeof filterValue !== 'string' || filterValue); // 多个值筛选

  var isMultipleFilter = (0, _spauiUtils.isArray)(filterValue) && filterValue.length > 0;
  var result = []; // 指定了筛选内容

  if (filterName && (isSingleFilter || isMultipleFilter)) {
    if (isMultipleFilter) {
      data.map(function (item) {
        if (item && (0, _spauiUtils.indexOf)(filterValue, item[filterName]) > -1) {
          result.push(item);
        }

        return item;
      });
    } else {
      data.map(function (item) {
        if (item) {
          if (item[filterName] === filterValue) {
            result.push(item);
          } else if ( // 特殊处理想一个是数字，一个是字符串的情况
          (typeof item[filterName] === 'number' && typeof filterValue === 'string' || typeof item[filterName] === 'string' && typeof filterValue === 'number') && (0, _number.toInt)(item[filterName]) === (0, _number.toInt)(filterValue)) {
            result.push(item);
          }
        }

        return item;
      });
    }
  } else {
    result = data;
  }

  return result;
}; // 总计


exports.getDataByFilterValue = getDataByFilterValue;

var getTotalData = function getTotalData(propsData, filterName, filterValue, totalData) {
  var data = getDataByFilterValue(propsData, filterName, filterValue);

  var setData = function setData(item, result, resultAllNotNumberMap) {
    var itemKey = '';

    for (itemKey in item) {
      if (typeof itemKey !== 'undefined') {
        if (_typeof(item[itemKey]) === 'object') {
          if (_typeof(result[itemKey]) !== 'object') {
            result[itemKey] = {};
          }

          if (_typeof(resultAllNotNumberMap[itemKey]) !== 'object') {
            resultAllNotNumberMap[itemKey] = {};
          }

          setData(item[itemKey], result[itemKey], resultAllNotNumberMap[itemKey]);
        } else if (_typeof(result) === 'object') {
          if (!result[itemKey]) {
            result[itemKey] = 0;
          } // 如果是undeifnd的初始化为false


          if ((0, _spauiUtils.isUndefined)(resultAllNotNumberMap[itemKey])) {
            resultAllNotNumberMap[itemKey] = false;
          } // 是否都为非数据


          if (!resultAllNotNumberMap[itemKey] && typeof item[itemKey] === 'number' || (parseFloat(item[itemKey], 10) || 0) === item[itemKey]) {
            resultAllNotNumberMap[itemKey] = true;
          }

          result[itemKey] = (parseFloat(result[itemKey]) || 0) + (parseFloat(item[itemKey]) || 0);
        }
      }
    }
  };

  var resultAllNotNumberMap = {};
  var result = {};
  var key = '';

  if ((0, _spauiUtils.isObject)(totalData)) {
    // 如果有传totalData数据优先用自带的
    result = (0, _spauiUtils.objectAssign)(totalData);
  } else if (_typeof(totalData) === 'object' && !totalData) {
    result = {};
  } else {
    data.map(function (item) {
      if (item) {
        setData(item, result, resultAllNotNumberMap);
      }

      return item;
    });

    for (key in resultAllNotNumberMap) {
      if (key && !resultAllNotNumberMap[key]) {
        result[key] = '';
      }
    }
  }

  return result;
}; // 获取合并单元格数据


exports.getTotalData = getTotalData;

var getRowAndColSpanData = function getRowAndColSpanData() {
  var theProps = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var setRowAndColSpan = theProps.setRowAndColSpan,
      total = theProps.total,
      loading = theProps.loading,
      loadingType = theProps.loadingType,
      _theProps$children = theProps.children,
      children = _theProps$children === void 0 ? null : _theProps$children,
      propsData = theProps.data,
      filterName = theProps.filterName,
      filterValue = theProps.filterValue,
      propsTotalData = theProps.totalData,
      supportingHierarchicalData = theProps.supportingHierarchicalData;
  var columns = (0, _get_node.getColumns)(children);

  var _setRowAndColSpan = function _setRowAndColSpan(type, name, value, rowIndex, colIndex, tdData, autoRowAndColSpanData) {
    var _autoRowAndColSpanData = autoRowAndColSpanData || {
      rowSpan: 1,
      colSpan: 1
    };

    var _rowData = [];

    if (!result[type][rowIndex]) {
      result[type][rowIndex] = [];
    }

    _rowData = result[type][rowIndex]; // 如果有传自定义设置函数就是用自定义设置

    if ((0, _spauiUtils.isFunction)(setRowAndColSpan)) {
      _rowData[colIndex] = setRowAndColSpan(type, name, value, rowIndex, colIndex, tdData, _autoRowAndColSpanData, columns.length) || {};
    } else {
      _rowData[colIndex] = _autoRowAndColSpanData;
    }

    _rowData[colIndex] = {
      rowSpan: _rowData[colIndex].rowSpan || 1,
      colSpan: _rowData[colIndex].colSpan || 1,
      style: _rowData[colIndex].style || {},
      className: _rowData[colIndex].className || ''
    };
  };

  var _setNoRender = function _setNoRender() {
    var rowAndColSpanData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    rowAndColSpanData.map(function (item, rowIndex) {
      item.map(function (subItem, colIndex) {
        var i = 1; // 如果给后续的标签打上不需要渲染的标签

        if (subItem.rowSpan && subItem.rowSpan > 1) {
          i = 1;

          for (; i < subItem.rowSpan; i++) {
            if (rowAndColSpanData[rowIndex + i] && rowAndColSpanData[rowIndex + i][colIndex]) {
              rowAndColSpanData[rowIndex + i][colIndex].renderable = false;
            }
          }
        }

        if (subItem.colSpan && subItem.colSpan > 1) {
          i = 1;

          for (; i < subItem.colSpan; i++) {
            if (rowAndColSpanData[rowIndex][colIndex + i]) {
              rowAndColSpanData[rowIndex][colIndex + i].renderable = false;
            }
          }
        }

        return subItem;
      });
      return item;
    });
  };

  var data = getDataByFilterValue(propsData, filterName, filterValue);
  var result = {
    head: [],
    data: [],
    total: []
  };
  var totalData = {};
  var columnsData = []; // 处理头部

  columns.map(function () {
    var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var index = arguments.length > 1 ? arguments[1] : undefined;
    var _item$props = item.props,
        props = _item$props === void 0 ? {} : _item$props;
    var name = props.name; // columns的顺序

    columnsData.push(name);

    _setRowAndColSpan('head', name, '', 0, index, {});

    return item;
  }); // 处理数据

  if (!loading || loadingType === 'cover') {
    data.map(function () {
      var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var index = arguments.length > 1 ? arguments[1] : undefined;
      columns.map(function () {
        var colItem = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        var colIndex = arguments.length > 1 ? arguments[1] : undefined;
        var _colItem$props = colItem.props,
            props = _colItem$props === void 0 ? {} : _colItem$props;
        var name = props.name,
            autoRowSpan = props.autoRowSpan,
            autoColSpan = props.autoColSpan;
        var autoRowAndColSpanData = {
          rowSpan: 1,
          colSpan: 1
        };
        var i = index + 1;
        var len = data.length;
        var rowEqualCount = 1;
        var colEqualCount = 1; // 用来标识单元格合并是否被中断了

        var isBreak = false;

        if (autoRowSpan) {
          for (; i < len && !isBreak; i++) {
            var valueByItem = getValueByName(item, name, supportingHierarchicalData);
            var valueByData = getValueByName(data[i], name, supportingHierarchicalData);

            if (valueByItem === valueByData) {
              rowEqualCount += 1;
            } else {
              isBreak = true;
            }
          }
        }

        isBreak = false;

        if (autoColSpan) {
          i = colIndex + 1;
          len = columnsData.length;

          for (; i < len && !isBreak; i++) {
            var _valueByItem = getValueByName(item, name, supportingHierarchicalData);

            var _valueByData = getValueByName(item, columnsData[i], supportingHierarchicalData);

            if (_valueByItem === _valueByData) {
              colEqualCount += 1;
            } else {
              isBreak = true;
            }
          }
        }

        autoRowAndColSpanData.rowSpan = rowEqualCount;
        autoRowAndColSpanData.colSpan = colEqualCount;

        _setRowAndColSpan('data', name, getValueByName(item, name, supportingHierarchicalData), index, colIndex, item, autoRowAndColSpanData);

        return colItem;
      });
      return item;
    });
  } // 处理总计


  if (total) {
    totalData = getTotalData(propsData, filterName, filterValue, propsTotalData);
    columns.map(function () {
      var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var index = arguments.length > 1 ? arguments[1] : undefined;
      var _item$props2 = item.props,
          props = _item$props2 === void 0 ? {} : _item$props2;
      var name = props.name;

      _setRowAndColSpan('total', name, getValueByName(total, name, supportingHierarchicalData), 0, index, totalData);

      return item;
    });
  } // 如果单元格被设置成colSpan, rowSpan大于1，单元格后面的是不需要渲染的，给打上标记


  _setNoRender(result.head);

  _setNoRender(result.data);

  _setNoRender(result.total);

  return result;
};
/**
 *
 * @param {object} data 行数据
 * @param {*} name 字段名称
 */


exports.getRowAndColSpanData = getRowAndColSpanData;

var getValueByName = function getValueByName() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var name = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var supportingHierarchicalData = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  var result;
  var undefinedValue;
  var nameArray = [];
  var nameString = "".concat(name);

  if (nameString) {
    if (!supportingHierarchicalData) {
      result = data[name];
    } else {
      nameArray = nameString.split('.') || []; // 如果有值的话，先把result当成data，方便后续一层一层替换

      if (nameArray.length > 0 && _typeof(data) === 'object') {
        result = data;
        nameArray.map(function (item) {
          if (item || typeof item === 'number') {
            if (_typeof(result) === 'object') {
              result = result[item];
            } else {
              result = undefinedValue;
            }
          }

          return item;
        });
      }
    }
  }

  return result;
};
/**
 *
 * @param {object} data 行数据
 * @param {*} name 字段名称
 * @param {*} value 值
 */
// export const setValueByName = (data = {}, name = '', value = '') => {
//     let result = data,
//         nameArray = [],
//         nameString = name + '';
//     if (nameString) {
//         nameArray = nameString.split('.') || [];
//         // 如果有值的话，先把result当成data，方便后续一层一层替换
//         if (nameArray.length > 0 && typeof data === 'object') {
//             nameArray.map((item, index) => {
//                 if (typeof result === 'object') {
//                     if (index < nameArray.length - 1) {
//                         if (!result[item]) {
//                             result[item] = {};
//                         }
//                         result = result[item];
//                     } else {
//                         result[item] = value;
//                     }
//                 }
//                 return item;
//             });
//         }
//     }
//     return result;
// };


exports.getValueByName = getValueByName;
var _default = {
  getDataByFilterValue: getDataByFilterValue,
  getTotalData: getTotalData,
  getRowAndColSpanData: getRowAndColSpanData,
  getValueByName: getValueByName
};
exports["default"] = _default;