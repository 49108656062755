"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _index = require("../../utils/index");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
var MaskContentComponent = function MaskContentComponent(props) {
  var _props$data = props.data,
      data = _props$data === void 0 ? {} : _props$data,
      upload = props.upload,
      canReupload = props.canReupload,
      _props$type = props.type,
      type = _props$type === void 0 ? '' : _props$type;
  var isVideoFile = type === 'video' || (0, _index.isVideo)(data.url);
  return _react["default"].createElement("div", {
    className: "spaui-uploader-mask-content"
  }, upload && canReupload ? _react["default"].createElement("div", {
    className: "spaui-uploader-mask-content-inner"
  }, _react["default"].createElement("h3", null, "\u70B9\u51FB\u66FF\u6362", isVideoFile ? '视频' : '图片')) : null);
};

var _default = MaskContentComponent;
exports["default"] = _default;