"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _spauiUtils = require("@tencent/spaui-utils");

var _classnames = _interopRequireDefault(require("classnames"));

var _index = require("../utils/index");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var ListComponent = function ListComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _props$data = props.data,
      data = _props$data === void 0 ? [] : _props$data,
      onChange = props.onChange,
      _props$className = props.className,
      className = _props$className === void 0 ? '' : _props$className,
      _props$value = props.value,
      propsValue = _props$value === void 0 ? '' : _props$value,
      _props$classNameMap = props.classNameMap,
      classNameMap = _props$classNameMap === void 0 ? {} : _props$classNameMap;
  return _react["default"].createElement("div", {
    className: (0, _classnames["default"])(className, classNameMap.DatePickerMonthItemClassName)
  }, _react["default"].createElement("ul", {
    className: "clearfix ".concat(classNameMap.DatePickerMonthListClassName)
  }, data.map(function () {
    var _classNames;

    var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var value = item.value,
        label = item.label,
        _item$disabled = item.disabled,
        propsDisabled = _item$disabled === void 0 ? false : _item$disabled,
        onMouseEnter = item.onMouseEnter,
        among = item.among,
        itemOn = item.on;
    var on = !(0, _spauiUtils.isUndefined)(itemOn) ? itemOn : value === propsValue;
    var disabled = on ? false : propsDisabled;
    return _react["default"].createElement("li", {
      key: value,
      className: (0, _classnames["default"])({
        on: on,
        disabled: disabled
      }, (_classNames = {}, _defineProperty(_classNames, classNameMap.DatePickerDateItemSelectedClassName, itemOn), _defineProperty(_classNames, classNameMap.DatePickerAmongDateItemClassName, among), _classNames))
    }, _react["default"].createElement("a", {
      className: (0, _classnames["default"])({
        'spaui-datepicker-pointer': !disabled
      }),
      onClick: function onClick(event) {
        if (!disabled && (0, _spauiUtils.isFunction)(onChange) && value.toString().length > 0) {
          onChange(event, (0, _index.toInt)(value));
        }
      },
      onMouseEnter: onMouseEnter
    }, label));
  })));
};

var _default = ListComponent;
exports["default"] = _default;