"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
// 星星组件
var StarComponent = function StarComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var value = props.value,
      type = props.type;

  var star = _react["default"].createElement("svg", {
    className: "spaui-rating-icon",
    viewBox: "0 0 1024 1024",
    xmlns: "http://www.w3.org/2000/svg"
  }, _react["default"].createElement("path", {
    fill: "currentColor",
    d: "M 569.499 94.591 L 679.95 318.426 l 273.268 40.688 c 49.604 7.397 65.19 42.313 28.735 80.583 l -199.268 195.12 l 48.855 284.62 c 6.563 41.2 -33.248 65.147 -68.226 41.2 L 513.587 830.366 L 259.393 963.953 c -34.038 17.382 -69.039 6.2 -62.689 -41.458 l 47.765 -287.656 l -205.362 -199.44 c -39.662 -36.582 -19.05 -69.98 26.982 -76.094 l 281.2 -40.858 s 57.706 -117.039 110.324 -223.644 c 38.315 -71.581 80.328 -70.533 111.886 -0.212 Z"
  }));

  return _react["default"].createElement("div", {
    className: (0, _classnames["default"])('spaui-rating-star', {
      'spaui-rating-full': type === 'full',
      'spaui-rating-half': type === 'half',
      'spaui-rating-empty': type === 'empty'
    })
  }, _react["default"].createElement("div", {
    className: "spaui-rating-first",
    "data-halfvalue": value - 0.5,
    "data-value": value
  }, star), _react["default"].createElement("div", {
    className: "spaui-rating-second",
    "data-halfvalue": value,
    "data-value": value
  }, star));
};

var _default = StarComponent;
exports["default"] = _default;