"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.pushDataForSelect = exports.pushDataToTarget = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * 往target添加data
 * @param {Object} config 配置
 * @param {Array} config.target 要存数据大数组
 * @param {*} config.data 被push的数据
 * @param {Function} config.by 特殊处理data的函数
 * @returns {Void}
 */
var pushDataToTarget = function pushDataToTarget() {
  var config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _config$target = config.target,
      target = _config$target === void 0 ? [] : _config$target,
      data = config.data,
      by = config.by;
  var byData;

  if ((0, _spauiUtils.isFunction)(by)) {
    byData = by(data);
  } else {
    byData = data;
  }

  if (typeof byData !== 'undefined') {
    if ((0, _spauiUtils.isObject)(byData)) {
      target.push((0, _spauiUtils.objectAssign)({}, byData));
    } else {
      target.push(byData);
    }
  }
};
/**
 * 往target添加data
 * @param {Object} config 配置
 * @param {Array} config.target 要存数据大数组
 * @param {*} config.data 被push的数据
 * @param {String|Number} config.label 输入框大文案
 * @returns {Void}
 */


exports.pushDataToTarget = pushDataToTarget;

var pushDataForSelect = function pushDataForSelect() {
  var config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var target = config.target,
      data = config.data,
      label = config.label,
      _config$fieldNames = config.fieldNames,
      fieldNames = _config$fieldNames === void 0 ? {} : _config$fieldNames;
  var labelFieldName = fieldNames.labelFieldName,
      valueFieldName = fieldNames.valueFieldName;
  pushDataToTarget({
    target: target,
    data: data,
    by: function by() {
      var result;

      if (data === label) {
        result = data;
      } else if (data[labelFieldName] === label) {
        result = data[valueFieldName];
      }

      return result;
    }
  });
};

exports.pushDataForSelect = pushDataForSelect;
var _default = {
  pushDataToTarget: pushDataToTarget,
  pushDataForSelect: pushDataForSelect
};
exports["default"] = _default;