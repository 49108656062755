"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isEqualArray = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

var isArray = _spauiUtils.utils.isArray;
/**
 * 判断两个数组是否相等（顺序不同则为不一致）
 */

var isEqualArray = function isEqualArray(arrayA, arrayB) {
  if (arrayA === arrayB) {
    return true;
  }

  if (!isArray(arrayA) || !isArray(arrayB)) {
    return false;
  }

  if (arrayA.length !== arrayB.length) {
    return false;
  }

  for (var i = 0; i < arrayA.length; i++) {
    if (arrayA[i] !== arrayB[i]) {
      return false;
    }
  }

  return true;
};

exports.isEqualArray = isEqualArray;