"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultProps = exports.propTypes = exports.config = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

var _sort_icon_component = _interopRequireDefault(require("./component/sort_icon_component"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件参数配置
 */

/**
 * Column 接口参数
 * @alias ColumnProps
 * @description 表格列组件。
 * @property {titleline} 默认支持参数
 * @property {blankline} className;style;id;data-*;accessKey;dir;draggable;tabIndex;title;htmlRef;
 * @property {titleline} 基本属性
 * @property {string} name='' 字段名称
 * @property {string} [type=''] 列类型*type为optional时该列为可选列*
 * @property {string} [align='left'] 列对齐方式*text-align的值，比如：left, right, center*
 * @property {string} [verticalAlign=''] 列单元格垂直对齐方式*vertical-align的值，比如：top, bottom, middle*
 * @property {bool} [show=true] 是否显示
 * @property {bool} [renderable=true] 是否渲染dom结构
 * @property {bool} [fixedable=false] 列是否固定
 * @property {string} [fixedAlign='left'] 固定列的方向*可选值：left, right*
 * @property {bool} [fixedBorder=true] 是否需要固定列的边距border
 * @property {string} [fixedClassName=''] 启用固定列时单元格不在原位置时会添加该className(可用于处理样式)
 * @property {func} [onClick] 点击回调*onClick=(event)*
 * @property {titleline} type=optional为可选列的属性
 * @property {bool} [multiple=false] 是否支持多选
 * @property {string} [target=''] 可选列字段名*指定对应哪个字段的数据*
 * @property {string|number|array|bool} [value=''] 可选列选中的数据
 * @property {func} [setChecked] 设置可选列是否可以选中*setChecked=({value,data,dataMap,isAll})=>{return bool;}; 优先级最高*
 * @property {func} [setIndeterminate] 设置是否是半选状态*setIndeterminate=({value,data,dataMap,isAll})=>{return bool;}; *
 * @property {number} [max] type=optionals且多选时，最多可选择多少个数据*指定max的时候没有全选按钮*
 * @property {bool} [needCheckAll] type=optionals且多选时在max>0时是否强制出全选
 * @property {func} [canOptional] 对应选项是否可选择*canOptional=(data,disabled)=> {return bool;}*
 * @property {func} [onOptionalChange] 可选列选中回调*(可参考下方案例)*
 * @property {array} [disabledValue=[]] 可选列禁止的选项
 * @property {object} [disabledValueConfig={}] 可选列禁止的提示配置（内容可以是提示文案或同OverlayTrigger的props配置）
 * @property {titleline} 设置宽度属性
 * @property {number} [minWidth=0] 最小宽度*大于0才有效*
 * @property {number} [maxWidth=0] 设置最大宽度*大于0才有效*
 * @property {titleline} 自定义单元格数据格式属性
 * @property {string} [format=''] 通过${value}定制数据格式
 * @property {func} [render=function(){}] 通过render方法定制数据*(具体参数看案例)*
 * @property {func} [component] 自定义单元格组件*(具体参数看案例)*
 * @property {func} [customScope=[]] 指定自定义替换内容针对哪些区域生效*(值为:data(数据行),thead(头部),total(总计),expand(扩展行),additional)*
 * @property {titleline} 筛选属性
 * @property {bool} [filterable=false] 是否支持筛选
 * @property {bool} [filterMultiple=false] 是否支持筛选多选
 * @property {bool} [filterPlaceholder='请选择'] filter下拉框placeholder
 * @property {func|element} [FilterComponent=null] 过滤组件*(具体参数看案例)*
 * @property {func} [setFilterLabel=function(){}] 过滤组件的label文案*(具体参数看案例)*
 * @property {titleline} 表格宽度拖动相关属性
 * @property {bool} [adjustWidth=false] 是否用户可自行调整宽度（设置后同时影响下一列的宽度，最后一列不需要设置）
 * @property {number} [adjustMinWidth=0] 最小拖动宽度
 * @property {number} [adjustMaxWidth] 最大拖动宽度
 * @property {titleline} 合并单元格属性
 * @property {number} [colSpan=1] 列单元格合并
 * @property {number} [rowSpan=1] 行单元格合并
 * @property {bool} [autoRowSpan=false] 当上下两行数据一致时，是否合并单元格
 * @property {bool} [autoColSpan=false] 当左右两列数据一致时，是否合并单元格
 * @property {blankline} className属性
 * @property {string} [headClassName = ''] 头部单元格className
 * @property {string} [dataClassName = ''] 数据单元格className
 * @property {string} [totalClassName = ''] 总计单元格className
 * @property {titleline} 排序属性
 * @property {bool} [sortable=false] 是否排序
 * @property {func|element} [SortIconComponent] 排序图标组件*(具体参数看案例)*
 * @property {bool} [alwaysShowSortIcon=false] 为true的时候只要是可排序的字段就都会调用SortIconComponent
 *
 * @example
 *
 * render = (type, name, value, data, checkedValue, disabled, rowIndex) => return content
 * type: expand:扩展行，additional添加行，data数据行, head头部，total总计 // rowIndex 只有tbody区域才有
 *
 * component:
 * &lt;CustomTdComponent
    {...props}
    trType=''
    checkedValue={props.value}
    type={trType}
    name={name}
    value={result}
    data={data}
    disabled={disabled}
/&gt;
 *
 &lt;FilterComponent
    placeholder={selectPlaceholder}
    multiple={filterMultiple}
    data={filterData}
    value={name === filterName ? filterValue : ''}
    onChange={(event, value) => {}}
/&gt;
 *
 * setFilterLabel=(name, value, children); \n name: 字段名，value: 对应单元格的值，children：列的children
 *
 &lt;SortIconComponent
    alwaysShowSortIcon={alwaysShowSortIcon}
    name={name}
    sortName={sortName}
    sortOrder={sortOrder}
/&gt;
 *
 * render = (type, name, value, data, checkedValue, disabled);
 * type: expand:扩展行，additional添加行，data数据行, head头部，total总计；
 * name: 字段名；
 * value: 字段值；
 * data: 行数据；
 * checkedValue: 选中的数据，optional特有;
 * disabled: 经过disabledValue, max, canOptional之后是否禁用
 *
 * // value：选中的值, data: 选中的行数据, dataMap: 所有选中的value对应的data, clickArea 表明点击的区域位置（head, body）
 * onOptionalChange=(event, value, data, dataMap, clickArea)
 */
var config = {
  name: {
    type: _spauiUtils.PropTypes.string.isRequired,
    defaultValue: ''
  },
  sortable: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  format: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  render: {
    type: _spauiUtils.PropTypes.func
  },
  customScope: {
    type: _spauiUtils.PropTypes.array,
    defaultValue: []
  },
  component: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
  filterable: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  filterMultiple: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  filterPlaceholder: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  FilterComponent: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.element, _spauiUtils.PropTypes.func]),
  setFilterLabel: {
    type: _spauiUtils.PropTypes.func,
    defaultValue: function defaultValue(name, value, children) {
      var result = '';

      if (value) {
        result = value;
      } else {
        result = children;
      }

      return result;
    }
  },
  type: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  multiple: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  target: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  value: {
    type: _spauiUtils.PropTypes.any,
    defaultValue: ''
  },
  max: {
    type: _spauiUtils.PropTypes.number
  },
  needCheckAll: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: false
  },
  canOptional: {
    type: _spauiUtils.PropTypes.func,
    defaultValue: function defaultValue(data) {
      var disabled = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var loading = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      return !loading && !disabled;
    }
  },
  setChecked: {
    type: _spauiUtils.PropTypes.func
  },
  setIndeterminate: {
    type: _spauiUtils.PropTypes.func
  },
  onOptionalChange: {
    type: _spauiUtils.PropTypes.func
  },
  disabledValue: {
    type: _spauiUtils.PropTypes.array,
    defaultValue: []
  },
  disabledValueConfig: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  align: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'left'
  },
  verticalAlign: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  show: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  colSpan: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: 1
  },
  rowSpan: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: 1
  },
  autoRowSpan: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  autoColSpan: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  renderable: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  minWidth: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.number, _spauiUtils.PropTypes.string]),
    defaultValue: 0
  },
  maxWidth: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.number, _spauiUtils.PropTypes.string]),
    defaultValue: 0
  },
  fixedable: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  fixedAlign: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'left'
  },
  fixedBorder: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  fixedClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  sortName: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.array, _spauiUtils.PropTypes.string]),
    defaultValue: ''
  },
  sortOrder: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.object, _spauiUtils.PropTypes.string]),
    defaultValue: ''
  },
  onSort: {
    type: _spauiUtils.PropTypes.func
  },
  filterName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  filterValue: {
    type: _spauiUtils.PropTypes.any,
    defaultValue: ''
  },
  onFilter: {
    type: _spauiUtils.PropTypes.func
  },
  onClick: {
    type: _spauiUtils.PropTypes.func
  },
  headClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  dataClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  totalClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  adjustWidth: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  adjustMinWidth: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.number, _spauiUtils.PropTypes.string]),
    defaultValue: 0
  },
  undefinedLabel: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: '-'
  },
  SortIconComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _sort_icon_component["default"]
  },
  alwaysShowSortIcon: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  }
}; // 转化成PureComponent组件的配置

exports.config = config;
var configData = (0, _spauiUtils.formatProps)(config); // 导出

var propTypes = configData.propTypes;
exports.propTypes = propTypes;
var defaultProps = configData.defaultProps;
exports.defaultProps = defaultProps;
var _default = {
  config: config,
  propTypes: propTypes,
  defaultProps: defaultProps
};
exports["default"] = _default;