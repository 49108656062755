"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.uniqueId = exports.getId = exports.isEmptyObject = exports.addUploaderJs = exports.isVideo = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

var isFunction = _spauiUtils.utils.isFunction,
    loadJs = _spauiUtils.utils.loadJs,
    getVersion = _spauiUtils.utils.getVersion; // 是否视频的格式

var isVideo = function isVideo() {
  var url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var videoTypes = ['mpeg', 'mpg', 'dat', 'avi', 'mov', 'asf', 'wmv', 'navi', '3gp', 'ra', 'ram', 'mkv', 'flv', 'f4v', 'rmvb', 'webm', 'wma', 'rm', 'flash', 'mp4', 'mid'];
  var result = false;
  var theURL = '';

  if (url) {
    theURL = url.toLowerCase();
    videoTypes.map(function (item) {
      if (theURL.indexOf(".".concat(item)) > -1) {
        result = true;
      }

      return item;
    });
  }

  return result;
}; // 添加上传组件的依赖js
// 添加依赖文件


exports.isVideo = isVideo;
var ieVersion = getVersion();
var useFileAPI = ieVersion <= -1 || ieVersion >= 10;
var isAddJSing = false;
var onLoadStack = [];

var addUploaderJs = function addUploaderJs(onload) {
  var addJSOnLoad = function addJSOnLoad() {
    onLoadStack.map(function (item) {
      if (isFunction(item)) {
        item();
      }
    });

    if (isFunction(onload)) {
      onload();
    }

    onLoadStack = [];
    isAddJSing = false;
  };

  var url = '//public.gdtimg.com/qzone/biz/gdt/lib/fileapi-2.0.11/FileAPI.html5.js?max_age=31536000';
  var url1 = '//public.gdtimg.com/qzone/biz/gdt/lib/jquery/jquery-1.12.2.js';
  var url2 = '//public.gdtimg.com/qzone/biz/gdt/lib/network/0.1.0/network.min.js?max_age=31536000';

  if (!useFileAPI) {
    if (isAddJSing) {
      // 存储下onload回调
      onLoadStack.push(onload);
    } else {
      // 避免本地开发调试不起来
      if (location.protocol === 'file:') {
        url1 = "http:".concat(url1);
        url2 = "http:".concat(url2);
      }

      isAddJSing = true;

      if ((typeof window === "undefined" ? "undefined" : _typeof(window)) === 'object' && _typeof(window.jQuery) === 'object') {
        // jquery已经有了，直接跳过
        loadJs({
          url: url2,
          onload: addJSOnLoad
        });
      } else {
        loadJs({
          url: url1,
          onload: function onload() {
            loadJs({
              url: url2,
              onload: addJSOnLoad
            });
          }
        });
      }
    }
  } else {
    if ((typeof window === "undefined" ? "undefined" : _typeof(window)) === 'object' && typeof window.FileAPI === 'undefined') {
      if (isAddJSing) {
        onLoadStack.push(onload);
      } else {
        if (location.protocol === 'file:') {
          url = "http:".concat(url);
        }

        isAddJSing = true;
        loadJs({
          url: url,
          onload: addJSOnLoad
        });
      }
    } else {
      addJSOnLoad();
    }
  }
}; // 是否空对象


exports.addUploaderJs = addUploaderJs;

var isEmptyObject = function isEmptyObject() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var result = true;
  var key = '';
  var i = 0;

  for (key in data) {
    if (key) {
      i += 1;
    }
  }

  if (i > 0) {
    result = false;
  }

  return result;
}; // 获取id节点


exports.isEmptyObject = isEmptyObject;

var getId = function getId() {
  var id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var result = null;

  if (id) {
    result = (typeof document === "undefined" ? "undefined" : _typeof(document)) === 'object' && document.getElementById(id);
  }

  return result;
}; // 生成唯一id


exports.getId = getId;

var uniqueId = function uniqueId() {
  return (0, _spauiUtils.uniqueId)('spaui-uploader');
};

exports.uniqueId = uniqueId;
var _default = {
  isVideo: isVideo,
  addUploaderJs: addUploaderJs,
  isEmptyObject: isEmptyObject,
  getId: getId,
  uniqueId: uniqueId
};
exports["default"] = _default;