"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
var _default = function _default(props) {
  var showStepButton = props.showStepButton,
      suffix = props.suffix,
      onClick = props.onClick,
      _props$classNameMap = props.classNameMap,
      classNameMap = _props$classNameMap === void 0 ? {} : _props$classNameMap;
  var result = null;
  var _suffix = null;

  if (showStepButton) {
    _suffix = _react["default"].createElement("i", {
      className: "graph-icon graph-icon-add",
      onClick: onClick
    }, _react["default"].createElement("i", {
      className: "add-hor"
    }), _react["default"].createElement("i", {
      className: "add-ver"
    }));
  } else if (suffix) {
    _suffix = suffix;
  }

  if (_suffix) {
    result = _react["default"].createElement("div", {
      className: classNameMap.SliderSuffixClassName
    }, _suffix);
  }

  return result;
};

exports["default"] = _default;