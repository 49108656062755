"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.Column = exports.Table = void 0;

var _table = _interopRequireDefault(require("./table"));

var _column = _interopRequireDefault(require("./column"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @author kirkzheng <kirkzheng@tencent.com>
 * @module Table
 */
var Table = _table["default"];
exports.Table = Table;
var Column = _column["default"]; // 调试信息
// if (process.env.NODE_ENV !== 'production') {
//     const {whyDidYouUpdate} = require('why-did-you-update');
//     whyDidYouUpdate(React);
// }

exports.Column = Column;
var _default = {
  Table: Table,
  Column: Column
};
exports["default"] = _default;