"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.getItemName = exports.getItemThumbUrl = exports.getItemUrl = exports.getDownloadUrl = void 0;

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
var getDownloadUrl = function getDownloadUrl(itemInfo) {
  var result;

  if (_typeof(itemInfo) === 'object' && itemInfo) {
    result = itemInfo.downloadUrl || itemInfo.url || itemInfo.thumbUrl || '';
  } else if (typeof itemInfo === 'string') {
    result = itemInfo;
  }

  return result;
};

exports.getDownloadUrl = getDownloadUrl;

var getItemUrl = function getItemUrl(itemInfo) {
  var result = '';

  if (_typeof(itemInfo) === 'object' && itemInfo) {
    result = itemInfo.url || '';
  } else if (typeof itemInfo === 'string') {
    result = itemInfo;
  }

  return result;
};

exports.getItemUrl = getItemUrl;

var getItemThumbUrl = function getItemThumbUrl(itemInfo) {
  var result = '';

  if (_typeof(itemInfo) === 'object' && itemInfo) {
    result = itemInfo.thumbUrl || itemInfo.url || '';
  } else if (typeof itemInfo === 'string') {
    result = itemInfo;
  }

  return result;
};

exports.getItemThumbUrl = getItemThumbUrl;

var getItemName = function getItemName(itemInfo) {
  var result = '';

  if (_typeof(itemInfo) === 'object' && itemInfo) {
    result = itemInfo.name || '';
  }

  return result;
};

exports.getItemName = getItemName;
var _default = {
  getDownloadUrl: getDownloadUrl,
  getItemUrl: getItemUrl,
  getItemThumbUrl: getItemThumbUrl,
  getItemName: getItemName
};
exports["default"] = _default;