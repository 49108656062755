"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _spauiUtils = require("@tencent/spaui-utils");

var _get_node = require("../utils/get_node");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

// 空标记
var trType = 'empty';

var EmptyComponent = function EmptyComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var emptyText = props.emptyText,
      PropsEmptyComponent = props.EmptyComponent,
      cellInnerClassName = props.cellInnerClassName,
      _props$children = props.children,
      children = _props$children === void 0 ? null : _props$children,
      _props$setTrClassName = props.setTrClassName,
      setTrClassName = _props$setTrClassName === void 0 ? function () {
    return '';
  } : _props$setTrClassName,
      clickSpace = props.clickSpace,
      _props$emptyTrClassNa = props.emptyTrClassName,
      emptyTrClassName = _props$emptyTrClassNa === void 0 ? '' : _props$emptyTrClassNa,
      _props$fixedEmptyComp = props.fixedEmptyComponent,
      fixedEmptyComponent = _props$fixedEmptyComp === void 0 ? false : _props$fixedEmptyComp,
      emptyDataNodeRef = props.emptyDataNodeRef; // 获取目前有多少列

  var columns = (0, _get_node.getColumns)(children); // 点击回调

  var onClickTr = function onClickTr(event) {
    // 拖动的时候是否要响应点击回调
    if (!mouseDownPageX && !mouseDownPageY || typeof event.pageX === 'undefined' && typeof event.pageY === 'undefined' || mouseDownPageX - clickSpace <= event.pageX && mouseDownPageX + clickSpace >= event.pageX && mouseDownPageY - clickSpace <= event.pageY && mouseDownPageY + clickSpace >= event.pageY) {
      if ((0, _spauiUtils.isFunction)(props.onClickTr)) {
        props.onClickTr(event, trType);
      }
    } // 点击后把点击位置清空


    mouseDownPageX = 0;
    mouseDownPageY = 0;
  };

  var onMouseDownTr = function onMouseDownTr(event) {
    // 鼠标按下记录下位置
    mouseDownPageX = event.pageX || 0;
    mouseDownPageY = event.pageY || 0;
  };

  var onMouseOverTr = function onMouseOverTr(event) {
    if ((0, _spauiUtils.isFunction)(props.onMouseOverTr)) {
      props.onMouseOverTr(event, trType);
    }
  };

  var onMouseOutTr = function onMouseOutTr(event) {
    if ((0, _spauiUtils.isFunction)(props.onMouseOutTr)) {
      props.onMouseOutTr(event, trType);
    }
  };

  var result = '';
  var mouseDownPageX = 0;
  var mouseDownPageY = 0;
  var emptyElement = null;

  if (!(0, _spauiUtils.isUndefined)(PropsEmptyComponent)) {
    // 自定义空组件
    result = _react["default"].createElement("tbody", null, (0, _get_node.getPreContent)(columns, props), _react["default"].createElement("tr", {
      "data-spaui-table-tr": "1",
      className: "".concat(setTrClassName(trType), " ").concat(emptyTrClassName)
    }, _react["default"].createElement("td", {
      colSpan: columns.length
    }, _react["default"].createElement(PropsEmptyComponent, props))));
  } else {
    if (emptyText && _typeof(emptyText) === 'object') {
      emptyElement = _react["default"].createElement("div", {
        className: cellInnerClassName
      }, emptyText);
    } else {
      emptyElement = _react["default"].createElement("div", {
        className: cellInnerClassName,
        dangerouslySetInnerHTML: {
          __html: emptyText
        }
      });
    }

    result = _react["default"].createElement("tbody", null, (0, _get_node.getPreContent)(columns, props), _react["default"].createElement("tr", {
      "data-spaui-table-tr": "1",
      className: "".concat(setTrClassName(trType), " ").concat(emptyTrClassName),
      onClick: onClickTr,
      onMouseOver: onMouseOverTr,
      onMouseOut: onMouseOutTr,
      onMouseDown: onMouseDownTr
    }, _react["default"].createElement("td", {
      "data-fixed": fixedEmptyComponent ? 1 : '',
      colSpan: columns.length,
      ref: emptyDataNodeRef
    }, emptyElement)));
  }

  return result;
};

var _default = EmptyComponent;
exports["default"] = _default;