"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultProps = exports.propTypes = exports.config = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

var _link_component = _interopRequireDefault(require("./component/link_component"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件参数配置
 */

/**
 * Tab 接口参数
 * @alias TabsProps
 * @description 多个标签进行快速内容切换。
 * @property {titleline} 默认支持参数
 * @property {blankline} className;style;id;data-*;accessKey;dir;draggable;tabIndex;title;htmlRef;
 * @property {titleline} 基础属性
 * @property {string} value='' 展示的tab
 * @property {func} onChange 切换状态回调函数*onChange(event, value){}*
 * @property {array} [data=[]] 切换按钮选项*(如果有数据就不会使用children的数据)*
 * @property {bool} [needSlider=true] 是否需要slider
 * @property {bool} [unmountInactivePanel=false] 卸载非激活的面板*不处于选中态的选项会被unmount*
 * @property {bool} [disabled=false] 是否禁用
 * @property {bool} [stacked=false] 是否垂直堆叠
 * @property {bool} [unChange=false] unChange=true的时候点击不会响应onChange
 * @property {number} [setSliderPositionTimeout=0] 延缓多久才开始设置slider的位置*单位ms*
 * @property {titleline} 设置对应节点class属性
 * @property {string} [tabButtonWrapClassName=''] button 外层结构的className
 * @property {object} [tabButtonWrapStyle={}] button 外层结构的style
 * @property {string} [tabContentWrapClassName=''] content 外层结构的className
 * @property {object} [tabContentWrapStyle={}] content 外层结构的style
 * @property {string} [tabButtonClassName=''] button className
 * @property {string} [tabPanelInnerClassName='spaui-tabs-body-inner'] 每个tabPanel位置div className
 * @property {bool} [defaultTabButtonClassName=true] 加上默认的defaultTabButtonClassName

 * @property {any} [addon=null] 附加结构
 * @property {func} [LinkComponent] 自定义点击按钮的结构内容*(具体参数看案例)*
 * @property {func|element} [TabCommonComponent] 公共面板结构*(具体参数看案例)*
 * @property {string} [commonPosition='endOfHead'] 公共面板的位置，默认在head内以及按钮后
 * @property {string|number|element} [frontOfHeadContent=null] head前置内容
 * @property {string|number|element} [endOfHeadContent=null] head后置内容
 * @property {string|number|element} [frontOfBodyContent=null] body前置内容
 * @property {string|number|element} [endOfBodyContent=null] body后置内容
 * @property {string|number|element} [frontOfTabsContent=null] tabs前置内容
 * @property {string|number|element} [endOfTabsContent=null] tabs后置内容
 * @property {titleline} CSS 自定义属性
 * @property {string|number} [navGap='24px'] 按钮之间间距
 * @property {string} [navBorder=null] 导航下边框
 * @property {string} [buttonColor=null] 按钮颜色
 * @property {string} [buttonActiveColor=null] 按钮激活颜色
 * @property {string} [buttonBg=null] 按钮背景
 * @property {string} [buttonActiveBg=null] 按钮激活背景
 * @property {string} [buttonPadding=null] 按钮内边距
 * @property {string} [buttonBorder=null] 按钮边框
 * @property {string} [buttonActiveBorder=null] 按钮激活边框
 * @property {string|number} [buttonRadius=null] 按钮圆角
 * @property {string} [indicatorColor=null] 指示器颜色
 * @property {string|number} [indicatorWidth=null] 指示器宽度
 * @property {string|number} [indicatorHeight=null] 指示器高度
 * @property {string|number} [indicatorRadius=null] 指示器圆角
 * @property {string} [textSize='normal'] 文字大小*可选值: normal, small, medium, large*
 * @property {string} [textType='primary'] 文字类型*可选值: primary, secondary, comment, disabled*
 * @property {string} [buttonClassName=''] 按钮 className
 * @property {string} [buttonActiveClassName=''] 按钮激活时添加的 className
 * @see
 * commonPosition 可选值:
 *   frontOfHead, endOfHead, frontOfBody, endOfBody, frontOfTabs, endOfTabs
 *
 * @example
 * &lt;LinkComponent
    {...props}
    disabled={disabled}
    name={name}
    onClick={(event) => {}}
&gt;{tab}&lt;/LinkComponent&gt;
 *
 * &lt;TabCommonComponent
    disabled={disabled}
    needSlider={needSlider}
    stacked={stacked}
    value={value}
/&gt;
 * TabCommonComponent = (&lt;div&gt;内容&lt;div/&gt;)
 */
var config = {
  tabButtonWrapClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  tabButtonWrapStyle: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  tabButtonClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  tabContentWrapClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  tabContentWrapStyle: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  value: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number]).isRequired,
    defaultValue: ''
  },
  onChange: {
    type: _spauiUtils.PropTypes.func
  },
  data: {
    type: _spauiUtils.PropTypes.array
  },
  needSlider: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  sliderTopIsAdjust: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  unmountInactivePanel: {
    type: _spauiUtils.PropTypes.bool,
    unmountInactivePanel: false
  },
  disabled: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  stacked: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  unChange: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  setSliderPositionTimeout: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: 0
  },
  defaultTabButtonClassName: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  tabPanelInnerClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'spaui-tabs-body-inner'
  },
  LinkComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _link_component["default"]
  },
  TabCommonComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element])
  },
  commonPosition: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'endOfHead'
  },
  frontOfHeadContent: {
    type: _spauiUtils.PropTypes.any
  },
  endOfHeadContent: {
    type: _spauiUtils.PropTypes.any
  },
  frontOfBodyContent: {
    type: _spauiUtils.PropTypes.any
  },
  endOfBodyContent: {
    type: _spauiUtils.PropTypes.any
  },
  frontOfTabsContent: {
    type: _spauiUtils.PropTypes.any
  },
  endOfTabsContent: {
    type: _spauiUtils.PropTypes.any
  },
  navGap: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number])
  },
  navBorder: {
    type: _spauiUtils.PropTypes.string
  },
  buttonColor: {
    type: _spauiUtils.PropTypes.string
  },
  buttonActiveColor: {
    type: _spauiUtils.PropTypes.string
  },
  buttonBg: {
    type: _spauiUtils.PropTypes.string
  },
  buttonActiveBg: {
    type: _spauiUtils.PropTypes.string
  },
  buttonPadding: {
    type: _spauiUtils.PropTypes.string
  },
  buttonBorder: {
    type: _spauiUtils.PropTypes.string
  },
  buttonActiveBorder: {
    type: _spauiUtils.PropTypes.string
  },
  buttonRadius: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number])
  },
  buttonClassName: {
    type: _spauiUtils.PropTypes.string
  },
  buttonActiveClassName: {
    type: _spauiUtils.PropTypes.string
  },
  indicatorColor: {
    type: _spauiUtils.PropTypes.string
  },
  indicatorWidth: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number])
  },
  indicatorHeight: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number])
  },
  indicatorRadius: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number])
  },
  textSize: {
    type: _spauiUtils.PropTypes.oneOf(['normal', 'small', 'medium', 'large'])
  },
  textType: {
    type: _spauiUtils.PropTypes.oneOf(['primary', 'secondary', 'comment', 'disabled'])
  }
}; // 转化成PureComponent组件的配置

exports.config = config;
var configData = (0, _spauiUtils.formatProps)(config); // 导出

var propTypes = configData.propTypes;
exports.propTypes = propTypes;
var defaultProps = configData.defaultProps;
exports.defaultProps = defaultProps;
var _default = {
  config: config,
  propTypes: propTypes,
  defaultProps: defaultProps
};
exports["default"] = _default;