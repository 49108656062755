"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
var TaskComponent = function TaskComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var onClick = props.onClick,
      href = props.href,
      _props$statusClassNam = props.statusClassName,
      statusClassName = _props$statusClassNam === void 0 ? '' : _props$statusClassNam,
      _props$label = props.label,
      label = _props$label === void 0 ? '' : _props$label;
  return _react["default"].createElement("li", {
    onClick: onClick
  }, _react["default"].createElement("a", {
    href: href,
    className: statusClassName
  }, label));
};

var _default = TaskComponent;
exports["default"] = _default;