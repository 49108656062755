"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _spauiUtils = require("@tencent/spaui-utils");

var _spauiPopover = require("@tencent/spaui-popover");

var _classnames = _interopRequireDefault(require("classnames"));

var _index = require("../utils/index");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

var DomComponent = function DomComponent(props) {
  var _props$data = props.data,
      data = _props$data === void 0 ? [] : _props$data,
      activeData = props.activeData,
      clear = props.clear,
      onClear = props.onClear,
      onDelete = props.onDelete,
      disabled = props.disabled,
      readonly = props.readonly,
      devProps = props.devProps,
      tipRender = props.tipRender,
      setItemClassName = props.setItemClassName,
      ClearComponent = props.ClearComponent,
      _props$ClearComponent = props.ClearComponentProps,
      ClearComponentProps = _props$ClearComponent === void 0 ? {} : _props$ClearComponent,
      DeleteComponent = props.DeleteComponent,
      _props$DeleteComponen = props.DeleteComponentProps,
      DeleteComponentProps = _props$DeleteComponen === void 0 ? {} : _props$DeleteComponen,
      AddComponent = props.AddComponent,
      _props$AddComponentPr = props.AddComponentProps,
      AddComponentProps = _props$AddComponentPr === void 0 ? {} : _props$AddComponentPr,
      _props$componentSeque = props.componentSequence,
      componentSequence = _props$componentSeque === void 0 ? [] : _props$componentSeque,
      _props$OverlayTrigger = props.OverlayTriggerProps,
      OverlayTriggerProps = _props$OverlayTrigger === void 0 ? {} : _props$OverlayTrigger,
      _props$classNameMap = props.classNameMap,
      classNameMap = _props$classNameMap === void 0 ? {} : _props$classNameMap,
      _props$children = props.children,
      children = _props$children === void 0 ? null : _props$children,
      onMouseEnter = props.onMouseEnter,
      onMouseLeave = props.onMouseLeave,
      _props$activeFieldNam = props.activeFieldName,
      activeFieldName = _props$activeFieldNam === void 0 ? '' : _props$activeFieldNam,
      onLabelClick = props.onLabelClick,
      valueFieldName = props.valueFieldName,
      labelFieldName = props.labelFieldName,
      setNodeProps = props.setNodeProps,
      prefix = props.prefix;
  var componentMap = {};
  var componentList = [];
  var clearShow = true;
  var key = '';

  if (!clear || disabled || readonly) {
    clearShow = false;
  } // 清除结构组件


  if (data.length > 0 && typeof ClearComponent !== 'undefined' && ClearComponent) {
    var clearAdditionProps = {};

    if ((0, _spauiUtils.isFunction)(setNodeProps)) {
      var clearSetNodePropsResult = setNodeProps('clear');

      if (_typeof(clearSetNodePropsResult) === 'object' && clearSetNodePropsResult) {
        clearAdditionProps = clearSetNodePropsResult;
      }
    }

    componentMap.clear = _react["default"].createElement(ClearComponent, _extends({}, ClearComponentProps, clearAdditionProps, {
      classNameMap: classNameMap,
      key: "clear",
      show: clearShow,
      onClick: onClear
    }));
  } // 附加组件


  if (typeof AddComponent !== 'undefined' && AddComponent) {
    var addAdditionProps = {};

    if ((0, _spauiUtils.isFunction)(setNodeProps)) {
      var addSetNodePropsResult = setNodeProps('add');

      if (_typeof(addSetNodePropsResult) === 'object' && addSetNodePropsResult) {
        addAdditionProps = addSetNodePropsResult;
      }
    }

    componentMap.add = _react["default"].createElement(AddComponent, _extends({
      key: "add"
    }, AddComponentProps, addAdditionProps));
  } // 如果有指定顺序的就放在前面，并删除掉标记


  componentSequence.map(function (item) {
    if (item && componentMap[item]) {
      componentList.push(componentMap[item]);
      delete componentMap[item];
    }

    return item;
  }); // 把还没有排序的添加上来

  for (key in componentMap) {
    if (componentMap[key]) {
      componentList.push(componentMap[key]);
    }
  }

  return _react["default"].createElement("ul", (0, _spauiUtils.globalProps)(props, {}, devProps[0], devProps[1]), prefix, data.map(function (item, index) {
    var _classNames;

    var label = '';
    var value = '';
    var isDelete = true;
    var itemDom = null;
    var result = null;
    var tip = '';
    var itemClassName = '';
    var clearDom = null; // 看看是否指定了tip

    if (_typeof(item) !== 'object') {
      label = item;
      value = item;
    } else {
      label = item[labelFieldName] || item[valueFieldName] || '';
      value = item[valueFieldName] || '';

      if (typeof item["delete"] !== 'undefined' && !item["delete"]) {
        isDelete = false;
      } else if (typeof item.disabled !== 'undefined' && item.disabled) {
        isDelete = false;
      }

      tip = item.tip || '';
    } // 是否自定义tip内容


    if ((0, _spauiUtils.isFunction)(tipRender)) {
      tip = tipRender(_objectSpread({}, props, {
        item: item
      }));
    } // 动态根据数据设置className


    if ((0, _spauiUtils.isFunction)(setItemClassName)) {
      itemClassName = setItemClassName(item, disabled || !isDelete);
    }

    var itemAddtionalProps = {};
    var itemDeleteProps = {};

    if ((0, _spauiUtils.isFunction)(setNodeProps)) {
      var itemSetNodePropsResult = setNodeProps('element', item);

      if (_typeof(itemSetNodePropsResult) === 'object' && itemSetNodePropsResult) {
        itemAddtionalProps = itemSetNodePropsResult;
      }

      var itemDeleteSetNodePropsResult = setNodeProps('element-delete', item);

      if (_typeof(itemDeleteSetNodePropsResult) === 'object' && itemDeleteSetNodePropsResult) {
        itemDeleteProps = itemDeleteSetNodePropsResult;
      }
    }

    if (!readonly) {
      clearDom = _react["default"].createElement(DeleteComponent, _extends({
        itemDeleteProps: itemDeleteProps
      }, DeleteComponentProps, {
        item: item,
        onDelete: onDelete
      }));
    } // 默认的标签结构


    itemDom = _react["default"].createElement("li", _extends({}, itemAddtionalProps, {
      onClick: function onClick(event) {
        onLabelClick(event, item, disabled || _typeof(item) === 'object' && item && item.disabled);
      },
      key: value,
      className: (0, _classnames["default"])(_typeof(item) === 'object' && item ? item.className || '' : '', itemClassName, itemAddtionalProps.className || '', (_classNames = {}, _defineProperty(_classNames, classNameMap.LabelsItemReadonlyClassName, disabled || !isDelete || readonly), _defineProperty(_classNames, classNameMap.LabelsActiveClassName, (0, _index.isInActiveData)(activeData, item, activeFieldName) > -1), _defineProperty(_classNames, 'spaui-cursor-pointer', typeof activeData !== 'undefined' && !disabled && !item.disabled), _classNames)),
      style: _typeof(item) === 'object' && item ? item.style || {} : {},
      onMouseEnter: onMouseEnter ? function () {
        onMouseEnter(item, index);
      } : undefined,
      onMouseLeave: onMouseLeave ? function () {
        onMouseLeave(item, index);
      } : undefined
    }), label, clearDom); // 如果有提示文案就添加popover

    if (tip) {
      result = _react["default"].createElement(_spauiPopover.OverlayTrigger, _extends({
        key: index,
        overlay: _react["default"].createElement(_spauiPopover.PopOver, null, tip)
      }, OverlayTriggerProps), itemDom);
    } else {
      result = itemDom;
    }

    return result;
  }), componentList, children);
};

var _default = DomComponent;
exports["default"] = _default;