"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _carousel = _interopRequireDefault(require("./carousel"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 导出组件
 * @author kirkzheng
 */
// 调试信息
// if (process.env.NODE_ENV !== 'production') {
//     const {whyDidYouUpdate} = require('why-did-you-update');
//     whyDidYouUpdate(React);
// }
var _default = _carousel["default"];
exports["default"] = _default;