"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.formatRangeDateTimeValueToDisplayValue = exports.validate = exports.splitValue = exports.formatDate = exports.transInputDateTimeFormat = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

var _index = require("./index");

var _data = require("../config/data");

var _datepicker_compact_props = _interopRequireDefault(require("../datepicker_compact_props"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

/**
 * 验证一个日期格式是否合格
 * @param {string} year 年
 * @param {string} month 月
 * @param {string} day 日
 */
var isRegularFormatDate = function isRegularFormatDate() {
  var year = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var month = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var day = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';

  if (/^\d{4}$/.test(year) && /^\d{2}$/.test(month) && /^\d{2}$/.test(day)) {
    return true;
  }

  return false;
}; // 支持的时间粒度，比如是否支持时 or 分 or 秒


var supportedTimeGranularity = function supportedTimeGranularity() {
  var timePickerProps = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _timePickerProps$time = timePickerProps.timeList,
      timeList = _timePickerProps$time === void 0 ? [] : _timePickerProps$time,
      _timePickerProps$conf = timePickerProps.config,
      config = _timePickerProps$conf === void 0 ? {} : _timePickerProps$conf;
  var _config$hour = config.hour,
      hour = _config$hour === void 0 ? false : _config$hour,
      _config$minute = config.minute,
      minute = _config$minute === void 0 ? false : _config$minute,
      _config$second = config.second,
      second = _config$second === void 0 ? false : _config$second; // 主要判断下时间列表要显示到哪个节点

  timeList.map(function (item) {
    if (item.length > 0 && item.length < 3) {
      hour = true;
    } else if (item.length > 3 && item.length < 6) {
      hour = true;
      minute = true;
    } else if (item.length > 5) {
      hour = true;
      minute = true;
      second = true;
    }

    return item;
  });
  return {
    hour: hour,
    minute: minute,
    second: second
  };
};
/**
 * 转换单个日期时间格式
 * @param {string} dateTime 输入值中的单个日期时间
 * @param {string|array} dateFormat 当前日期的格式
 */


var transDateTimeFormat = function transDateTimeFormat() {
  var dateTime = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var dateFormat = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var dateFormatList = [];

  if ((0, _spauiUtils.isArray)(dateFormat)) {
    dateFormatList = dateFormat;
  } else {
    dateFormatList = [dateFormat];
  }

  for (var i = 0; i < dateFormatList.length; i++) {
    var dateFormatItem = dateFormatList[i];
    var yearIndex = dateFormatItem.indexOf('YYYY');
    var monthIndex = dateFormatItem.indexOf('MM');
    var dayIndex = dateFormatItem.indexOf('DD');
    var year = yearIndex >= 0 ? dateTime.slice(yearIndex, yearIndex + 4) : '';
    var month = monthIndex >= 0 ? dateTime.slice(monthIndex, monthIndex + 2) : '';
    var day = dayIndex >= 0 ? dateTime.slice(dayIndex, dayIndex + 2) : ''; // 如果匹配到了这个日期格式就直接返回，如果没有匹配中，在匹配最后一个的时候都没有匹配中就返回最后一个

    if (isRegularFormatDate(year, month, day) || i + 1 === dateFormatList.length) {
      return year + month + day + dateTime.slice(dateFormatItem.length);
    }
  }
};
/**
 * 转换输入的日期时间
 * @param {string} inputDateTime 用户输入的日期时间
 * @param {object} props 组件的 props
 */


var transInputDateTimeFormat = function transInputDateTimeFormat() {
  var inputDateTime = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var props = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var inputDateFormat = props.inputDateFormat,
      inputDateSeparator = props.inputDateSeparator,
      type = props.type,
      startDateCanBeUnlimited = props.startDateCanBeUnlimited,
      unlimitedDateValueLabel = props.unlimitedDateValueLabel,
      endDateCanBeUnlimited = props.endDateCanBeUnlimited;

  if (type === 'single') {
    return transDateTimeFormat(inputDateTime, inputDateFormat);
  } // 是否是可以选择无限日期


  var valueArray = inputDateTime.split(inputDateSeparator);
  var startDateIsUnlimited = false;
  var endDateIsUnlimited = false;

  if (startDateCanBeUnlimited && unlimitedDateValueLabel && valueArray[0] === unlimitedDateValueLabel) {
    startDateIsUnlimited = true;
  }

  if (endDateCanBeUnlimited && unlimitedDateValueLabel && valueArray[1] === unlimitedDateValueLabel) {
    endDateIsUnlimited = true;
  } // 根据起始和结束日期是否是无限日期来返回对应的日期格式


  if (!startDateIsUnlimited && !endDateIsUnlimited) {
    var startDateTime = transDateTimeFormat(valueArray[0] || '', inputDateFormat);
    var endDateTime = transDateTimeFormat(valueArray[1] || '', inputDateFormat);
    return startDateTime + _data.RANGE_DATE_SEPARATOR + endDateTime;
  }

  if (startDateIsUnlimited && !endDateIsUnlimited) {
    return unlimitedDateValueLabel + _data.RANGE_DATE_SEPARATOR + transDateTimeFormat(valueArray[1] || '', inputDateFormat);
  }

  if (!startDateIsUnlimited && endDateIsUnlimited) {
    return transDateTimeFormat(valueArray[0] || '', inputDateFormat) + _data.RANGE_DATE_SEPARATOR + unlimitedDateValueLabel;
  }

  return unlimitedDateValueLabel + _data.RANGE_DATE_SEPARATOR + unlimitedDateValueLabel;
};
/**
 * 转换格式，将object格式的数据转成成字符串
 * @param {object} data 日期信息
 * @param {object} timePickerProps 日期选择器属性
 * @param {string|array} format 日期转换后的格式
 */


exports.transInputDateTimeFormat = transInputDateTimeFormat;

var formatDate = function formatDate() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var timePickerProps = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var format = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'YYYYMMDD';

  var _supportedTimeGranula = supportedTimeGranularity(timePickerProps),
      _supportedTimeGranula2 = _supportedTimeGranula.hour,
      hour = _supportedTimeGranula2 === void 0 ? false : _supportedTimeGranula2,
      _supportedTimeGranula3 = _supportedTimeGranula.minute,
      minute = _supportedTimeGranula3 === void 0 ? false : _supportedTimeGranula3,
      _supportedTimeGranula4 = _supportedTimeGranula.second,
      second = _supportedTimeGranula4 === void 0 ? false : _supportedTimeGranula4;

  var result = ''; // 必须有年份才能够转行

  if (data.year) {
    // 如果传递了多个日期格式，默认使用第一个格式来进行转换
    // 先转日期，再根据需要转时间
    var firstFormat = (0, _spauiUtils.isArray)(format) ? format[0] : format;
    result = firstFormat.replace('YYYY', data.year).replace('MM', (0, _index.addZero)(data.month + 1)).replace('DD', (0, _index.addZero)(data.date));

    if (hour && typeof data.hour !== 'undefined') {
      result = "".concat(result, " ").concat((0, _index.addZero)(data.hour));
    }

    if (minute && typeof data.minute !== 'undefined') {
      result = result + (0, _index.addZero)(data.minute);
    }

    if (second && typeof data.second !== 'undefined') {
      result = result + (0, _index.addZero)(data.second);
    }
  }

  return result;
}; // 是否在timeList内


exports.formatDate = formatDate;

var inTimeList = function inTimeList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var timeList = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var result = false;
  var timeString = ''; // 如果有timeList列表的话，就把data数据的时分秒先转行成字符串再对比下

  if (typeof data.hour !== 'undefined') {
    timeString = (0, _index.addZero)(data.hour);
  }

  if (typeof data.minute !== 'undefined') {
    timeString += ":".concat((0, _index.addZero)(data.minute));
  }

  if (typeof data.second !== 'undefined') {
    timeString += ":".concat((0, _index.addZero)(data.second));
  }

  timeList.map(function (item) {
    if (item && item === timeString) {
      result = true;
    }

    return item;
  });
  return result;
}; // 是否在hourList or minuteList or secondList内


var inHourListAndMinuteListAndSecondList = function inHourListAndMinuteListAndSecondList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var hourList = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var minuteList = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  var secondList = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
  var result = false;

  if ((0, _spauiUtils.isArray)(hourList) && typeof data.hour !== 'undefined' && (0, _spauiUtils.indexOf)(hourList, data.hour) > -1 && (!(0, _spauiUtils.isArray)(minuteList) || (0, _spauiUtils.isArray)(minuteList) && typeof data.minute !== 'undefined' && (0, _spauiUtils.indexOf)(minuteList, data.minute) > -1) && (!(0, _spauiUtils.isArray)(secondList) || (0, _spauiUtils.isArray)(secondList) && typeof data.second !== 'undefined' && (0, _spauiUtils.indexOf)(secondList, data.second) > -1)) {
    result = true;
  }

  return result;
}; // 是否在时间配置内


var inTimeConfig = function inTimeConfig() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var config = arguments.length > 1 ? arguments[1] : undefined;
  var hour = data.hour,
      minute = data.minute,
      second = data.second;
  var hourIsOk = config.hour && hour > -1 && hour < 24;
  var minuteIsOk = !config.minute || config.minute && minute > -1 && minute < 60;
  var secondIsOk = !config.second || config.second && second > -1 && second < 60;
  var result = false;

  if (hourIsOk && minuteIsOk && secondIsOk) {
    result = true;
  }

  return result;
}; // 是否在timePickerProps的数据里面


var inTimePickerData = function inTimePickerData() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var timePickerProps = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var timeList = timePickerProps.timeList,
      hourList = timePickerProps.hourList,
      minuteList = timePickerProps.minuteList,
      secondList = timePickerProps.secondList,
      config = timePickerProps.config;
  var result = false;

  if ((0, _spauiUtils.isArray)(timeList)) {
    result = inTimeList(data, timeList);
  } else if ((0, _spauiUtils.isArray)(hourList) || (0, _spauiUtils.isArray)(minuteList) || (0, _spauiUtils.isArray)(secondList)) {
    result = inHourListAndMinuteListAndSecondList(data, hourList, minuteList, secondList);
  } else if (_typeof(config) === 'object') {
    result = inTimeConfig(data, config);
  }

  return result;
};
/**
 * 获取字符串中对应子字符串的个数
 * @param {string} string 字符串
 * @param {string} char 子字符串
 */


var getCharCount = function getCharCount() {
  var string = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

  var _char = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

  var subCharReg = new RegExp(_char, 'g');
  return (string.match(subCharReg) || []).length;
}; // 拆分value变更为年月日


var splitValue = function splitValue() {
  var theValue = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var timePickerProps = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var value = (0, _spauiUtils.trim)(theValue);

  var _supportedTimeGranula5 = supportedTimeGranularity(timePickerProps),
      _supportedTimeGranula6 = _supportedTimeGranula5.hour,
      hour = _supportedTimeGranula6 === void 0 ? false : _supportedTimeGranula6,
      _supportedTimeGranula7 = _supportedTimeGranula5.minute,
      minute = _supportedTimeGranula7 === void 0 ? false : _supportedTimeGranula7,
      _supportedTimeGranula8 = _supportedTimeGranula5.second,
      second = _supportedTimeGranula8 === void 0 ? false : _supportedTimeGranula8;

  var result = {};
  var tempYear = 0;
  var tempMonth = 0;
  var tempDate = 0;
  var tempHour = 0;
  var tempMinute = 0;
  var tempSecond = 0;
  var tempDateObject = null;
  var len = 8; // 如果有时分秒长度是不一样的

  if (hour) {
    // +3主要是，日期与时间中间有个空格
    len += 3;
  }

  if (minute) {
    len += 2;
  }

  if (second) {
    len += 2;
  } // 只有满足了长度才能够进行拆解


  if (value.length === len) {
    // 将对应位置的数据提取出来先
    tempYear = (0, _index.toInt)(value.slice(0, 4));
    tempMonth = (0, _index.toInt)(value.slice(4, 6)) - 1;
    tempDate = (0, _index.toInt)(value.slice(6, 8));
    tempHour = (0, _index.toInt)(value.slice(9, 11));
    tempMinute = (0, _index.toInt)(value.slice(11, 13));
    tempSecond = (0, _index.toInt)(value.slice(13, 15)); // 先验证是否满足日期的

    if (tempYear > 0 && tempMonth > -1 && tempMonth < 12 && tempDate > 0 && tempDate < 32) {
      // 重新获取下日期数据，有可能对应月份是没有那个日期的但是又是符合范围的，比如20190229
      tempDateObject = new Date(tempYear, tempMonth, tempDate, tempHour, tempMinute, tempSecond);
      result.year = tempDateObject.getFullYear();
      result.month = tempDateObject.getMonth();
      result.date = tempDateObject.getDate();

      if (hour) {
        result.hour = tempDateObject.getHours();
      }

      if (minute) {
        result.minute = tempDateObject.getMinutes();
      }

      if (second) {
        result.second = tempDateObject.getSeconds();
      }
    }
  }

  return result;
}; // 符合长度与格式，进行数值的验证，检验一个日期


exports.splitValue = splitValue;

var checkOneDate = function checkOneDate() {
  var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var props = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  var optionalStartDateObject = props.optionalStartDateObject,
      optionalEndDateObject = props.optionalEndDateObject,
      timePicker = props.timePicker,
      timePickerProps = props.timePickerProps,
      startTimePickerProps = props.startTimePickerProps,
      unlimitedDateValueLabel = props.unlimitedDateValueLabel,
      startDateCanBeUnlimited = props.startDateCanBeUnlimited;
  var validateType = '';
  var valueData = {};
  var dateObject = null;
  var oneDateTimePickerProps = {};
  var oneDateSuccessMsg = 'success';
  var startDateIsUnlimited = false;

  if (startDateCanBeUnlimited && unlimitedDateValueLabel && value === unlimitedDateValueLabel) {
    startDateIsUnlimited = true;
  } // 双日期的开始日期返回参数，成功返回有点不一样


  if (type === 'single') {
    oneDateTimePickerProps = timePickerProps;
  } else {
    oneDateTimePickerProps = startTimePickerProps;
    oneDateSuccessMsg = 'startDate success';
  } // 长度且格式符合


  valueData = splitValue(value, oneDateTimePickerProps);

  if (valueData.year) {
    dateObject = new Date(valueData.year, valueData.month, valueData.date);
    var dateTimeStamp = dateObject.getTime(); // 验证是否在可选日期范围内

    if (dateTimeStamp >= optionalStartDateObject.getTime() && dateTimeStamp <= optionalEndDateObject.getTime()) {
      // 如果有开启时分秒，看看是否数据在时分秒的选项内
      if (timePicker) {
        if (inTimePickerData(valueData, oneDateTimePickerProps)) {
          validateType = oneDateSuccessMsg;
        } else {
          validateType = 'time error';
        }
      } else {
        validateType = oneDateSuccessMsg;
      }
    } else {
      validateType = 'value is not optional';
    }
  } else {
    if (type === 'single') {
      validateType = 'value error';
    } else {
      validateType = 'value too short';
    }
  } // 检验开始日期是否无限日期


  if (type === 'startDate' && startDateIsUnlimited) {
    validateType = oneDateSuccessMsg;
  }

  return validateType;
}; // 符合长度与格式，进行数值的验证, 检验两个日期


var checkTwoDate = function checkTwoDate() {
  var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var props = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var _compactProps = (0, _datepicker_compact_props["default"])(props),
      optionalStartDateObject = _compactProps.optionalStartDateObject,
      optionalEndDateObject = _compactProps.optionalEndDateObject,
      shortestRange = _compactProps.shortestRange,
      longestRange = _compactProps.longestRange,
      timePicker = _compactProps.timePicker,
      startTimePickerProps = _compactProps.startTimePickerProps,
      endTimePickerProps = _compactProps.endTimePickerProps,
      minTimeRange = _compactProps.minTimeRange,
      maxTimeRange = _compactProps.maxTimeRange,
      unlimitedDateValueLabel = _compactProps.unlimitedDateValueLabel,
      startDateCanBeUnlimited = _compactProps.startDateCanBeUnlimited,
      endDateCanBeUnlimited = _compactProps.endDateCanBeUnlimited;

  var validateType = '';
  var startValueData = {};
  var endValueData = {};
  var startDateObject = null;
  var startDateTimeStamp = 0;
  var endDateObject = null;
  var endDateTimeStamp = 0;
  var valueArray = [];
  var spaceTime = 0;
  var startDateIsUnlimited = false;
  var endDateIsUnlimited = false; // 符合长度与格式，进行数值的验证
  // 长度且格式符合

  valueArray = value.split(_data.RANGE_DATE_SEPARATOR) || [];
  startValueData = splitValue(valueArray[0], startTimePickerProps);
  endValueData = splitValue(valueArray[1], endTimePickerProps);
  startDateIsUnlimited = startDateCanBeUnlimited && unlimitedDateValueLabel && valueArray[0] === unlimitedDateValueLabel;
  endDateIsUnlimited = endDateCanBeUnlimited && unlimitedDateValueLabel && valueArray[1] === unlimitedDateValueLabel;

  if ((startValueData.year || startDateIsUnlimited) && (endValueData.year || endDateIsUnlimited)) {
    startDateObject = new Date(startValueData.year, startValueData.month, startValueData.date);
    startDateTimeStamp = startDateObject.getTime();
    endDateObject = new Date(endValueData.year, endValueData.month, endValueData.date);
    endDateTimeStamp = endDateObject.getTime();
    var optionalStartDateTimeStamp = optionalStartDateObject.getTime();
    var optionalEndDateTimeStamp = optionalEndDateObject.getTime(); // 判断下是否都在范围内

    if ((startDateTimeStamp >= optionalStartDateTimeStamp && startDateTimeStamp <= optionalEndDateTimeStamp || startDateIsUnlimited) && (endDateTimeStamp >= optionalStartDateTimeStamp && endDateTimeStamp <= optionalEndDateTimeStamp || endDateIsUnlimited)) {
      // 是否符合间距
      spaceTime = Math.abs(endDateTimeStamp - startDateTimeStamp); // 是否符合范围间距

      if (spaceTime >= shortestRange * _data.ONE_DAY_TIME && spaceTime <= longestRange * _data.ONE_DAY_TIME || startDateIsUnlimited || endDateIsUnlimited) {
        if (timePicker) {
          // 如果有开启时分秒，看看是否数据在时分秒的选项内
          if ((inTimePickerData(startValueData, startTimePickerProps) || startDateIsUnlimited) && (inTimePickerData(endValueData, endTimePickerProps) || endDateIsUnlimited)) {
            validateType = 'success';

            if ((typeof maxTimeRange !== 'undefined' || typeof minTimeRange !== 'undefined') && !startDateIsUnlimited && !endDateIsUnlimited) {
              startDateObject = new Date(startValueData.year, startValueData.month, startValueData.date, startValueData.hour || 0, startValueData.minute || 0, startValueData.second || 0);
              endDateObject = new Date(endValueData.year, endValueData.month, endValueData.date, endValueData.hour || 0, endValueData.minute || 0, endValueData.second || 0);

              if (typeof maxTimeRange !== 'undefined') {
                if (Math.abs(endDateObject.getTime() - startDateObject.getTime()) > maxTimeRange) {
                  validateType = 'time error';
                }
              }

              if (typeof minTimeRange !== 'undefined') {
                if (Math.abs(endDateObject.getTime() - startDateObject.getTime()) < minTimeRange) {
                  validateType = 'time error';
                }
              }
            }
          } else {
            validateType = 'time error';
          }
        } else {
          validateType = 'success';
        }
      } else {
        validateType = 'unqualified spacing';
      }
    } else {
      validateType = 'value is not optional';
    }
  } else {
    validateType = 'value error';
  }

  return validateType;
};
/**
 * 日期格式符合后进行验证是否ok
 * @param {string} value 输入框的值
 * @param {object} props 面板props
 * @param {string} type 验证的内容类似，single单日期组件的验证，startDate验证双日期的开始日期，range验证双日期
 */


var checkDate = function checkDate() {
  var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var props = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  var validateType = ''; // 符合长度与格式，进行数值的验证

  if (type === 'single' || type === 'startDate') {
    validateType = checkOneDate(value, props, type);
  } else if (type === 'range') {
    validateType = checkTwoDate(value, props, type);
  }

  return validateType;
}; // 获取字符长度跟提示配置


var getLengthAndTipBytimePickerProps = function getLengthAndTipBytimePickerProps() {
  var timePickerProps = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // 根据支持的力度不一样，长度，提示也不一样
  var timeGranularity = supportedTimeGranularity(timePickerProps);
  var length = 0;
  var timeString = '';
  var timeTipString = '';

  if (timeGranularity.hour) {
    length += 3; // 小时前有个空格

    timeString = ' 10';
    timeTipString = ' 10:00';
  }

  if (timeGranularity.minute) {
    length += 2;
    timeString += '20';
    timeTipString = ' 10:20';
  }

  if (timeGranularity.second) {
    length += 2;
    timeString += '40';
    timeTipString = ' 10:20:40';
  } // 如果是自己传的timeList就用第一个展示提示


  if (timePickerProps && timePickerProps.timeList && timePickerProps.timeList.length > 0) {
    timeString = " ".concat(timePickerProps.timeList[0].replace(/:/gi, ''));
    timeTipString = " ".concat(timePickerProps.timeList[0]);
  }

  return {
    length: length,
    timeString: timeString,
    timeTipString: timeTipString
  };
}; // 检查单日期


var validateForSingleDate = function validateForSingleDate() {
  var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var props = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var optionalStartDateData = props.optionalStartDateData,
      optionalEndDateData = props.optionalEndDateData,
      clear = props.clear,
      timePicker = props.timePicker,
      timePickerProps = props.timePickerProps,
      _props$locale = props.locale,
      locale = _props$locale === void 0 ? {} : _props$locale,
      singleDateInputFormatErrorLabel = props.singleDateInputFormatErrorLabel,
      inputDateFormat = props.inputDateFormat; // 错误提示信息

  var errorTipMap = {
    "default": singleDateInputFormatErrorLabel || locale.errorTipDefaultLabelBySingle,
    // 默认提示
    'value is not optional': locale.errorTipValueIsNotOptionalLabelBySingle.replace('{date1}', formatDate(optionalStartDateData, {}, inputDateFormat)).replace('{date2}', formatDate(optionalEndDateData, {}, inputDateFormat)),
    'time error': locale.errorTipFormatErrorBySingle
  };
  var singleErrorMessage = '';
  var singleDateReg = null;
  var validateType = 'value is empty';
  var len = 0;
  var timeGranularity = {};
  var timeTipString = '';
  var timeString = '';
  var lengthAndTipBytimePickerPropsData = {};

  if (value.length > 0) {
    len = 8;

    if (timePicker) {
      singleDateReg = /^[0-9]*\s?[0-9]*$/gi; // 根据支持的力度不一样，长度，提示也不一样

      timeGranularity = supportedTimeGranularity(timePickerProps);
      lengthAndTipBytimePickerPropsData = getLengthAndTipBytimePickerProps(timePickerProps);
      len += lengthAndTipBytimePickerPropsData.length;
      timeString = lengthAndTipBytimePickerPropsData.timeString;
      timeTipString = lengthAndTipBytimePickerPropsData.timeTipString;
    } else {
      singleDateReg = /^[0-9]*$/gi;
    }

    if (!singleDateReg.test(value)) {
      validateType = 'format error';
    } else if (value.length > 8 && timeGranularity.hour && value.indexOf(' ') !== 8) {
      // 日期中间必须有个空格
      validateType = 'format error';
    } else if (value.length < len) {
      validateType = 'value too short';
    } else if (value.length > len) {
      validateType = 'value too long';
    } else {
      // 根据返回类型，定制一下提示文案
      validateType = checkDate(value, props, 'single');
    }
  } else if (clear) {
    validateType = 'success';
  } // 根据返回类型，定制一下提示文案


  singleErrorMessage = (errorTipMap[validateType] || errorTipMap["default"]).replace(/{time}/gi, timeString).replace(/{timeTip}/gi, timeTipString);
  return {
    type: validateType,
    msg: validateType !== 'success' ? singleErrorMessage : ''
  };
}; // 获取两个日期的间隔时间


var getDaySpaces = function getDaySpaces() {
  var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var props = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var startTimePickerProps = props.startTimePickerProps,
      endTimePickerProps = props.endTimePickerProps;
  var valueArray = value.split(_data.RANGE_DATE_SEPARATOR) || [];
  var daySpaces = 0;
  var startValueData = {};
  var endValueData = {};
  var startDateObject = null;
  var endDateObject = null;

  if (valueArray.length === 2 && valueArray[0] && valueArray[1]) {
    startValueData = splitValue(valueArray[0], startTimePickerProps);
    endValueData = splitValue(valueArray[1], endTimePickerProps);

    if (startValueData.year && endValueData.year) {
      startDateObject = new Date(startValueData.year, startValueData.month, startValueData.date);
      endDateObject = new Date(endValueData.year, endValueData.month, endValueData.date);
      daySpaces = Math.ceil(Math.abs(endDateObject.getTime() - startDateObject.getTime()) / _data.ONE_DAY_TIME);
    }
  }

  return daySpaces;
}; // 获取双日期的验证信息


var getCheckInfoForRangeDate = function getCheckInfoForRangeDate() {
  var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var props = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var timePicker = props.timePicker,
      startTimePickerProps = props.startTimePickerProps,
      endTimePickerProps = props.endTimePickerProps;
  var singleDateReg = null;
  var rangeDateReg = null;
  var len = 0;
  var startLen = 0;
  var endLen = 0;
  var startTimeTipString = '';
  var startTimeString = '';
  var endTimeTipString = '';
  var endTimeString = '';
  var lengthAndTipBytimePickerPropsData = {};

  if (value.length > 0) {
    len = 8 * 2 + _data.RANGE_DATE_SEPARATOR.length;
    startLen = 8;
    endLen = 8;

    if (timePicker) {
      rangeDateReg = /^[0-9]*\s?[0-9]*\s{3}-?\s{3}[0-9]*\s?[0-9]*$/gi;
      singleDateReg = /^[0-9]*\s?[0-9]*$/gi; // 根据支持的力度不一样，长度，提示也不一样

      lengthAndTipBytimePickerPropsData = getLengthAndTipBytimePickerProps(startTimePickerProps);
      len += lengthAndTipBytimePickerPropsData.length;
      startLen += lengthAndTipBytimePickerPropsData.length;
      startTimeString = lengthAndTipBytimePickerPropsData.timeString;
      startTimeTipString = lengthAndTipBytimePickerPropsData.timeTipString;
      lengthAndTipBytimePickerPropsData = getLengthAndTipBytimePickerProps(endTimePickerProps);
      len += lengthAndTipBytimePickerPropsData.length;
      endLen += lengthAndTipBytimePickerPropsData.length;
      endTimeString = lengthAndTipBytimePickerPropsData.timeString;
      endTimeTipString = lengthAndTipBytimePickerPropsData.timeTipString;
    } else {
      rangeDateReg = /^[0-9]*\s{3}-?\s{3}[0-9]*$/gi;
      singleDateReg = /^[0-9]*$/gi;
    }
  }

  return {
    singleDateReg: singleDateReg,
    rangeDateReg: rangeDateReg,
    len: len,
    startLen: startLen,
    startTimeString: startTimeString,
    startTimeTipString: startTimeTipString,
    endLen: endLen,
    endTimeString: endTimeString,
    endTimeTipString: endTimeTipString
  };
}; // 检查双日期


var validateForRangeDate = function validateForRangeDate() {
  var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var props = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var optionalStartDateData = props.optionalStartDateData,
      optionalEndDateData = props.optionalEndDateData,
      shortestRange = props.shortestRange,
      longestRange = props.longestRange,
      clear = props.clear,
      timePicker = props.timePicker,
      startTimePickerProps = props.startTimePickerProps,
      unlimitedDateValueLabel = props.unlimitedDateValueLabel,
      startDateCanBeUnlimited = props.startDateCanBeUnlimited,
      endDateCanBeUnlimited = props.endDateCanBeUnlimited,
      _props$locale2 = props.locale,
      locale = _props$locale2 === void 0 ? {} : _props$locale2,
      rangeDateInputFormatErrorLabel = props.rangeDateInputFormatErrorLabel,
      inputDateFormat = props.inputDateFormat; // 错误提示信息

  var errorTipMap = {
    "default": rangeDateInputFormatErrorLabel || locale.errorTipDefaultLabelByRange,
    // 默认提示
    'value is not optional': locale.errorTipValueIsNotOptionalLabelByRange.replace('{date1}', formatDate(optionalStartDateData, {}, inputDateFormat)).replace('{date2}', formatDate(optionalEndDateData, {}, inputDateFormat)),
    'time error': locale.errorTipFormatErrorByRange,
    'unqualified spacing': locale.errorTipSpacingErrorByRange.replace('{shortestRange}', shortestRange).replace('{longestRange}', longestRange)
  };

  var _getCheckInfoForRange = getCheckInfoForRangeDate(value, props),
      singleDateReg = _getCheckInfoForRange.singleDateReg,
      rangeDateReg = _getCheckInfoForRange.rangeDateReg,
      startTimeString = _getCheckInfoForRange.startTimeString,
      startTimeTipString = _getCheckInfoForRange.startTimeTipString,
      endTimeString = _getCheckInfoForRange.endTimeString,
      endTimeTipString = _getCheckInfoForRange.endTimeTipString;

  var _getCheckInfoForRange2 = getCheckInfoForRangeDate(value, props),
      len = _getCheckInfoForRange2.len,
      startLen = _getCheckInfoForRange2.startLen,
      endLen = _getCheckInfoForRange2.endLen;

  var startTimeGranularity = timePicker ? supportedTimeGranularity(startTimePickerProps) : {};
  var valueArray = value.split(_data.RANGE_DATE_SEPARATOR);
  var startDateIsUnlimited = startDateCanBeUnlimited && unlimitedDateValueLabel && valueArray[0] === unlimitedDateValueLabel;
  var endDateIsUnlimited = endDateCanBeUnlimited && unlimitedDateValueLabel && valueArray[1] === unlimitedDateValueLabel;
  var startDateSeparatorLength = (_data.RANGE_DATE_SEPARATOR.length + 1) / 2;
  var rangeErrorMessage = '';
  var validateType = 'value is empty'; // 如果是无限日期的话，要减去对应的日期

  if (startDateIsUnlimited) {
    len = len - startLen + unlimitedDateValueLabel.length;
    startLen = unlimitedDateValueLabel.length;
  }

  if (endDateIsUnlimited) {
    len = len - endLen + unlimitedDateValueLabel.length;
    endLen = unlimitedDateValueLabel.length;
  }

  if (value.length > 0) {
    // 如果开始日期最后带上了-，那也认为时ok的
    if (startDateIsUnlimited && value.length === startLen || value.length === startLen && singleDateReg.test(value) || value.length === startLen + startDateSeparatorLength && value.indexOf(_data.RANGE_DATE_SEPARATOR) === startLen && singleDateReg.test(value.slice(0, -startDateSeparatorLength))) {
      // 根据返回类型，定制一下提示文案
      validateType = checkDate(value.split(_data.RANGE_DATE_SEPARATOR)[0], props, 'startDate');
    } else if (!rangeDateReg.test(value) && !startDateIsUnlimited && !endDateIsUnlimited || getCharCount(value, _data.RANGE_DATE_SEPARATOR) > 1 || value.indexOf(_data.RANGE_DATE_SEPARATOR) > -1 && value.indexOf(_data.RANGE_DATE_SEPARATOR) !== startLen || value.length > 8 && startTimeGranularity.hour && value.indexOf(' ') !== 8 // 日期中间必须有个空格
    ) {
        // 需要验证下 - 的位置以及 - 的数量
        validateType = 'format error';
      } else if (value.length < len) {
      validateType = 'value too short';
    } else if (value.length > len) {
      validateType = 'value too long';
    } else if (valueArray.length !== 2 || valueArray[0].length !== startLen || valueArray[1].length !== endLen) {
      validateType = 'format error';
    } else {
      // 格式合格进行校验
      validateType = checkDate(value, props, 'range');
    }
  } else if (clear) {
    validateType = 'success';
  } // 根据返回类型，定制一下提示文案


  rangeErrorMessage = (errorTipMap[validateType] || errorTipMap["default"]).replace(/{startTime}/gi, startTimeString).replace(/{startTimeTip}/gi, startTimeTipString).replace(/{endTime}/gi, endTimeString).replace(/{endTimeTip}/gi, endTimeTipString).replace(/{daySpaces}/gi, getDaySpaces(value, props));
  return {
    type: validateType,
    msg: validateType !== 'success' ? rangeErrorMessage : ''
  };
}; // 验证值是否合格


var validate = function validate() {
  var theValue = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var props = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var value = (0, _spauiUtils.trim)(theValue);
  var _props$type = props.type,
      type = _props$type === void 0 ? '' : _props$type;
  var result = {};

  if (type === 'single') {
    result = validateForSingleDate(value, props);
  } else if (type === 'range') {
    result = validateForRangeDate(value, props);
  }

  return result;
}; // 将范围日期时间数据格式化为显示的数据


exports.validate = validate;

var formatRangeDateTimeValueToDisplayValue = function formatRangeDateTimeValueToDisplayValue() {
  var startDateTime = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var endDateTime = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var dateTime = [startDateTime, endDateTime];
  var dateTimeArr = dateTime.map(function (item) {
    var dateTimeReg = /^[0-9]*\s[0-9]*$/gi; // 如果数据格式正确，进行转换

    if (dateTimeReg.test(item)) {
      var date = "".concat(item.slice(0, 4), ".").concat(item.slice(4, 6), ".").concat(item.slice(6, 8));
      var time = '';
      var timeList = [item.slice(9, 11), item.slice(11, 13), item.slice(13, 15)].filter(function (timeItem) {
        return timeItem !== '';
      });
      timeList.forEach(function (timeItem, index) {
        if (timeItem !== '') {
          time = time + timeItem;

          if (index + 1 !== timeList.length) {
            time = "".concat(time, ":");
          }
        }
      });
      return "".concat(date, " ").concat(time);
    }

    return item;
  });
  return dateTimeArr.join(_data.RANGE_DATE_SEPARATOR);
};

exports.formatRangeDateTimeValueToDisplayValue = formatRangeDateTimeValueToDisplayValue;
var _default = {
  formatDate: formatDate,
  splitValue: splitValue,
  validate: validate,
  formatRangeDateTimeValueToDisplayValue: formatRangeDateTimeValueToDisplayValue,
  transInputDateTimeFormat: transInputDateTimeFormat
};
exports["default"] = _default;