"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.accMul = void 0;

/**
 * 精确乘法计算
 * @param {number} arg1
 * @param {number} arg2
 * @return {number}
 */
var accMul = function accMul() {
  var arg1 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var arg2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var m = 0;
  var s1 = String(arg1);
  var s2 = String(arg2);
  var result = '';

  try {
    m += s1.split('.')[1].length;
  } catch (e) {}

  try {
    m += s2.split('.')[1].length;
  } catch (e) {}

  result = Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / Math.pow(10, m);

  if (isNaN(result)) {
    result = '';
  }

  return result;
};

exports.accMul = accMul;