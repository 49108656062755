"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
var TotalComponent = function TotalComponent(props) {
  var data = props.data,
      _props$locale = props.locale,
      locale = _props$locale === void 0 ? {} : _props$locale;
  return _react["default"].createElement("div", {
    className: "viewer-total"
  }, locale.totalLabel.replace('{value}', data.length));
};

var _default = TotalComponent;
exports["default"] = _default;