"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _pdfobject = require("../../utils/pdfobject");

var _index = require("../../utils/index");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
var ToolComponent = function ToolComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var isLowVersionOfIE = props.isLowVersionOfIE,
      onDeg = props.onDeg,
      scale = props.scale,
      maxScale = props.maxScale,
      onZoomOut = props.onZoomOut,
      onZoomIn = props.onZoomIn,
      info = props.info,
      fixedScale = props.fixedScale,
      _props$locale = props.locale,
      locale = _props$locale === void 0 ? {} : _props$locale;
  var url = (0, _index.getItemUrl)(info);
  var isPDFFile = info && (0, _pdfobject.isPDF)(url);
  return _react["default"].createElement("div", {
    className: "viewer-action-group"
  }, _react["default"].createElement("ul", {
    className: "viewer-action"
  }, // 低版本IE跟PDF不支持
  !isLowVersionOfIE && !isPDFFile ? _react["default"].createElement("li", {
    className: "viewer-action-btn rotate",
    onClick: onDeg
  }, _react["default"].createElement("i", {
    className: "viewer-icon viewer-icon-rotate"
  }), _react["default"].createElement("p", {
    className: "viewer-tip"
  }, locale.rotateLabel)) : null, // 低版本IE跟PDF跟固定比例不支持
  !isLowVersionOfIE && typeof fixedScale === 'undefined' && !isPDFFile ? _react["default"].createElement("li", {
    className: "viewer-action-btn zoomout ".concat(scale > 0 ? '' : 'disabled'),
    onClick: onZoomOut
  }, _react["default"].createElement("i", {
    className: "viewer-icon viewer-icon-zoomout"
  }), _react["default"].createElement("p", {
    className: "viewer-tip"
  }, locale.zoomoutLabel)) : null, // 低版本IE跟PDF跟固定比例不支持
  !isLowVersionOfIE && typeof fixedScale === 'undefined' && !isPDFFile ? _react["default"].createElement("li", {
    className: "viewer-action-btn zoomin ".concat(scale < maxScale ? '' : 'disabled'),
    onClick: onZoomIn
  }, _react["default"].createElement("i", {
    className: "viewer-icon viewer-icon-zoomin"
  }), _react["default"].createElement("p", {
    className: "viewer-tip"
  }, locale.zoominLabel)) : null, _react["default"].createElement("li", {
    className: "viewer-action-btn ".concat(isLowVersionOfIE || isPDFFile ? '' : 'is-text')
  }, _react["default"].createElement("a", {
    href: url,
    target: "_blank"
  }, _react["default"].createElement("span", {
    className: "viewer-txt"
  }, isPDFFile ? locale.lookPDFLabel : locale.lookImageLabel)))));
};

var _default = ToolComponent;
exports["default"] = _default;