"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件对应结构的默认className
 */
var _default = {
  ImageViewerClassName: 'viewer-by-image',
  // ''
  ImageOverlayClassName: 'viewer-overlay',
  // preview-box-mask
  ImageCloseClassName: 'viewer-close',
  // previewclose
  ImageBodyClassName: 'viewer-body-by-image',
  // preview-box-content
  ImagesViewerClassName: 'viewer',
  ImagesViewerCarouselClassName: 'viewer-carousel',
  ImagesViewerIEClassName: 'spaui-viewer-ie',
  ImagesInnerClassName: 'viewer-inner',
  ImagesCloseClassName: 'viewer-close',
  ImagesBodyClassName: 'viewer-body',
  ImagesMaskClassName: 'viewer-mask',
  ImagesDragClassName: 'viewer-dragimage',
  ImagesPreClassName: 'viewer-pre',
  ImagesNextClassName: 'viewer-next',
  ImagesFootClassName: 'viewer-foot',
  ImagesOverlayClassName: 'viewer-overlay'
};
exports["default"] = _default;