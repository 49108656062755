"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
// 关闭按钮组件
var CloseComponent = function CloseComponent(props) {
  var onClose = props.onClose,
      closeClassName = props.closeClassName,
      _props$classNameMap = props.classNameMap,
      classNameMap = _props$classNameMap === void 0 ? {} : _props$classNameMap;
  var className = (0, _classnames["default"])(classNameMap.ImageCloseClassName, closeClassName);
  return _react["default"].createElement("div", {
    onClick: onClose,
    className: className
  }, _react["default"].createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    xmlnsXlink: "http://www.w3.org/1999/xlink",
    width: "24px",
    height: "24px",
    viewBox: "0 0 24 24",
    version: "1.1"
  }, _react["default"].createElement("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd"
  }, _react["default"].createElement("rect", {
    x: "0",
    y: "0",
    width: "20",
    height: "20"
  }), _react["default"].createElement("g", {
    transform: "translate(-5.000000, -5.000000)",
    fill: "CurrentColor"
  }, _react["default"].createElement("polygon", {
    /* eslint-disable-next-line */
    transform: "translate(17.000000, 17.000000) rotate(45.000000) translate(-17.000000, -17.000000) ",
    points: "18 16 29 16 29 18 18 18 18 29 16 29 16 18 5 18 5 16 16 16 16 5 18 5"
  })))));
};

var _default = CloseComponent;
exports["default"] = _default;