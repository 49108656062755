"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

/**
 * @fileoverview 兼容参数
 */
var getDate = function getDate(props) {
  var date = props.date,
      value = props.value;
  var result = '';

  if (typeof value !== 'undefined') {
    result = value;
  } else {
    result = date;
  }

  return result;
};

var getRangeDate = function getRangeDate(props) {
  var startDate = props.startDate,
      endDate = props.endDate,
      value = props.value;
  var start = startDate;
  var end = endDate;

  if (value && _typeof(value) === 'object') {
    if (typeof value.start !== 'undefined') {
      start = value.start;
    }

    if (typeof value.end !== 'undefined') {
      end = value.end;
    }
  }

  return {
    startDate: start,
    endDate: end
  };
}; // 兼容props，因为有些名称被废弃了但是要兼容


var _default = function _default() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  var getStringDate = function getStringDate(item) {
    var numberReg = /^\d*$/gi;
    var result = item;
    var tempDate = null;
    var dateData = {};
    var timeFormatLength = 0;

    if (numberReg.test(item) && (item || typeof item === 'number')) {
      if (item === 0) {
        result = '';
      } else {
        tempDate = new Date(parseInt(item, 10) || 0);
        dateData = {
          year: tempDate.getFullYear(),
          month: tempDate.getMonth(),
          date: tempDate.getDate()
        };

        if (timePicker && format) {
          timeFormatLength = format.split(':').length;

          if (timeFormatLength > 2) {
            dateData.second = tempDate.getSeconds();
          }

          if (timeFormatLength > 1) {
            dateData.minute = tempDate.getMinutes();
          }

          if (timeFormatLength > 0) {
            dateData.hour = tempDate.getHours();
          }
        }

        result = (0, _spauiUtils.joinDate)(dateData, format);
      }
    }

    return result;
  };

  var clearable = props.clearable,
      clear = props.clear,
      shortcutable = props.shortcutable,
      useShortcut = props.useShortcut,
      TimePickerAble = props.TimePickerAble,
      prospTimePicker = props.timePicker,
      TimePickerProps = props.TimePickerProps,
      timePickerProps = props.timePickerProps,
      propsShortestRange = props.shortestRange,
      propsLongestRange = props.longestRange,
      minTimeRange = props.minTimeRange,
      maxTimeRange = props.maxTimeRange,
      _props$useTimeStamp = props.useTimeStamp,
      propsUseTimeStamp = _props$useTimeStamp === void 0 ? false : _props$useTimeStamp,
      format = props.format;
  var useTimeStamp = propsUseTimeStamp;
  var timePicker = typeof TimePickerAble !== 'undefined' ? TimePickerAble : prospTimePicker;
  var date = getDate(props);

  var _getRangeDate = getRangeDate(props),
      startDate = _getRangeDate.startDate,
      endDate = _getRangeDate.endDate;

  var shortestRange = propsShortestRange;
  var longestRange = propsLongestRange;

  if (typeof minTimeRange !== 'undefined') {
    // 向下取天数
    shortestRange = Math.floor(minTimeRange / (24 * 60 * 60 * 1000));
  }

  if (typeof maxTimeRange !== 'undefined') {
    // 向上取天数
    longestRange = Math.ceil(maxTimeRange / (24 * 60 * 60 * 1000));
  } // 如果开启了时间戳，需要发现日期格式是时间戳的要转化一下


  if (useTimeStamp) {
    date = getStringDate(date, format);
    startDate = getStringDate(startDate, format);
    endDate = getStringDate(endDate, format);
  }

  return _objectSpread({}, props, {
    date: date,
    startDate: startDate,
    endDate: endDate,
    useTimeStamp: useTimeStamp,
    clear: typeof clearable !== 'undefined' ? clearable : clear,
    useShortcut: typeof shortcutable !== 'undefined' ? shortcutable : useShortcut,
    timePicker: timePicker,
    timePickerProps: typeof TimePickerProps !== 'undefined' ? TimePickerProps : timePickerProps,
    shortestRange: shortestRange,
    longestRange: longestRange
  });
};

exports["default"] = _default;