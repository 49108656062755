"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var AddComponent = function AddComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var onKeyPress = props.onKeyPress,
      onKeyUp = props.onKeyUp,
      onKeyDown = props.onKeyDown,
      onMouseOver = props.onMouseOver,
      onMouseOut = props.onMouseOut,
      onMouseEnter = props.onMouseEnter,
      onMouseLeave = props.onMouseLeave,
      onClick = props.onClick;
  var eventMap = {
    onKeyPress: onKeyPress,
    onKeyUp: onKeyUp,
    onKeyDown: onKeyDown,
    onMouseOver: onMouseOver,
    onMouseOut: onMouseOut,
    onMouseEnter: onMouseEnter,
    onMouseLeave: onMouseLeave,
    onClick: onClick
  };
  return _react["default"].createElement("li", _extends({
    className: "img-li img-li-add"
  }, eventMap), _react["default"].createElement("span", null, "+"));
};

var _default = AddComponent;
exports["default"] = _default;