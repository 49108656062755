"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.asyncPool = void 0;

var asyncPool = function asyncPool(poolLimit, array, iteratorFn) {
  var i = 0;
  var ret = [];
  var executing = [];

  var enqueue = function enqueue() {
    // 当已遍历完成，则结束。
    if (i === array.length) {
      return Promise.resolve();
    } // 每调一次enqueue，初始化一个promise


    var item = array[i];
    i += 1;
    var p = Promise.resolve().then(function () {
      return iteratorFn(item, array);
    }); // 放入promises数组

    ret.push(p); // promise执行完毕，从executing数组中删除

    var e = p.then(function () {
      return executing.splice(executing.indexOf(e), 1);
    }); // 插入executing数字，表示正在执行的promise

    executing.push(e); // 每当executing数组中promise数量等于poolLimit时，就阻塞插入新的promise，等待其中有一个完成后则继续遍历

    var r = Promise.resolve();

    if (executing.length >= poolLimit) {
      r = Promise.race(executing);
    } // 递归，直到遍历完array


    return r.then(function () {
      return enqueue();
    });
  };

  return enqueue().then(function () {
    return Promise.all(ret);
  });
};

exports.asyncPool = asyncPool;