"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
var _default = function _default() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var pageSizeList = props.pageSizeList,
      pageSizeChangeData = props.pageSizeChangeData,
      active = props.active,
      value = props.value,
      _props$data = props.data,
      data = _props$data === void 0 ? {} : _props$data,
      pagesize = props.pagesize,
      pageSize = props.pageSize,
      totalSize = props.totalSize,
      totalnum = props.totalnum;
  var dataPageSize = data.pageSize,
      dataPagesize = data.pagesize,
      dataTotalSize = data.totalSize,
      dataTotalNum = data.totalnum,
      totalpage = data.totalpage;

  var _pageSize = parseInt(dataPageSize || dataPagesize || pagesize || pageSize, 10) || 0;

  var _totalSize = parseInt(dataTotalSize || dataTotalNum || totalSize || totalnum, 10) || 0;

  var hide = typeof props.isHide !== 'undefined' ? props.isHide : props.hide;
  return _objectSpread({}, props, {
    pageSizeList: pageSizeChangeData || pageSizeList || [],
    value: active || value || 1,
    pageSize: _pageSize,
    totalSize: _totalSize,
    totalPage: Math.ceil(_totalSize / _pageSize) || totalpage || 0,
    hide: hide
  });
};

exports["default"] = _default;