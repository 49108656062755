"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultProps = exports.propTypes = exports.config = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件参数配置
 */

/**
 * DropMenuTrigger 接口参数
 * @alias DropMenuTriggerProps
 * @description 向下弹出列表组件的触发器。
 * @property {titleline} 默认支持参数
 * @property {blankline} className;style;id;data-*;accessKey;dir;draggable;tabIndex;title;htmlRef;
 * @property {titleline} 基础属性
 * @property {string} [trigger='click'] 打开的方式，click or hover
 * @property {bool} [open=false] 是否打开
 * @property {bool} [closeAfterSelect=false] 选中某选项后是否需要自动关闭菜单
 * @property {func} [onOpen] 打开回调 onOpen = (event, open)
 * @property {element} [dropMenu=null] 下拉菜单内容
 * @property {number} [dropMenuWidth] dropMenu的宽度，可用于alignment
 * @property {string} [dropMenuWrapClassName=''] 放置dropMenu的父级结构className
 * @property {number} [showDelayTime=0] trigger=hover显示延迟时间(单位毫秒)
 * @property {number} [hideDelayTime=100] trigger=hover消失延迟时间（单位毫秒）
 * @property {bool} [documentClickAutoHide=true] 点击document自动关闭
 * @property {string} [alignment='left'] 对齐*可选值: left, right, auto*
 */
var config = {
  trigger: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'click'
  },
  open: {
    type: _spauiUtils.PropTypes.bool
  },
  onOpen: {
    type: _spauiUtils.PropTypes.func
  },
  closeAfterSelect: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  dropMenu: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: null
  },
  dropMenuWrapClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  showDelayTime: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.number, _spauiUtils.PropTypes.string]),
    defaultValue: 0
  },
  hideDelayTime: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.number, _spauiUtils.PropTypes.string]),
    defaultValue: 100
  },
  documentClickAutoHide: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  alignment: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'left'
  },
  dropMenuWidth: {
    type: _spauiUtils.PropTypes.any,
    defaultValue: 0
  }
}; // 转化成PureComponent组件的配置

exports.config = config;
var configData = (0, _spauiUtils.formatProps)(config); // 导出

var propTypes = configData.propTypes;
exports.propTypes = propTypes;
var defaultProps = configData.defaultProps;
exports.defaultProps = defaultProps;
var _default = {
  config: config,
  propTypes: propTypes,
  defaultProps: defaultProps
};
exports["default"] = _default;