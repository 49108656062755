"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.DropMenuTrigger = exports.DropMenu = void 0;

var _drop_menu = _interopRequireDefault(require("./drop_menu"));

var _drop_menu_trigger = _interopRequireDefault(require("./drop_menu_trigger"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
// 兼容旧模式，导出一个唯一的DropMenu
var defaultData = _drop_menu["default"];
var DropMenu = _drop_menu["default"];
exports.DropMenu = DropMenu;
var DropMenuTrigger = _drop_menu_trigger["default"]; // 存放到default数据中

exports.DropMenuTrigger = DropMenuTrigger;
defaultData.DropMenu = DropMenu;
defaultData.DropMenuTrigger = DropMenuTrigger; // 调试信息
// if (process.env.NODE_ENV !== 'production') {
//     const {whyDidYouUpdate} = require('why-did-you-update');
//     whyDidYouUpdate(React);
// }

var _default = defaultData;
exports["default"] = _default;