"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _spauiUtils = require("@tencent/spaui-utils");

var _classnames = _interopRequireDefault(require("classnames"));

var _spauiPortal = _interopRequireDefault(require("@tencent/spaui-portal"));

var _submenu_component = _interopRequireDefault(require("./submenu_component"));

var _index = require("../utils/index");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var DomComponent = function DomComponent() {
  var _classNames;

  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _props$data = props.data,
      data = _props$data === void 0 ? [] : _props$data,
      toggleTitle = props.toggleTitle,
      _props$toggleProps = props.toggleProps,
      toggleProps = _props$toggleProps === void 0 ? {} : _props$toggleProps,
      ToggleComponent = props.ToggleComponent,
      ItemComponent = props.ItemComponent,
      IconComponent = props.IconComponent,
      ArrowComponent = props.ArrowComponent,
      toggle = props.toggle,
      type = props.type,
      onTypeChange = props.onTypeChange,
      isActive = props.isActive,
      onChange = props.onChange,
      value = props.value,
      onMenuItemMouseEnter = props.onMenuItemMouseEnter,
      onMenuItemMouseLeave = props.onMenuItemMouseLeave,
      onMenuItemTransitionEnd = props.onMenuItemTransitionEnd,
      groupRef = props.groupRef,
      onMenuGroupTransitionEnd = props.onMenuGroupTransitionEnd,
      id = props.id,
      fixed = props.fixed,
      version = props.version,
      devProps = props.devProps,
      _props$customProps = props.customProps,
      customProps = _props$customProps === void 0 ? {} : _props$customProps,
      nodeIsSelected = props.nodeIsSelected,
      LinkComponent = props.LinkComponent,
      FooterComponent = props.FooterComponent,
      HeaderComponent = props.HeaderComponent,
      _props$groupClassName = props.groupClassName,
      groupClassName = _props$groupClassName === void 0 ? '' : _props$groupClassName,
      _props$showPortalMenu = props.showPortalMenuWhenNoSubmenu,
      showPortalMenuWhenNoSubmenu = _props$showPortalMenu === void 0 ? true : _props$showPortalMenu,
      show = props.show,
      width = props.width,
      style = props.style,
      _props$classNameMap = props.classNameMap,
      classNameMap = _props$classNameMap === void 0 ? {} : _props$classNameMap,
      thirdLevelAsPop = props.thirdLevelAsPop;
  var toggleDom = null;
  var needIcon = false; // 是否需要icon

  var headerDom = null;
  var footerDom = null;
  var haveShortLabel = false; // 遍历看下

  data.map(function (item) {
    if (item) {
      if (!needIcon && (item.iconClassName || item.icon)) {
        needIcon = true;
      }

      if (item.shortLabel) {
        haveShortLabel = true;
      }
    }

    return item;
  }); // 数据为空就不需要渲染了

  if (typeof show === 'undefined' && data.length < 1) {
    return null;
  }

  if (typeof show !== 'undefined' && !show) {
    return null;
  } // 头部结构


  if (typeof HeaderComponent !== 'undefined') {
    if ((0, _spauiUtils.isReactNode)(HeaderComponent)) {
      headerDom = HeaderComponent;
    } else {
      headerDom = _react["default"].createElement(HeaderComponent, _extends({}, customProps, {
        data: data,
        toggleTitle: toggleTitle,
        toggle: toggle,
        type: type,
        onTypeChange: onTypeChange,
        value: value,
        fixed: fixed
      }));
    }
  } // 尾部结构


  if (typeof FooterComponent !== 'undefined') {
    if ((0, _spauiUtils.isReactNode)(FooterComponent)) {
      footerDom = FooterComponent;
    } else {
      footerDom = _react["default"].createElement(FooterComponent, _extends({}, customProps, {
        data: data,
        toggleTitle: toggleTitle,
        toggle: toggle,
        type: type,
        onTypeChange: onTypeChange,
        value: value,
        fixed: fixed
      }));
    }
  } // 切换按钮的结构，支持jsx


  if (typeof ToggleComponent !== 'undefined' && toggle) {
    if ((0, _spauiUtils.isReactNode)(ToggleComponent)) {
      toggleDom = ToggleComponent;
    } else {
      toggleDom = _react["default"].createElement(ToggleComponent, _extends({
        classNameMap: classNameMap,
        title: toggleTitle,
        type: type,
        onChange: onTypeChange
      }, toggleProps));
    }
  }

  var newWidth = (0, _spauiUtils.pxVal)(width);
  var cssVal = newWidth ? _defineProperty({}, '--menu-width', newWidth) : {};
  return _react["default"].createElement("div", _extends({}, (0, _spauiUtils.globalProps)(props, {
    className: (0, _classnames["default"])(classNameMap.Menu, (_classNames = {}, _defineProperty(_classNames, classNameMap.MenuMini, type === 'mini'), _defineProperty(_classNames, classNameMap.MenuFixed, fixed), _defineProperty(_classNames, classNameMap.MenuWithShortLabel, haveShortLabel), _defineProperty(_classNames, classNameMap.CustomWidth, width), _classNames))
  }, devProps[0], devProps[1]), {
    style: _objectSpread({}, cssVal, {}, style)
  }), toggleDom, headerDom, _react["default"].createElement("ul", {
    className: (0, _classnames["default"])(classNameMap.MenuGroup, groupClassName, _defineProperty({}, classNameMap.IsMini, type === 'mini' && (version === 1 || version === '1'))),
    ref: groupRef,
    onTransitionEnd: onMenuGroupTransitionEnd
  }, data.map(function () {
    var _classNames5;

    var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var index = arguments.length > 1 ? arguments[1] : undefined;
    var label = item.label,
        shortLabel = item.shortLabel,
        _item$iconClassName = item.iconClassName,
        iconClassName = _item$iconClassName === void 0 ? '' : _item$iconClassName,
        _item$key = item.key,
        key = _item$key === void 0 ? '' : _item$key,
        miniHref = item.miniHref,
        _item$data = item.data,
        itemData = _item$data === void 0 ? [] : _item$data; // 是否激活中

    var itemActive = isActive(item);
    var dataAttribute = {};
    var _item$href = item.href,
        href = _item$href === void 0 ? '' : _item$href;
    var subMenuComponentDom = null;
    var attrKey = '';
    var subMenuProps = {}; // 如果指定了miniHref，那就在mini的时候用miniHref

    if (type === 'mini' && typeof miniHref !== 'undefined') {
      href = miniHref;
    } // 如果href为空且miniHref为undefined且type===mini，href用itemData里面第一个有href的代替


    if (!href && typeof miniHref === 'undefined' && type === 'mini' && itemData.length > 0) {
      itemData.map(function (tempData) {
        if (!href && tempData && tempData.href) {
          href = tempData.href;
        }

        return tempData;
      });
    } // 获取自定义data参数


    for (attrKey in item) {
      if (attrKey.indexOf('data-') === 0) {
        dataAttribute[attrKey] = item[attrKey];
      }
    } // 第二级菜单的属性


    subMenuProps = _objectSpread({}, customProps, {
      menuComponentProps: props,
      customProps: customProps,
      itemData: itemData,
      isActive: isActive,
      value: value,
      itemKey: key,
      itemHref: href,
      type: type,
      onMouseEnter: onMenuItemMouseEnter,
      onMouseLeave: onMenuItemMouseLeave,
      onTransitionEnd: onMenuItemTransitionEnd,
      ItemComponent: ItemComponent,
      LinkComponent: LinkComponent,
      IconComponent: IconComponent,
      ArrowComponent: ArrowComponent,
      onChange: onChange,
      nodeIsSelected: nodeIsSelected,
      needIcon: needIcon,
      isLast: index === data.length - 1,
      classNameMap: classNameMap,
      thirdLevelAsPop: thirdLevelAsPop
    }); // 有二级节点

    if (itemData.length > 0) {
      if (type !== 'mini') {
        subMenuComponentDom = _react["default"].createElement(_submenu_component["default"], subMenuProps);
      } else {
        subMenuComponentDom = _react["default"].createElement(_spauiPortal["default"], null, _react["default"].createElement(_submenu_component["default"], _extends({}, subMenuProps, {
          className: (0, _classnames["default"])(classNameMap.PopMenu, _defineProperty({}, classNameMap.PopMenuFixed, fixed)) // 用于隐藏与显示
          ,
          id: "".concat(id, "_portal_").concat(index),
          style: {
            display: 'none'
          },
          inPortal: true
        })));
      }
    } else {
      // 没有二级节点，但是为mini的时候要用浮层展示第一层的内容
      if (type === 'mini' && showPortalMenuWhenNoSubmenu) {
        subMenuComponentDom = _react["default"].createElement(_spauiPortal["default"], null, _react["default"].createElement(_submenu_component["default"], _extends({}, subMenuProps, {
          className: (0, _classnames["default"])(classNameMap.PopMenu, classNameMap.PopMenuTooltip, _defineProperty({}, classNameMap.PopMenuFixed, fixed)) // 用于隐藏与显示
          ,
          id: "".concat(id, "_portal_").concat(index),
          style: {
            display: 'none'
          } // 实际上是第一层节点的内容
          ,
          itemData: [{
            label: label,
            shortLabel: shortLabel,
            href: href
          }],
          inPortal: true
        })));
      }
    } // 第一层结构


    return _react["default"].createElement("li", {
      className: (0, _classnames["default"])(classNameMap.MenuItem, (_classNames5 = {
        'js-has-menu': itemData.length > 0
      }, _defineProperty(_classNames5, classNameMap.HasSubMenu, itemData.length > 0), _defineProperty(_classNames5, classNameMap.MenuOpen, itemActive && itemData.length > 0), _defineProperty(_classNames5, classNameMap.MenuSelected, nodeIsSelected(value, item, type)), _defineProperty(_classNames5, classNameMap.MenuChildSelected, (0, _index.childIsSelect)(value, item, nodeIsSelected)), _classNames5), item.className || ''),
      key: index,
      onMouseEnter: onMenuItemMouseEnter,
      onMouseLeave: onMenuItemMouseLeave,
      onTransitionEnd: onMenuItemTransitionEnd // data-index 用于判断当前是第几个
      ,
      "data-index": index // 标记是最高一层
      ,
      "data-toplevel": "1" // data-open用于标记是否打开
      ,
      "data-open": itemActive && itemData.length > 0 ? 1 : 0
    }, _react["default"].createElement(ItemComponent, _extends({}, customProps, {
      menuComponentProps: props,
      selected: nodeIsSelected(value, item, type),
      LinkComponent: LinkComponent,
      type: type,
      itemKey: key,
      itemHref: href,
      value: value,
      className: classNameMap.MenuTitle,
      href: href,
      onClick: function onClick(event, noChangeValue) {
        onChange(event, item, 0, itemActive, noChangeValue);
      },
      iconClassName: (0, _classnames["default"])(_defineProperty({
        icon: version === 1 || version === '1'
      }, classNameMap.MenuIcon, needIcon), iconClassName),
      label: label,
      shortLabel: shortLabel,
      data: itemData,
      dataAttribute: dataAttribute,
      IconComponent: IconComponent,
      ArrowComponent: ArrowComponent,
      nodeData: item,
      inPortal: false,
      classNameMap: classNameMap
    })), subMenuComponentDom);
  })), footerDom);
};

var _default = DomComponent;
exports["default"] = _default;