"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
var ItemComponent = function ItemComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var value = props.value,
      disabled = props.disabled,
      checked = props.checked,
      onChange = props.onChange,
      children = props.children,
      style = props.style,
      className = props.className;
  return _react["default"].createElement("label", {
    className: "btn ".concat(className),
    key: value,
    style: style
  }, _react["default"].createElement("div", {
    className: "layout"
  }, _react["default"].createElement("input", {
    type: "checkbox",
    "data-available": "true",
    value: value,
    disabled: disabled,
    checked: checked,
    onChange: onChange
  }), children));
};

var _default = ItemComponent;
exports["default"] = _default;