"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.isPDF = exports.showPDFObject = exports.addPDFObject = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

var loadJs = _spauiUtils.utils.loadJs; // 加载pdf依赖模块

var addPDFObject = function addPDFObject(onload) {
  var url = '//public.gdtimg.com/qzone/biz/gdt/lib/pdfobject/2.0/pdfobject.js';

  if ((typeof window === "undefined" ? "undefined" : _typeof(window)) === 'object' && typeof window.PDFObject === 'undefined') {
    if (location.protocol === 'file:') {
      url = "http:".concat(url);
    }

    loadJs({
      url: url,
      onload: onload
    });
  } else {
    onload();
  }
}; // 展示pdf


exports.addPDFObject = addPDFObject;

var showPDFObject = function showPDFObject(self) {
  var url = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var id = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  clearTimeout(self.timeout);
  self.timeout = setTimeout(function () {
    var $id = null;

    if (_typeof(window.PDFObject) === 'object' && url && id) {
      $id = document.getElementById(id);

      if ($id) {
        if (window.PDFObject.supportsPDFs) {
          window.PDFObject.embed(url, "#".concat(id));
        } else {
          $id.innerHTML = "<iframe src=\"".concat(url, "\" width=\"100%\" height=\"100%\">\u4E0D\u652F\u6301PDF\u9884\u89C8</iframe>");
        }
      }
    }
  }, 10);
};

exports.showPDFObject = showPDFObject;

var isPDF = function isPDF() {
  var url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var result = false;
  result = url && url.indexOf('.pdf') > -1;
  return result;
};

exports.isPDF = isPDF;
var _default = {
  addPDFObject: addPDFObject,
  showPDFObject: showPDFObject,
  isPDF: isPDF
};
exports["default"] = _default;