"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
var StatusComponent = function StatusComponent() {
  return _react["default"].createElement("i", {
    className: "spaui-task-status"
  }, _react["default"].createElement("i", {
    className: "spaui-icon-task-done"
  }), _react["default"].createElement("i", {
    className: "spaui-icon-task-doing"
  }));
};

var _default = StatusComponent;
exports["default"] = _default;