"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _default = function _default() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var isOptionalRow = props.isOptionalRow,
      propsWholeRowIsOptional = props.wholeRowIsOptional,
      propsCssFixedTheadAble = props.cssFixedTheadAble,
      cssFixedThead = props.cssFixedThead;
  var wholeRowIsOptional = false;
  var cssFixedTheadAble = false;

  if (!(0, _spauiUtils.isUndefined)(isOptionalRow)) {
    wholeRowIsOptional = isOptionalRow;
  } else if (!(0, _spauiUtils.isUndefined)(propsWholeRowIsOptional)) {
    wholeRowIsOptional = propsWholeRowIsOptional;
  }

  if (!(0, _spauiUtils.isUndefined)(cssFixedThead)) {
    cssFixedTheadAble = cssFixedThead;
  } else if (!(0, _spauiUtils.isUndefined)(propsCssFixedTheadAble)) {
    cssFixedTheadAble = propsCssFixedTheadAble;
  }

  return _objectSpread({}, props, {
    wholeRowIsOptional: wholeRowIsOptional,
    cssFixedTheadAble: cssFixedTheadAble
  });
};

exports["default"] = _default;