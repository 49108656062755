"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _icon_component = _interopRequireDefault(require("./icon_component"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/**
 * 默认加载中组件
 * @return {object}
 */
var LoadingComponent = function LoadingComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _props$classNameMap = props.classNameMap,
      classNameMap = _props$classNameMap === void 0 ? {} : _props$classNameMap,
      _props$style = props.style,
      style = _props$style === void 0 ? {} : _props$style;
  return _react["default"].createElement("div", {
    className: classNameMap.TreeListClassName
  }, _react["default"].createElement("div", {
    className: "".concat(classNameMap.TreeListInnerClassName, " clearfix")
  }, _react["default"].createElement("div", {
    className: classNameMap.TreeLoadingClassName,
    style: _objectSpread({
      height: 100,
      position: 'relative'
    }, style || {})
  }, _react["default"].createElement(_icon_component["default"], {
    type: "loading-blue"
  }))));
};

var _default = LoadingComponent;
exports["default"] = _default;