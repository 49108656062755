"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

/**
 *
 * @param {*} data 一个选项的数据
 * @param {Object} config 配置
 * @param {String} config.value 搜索词
 * @param {Boolean} config.html 是否支持html片段
 * @returns {*} 替换文案后的选项数据
 */
var _default = function _default(data) {
  var config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var value = config.value,
      html = config.html,
      _config$fieldNames = config.fieldNames,
      fieldNames = _config$fieldNames === void 0 ? {} : _config$fieldNames;
  var labelFieldName = fieldNames.labelFieldName;
  var result = data;
  var label = _typeof(data) === 'object' ? data[labelFieldName] || '' : data;
  var labelList = [];
  var jsxLabelList = []; // 只有字符串，number才能够处理，jsx的没法处理

  if ((typeof label === 'string' || typeof label === 'number') && value) {
    label = "".concat(label); // 只有支持html片段才可以替换，因为React结构的没法替换

    if (html && label.indexOf(value) > -1) {
      label = label.replace(new RegExp(value, 'gi'), function (replaceValue) {
        return "<em class=\"emphasize\">".concat(replaceValue, "</em>");
      });

      if (_typeof(data) === 'object') {
        result = _objectSpread({}, data);
        result[labelFieldName] = label;
      } else {
        result = label;
      }
    } else if (label.indexOf(value) > -1) {
      labelList = label.split(value);
      labelList.map(function (item, index) {
        if (typeof item !== 'undefined') {
          jsxLabelList.push(item); // 最后一个不加

          if (index < labelList.length - 1) {
            jsxLabelList.push(_react["default"].createElement("em", {
              key: "".concat(index, "_").concat(value),
              className: "emphasize"
            }, value));
          }
        }

        return item;
      });
      label = jsxLabelList;

      if (_typeof(data) === 'object') {
        result = _objectSpread({}, data);
        result[labelFieldName] = label;
      } else {
        result = label;
      }
    }
  }

  return result;
};

exports["default"] = _default;