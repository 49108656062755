"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var NoValueComponent = function NoValueComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var className = (0, _classnames["default"])('spaui-upmedia-inner', props.className);
  var title = props.title,
      _props$description = props.description,
      description = _props$description === void 0 ? null : _props$description,
      icon = props.icon;
  var divProps = {};
  var key = '';

  for (key in props) {
    if (key !== 'useFileAPI' && key !== 'description' && key !== 'title' && key !== 'icon') {
      divProps[key] = props[key];
    }
  }

  return _react["default"].createElement("div", _extends({}, divProps, {
    className: className
  }), _react["default"].createElement("div", {
    className: "spaui-upmedia-uploader"
  }, icon, title ? _react["default"].createElement("span", {
    className: "spaui-upmedia-title"
  }, title) : null, description ? _react["default"].createElement("p", {
    className: "spaui-upmedia-desc",
    dangerouslySetInnerHTML: {
      __html: description
    }
  }) : description));
};

var _default = NoValueComponent;
exports["default"] = _default;