"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _index = require("../config/index");

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
var getRoutineList = function getRoutineList() {
  var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  // 一天的半小时时间数组，0050表示30分钟，0100表示1小时，0750表示7小时30分，以此类推
  var halfHoursArr = new Array(48).join(',').split(',').map(function (item, index) {
    return 50 * (index + 1);
  });

  var setRoutineList = function setRoutineList(dayIndex) {
    halfHoursArr.forEach(function (item) {
      routineList[item + (dayIndex + 1) * 10000] = _index.SELECT_STATUS.UNSELECTED;
    });
  };

  var routineList = {};
  var i = 0;
  var routineKeys = []; // 一个星期的半小时时间对象，星期1至7为10000至70000，每个加上上述48个半小时的数字，构成含7*48 = 336个key的对象

  for (; i < 7; i++) {
    setRoutineList(i);
  }

  routineKeys = Object.keys(routineList); // 将传入的二进制字符串转为选中态

  if (value) {
    var digitArr = value.split('');

    var selectedStr = _index.SELECT_STATUS.SELECTED.toString();

    digitArr.forEach(function (item, key) {
      if (item === selectedStr && routineKeys[key]) {
        routineList[routineKeys[key]] = _index.SELECT_STATUS.SELECTED;
      }
    });
  }

  return {
    routineKeys: routineKeys,
    routineList: routineList
  };
};

var _default = getRoutineList;
exports["default"] = _default;