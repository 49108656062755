"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

var isAddJSing = false;
var onLoadStack = [];

var _default = function _default(onload) {
  var addJSOnLoad = function addJSOnLoad() {
    onLoadStack.map(function (item) {
      if ((0, _spauiUtils.isFunction)(item)) {
        item();
      }
    });

    if ((0, _spauiUtils.isFunction)(onload)) {
      onload();
    }

    onLoadStack = [];
    isAddJSing = false;
  };

  var url = '//public.gdtimg.com/qzone/biz/gdt/lib/echarts-4.2.1/echarts.min.js?max_age=31536000';

  if ((typeof window === "undefined" ? "undefined" : _typeof(window)) === 'object' && typeof window.echarts === 'undefined') {
    if (isAddJSing) {
      onLoadStack.push(onload);
    } else {
      if (location.protocol === 'file:') {
        url = "http:".concat(url);
      }

      isAddJSing = true;
      (0, _spauiUtils.loadJs)({
        url: url,
        onload: addJSOnLoad
      });
    }
  } else {
    addJSOnLoad();
  }
};

exports["default"] = _default;