"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
var CellComponent = function CellComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var onClick = props.onClick,
      onMouseEnter = props.onMouseEnter,
      className = props.className,
      labelClassName = props.labelClassName,
      date = props.date;
  return _react["default"].createElement("li", {
    className: className
  }, _react["default"].createElement("a", {
    className: labelClassName,
    onClick: onClick,
    onMouseEnter: onMouseEnter
  }, date));
};

var _default = CellComponent;
exports["default"] = _default;