"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultProps = exports.propTypes = exports.config = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

var _index = require("./utils/index");

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * TimePicker 接口参数
 * @alias TimePickerProps
 * @description 用于选择或者输入时间。
 * @property {titleline} 默认支持参数
 * @property {blankline} className;style;id;data-*;accessKey;dir;draggable;tabIndex;title;htmlRef;
 * @property {titleline} 基础属性
 * @property {string} [size='default'] 尺寸 *可选值: default(默认尺寸), small(小尺寸)*
 * @property {string} value='' 选择的时间
 * @property {func} onChange 选择回调<br />onChange = function(event, value)
 * @property {string} [beginTime=''] 开始时间（格式：11:00:00，小于该时间的会置灰禁选）
 * @property {string} [endTime=''] 结束时间（格式：20:10:01，大于该时间的会置灰禁选）
 * @property {string} [placeholder=''] 文案提示
 * @property {bool} [write=true] 是否可写
 * @property {bool} [disabled=false] 是否禁用
 * @property {bool} [open] 是否展示
 * @property {func} [onOpen] 是否展示回调<br />onOpen = function(event, open)
 * @property {func} [onClick]  点击<br />onClick = function(event)
 * @property {func} [onClickItem] 点击选项<br />onClickItem = function(event, type, value)
 * @property {bool} [clear=false] 是否可清空
 * @property {func} [onClear] 清空回调*onClear=(event)*
 * @property {titleline} 自定义选择数据属性
 * @property {bool} [config={}] 配置选择的数据，默认config = {hour: true, minute: true, second: false}
 * @property {array} [hourList] 可选小时列表
 * @property {array} [minuteList] 可选分钟列表
 * @property {array} [secondList] 可选秒列表
 * @property {array} [timeList] 自定义可选择时间列表
 * @property {string} [transitionName='slide-up'] 自定义动效名称 遵循 od-animate 规范
 * @property {titleline} 结构属性
 * @property {object} [formGroupProps] 输入表单组结构属性
 */
var config = {
  size: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'default'
  },
  value: {
    type: _spauiUtils.PropTypes.string.isRequired,
    defaultValue: ''
  },
  showPlaceholder: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  placeholder: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: '请选择'
  },
  write: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  disabled: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  config: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {
      hour: true,
      minute: true,
      second: false
    }
  },
  open: {
    type: _spauiUtils.PropTypes.bool
  },
  hourList: {
    type: _spauiUtils.PropTypes.array,
    defaultValue: (0, _index.genList)(24)
  },
  minuteList: {
    type: _spauiUtils.PropTypes.array,
    defaultValue: (0, _index.genList)(60)
  },
  secondList: {
    type: _spauiUtils.PropTypes.array,
    defaultValue: (0, _index.genList)(60)
  },
  timeList: {
    type: _spauiUtils.PropTypes.array
  },
  onOpen: {
    type: _spauiUtils.PropTypes.func
  },
  onClick: {
    type: _spauiUtils.PropTypes.func
  },
  onClickItem: {
    type: _spauiUtils.PropTypes.func
  },
  onChange: {
    type: _spauiUtils.PropTypes.func
  },
  clear: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  onClear: {
    type: _spauiUtils.PropTypes.func
  },
  transitionName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'slide-up'
  },
  formGroupProps: {
    type: _spauiUtils.PropTypes.object
  }
}; // 转化成PureComponent组件的配置

exports.config = config;
var configData = (0, _spauiUtils.formatProps)(config); // 导出

var propTypes = configData.propTypes;
exports.propTypes = propTypes;
var defaultProps = configData.defaultProps;
exports.defaultProps = defaultProps;
var _default = {
  config: config,
  propTypes: propTypes,
  defaultProps: defaultProps
};
exports["default"] = _default;