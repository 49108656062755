"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
var compactProps = function compactProps() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var clear = typeof props.clearable !== 'undefined' ? props.clearable : props.clear;
  var half = typeof props.halfable !== 'undefined' ? props.halfable : props.half;
  var readOnly = typeof props.readonly !== 'undefined' ? props.readonly : props.readOnly;
  var onMouseOver = typeof props.onMouseOver !== 'undefined' ? props.onMouseOver : props.onMouseOver;
  return {
    clear: clear,
    half: half,
    readOnly: readOnly,
    onMouseOver: onMouseOver
  };
};

var _default = compactProps;
exports["default"] = _default;