"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.OverlayTrigger = exports.Popover = exports.PopOver = void 0;

var _popover = _interopRequireDefault(require("./popover"));

var _overlay_trigger = _interopRequireDefault(require("./overlay_trigger"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
var PopOver = _popover["default"];
exports.PopOver = PopOver;
var Popover = _popover["default"];
exports.Popover = Popover;
var OverlayTrigger = _overlay_trigger["default"]; // 调试信息
// if (process.env.NODE_ENV !== 'production') {
//     const {whyDidYouUpdate} = require('why-did-you-update');
//     whyDidYouUpdate(React);
// }

exports.OverlayTrigger = OverlayTrigger;
var _default = {
  PopOver: PopOver,
  Popover: Popover,
  OverlayTrigger: OverlayTrigger
};
exports["default"] = _default;