"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2020, a Tencent company. All rights reserved.
 */
var LinkComponent = function LinkComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _props$href = props.href,
      href = _props$href === void 0 ? '' : _props$href,
      _props$children = props.children,
      children = _props$children === void 0 ? null : _props$children,
      target = props.target,
      className = props.className,
      _props$unLink = props.unLink,
      unLink = _props$unLink === void 0 ? false : _props$unLink,
      disabled = props.disabled,
      onClick = props.onClick;

  if (href && !unLink) {
    return _react["default"].createElement("a", {
      href: href,
      target: target,
      className: className,
      disabled: disabled,
      onClick: onClick
    }, children);
  }

  return _react["default"].createElement("span", {
    className: className,
    onClick: onClick
  }, children);
};

var _default = LinkComponent;
exports["default"] = _default;