"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _spauiLoading = _interopRequireDefault(require("@tencent/spaui-loading"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
var LoadingComponent = function LoadingComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var className = props.className;
  return _react["default"].createElement(_spauiLoading["default"], {
    className: className
  });
};

var _default = LoadingComponent;
exports["default"] = _default;