"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultProps = exports.propTypes = exports.config = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件参数配置
 */

/**
 * BlockSelector 接口参数
 * @alias BlockSelectorProps
 * @description 方块内容选择器。
 * @property {titleline} 默认支持参数
 * @property {titleline} style;id;data-*;accessKey;dir;draggable;tabIndex;title;htmlRef;
 * @property {string} [className=''] className='mini'的时候是小尺寸的
 * @property {titleline} 基本属性
 * @property {array} data=[]] 数据<br /> data=[{label: '中学生', value: 1}]
 * @property {string|bool|number|array} value='' 选中值
 * @property {func} onChange 切换选中值回调函数<br />onChange = (event, value)   // value:值
 * @property {bool} [disabled=false] 是否禁用
 * @property {bool} [multiple=false] 是否多选
 * @property {func} [render] 根据值渲染对应内容<br />render=(value,data,attrs)//value:对应数据，data:整个节点数据, attrs={checked,disabled}
 */
var config = {
  data: {
    type: _spauiUtils.PropTypes.array.isRequired,
    defaultValue: []
  },
  value: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.bool, _spauiUtils.PropTypes.number, _spauiUtils.PropTypes.array]).isRequired,
    defaultValue: ''
  },
  onChange: {
    type: _spauiUtils.PropTypes.func.isRequired
  },
  disabled: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  multiple: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  render: {
    type: _spauiUtils.PropTypes.func
  }
}; // 转化成PureComponent组件的配置

exports.config = config;
var configData = (0, _spauiUtils.formatProps)(config); // 导出

var propTypes = configData.propTypes;
exports.propTypes = propTypes;
var defaultProps = configData.defaultProps;
exports.defaultProps = defaultProps;
var _default = {
  config: config,
  propTypes: propTypes,
  defaultProps: defaultProps
};
exports["default"] = _default;