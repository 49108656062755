"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
var _default = function _default() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _props$data = props.data,
      data = _props$data === void 0 ? {} : _props$data;
  var _data$img = data.img,
      img = _data$img === void 0 ? '' : _data$img;
  var result = null;

  if (img) {
    result = _react["default"].createElement("div", {
      className: "feed-img spaui-userpicker-feed-img"
    }, _react["default"].createElement("img", {
      src: img
    }));
  }

  return result;
};

exports["default"] = _default;