"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _spauiUtils = require("@tencent/spaui-utils");

var _classnames = _interopRequireDefault(require("classnames"));

var _index = require("../config/index");

var _get_node = require("../utils/get_node");

var _get_data = require("../utils/get_data");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

// 生成唯一id
var genUniqueId = function genUniqueId() {
  return (0, _spauiUtils.uniqueId)('spaui-table-tfoot');
};

var TfootComponent = function TfootComponent() {
  var theProps = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var cellInnerClassName = theProps.cellInnerClassName,
      _theProps$children = theProps.children,
      children = _theProps$children === void 0 ? null : _theProps$children,
      propsTotalData = theProps.totalData,
      data = theProps.data,
      filterName = theProps.filterName,
      filterValue = theProps.filterValue,
      htmlRef = theProps.htmlRef,
      _theProps$setTrClassN = theProps.setTrClassName,
      setTrClassName = _theProps$setTrClassN === void 0 ? _spauiUtils.noop : _theProps$setTrClassN,
      setCellStyle = theProps.setCellStyle,
      clickSpace = theProps.clickSpace,
      _theProps$onMouseOver = theProps.onMouseOverTr,
      onMouseOverTr = _theProps$onMouseOver === void 0 ? _spauiUtils.noop : _theProps$onMouseOver,
      _theProps$onMouseOutT = theProps.onMouseOutTr,
      onMouseOutTr = _theProps$onMouseOutT === void 0 ? _spauiUtils.noop : _theProps$onMouseOutT,
      supportingHierarchicalData = theProps.supportingHierarchicalData,
      _theProps$totalLabel = theProps.totalLabel,
      totalLabel = _theProps$totalLabel === void 0 ? '' : _theProps$totalLabel,
      _theProps$totalLabelC = theProps.totalLabelColSpan,
      totalLabelColSpan = _theProps$totalLabelC === void 0 ? 1 : _theProps$totalLabelC,
      _theProps$totalTip = theProps.totalTip,
      totalTip = _theProps$totalTip === void 0 ? '' : _theProps$totalTip,
      TotalContentComponent = theProps.TotalContentComponent;
  var columns = (0, _get_node.getColumns)(children); // 获取合并单元格的数据

  var _ref = (0, _get_data.getRowAndColSpanData)(theProps) || {},
      _ref$total = _ref.total,
      totalRowAndColSpanData = _ref$total === void 0 ? [] : _ref$total;

  var curTotalRowAndColSpanData = totalRowAndColSpanData[0] || []; // 获取totalData的数据

  var totalData = (0, _get_data.getTotalData)(data, filterName, filterValue, propsTotalData);

  var onClickTr = function onClickTr(event, trType, theTotalData) {
    // 没有拖动或者拖动的间距在一定范围内才触发点击行这个事件
    if (!eventClickPageX && !eventClickPageY || typeof event.pageX === 'undefined' && typeof event.pageY === 'undefined' || eventClickPageX - clickSpace <= event.pageX && eventClickPageX + clickSpace >= event.pageX && eventClickPageY - clickSpace <= event.pageY && eventClickPageY + clickSpace >= event.pageY) {
      if ((0, _spauiUtils.isFunction)(theProps.onClickTr)) {
        theProps.onClickTr(event, trType, theTotalData);
      }
    }

    eventClickPageX = 0;
    eventClickPageY = 0;
  };

  var trType = 'total';
  var eventClickPageX = 0;
  var eventClickPageY = 0;

  if (totalLabelColSpan > 1) {
    curTotalRowAndColSpanData.map(function (item, index) {
      if (index === 0) {
        item.colSpan = columns.length > totalLabelColSpan ? totalLabelColSpan : columns.length;
      } else if (index < totalLabelColSpan) {
        item.isNode = true;
        item['data-displayindex'] = 0; // 该单元格是隐藏的，真正需要显示的是第 0 个单元格
      }

      return item;
    });
  }

  return _react["default"].createElement("tfoot", {
    ref: htmlRef
  }, totalTip ? _react["default"].createElement(TotalContentComponent, _extends({}, theProps, {
    className: "spaui-table-total ".concat(setTrClassName(trType, totalData) || ''),
    onClick: function onClick(event) {
      return onClickTr(event, trType, totalData);
    },
    onMouseOver: function onMouseOver(event) {
      return onMouseOverTr(event, trType, totalData);
    },
    onMouseOut: function onMouseOut(event) {
      return onMouseOutTr(event, trType, totalData);
    },
    onMouseDown: function onMouseDown(event) {
      eventClickPageX = event.pageX || 0;
      eventClickPageY = event.pageY || 0;
    },
    columns: columns,
    TotalContentComponent: null
  }), _react["default"].createElement("td", {
    colSpan: columns.length,
    "data-fixed": columns[0] && columns[0].props && columns[0].props.fixedable ? 1 : '',
    "data-index": 0
  }, _react["default"].createElement("div", null, totalTip))) : _react["default"].createElement(TotalContentComponent, _extends({}, theProps, {
    className: "spaui-table-total ".concat(setTrClassName(trType, totalData) || ''),
    onClick: function onClick(event) {
      return onClickTr(event, trType, totalData);
    },
    onMouseOver: function onMouseOver(event) {
      return onMouseOverTr(event, trType, totalData);
    },
    onMouseOut: function onMouseOut(event) {
      return onMouseOutTr(event, trType, totalData);
    },
    onMouseDown: function onMouseDown(event) {
      eventClickPageX = event.pageX || 0;
      eventClickPageY = event.pageY || 0;
    },
    columns: columns,
    TotalContentComponent: null
  }), columns.map(function () {
    var subItem = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var index = arguments.length > 1 ? arguments[1] : undefined;
    var _subItem$props = subItem.props,
        props = _subItem$props === void 0 ? {} : _subItem$props;
    var name = props.name,
        format = props.format,
        type = props.type,
        render = props.render,
        align = props.align,
        verticalAlign = props.verticalAlign,
        show = props.show,
        className = props.className,
        isFixed = props.isFixed,
        _props$fixedable = props.fixedable,
        fixedable = _props$fixedable === void 0 ? false : _props$fixedable,
        _props$fixedAlign = props.fixedAlign,
        fixedAlign = _props$fixedAlign === void 0 ? '' : _props$fixedAlign,
        onClickTd = props.onClick,
        undefinedLabel = props.undefinedLabel,
        CustomTdComponent = props.component,
        customScope = props.customScope,
        _props$fixedBorder = props.fixedBorder,
        fixedBorder = _props$fixedBorder === void 0 ? true : _props$fixedBorder;
    var isFixedColumn = !(0, _spauiUtils.isUndefined)(isFixed) ? isFixed : fixedable; // 合并单元格的数据

    var rowAndColSpanProps = curTotalRowAndColSpanData[index] || {};
    var originValue = (0, _get_data.getValueByName)(totalData, name, supportingHierarchicalData); // 没有指定component, render的默认显示方式

    var defaultCellChildren = function defaultCellChildren() {
      var useFormat = false; // 是否使用format的方式

      if (format) {
        // 如果指定了作用域，在作用域内的就用render处理下，不在作用域内的就用默认的方式
        if ((0, _spauiUtils.isArray)(customScope) && customScope.length > 0) {
          if ((0, _spauiUtils.indexOf)(customScope, trType) > -1) {
            useFormat = true;
          }
        } else {
          // 没有指定作用域的，全部都是render
          useFormat = true;
        }
      } // 有格式的话用格式


      if (useFormat) {
        cellChildren = format.replace(/\$\{value\}/gi, value);
      } else {
        // 如果没有值的话，就用undefinedLabel展示
        if (typeof originValue === 'undefined') {
          cellChildren = undefinedLabel;
        } else {
          // 如果是object要转换下（不能使React的object）
          if (value && _typeof(value) === 'object' && typeof value.$$typeof === 'undefined' && typeof value.props === 'undefined') {
            try {
              cellChildren = JSON.stringify(value);
            } catch (e) {
              cellChildren = '';
            }
          } else {
            if (value && _typeof(value) === 'object' && typeof value.$$typeof !== 'undefined' && typeof value.props !== 'undefined') {
              cellChildren = undefinedLabel;
            } else {
              cellChildren = value;
            }
          }
        }
      }

      if (index === 0) {
        cellChildren = _react["default"].createElement("label", null, totalLabel, cellChildren);
      }
    };

    var value = typeof originValue === 'undefined' ? '' : originValue;
    var result = null;
    var style = {};
    var cellChildren = value;
    var cellStyle = {};
    var useCustomTdComponent = false; // 有传对齐参数的，换成text-align与verticalAlign

    if (align) {
      style.textAlign = align;
    }

    if (verticalAlign) {
      style.verticalAlign = verticalAlign;
    }

    style = (0, _spauiUtils.objectAssign)(style, rowAndColSpanProps.style); // 使用函数自定义了单元格的style

    if ((0, _spauiUtils.isFunction)(setCellStyle)) {
      cellStyle = setCellStyle(trType, name, value, 0, index, totalData);

      if (_typeof(cellStyle) === 'object') {
        style = (0, _spauiUtils.objectAssign)({}, style, cellStyle);
      }
    } // 单元格内容 - 可选择的列


    if (type === 'optional') {
      if (index === 0) {
        cellChildren = _react["default"].createElement("label", null, totalLabel);
      } else {
        cellChildren = '';
      }
    } else {
      // 单元格内容 - 如果指定了component用自定义的component
      if (typeof CustomTdComponent !== 'undefined') {
        // 如果指定了作用域，在作用域内的就用render处理下，不在作用域内的就用默认的方式
        if ((0, _spauiUtils.isArray)(customScope) && customScope.length > 0) {
          if ((0, _spauiUtils.indexOf)(customScope, trType) > -1) {
            useCustomTdComponent = true;
          }
        } else {
          useCustomTdComponent = true;
        }

        if (useCustomTdComponent) {
          if ((0, _spauiUtils.isReactNode)(CustomTdComponent)) {
            cellChildren = CustomTdComponent;
          } else {
            cellChildren = _react["default"].createElement(CustomTdComponent, _extends({}, props, {
              checkedValue: props.value,
              type: trType,
              name: name,
              value: originValue,
              data: totalData
            }));
          }
        } else {
          defaultCellChildren();
        } // 如果指定的render用render

      } else if (render && (0, _spauiUtils.isFunction)(render)) {
        // 如果指定了作用域，在作用域内的就用render处理下，不在作用域内的就用默认的方式
        if ((0, _spauiUtils.isArray)(customScope) && customScope.length > 0) {
          if ((0, _spauiUtils.indexOf)(customScope, trType) > -1) {
            cellChildren = render(trType, name, typeof originValue === 'undefined' && index === 0 ? totalLabel : originValue, totalData);
          } else {
            defaultCellChildren();
          }
        } else {
          // 没有指定作用域的，全部都是render
          cellChildren = render(trType, name, typeof originValue === 'undefined' && index === 0 ? totalLabel : originValue, totalData);
        }
      } else {
        defaultCellChildren();
      }
    } // 可显示并且不是被合并了的单元格


    if (show && !(!(0, _spauiUtils.isUndefined)(rowAndColSpanProps.renderable) && !rowAndColSpanProps.renderable)) {
      if (rowAndColSpanProps.isNode) {
        style = {
          display: 'none'
        };
        delete rowAndColSpanProps.isNode;
      }

      result = _react["default"].createElement("td", _extends({
        key: genUniqueId()
      }, rowAndColSpanProps, {
        style: style,
        className: (0, _classnames["default"])(className, rowAndColSpanProps.className, props.totalClassName),
        onClick: onClickTd,
        "data-index": index
      }), _react["default"].createElement("div", {
        className: cellInnerClassName
      }, cellChildren, isFixedColumn ? _react["default"].createElement(_index.FixedColumnBorder, {
        fixedAlign: fixedAlign,
        show: fixedBorder
      }) : null));
    }

    return result;
  })));
};

var _default = TfootComponent;
exports["default"] = _default;