"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultProps = exports.propTypes = exports.config = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件参数配置
 */

/**
 * KeyDay 接口参数
 * @alias KeyDayProps
 * @description 关键日期选择组件。
 * @property {titleline} 默认支持参数
 * @property {blankline} className;style;id;data-*;accessKey;dir;draggable;tabIndex;title;htmlRef;
 * @property {titleline} 基础属性
 * @property {array} value=[]] 选中的数据
 * @property {array} [keyday=[]] 关键日期
 * @property {func} [onChange] 数据变更回调<br />onChange = (value, keyday)
 * @property {bool} [disabled=false] 是否禁用
 * @property {string} [type='default'] 类型*可选值: default, keyday*
 * @property {number} [optionalStartRelativeDays=0] 可选开始相对时间
 * @property {number} [optionalEndRelativeDays=120] 可选结束相对时间
 * @property {string} [optionalStartDate] 可选开始日期
 * @property {string} [optionalEndDate] 可选结束日期
 * @property {string} [format='XXXX-XX-XX'] 日期格式
 *
 * @see
 *
 * onChange typings:
 *     (value: any, keyday: any) => any
 */
var config = {
  value: {
    type: _spauiUtils.PropTypes.array.isRequired,
    defaultValue: []
  },
  onChange: {
    type: _spauiUtils.PropTypes.func
  },
  keyday: {
    type: _spauiUtils.PropTypes.array,
    defaultValue: []
  },
  disabled: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  type: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  optionalStartRelativeDays: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: 0
  },
  optionalEndRelativeDays: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: 120
  },
  optionalStartDate: {
    type: _spauiUtils.PropTypes.string
  },
  optionalEndDate: {
    type: _spauiUtils.PropTypes.string
  },
  format: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'XXXX-XX-XX'
  }
}; // 转化成PureComponent组件的配置

exports.config = config;
var configData = (0, _spauiUtils.formatProps)(config); // 导出

var propTypes = configData.propTypes;
exports.propTypes = propTypes;
var defaultProps = configData.defaultProps;
exports.defaultProps = defaultProps;
var _default = {
  config: config,
  propTypes: propTypes,
  defaultProps: defaultProps
};
exports["default"] = _default;