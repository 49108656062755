"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件对应结构的默认className
 */
var _default = {
  topLeftClassName: 'radius-tl',
  topRightClassName: 'radius-tr',
  bottomLeftClassName: 'radius-bl',
  bottomRightClassName: 'radius-br',
  emptyClassName: 'empty',
  displayTextClassName: 'spaui-button-group-display-text'
};
exports["default"] = _default;