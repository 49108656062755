"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.getChildValues = exports.getParentValues = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
// 获取节点对应的所有父节点值
var getParentValues = function getParentValues() {
  var nodeData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var nodeMap = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var parentFieldName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  var useTreePosition = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

  var getValue = function getValue() {
    var theNodeData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var parentNodeValue = '';

    if (theNodeData && (theNodeData[parentFieldName] || typeof theNodeData[parentFieldName] === 'number')) {
      parentNodeValue = theNodeData[parentFieldName];

      if (nodeMap[parentNodeValue]) {
        if (nodeMap[parentNodeValue]._treePosition && useTreePosition) {
          result.push(nodeMap[parentNodeValue]._treePosition);
        } else {
          result.push(parentNodeValue);
        }
      } else {
        result.push(parentNodeValue);
      }

      if (nodeMap[parentNodeValue]) {
        getValue(nodeMap[parentNodeValue]);
      }
    }
  };

  var result = [];

  if (nodeData) {
    getValue(nodeData);
  }

  return result;
}; // 获取节点对应的所有子节点值


exports.getParentValues = getParentValues;

var getChildValues = function getChildValues() {
  var nodeValue = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var nodeMap = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var valueFieldName = arguments.length > 2 ? arguments[2] : undefined;
  var onlyGetSubOption = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

  var getValue = function getValue() {
    var theNodeData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    if (theNodeData && (0, _spauiUtils.isArray)(theNodeData.childrenNodes) && theNodeData.childrenNodes.length > 0) {
      theNodeData.childrenNodes.map(function (item) {
        var subNodeValue = '';

        if (item) {
          subNodeValue = item[valueFieldName];
          var childrenNodes = item.childrenNodes || [];

          if (subNodeValue || typeof subNodeValue === 'number') {
            if (!onlyGetSubOption || onlyGetSubOption && childrenNodes.length < 1) {
              result.push(subNodeValue);
            }

            getValue(nodeMap[subNodeValue]);
          }
        }

        return item;
      });
    }
  };

  var nodeData = nodeMap[nodeValue] || null;
  var result = [];

  if (nodeData && (0, _spauiUtils.isArray)(nodeData.childrenNodes) && nodeData.childrenNodes.length > 0) {
    getValue(nodeData);
  }

  return result;
};

exports.getChildValues = getChildValues;
var _default = {
  getParentValues: getParentValues
};
exports["default"] = _default;