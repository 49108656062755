"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _index = require("../../utils/index");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
var _default = function _default() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _props$data = props.data,
      data = _props$data === void 0 ? {} : _props$data,
      isPDF = props.isPDF;
  var result = null;

  if (isPDF) {
    result = _react["default"].createElement("div", {
      className: "viewer-pic is-loaded viewer-pdf"
    }, (0, _index.getItemName)(data) || 'PDF');
  } else {
    result = _react["default"].createElement("div", {
      className: "viewer-pic is-loaded",
      style: {
        backgroundImage: "url(".concat((0, _index.getItemThumbUrl)(data), ")")
      }
    });
  }

  return result;
};

exports["default"] = _default;