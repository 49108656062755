"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _spauiIcon = _interopRequireDefault(require("@tencent/spaui-icon"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2020, a Tencent company. All rights reserved.
 */
var SepComponent = function SepComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var separator = props.separator,
      baseClassName = props.baseClassName;
  return _react["default"].createElement("span", {
    className: "".concat(baseClassName, "-sep")
  }, _react["default"].createElement("span", {
    className: "spaui-icon"
  }, separator || _react["default"].createElement(_spauiIcon["default"], {
    name: "right-arrow-outlined"
  })));
};

var _default = SepComponent;
exports["default"] = _default;