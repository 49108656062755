"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview loading
 */
var TotalContentComponent = function TotalContentComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // children就是默认的展示内容，如果不需要可以自定义，onClick, onMouseOver, onMouseOut, onMouseDown是必须透传的
  var className = props.className,
      onClick = props.onClick,
      onMouseOver = props.onMouseOver,
      onMouseOut = props.onMouseOut,
      onMouseDown = props.onMouseDown,
      children = props.children;
  return _react["default"].createElement("tr", {
    "data-spaui-table-tr": "1",
    className: className,
    onClick: onClick,
    onMouseOver: onMouseOver,
    onMouseOut: onMouseOut,
    onMouseDown: onMouseDown
  }, children);
};

var _default = TotalContentComponent;
exports["default"] = _default;