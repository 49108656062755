"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
var Prefix = function Prefix(props) {
  var showStepButton = props.showStepButton,
      prefix = props.prefix,
      onClick = props.onClick,
      _props$classNameMap = props.classNameMap,
      classNameMap = _props$classNameMap === void 0 ? {} : _props$classNameMap;
  var result = null;
  var _prefix = null;

  if (showStepButton) {
    _prefix = _react["default"].createElement("i", {
      className: "graph-icon graph-icon-add",
      onClick: onClick
    }, _react["default"].createElement("i", {
      className: "add-hor"
    }));
  } else if (prefix) {
    _prefix = prefix;
  }

  if (_prefix) {
    result = _react["default"].createElement("div", {
      className: classNameMap.SliderPrefixClassName
    }, _prefix);
  }

  return result;
};

var _default = Prefix;
exports["default"] = _default;