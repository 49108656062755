"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultProps = exports.propTypes = exports.config = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

var _close_component = _interopRequireDefault(require("./component/contentandimage/close_component"));

var _display_component = _interopRequireDefault(require("./component/common/display_component"));

var _head_component = _interopRequireDefault(require("./component/images/head_component"));

var _pic_component = _interopRequireDefault(require("./component/images/pic_component"));

var _tool_component = _interopRequireDefault(require("./component/images/tool_component"));

var _bottom_component = _interopRequireDefault(require("./component/images/bottom_component"));

var _total_component = _interopRequireDefault(require("./component/images/total_component"));

var _small_display_component = _interopRequireDefault(require("./component/images/small_display_component"));

var _switchdots_component = _interopRequireDefault(require("./component/images/switchdots_component"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件参数配置
 */

/**
 * Viewer 接口参数
 * @alias ViewerProps
 * @description 用于大图查看图片、文档或多图查看器。
 * @property {titleline} 默认支持参数
 * @property {blankline} className;style;id;data-*;accessKey;dir;draggable;tabIndex;title;htmlRef;
 * @property {titleline} 基本属性
 * @property {bool} show=false 是否展示
 * @property {bool} [fixedDisplayContent=false] 多图时是否固定展示内容区域
 * @property {func} [onClose] 关闭弹框回调<br />onClose = function(event) {}
 * @property {string} [type='content'] 展示类型*可选值：content, image, images*
 * @property {string} [maskClassName=''] 遮罩层class
 * @property {string} [contentClassName=''] 内容class
 * @property {object} [contentStyle={}] 内容style
 * @property {string} [closeClassName=''] 关闭按钮class
 * @property {bool} [enabledMaskClose=true] 遮罩层是否可点击关闭
 * @property {titleline} 单图属性
 * @property {bool} [scroll=false] 是否有滚动条可以滚动
 * @property {number} [width] 弹框内容宽度
 * @property {number} [height] 弹框内容高度
 * @property {func} [setSize] 单图展示时可以根据图片的实际width,height来重设图片的宽高*setSize(width,height) => return {width, height}*
 * @property {titleline} 多图属性
 * @property {array} [data=[]] 多图数据
 * @property {number} [index=0] 展示哪张图片
 * @property {number} [footShow=true] 多图默认是否展示小图列表
 * @property {bool} [titleBar=true] 是否展示图标名字标题结构
 * @property {bool} [download=true] 是否可下载
 * @property {bool} [tool=true] 是否有放大缩小工具
 * @property {bool} [thumb=true] 是否有小图片模块
 * @property {bool} [total=false] 是否总计模块
 * @property {bool} [switchdots=false] 是否有切换圆点
 * @property {number} [scale] 多图展示缩放比例
 * @property {number} [scaleRatio=0.1] 每次缩放比例
 * @property {number} [fixedScale] 固定缩放比例，如果不使用弹框的类型，默认就是1
 * @property {number} [maxScale=1] 缩放最大的比例
 * @property {number} [deg] 多图展示旋转角度
 * @property {number} [degRatio=90] 每次旋转角度
 * @property {bool} [prevAndNext=true] 是否有左右切换按钮
 * @property {bool} [dragImage=false] 图片是否可拖动(ie10及以下不可拖动)
 * @property {bool} [usePortal=true] 是否使用弹框
 * @property {func} [onScale] 多图缩放<br/>onScale=function(event, currentScale, nextScale){}
 * @property {func} [onDeg] 多图旋转<br />onDeg=function(event, currentDeg, nextDeg){}
 * @property {string} [headClassName=''] 多图头部class
 * @property {object} [headStyle={}] 多图头部style
 * @property {string} [footClassName=''] 多图尾部class
 * @property {object} [footStyle={}] 多图头部style
 * @property {number} [horizontalGap] 多图缩放时展示范围的水平间隙（主要可以排除左右按钮之类的覆盖区域）
 * @property {number} [verticalGap] 多图缩放时展示范围的垂直间隙（主要可以排除上下按钮之类的覆盖区域）
 * @property {func} [onClickPrev] 点击上一个图片回调
 * @property {func} [onClickNext] 点击下一个图片回调
 * @property {titleline} 单图自定义属性
 * @property {element|func} [DisplayComponent] 图片展示组件*(具体参数看案例)*
 * @property {element|func} [CloseComponent] type != images 关闭按钮组件*(具体参数看案例)*
 * @property {titleline} 多图自定义属性
 * @property {element|func} [DisplayComponent] 图片展示组件*(具体参数看案例)*
 * @property {element|func} [SmallDisplayComponent] type=images 多图片展示底下小图片组件*(具体参数看案例)*
 * @property {element|func} [HeadComponent] type=images 头部组件*(具体参数看案例)*
 * @property {element|func} [PicComponent] type=images 多图展示结构*(具体参数看案例)*
 * @property {element|func} [ToolComponent] type=images 多图工具操作结构*(具体参数看案例)*
 * @property {element|func} [BottomComponent] type=images 多图底部操作结构*(具体参数看案例)*
 * @property {element|func} [TotalComponent] type=images 多图总计结构*(具体参数看案例)*
 * @property {element|func} [ExtensionComponent] type=images 多图扩展组件结构*(具体参数看案例)*
 * @property {element|func} [SwitchDotsComponent] type=images 多图切换圆点结构*(具体参数看案例)*
 * @property {object} [classNameMap={}] 组件内对应结构的默认className(可以针对对应位置进行替换,具体参数见案例)
 * @example
 * &lt;DisplayComponent
 type={type}
    data={{
        url: url
    }}
    width='0'
    height='0'
    htmlRef={(el) => { }}
/&gt;
    *
    *&lt;CloseComponent
{...this.props}
/&gt;
    *
    * &lt;SmallDisplayComponent
isPDF={}
data={item}
/&gt;
    *
    * &lt;HeadComponent
info={imgData}
data={data}
downloadable={download}
download={download}
className={classNames('viewer-close', this.props.closeClassName)}
onClose={onClose}
/&gt;
    *
    * &lt;PicComponent
type={type}
DisplayComponent={DisplayComponent}
info={imgData}
data={data}
scale={scale}
deg={deg}
dragImage={dragImage}
onMouseDown={this.handleImageMouseDown}
id={this.picId}
/&gt;
    *
    * &lt;ToolComponent
toolable={tool}
isLowVersionOfIE={isLowVersionOfIE}
data={data}
info={imgData}
onDeg={this.handleDeg}
scale={scale}
maxScale={maxScale}
deg={deg}
onZoomOut={this.handleZoomOut}
onZoomIn={this.handleZoomIn}
fixedScale={fixedScale}
/&gt;
    *
    * &lt;BottomComponent
SmallDisplayComponent={SmallDisplayComponent}
thumbable={thumb}
totalable={total}
total={total}
info={imgData}
data={data}
index={index}
toggleShowFoot={this.handleToggleShowFoot}
onCurrent={this.handleCurrent}
onPrev={this.handlePrev}
onNext={this.handleNext}
htmlRef={(el) => { }}
TotalComponent={TotalComponent}
/&gt;
    *
    * &lt;TotalComponent
totalable={total}
index={index}
data={data}
/&gt;
    *
    * &lt;ExtensionComponent
{...this.props}
index={index}
/&gt;
    *
    *
    * &lt;SwitchDotsComponent
data={data}
index={index}
onCurrent={(event, index)}
/&gt;
    *
    *
    * classNameMap配置可见于：
    * https://git.woa.com/ads/spaui-basic_ui_components/tree/master/src/spaui-viewer/src/js/config/classname_map.js
    */
var config = {
  show: {
    type: _spauiUtils.PropTypes.bool.isRequired,
    defaultValue: false
  },
  fixedDisplayContent: {
    type: _spauiUtils.PropTypes.bool.isRequired,
    defaultValue: false
  },
  dragImage: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  type: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'content'
  },
  onClose: {
    type: _spauiUtils.PropTypes.func
  },
  data: {
    type: _spauiUtils.PropTypes.array,
    defaultValue: []
  },
  footShow: {
    type: _spauiUtils.PropTypes.bool
  },
  index: {
    type: _spauiUtils.PropTypes.number
  },
  scale: {
    type: _spauiUtils.PropTypes.number
  },
  deg: {
    type: _spauiUtils.PropTypes.number
  },
  scaleRatio: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: 0.1
  },
  fixedScale: {
    type: _spauiUtils.PropTypes.number
  },
  degRatio: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: 90
  },
  maxScale: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: 1
  },
  titleBar: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  download: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  thumb: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  total: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  switchdots: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  tool: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  prevAndNext: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  width: {
    type: _spauiUtils.PropTypes.number
  },
  height: {
    type: _spauiUtils.PropTypes.number
  },
  setSize: {
    type: _spauiUtils.PropTypes.func
  },
  autoFitSize: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  maskClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  contentClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  contentStyle: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  headClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  headStyle: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  footClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  footStyle: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  closeClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  enabledMaskClose: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  scroll: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  usePortal: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  onScale: {
    type: _spauiUtils.PropTypes.func
  },
  onClickPrev: {
    type: _spauiUtils.PropTypes.func
  },
  onClickNext: {
    type: _spauiUtils.PropTypes.func
  },
  onDeg: {
    type: _spauiUtils.PropTypes.func
  },
  horizontalGap: {
    type: _spauiUtils.PropTypes.number
  },
  verticalGap: {
    type: _spauiUtils.PropTypes.number
  },
  HeadComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _head_component["default"]
  },
  PicComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _pic_component["default"]
  },
  ToolComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _tool_component["default"]
  },
  BottomComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _bottom_component["default"]
  },
  TotalComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _total_component["default"]
  },
  ExtensionComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element])
  },
  DOMComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element])
  },
  CloseComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _close_component["default"]
  },
  DisplayComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _display_component["default"]
  },
  SmallDisplayComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _small_display_component["default"]
  },
  SwitchDotsComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _switchdots_component["default"]
  }
}; // 转化成PureComponent组件的配置

exports.config = config;
var configData = (0, _spauiUtils.formatProps)(config); // 导出

var propTypes = configData.propTypes;
exports.propTypes = propTypes;
var defaultProps = configData.defaultProps;
exports.defaultProps = defaultProps;
var _default = {
  config: config,
  propTypes: propTypes,
  defaultProps: defaultProps
};
exports["default"] = _default;