"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件对应结构的默认className
 */
var _default = {
  Dropdown: '',
  // Dropdown组件顶级className
  DropdownToggleOpenClassName: 'with-drop spaui-dropdown-open',
  // 下拉面板打开时触发对象添加的className
  DropdownContent: 'spaui-dropdown-content' // DropdownContent组件顶级className

};
exports["default"] = _default;