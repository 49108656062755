"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.isAdjustWidth = exports.isSetedCustomStyle = exports.isFixedTableColumn = exports.getFixedHeight = exports.getFixedRows = exports.isFixedTableTotalByCss = exports.isFixedTableTheadByCss = exports.isFixedTableThead = void 0;

var _table_compact_props = _interopRequireDefault(require("../table_compact_props"));

var _get_node = require("./get_node");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 是与否的判断
 * @author kirkzheng
 */
// 是否固定表格头部
var isFixedTableThead = function isFixedTableThead() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var fixedRows = props.fixedRows,
      fixedHeight = props.fixedHeight,
      hideThead = props.hideThead,
      _props$data = props.data,
      data = _props$data === void 0 ? [] : _props$data,
      notFixedTheadOnEmptyData = props.notFixedTheadOnEmptyData;

  var _compactProps = (0, _table_compact_props["default"])(props),
      cssFixedTheadAble = _compactProps.cssFixedTheadAble,
      cssFixedTotalAble = _compactProps.cssFixedTotalAble;

  var result = false; // 头部都没有了，也就没法固定了

  if (hideThead) {
    result = false;
  } else {
    var isFixedContent = fixedHeight > 0 || fixedRows > 0 || cssFixedTheadAble || cssFixedTotalAble; // 指定了total固定，即使没有指定cssFixedTheadAble也会当成是true来处理，这样total才能够滚动

    result = isFixedContent && (!notFixedTheadOnEmptyData || data && data.length !== 0) || false;
  }

  return result;
}; // 是否css固定头部


exports.isFixedTableThead = isFixedTableThead;

var isFixedTableTheadByCss = function isFixedTableTheadByCss() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  var _compactProps2 = (0, _table_compact_props["default"])(props),
      cssFixedTheadAble = _compactProps2.cssFixedTheadAble,
      hideThead = _compactProps2.hideThead;

  var result = cssFixedTheadAble;

  if (hideThead) {
    result = false;
  }

  return result;
}; // 是否css固定尾部


exports.isFixedTableTheadByCss = isFixedTableTheadByCss;

var isFixedTableTotalByCss = function isFixedTableTotalByCss() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  var _compactProps3 = (0, _table_compact_props["default"])(props),
      _compactProps3$cssFix = _compactProps3.cssFixedTotalAble,
      cssFixedTotalAble = _compactProps3$cssFix === void 0 ? false : _compactProps3$cssFix;

  return cssFixedTotalAble;
}; // 获取fixedRows


exports.isFixedTableTotalByCss = isFixedTableTotalByCss;

var getFixedRows = function getFixedRows() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _props$fixedRows = props.fixedRows,
      fixedRows = _props$fixedRows === void 0 ? 0 : _props$fixedRows;
  var cssFixedTheadAble = isFixedTableTheadByCss(props);
  var cssFixedTotalAble = isFixedTableTotalByCss(props);
  var result = fixedRows; // 如果指定页面固定头部尾部，fixedRows如果大于0那也是无效的

  if ((cssFixedTheadAble || cssFixedTotalAble) && fixedRows > 0) {
    result = 0;
  }

  return result;
}; // 获取fixedHeight


exports.getFixedRows = getFixedRows;

var getFixedHeight = function getFixedHeight() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _props$fixedHeight = props.fixedHeight,
      fixedHeight = _props$fixedHeight === void 0 ? 0 : _props$fixedHeight,
      _props$data2 = props.data,
      data = _props$data2 === void 0 ? [] : _props$data2;
  var cssFixedTheadAble = isFixedTableTheadByCss(props);
  var cssFixedTotalAble = isFixedTableTotalByCss(props);
  var result = fixedHeight; // 如果指定页面固定头部尾部，fixedHeight如果大于0那也是无效的
  // 如果没有表格数据，则强行将 fixedHeight 设置为零（此时设置 fixedHeight 无意义）

  if ((cssFixedTheadAble || cssFixedTotalAble) && fixedHeight > 0 || data && data.length === 0) {
    result = 0;
  }

  return result;
}; // 是否固定表格列


exports.getFixedHeight = getFixedHeight;

var isFixedTableColumn = function isFixedTableColumn() {
  var theProps = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _theProps$children = theProps.children,
      children = _theProps$children === void 0 ? null : _theProps$children;
  var columns = (0, _get_node.getColumns)(children);
  var result = false;
  columns.map(function () {
    var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var _item$props = item.props,
        props = _item$props === void 0 ? {} : _item$props;
    var _props$fixedable = props.fixedable,
        fixedable = _props$fixedable === void 0 ? false : _props$fixedable;

    if (!result && fixedable) {
      result = true;
    }

    return item;
  });
  return result;
}; // 是否已经设置自定义样式标记


exports.isFixedTableColumn = isFixedTableColumn;

var isSetedCustomStyle = function isSetedCustomStyle() {
  var index = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : -1;
  var nodeMap = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var colThead = nodeMap.colThead,
      colTbody = nodeMap.colTbody,
      colTfoot = nodeMap.colTfoot,
      len = nodeMap.len;
  var result = true;
  var i = 0;
  var itemThead = null;
  var customstyle = false;
  itemThead = colTbody[index] || colThead[index] || colTfoot[index] || null; // 先判断下是否已经处理过自适应的，自适应的只认第一次判断的结果

  if (!itemThead) {
    for (i = 0; i < len; i++) {
      itemThead = colTbody[i] || colThead[i] || colTfoot[i];
      customstyle = itemThead.getAttribute('data-customstyle');

      if (customstyle && result) {
        result = false;
      }
    }
  } else {
    customstyle = itemThead.getAttribute('data-customstyle');

    if (customstyle && result) {
      result = false;
    }
  }

  return result;
}; // 是否有自适应宽度


exports.isSetedCustomStyle = isSetedCustomStyle;

var isAdjustWidth = function isAdjustWidth(self) {
  var children = self.props.children;
  var columns = (0, _get_node.getColumns)(children);
  var result = false;
  columns.map(function () {
    var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var _item$props2 = item.props,
        props = _item$props2 === void 0 ? {} : _item$props2;
    var _props$adjustWidth = props.adjustWidth,
        adjustWidth = _props$adjustWidth === void 0 ? false : _props$adjustWidth;

    if (!result && adjustWidth) {
      result = true;
    }

    return item;
  });
  return result;
};

exports.isAdjustWidth = isAdjustWidth;
var _default = {
  isFixedTableThead: isFixedTableThead,
  isFixedTableTheadByCss: isFixedTableTheadByCss,
  isFixedTableTotalByCss: isFixedTableTotalByCss,
  isFixedTableColumn: isFixedTableColumn,
  isSetedCustomStyle: isSetedCustomStyle,
  isAdjustWidth: isAdjustWidth,
  getFixedRows: getFixedRows,
  getFixedHeight: getFixedHeight
};
exports["default"] = _default;