"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
var HelpBlockComponent = function HelpBlockComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var children = props.children,
      _props$helpBlockClass = props.helpBlockClassName,
      helpBlockClassName = _props$helpBlockClass === void 0 ? '' : _props$helpBlockClass,
      _props$helpBlockStyle = props.helpBlockStyle,
      helpBlockStyle = _props$helpBlockStyle === void 0 ? {} : _props$helpBlockStyle;
  return _react["default"].createElement("div", {
    className: "help-block ".concat(helpBlockClassName),
    style: helpBlockStyle
  }, children);
};

var _default = HelpBlockComponent;
exports["default"] = _default;