"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _index = require("../utils/index");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var LinkComponent = function LinkComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var children = props.children,
      href = props.href,
      className = props.className,
      _props$target = props.target,
      target = _props$target === void 0 ? '' : _props$target;
  var anchorProps = {};

  if (target) {
    anchorProps.target = target;
  } else {
    anchorProps = {};
  }

  return _react["default"].createElement("a", _extends({
    role: props.role,
    "data-toggle": props['data-toggle'],
    "data-haspopup": props['data-haspopup'],
    "aria-expanded": props['aria-expanded'],
    href: href,
    className: className
  }, (0, _index.getSupportEventMap)(props), (0, _index.getDataSet)(props), anchorProps), children);
};

var _default = LinkComponent;
exports["default"] = _default;