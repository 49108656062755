"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.getBody = exports.getClientSize = exports.getScrollLeft = exports.getScrollTop = exports.haveJQuery = void 0;

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
// 是否有jquery
var haveJQuery = (typeof window === "undefined" ? "undefined" : _typeof(window)) === 'object' && typeof window.jQuery !== 'undefined'; // scrollTop

exports.haveJQuery = haveJQuery;

var getScrollTop = function getScrollTop(target) {
  var result = 0;

  if (target) {
    result = target.scrollTop;

    if (result < 1 && haveJQuery) {
      result = jQuery(target).scrollTop();
    }
  } else {
    if ((typeof document === "undefined" ? "undefined" : _typeof(document)) === 'object') {
      result = document.getElementsByTagName('html')[0].scrollTop || document.getElementsByTagName('body')[0].scrollTop;
    }

    if (result < 1 && haveJQuery) {
      result = jQuery('html').scrollTop() || jQuery('body').scrollTop();
    }
  }

  return result;
}; // scrollLeft


exports.getScrollTop = getScrollTop;

var getScrollLeft = function getScrollLeft(target) {
  var result = 0;

  if (target) {
    result = target.scrollLeft;

    if (result < 1 && haveJQuery) {
      result = jQuery(target).scrollLeft();
    }
  } else {
    if ((typeof document === "undefined" ? "undefined" : _typeof(document)) === 'object') {
      result = document.getElementsByTagName('html')[0].scrollLeft || document.getElementsByTagName('body')[0].scrollLeft;
    }

    if (result < 1 && haveJQuery) {
      result = jQuery('html').scrollLeft() || jQuery('body').scrollLeft();
    }
  }

  return result;
}; // 获取客户端的宽高


exports.getScrollLeft = getScrollLeft;

var getClientSize = function getClientSize() {
  var result = {
    clientWidth: 0,
    clientHeight: 0
  };

  if ((typeof document === "undefined" ? "undefined" : _typeof(document)) === 'object' && document) {
    result = {
      clientWidth: Math.max(document.body.clientWidth, document.documentElement.clientWidth),
      clientHeight: Math.max(document.body.clientHeight, document.documentElement.clientHeight)
    };
  }

  return result;
};

exports.getClientSize = getClientSize;

var getBody = function getBody() {
  var result = null;

  if ((typeof document === "undefined" ? "undefined" : _typeof(document)) === 'object') {
    result = document.body || document.getElementsByTagName('body')[0];
  }

  return result;
};

exports.getBody = getBody;
var _default = {
  haveJQuery: haveJQuery,
  getScrollTop: getScrollTop,
  getScrollLeft: getScrollLeft,
  getClientSize: getClientSize,
  getBody: getBody
};
exports["default"] = _default;