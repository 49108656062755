"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _spauiCheckbox = _interopRequireDefault(require("@tencent/spaui-checkbox"));

var _text_component = _interopRequireDefault(require("./text_component"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var CheckAllComponent = function CheckAllComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _props$checkAllLabel = props.checkAllLabel,
      checkAllLabel = _props$checkAllLabel === void 0 ? '' : _props$checkAllLabel,
      _props$checkAllDataSe = props.checkAllDataSet,
      checkAllDataSet = _props$checkAllDataSe === void 0 ? {} : _props$checkAllDataSe,
      checked = props.checked,
      indeterminate = props.indeterminate,
      _props$nodeClassName = props.nodeClassName,
      nodeClassName = _props$nodeClassName === void 0 ? '' : _props$nodeClassName,
      _props$nodeCheckedCla = props.nodeCheckedClassName,
      nodeCheckedClassName = _props$nodeCheckedCla === void 0 ? '' : _props$nodeCheckedCla,
      checkAll = props.checkAll,
      onClick = props.onClick,
      setNodeClassName = props.setNodeClassName,
      _props$disabled = props.disabled,
      disabled = _props$disabled === void 0 ? false : _props$disabled,
      _props$CheckboxProps = props.CheckboxProps,
      CheckboxProps = _props$CheckboxProps === void 0 ? {} : _props$CheckboxProps,
      onChange = props.onChange;
  return _react["default"].createElement("li", _extends({
    title: checkAllLabel
  }, checkAllDataSet, {
    className: (0, _classnames["default"])(nodeClassName, _defineProperty({
      checked: checked
    }, nodeCheckedClassName, checked), setNodeClassName({
      checkAll: checkAll
    }, {
      checked: checked,
      indeterminate: indeterminate
    })),
    onClick: onClick
  }), _react["default"].createElement(_spauiCheckbox["default"], _extends({}, CheckboxProps, {
    className: (0, _classnames["default"])('spaui-tree-checkbox', CheckboxProps.className || ''),
    TextComponent: _text_component["default"],
    disabled: disabled,
    indeterminate: indeterminate,
    checked: checked,
    onChange: onChange
  }), checkAllLabel));
};

var _default = CheckAllComponent;
exports["default"] = _default;