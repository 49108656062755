"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
var _default = function _default() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var children = props.children,
      _props$textClassName = props.textClassName,
      textClassName = _props$textClassName === void 0 ? '' : _props$textClassName,
      label = props.label;
  var result = null;
  var showLabel = children;

  if (typeof showLabel === 'undefined' && typeof label !== 'undefined') {
    showLabel = label;
  }

  if (showLabel) {
    result = _react["default"].createElement("span", {
      className: "".concat(textClassName, " spaui-checkbox-text"),
      onMouseEnter: props.onMouseEnter,
      onMouseLeave: props.onMouseLeave
    }, showLabel);
  }

  return result;
};

exports["default"] = _default;