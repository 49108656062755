"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultProps = exports.propTypes = exports.config = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

var _label_component = _interopRequireDefault(require("./component/label_component"));

var _item_component = _interopRequireDefault(require("./component/item_component"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件参数配置
 */

/**
 * 属性说明
 * @alias CarouselProps
 * @property {titleline} 默认支持参数
 * @description 用于滑动查看图片。
 * @property {blankline} className;style;id;data-*;accessKey;dir;draggable;tabIndex;title;htmlRef;
 * @property {titleline} 基础属性
 * @property {number} value=0 默认展示,data索引位置
 * @property {array} data=[]] 图片数据
 * @property {func} onChange 切换状态回调函数*onChange = (event, value, data)   // value:值, data: 对应的数据*
 * @property {bool} [autoPlay=false] 是否自动播放
 * @property {number} [autoPlayTime=3000] 自动播放间隔时间
 * @property {string|number} [itemHeight=0] 图片元素的高度
 * @property {string} [arrowDisplayType] 箭头显示类型*可选值：default(一直显示), auto(第一张时不显示切换上一张的箭头 最后一张不显示切换下一张的箭头)*
 * @property {func} [onClickItem] 点击某个item回调数据*onClickItem = (event, data)   // data: 对应的数据*
 * @property {string} [innerClassName=''] inner结构的className
 * @property {object} [innerStyle={}] inner结构的style样式
 * @property {object} [itemStyle={}] 每个图片节点的style样式
 * @property {object} [imgStyle={}] 图片的style样式
 * @property {object} [indicatorStyle={}] 小圆点的style样式
 * @property {object} [controlStyle={}] 切换控制的style样式
 * @property {bool} [showIndicator=true] 是否显示小圆点指标
 * @property {string} [indicatorSequence='first'] 小圆点指示器的代码出现顺序 first|last
 * @property {number} [transitionTime=300] 动画时间*（用于知道什么时候回收动画标记）*
 * @property {string} [controlElementAlignmentNodeClassName=''] 可用于设置控制节点对齐某个节点（值为对应节点的className）*(用querySelectorAll查找)*
 * @property {titleline} 自定义结构属性
 * @property {element} [PrevArrowComponent] 切换轮播图上一张的箭头结构
 * @property {element} [NextArrowComponent] 切换轮播图下一张的箭头结构
 * @property {element} [ItemComponent] 元素结构*(具体参数看案例)*
 * @property {element} [LabelComponent] 文案结构*(具体参数看案例)*
 * @property {element} [AdditionComponent] 每个结构附加的结构*(旧名称为ItemAppendComponent)(具体参数看案例)*
 * @property {element} [IndicatorComponent] 自定义小圆点结构*(具体参数看案例)*
 * @property {object} [classNameMap={}] 组件内对应结构的默认className(可以针对对应位置进行替换,具体参数见案例)
 *
 * @see
 *
 * onChange typings:
 *     (event: any, value: any, data: any) => any
 *
 * ====
 *
 * onClickItem typings:
 *     (event: any, data: any) => any
 *
 *
 * @example
 * &lt;ItemComponent
    index={index}
    className={className}
    key={index}
    data={item}
    value={currentValue}
    active={index === currentValue}
    itemHeight={itemHeight}
    itemStyle={itemStyle}
    imgStyle={imgStyle}
    onChange={this.handleChange}
    onClickItem={onClickItem}
    onTransitionEnd={this.handleTransitionEnd}
    LabelComponent={LabelComponent}
    AdditionComponent={AdditionComponent}
/&gt;
 *
 *&lt;LabelComponent
    index={index}
    value={value}
    data={data}
/&gt;
 *
 *&lt;AdditionComponent
    index={index}
    value={value}
    data={data}
/&gt;
 *
 * classNameMap配置可见于：
 * https://git.woa.com/ads/spaui-basic_ui_components/tree/master/src/spaui-carousel/src/js/config/classname_map.js
 */
var config = {
  data: {
    type: _spauiUtils.PropTypes.array.isRequired,
    defaultValue: []
  },
  value: {
    type: _spauiUtils.PropTypes.number.isRequired,
    defaultValue: 0
  },
  autoPlay: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  autoPlayTime: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.number, _spauiUtils.PropTypes.string]),
    defaultValue: 3000
  },
  onChange: {
    type: _spauiUtils.PropTypes.func.isRequired
  },
  innerClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  innerStyle: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  itemStyle: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  imgStyle: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  indicatorStyle: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  controlStyle: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  showIndicator: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  indicatorSequence: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'first'
  },
  transitionTime: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.number, _spauiUtils.PropTypes.string]),
    defaultValue: 300
  },
  controlElementAlignmentNodeClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  itemHeight: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number]),
    defaultValue: 0
  },
  onClickItem: {
    type: _spauiUtils.PropTypes.func
  },
  ItemComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _item_component["default"]
  },
  LabelComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _label_component["default"]
  },
  AdditionComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: null
  },
  IndicatorComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func]),
    defaultValue: null
  }
}; // 转化成PureComponent组件的配置

exports.config = config;
var configData = (0, _spauiUtils.formatProps)(config); // 导出

var propTypes = configData.propTypes;
exports.propTypes = propTypes;
var defaultProps = configData.defaultProps;
exports.defaultProps = defaultProps;
var _default = {
  config: config,
  propTypes: propTypes,
  defaultProps: defaultProps
};
exports["default"] = _default;