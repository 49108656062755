"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _spauiCheckbox = _interopRequireDefault(require("@tencent/spaui-checkbox"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
var CheckAllComponent = function CheckAllComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var disabled = props.disabled,
      checkAllLabel = props.checkAllLabel,
      _props$checked = props.checked,
      checked = _props$checked === void 0 ? false : _props$checked,
      _props$indeterminate = props.indeterminate,
      indeterminate = _props$indeterminate === void 0 ? false : _props$indeterminate,
      onChange = props.onChange;
  return _react["default"].createElement("div", {
    className: "spaui-userpicker-checkall"
  }, _react["default"].createElement(_spauiCheckbox["default"], {
    onChange: onChange,
    disabled: disabled,
    checked: checked,
    indeterminate: indeterminate
  }, checkAllLabel));
};

var _default = CheckAllComponent;
exports["default"] = _default;