"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultProps = exports.propTypes = exports.config = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

var _LinkComponent = _interopRequireDefault(require("./component/LinkComponent"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件参数配置
 */

/**
 * TopNav 接口参数
 * @alias TopNavProps
 * @description 顶部导航栏组件。
 * @property {titleline} 默认支持参数
 * @property {blankline} className;style;id;data-*;accessKey;dir;draggable;tabIndex;title;htmlRef;
 * @property {titleline} 基础属性
 * @property {string|element} title='' logo标题
 * @property {string} rootUrl='' 网站根目录url
 * @property {string} theme='dark' 主题，默认为 dark
 * @property {bool} [fixed] 是否 position: fixed 定位
 * @property {array} [list=[]] 链接设置
 * @property {string} [logoUrl='//public.gdtimg.com/qzone/biz/gdt/image/ams.svg'] logo url
 * @property {object} [logoAttribute={alt: ''}] logo图片的其他属性
 * @property {string} [value=''] value会与list中对应节点的数据进行匹配，标明是否选中
 * @property {bool} [open] 导航为收起状态时，下拉面板时否展开
 * @property {func} [onOpen] 导航为收起状态时，下拉面板展开回调 onOpen=(event, open)
 * @property {string} [searchValue=''] 搜索值
 * @property {func} [onSearchChange=function(){}] 搜索回调
 * @property {object} [headerProps={}] header props
 * @property {object} [collapseProps={}] collapse props
 * @property {titleline} 自定义组件相关属性
 * @property {func} [LinkComponent] 自定义点击按钮的结构内容
 * @property {func|element} [LogoComponent] logo位置的component
 * @property {func|element} [LogoTitleComponent] 导航标题名称位置的component
 * @example
 * &lt;LinkComponent
 name=''
 className=''
 href=''
 ...{event}
&gt;children&lt;/LinkComponent&gt;
 *
 *
 * &lt;LogoComponent
    href=''
    className=''
    type='logo'
    ...{event}
&gt;children&lt;/LogoComponent&gt;
 *
 *
 * &lt;LogoTitleComponent
    href=''
    className=''
    type='logo-title'
    ...{event}
&gt;children&lt;/LogoTitleComponent&gt;
 */
var config = {
  title: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.element]),
    defaultValue: ''
  },
  rootUrl: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  logoUrl: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: '//public.gdtimg.com/qzone/biz/gdt/image/ams.svg'
  },
  logoAttribute: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {
      alt: ''
    }
  },
  theme: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'dark'
  },
  fixed: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  list: {
    type: _spauiUtils.PropTypes.array,
    defaultValue: []
  },
  value: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.number, _spauiUtils.PropTypes.string]),
    defaultValue: ''
  },
  searchValue: {
    type: _spauiUtils.PropTypes.string
  },
  onSearchChange: {
    type: _spauiUtils.PropTypes.func
  },
  headerProps: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  collapseProps: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  open: {
    type: _spauiUtils.PropTypes.bool
  },
  onOpen: {
    type: _spauiUtils.PropTypes.func
  },
  LinkComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.element, _spauiUtils.PropTypes.func]),
    defaultValue: _LinkComponent["default"]
  },
  LogoComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.element, _spauiUtils.PropTypes.func])
  },
  LogoTitleComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.element, _spauiUtils.PropTypes.func])
  }
}; // 转化成PureComponent组件的配置

exports.config = config;
var configData = (0, _spauiUtils.formatProps)(config); // 导出

var propTypes = configData.propTypes;
exports.propTypes = propTypes;
var defaultProps = configData.defaultProps;
exports.defaultProps = defaultProps;
var _default = {
  config: config,
  propTypes: propTypes,
  defaultProps: defaultProps
};
exports["default"] = _default;