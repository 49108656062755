"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.TabCommon = exports.TabPanel = exports.Tabs = void 0;

var _tabs = _interopRequireDefault(require("./tabs"));

var _tab_panel = _interopRequireDefault(require("./tab_panel"));

var _tab_common = _interopRequireDefault(require("./tab_common"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @author kirkzheng <kirkzheng@tencent.com>
 * @module Table
 */
var Tabs = _tabs["default"];
exports.Tabs = Tabs;
var TabPanel = _tab_panel["default"];
exports.TabPanel = TabPanel;
var TabCommon = _tab_common["default"]; // 调试信息
// if (process.env.NODE_ENV !== 'production') {
//     const {whyDidYouUpdate} = require('why-did-you-update');
//     whyDidYouUpdate(React);
// }

exports.TabCommon = TabCommon;
var _default = {
  Tabs: Tabs,
  TabPanel: TabPanel,
  TabCommon: TabCommon
};
exports["default"] = _default;