"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.removeClassNameForCell = exports.addClassNameForCell = exports.getTableClassName = void 0;

var _classnames = _interopRequireDefault(require("classnames"));

var _spauiUtils = require("@tencent/spaui-utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 与classname相关
 * @author kirkzheng
 */

/**
 * 获取表格的className
 * @private
 */
var getTableClassName = function getTableClassName() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var tableClassName = props.tableClassName,
      striped = props.striped,
      hover = props.hover,
      bordered = props.bordered,
      condensed = props.condensed,
      responsive = props.responsive,
      _props$useOldClass = props.useOldClass,
      useOldClass = _props$useOldClass === void 0 ? true : _props$useOldClass;
  var result = '';
  result = (0, _classnames["default"])(tableClassName, {
    table: useOldClass,
    'table-striped': striped,
    'table-hover': hover,
    'table-bordered': bordered,
    'table-condensed': condensed,
    'table-responsive': responsive
  });
  return result;
}; // 给对应的列所有的单元格加上对应的classname


exports.getTableClassName = getTableClassName;

var addClassNameForCell = function addClassNameForCell(tableWrap, index) {
  var className = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  var tr = [];
  var td = [];
  var i = 0;
  var len = 0;
  var classNameList = [];

  if (tableWrap && className) {
    tr = tableWrap.getElementsByTagName('tr');
    len = tr.length;

    for (; i < len; i++) {
      if (tr[i]) {
        td = tr[i].getElementsByTagName('th');

        if (td.length < 1) {
          td = tr[i].getElementsByTagName('td');
        } // 有可能这一列是被合并的单元格，需要获取真正显示的单元格的索引


        if (td && td[index] && !(0, _spauiUtils.isUndefined)(td[index].dataset.displayindex)) {
          index = td[index].dataset.displayindex;
        }

        if (td && td[index]) {
          classNameList = td[index].className.split(' ') || [];

          if ((0, _spauiUtils.indexOf)(classNameList, className) < 0) {
            td[index].className = "".concat(td[index].className, " ").concat(className);
          }
        }
      }
    }
  }
}; // 给对应的列所有的单元格去除对应的classname


exports.addClassNameForCell = addClassNameForCell;

var removeClassNameForCell = function removeClassNameForCell(tableWrap, index) {
  var className = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';

  var removeClassName = function removeClassName() {
    var node = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    var theClassName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    var result = [];
    var classNameArray = [];

    if (node && theClassName) {
      classNameArray = node.className.split(' ');
      classNameArray.map(function (item) {
        if (item !== theClassName) {
          result.push(item);
        }

        return item;
      });
      node.className = result.join(' ');
    }
  };

  var tr = [];
  var td = [];
  var i = 0;
  var len = 0;

  if (tableWrap && className) {
    tr = tableWrap.getElementsByTagName('tr');
    len = tr.length;

    for (; i < len; i++) {
      if (tr[i]) {
        td = tr[i].getElementsByTagName('th');

        if (td.length < 1) {
          td = tr[i].getElementsByTagName('td');
        } // 有可能这一列是被合并的单元格，需要获取真正显示的单元格的索引


        if (td && td[index] && !(0, _spauiUtils.isUndefined)(td[index].dataset.displayindex)) {
          index = td[index].dataset.displayindex;
        }

        if (td && td[index]) {
          removeClassName(td[index], className);
        }
      }
    }
  }
};

exports.removeClassNameForCell = removeClassNameForCell;
var _default = {
  getTableClassName: getTableClassName,
  addClassNameForCell: addClassNameForCell,
  removeClassNameForCell: removeClassNameForCell
};
exports["default"] = _default;