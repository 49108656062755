"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 兼容参数
 */
// 自定义名称默认字段配置
var defaultFieldNames = {
  labelFieldName: 'label',
  valueFieldName: 'value',
  nodeFieldName: 'list',
  titleFieldName: 'title',
  nameFieldName: 'name'
}; // 兼容props，因为有些名称被废弃了但是要兼容

var _default = function _default() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _props$fieldNames = props.fieldNames,
      fieldNames = _props$fieldNames === void 0 ? {} : _props$fieldNames,
      title = props.title,
      tip = props.tip,
      originValue = props.value,
      _props$single = props.single,
      single = _props$single === void 0 ? false : _props$single,
      _props$data = props.data,
      data = _props$data === void 0 ? [] : _props$data;

  var newFieldNames = _objectSpread({}, defaultFieldNames, {}, fieldNames);

  var value = originValue;

  if (single && _typeof(originValue) !== 'object') {
    var valueFieldName = newFieldNames.valueFieldName;
    value = {};
    data.forEach(function (item) {
      if (item.list) {
        item.list.forEach(function (subItem) {
          if (subItem[valueFieldName] === originValue) {
            value[item.name] = originValue;
          }

          if (subItem.list) {
            subItem.list.forEach(function (finalItem) {
              if (finalItem[valueFieldName] === originValue) {
                value[item.name] = originValue;
              }
            });
          }
        });
      }
    });
  }

  return _objectSpread({}, props, {
    title: title || tip || '',
    fieldNames: newFieldNames,
    value: value
  });
};

exports["default"] = _default;