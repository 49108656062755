"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

// 兼容props，因为有些名称被废弃了但是要兼容
var _default = function _default() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var htmlable = props.htmlable,
      html = props.html,
      clearable = props.clearable,
      clear = props.clear,
      checkallable = props.checkallable,
      checkAll = props.checkAll,
      checkallLabel = props.checkallLabel,
      checkAllLabel = props.checkAllLabel,
      propsMountTarget = props.mountTarget,
      multiple = props.multiple,
      value = props.value,
      _props$requiredValue = props.requiredValue,
      requiredValue = _props$requiredValue === void 0 ? [] : _props$requiredValue,
      _props$data = props.data,
      data = _props$data === void 0 ? [] : _props$data,
      _props$valueFieldName = props.valueFieldName,
      valueFieldName = _props$valueFieldName === void 0 ? '' : _props$valueFieldName;
  var mountTarget = propsMountTarget;
  var newValue = value;
  var newData = data; // mountTarget 如果指定是document.body必须用字符串的形式

  if ((typeof document === "undefined" ? "undefined" : _typeof(document)) === 'object' && document && document.body && document.body === mountTarget) {
    mountTarget = 'body';
  } // 如果有必须项的话就要加上


  if (multiple && (0, _spauiUtils.isArray)(newValue) && requiredValue.length > 0) {
    newValue = (0, _spauiUtils.cloneArray)(newValue);
    requiredValue.forEach(function (item) {
      if (newValue.indexOf(item) < 0) {
        newValue.push(item);
      }
    });
    newData.map(function (item) {
      var haveObjValue = _typeof(item) === 'object' && item && requiredValue.indexOf(item[valueFieldName]) > -1;

      if (requiredValue.indexOf(item) > -1 || haveObjValue) {
        item.disabled = true;
      }

      return item;
    });
  }

  return _objectSpread({}, props, {
    mountTarget: mountTarget,
    value: newValue,
    data: newData,
    html: typeof htmlable !== 'undefined' ? htmlable : html,
    clear: typeof clearable !== 'undefined' ? clearable : clear,
    checkAll: typeof checkallable !== 'undefined' ? checkallable : checkAll,
    checkAllLabel: typeof checkallLabel !== 'undefined' ? checkallLabel : checkAllLabel
  });
};

exports["default"] = _default;