"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.removeResize = exports.addResize = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

// 是否jquery
var haveJQuery = (typeof window === "undefined" ? "undefined" : _typeof(window)) === 'object' && typeof window.jQuery === 'function'; // 添加resize

var addResize = function addResize(resizeCallback) {
  var target = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : window;

  if (target) {
    if (haveJQuery) {
      jQuery(target).on('resize', resizeCallback);
    } else if (_typeof(target) === 'object' && (0, _spauiUtils.isFunction)(target.addEventListener)) {
      target.addEventListener('resize', resizeCallback);
    }
  }
}; // 去除resize


exports.addResize = addResize;

var removeResize = function removeResize(resizeCallback) {
  var target = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : window;

  if (target) {
    if (haveJQuery) {
      jQuery(target).off('resize', resizeCallback);
    } else if (_typeof(target) === 'object' && (0, _spauiUtils.isFunction)(target.removeEventListener)) {
      target.removeEventListener('resize', resizeCallback);
    }
  }
};

exports.removeResize = removeResize;
var _default = {
  addResize: addResize,
  removeResize: removeResize
};
exports["default"] = _default;