"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _spauiUtils = require("@tencent/spaui-utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var _default = function _default() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var EmptyComponent = props.EmptyComponent,
      useFileAPI = props.useFileAPI,
      drag = props.drag,
      _props$useNewUI = props.useNewUI,
      useNewUI = _props$useNewUI === void 0 ? false : _props$useNewUI,
      componentProps = props.componentProps;
  var divProps = {};
  var key = '';

  for (key in props) {
    if (!['EmptyComponent', 'useFileAPI', 'drag', 'useNewUI', 'componentProps'].includes(key)) {
      divProps[key] = props[key];
    }
  }

  if (useNewUI) {
    if ((0, _spauiUtils.isReactNode)(EmptyComponent)) {
      return EmptyComponent;
    }

    return _react["default"].createElement(EmptyComponent, _extends({
      useNewUI: useNewUI
    }, divProps, {
      useFileAPI: useFileAPI,
      drag: drag,
      componentProps: componentProps
    }));
  }

  return _react["default"].createElement("div", _extends({
    className: "uper spaui-uploader-emptycomponent"
  }, divProps), _react["default"].createElement("div", {
    className: "upline"
  }, _react["default"].createElement("label", {
    className: "upfile"
  }, (0, _spauiUtils.isReactNode)(EmptyComponent) ? EmptyComponent : _react["default"].createElement(EmptyComponent, {
    componentProps: componentProps,
    useNewUI: useNewUI,
    useFileAPI: useFileAPI,
    drag: drag
  }))));
};

exports["default"] = _default;