"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultProps = exports.propTypes = exports.config = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件参数配置
 */

/**
 * Tooltip 接口参数
 * @alias TooltipProps
 * @property {titleline} 默认支持参数
 * @property {blankline} className;style;id;data-*;accessKey;dir;draggable;tabIndex;title;htmlRef;
 * @property {titleline} 基础属性
 * @property {string} [title] 提示文字
 * @property {bool} [hideArrow=false] 是否隐藏箭头
 * @property {titleline} 组件属性
 * @property {object} [OverlayTriggerProps={}] OverlayTrigger 组件的 props
 * @property {object} [PopOverProps={}] PopOver 组件的 props
 */
var config = {
  title: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.node, _spauiUtils.PropTypes.string])
  },
  OverlayTriggerProps: {
    type: _spauiUtils.PropTypes.object,
    "default": {}
  },
  PopOverProps: {
    type: _spauiUtils.PropTypes.object,
    "default": {}
  },
  hideArrow: {
    type: _spauiUtils.PropTypes.bool,
    "default": false
  }
}; // 转化成PureComponent组件的配置

exports.config = config;
var configData = (0, _spauiUtils.formatProps)(config); // 导出

var propTypes = configData.propTypes;
exports.propTypes = propTypes;
var defaultProps = configData.defaultProps;
exports.defaultProps = defaultProps;
var _default = {
  config: config,
  propTypes: propTypes,
  defaultProps: defaultProps
};
exports["default"] = _default;