"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultProps = exports.propTypes = exports.config = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

var _link_component = _interopRequireDefault(require("./component/link_component"));

var _sep_component = _interopRequireDefault(require("./component/sep_component"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件参数配置
 */

/**
 * Breadcrumb 接口参数
 * @alias BreadcrumbProps
 * @property {titleline} 默认支持参数
 * @property {blankline} className;style;id;data-*;accessKey;dir;draggable;tabIndex;title;htmlRef;
 * @property {titleline} 基础属性
 * @property {string} [baseClassName] 基础类名
 * @property {string} [itemClassName] item类名
 * @property {string|element} [prefix] 前置内容
 * @property {string|element} [suffix] 后置内容
 * @property {func} [onClick] 单击事件*(value, data) => {}*
 * @property {string|element} [LinkComponent] 自定义链接组件
 * @property {string|element} [SepComponent] 自定义分隔组件
 * @property {string|element} [separator] 自定义分隔号
 * @property {array} [data] 数据*支持icon|disabled|unLink|className字段*
 */
var config = {
  data: {
    type: _spauiUtils.PropTypes.array.isRequired,
    defaultValue: []
  },
  baseClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'spaui-breadcrumb'
  },
  itemClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'spaui-breadcrumb-item'
  },
  prefix: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.node, _spauiUtils.PropTypes.element])
  },
  suffix: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.node, _spauiUtils.PropTypes.element])
  },
  onClick: {
    type: _spauiUtils.PropTypes.func
  },
  LinkComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _link_component["default"]
  },
  SepComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _sep_component["default"]
  },
  separator: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element, _spauiUtils.PropTypes.string]),
    defaultValue: null
  }
}; // 转化成PureComponent组件的配置

exports.config = config;
var configData = (0, _spauiUtils.formatProps)(config); // 导出

var propTypes = configData.propTypes;
exports.propTypes = propTypes;
var defaultProps = configData.defaultProps;
exports.defaultProps = defaultProps;
var _default = {
  config: config,
  propTypes: propTypes,
  defaultProps: defaultProps
};
exports["default"] = _default;