"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 筛选函数
 */

/**
 *
 * @param {*} data 一个选项的数据
 * @param {String} value 搜索词
 * @param {Function} convertPYs 拼音转化函数(@tencent/spa-pinyin方法)
 * @returns {Boolean} 是否满足筛选
 */
var _default = function _default(data, value, convertPYs) {
  var props = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  var _props$fieldNames = props.fieldNames,
      fieldNames = _props$fieldNames === void 0 ? {} : _props$fieldNames;
  var _fieldNames$labelFiel = fieldNames.labelFieldName,
      labelFieldName = _fieldNames$labelFiel === void 0 ? 'label' : _fieldNames$labelFiel;
  var label = ((0, _spauiUtils.isObject)(data) ? data[labelFieldName] || '' : data).toString();
  var reg = /^[a-zA-Z\s]*$/gi; // 只有纯拼音或者空才能够进行拼音搜索

  var result = false;
  var valuePinyin = '';
  var labelPinyin = '';
  var pinyinMatch = false;

  if (label && value) {
    // 转化成拼音
    if (convertPYs && reg.test(value)) {
      labelPinyin = convertPYs(label)[0];
      valuePinyin = convertPYs(value.toString())[0];

      if (labelPinyin && labelPinyin.indexOf(valuePinyin) > -1) {
        pinyinMatch = true;
      }
    }

    if (label.indexOf(value) > -1 || pinyinMatch) {
      result = true;
    }
  }

  return result;
};

exports["default"] = _default;