"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件对应结构的默认className
 */
var _default = {
  Select: 'spaui-select',
  SelectOpenByComponentClassName: 'spaui-select-open-by-component',
  SelectOpenClassName: 'spaui-select-open',
  SelectContainerClassName: 'selection-container selection-container-single',
  SelectContainerOpenClassName: 'selection-with-drop onseting',
  SelectContainerWithTipsClassName: 'with-tips',
  SelectContainerWithSearchLoadingClassName: 'with-search-loading',
  SelectContainerWithSearchClassName: 'with-search',
  SelectContainerWithLabelsClassName: 'with-labels',
  SelectContainerWithTagsClassName: 'with-tags',
  SelectValueHolderClassName: 'selection-single',
  SelectValueHolderClearClassName: 'spaui-select-clearable',
  SelectDropTopClassName: 'drop-top',
  SelectDropClassName: 'selection-drop',
  SelectDropMultipleClassName: 'with-addon',
  SelectDropMoreInfoClassName: 'with-more-col',
  SelectDropWithImgClassName: 'with-icon',
  SelectDropWithTimeClassName: 'with-time',
  SelectDataClassName: 'selection-results',
  SelectItemClassName: 'selection-info',
  SelectCheckallItemClassName: 'selection-info-checkall',
  SelectSearchClassName: 'selection-search'
};
exports["default"] = _default;