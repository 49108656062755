"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultProps = exports.propTypes = exports.config = exports.buttonPropsDefaultData = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件参数配置
 */
// 默认配置
var buttonPropsDefaultData = {
  OverlayTriggerProps: {
    trigger: 'hover',
    hideDelayTime: 0,
    showDelayTime: 150
  }
};
/**
 * Button 接口参数
 * @alias ButtonProps
 * @description 按钮用于开始一个即时操作。
 * @property {titleline} 默认支持参数
 * @property {blankline} className;style;id;data-*;accessKey;dir;draggable;tabIndex;title;htmlRef;
 * @property {titleline} 基础属性
 * @property {string} [displayType='default'] 按钮展示类型*可选值: default, primary, info, warning, link, text, dashed*
 * @property {string} [size] 尺寸*可选值: default, small, large, extralarge*
 * @property {bool} [active=false] 是否激活
 * @property {bool} [disabled=false] 是否禁用
 * @property {bool} [loading=false] 是否加载中*displayType为默认样式时不支持*
 * @property {bool} [show=true] 是否展示
 * @property {func|element} [LoadingComponent] loading结构组件
 * @property {bool} [dropdown=false] 是否是下拉箭头
 * @property {string} [type='button'] 按钮类型，可用于原生的type='submit'
 * @property {string|element|func} [tagType='button'] 标签类型
 * @property {array} [supportEvents] 按钮支持的响应事件（默认是都支持）<br />按钮支持的事件，比如supportEvents=['onClick', 'onMouseDown']
 * @property {titleline} 链接属性
 * @property {string} [href] 跳转链接
 * @property {string} [target='_self'] href有值时打开窗口类型
 * @property {titleline} 提示属性
 * @property {string|element} [tip=''] hover提示
 * @property {element} [tipTarget=null] hover的触发对象*（为空时就是以弹框为对象）*
 * @property {string} [tipSequence='last'] hover的触发对象的位置*可选值: first, last*
 * @property {string} [withTipClassName='with-help-tip'] tip展示时button会添加的className
 * @property {object} [OverlayTriggerProps={trigger: 'hover',delay: 1000}] tip使用的是spaui-popover，这里可以配置对应的trigger参数
 * @property {object} [popoverProps] tip使用的是 spaui-popover，这里可以配置对应的 popover 参数
 * @property {string} [baseClass='spaui-button'] 基础className
 * @property {bool} [useBaseClass=true] 是否默认带上baseClass
 * @property {titleline} 其他属性
 * @property {func|element} [icon] 图标
 * @property {func|element} [endIcon] 右图标
 * @property {bool} [isCompound=false] 是否为复合型按钮，若为true将没有height、padding等限制
 * @property {string|number} [width] 宽度
 * @property {string|number} [height] 高度
 * @property {string|number} [padding] 内边距
 * @property {string|number} [radius] 圆角
 * @property {bool} [hasSpan=false] 文案是否有span标签包裹
 */

exports.buttonPropsDefaultData = buttonPropsDefaultData;
var config = {
  displayType: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'default',
    visualData: {
      type: 'radio',
      description: '按钮展示类型',
      value: ['default', 'primary', 'info', 'warning', 'link', 'text', 'dashed']
    }
  },
  size: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'default',
    visualData: {
      type: 'radio',
      description: '尺寸',
      value: ['default', 'small', 'large', 'extralarge']
    }
  },
  href: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: '',
    visualData: {
      type: 'input',
      description: '链接'
    }
  },
  target: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: '_self',
    visualData: {
      setType: function setType(stateData) {
        return stateData.href ? 'radio' : '';
      },
      value: ['_self', '_blank'],
      description: '链接的打开方式'
    }
  },
  active: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false,
    visualData: {
      type: 'switch',
      description: '是否激活'
    }
  },
  disabled: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false,
    visualData: {
      type: 'switch',
      description: '是否禁用'
    }
  },
  tip: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.element, _spauiUtils.PropTypes.string]),
    defaultValue: '',
    visualData: {
      type: 'input',
      description: 'hover提示'
    }
  },
  tipTarget: {
    type: _spauiUtils.PropTypes.any,
    defaultValue: null
  },
  tipSequence: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'last',
    visualData: {
      type: 'radio',
      description: 'tip位置',
      value: ['last', 'first']
    }
  },
  withTipClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'with-help-tip'
  },
  supportEvents: {
    type: _spauiUtils.PropTypes.array,
    visualData: {
      type: 'array',
      value: ['onClick', 'onMouseOver', 'onMouseOut', 'onMouseEnter', 'onMouseLeave', 'onMouseUp'],
      tip: '(使用“,”分割数据)',
      description: '按钮支持的响应事件'
    }
  },
  OverlayTriggerProps: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: buttonPropsDefaultData.OverlayTriggerProps,
    visualData: {
      type: 'object',
      tip: '(使用JSON格式)',
      description: 'tip的浮层设置'
    }
  },
  popoverProps: {
    type: _spauiUtils.PropTypes.object
  },
  block: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false,
    visualData: {
      type: 'switch',
      description: '是否块状'
    }
  },
  loading: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false,
    visualData: {
      type: 'switch',
      description: '是否加载中'
    }
  },
  show: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  dropdown: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false,
    visualData: {
      type: 'switch',
      description: '是否下拉箭头按钮'
    }
  },
  onClick: {
    type: _spauiUtils.PropTypes.func
  },
  tagType: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element, _spauiUtils.PropTypes.string]),
    defaultValue: 'button'
  },
  type: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'button',
    visualData: {
      type: 'radio',
      value: ['button', 'submit'],
      description: '按钮type类型'
    }
  },
  baseClass: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'spaui-button'
  },
  useBaseClass: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  icon: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element])
  },
  endIcon: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element])
  },
  isCompound: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false,
    visualData: {
      type: 'switch',
      description: '是否为复合型按钮'
    }
  },
  width: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number])
  },
  height: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number])
  },
  padding: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number])
  },
  radius: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number])
  },
  hasSpan: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  }
}; // 转化成PureComponent组件的配置

exports.config = config;
var configData = (0, _spauiUtils.formatProps)(config); // 导出

var propTypes = configData.propTypes;
exports.propTypes = propTypes;
var defaultProps = configData.defaultProps;
exports.defaultProps = defaultProps;
var _default = {
  buttonPropsDefaultData: buttonPropsDefaultData,
  config: config,
  propTypes: propTypes,
  defaultProps: defaultProps
};
exports["default"] = _default;