// 获取页面全局变量的函数
const getWindowVariable = (varName = '') => {
  const varData = typeof window === 'object' && varName ? window[varName] : '';
  let result = null;
  if (
    typeof varData === 'object' && varData && typeof varData.default !== 'undefined'
  ) {
    result = window[varName].default;
  }
  return result;
};

let exportTemp = null;

import versionInfo from './info';

import _List from '@tencent/spaui-list';
export const List = getWindowVariable('SPAUI_LIST') || _List;

import _Breadcrumb from '@tencent/spaui-breadcrumb';
export const Breadcrumb = getWindowVariable('SPAUI_BREADCRUMB') || _Breadcrumb;

import _Drawer from '@tencent/spaui-drawer';
export const Drawer = getWindowVariable('SPAUI_DRAWER') || _Drawer;

import _Transition from '@tencent/od-animate';
/**
 * @deprecated 请使用 rc-motion 的 CSSMotion 代替
 */
export const Transition = getWindowVariable('SPAUI_TRANSITION') || _Transition;

import _Space from '@tencent/spaui-space';
export const Space = getWindowVariable('SPAUI_SPACE') || _Space;

import _Alert from '@tencent/spaui-alert';
export const Alert = getWindowVariable('SPAUI_ALERT') || _Alert;

import _AutoComplete from '@tencent/spaui-autocomplete';
export const AutoComplete = getWindowVariable('SPAUI_AUTOCOMPLETE') || _AutoComplete;

import _BlockSelector from '@tencent/spaui-blockselector';
export const BlockSelector = getWindowVariable('SPAUI_BLOCKSELECTOR') || _BlockSelector;

import _Button from '@tencent/spaui-button';
export const Button = getWindowVariable('SPAUI_BUTTON') || _Button;

import _ButtonGroup from '@tencent/spaui-buttongroup';
export const ButtonGroup = getWindowVariable('SPAUI_BUTTONGROUP') || _ButtonGroup;

import _Carousel from '@tencent/spaui-carousel';
export const Carousel = getWindowVariable('SPAUI_CAROUSEL') || _Carousel;

import _Chart from '@tencent/spaui-chart';
export const Chart = getWindowVariable('SPAUI_CHART') || _Chart;

import _Checkbox from '@tencent/spaui-checkbox';
export const Checkbox = getWindowVariable('SPAUI_CHECKBOX') || _Checkbox;

import _CheckboxGroup from '@tencent/spaui-checkboxgroup';
export const CheckboxGroup = getWindowVariable('SPAUI_CHECKBOXGROUP') || _CheckboxGroup;

import _Clipper from '@tencent/spaui-clipper';
export const Clipper = getWindowVariable('SPAUI_CLIPPER') || _Clipper;

import _Coder from '@tencent/spaui-coder';
export const Coder = getWindowVariable('SPAUI_CODER') || _Coder;

import _Collapse from '@tencent/spaui-collapse';
export const Collapse = getWindowVariable('SPAUI_COLLAPSE') || _Collapse;

import _DatePicker from '@tencent/spaui-datepicker';
export const DatePicker = getWindowVariable('SPAUI_DATEPICKER') || _DatePicker;

import _Dialog from '@tencent/spaui-dialog';
export const Dialog = getWindowVariable('SPAUI_DIALOG') || _Dialog;

import _Dropdown from '@tencent/spaui-dropdown';
const SPAUI_DROPDOWN = getWindowVariable('SPAUI_DROPDOWN') || _Dropdown;
exportTemp = SPAUI_DROPDOWN.Dropdown;
exportTemp.Dropdown = SPAUI_DROPDOWN.Dropdown;
exportTemp.DropdownToggle = SPAUI_DROPDOWN.DropdownToggle;
exportTemp.DropdownContent = SPAUI_DROPDOWN.DropdownContent;
export const Dropdown = exportTemp;
export const { DropdownToggle } = SPAUI_DROPDOWN;
export const { DropdownContent } = SPAUI_DROPDOWN;

import _DropMenu from '@tencent/spaui-dropmenu';
const SPAUI_DROPMENU = getWindowVariable('SPAUI_DROPMENU') || _DropMenu;
exportTemp = SPAUI_DROPMENU.DropMenu;
exportTemp.DropMenu = SPAUI_DROPMENU.DropMenu;
exportTemp.DropMenuTrigger = SPAUI_DROPMENU.DropMenuTrigger;
export const DropMenu = exportTemp;
export const { DropMenuTrigger } = SPAUI_DROPMENU;

import _Echarts from '@tencent/spaui-echarts';
export const Echarts = getWindowVariable('SPAUI_ECHARTS') || _Echarts;

import _FormGroup from '@tencent/spaui-formgroup';
export const FormGroup = getWindowVariable('SPAUI_FORMGROUP') || _FormGroup;

import _Icon from '@tencent/spaui-icon';
export const Icon = getWindowVariable('SPAUI_ICON') || _Icon;

import _Input from '@tencent/spaui-input';
export const Input = getWindowVariable('SPAUI_INPUT') || _Input;

import _KeyDay from '@tencent/spaui-keyday';
export const KeyDay = getWindowVariable('SPAUI_KEYDAY') || _KeyDay;

import _Labels from '@tencent/spaui-labels';
export const Labels = getWindowVariable('SPAUI_LABELS') || _Labels;

import _Loading from '@tencent/spaui-loading';
export const Loading = getWindowVariable('SPAUI_LOADING') || _Loading;

import _Menu from '@tencent/spaui-menu';
export const Menu = getWindowVariable('SPAUI_MENU') || _Menu;

import _PageBar from '@tencent/spaui-pagebar';
export const PageBar = getWindowVariable('SPAUI_PAGEBAR') || _PageBar;

import _PopOver from '@tencent/spaui-popover';
const SPAUI_POPOVER = getWindowVariable('SPAUI_POPOVER') || _PopOver;
exportTemp = SPAUI_POPOVER.PopOver;
exportTemp.PopOver = SPAUI_POPOVER.PopOver;
exportTemp.Popover = SPAUI_POPOVER.PopOver;
exportTemp.OverlayTrigger = SPAUI_POPOVER.OverlayTrigger;
export const PopOver = exportTemp;
export const Popover = exportTemp;
export const { OverlayTrigger } = SPAUI_POPOVER;

import _Portal from '@tencent/spaui-portal';
export const Portal = getWindowVariable('SPAUI_PORTAL') || _Portal;

import _Radio from '@tencent/spaui-radio';
export const Radio = getWindowVariable('SPAUI_RADIO') || _Radio;

import _RadioGroup from '@tencent/spaui-radiogroup';
export const RadioGroup = getWindowVariable('SPAUI_RADIOGROUP') || _RadioGroup;

import _Rating from '@tencent/spaui-rating';
export const Rating = getWindowVariable('SPAUI_RATING') || _Rating;

import _Select from '@tencent/spaui-select';
export const Select = getWindowVariable('SPAUI_SELECT') || _Select;

import _Slider from '@tencent/spaui-slider';
export const Slider = getWindowVariable('SPAUI_SLIDER') || _Slider;

import _Switch from '@tencent/spaui-switch';
export const Switch = getWindowVariable('SPAUI_SWITCH') || _Switch;

import _Table from '@tencent/spaui-table';
const SPAUI_TABLE = getWindowVariable('SPAUI_TABLE') || _Table;
exportTemp = SPAUI_TABLE.Table;
exportTemp.Table = SPAUI_TABLE.Table;
exportTemp.Column = SPAUI_TABLE.Column;
export const Table = exportTemp;
export const { Column } = SPAUI_TABLE;

import _Tabs from '@tencent/spaui-tabs';
const SPAUI_TABS = getWindowVariable('SPAUI_TABS') || _Tabs;
exportTemp = SPAUI_TABS.Tabs;
exportTemp.Tabs = SPAUI_TABS.Tabs;
exportTemp.TabPanel = SPAUI_TABS.TabPanel;
exportTemp.TabCommon = SPAUI_TABS.TabCommon;
export const Tabs = exportTemp;
export const { TabPanel } = SPAUI_TABS;
export const { TabCommon } = SPAUI_TABS;

import _Task from '@tencent/spaui-task';
export const Task = getWindowVariable('SPAUI_TASK') || _Task;

import _TimePicker from '@tencent/spaui-timepicker';
export const TimePicker = getWindowVariable('SPAUI_TIMEPICKER') || _TimePicker;

import _TimeRoutineSelector from '@tencent/spaui-timeroutineselector';
export const TimeRoutineSelector = getWindowVariable('SPAUI_TIMEROUTINESELECTOR') || _TimeRoutineSelector;

import _TopNav from '@tencent/spaui-topnav';
export const TopNav = getWindowVariable('SPAUI_TOPNAV') || _TopNav;

import _SelectTree from '@tencent/spaui-select-tree';
export const SelectTree = getWindowVariable('SPAUI_SELECT_TREE') || _SelectTree;

import _Tree from '@tencent/spaui-tree';
const SPAUI_TREE = getWindowVariable('SPAUI_TREE') || _Tree;
exportTemp = SPAUI_TREE.Tree;
exportTemp.Tree = SPAUI_TREE.Tree;
exportTemp.CascadeTree = SPAUI_TREE.CascadeTree;
export const Tree = exportTemp;
export const { CascadeTree } = SPAUI_TREE;

import _Uploader from '@tencent/spaui-uploader';
const SPAUI_UPLOADER = getWindowVariable('SPAUI_UPLOADER') || _Uploader;
exportTemp = SPAUI_UPLOADER.Uploader;
exportTemp.Uploader = SPAUI_UPLOADER.Uploader;
exportTemp.ImageUploader = SPAUI_UPLOADER.ImageUploader;
exportTemp.FileUploader = SPAUI_UPLOADER.FileUploader;
exportTemp.MediaUploader = SPAUI_UPLOADER.MediaUploader;
export const Uploader = exportTemp;
export const { ImageUploader } = SPAUI_UPLOADER;
export const { FileUploader } = SPAUI_UPLOADER;
export const { MediaUploader } = SPAUI_UPLOADER;

import _UserPicker from '@tencent/spaui-userpicker';
const SPAUI_USERPICKER = getWindowVariable('SPAUI_USERPICKER') || _UserPicker;
exportTemp = SPAUI_USERPICKER.UserPicker;
exportTemp.UserPicker = SPAUI_USERPICKER.UserPicker;
exportTemp.ImportUser = SPAUI_USERPICKER.ImportUser;
exportTemp.Picker = SPAUI_USERPICKER.Picker;
exportTemp.Import = SPAUI_USERPICKER.Import;
export const UserPicker = exportTemp;
export const { ImportUser } = SPAUI_USERPICKER;
export const { Picker } = SPAUI_USERPICKER;
export const { Import } = SPAUI_USERPICKER;

import _Viewer from '@tencent/spaui-viewer';
export const Viewer = getWindowVariable('SPAUI_VIEWER') || _Viewer;

import _WaterMark from '@tencent/spaui-watermark';
export const WaterMark = getWindowVariable('SPAUI_WATERMARK') || _WaterMark;

import _Tooltip from '@tencent/spaui-tooltip';
export const Tooltip = getWindowVariable('SPAUI_TOOLTIP') || _Tooltip;

import _message from '@tencent/spaui-message';
export const message = getWindowVariable('SPAUI_MESSAGE') || _message;

import _Empty from '@tencent/spaui-empty';
export const Empty = getWindowVariable('SPAUI_EMPTY') || _Empty;

import _Badge from '@tencent/spaui-badge';
export const Badge = getWindowVariable('SPAUI_BADGE') || _Badge;

// WaterMark名称调整为Watermark
export const Watermark = WaterMark;

import { report } from '@tencent/spaui-utils';
const { componentUsageReporting, cancelComponentSignReport } = report;
typeof cancelComponentSignReport !== 'undefined' && cancelComponentSignReport();
typeof componentUsageReporting !== 'undefined' && componentUsageReporting({
  scope: 'spaui',
  name: 'spaui',
  version: versionInfo.version,
});
typeof componentUsageReporting !== 'undefined' && componentUsageReporting({
  scope: 'spaui',
  name: 'react',
  version: window.React ? window.React.version : '0',
});

export const { version } = versionInfo;

const OLD_SPAUI = typeof window === 'object' && window && window.SPAUI;
export const noConflict = () => {
  if (typeof window === 'object' && window && window.SPAUI) {
    window.SPAUI = OLD_SPAUI;
  }
  return {
    List,
    Breadcrumb,
    Drawer,
    Transition,
    Alert,
    Space,
    Tooltip,
    message,
    Empty,
    Badge,
    AutoComplete,
    BlockSelector,
    Button,
    ButtonGroup,
    Carousel,
    Chart,
    Checkbox,
    CheckboxGroup,
    Clipper,
    Coder,
    Collapse,
    DatePicker,
    Dialog,
    Dropdown,
    DropdownToggle,
    DropdownContent,
    DropMenu,
    DropMenuTrigger,
    Echarts,
    FormGroup,
    Icon,
    Input,
    KeyDay,
    Labels,
    Loading,
    Menu,
    PageBar,
    PopOver,
    Popover,
    OverlayTrigger,
    Portal,
    Radio,
    RadioGroup,
    Rating,
    Select,
    Slider,
    Switch,
    Table,
    Column,
    Tabs,
    TabPanel,
    TabCommon,
    Task,
    TimePicker,
    TimeRoutineSelector,
    TopNav,
    Tree,
    CascadeTree,
    Uploader,
    ImageUploader,
    FileUploader,
    MediaUploader,
    UserPicker,
    ImportUser,
    Picker,
    Import,
    Viewer,
    WaterMark,
    version,
    Watermark,
    SelectTree,
  };
};

export default {
  List,
  Breadcrumb,
  Drawer,
  Alert,
  Space,
  Transition,
  Tooltip,
  message,
  Empty,
  Badge,
  AutoComplete,
  BlockSelector,
  Button,
  ButtonGroup,
  Carousel,
  Chart,
  Checkbox,
  CheckboxGroup,
  Clipper,
  Coder,
  Collapse,
  DatePicker,
  Dialog,
  Dropdown,
  DropdownToggle,
  DropdownContent,
  DropMenu,
  DropMenuTrigger,
  Echarts,
  FormGroup,
  Icon,
  Input,
  KeyDay,
  Labels,
  Loading,
  Menu,
  PageBar,
  PopOver,
  Popover,
  OverlayTrigger,
  Portal,
  Radio,
  RadioGroup,
  Rating,
  Select,
  Slider,
  Switch,
  Table,
  Column,
  Tabs,
  TabPanel,
  TabCommon,
  Task,
  TimePicker,
  TimeRoutineSelector,
  TopNav,
  Tree,
  CascadeTree,
  Uploader,
  ImageUploader,
  FileUploader,
  MediaUploader,
  UserPicker,
  ImportUser,
  Picker,
  Import,
  Viewer,
  WaterMark,
  version,
  Watermark,
  noConflict,
  SelectTree,
};
