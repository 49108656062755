"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultProps = exports.propTypes = exports.config = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件参数配置
 */

/**
 * PopOver 接口参数
 * @alias PopOverProps
 * @description 点击/鼠标移入元素，弹出气泡式的卡片浮层。
 * @property {titleline} 默认支持参数
 * @property {blankline} className;style;id;data-*;accessKey;dir;draggable;tabIndex;title;htmlRef;
 * @property {titleline} 基本属性
 * @property {string} [placement='top'] 位置
 * @property {number} [positionLeft=-9999] left位置
 * @property {number} [positionTop=-9999] top位置
 * @property {object} [positionTarget] 可以指定位置的对象(原生dom结构，指定了就会忽略positionLeft, positionTop)
 * @property {string} [title=''] 标题
 * @property {number} [gap=20] 浮层碰到左右两边的间距
 * @property {bool} [show=true] 是否展示
 * @property {object} [arrowStyle={}] 箭头的style样式
 * @property {bool} [hideArrow=false] 是否隐藏箭头
 * @property {string|number} [maxWidth] 最大宽度
 * @property {string} [transitionName='spaui-popover'] 自定义动画名称（需要设置对应的CSS, eg: **-enter/appear/leave）
 */
var config = {
  placement: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'top'
  },
  topOfOverlay: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: -9999
  },
  leftOfOverlay: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: -9999
  },
  positionTarget: {
    type: _spauiUtils.PropTypes.object
  },
  title: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  gap: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: 20
  },
  show: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  arrowStyle: {
    type: _spauiUtils.PropTypes.object
  },
  hideArrow: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  transitionName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'spaui-popover'
  },
  className: {
    type: _spauiUtils.PropTypes.string,
    visualData: {
      type: 'input'
    }
  },
  style: {
    type: _spauiUtils.PropTypes.object,
    visualData: {
      type: 'object'
    }
  },
  id: {
    type: _spauiUtils.PropTypes.string,
    visualData: {
      type: 'input'
    }
  },
  maxWidth: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number]),
    defaultValue: null
  }
}; // 转化成PureComponent组件的配置

exports.config = config;
var configData = (0, _spauiUtils.formatProps)(config); // 导出

var propTypes = configData.propTypes;
exports.propTypes = propTypes;
var defaultProps = configData.defaultProps;
exports.defaultProps = defaultProps;
var _default = {
  config: config,
  propTypes: propTypes,
  defaultProps: defaultProps
};
exports["default"] = _default;