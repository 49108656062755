"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.nextAnimationFrame = exports.getBoundingClientRect = exports.genDataMap = exports.getRowKey = exports.haveJQuery = exports.indexOfInTitleOptions = exports.indexOfInExpandOptions = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

// 在展开项中的顺序
var indexOfInExpandOptions = function indexOfInExpandOptions() {
  var expandOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var result = -1;
  expandOptions.map(function (item, index) {
    var key = '';
    var isEqual = true;

    if (result < 0) {
      // 必须每个值都完全匹配
      for (key in item) {
        // _x为保留属性用于控制是否隐藏，所以_x不加入属性判断
        if (key.slice(0, 1) !== '_' && item[key] !== data[key]) {
          isEqual = false;
        }
      }

      if (isEqual) {
        result = index;
      }
    }

    return item;
  });
  return result;
}; // 在标题选项中


exports.indexOfInExpandOptions = indexOfInExpandOptions;

var indexOfInTitleOptions = function indexOfInTitleOptions() {
  var titleOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var result = -1;
  titleOptions.map(function () {
    var titleConfig = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var index = arguments.length > 1 ? arguments[1] : undefined;
    var item = titleConfig.match || {};
    var key = '';
    var isEqual = true;

    if (result < 0) {
      // 必须每个值都完全匹配
      for (key in item) {
        // _x为保留属性用于控制是否隐藏，所以_x不加入属性判断
        if (key.slice(0, 1) !== '_' && item[key] !== data[key]) {
          isEqual = false;
        }
      }

      if (isEqual) {
        result = index;
      }
    }

    return titleConfig;
  });
  return result;
};

exports.indexOfInTitleOptions = indexOfInTitleOptions;
var haveJQuery = (typeof window === "undefined" ? "undefined" : _typeof(window)) === 'object' && typeof window.jQuery === 'function'; // 获取对应行的key

exports.haveJQuery = haveJQuery;

var getRowKey = function getRowKey() {
  var rowData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var rowKey = arguments.length > 1 ? arguments[1] : undefined;
  var result = '';

  if (_typeof(rowData) === 'object' && rowData && typeof rowKey !== 'undefined') {
    if ((0, _spauiUtils.isArray)(rowKey)) {
      result = [];
      rowKey.map(function (key) {
        if (typeof rowData[key] !== 'undefined') {
          result.push(rowData[key]);
        }

        return key;
      });
      result = result.join(',');
    } else if (typeof rowData[rowKey] !== 'undefined') {
      result = rowData[rowKey];
    }
  }

  return result;
};
/**
 * 将表格数据转换成map的形式
 * @param {string} key 唯一的字段名称
 * @param {array} dataList 表格的data
 * @param {array|string} filterValue 设置返回的 map 中仅有的 key
 */


exports.getRowKey = getRowKey;

var genDataMap = function genDataMap() {
  var key = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var dataList = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var filterValue = arguments.length > 2 ? arguments[2] : undefined;

  if (!(0, _spauiUtils.isArray)(dataList)) {
    return {};
  }

  var dataMap = {};
  var filter = [].concat(filterValue);
  dataList.forEach(function (item) {
    if ((0, _spauiUtils.isUndefined)(filterValue) || filter.includes(item[key])) {
      dataMap[item[key]] = item;
    }
  });
  return dataMap;
};
/**
 * 获取元素相对于视口的位置（添加了报错处理，修复了在 IE <= 11 下，getBoundingClientRect 对于在 DOM 外部元素调用时会报  unspecified error 的问题）
 * https://docs.w3cub.com/browser_support_tables/getboundingclientrect/
 * @param {*} element
 */


exports.genDataMap = genDataMap;

var getBoundingClientRect = function getBoundingClientRect(element) {
  try {
    return element.getBoundingClientRect() || {};
  } catch (_error) {
    return {};
  }
};

exports.getBoundingClientRect = getBoundingClientRect;

var nextAnimationFrame = function nextAnimationFrame(callback) {
  if (window.requestAnimationFrame) {
    window.requestAnimationFrame(callback);
  } else {
    setTimeout(callback, 16);
  }
};

exports.nextAnimationFrame = nextAnimationFrame;
var _default = {
  indexOfInExpandOptions: indexOfInExpandOptions,
  indexOfInTitleOptions: indexOfInTitleOptions,
  haveJQuery: haveJQuery,
  getRowKey: getRowKey,
  getBoundingClientRect: getBoundingClientRect,
  nextAnimationFrame: nextAnimationFrame
};
exports["default"] = _default;