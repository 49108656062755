"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultProps = exports.propTypes = exports.config = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件参数配置
 */

/**
 * Input 接口参数
 * @alias InputProps
 * @description 通过鼠标或键盘输入字符，提供不同尺寸。
 * @property {titleline} 默认支持参数
 * @property {blankline} className;style;id;data-*;accessKey;dir;draggable;tabIndex;title;htmlRef;
 * @property {string} [className=''] className='without-spin-button'为type=number时去掉右侧的上下按钮
 * @property {titleline} 普通输入框属性
 * @property {string|number|bool} value='' 值
 * @property {func} [onChange] 切换状态回调函数<br />onChange = (event){}
 * @property {func} [onPressEnter] 按下回车的回调<br />onPressEnter = (value){}
 * @property {func} [onFocus] 聚焦回调函数
 * @property {func} [onBlur] 失焦回调函数
 * @property {string} [name=''] 字段名
 * @property {string} [placeholder=''] 占位符提示
 * @property {bool} [disabled=false] 是否禁用
 * @property {string} [size='default'] 尺寸, *可选值: small, large, mini, extralarge, default*
 * @property {string} [type='text'] 类型，type=number的时候只能输入数字以及一个'.'
 * @property {string} [status='normal'] 提示状态*可选值: success, error, normal*
 * @property {string|element|func} [tagType='input'] 标签类型
 * @property {titleline} 限制输入字符长度
 * @property {number} [maxLength] 最大长度
 * @property {func} [truncate] 自定义截断方法
 * @property {bool} [useComponsition=true] 开启Componsition事件用于处理中文输入法<br />(开启后只有中文输入完才响应onChange)
 * @property {bool} [borderless=false] 是否无边框
 * @property {bool} [underlined=false] 是否为单线输入框
 * @property {string} [wrapperClassName] wrapper 容器 className
 * @property {string|element} [prefix] 前缀
 * @property {string|element} [suffix] 后缀
 * @property {titleline} CSS 属性
 * @property {string|number} [width] 宽度
 * @property {string|number} [height] 高度
 * @property {string|number} [radius] 圆角
 * @see
 * type 可选值:
 *   text, number, digit, url, button, checkbox, color, date, datetime-local, email
 *
 * ====
 *
 * onChange typings:
 *   (event: any, value: string) => any
 */
var config = {
  value: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.number, _spauiUtils.PropTypes.string, _spauiUtils.PropTypes.bool]).isRequired,
    defaultValue: ''
  },
  onChange: {
    type: _spauiUtils.PropTypes.func
  },
  onPressEnter: {
    type: _spauiUtils.PropTypes.func
  },
  onFocus: {
    type: _spauiUtils.PropTypes.func
  },
  onBlur: {
    type: _spauiUtils.PropTypes.func
  },
  onInput: {
    type: _spauiUtils.PropTypes.func
  },
  name: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  placeholder: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  disabled: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  readOnly: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  size: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'default'
  },
  type: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'text'
  },
  status: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'normal'
  },
  maxLength: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.number, _spauiUtils.PropTypes.string])
  },
  calculateLength: {
    type: _spauiUtils.PropTypes.func,
    defaultValue: function defaultValue() {
      var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var result = 0;

      if ((value || typeof value === 'number') && typeof value.length !== 'undefined') {
        result = value.length;
      }

      return result;
    }
  },
  slice: {
    type: _spauiUtils.PropTypes.func,
    defaultValue: function defaultValue() {
      var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var maxLength = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      var result = value;

      if (maxLength > 0) {
        result = value.slice(0, maxLength);
      }

      return result;
    }
  },
  truncate: {
    type: _spauiUtils.PropTypes.func
  },
  useComponsition: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  tagType: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element, _spauiUtils.PropTypes.string]),
    defaultValue: 'input'
  },
  borderless: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  underlined: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  prefix: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.element, _spauiUtils.PropTypes.string])
  },
  suffix: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.element, _spauiUtils.PropTypes.string])
  },
  width: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number])
  },
  height: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number])
  },
  radius: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number])
  },
  wrapperClassName: {
    type: _spauiUtils.PropTypes.string
  }
}; // 转化成PureComponent组件的配置

exports.config = config;
var configData = (0, _spauiUtils.formatProps)(config); // 导出

var propTypes = configData.propTypes;
exports.propTypes = propTypes;
var defaultProps = configData.defaultProps;
exports.defaultProps = defaultProps;
var _default = {
  config: config,
  propTypes: propTypes,
  defaultProps: defaultProps
};
exports["default"] = _default;