"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.FixedColumnBorder = exports.percentReg = exports.tableScrollClassName = exports.tableFixedTheadClassName = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
// 固定thead时添加的classname
var tableFixedTheadClassName = 'spaui-table-fixed-thead'; // 滚动条className

exports.tableFixedTheadClassName = tableFixedTheadClassName;
var tableScrollClassName = 'spaui-table-wraper-scroll'; // 百分比正则

exports.tableScrollClassName = tableScrollClassName;
var percentReg = /^[0-9.]*/i; // 固定列的边框线

exports.percentReg = percentReg;

var FixedColumnBorder = function FixedColumnBorder() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _props$fixedAlign = props.fixedAlign,
      fixedAlign = _props$fixedAlign === void 0 ? '' : _props$fixedAlign,
      _props$show = props.show,
      show = _props$show === void 0 ? true : _props$show;
  var className = 'spaui-table-fixed-td-border';

  if (typeof show !== 'undefined' && !show) {
    return null;
  }

  if (fixedAlign === 'right') {
    className += ' spaui-table-fixed-td-border-byright';
  }

  return _react["default"].createElement("span", {
    className: className
  });
};

exports.FixedColumnBorder = FixedColumnBorder;
var _default = {
  tableFixedTheadClassName: tableFixedTheadClassName,
  tableScrollClassName: tableScrollClassName,
  percentReg: percentReg,
  FixedColumnBorder: FixedColumnBorder
};
exports["default"] = _default;