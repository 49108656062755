"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _spauiUtils = require("@tencent/spaui-utils");

var _number = require("../utils/number");

var _get_node = require("../utils/get_node");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

// 生成唯一id
var genUniqueId = function genUniqueId() {
  return (0, _spauiUtils.uniqueId)('spaui-table-colgroup');
};

var ColGroup = function ColGroup(theProps) {
  var htmlRef = theProps.htmlRef,
      columns = theProps.columns,
      minColumnWidth = theProps.minColumnWidth,
      total = theProps.total,
      totalLabel = theProps.totalLabel;
  var defaultOptionalCellWidth = 35 + (total && totalLabel ? 10 : 0);
  return _react["default"].createElement("colgroup", {
    ref: htmlRef
  }, columns.map(function (item) {
    var _item$props = item.props,
        props = _item$props === void 0 ? {} : _item$props;
    var type = props.type,
        target = props.target;
    var colProps = {};
    var maxWidth = (0, _number.toInt)((0, _get_node.getColumnMaxWidth)(props));
    var width = (0, _number.toInt)(props.width);
    var minWidth = (0, _number.toInt)(props.minWidth || minColumnWidth); // 如果最小宽度大于最大宽度的设置，那就要调整最小宽度

    if (maxWidth > 0 && minWidth > maxWidth) {
      minWidth = maxWidth;
    }

    if (minWidth > 0) {
      colProps['data-min-width'] = minWidth;
    }

    if (maxWidth > 0) {
      colProps['data-max-width'] = maxWidth;
    }

    if (width) {
      if (width < minWidth) {
        width = minWidth;
      }
    } else if (type === 'optional' && target) {
      // 选择框给一个默认的选择
      if ((0, _number.toInt)(props.minWidth) > defaultOptionalCellWidth) {
        width = (0, _number.toInt)(props.minWidth);
      } else {
        width = defaultOptionalCellWidth;
      }

      colProps['data-min-width'] = width;
      colProps['data-optional'] = 1;
    }

    if (width) {
      colProps.width = "".concat(width, "px");
      colProps['data-width'] = colProps.width || '';
    }

    return _react["default"].createElement("col", _extends({
      key: genUniqueId()
    }, colProps));
  }));
};

var _default = ColGroup;
exports["default"] = _default;