"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultProps = exports.propTypes = exports.config = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件参数配置
 */

/**
  * Icon 接口参数
  * @alias IconProps
  * @description 图标。
  * @property {titleline} 默认支持参数
  * @property {blankline} className;style;id;data-*;accessKey;dir;draggable;tabIndex;title;htmlRef;
  * @property {titleline} 基础属性
  * @property {string} type='confirm' 类型（可选值参考下方）
  * @property {string} [size=''] 尺寸*可选值: large, small*
  * @property {number} [boxSize=11] viewBox的width和height，值为 boxSize（仅x-close,x-plus,x-minus）
  * @property {number} [boxGap=0] 四周预留透明空间（仅x-close,x-plus,x-minus）
  * @property {number} [strokeWidth=1] 描边大小（仅x-close,x-plus,x-minus）
  * @property {number} [strokeRadius=0] 描边圆角（仅x-close,x-plus,x-minus）
  * @property {string} [name=''] 图标名称（可选值参考下方）
  * @property {bool} [solid=false] 是否实心（目前不是每个图标都有）
  * @see
  * type 可选值:
  *  strongwarn, warn, success, confirm, tips, wrong, error,
  *  add, close, delete, arrow-up, arrow-down, arrow-left,
  *  arrow-right, checkmark, sort-asc, sort-desc, viewer,
  *  x-close, x-plus, x-minus
  * ====
  * name 可选值：
  *    check-filled, plus-filled, minus-filled, close-filled,
  * arrow-left-filled, arrow-right-filled, info-filled, exclamation-filled,
  * question-filled, check-square-filled, minus-square-filled, plus-square-filled,
  * edit-outlined, form-outlined, copy-outlined, date-outlined, plus-outlined, download,
  * sort-outlined, search-outlined, delete-outlined, picture-outlined, crowd-outlined,
  * operation-auth-outlined, sound-on-outlined, sound-off-outlined, download-outlined,
  * from-auth-outlined, marketing-app-outlined, download-center-outlined, check-outlined,
  * income-outlined, video-play-outlined, flow-outlined, dashboard-outlined, account-outlined,
  * chart-outlined, model-outlined, data-outlined, file-outlined, time-outlined, mailbox-outlined,
  * home-outlined, welfare-outlined, group-outlined, label-outlined, effect-outlined, link-outlined,
  * enterprise-outlined, phone-outlined, more-outlined, collection-outlined, contract-outlined,
  * save-outlined, save-as-outlined, save-as-copy-outlined, save-section-outlined,
  * pen-outlined, price-competition-outlined, allocate-outlined, mapping-outlined, mapping2-outlined, drp-outlined,
  * transfer-accounts-outlined, transfer-rmb-outlined, update-logs-outlined, feedback-outlined,
  * batch-processing-outlined, shield-outlined, voucher-outlined, upload-outlined, spline-outlined,
  * sunburst-outlined, details-outlined, explain-outlined, merge-outlined, split-outlined,
  * contrast-outlined, custom-outlined, ai-drawing-outlined, quick-drawing-outlined, video-screeshots-outlined,
  * video-outlined, zoom-outlined, plus-square-outlined, temporary-colored, special-colored, new-colored, star-colored,
  * rise-colored, fall-colored, golden-colored, silver-colored, bronze-colored, badge-colored, recommend-colored,
  * fast-forward-outlined, check-bold-outlined, back-outlined, forward-outlined, ellipsis-outlined, clear-outlined,
  * dropdown-arrow-outlined, in-decrease-outlined, plus-bold-outlined, minus-bold-outlined, triangle-right-outlined,
  * triangle-left-outlined, cursor-hand-outlined, cursor-arrow-outlined, close-normal-outlined, close-medium-outlined,
  * close-bold-outlined, right-arrow-outlined, right-arrow-medium-outlined, right-arrow-bold-outlined,
  * right-arrow-double-outlined, left-arrow-outlined, left-arrow-medium-outlined, left-arrow-bold-outlined, edit, search
  * left-arrow-double-outlined, triangle-down-outlined
  */
var config = {
  type: {
    type: _spauiUtils.PropTypes.string.isRequired,
    defaultValue: 'confirm'
  },
  size: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  boxSize: {
    type: _spauiUtils.PropTypes.number
  },
  boxGap: {
    type: _spauiUtils.PropTypes.number
  },
  strokeWidth: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: 1
  },
  strokeRadius: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: 0
  },
  name: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  solid: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.bool])
  }
}; // 转化成PureComponent组件的配置

exports.config = config;
var configData = (0, _spauiUtils.formatProps)(config); // 导出

var propTypes = configData.propTypes;
exports.propTypes = propTypes;
var defaultProps = configData.defaultProps;
exports.defaultProps = defaultProps;
var _default = {
  config: config,
  propTypes: propTypes,
  defaultProps: defaultProps
};
exports["default"] = _default;