"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _child_component = _interopRequireDefault(require("./child_component"));

var _index = require("../utils/index");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var SubMenuComponent = function SubMenuComponent(props) {
  var itemData = props.itemData,
      isActive = props.isActive,
      value = props.value,
      type = props.type,
      onMouseEnter = props.onMouseEnter,
      onMouseLeave = props.onMouseLeave,
      onTransitionEnd = props.onTransitionEnd,
      ItemComponent = props.ItemComponent,
      IconComponent = props.IconComponent,
      ArrowComponent = props.ArrowComponent,
      onChange = props.onChange,
      nodeIsSelected = props.nodeIsSelected,
      className = props.className,
      style = props.style,
      id = props.id,
      itemKey = props.itemKey,
      itemHref = props.itemHref,
      _props$customProps = props.customProps,
      customProps = _props$customProps === void 0 ? {} : _props$customProps,
      _props$inPortal = props.inPortal,
      inPortal = _props$inPortal === void 0 ? false : _props$inPortal,
      LinkComponent = props.LinkComponent,
      needIcon = props.needIcon,
      _props$isLast = props.isLast,
      isLast = _props$isLast === void 0 ? false : _props$isLast,
      _props$classNameMap = props.classNameMap,
      classNameMap = _props$classNameMap === void 0 ? {} : _props$classNameMap,
      thirdLevelAsPop = props.thirdLevelAsPop,
      menuComponentProps = props.menuComponentProps;
  return _react["default"].createElement("ul", {
    className: (0, _classnames["default"])(classNameMap.SubMenu, classNameMap.unMarginBottom, className, _defineProperty({}, classNameMap.SubMenuLast, isLast)),
    style: style,
    id: id
  }, itemData.map(function () {
    var _classNames4;

    var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var index = arguments.length > 1 ? arguments[1] : undefined;
    var label = item.label,
        _item$href = item.href,
        href = _item$href === void 0 ? '' : _item$href,
        _item$data = item.data,
        data = _item$data === void 0 ? [] : _item$data,
        iconClassName = item.iconClassName; // 非mini的时候才需要激活展开

    var subItemActive = type !== 'mini' && isActive(item);
    var subItemMenuStyle = {};
    var dataAttribute = {};
    var attrKey = '';
    var ItemComponentProps = {}; // data-*属性

    for (attrKey in item) {
      if (attrKey.indexOf('data-') === 0) {
        dataAttribute[attrKey] = item[attrKey];
      }
    } // ItemComponent的属性


    ItemComponentProps = _objectSpread({}, customProps, {
      // 透传用户自己的属性
      menuComponentProps: menuComponentProps,
      type: type,
      itemKey: itemKey,
      itemHref: itemHref,
      value: value,
      className: (0, _classnames["default"])(_defineProperty({}, classNameMap.MenuTitle, data.length > 0)),
      href: href,
      onClick: function onClick(event) {
        onChange(event, item, 1, subItemActive);
      },
      label: label,
      dataAttribute: dataAttribute,
      IconComponent: IconComponent,
      ArrowComponent: ArrowComponent,
      classNameMap: classNameMap
    }); // 有子内容，不能是有滚动条而且得加一些属性

    if (data.length > 0) {
      ItemComponentProps.iconClassName = (0, _classnames["default"])(iconClassName, _defineProperty({}, classNameMap.MenuIcon, needIcon));
      ItemComponentProps.data = data;

      if (thirdLevelAsPop) {
        subItemMenuStyle.overflow = 'unset';
      }
    }

    return _react["default"].createElement("li", {
      className: (0, _classnames["default"])(classNameMap.MenuItem, (_classNames4 = {
        'js-has-menu': data.length > 0
      }, _defineProperty(_classNames4, classNameMap.HasSubMenu, data.length > 0), _defineProperty(_classNames4, classNameMap.MenuSelected, nodeIsSelected(value, item, type)), _defineProperty(_classNames4, classNameMap.MenuChildSelected, (0, _index.childIsSelect)(value, item, nodeIsSelected)), _defineProperty(_classNames4, classNameMap.SubMenuOpen, subItemActive && data.length > 0), _classNames4), item.className || ''),
      key: index // data-open用于标记是否打开
      ,
      "data-open": subItemActive && data.length > 0 ? 1 : 0,
      onMouseEnter: onMouseEnter,
      onMouseLeave: onMouseLeave,
      onTransitionEnd: onTransitionEnd,
      style: subItemMenuStyle
    }, _react["default"].createElement(ItemComponent, _extends({}, ItemComponentProps, {
      selected: nodeIsSelected(value, item, type),
      nodeData: item,
      inPortal: inPortal,
      LinkComponent: LinkComponent
    })), data.length > 0 ? _react["default"].createElement(_child_component["default"], {
      type: type,
      nodeIsSelected: nodeIsSelected,
      data: data,
      value: value,
      onChange: onChange,
      onMenuItemMouseEnter: onMouseEnter,
      onMenuItemMouseLeave: onMouseLeave,
      onMenuItemTransitionEnd: onTransitionEnd,
      classNameMap: classNameMap
    }) : null);
  }));
};

var _default = SubMenuComponent;
exports["default"] = _default;