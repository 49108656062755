"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _spauiUtils = require("@tencent/spaui-utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var _default = function _default(props) {
  var inputValue = props.inputValue,
      onOpen = props.onOpen,
      _props$InputComponent = props.InputComponent,
      InputComponent = _props$InputComponent === void 0 ? null : _props$InputComponent,
      onInputChange = props.onInputChange,
      inputHtmlRef = props.inputHtmlRef;
  var result = null;

  if ((0, _spauiUtils.isReactNode)(InputComponent)) {
    result = InputComponent;
  } else {
    result = _react["default"].createElement(InputComponent, _extends({}, props, {
      onClick: onOpen,
      value: inputValue,
      onChange: onInputChange,
      htmlRef: inputHtmlRef
    }));
  }

  return result;
};

exports["default"] = _default;