"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultProps = exports.propTypes = exports.config = exports.defaultOverlayTriggerProps = void 0;

var _react = _interopRequireDefault(require("react"));

var _spauiIcon = _interopRequireDefault(require("@tencent/spaui-icon"));

var _spauiUtils = require("@tencent/spaui-utils");

var _text_component = _interopRequireDefault(require("./component/text_component"));

var _tip_component = _interopRequireDefault(require("./component/tip_component"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件参数配置
 */
// 默认配置
var defaultOverlayTriggerProps = {
  trigger: 'hover',
  hideDelayTime: 0,
  placement: 'top'
};
/**
 * Radio 接口参数
 * @alias RadioProps
 * @description 单选框。
 * @property {titleline} 默认支持参数
 * @property {blankline} className;style;id;data-*;accessKey;dir;draggable;tabIndex;title;htmlRef;
 * @property {titleline} 基本属性
 * @property {string|number|bool} value='' 值
 * @property {bool} checked=false 是否选中
 * @property {string} [checkedClassName=''] 选中项的类名
 * @property {bool} [canCancelCheck=false] 是否可以取消选中
 * @property {func} onChange 切换状态回调函数*onChange = (event, value)*
 * @property {any} [label] 展示文案
 * @property {string} [name=''] 字段名
 * @property {bool} [disabled=false] 是否禁用
 * @property {string|element} [inputTip=''] 操作框提示
 * @property {string} [size='default'] 尺寸大小*可选：default, large, extralarge*
 * @property {string|element} [tip=''] 选项提示（带问好图标提示）
 * @property {object} [tipStyle={}] 提示结构的style
 * @property {bool} [OverlayTriggerProps={trigger: 'hover',delay: 0}] tip使用的是spaui-popover，这里可以配置对应的trigger参数
 * @property {func|element} [TipComponent] 展示tip的组件结构
 * @property {func|element} [TipIconComponent] 展示tip的图表组件结构
 * @property {bool} [textChecked=true] 是否文字可选中
 * @property {bool} [hideIcon=false] 是否隐藏 radio 图标
 * @property {bool} [isImmediatelyRespondChecked=false] 是否立马响应状态*（主要用于数据量大有延迟的时候）*
 * @property {string} [tagName='label'] 使用什么标签触发选中事件*(由于ReactJS的label默认带了onClick容易往上冒泡所以可选择是否不用)*
 * @property {func|element} [TextComponent] 文案的结构*(具体参数看案例)*
 *
 * @see
 *
 * onChange typings:
 *     (event: any, value: any) => any
 *
 * @example
 * &lt;TextComponent {...this.props} /&gt;
 */

exports.defaultOverlayTriggerProps = defaultOverlayTriggerProps;
var config = {
  value: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number, _spauiUtils.PropTypes.bool]).isRequired,
    defaultValue: ''
  },
  checked: {
    type: _spauiUtils.PropTypes.bool.isRequired,
    defaultValue: false
  },
  checkedClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  canCancelCheck: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  onChange: {
    type: _spauiUtils.PropTypes.func
  },
  label: {
    type: _spauiUtils.PropTypes.any
  },
  name: {
    type: _spauiUtils.PropTypes.string
  },
  disabled: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  inputTip: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.element]),
    defaultValue: ''
  },
  size: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'default'
  },
  tip: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.element]),
    defaultValue: ''
  },
  tipStyle: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  OverlayTriggerProps: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: defaultOverlayTriggerProps
  },
  TipComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.element, _spauiUtils.PropTypes.func]),
    defaultValue: _tip_component["default"]
  },
  TipIconComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.element, _spauiUtils.PropTypes.func]),
    defaultValue: _react["default"].createElement(_spauiIcon["default"], {
      className: "spaui-svg-icon-tip",
      name: "question-filled",
      solid: "true"
    })
  },
  textChecked: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  isImmediatelyRespondChecked: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  hideIcon: {
    type: _spauiUtils.PropTypes.bool
  },
  iconHtml: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.element, _spauiUtils.PropTypes.func]),
    defaultValue: _react["default"].createElement("span", {
      className: "ico"
    })
  },
  tagName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'label'
  },
  TextComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.element, _spauiUtils.PropTypes.func]),
    defaultValue: _text_component["default"]
  }
}; // 转化成PureComponent组件的配置

exports.config = config;
var configData = (0, _spauiUtils.formatProps)(config); // 导出

var propTypes = configData.propTypes;
exports.propTypes = propTypes;
var defaultProps = configData.defaultProps;
exports.defaultProps = defaultProps;
var _default = {
  config: config,
  propTypes: propTypes,
  defaultProps: defaultProps,
  defaultOverlayTriggerProps: defaultOverlayTriggerProps
};
exports["default"] = _default;