"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
var SliderHandle = function SliderHandle() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var className = props.className,
      htmlRef = props.htmlRef,
      _props$type = props.type,
      type = _props$type === void 0 ? '' : _props$type,
      onMouseDown = props.onMouseDown,
      onMouseOver = props.onMouseOver,
      onMouseOut = props.onMouseOut,
      style = props.style;
  return _react["default"].createElement("div", {
    className: className,
    ref: htmlRef,
    "data-handle": type,
    onMouseDown: onMouseDown,
    onMouseOver: onMouseOver,
    onMouseOut: onMouseOut,
    style: style
  });
};

var _default = SliderHandle;
exports["default"] = _default;