"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultProps = exports.propTypes = exports.config = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

var _help_block_component = _interopRequireDefault(require("./component/help_block_component"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件参数配置
 */

/**
 * FormGroup 接口参数
 * @alias FormGroupProps
 * @description 表单控件，包含数据校验以及对应样式。
 * @property {titleline} 默认支持参数
 * @property {blankline} className;style;id;data-*;accessKey;dir;draggable;tabIndex;title;htmlRef;
 * @property {titleline} 基础属性
 * @property {bool} [clear=false] 是否可清空
 * @property {func} [onClear] 清空回调*onClear=(event)*
 * @property {titleline} feedback相关属性
 * @property {string|element} [feedback=''] 提示信息
 * @property {string} [feedbackClassName=''] feedback的className
 * @property {object} [feedbackStyle={}] feedback的style
 * @property {bool} [autoFeedbackWidth=true] 自适应feedback的宽度
 * @property {number} [feedbackSpace=20] 自适应feedback的间隙宽度
 * @property {titleline} helpBlock相关属性
 * @property {string} [status='normal'] help-block的状态*可选值: success, error, normal*
 * @property {string|element} [helpBlock=''] help-block内容
 * @property {string} [helpBlockClassName=''] help-block结构className
 * @property {object} [helpBlockStyle={}] help-block结构的style
 * @property {func} [HelpBlockComponent] 用于展示helpBlock的组件*(具体参数看案例)*
 * @property {object} [classNameMap={}] 组件内对应结构的默认className(可以针对对应位置进行替换,具体参数见案例)
 * @property {titleline} prefix相关属性
 * @property {string|element} [prefix=''] 左侧扩展信息
 * @property {number} [prefixSpace=20] prefix的间隙宽度
 * @property {bool} [autoPrefixWidth=true] 自适应prefix的宽度
 * @property {object} [prefixStyle={}] prefix的额外样式
 * @property {string} [prefixClassName=''] prefix的额外类名
 * @property {titleline} addons相关属性
 * @property {string|element} [addons=''] 附加内容
 * @property {bool} [hasWrap=false] children和prefix,feedback是否使用一个容器包裹
 * @example
 * &lt;HelpBlockComponent
 {...this.props}
&gt;{helpBlock}&lt;/HelpBlockComponent&gt;
 *
 * classNameMap配置可见于：https://git.woa.com/ads/spaui-basic_ui_components/tree/master/src/spaui-formgroup/src/js/config/classname_map.js
 */
var config = {
  autoFeedbackWidth: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  feedbackSpace: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: 20
  },
  feedback: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.element]),
    defaultValue: '',
    visualData: {
      type: 'input'
    }
  },
  feedbackClassName: {
    type: _spauiUtils.PropTypes.string,
    className: '',
    visualData: {
      type: 'input'
    }
  },
  feedbackStyle: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {},
    visualData: {
      type: 'object'
    }
  },
  clear: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.bool]),
    defaultValue: false,
    visualData: {
      type: 'switch'
    }
  },
  onClear: _spauiUtils.PropTypes.func,
  status: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'normal',
    visualData: {
      type: 'radio',
      value: ['normal', 'success', 'error']
    }
  },
  helpBlock: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.element]),
    defaultValue: '',
    visualData: {
      type: 'input'
    }
  },
  helpBlockClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValueP: '',
    visualData: {
      type: 'input'
    }
  },
  helpBlockStyle: {
    type: _spauiUtils.PropTypes.object,
    defaultValueP: {},
    visualData: {
      type: 'object'
    }
  },
  HelpBlockComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _help_block_component["default"]
  },
  prefix: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.element]),
    defaultValue: '',
    visualData: {
      type: 'input'
    }
  },
  prefixSpace: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: 20
  },
  autoPrefixWidth: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  prefixClassName: {
    type: _spauiUtils.PropTypes.string
  },
  prefixStyle: {
    type: _spauiUtils.PropTypes.object
  },
  addons: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.element]),
    defaultValue: ''
  },
  className: {
    type: _spauiUtils.PropTypes.string,
    visualData: {
      type: 'input'
    }
  },
  style: {
    type: _spauiUtils.PropTypes.object,
    visualData: {
      type: 'object'
    }
  },
  id: {
    type: _spauiUtils.PropTypes.string,
    visualData: {
      type: 'input'
    }
  },
  hasWrap: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  }
}; // 转化成PureComponent组件的配置

exports.config = config;
var configData = (0, _spauiUtils.formatProps)(config); // 导出

var propTypes = configData.propTypes;
exports.propTypes = propTypes;
var defaultProps = configData.defaultProps;
exports.defaultProps = defaultProps;
var _default = {
  config: config,
  propTypes: propTypes,
  defaultProps: defaultProps
};
exports["default"] = _default;