"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultProps = exports.propTypes = exports.config = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件参数配置
 */

/**
 * Notice 接口参数
 * @alias NoticeProps
 * @property {titleline} 默认支持参数
 * @property {blankline} className;style;id;data-*;accessKey;dir;draggable;tabIndex;title;htmlRef;
 * @property {titleline} 基础属性
 * @property {string} [type='info'] 提示风格类型*可选值：info, success, error, warn, strongwarn*
 * @property {string|element} [message] 消息内容
 * @property {number} [duration=3] 持续时间*单位：秒, 默认值同全局配置，优先级：组件配置 > 全局配置 *
 * @property {bool} [closable=false] 是否能够手动关闭
 * @property {func} [onClose] 关闭回调*形式：(isAutoClose, config) => {}; isAutoClose 参数表明是自定关闭还是手动关闭*
 * @property {string} [theme='normal'] 主题色*可选值：normal, light, heavy*
 * @property {string|number} [id] 消息id*如果要更新信息内容，需要使用这个来标识具体哪个消息*
 * @property {bool} [canBackout=false] 是否显示撤销按钮
 * @property {string} [backoutLabel='撤销'] 撤销按钮文案
 * @property {func} [onClickBackout] 点击撤销按钮的回调
 * @property {titleline} 内部组件属性
 * @property {object} [AlertProps={}] 给 Alert 组件添加额外 props
 * @property {object} [backoutButtonProps={}] 给撤销 Button 组件添加额外 props
 * @property {object} [closeButtonProps={}] 给关闭 Button 组件添加的额外 props
 */
var config = {
  type: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'info'
  },
  duration: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: 3
  },
  message: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.element, _spauiUtils.PropTypes.string])
  },
  backoutLabel: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: '撤销'
  },
  onClickBackout: {
    type: _spauiUtils.PropTypes.func
  },
  onClose: {
    type: _spauiUtils.PropTypes.func
  },
  closable: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  canBackout: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  theme: {
    type: _spauiUtils.PropTypes.oneOf(['normal', 'light', 'heavy'])
  },
  id: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.number, _spauiUtils.PropTypes.string])
  },
  AlertProps: {
    type: _spauiUtils.PropTypes.object,
    "default": {}
  },
  backoutButtonProps: {
    type: _spauiUtils.PropTypes.object,
    "default": {}
  },
  closeButtonProps: {
    type: _spauiUtils.PropTypes.object,
    "default": {}
  }
}; // 转化成PureComponent组件的配置

exports.config = config;
var configData = (0, _spauiUtils.formatProps)(config); // 导出

var propTypes = configData.propTypes;
exports.propTypes = propTypes;
var defaultProps = configData.defaultProps;
exports.defaultProps = defaultProps;
var _default = {
  config: config,
  propTypes: propTypes,
  defaultProps: defaultProps
};
exports["default"] = _default;