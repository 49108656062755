"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _spauiUtils = require("@tencent/spaui-utils");

var _spauiLabels = _interopRequireDefault(require("@tencent/spaui-labels"));

var _spauiInput = _interopRequireDefault(require("@tencent/spaui-input"));

var _index = require("../utils/index");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

var _default = function _default(props) {
  var onOpen = props.onOpen,
      html = props.html,
      value = props.value,
      multiple = props.multiple,
      placeholder = props.placeholder,
      _props$data = props.data,
      data = _props$data === void 0 ? {} : _props$data,
      unClose = props.unClose,
      searchValue = props.searchValue,
      onSearch = props.onSearch,
      propsValueLabelField = props.valueLabelField,
      disabled = props.disabled,
      onChange = props.onChange,
      onClickValueArea = props.onClickValueArea,
      _props$valueAreaStyle = props.valueAreaStyle,
      valueAreaStyle = _props$valueAreaStyle === void 0 ? {} : _props$valueAreaStyle,
      _props$valueAreaClass = props.valueAreaClassName,
      valueAreaClassName = _props$valueAreaClass === void 0 ? '' : _props$valueAreaClass,
      _props$labelFieldName = props.labelFieldName,
      labelFieldName = _props$labelFieldName === void 0 ? '' : _props$labelFieldName,
      _props$classNameMap = props.classNameMap,
      classNameMap = _props$classNameMap === void 0 ? {} : _props$classNameMap,
      _props$LabelsProps = props.LabelsProps,
      LabelsProps = _props$LabelsProps === void 0 ? {} : _props$LabelsProps,
      open = props.open,
      mode = props.mode,
      _props$tagsInput = props.tagsInput,
      tagsInput = _props$tagsInput === void 0 ? true : _props$tagsInput,
      handleTagsInputKeyDown = props.handleTagsInputKeyDown,
      _props$requiredValue = props.requiredValue,
      requiredValue = _props$requiredValue === void 0 ? [] : _props$requiredValue,
      setLabelComponentLabel = props.setLabelComponentLabel,
      valuePrefix = props.valuePrefix,
      valueSuffix = props.valueSuffix;

  var _getTheContentOfTheVa = (0, _index.getTheContentOfTheValueAreaComponent)(props, true),
      onClear = _getTheContentOfTheVa.onClear,
      arrowDom = _getTheContentOfTheVa.arrowDom,
      showClearIcon = _getTheContentOfTheVa.showClearIcon;

  var valueLabelField = typeof propsValueLabelField === 'undefined' ? [labelFieldName, 'mark', 'description'] : propsValueLabelField; // 获取文案

  var getLabel = function getLabel() {
    var theValue = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var valueData = data[theValue] || {};
    var haveLabel = typeof valueData[labelFieldName] !== 'undefined';
    var label = haveLabel ? valueData[labelFieldName] : '';
    var result = ''; // 选项数据结构为对象

    if (_typeof(valueData) === 'object' && haveLabel) {
      if (multiple) {
        if (mode === 'tags') {
          // 标签模式下 label 允许为 DOM 结构，仅渲染 labelFieldName 结构
          result = valueData[labelFieldName];
        } else {
          // 多选就只是-分隔
          result = [];
          valueLabelField.forEach(function (fieldName) {
            if (typeof valueData[fieldName] !== 'undefined') {
              result.push(String(valueData[fieldName]));
            }
          });
          result = result.join('-');
        }
      } else {
        if (!html) {
          result = [];
          valueLabelField.forEach(function (fieldName) {
            if (typeof valueData[fieldName] !== 'undefined') {
              result.push(String(valueData[fieldName]));
            }
          });
          result = result.join('-');
        } else {
          result = _react["default"].createElement("span", null, valueLabelField.map(function (fieldName, index) {
            if (typeof valueData[fieldName] !== 'undefined' && fieldName === labelFieldName) {
              return _react["default"].createElement("span", {
                key: index,
                dangerouslySetInnerHTML: {
                  __html: valueData[fieldName]
                }
              });
            }

            if (typeof valueData[fieldName] !== 'undefined' && fieldName === 'mark') {
              return _react["default"].createElement("i", {
                key: index,
                dangerouslySetInnerHTML: {
                  __html: valueData[fieldName]
                }
              });
            }

            if (typeof valueData[fieldName] !== 'undefined') {
              return _react["default"].createElement("span", {
                key: index,
                dangerouslySetInnerHTML: {
                  __html: valueData[fieldName]
                }
              });
            }
          }));
        }
      }
    } else {
      if (multiple) {
        result = label;
      } else {
        if (!html) {
          result = label;
        } else {
          result = _react["default"].createElement("span", {
            dangerouslySetInnerHTML: {
              __html: label
            }
          });
        }
      }
    }

    if ((0, _spauiUtils.isFunction)(setLabelComponentLabel)) {
      return setLabelComponentLabel(valueData, result);
    }

    return result;
  }; // 标签回调


  var onLabelsChange = function onLabelsChange(event, labelsData) {
    var result = '';

    if (multiple) {
      result = [];
      labelsData.map(function () {
        var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        result.push(item.value);
        return item;
      });
    } else {
      if (labelsData.length > 0) {
        result = labelsData[0].value;
      }
    }

    if ((0, _spauiUtils.isFunction)(onChange)) {
      onChange(event, result, true);
    }
  }; // 点击内容展示区域


  var onValueAreaClick = function onValueAreaClick(event) {
    var target = event.target || null;

    if ((0, _spauiUtils.isFunction)(onOpen)) {
      if (mode !== 'tags') {
        if (target) {
          // 点的不是labels的删除按钮才关闭
          if (target.parentNode && target.parentNode.className && target.parentNode.className.indexOf && target.parentNode.className.indexOf('icon del') < 0 || labelData.length < 2) {
            onOpen(event);
          }
        } else {
          onOpen(event);
        }
      } else {
        // 打开下拉面板
        onOpen(event, true);
      }
    }

    if ((0, _spauiUtils.isFunction)(onClickValueArea)) {
      onClickValueArea(event);
    }

    if ((0, _spauiUtils.isFunction)(unClose) && open) {
      unClose(event);
    }
  }; // 获取Labels的数据


  var getLabelsData = function getLabelsData() {
    var result = [];
    var tempData = {};

    if (multiple) {
      if ((0, _spauiUtils.isArray)(value)) {
        value.map(function (item) {
          tempData = {}; // 这个是Label组件自己的字段

          tempData.label = getLabel(item);
          tempData.value = item;

          if (_typeof(data) === 'object' && data && data[item] && data[item].disabled) {
            tempData["delete"] = false;
          }

          if ((0, _spauiUtils.isArray)(requiredValue) && requiredValue.includes(tempData.value)) {
            tempData["delete"] = false;
            tempData.className = (0, _classnames["default"])(tempData.className, 'disabled');
          }

          result.push(tempData);
          return item;
        });
      } else {
        console.error("\u3010spaui-select\u3011multiple\u4E3Atrue\u65F6\uFF0Cvalue\u9700\u8981\u4E3A\u6570\u7EC4\uFF0C\u76EE\u524Dvalue=".concat(value));
      }
    } else {
      tempData = {};
      tempData.label = getLabel(value);
      tempData.value = value;

      if (_typeof(data) === 'object' && data && data[value] && data[value].disabled) {
        tempData["delete"] = false;
      }

      if ((0, _spauiUtils.isArray)(requiredValue) && requiredValue.includes(tempData.value)) {
        tempData["delete"] = false;
        tempData.className = (0, _classnames["default"])(tempData.className, 'disabled');
      }

      if (typeof value === 'number' || value || typeof tempData.label === 'number' || tempData.label) {
        result = [tempData];
      }
    }

    return result;
  };

  var labelData = getLabelsData();

  var setPlaceholderHtml = function setPlaceholderHtml(labelElement) {
    var result = labelElement;

    if (labelElement && typeof labelElement === 'string') {
      result = _react["default"].createElement("div", {
        className: "spaui-selection-item tt"
      }, _react["default"].createElement("div", {
        className: "spaui-selection-item-content in"
      }, labelElement));
    }

    return result;
  };

  var content = null;
  var placeholderClassName = ''; // tags 标签模式

  if (mode === 'tags') {
    content = _react["default"].createElement(_spauiLabels["default"], _extends({
      data: labelData,
      onChange: onLabelsChange,
      onClear: onClear,
      isClear: false,
      clear: false,
      disabled: disabled
    }, LabelsProps), _react["default"].createElement("li", {
      className: "search-input-container"
    }, _react["default"].createElement(_spauiInput["default"], {
      className: "search-input",
      value: searchValue,
      placeholder: labelData.length === 0 ? placeholder : '',
      onChange: onSearch,
      onKeyDown: handleTagsInputKeyDown,
      readOnly: !tagsInput
    })));
  } else {
    if (labelData.length > 0) {
      content = _react["default"].createElement(_spauiLabels["default"], _extends({
        data: labelData,
        onChange: onLabelsChange,
        onClear: onClear,
        isClear: false,
        clear: false,
        disabled: disabled
      }, LabelsProps));
    } else {
      content = _react["default"].createElement("span", {
        className: "selection-single-text"
      }, setPlaceholderHtml(placeholder));
      placeholderClassName = 'placeholder';
    }
  }

  return _react["default"].createElement("div", {
    className: (0, _classnames["default"])(classNameMap.SelectValueHolderClassName, placeholderClassName, valueAreaClassName, _defineProperty({}, classNameMap.SelectValueHolderClearClassName, showClearIcon)),
    onClick: onValueAreaClick,
    style: valueAreaStyle
  }, valuePrefix, content, valueSuffix, showClearIcon ? _react["default"].createElement("div", {
    className: "spaui-select-clear",
    role: "button",
    onClick: onClear
  }, _react["default"].createElement("svg", {
    viewBox: "0 0 16 16",
    width: "16px",
    height: "16px"
  }, _react["default"].createElement("circle", {
    fill: "currentColor",
    fillRule: "evenodd",
    cx: "8",
    cy: "8",
    r: "8"
  }), _react["default"].createElement("path", {
    d: "M5.333 5.333l5.334 5.334M10.667 5.333l-5.334 5.334",
    stroke: "#FFF",
    strokeLinecap: "square"
  }))) : null, arrowDom);
};

exports["default"] = _default;