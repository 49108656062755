"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件对应结构的默认className
 */
var _default = {
  Slider: 'spaui-slider',
  SliderWithFixClassName: 'slider-with-fix',
  SliderWithStepClassName: 'spaui-slider-with-step',
  SliderStepTopClassName: 'spaui-slider-step-top',
  SliderShowStepNodeClassName: 'spaui-showstepnode',
  SliderClassName: 'slider',
  SliderInnerClassName: 'slider-inner',
  SliderHandleClassName: 'slider-handle',
  SliderLeftHandleClassName: 'slider-handle-l',
  SliderRightHandleClassName: 'slider-handle-r',
  SliderInfoClassName: 'slider-info',
  SliderStepWrapClassName: 'spaui-slider-step-wrap',
  SliderStepClassName: 'slider-step',
  SliderNodeClassName: 'slider-node',
  SliderMaskClassName: 'slider-mask',
  SliderMaskNodeClassName: 'slider-mask-node',
  SliderRailClassName: 'slider-rail',
  SliderTrackClassName: 'slider-track',
  SliderPrefixClassName: 'slider-prefix',
  SliderSuffixClassName: 'slider-suffix',
  SliderPopOverClassName: 'spaui-popover spaui-popover-slider',
  SliderPopOverOffsetClassName: 'spaui-popover-offset',
  SliderPopOverArrowClassName: 'spaui-popover-arrow',
  SliderPopOverContentClassName: 'spaui-popover-content'
};
exports["default"] = _default;