"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.DropdownToggle = exports.DropdownContent = exports.Dropdown = void 0;

var _dropdown = _interopRequireDefault(require("./dropdown"));

var _dropdown_content = _interopRequireDefault(require("./dropdown_content"));

var _dropdown_toggle = _interopRequireDefault(require("./dropdown_toggle"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
// 兼容旧模式，导出一个唯一的DropMenu
var defaultData = {};
var Dropdown = _dropdown["default"];
exports.Dropdown = Dropdown;
var DropdownContent = _dropdown_content["default"];
exports.DropdownContent = DropdownContent;
var DropdownToggle = _dropdown_toggle["default"]; // 存放到default数据中

exports.DropdownToggle = DropdownToggle;
defaultData.Dropdown = Dropdown;
defaultData.DropdownContent = DropdownContent;
defaultData.DropdownToggle = DropdownToggle; // 调试信息
// if (process.env.NODE_ENV !== 'production') {
//     const {whyDidYouUpdate} = require('why-did-you-update');
//     whyDidYouUpdate(React);
// }

var _default = defaultData;
exports["default"] = _default;