"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.position = exports.getValueByPosition = exports.getStyleByValue = exports.getPercentByValue = exports.getDomWidthAndHeight = exports.getPopOverShowConfig = exports.getLeftPopOver = exports.haveJQuery = exports.clamp = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

/**
 * Clamp position between a range
 * @param  {number} - Value to be clamped
 * @param  {number} - Minimum value in range
 * @param  {number} - Maximum value in range
 * @return {number} - Clamped value
 */
var clamp = function clamp(value, min, max) {
  return Math.min(Math.max(value, min), max);
}; // 是否jquery


exports.clamp = clamp;
var haveJQuery = (typeof window === "undefined" ? "undefined" : _typeof(window)) === 'object' && typeof window.jQuery === 'function'; // 获取左popover的配置

exports.haveJQuery = haveJQuery;

var getLeftPopOver = function getLeftPopOver() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return props.popOver || props.leftPopOver || {};
}; // 获取popover是否需要显示


exports.getLeftPopOver = getLeftPopOver;

var getPopOverShowConfig = function getPopOverShowConfig() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var state = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var rightPopOver = props.rightPopOver;
  var leftPopOver = getLeftPopOver(props);
  var result = {
    showLeftPopOver: state.showLeftPopOver || false,
    showRightPopOver: state.showRightPopOver || false
  };

  if (leftPopOver.type === 'show') {
    result.showLeftPopOver = true;
  }

  if (rightPopOver.type === 'show') {
    result.showRightPopOver = true;
  }

  return result;
}; // 获取Slider对应结构的宽高


exports.getPopOverShowConfig = getPopOverShowConfig;

var getDomWidthAndHeight = function getDomWidthAndHeight(self) {
  if (!self.slider) {
    return;
  } // SliderInner的宽度


  var sliderRect = self.slider.getBoundingClientRect();
  self.sliderWidth = sliderRect.width; // 操作圆球slider-handle的宽高

  if (self.handle) {
    var handleRect = self.handle.getBoundingClientRect();
    self.handleWidth = handleRect.width;
    self.handleHeight = handleRect.height;
    self.halfHandleWidth = Math.round(handleRect.width / 2 * 100) / 100;
  } // 左右操作圆球slider-handle的宽高


  if (self.handleLeft && self.handleRight) {
    var handleLeftRect = self.handleLeft.getBoundingClientRect();
    var handleRightRect = self.handleRight.getBoundingClientRect();
    self.handleLeftWidth = handleLeftRect.width;
    self.handleLeftHeight = handleLeftRect.height;
    self.handleRightWidth = handleRightRect.width;
    self.handleRightHeight = handleRightRect.height;
    self.halfHandleLeftWidth = Math.round(handleLeftRect.width / 2 * 100) / 100;
    self.halfHandleRightWidth = Math.round(handleRightRect.width / 2 * 100) / 100;
  }
};
/**
 * 根据滑动值计算偏移百分比
 * @private
 * @param  {number} value - 滑动值
 * @return {number} result - 偏移百分比
 */


exports.getDomWidthAndHeight = getDomWidthAndHeight;

var getPercentByValue = function getPercentByValue(value, props) {
  var min = props.min,
      max = props.max,
      type = props.type,
      valueNodes = props.valueNodes,
      stepNodes = props.stepNodes;
  var diffMaxMin = max - min;
  var clampLeftVal = 0;
  var clampRightVal = 0;
  var leftDiffValMin = 0;
  var rightDiffValMin = 0;
  var leftPercentage = 0;
  var rightPercentage = 0;
  var result = null;

  if (type === 'range') {
    clampLeftVal = clamp(value[0] || 0, min, max);
    clampRightVal = clamp(value[1] || 0, min, max);
    leftDiffValMin = clampLeftVal - min;
    rightDiffValMin = clampRightVal - min;

    if ((0, _spauiUtils.isArray)(valueNodes) && valueNodes.length > 0 && (0, _spauiUtils.isArray)(stepNodes) && valueNodes.length === stepNodes.length && valueNodes.includes(clampLeftVal) && valueNodes.includes(clampRightVal)) {
      leftPercentage = stepNodes[valueNodes.indexOf(clampLeftVal)];
      rightPercentage = stepNodes[valueNodes.indexOf(clampRightVal)];
    } else {
      leftPercentage = leftDiffValMin / diffMaxMin;
      rightPercentage = rightDiffValMin / diffMaxMin;
    }

    result = [leftPercentage, rightPercentage];
  } else {
    clampLeftVal = clamp(value, min, max);
    leftDiffValMin = clampLeftVal - min;

    if ((0, _spauiUtils.isArray)(valueNodes) && valueNodes.length > 0 && (0, _spauiUtils.isArray)(stepNodes) && valueNodes.length === stepNodes.length && valueNodes.includes(clampLeftVal)) {
      leftPercentage = stepNodes[valueNodes.indexOf(clampLeftVal)];
    } else {
      leftPercentage = leftDiffValMin / diffMaxMin;
    }

    result = leftPercentage;
  }

  return result;
};
/**
 * 根据百分比计算元素样式
 * @private
 * @param  {number} percent - 左侧百分比
 * @return {object} {shouldShow, trackWidth, handleLeft} - 样式信息
 */


exports.getPercentByValue = getPercentByValue;

var getStyleByValue = function getStyleByValue(value, self) {
  var percent = getPercentByValue(value, self.props);
  var type = self.props.type;
  var result = {};
  var trackLeft = 0;
  var trackRight = 0;
  var leftPercentPage = 0;
  var rightPercentPage = 0;
  result = {
    shouldShow: false,
    trackWidth: 0,
    trackLeft: 0,
    handleLeft: 0,
    handleRight: 0
  };

  if (self.sliderWidth) {
    if (type === 'range' && self.handleLeftWidth && self.handleRightWidth && self.halfHandleLeftWidth && self.halfHandleRightWidth) {
      leftPercentPage = percent[0] || 0;
      rightPercentPage = percent[1] || 0;
      trackLeft = Math.round(leftPercentPage * self.sliderWidth * 10) / 10;
      trackRight = Math.round(rightPercentPage * self.sliderWidth * 10) / 10;
      result = {
        shouldShow: true,
        trackWidth: trackRight - trackLeft || 0,
        trackLeft: trackLeft,
        handleLeft: trackLeft,
        handleRight: trackRight
      };
    } else {
      leftPercentPage = percent;

      if (self.handleWidth && self.halfHandleWidth) {
        trackLeft = Math.round(leftPercentPage * self.sliderWidth * 10) / 10;
        result = {
          shouldShow: true,
          trackWidth: trackLeft,
          handleLeft: trackLeft
        };
      }
    }
  }

  return result;
};
/**
 * 根据偏移百分比计算滑动值
 * @private
 * @param  {number} pos - 当前偏移百分比
 * @return {number} value - 滑动值
 */


exports.getStyleByValue = getStyleByValue;

var getValueByPosition = function getValueByPosition(pos, props) {
  var min = props.min,
      max = props.max,
      step = props.step,
      valueNodes = props.valueNodes;
  var percentage = clamp(pos, 0, 1) / 1;
  var percentValue = percentage * (max - min);
  var baseVal = 0;
  var value = 0;
  var valueSpace = 0;
  var valueIndex = -1;

  if (valueNodes.length < 1) {
    // 如果slider太短，某些刻度值无法选中，这里改成ceil或floor都没用的，加长或缩小值范围或加大步长吧
    baseVal = step * Math.round(percentValue / step);
    value = baseVal + min;
  } else {
    // 看看percentValue距离valueNodes中哪个数据最近
    valueNodes.map(function (item, index) {
      var curValueSpace = Math.abs(item - percentValue);

      if (valueIndex < 0) {
        valueSpace = curValueSpace;
        valueIndex = index;
      } else {
        if (curValueSpace < valueSpace) {
          valueSpace = curValueSpace;
          valueIndex = index;
        }
      }

      return item;
    });
    value = valueNodes[valueIndex];
  }

  return clamp(value, min, max);
};
/**
 * 根据鼠标位置计算滑动值
 * @private
 * @param  {Object} event - 事件event对象
 * @param {String} action - 事件动作
 * @return {number} value - 滑动值
 */


exports.getValueByPosition = getValueByPosition;

var position = function position(event) {
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var self = arguments.length > 2 ? arguments[2] : undefined;

  if (self.slider) {
    var _self$props = self.props,
        type = _self$props.type,
        exceed = _self$props.exceed,
        _self$props$canTransl = _self$props.canTranslationRange,
        canTranslationRange = _self$props$canTransl === void 0 ? false : _self$props$canTransl,
        min = _self$props.min,
        max = _self$props.max;
    var trackMouseDown = self.trackMouseDown || false;
    var trackValueList = typeof self.trackValue === 'string' && self.trackValue.split('-') || [];

    var value = self._getValue(); // const directionStyle = 'left' // 后续可配，支持上下左右


    var _self$slider$getBound = self.slider.getBoundingClientRect(),
        left = _self$slider$getBound.left;

    var coordinate = !event.touches ? event.clientX : event.touches[0].clientX;
    var pos;
    var result;
    var handle = '';
    var leftValue = 0;
    var rightValue = 0;
    var translationInitLeftValue = 0;
    var translationInitRightValue = 0;
    var translationValue = 0;

    var getValueByClientX = function getValueByClientX(initCoordinate) {
      // 根据位置获取对应的值
      var _coordinate = clamp(initCoordinate, left + self.halfHandleLeftWidth, left + self.sliderWidth - self.halfHandleLeftWidth);

      var _pos = (_coordinate - left - self.halfHandleLeftWidth) / (self.sliderWidth - self.handleLeftWidth);

      return getValueByPosition(_pos, self.props);
    };

    if (type === 'range' && action !== 'click') {
      // 平移
      if (canTranslationRange && trackMouseDown) {
        translationInitLeftValue = parseInt(trackValueList[0] || 0, 10) || 0;
        translationInitRightValue = parseInt(trackValueList[1] || 0, 10) || 0;
        translationValue = getValueByClientX(coordinate) - getValueByClientX(self.trackCoordinate);
        leftValue = translationInitLeftValue;
        rightValue = translationInitRightValue;
        leftValue += translationValue;
        rightValue += translationValue; // 超过边界的时候要设置下

        if (leftValue < min) {
          translationValue = min - translationInitLeftValue;
          leftValue = translationInitLeftValue;
          rightValue = translationInitRightValue;
          leftValue += translationValue;
          rightValue += translationValue;
        } else if (rightValue > max) {
          translationValue = max - translationInitRightValue;
          leftValue = translationInitLeftValue;
          rightValue = translationInitRightValue;
          leftValue += translationValue;
          rightValue += translationValue;
        }
      } else {
        handle = event.target.getAttribute('data-handle') || '';
        leftValue = value[0] || 0;
        rightValue = value[1] || 0;

        if (handle) {
          self.handleTargetType = handle;
        }

        handle = handle || self.handleTargetType;

        if (handle === 'left') {
          coordinate = clamp(coordinate, left + self.halfHandleLeftWidth, left + self.sliderWidth - self.halfHandleLeftWidth);
          pos = (coordinate - left - self.halfHandleLeftWidth) / (self.sliderWidth - self.handleLeftWidth);
          leftValue = getValueByPosition(pos, self.props);
        } else if (handle === 'right') {
          coordinate = clamp(coordinate, left + self.halfHandleRightWidth, left + self.sliderWidth - self.halfHandleRightWidth);
          pos = (coordinate - left - self.halfHandleRightWidth) / (self.sliderWidth - self.handleRightWidth);
          rightValue = getValueByPosition(pos, self.props);
        } // 不能超过另外一个值


        if (!exceed) {
          if (handle === 'left') {
            if (leftValue > rightValue) {
              leftValue = rightValue;
            }
          } else if (handle === 'right') {
            if (rightValue < leftValue) {
              rightValue = leftValue;
            }
          }
        }
      }

      if (leftValue > rightValue) {
        result = [rightValue, leftValue];
      } else {
        result = [leftValue, rightValue];
      }
    } else {
      coordinate = clamp(coordinate, left + self.halfHandleWidth, left + self.sliderWidth - self.halfHandleWidth);
      pos = (coordinate - left - self.halfHandleWidth) / (self.sliderWidth - self.handleWidth);
      result = getValueByPosition(pos, self.props);
    }

    return result;
  }
};

exports.position = position;
var _default = {
  clamp: clamp,
  haveJQuery: haveJQuery,
  getLeftPopOver: getLeftPopOver,
  getPopOverShowConfig: getPopOverShowConfig,
  getDomWidthAndHeight: getDomWidthAndHeight,
  getPercentByValue: getPercentByValue,
  getStyleByValue: getStyleByValue,
  getValueByPosition: getValueByPosition,
  position: position
};
exports["default"] = _default;