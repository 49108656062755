"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.setTableAttributeWhenFixedThead = exports.setWrapLeft = exports.setCellLeft = exports.genGetCellLeftMethod = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

var _number = require("./number");

var _index = require("../config/index");

var _bool = require("./bool");

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 设置位置相关
 * @author kirkzheng
 */
// 根据dom列表，以及制定的列，来计算出总宽度
var getTotalWidthByColumnList = function getTotalWidthByColumnList() {
  var domList = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var listIndex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var result = 0;
  listIndex.map(function (item) {
    if (domList[item] && domList[item].getBoundingClientRect) {
      result += domList[item].getBoundingClientRect().width;
    }

    return item;
  });
  return result;
}; // 生成获取left的方法


var genGetCellLeftMethod = function genGetCellLeftMethod(index, domList, clientWidth) {
  var columnProps = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  var unFixedColumnListBeforeIndex = arguments.length > 4 ? arguments[4] : undefined;
  var fixedColumnListAfterIndex = arguments.length > 5 ? arguments[5] : undefined;
  var _columnProps$fixedAli = columnProps.fixedAlign,
      fixedAlign = _columnProps$fixedAli === void 0 ? '' : _columnProps$fixedAli;
  var positionLeft = 0;
  var i = 0;
  var columnListByToIndex = []; // 在index(包括这一列)的顺序

  if (fixedAlign === 'right') {
    for (; i < index; i++) {
      columnListByToIndex.push(i);
    }

    columnListByToIndex.push(index); // 可视窗口 - 对应列（包括这一列）的总宽度就可以将这一列出现在固定窗口右边，随着scrollLeft的滚动也需要变动
    // 另外，由于这一列后面也有固定的，所以要留位置给后面的列，所以要再计算后面有没有一定固定在右边的列

    var columnListByToIndexWidth = getTotalWidthByColumnList(domList, columnListByToIndex);
    var fixedColumnListAfterIndexWidth = getTotalWidthByColumnList(domList, fixedColumnListAfterIndex);
    positionLeft = clientWidth - columnListByToIndexWidth - fixedColumnListAfterIndexWidth;
  } else {
    // 减去在前面非固定的列的总宽度即可靠左（前面固定的不减去是因为前面的会占位呢）
    positionLeft = 0 - getTotalWidthByColumnList(domList, unFixedColumnListBeforeIndex);
  }

  return function (scrollLeft) {
    var result = 0;

    if (fixedAlign === 'right') {
      result = positionLeft + scrollLeft; // 如果大于0，所以超过原来的位置，这是不可以的

      if (result > 0) {
        result = 0;
      }
    } else {
      result = positionLeft + scrollLeft; // 如果小于0，所以超过原来的位置，这是不可以的

      if (result < 0) {
        result = 0;
      }
    }

    return result;
  };
}; // 固定列的时候设置单元格的偏移量


exports.genGetCellLeftMethod = genGetCellLeftMethod;

var setCellLeft = function setCellLeft() {
  var currentDom = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  var scrollLeft = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var fixedClassName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  var useCSSTransform = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  var tableFixedTdClassName = 'spaui-table-fixed-td';
  var classNameArr = [];
  var currentDomClassName = '';
  var index = -1;
  var shouldBeUpdateClassName = false;

  if (currentDom) {
    currentDomClassName = currentDom.className || '';
    classNameArr = currentDomClassName.split(' ') || [];
    index = (0, _spauiUtils.indexOf)(classNameArr, tableFixedTdClassName);

    if (scrollLeft !== 0) {
      if (index < 0) {
        classNameArr.push(tableFixedTdClassName);
        shouldBeUpdateClassName = true;
      }
    } else {
      if (index > -1) {
        classNameArr.splice(index, 1);
        shouldBeUpdateClassName = true;
      }
    }

    if (fixedClassName) {
      index = (0, _spauiUtils.indexOf)(classNameArr, fixedClassName);

      if (scrollLeft !== 0 && scrollLeft !== '0') {
        if (index < 0) {
          classNameArr.push(fixedClassName);
          shouldBeUpdateClassName = true;
        }
      } else {
        if (index > -1) {
          classNameArr.splice(index, 1);
          shouldBeUpdateClassName = true;
        }
      }
    } // 不相同再设置，避免重绘


    if (shouldBeUpdateClassName) {
      currentDom.className = classNameArr.join(' ');
    }

    if (useCSSTransform) {
      currentDom.style.transform = "translateX(".concat(scrollLeft, "px)");
    } else {
      currentDom.style.left = "".concat(scrollLeft, "px");
    }
  }
}; // 拖动的时候设置表格外部的偏移距离


exports.setCellLeft = setCellLeft;

var setWrapLeft = function setWrapLeft() {
  var self = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var left = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var isTfootWraper = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var _self$props = self.props,
      props = _self$props === void 0 ? {} : _self$props;
  var cssFixedTheadAble = (0, _bool.isFixedTableTheadByCss)(props);
  var cssFixedTotalAble = (0, _bool.isFixedTableTotalByCss)(props);

  if (cssFixedTheadAble && self.theadWrap) {
    // 如果固定thead的，默认都要给thead传位置，不过是否生效由position来决定
    self.theadWrap.style.left = "".concat(left, "px"); // self.theadWrap.style.transform = `translateX(${left}px)`;

    if ((0, _spauiUtils.getStyle)(self.tableTheadWraper, 'position') === 'fixed') {
      self.theadWrap.style.position = 'relative';
    } else {
      self.theadWrap.style.position = 'static';
    }
  }

  if (cssFixedTotalAble && self.tfootWrap && !isTfootWraper) {
    // self.tfootWrap.style.left = left + 'px';
    self.tfootWrap.style.position = 'relative';
  }
};
/**
 * 固定表头的时候需要设置下表格的一些大小位置属性
 */


exports.setWrapLeft = setWrapLeft;

var setTableAttributeWhenFixedThead = function setTableAttributeWhenFixedThead(tableWrapHeight, tbodyHeight, placement) {
  var nodeMap = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  var tableWrap = nodeMap.tableWrap,
      tbodyWrap = nodeMap.tbodyWrap,
      tableTbodyWraper = nodeMap.tableTbodyWraper,
      theadTable = nodeMap.theadTable,
      tbodyTable = nodeMap.tbodyTable;
  var tbodyTableHeight = (0, _number.toInt)(tbodyWrap.getElementsByTagName('table')[0].clientHeight);
  var tableWrapClassName = (tableWrap.className || '').split(' ');

  var _classNameIndex = (0, _spauiUtils.indexOf)(tableWrapClassName, _index.tableFixedTheadClassName);

  tableWrap.style.height = "".concat(tableWrapHeight, "px");
  tableTbodyWraper.style.height = "".concat(tbodyHeight, "px");
  theadTable.setAttribute('data-fixedableThead', 1); // 固定导航需要添加class

  if (_classNameIndex < 0) {
    tableWrapClassName.push(_index.tableFixedTheadClassName);
  }

  _classNameIndex = (0, _spauiUtils.indexOf)(tableWrapClassName, _index.tableScrollClassName);

  if (_classNameIndex > -1) {
    tableWrapClassName.splice(_classNameIndex, 1);
  }

  tableWrap.className = tableWrapClassName.join(' ');
  theadTable.style.width = "".concat(tbodyTable.clientWidth, "px");

  switch (placement) {
    case 'center':
      tableTbodyWraper.scrollTop = (tbodyTableHeight - tbodyHeight) / 2;
      break;

    case 'bottom':
      tableTbodyWraper.scrollTop = tbodyTableHeight - tbodyHeight;
      break;
  }
};

exports.setTableAttributeWhenFixedThead = setTableAttributeWhenFixedThead;
var _default = {
  setCellLeft: setCellLeft,
  genGetCellLeftMethod: genGetCellLeftMethod,
  setWrapLeft: setWrapLeft,
  setTableAttributeWhenFixedThead: setTableAttributeWhenFixedThead
};
exports["default"] = _default;