"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
var InfoComponent = function InfoComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _props$data = props.data,
      data = _props$data === void 0 ? {} : _props$data,
      _props$searchValue = props.searchValue,
      searchValue = _props$searchValue === void 0 ? '' : _props$searchValue,
      _props$highlightable = props.highlightable,
      highlightable = _props$highlightable === void 0 ? true : _props$highlightable,
      ellipsisCount = props.ellipsisCount;
  var id = data.id,
      _data$name = data.name,
      name = _data$name === void 0 ? '' : _data$name,
      _data$label = data.label,
      label = _data$label === void 0 ? '' : _data$label,
      _data$describe = data.describe,
      describe = _data$describe === void 0 ? '' : _data$describe; // 高亮

  var highlight = function highlight(theName) {
    var result = '';

    if (highlightable) {
      // 替换搜索词
      result = theName.replace(searchValue, "<strong class=\"feed-highlight spaui-userpicker-feed-highlight\">".concat(searchValue, "</strong>"));
    } else {
      result = theName;
    }

    return result;
  }; // 省略


  var ellipsis = function ellipsis(theDescribe, maxCount) {
    var result = ''; // 超过最大数量的时候，只展示maxCount个字符，从中间截断

    if (theDescribe.length > maxCount) {
      /* eslint-disable-next-line */
      result = "<span class=\"feed-desc-prefix spaui-userpicker-feed-desc-prefix\">".concat(theDescribe.slice(0, Math.floor(maxCount / 2)), "</span>");
      result += '<span class="user-ellipse">...</span>';
      /* eslint-disable-next-line */

      result = "".concat(result, "<span class=\"feed-desc-suffix  spaui-userpicker-feed-desc-suffix\">").concat(theDescribe.slice(theDescribe.length - Math.floor(maxCount / 2)), "</span>");
    } else {
      result = theDescribe;
    }

    return result;
  };

  return _react["default"].createElement("div", {
    className: "feed-info spaui-userpicker-feed-info"
  }, _react["default"].createElement("span", {
    className: "feed-title spaui-userpicker-feed-title",
    dangerouslySetInnerHTML: {
      __html: highlight(name || label)
    }
  }), typeof id !== 'undefined' ? _react["default"].createElement("p", {
    className: "feed-desc spaui-userpicker-feed-desc"
  }, _react["default"].createElement("span", {
    className: "feed-desc-prefix  spaui-userpicker-feed-desc-prefix"
  }, "ID: ", id), "\xA0", _react["default"].createElement("span", {
    className: "feed-desc-suffix  spaui-userpicker-feed-desc-suffix"
  }, describe)) : _react["default"].createElement("p", {
    className: "feed-desc spaui-userpicker-feed-desc",
    dangerouslySetInnerHTML: {
      __html: ellipsis(describe, ellipsisCount)
    }
  }));
};

var _default = InfoComponent;
exports["default"] = _default;