"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultLocale = void 0;

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
var defaultLocale = {
  JanuaryLabel: '1月',
  FebruaryLabel: '2月',
  MarchLabel: '3月',
  AprilLabel: '4月',
  MayLabel: '5月',
  JuneLabel: '6月',
  JulyLabel: '7月',
  AugustLabel: '8月',
  SeptemberLabel: '9月',
  OctoberLabel: '10月',
  NovemberLabel: '11月',
  DecemberLabel: '12月',
  yearLabel: '年',
  hourLabel: '时',
  minuteLabel: '分',
  secondLabel: '秒',
  weekListMapBySunDay: ['日', '一', '二', '三', '四', '五', '六'],
  weekListMapByMonDay: ['一', '二', '三', '四', '五', '六', '日'],
  todayLabel: '今天',
  yesterdayLabel: '昨天',
  daysAgoLabel: '{value}天前',
  lastDaysLabel: '最近{value}天',
  fromNowDaysLabel: '往后{value}天',
  submitLabel: '确定',
  cancelLabel: '取消',
  separateLabel: '至',
  errorTipDefaultLabelBySingle: '请填写正确日期格式，<br />如：“2019-03-05{timeTip}”为“20190305{time}”',
  // 默认提示
  errorTipValueIsNotOptionalLabelBySingle: '请重新填写日期，当前日期可选范围为：<br />{date1} 至 {date2}',
  errorTipFormatErrorBySingle: '"时分秒"格式错误或不在可选择范围内',
  errorTipDefaultLabelByRange: '请填写正确日期格式，<br />如：“2019-03-05{startTimeTip}至2019-03-06{endTimeTip}”为<br />“20190305{startTime}&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;20190306{endTime}',
  // 默认提示
  errorTipValueIsNotOptionalLabelByRange: '请重新填写日期，当前日期可选范围为：<br />{date1} 至 {date2}',
  errorTipFormatErrorByRange: '"时分秒"格式错误或不在可选择范围内',
  errorTipSpacingErrorByRange: '请重新填写日期，开始日期与结束日期的距离，最短为：{shortestRange}天，最长为：{longestRange}天，目前为{daySpaces}天'
};
exports.defaultLocale = defaultLocale;
var _default = {
  defaultLocale: defaultLocale
};
exports["default"] = _default;