"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _spauiButton = _interopRequireDefault(require("@tencent/spaui-button"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
var _default = function _default(props) {
  var type = props.type,
      onClick = props.onClick,
      disabled = props.disabled,
      label = props.label;
  var result = null;

  if (type === 'icon') {
    result = _react["default"].createElement("i", {
      className: "icon icon-double-rarr"
    }, _react["default"].createElement("i", {
      className: "icon icon-arr icon-arr-right"
    }), _react["default"].createElement("i", {
      className: "icon icon-arr icon-arr-right"
    }));
  } else {
    result = _react["default"].createElement(_spauiButton["default"], {
      displayType: "primary",
      onClick: onClick,
      disabled: disabled
    }, label);
  }

  return result;
};

exports["default"] = _default;