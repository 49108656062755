"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultProps = exports.propTypes = exports.config = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

var _total_content_component = _interopRequireDefault(require("./component/total_content_component"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件参数配置
 */

/**
 * Table 接口参数
 * @alias TableProps
 * @description 展示行列数据。
 * @property {titleline} 默认支持参数
 * @property {blankline} className;style;id;data-*;accessKey;dir;draggable;tabIndex;title;htmlRef;
 * @property {titleline} 主题相关参数
 * @property {bool} [useOldClass=true] 是否使用基础className
 * @property {titleline} 基本属性
 * @property {array} data=[]] 表格数据
 * @property {array|string} [rowKey] 可以指定行中某字段数据为该tr的key防止数据变更没重新渲染(支持一个或多个)*如：rowKey='ctr' or rowKey=['ctr', 'id']*
 * @property {number} [minColumnWidth=0] 列的最小宽度
 * @property {bool} [hideThead=false] 是否隐藏thead
 * @property {bool} [wholeRowIsOptional=false] 是否整行点击可选
 * @property {func} [setRowAndColSpan] 设置合并单元格
 * @property {array} [titleOptions=[]] 标题配置*（滚动的时候根据match条件进行匹配，匹配到会在这一行之前加一个标题行，具体参数看案例）*
 * @property {bool} [supportingHierarchicalData=true] 是否支持data为多层数据*开启后可在Column的name中设置类viewcount.creative*
 * @property {func} [onBodyScroll] 表格body区域滚动回调*onBodyScroll = (event, [offsetTop, offsetBottom], totalHeight)*
 * @property {titleline} 宽度拖动相关属性
 * @property {string} [adjustWidthClassName=''] 拖动的时候在对应的单元格添加的className
 * @property {string} [adjustWidthClassNameTrigger=''] 添加adjustWidthClassName的方式*可选值：hover*
 * @property {func} [onColumnWidthChange] 列宽度调整回调*onColumnWidthChange=function(event, data) \ndata为对应列的宽度配置*
 * @property {bool} [scrollTarget] 滚动对象*目前只支持scrollTarget='body'的情况，开启后如果使用cssFixedTheadAble或者cssFixedTotalAble组件会自动计算*
 * @property {bool} [autoForCssFixedThead=true] 自动计算固定表格头部
 * @property {func} [setCssFixedTheadSpace] 距离表格多少间距的时候就开始固定thead*setCssFixedTheadSpace=()=> return number; (默认为0)*
 * @property {bool} [autoForCssFixedTotal=true] 自动计算固定表格尾部
 * @property {func} [setCssFixedTotalSpace] 距离表格多少间距的时候就开始固定total*setCssFixedTotalSpace=()=>return number; *
 * @property {titleline} 数据加载中属性
 * @property {bool} [loading=false] 是否加载数据中
 * @property {bool} [fixedLoadingComponent=false] 是否固定空提示的模块
 * @property {bool} [hideScrollBar=false] 是否隐藏滚动条
 * @property {bool} [loadingType='default'] loading类型*可选值：default, cover*
 * @property {string} [loadingTrClassName='spaui-table-loading-tr'] loading对应tr的className
 * @property {func|element} [LoadingComponent=null] 自定义Loading结构*(具体参数看案例)*
 * @property {titleline} 数据为空属性
 * @property {string|element} [emptyText='抱歉，暂无相关数据记录'] 数据为空的提示
 * @property {bool} [fixedEmptyComponent=false] 是否固定空提示的模块
 * @property {string} [emptyTrClassName='spaui-table-empty-tr'] 空数据对应tr的className
 * @property {func | element} [EmptyComponent] 数据为空显示的Component*(具体参数看案例)*
 * @property {titleline} 排序属性
 * @property {string} [sortName=''|[]] 排序字段名*多字段排序时为数组*
 * @property {string} [sortOrder=''|{}] 排序标记*多字段排序时为对象, key 为字段名称，value 为排序标记，可选值：asc(升), desc（降）*
 * @property {func} [onSort] 排序回调*onSort=function(event, sortName, sortOrder, clickSortName) *
 * @property {bool} [multiFieldSort=false] 是否为多字段排序
 * @property {number} [maxMultiFieldSortSize] 多字段排序支持的最大数量
 * @property {string} [thClickFocusClassName=''] 表头单元格连续点击焦点className(移出即消失)
 * @property {titleline} 拖动属性
 * @property {func} [DragComponent] 表格内容拖动组件
 * @property {func} [onDrag] 表格行拖动回调
 * @property {titleline} 筛选属性
 * @property {string} [filterName=''] 过滤字段名
 * @property {string} [filterValue=''] 过滤值
 * @property {func} [onFilter] 过滤回调 *onFilter=function(event, filterName, filterValue, filterMultiple)*
 * @property {titleline} 固定thead相关属性
 * @property {bool} [cssFixedTheadAble=false] 是否thead在页面层级固定(需要css固定)
 * @property {object} [theadWraperStyle={}] cssFixedTheadAble为true时可以通过这个设置thead的style
 * @property {number} [fixedHeight=0] 多少高度就固定thead（为0的时候不固定，fixedHeight包含了表头高度）
 * @property {number} [fixedRows=0] 多少行就固定thead（为0的时候不固定，可以通过判断data长度来决定是否使用）
 * @property {string} [fixedPlacement='top'] 默认thead固定的位置*可选值: top, center, bottom*
 * @property {bool} [notFixedTheadOnEmptyData=true] 默认没有表格数据时不进行固定头部
 * @property {titleline} 而外标题提示属性
 * @property {bool} [fixedTitle=false] 固定头部的时候，是否需要根据列来显示标题
 * @property {string} [fixedTitleClassName=''] fixedTitle tr的className
 * @property {object} [fixedTitleStyle={}] fixedTtitle tr的style
 * @property {func} [renderFixedTitle] 根据当前的数据来设置渲染自定义标题*renderFixedTitle = (event, data, thLength) => return innerHTML*
 * @property {titleline} 表格样式属性
 * @property {bool} [striped=false] 表格是否带条纹
 * @property {bool} [bordered=true] 表格是否带边框
 * @property {bool} [hover=false] 表格是否带hover状态
 * @property {bool} [condensed=false] 表格内边距是否变小
 * @property {bool} [responsive=false] 是否响应式表格
 * @property {string} [tableClassName=''] table元素的className
 * @property {string} [cellInnerClassName='spaui-table-td-inner'] 单元格内部div的className
 * @property {func} [setTrClassName=function(type, data){}] 设置行的className
 * @property {func} [setCellStyle=function(type, name, value, rowIndex, colIndex, data)] 可根据条件单独设置单元格的style样式
 * @property {func} [setCellTitle=function(type, name, value, rowIndex, colIndex, data, props)] 可根据需要设置对应单元格的title
 * @property {bool} [additionalDataStripeInherit=false] 附加数据行是否继承前置数据
 * @property {func} [setOddEvenClassName] 设置data数据行的奇偶className*setOddEvenClassName(item, index)=>return className*
 * @property {titleline} 总计属性
 * @property {bool} [cssFixedTotalAble=false] 是否total在页面层级固定
 * @property {bool} [hideBodyTotal=false] 是否隐藏 body 中的total行
 * @property {object} [totalWraperStyle={}] cssFixedTotalAble为true时可以通过这个设置total的style
 * @property {bool} [total=false] 是否显示总计
 * @property {object} [totalData] 自传总计数据*传了之后总计数据就直接用totalData展示*
 * @property {string|element} [totalTip=''] 支持总计的位置展示一段提示内容（如果totalTip有内容优先展示totalTip）
 * @property {string} [totalLabel='总计：'] 总计第一列标题
 * @property {number} [totalLabelColSpan=1] 总计文案显示占几行
 * @property {func} [TotalContentComponent] 总计内容自定义组件
 * @property {titleline} 扩展行属性
 * @property {func|element} [ExpandComponent=null] 点击行扩展的Component*(具体参数看案例)*
 * @property {object} [expandComponentProps={}] ExpandComponent附加的参数
 * @property {string} [expandComponentTdClassName=''] ExpandComponent的td className
 * @property {bool|func} [showExpandComponent=true] 是否展示ExpandComponent(也可为函数返回bool)
 * @property {bool} [expandComponentFixedable=false] 点击行扩展的Component是否fixed
 * @property {string} [expandPlacement='bottom'] 扩展的位置
 * @property {string} [expandTrActiveClassName='spaui-table-tr-active'] 可以扩展的行展开时候的className
 * @property {array} [expandOptions=[]] 扩展模块的数据
 * @property {func} [pushData=[]] 可以自行根据上一行的数据来确定是否要另外添加数据
 * @property {func} [wheelPreventDefault=false] 是否阻止滚轮的默认事件（可用于在表格内横向滚动时不会触发回退页面）
 * @property {titleline} 前置内容属性
 * @property {bool} [showPreContent=false] 是否展示preContent
 * @property {string} [preContent=null] 放在tbody最前面的内容
 * @property {func|element} [PreContentComponent] = 前置内容组件（props为Table props）
 * @property {bool} [preContentFixed=false] 前置内容是否固定
 * @property {string} [preContentTrClassName=''] 前置内容所在tr的class
 * @property {string} [preContentTdClassName=''] 前置内容所在td的class
 * @property {titleline} 表格行操作事件属性
 * @property {func} [onClickTr=function(event, type, data){}] 点击行回调（具体参数见下方案例）
 * @property {func} [onMouseOverTr=function(event, type, data){}] 鼠标移入行回调（具体参数见下方案例）
 * @property {func} [onMouseOutTr=function(event, type, data){}] 鼠标移出行回调（具体参数见下方案例）
 * @property {titleline} 占位属性
 * @property {element} [topSpaceElement = null] 在body表格上的顶部占位
 * @property {element} [bottomSpaceElement = null] 在body表格下的顶部占位
 * @property {boolean} [needResizeObserver=true] 是否监听表格resize重新计算宽度
 * @property {titleline} 自定义样式属性
 * @property {string|number} [cellPadding] 单元格的padding
 * @property {string|number} [firstCellPadding] 首列的padding(仅use-padding类名下生效)
 * @property {string|number} [lastCellPadding] 尾列的padding(仅use-padding类名下生效)
 * @property {string|number} [cellHeight] 单元格的最小高度
 * @property {string|number} [theadCellHeight] thead 单元格的最小高度
 * @property {string|number} [tfootCellHeight] tfoot 单元格的最小高度
 * @property {string} [cellBorderColor] 单元格边框颜色(仅use-bordered, use-border-bottom, use-rounded类名下生效)
 * @property {string} [borderColor] 表格外框颜色(仅use-bordered, use-border-bottom, use-rounded类名下生效)
 * @property {string} [theadBg] 表头颜色(仅use-thead-bg类名下生效)
 * @property {string} [trHoverBg] 行 hover 背景色(仅use-hover类名下生效)
 * @property {titleline} 其他属性
 * @property {boolean} [useCSSTransform=false] 是否使用translate代替left固定列
 *
 * @example
 * &lt;LoadingComponent
    {...this.props}
/&gt;
 *
 &lt;EmptyComponent
    {...this.props}
/&gt;
 *
 &lt;ExpandComponent
    type={item._expandType}
    data={item._expandData}
    additionalData={item._additionalData}
    expandComponentData={item._expandComponentData}
/&gt;
 *
 * 展开式都初始化ExpandComponent，实际上放的就是展开某行的数据，支持多行展开也就是多放几行的数据到数组里面
 * expandOptions = [{
    id: 1,
    viewcount: 10,
    click: 1,
    download: 100
}];

展开后不再初始化ExpandComponent，通过_show来控制展开或隐藏
* expandOptions = [{
    id: 1,
    viewcount: 10,
    click: 1,
    download: 100,
    _show: false
}];

展开后是否展示扩展数据，非下划线的数据实际上就是被展开行的数据，是根据这个来判断是哪行进行展开的，
通过_additional控制是否要添加，_additionalData为扩展数据，_optional用于控制是否继承可选项，_expandComponentData用于给ExpandComponent添加附带数据
* expandOptions = [{
    id: 1,
    viewcount: 10,
    click: 1,
    download: 100,
    _additional: true,
    _additionalData: [{}, {}],
    _expandComponentData： {},
    _optional: false
}];

扩展组件
<ExpandComponent
    ...expandComponentProps,
    data   // 展开某行的数据
    additionalData  // 扩展数据
    expandComponentData // 额外数据
/>

// 在id为2的行前会加上一个tr放置的内容就是title的内容
titleOptions = [{
    match: {
        id: 2
    },
    data: {
        title: (
            &lt;p&gt;2&lt;/p&gt;
        )
    }
}]

setRowAndColSpan=function(type, name, value, rowIndex, colIndex, data, autoRowAndColSpanData, columnLength){}
// type类型，name字段名，value对应单元格的值，rowIndex与colIndex所在的位置
*
*
* type:行类型，data:行数据*type值定义：data(数据行),title(头部)，expand(扩展行)，additional(添加行)，pushData(根据上一行来添加的新行)
* onClickTr=function(event, type, data){}
* onMouseOverTr=function(event, type, data){}
* onMouseOutTr=function(event, type, data){}
*/
var config = {
  data: {
    type: _spauiUtils.PropTypes.array.isRequired,
    defaultValue: [],
    visualData: {
      type: 'array'
    }
  },
  rowKey: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.array, _spauiUtils.PropTypes.string])
  },
  supportingHierarchicalData: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true,
    visualData: {
      type: 'switch'
    }
  },
  useOldClass: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true,
    visualData: {
      type: 'switch'
    }
  },
  emptyText: {
    type: _spauiUtils.PropTypes.any,
    defaultValue: '抱歉，暂无相关数据记录',
    visualData: {
      type: 'input'
    }
  },
  fixedEmptyComponent: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  EmptyComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element])
  },
  emptyTrClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'spaui-table-empty-tr',
    visualData: {
      type: 'input'
    }
  },
  loadingTrClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'spaui-table-loading-tr',
    visualData: {
      type: 'input'
    }
  },
  loading: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false,
    visualData: {
      type: 'switch'
    }
  },
  fixedLoadingComponent: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  useCSSTransform: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  hideScrollBar: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false,
    visualData: {
      type: 'switch'
    }
  },
  loadingType: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'default',
    visualData: {
      type: 'radio',
      value: ['default', 'cover']
    }
  },
  LoadingComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element])
  },
  tableClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: '',
    visualData: {
      type: 'input'
    }
  },
  expandTrActiveClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'spaui-table-tr-active',
    visualData: {
      type: 'input'
    }
  },
  striped: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false,
    visualData: {
      type: 'switch'
    }
  },
  bordered: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true,
    visualData: {
      type: 'switch'
    }
  },
  hover: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true,
    visualData: {
      type: 'switch'
    }
  },
  hideThead: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false,
    visualData: {
      type: 'switch'
    }
  },
  condensed: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false,
    visualData: {
      type: 'switch'
    }
  },
  responsive: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false,
    visualData: {
      type: 'switch'
    }
  },
  multiFieldSort: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  maxMultiFieldSortSize: {
    type: _spauiUtils.PropTypes.number
  },
  thClickFocusClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  sortName: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.array]),
    defaultValue: '',
    visualData: {
      type: 'input'
    }
  },
  sortOrder: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.object]),
    defaultValue: '',
    visualData: {
      type: 'radio',
      value: ['', 'asc', 'desc']
    }
  },
  onSort: {
    type: _spauiUtils.PropTypes.func
  },
  filterName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: '',
    visualData: {
      type: 'input'
    }
  },
  filterValue: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number, _spauiUtils.PropTypes.array]),
    defaultValue: '',
    visualData: {
      type: 'input'
    }
  },
  onFilter: {
    type: _spauiUtils.PropTypes.func
  },
  fixedHeight: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number]),
    defaultValue: 0,
    visualData: {
      type: 'number'
    }
  },
  fixedRows: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number]),
    defaultValue: 0,
    visualData: {
      type: 'number'
    }
  },
  fixedTitle: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false,
    visualData: {
      type: 'switch'
    }
  },
  fixedTitleClassName: {
    type: _spauiUtils.PropTypes.string
  },
  fixedTitleStyle: {
    type: _spauiUtils.PropTypes.object
  },
  renderFixedTitle: {
    type: _spauiUtils.PropTypes.func
  },
  notFixedTheadOnEmptyData: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  fixedPlacement: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'top',
    visualData: {
      type: 'radio',
      value: ['top', 'center', 'bottom']
    }
  },
  wholeRowIsOptional: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false,
    visualData: {
      type: 'switch'
    }
  },
  total: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false,
    visualData: {
      type: 'switch'
    }
  },
  totalData: {
    type: _spauiUtils.PropTypes.object
  },
  totalTip: {
    type: _spauiUtils.PropTypes.any,
    defaultValue: ''
  },
  totalLabel: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: '总计：',
    visualData: {
      type: 'input'
    }
  },
  totalLabelColSpan: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: 1
  },
  TotalContentComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _total_content_component["default"]
  },
  DragComponent: {
    type: _spauiUtils.PropTypes.func
  },
  onDrag: {
    type: _spauiUtils.PropTypes.func
  },
  setTrClassName: {
    type: _spauiUtils.PropTypes.func
  },
  setCellStyle: {
    type: _spauiUtils.PropTypes.func
  },
  setCellTitle: {
    type: _spauiUtils.PropTypes.func,
    defaultValue: function defaultValue() {
      return '';
    }
  },
  additionalDataStripeInherit: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  setOddEvenClassName: {
    type: _spauiUtils.PropTypes.func,
    defaultValue: function defaultValue(item, index) {
      if ((index + 1) % 2 === 1) {
        return 'spaui-table-tr-data-odd';
      }

      return 'spaui-table-tr-data-even';
    }
  },
  propsWholeRowIsOptional: {
    type: _spauiUtils.PropTypes.bool
  },
  onClickTr: {
    type: _spauiUtils.PropTypes.func
  },
  onMouseOverTr: {
    type: _spauiUtils.PropTypes.func
  },
  onMouseOutTr: {
    type: _spauiUtils.PropTypes.func
  },
  ExpandComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element])
  },
  expandComponentProps: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  expandComponentTdClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  showExpandComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.bool, _spauiUtils.PropTypes.func]),
    defaultValue: true
  },
  expandComponentFixedable: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false,
    visualData: {
      type: 'switch'
    }
  },
  expandPlacement: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'bottom',
    visualData: {
      type: 'radio',
      value: ['top', 'bottom']
    }
  },
  expandOptions: {
    type: _spauiUtils.PropTypes.array,
    defaultValue: [],
    visualData: {
      type: 'array'
    }
  },
  pushData: {
    type: _spauiUtils.PropTypes.func
  },
  wheelPreventDefault: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  showPreContent: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  preContent: {
    type: _spauiUtils.PropTypes.any,
    defaultValue: null
  },
  PreContentComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element])
  },
  preContentFixed: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  preContentTrClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  preContentTdClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  titleOptions: {
    type: _spauiUtils.PropTypes.array,
    defaultValue: [],
    visualData: {
      type: 'array'
    }
  },
  setRowAndColSpan: {
    type: _spauiUtils.PropTypes.func
  },
  adjustWidthClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: '',
    visualData: {
      type: 'input'
    }
  },
  adjustWidthClassNameTrigger: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'mousedown',
    visualData: {
      type: 'radio',
      value: ['mousedown', 'hover']
    }
  },
  adjustMinWidth: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.number, _spauiUtils.PropTypes.string]),
    defaultValue: 55,
    visualData: {
      type: 'number'
    }
  },
  minColumnWidth: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.number, _spauiUtils.PropTypes.string]),
    defaultValue: 0,
    visualData: {
      type: 'number'
    }
  },
  cssFixedTheadAble: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false,
    visualData: {
      type: 'switch'
    }
  },
  theadAlwaysUseFixed: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  cssFixedTotalAble: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false,
    visualData: {
      type: 'switch'
    }
  },
  hideBodyTotal: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  theadWraperStyle: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {},
    visualData: {
      type: 'object'
    }
  },
  cellInnerClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'spaui-table-td-inner',
    visualData: {
      type: 'input'
    }
  },
  onColumnWidthChange: {
    type: _spauiUtils.PropTypes.func
  },
  CheckboxProps: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {},
    visualData: {
      type: 'object'
    }
  },
  RadioProps: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {},
    visualData: {
      type: 'object'
    }
  },
  clickSpace: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: 10,
    visualData: {
      type: 'number'
    }
  },
  scrollTarget: {
    type: _spauiUtils.PropTypes.any,
    defaultValue: ''
  },
  autoForCssFixedThead: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  setCssFixedTheadSpace: {
    type: _spauiUtils.PropTypes.func
  },
  setCssFixedTotalSpace: {
    type: _spauiUtils.PropTypes.func
  },
  autoForCssFixedTotal: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  topSpaceElement: {
    type: _spauiUtils.PropTypes.any,
    defaultValue: null
  },
  bottomSpaceElement: {
    type: _spauiUtils.PropTypes.any,
    defaultValue: null
  },
  onBodyScroll: {
    type: _spauiUtils.PropTypes.func
  },
  adjustWidthSync: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  setAllBodyCellMaxMinWidth: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  fixedTotalNoUseColGroup: {
    type: _spauiUtils.PropTypes.bool
  },
  needResizeObserver: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  needResizeTableWidth: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  cellPadding: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.number, _spauiUtils.PropTypes.string])
  },
  firstCellPadding: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.number, _spauiUtils.PropTypes.string])
  },
  lastCellPadding: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.number, _spauiUtils.PropTypes.string])
  },
  cellHeight: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.number, _spauiUtils.PropTypes.string])
  },
  theadCellHeight: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.number, _spauiUtils.PropTypes.string])
  },
  tfootCellHeight: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.number, _spauiUtils.PropTypes.string])
  },
  theadBg: {
    type: _spauiUtils.PropTypes.string
  },
  cellBorderColor: {
    type: _spauiUtils.PropTypes.string
  },
  borderColor: {
    type: _spauiUtils.PropTypes.string
  },
  trHoverBg: {
    type: _spauiUtils.PropTypes.string
  }
}; // 转化成PureComponent组件的配置

exports.config = config;
var configData = (0, _spauiUtils.formatProps)(config); // 导出

var propTypes = configData.propTypes;
exports.propTypes = propTypes;
var defaultProps = configData.defaultProps;
exports.defaultProps = defaultProps;
var _default = {
  config: config,
  propTypes: propTypes,
  defaultProps: defaultProps
};
exports["default"] = _default;