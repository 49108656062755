"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _message = require("./message");

var _spauiUtils = require("@tencent/spaui-utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var messageTypeList = ['success', 'info', 'warn', 'strongwarn', 'error', 'loading'];

var open = function open(props) {
  return (0, _message.showMessage)(props);
};
/**
 * 生成默认的 message 快捷调用函数
 */


var genMessageFunc = function genMessageFunc() {
  var result = {};
  messageTypeList.forEach(function (typeName) {
    result[typeName] = function () {
      var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var duration = arguments.length > 1 ? arguments[1] : undefined;
      var props = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      // 支持多种参数格式
      if (typeof message === 'string' || _react["default"].isValidElement(message)) {
        return (0, _message.showMessage)(_objectSpread({
          type: typeName,
          message: message,
          duration: duration
        }, props));
      }

      if ((0, _spauiUtils.isObject)(message)) {
        var newProps = message;
        return (0, _message.showMessage)(_objectSpread({
          type: typeName
        }, newProps));
      }
    };
  });
  return result;
};

var _default = _objectSpread({
  open: open,
  remove: _message.remove,
  destroy: _message.destroy,
  config: _message.updateConfig
}, genMessageFunc());

exports["default"] = _default;