"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _spauiRadio = _interopRequireDefault(require("@tencent/spaui-radio"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var RadioComponent = function RadioComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var checked = props.checked,
      _props$data = props.data,
      data = _props$data === void 0 ? {} : _props$data,
      onChange = props.onChange,
      _props$fieldNames = props.fieldNames,
      fieldNames = _props$fieldNames === void 0 ? {} : _props$fieldNames,
      _props$disabled = props.disabled,
      disabled = _props$disabled === void 0 ? false : _props$disabled,
      onClick = props.onClick,
      canCancelCheck = props.canCancelCheck,
      classNameMap = props.classNameMap;
  var _fieldNames$valueFiel = fieldNames.valueFieldName,
      valueFieldName = _fieldNames$valueFiel === void 0 ? 'desc' : _fieldNames$valueFiel;
  return _react["default"].createElement("span", {
    className: classNameMap.TreeRadio
  }, _react["default"].createElement(_spauiRadio["default"], {
    onClick: onClick,
    onChange: onChange,
    checked: checked,
    disabled: disabled,
    value: data[valueFieldName],
    canCancelCheck: canCancelCheck
  }));
};

var _default = RadioComponent;
exports["default"] = _default;