"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.isChecked = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * 判断itemValue是否在value中或者相等
 * @param {*} value 数据或者基础数据
 * @param {*} itemValue 子数据
 * @param {*} multiple 是否多选
 */
var isChecked = function isChecked(value, itemValue) {
  var result = false;

  if ((0, _spauiUtils.isArray)(value)) {
    result = (0, _spauiUtils.indexOf)(value, itemValue) > -1;
  } else {
    if (typeof value !== 'undefined' && "".concat(value).length > 0) {
      result = "".concat(itemValue) === "".concat(value);
    }
  }

  return result;
};

exports.isChecked = isChecked;
var _default = {
  isChecked: isChecked
};
exports["default"] = _default;