"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _spauiCheckbox = _interopRequireDefault(require("@tencent/spaui-checkbox"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * 展开收起图标
 */
var CheckboxComponent = function CheckboxComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var checked = props.checked,
      _props$data = props.data,
      data = _props$data === void 0 ? {} : _props$data,
      onChange = props.onChange,
      _props$fieldNames = props.fieldNames,
      fieldNames = _props$fieldNames === void 0 ? {} : _props$fieldNames,
      _props$indeterminate = props.indeterminate,
      indeterminate = _props$indeterminate === void 0 ? false : _props$indeterminate,
      _props$disabled = props.disabled,
      disabled = _props$disabled === void 0 ? false : _props$disabled,
      onClick = props.onClick,
      classNameMap = props.classNameMap;
  var _fieldNames$valueFiel = fieldNames.valueFieldName,
      valueFieldName = _fieldNames$valueFiel === void 0 ? 'desc' : _fieldNames$valueFiel;
  return _react["default"].createElement("span", {
    className: classNameMap.TreeCheckbox
  }, _react["default"].createElement(_spauiCheckbox["default"], {
    onClick: onClick,
    onChange: onChange,
    checked: checked,
    disabled: disabled,
    indeterminate: indeterminate,
    value: data[valueFieldName]
  }));
};

var _default = CheckboxComponent;
exports["default"] = _default;