"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

// https://codesandbox.io/s/github/react-dnd/react-dnd/tree/gh-pages/examples_hooks_js/04-sortable/simple?from-embed
var ImgSortComponent = function ImgSortComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var index = props.index,
      onSort = props.onSort,
      children = props.children,
      className = props.className,
      ReactDnD = props.ReactDnD,
      _props$style = props.style,
      style = _props$style === void 0 ? {} : _props$style,
      sortDragItemClassName = props.sortDragItemClassName,
      sortDragItemStyle = props.sortDragItemStyle,
      onHover = props.onHover;
  var haveReactDnD = _typeof(ReactDnD) === 'object' && ReactDnD;

  if (!haveReactDnD || !ReactDnD.useDrag || !ReactDnD.useDrop || !_react["default"].useRef) {
    return _react["default"].createElement("li", {
      style: style,
      className: className
    }, children);
  }

  var useDrag = ReactDnD.useDrag,
      useDrop = ReactDnD.useDrop;

  var ref = _react["default"].useRef(null);

  var type = 'imgSortComponent';

  var _useDrop = useDrop({
    accept: type,
    hover: function hover(item, monitor) {
      if (!ref.current) {
        return;
      }

      var dragIndex = item.index;
      var hoverIndex = index; // Don't replace items with themselves

      if (dragIndex === hoverIndex) {
        return;
      } // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      // item.dragIndex = dragIndex;


      item.hoverIndex = hoverIndex;

      if (monitor.isOver()) {
        onHover(dragIndex, hoverIndex);
      } else {
        onHover(dragIndex, -1);
      }
    },
    drop: function drop(item) {
      onSort(item.index, item.hoverIndex);
      onHover(item.index, -1);
    },
    collect: function collect(monitor) {
      return {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
      };
    }
  }),
      _useDrop2 = _slicedToArray(_useDrop, 2),
      isOver = _useDrop2[0].isOver,
      drop = _useDrop2[1];

  if (!isOver) {
    onHover(index, -1);
  }

  var _useDrag = useDrag({
    item: {
      type: type,
      index: index
    },
    collect: function collect(monitor) {
      return {
        isDragging: monitor.isDragging()
      };
    }
  }),
      _useDrag2 = _slicedToArray(_useDrag, 2),
      isDragging = _useDrag2[0].isDragging,
      drag = _useDrag2[1];

  drag(drop(ref));
  var sortDragItemStyleData = {};

  if (isDragging) {
    if (typeof sortDragItemStyle === 'undefined') {
      if (!sortDragItemClassName) {
        sortDragItemStyleData = {
          cursor: 'move',
          opacity: 0.5
        };
      }
    } else {
      sortDragItemStyleData = sortDragItemStyle;
    }
  }

  return _react["default"].createElement("li", {
    ref: ref,
    style: _objectSpread({}, style, {}, sortDragItemStyleData),
    className: (0, _classnames["default"])(className, isDragging && sortDragItemClassName ? sortDragItemClassName : ''),
    "data-index": index
  }, children);
};

var _default = ImgSortComponent;
exports["default"] = _default;