"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.Import = exports.Picker = exports.ImportUser = exports.UserPicker = void 0;

var _picker = _interopRequireDefault(require("./picker"));

var _import2 = _interopRequireDefault(require("./import"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
// 因为可以用于不包括User的场景，所以不叫UserPicker，ImportUser了，但是还是需要兼容的
var UserPicker = _picker["default"];
exports.UserPicker = UserPicker;
var ImportUser = _import2["default"];
exports.ImportUser = ImportUser;
var Picker = _picker["default"];
exports.Picker = Picker;
var Import = _import2["default"]; // 调试信息
// if (process.env.NODE_ENV !== 'production') {
//     const {whyDidYouUpdate} = require('why-did-you-update');
//     whyDidYouUpdate(React);
// }

exports.Import = Import;
var _default = {
  UserPicker: UserPicker,
  Picker: Picker,
  ImportUser: ImportUser,
  Import: Import
};
exports["default"] = _default;