"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.getTopOfOverlay = exports.getLeftOfOverlay = exports.getScrollLeftOfDom = exports.getScrollTopOfDom = exports.getClientSize = exports.getChild = exports.getCssTextData = exports.haveJQuery = exports.getBody = exports.uniqueId = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

var _react = _interopRequireWildcard(require("react"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

// 生成唯一id
var uniqueId = function uniqueId() {
  return (0, _spauiUtils.uniqueId)('spaui-popover');
};

exports.uniqueId = uniqueId;

var getBody = function getBody() {
  var result = null;

  if ((typeof document === "undefined" ? "undefined" : _typeof(document)) === 'object') {
    result = document.body || document.getElementsByTagName('body')[0];
  }

  return result;
};

exports.getBody = getBody;
var haveJQuery = (typeof window === "undefined" ? "undefined" : _typeof(window)) === 'object' && typeof window.jQuery !== 'undefined'; // 把css字符串变成object，比如 'display:block;color: red;' 转化成{display: 'block', color: 'red'}

exports.haveJQuery = haveJQuery;

var getCssTextData = function getCssTextData(text) {
  var result = {};
  var arr = [];

  if (text) {
    arr = text.split(';');
    arr.map(function (item) {
      var itemArr = [];

      if (item) {
        itemArr = item.split(': ');

        if (itemArr.length === 2) {
          result[itemArr[0].replace(/^\s/gi, '')] = itemArr[1];
        }
      }

      return item;
    });
  }

  return result;
}; // 获取子元素


exports.getCssTextData = getCssTextData;

var getChild = function getChild(children) {
  var props = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var TagName = props.stringTagName || 'span';
  var child; // 如果是多个元素，只获取第一个非字符串的元素

  if ((0, _spauiUtils.isArray)(children)) {
    children.map(function (item, index) {
      if (!child && typeof item !== 'string') {
        child = (0, _react.cloneElement)(item, {
          key: index
        });
      }

      return item;
    });
  } else {
    if (typeof children === 'string' && children) {
      child = _react["default"].createElement(TagName, (0, _spauiUtils.globalProps)(props), children);
    } else {
      child = children;
    }
  }

  return child;
}; // 获取客户端的宽高


exports.getChild = getChild;

var getClientSize = function getClientSize() {
  return {
    clientWidth: Math.max(document.body.clientWidth, document.documentElement.clientWidth),
    clientHeight: Math.max(document.body.clientHeight, document.documentElement.clientHeight)
  };
}; // 获取对应结构的scrollTop;


exports.getClientSize = getClientSize;

var getScrollTopOfDom = function getScrollTopOfDom(target) {
  var result = 0;

  if (target) {
    result = target.scrollTop;

    if (result < 1 && haveJQuery) {
      result = jQuery(target).scrollTop();
    }
  } else {
    if ((typeof document === "undefined" ? "undefined" : _typeof(document)) === 'object') {
      var getElementsByTagName = document.getElementsByTagName.bind(document);
      result = getElementsByTagName('html')[0].scrollTop || getElementsByTagName('body')[0].scrollTop;
    }

    if (result < 1 && haveJQuery) {
      result = jQuery('html').scrollTop() || jQuery('body').scrollTop();
    }
  }

  return result;
}; // 获取对应结构的scrollLeft;


exports.getScrollTopOfDom = getScrollTopOfDom;

var getScrollLeftOfDom = function getScrollLeftOfDom(target) {
  var result = 0;

  if (target) {
    result = target.scrollLeft;

    if (result < 1 && haveJQuery) {
      result = jQuery(target).scrollLeft();
    }
  } else {
    if ((typeof document === "undefined" ? "undefined" : _typeof(document)) === 'object') {
      var getElementsByTagName = document.getElementsByTagName.bind(document);
      result = getElementsByTagName('html')[0].scrollLeft || getElementsByTagName('body')[0].scrollLeft;
    }

    if (result < 1 && haveJQuery) {
      result = jQuery('html').scrollLeft() || jQuery('body').scrollLeft();
    }
  }

  return result;
}; // 根据元素位置获取浮层的left


exports.getScrollLeftOfDom = getScrollLeftOfDom;

var getLeftOfOverlay = function getLeftOfOverlay(buttonLeft, buttonWidth, popupWidth, alignment) {
  var result = 0;

  if (alignment === 'left') {
    result = buttonLeft;
  } else if (alignment === 'leftPointer') {
    result = buttonLeft - popupWidth / 2;
  } else if (alignment === 'right') {
    result = buttonLeft + buttonWidth - popupWidth;
  } else if (alignment === 'rightPointer') {
    result = buttonLeft + buttonWidth - popupWidth / 2;
  } else {
    result = buttonLeft + (buttonWidth - popupWidth) / 2;
  }

  return result;
}; // 根据元素位置获取浮层的top


exports.getLeftOfOverlay = getLeftOfOverlay;

var getTopOfOverlay = function getTopOfOverlay(buttonTop, buttonHeight, popupHeight, alignment) {
  var result = 0;

  if (alignment === 'top') {
    result = buttonTop;
  } else if (alignment === 'bottom') {
    result = buttonTop + buttonHeight - popupHeight;
  } else {
    result = buttonTop + (buttonHeight - popupHeight) / 2;
  }

  return result;
};

exports.getTopOfOverlay = getTopOfOverlay;
var _default = {
  haveJQuery: haveJQuery,
  uniqueId: uniqueId,
  getBody: getBody,
  getCssTextData: getCssTextData,
  getChild: getChild,
  getClientSize: getClientSize,
  getScrollTopOfDom: getScrollTopOfDom,
  getScrollLeftOfDom: getScrollLeftOfDom,
  getLeftOfOverlay: getLeftOfOverlay,
  getTopOfOverlay: getTopOfOverlay
};
exports["default"] = _default;