"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _spauiUtils = require("@tencent/spaui-utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
var ClearComponent = function ClearComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var onClick = props.onClick,
      show = props.show,
      _props$classNameMap = props.classNameMap,
      classNameMap = _props$classNameMap === void 0 ? {} : _props$classNameMap;
  var allProps = (0, _spauiUtils.globalProps)(props, {
    className: classNameMap.LabelsClearClassName,
    style: {
      display: show ? '' : 'none'
    },
    supportAllEvents: false
  }); // 不需要spaui-component

  if (typeof allProps.className === 'string' && allProps.className) {
    allProps.className = allProps.className.replace(' spaui-component', '');
  }

  return _react["default"].createElement("li", allProps, _react["default"].createElement("a", {
    onClick: onClick,
    className: "spaui-cursor-pointer"
  }, "\u6E05\u7A7A"));
};

var _default = ClearComponent;
exports["default"] = _default;