"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.getAttributeToNumber = exports.toInt = void 0;

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 数字相关的方法
 * @author kirkzheng
 */
// 转化成数字
var toInt = function toInt(value) {
  var defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  return parseInt(value, 10) || defaultValue;
};
/**
 * 获取数字化的参数
 * @property {object} node=null 节点
 * @property {string} attribute = '' 参数名称
 */


exports.toInt = toInt;

var getAttributeToNumber = function getAttributeToNumber(node) {
  var attribute = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'data-width';
  var result = 0;

  if (node) {
    result = toInt(node.getAttribute(attribute));
  }

  return result;
};

exports.getAttributeToNumber = getAttributeToNumber;
var _default = {
  toInt: toInt,
  getAttributeToNumber: getAttributeToNumber
};
exports["default"] = _default;