"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _spauiPortal = _interopRequireDefault(require("@tencent/spaui-portal"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

var _default = function _default() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var target = props.target,
      children = props.children,
      _props$className = props.className,
      className = _props$className === void 0 ? '' : _props$className;
  var portalProps = {};
  var result = null;

  if (target === 'body' || _typeof(target) === 'object') {
    if (_typeof(target) === 'object') {
      portalProps.target = target;
    }

    result = _react["default"].createElement(_spauiPortal["default"], _extends({}, portalProps, {
      className: className
    }), _react["default"].createElement("div", {
      className: "timechoose"
    }, children));
  } else {
    result = children;
  }

  return result;
};

exports["default"] = _default;