"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _utils = require("../utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var getWeekLabel = function getWeekLabel(startDate, endDate, firstDayOfWeek, endDayOfWeek, unlimitedDateValue) {
  var formatLabel;
  var startWeekOfYear = (0, _utils.getWeekOfYearByDate)(startDate, firstDayOfWeek, endDayOfWeek);
  var endWeekOfYear = (0, _utils.getWeekOfYearByDate)(endDate, firstDayOfWeek, endDayOfWeek);
  var isLimited = (0, _utils.formatDate)(endDate) === (0, _utils.formatDate)(unlimitedDateValue);

  if (startWeekOfYear && startWeekOfYear !== '-') {
    formatLabel = "".concat(startWeekOfYear, "\u5468");

    if (startWeekOfYear !== endWeekOfYear) {
      if (endWeekOfYear && endWeekOfYear !== '-') {
        formatLabel += isLimited ? '至无限' : "\u81F3".concat(endWeekOfYear, "\u5468");
      }
    }
  }

  return formatLabel;
}; // 处理固定周选择器边界情况


var formatSingleOptionalWeekHolder = function formatSingleOptionalWeekHolder(type, date, startDate, endDate, fixedNumber, optionalStartDateData, optionalEndDateData, props) {
  var formatLabel;
  var firstDayOfWeek = props.firstDayOfWeek,
      endDayOfWeek = props.endDayOfWeek,
      unlimitedDateValue = props.unlimitedDateValue;

  if (type === 'single') {
    if (fixedNumber) {
      var optionalStartYear = optionalStartDateData.year,
          optionalStartMonth = optionalStartDateData.month,
          optionalStartDate = optionalStartDateData.date;
      var optionalEndYear = optionalEndDateData.year,
          optionalEndMonth = optionalEndDateData.month,
          optionalEndDate = optionalEndDateData.date;
      var last = (0, _utils.getLastWeekByNumber)(date, fixedNumber);
      var startDateFormat = Math.min(new Date(date).getTime(), new Date(last).getTime());
      var endDateFormat = Math.max(new Date(date).getTime(), new Date(last).getTime());

      if (fixedNumber < 0) {
        var optionalStart = (0, _utils.formatDate)(new Date(optionalStartYear, optionalStartMonth, optionalStartDate)); // 可选起始范围

        var rangeStartMonth = (0, _utils.getRangeWeekDays)(optionalStart, (0, _utils.formatDate)(endDateFormat), firstDayOfWeek, endDayOfWeek);

        if (new Date((0, _utils.formatDate)(last)).getTime() <= new Date((0, _utils.formatDate)(optionalStart)).getTime()) {
          endDateFormat = '';
          startDateFormat = (0, _utils.formatDate)(rangeStartMonth[0]);

          if (rangeStartMonth.length > 1) {
            endDateFormat = (0, _utils.formatDate)(rangeStartMonth[rangeStartMonth.length - 1]);
          }
        }
      } else {
        var optionalEnd = (0, _utils.formatDate)(new Date(optionalEndYear, optionalEndMonth, optionalEndDate)); // 可选截止范围

        var rangeEndMonth = (0, _utils.getRangeWeekDays)((0, _utils.formatDate)(startDateFormat), optionalEnd, firstDayOfWeek, endDayOfWeek);

        if (new Date((0, _utils.formatDate)(last)).getTime() >= new Date((0, _utils.formatDate)(optionalEnd)).getTime()) {
          startDateFormat = '';
          endDateFormat = (0, _utils.formatDate)(rangeEndMonth[rangeEndMonth.length - 1]);

          if (rangeEndMonth.length > 1) {
            startDateFormat = (0, _utils.formatDate)(rangeEndMonth[0]);
          }
        }
      }

      formatLabel = getWeekLabel((0, _utils.formatDate)(startDateFormat), (0, _utils.formatDate)(endDateFormat), firstDayOfWeek, endDayOfWeek, unlimitedDateValue);
    } else {
      formatLabel = "".concat((0, _utils.getWeekOfYearByDate)(date, firstDayOfWeek, endDayOfWeek), "\u5468");
    }
  } else {
    formatLabel = getWeekLabel(startDate, endDate, firstDayOfWeek, endDayOfWeek, unlimitedDateValue);
  }

  return formatLabel;
};

var ValueAreaComponent = function ValueAreaComponent() {
  var _classNames, _classNames2;

  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var onOpen = props.onOpen,
      label = props.label,
      placeholder = props.placeholder,
      disabled = props.disabled,
      clear = props.clear,
      type = props.type,
      picker = props.picker,
      onClear = props.onClear,
      startDate = props.startDate,
      endDate = props.endDate,
      date = props.date,
      _props$classNameMap = props.classNameMap,
      classNameMap = _props$classNameMap === void 0 ? {} : _props$classNameMap,
      fixedNumber = props.fixedNumber,
      optionalStartDateData = props.optionalStartDateData,
      optionalEndDateData = props.optionalEndDateData;
  var haveValue = type === 'single' ? date : startDate || endDate;
  var formatLabel = label;

  if (picker === 'week') {
    formatLabel = placeholder;

    if (haveValue) {
      formatLabel = formatSingleOptionalWeekHolder(type, date, startDate, endDate, fixedNumber, optionalStartDateData, optionalEndDateData, props);
    }
  }

  var canDelete = haveValue && clear && !disabled;
  return _react["default"].createElement("div", {
    className: (0, _classnames["default"])((_classNames = {}, _defineProperty(_classNames, classNameMap.DatePickerValueholderClassName, true), _defineProperty(_classNames, classNameMap.DatePickerValueholderDeleteClassName, canDelete), _classNames))
  }, _react["default"].createElement("div", {
    className: (0, _classnames["default"])((_classNames2 = {}, _defineProperty(_classNames2, classNameMap.DatePickerValueholderChooseDivClassName, true), _defineProperty(_classNames2, classNameMap.DatePickerValueholderPlaceholderClassName, label === placeholder), _defineProperty(_classNames2, classNameMap.DatePickerValueholderDisabledClassName, disabled), _classNames2)),
    onClick: onOpen
  }, _react["default"].createElement("span", null, formatLabel)), _react["default"].createElement("div", {
    className: classNameMap.DatePickerValueholderIconDivClassName,
    onClick: onOpen
  }, _react["default"].createElement("i", {
    className: "svg svg-date"
  }), canDelete ? _react["default"].createElement("i", {
    className: "svg svg-del svg-del-16",
    onClick: function onClick(event) {
      onClear(event);
      event.stopPropagation();
    }
  }) : null));
};

var _default = ValueAreaComponent;
exports["default"] = _default;