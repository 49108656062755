"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultProps = exports.propTypes = exports.config = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件参数配置
 */

/**
 * Alert 接口参数
 * @alias AlertProps
 * @description 警告提示，展现需要关注的信息
 * @property {titleline} 默认支持参数
 * @property {blankline} className;style;id;data-*;accessKey;dir;draggable;tabIndex;title;htmlRef;
 * @property {titleline} 基础属性
 * @property {string} [type='info'] 提示风格类型*可选值: info, success, error, warn, strongwarn, danger*
 * @property {string} [message=string|element] 警告提示内容*该属性与title等价, 且属性优先级高于 children 属性*
 * @property {string} [description=string|element] 辅助性文字介绍*该属性与desc等价*
 * @property {bool} [expandContentDefaultOpen=false] 可展开内容默认展示
 * @property {string} [expandContent=string|element] 可展开的内容
 * @property {object} [toggleButtonProps] 展开收起按钮参数
 * @property {bool} [showIcon=true] 是否显示辅助图标
 * @property {string} [iconType] 自定义图标类型*默认使用 type 属性对应的图标*
 * @property {element} [icon] 自定义图标
 * @property {string} [iconClassName] 自定义图标的 className
 * @property {bool} [closable=false] 是否可关闭*默认不可关闭*
 * @property {bool} [show=true] 是否展示
 * @property {func} [onClose] 关闭时触发的回调函数*(e: MouseEvent) => void*
 * @property {object} [closeButtonProps] 关闭按钮参数
 * @property {func} [onToggle] 当 expandContent 设置时，展开收起的回调*(e: MouseEvent) => void*
 * @property {bool} [hideBg=false] 是否隐藏背景色
 * @property {string} [transitionName='zoom-motion'] 自定义动效名称，遵循 od-animate 规范
 * @property {string} [size] 尺寸*可选值：small, medium*
 * @property {element} [prefix] 前置内容
 * @property {element} [suffix] 后置内容
 * @property {object} [contentProps] content 其他属性，仅支持 div 属性
 *
 * @see
 *
 * onClose typings:
 *     (event: any) => any
 */
var config = {
  type: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'info'
  },
  message: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.element, _spauiUtils.PropTypes.string])
  },
  title: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.element, _spauiUtils.PropTypes.string])
  },
  desc: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.element, _spauiUtils.PropTypes.string])
  },
  description: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.element, _spauiUtils.PropTypes.string])
  },
  showIcon: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  hideBg: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  iconType: {
    type: _spauiUtils.PropTypes.string
  },
  icon: {
    type: _spauiUtils.PropTypes.element
  },
  iconClassName: {
    type: _spauiUtils.PropTypes.string
  },
  closable: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  show: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  onClose: {
    type: _spauiUtils.PropTypes.func
  },
  closeButtonProps: {
    type: _spauiUtils.PropTypes.object
  },
  toggleButtonProps: {
    type: _spauiUtils.PropTypes.object
  },
  transitionName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'zoom-motion'
  },
  expandContent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.element, _spauiUtils.PropTypes.string])
  },
  expandContentDefaultOpen: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  size: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: '',
    visualData: {
      type: 'radio',
      description: '尺寸',
      value: ['', 'small', 'medium']
    }
  },
  prefix: {
    type: _spauiUtils.PropTypes.element
  },
  suffix: {
    type: _spauiUtils.PropTypes.element
  },
  contentProps: {
    type: _spauiUtils.PropTypes.object
  }
}; // 转化成PureComponent组件的配置

exports.config = config;
var configData = (0, _spauiUtils.formatProps)(config); // 导出

var propTypes = configData.propTypes;
exports.propTypes = propTypes;
var defaultProps = configData.defaultProps;
exports.defaultProps = defaultProps;
var _default = {
  config: config,
  propTypes: propTypes,
  defaultProps: defaultProps
};
exports["default"] = _default;