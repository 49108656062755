"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _spauiUtils = require("@tencent/spaui-utils");

var _classnames = _interopRequireDefault(require("classnames"));

var _spauiCheckbox = _interopRequireDefault(require("@tencent/spaui-checkbox"));

var _get_data = require("../utils/get_data");

var _bool = require("../utils/bool");

var _index = require("../utils/index");

var _get_node = require("../utils/get_node");

var _index2 = require("../config/index");

var _column = _interopRequireDefault(require("../column"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

var _id = 0; // 从data中获取name的所有数据

var getTargetData = function getTargetData() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var name = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var supportingHierarchicalData = arguments.length > 2 ? arguments[2] : undefined;
  var result = [];

  if (name) {
    // name为_index获得的就是行数索引
    data.map(function (item, index) {
      var originValue = (0, _get_data.getValueByName)(item, name, supportingHierarchicalData);

      if (typeof originValue !== 'undefined') {
        result.push(originValue);
      } else if (name === '_index') {
        result.push(index);
      }

      return item;
    });
  }

  return result;
}; // 是否全选按钮是禁用的


var isDisabled = function isDisabled() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var name = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var disabledValue = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  var supportingHierarchicalData = arguments.length > 3 ? arguments[3] : undefined;
  var targetData = getTargetData(data, name, supportingHierarchicalData);
  var result = true; // 有可选数据并且指定了禁用数据

  if (targetData.length > 0 && disabledValue.length > 0) {
    targetData.map(function (item) {
      // 如果有一个不禁选，那全选按钮就是没有禁用的
      if (result && (0, _spauiUtils.indexOf)(disabledValue, item) < 0) {
        result = false;
      }

      return item;
    });
  } else {
    result = false;
  }

  return result;
}; // 是否全选


var isAllChecked = function isAllChecked() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var name = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var disabledValue = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  var value = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
  var supportingHierarchicalData = arguments.length > 4 ? arguments[4] : undefined;
  var targetData = getTargetData(data, name, supportingHierarchicalData);
  var activeValue = [];
  var result = true; // 把选中的数据给一份activeValue

  value.map(function (valueItem) {
    activeValue.push(valueItem);
    return valueItem;
  }); // 如果全部禁用，就只看选中的值；没有全部禁用，就把已经被禁用的值也算进来

  if (!isDisabled(data, name, disabledValue, supportingHierarchicalData)) {
    disabledValue.map(function (disabledValueItem) {
      activeValue.push(disabledValueItem);
      return disabledValueItem;
    });
  }

  if (targetData.length > 0) {
    targetData.map(function (item) {
      // 如果有值不在activeValue的，那就说明没有全选
      if (result && (0, _spauiUtils.indexOf)(activeValue, item) < 0) {
        result = false;
      }

      return item;
    });
  } else {
    result = false;
  }

  return result;
}; // 是否半选


var isIndeterminate = function isIndeterminate() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var name = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var disabledValue = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  var value = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
  var supportingHierarchicalData = arguments.length > 4 ? arguments[4] : undefined;
  var targetData = getTargetData(data, name, supportingHierarchicalData);
  var isAll = isAllChecked(data, name, disabledValue, value, supportingHierarchicalData);
  var result = false;

  if (!isAll) {
    value.map(function (item) {
      // 在没有全选的情况下，只要有一个值是在可选列的数据中，那就是半选
      if ((0, _spauiUtils.indexOf)(targetData, item) > -1 && !result) {
        result = true;
      }

      return item;
    });
  }

  return result;
};

var _default = function _default() {
  var theProps = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var sortName = theProps.sortName,
      sortOrder = theProps.sortOrder,
      onSort = theProps.onSort,
      multiFieldSort = theProps.multiFieldSort,
      maxMultiFieldSortSize = theProps.maxMultiFieldSortSize,
      filterName = theProps.filterName,
      filterValue = theProps.filterValue,
      onFilter = theProps.onFilter,
      data = theProps.data,
      onClickTr = theProps.onClickTr,
      _theProps$onMouseOver = theProps.onMouseOverTr,
      onMouseOverTr = _theProps$onMouseOver === void 0 ? _spauiUtils.noop : _theProps$onMouseOver,
      _theProps$onMouseOutT = theProps.onMouseOutTr,
      onMouseOutTr = _theProps$onMouseOutT === void 0 ? _spauiUtils.noop : _theProps$onMouseOutT,
      _theProps$minColumnWi = theProps.minColumnWidth,
      minColumnWidth = _theProps$minColumnWi === void 0 ? 0 : _theProps$minColumnWi,
      cellInnerClassName = theProps.cellInnerClassName,
      hideThead = theProps.hideThead,
      htmlRef = theProps.htmlRef,
      onColumnMouseDown = theProps.onColumnMouseDown,
      _theProps$onThMouseOv = theProps.onThMouseOver,
      onThMouseOver = _theProps$onThMouseOv === void 0 ? _spauiUtils.noop : _theProps$onThMouseOv,
      _theProps$onThMouseOu = theProps.onThMouseOut,
      onThMouseOut = _theProps$onThMouseOu === void 0 ? _spauiUtils.noop : _theProps$onThMouseOu,
      _theProps$setTrClassN = theProps.setTrClassName,
      setTrClassName = _theProps$setTrClassN === void 0 ? _spauiUtils.noop : _theProps$setTrClassN,
      titleRef = theProps.titleRef,
      _theProps$fixedTitleC = theProps.fixedTitleClassName,
      fixedTitleClassName = _theProps$fixedTitleC === void 0 ? '' : _theProps$fixedTitleC,
      _theProps$fixedTitleS = theProps.fixedTitleStyle,
      fixedTitleStyle = _theProps$fixedTitleS === void 0 ? {} : _theProps$fixedTitleS,
      supportingHierarchicalData = theProps.supportingHierarchicalData,
      _theProps$CheckboxPro = theProps.CheckboxProps,
      CheckboxProps = _theProps$CheckboxPro === void 0 ? {} : _theProps$CheckboxPro,
      setCellStyle = theProps.setCellStyle,
      clickSpace = theProps.clickSpace,
      filterSelectOpen = theProps.filterSelectOpen,
      onFilterSelectOpen = theProps.onFilterSelectOpen,
      total = theProps.total,
      totalLabel = theProps.totalLabel,
      _theProps$loading = theProps.loading,
      loading = _theProps$loading === void 0 ? false : _theProps$loading,
      _theProps$thClickFocu = theProps.thClickFocusClassName,
      thClickFocusClassName = _theProps$thClickFocu === void 0 ? '' : _theProps$thClickFocu,
      _theProps$thClickFocu2 = theProps.thClickFocusKey,
      thClickFocusKey = _theProps$thClickFocu2 === void 0 ? '' : _theProps$thClickFocu2;
  var defaultOptionalCellWidth = 35 + (total && totalLabel ? 10 : 0);
  var columns = (0, _get_node.getColumns)(theProps.children);
  var trType = 'head';
  var rowAndColSpanData = (0, _get_data.getRowAndColSpanData)(theProps) || {};
  var headRowAndColSpanData = rowAndColSpanData.head || [];
  var isFixedThead = (0, _bool.isFixedTableThead)(theProps);

  var _onClickTr = function _onClickTr(event, theTrType) {
    // 没有拖动或者拖动的间距在一定范围内才触发点击行这个事件
    if (!eventClickPageX && !eventClickPageY || typeof event.pageX === 'undefined' && typeof event.pageY === 'undefined' || eventClickPageX - clickSpace <= event.pageX && eventClickPageX + clickSpace >= event.pageX && eventClickPageY - clickSpace <= event.pageY && eventClickPageY + clickSpace >= event.pageY) {
      if ((0, _spauiUtils.isFunction)(onClickTr)) {
        onClickTr(event, theTrType);
      }
    }

    eventClickPageX = 0;
    eventClickPageY = 0;
  };

  var _onMouseDownTr = function _onMouseDownTr(event) {
    // 记录下点击的位置
    eventClickPageX = event.pageX || 0;
    eventClickPageY = event.pageY || 0;

    if ((0, _spauiUtils.isFunction)(onColumnMouseDown)) {
      onColumnMouseDown(event);
    }
  }; // 获取可选列的children


  var getOptionalChildren = function getOptionalChildren() {
    var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var _item$props = item.props,
        props = _item$props === void 0 ? {} : _item$props;
    var target = props.target,
        value = props.value,
        onOptionalChange = props.onOptionalChange,
        canOptional = props.canOptional,
        setChecked = props.setChecked,
        setIndeterminate = props.setIndeterminate,
        max = props.max,
        needCheckAll = props.needCheckAll; // 全选回调

    var onCheckboxChange = function onCheckboxChange(event) {
      var checkboxChangeValue = []; // 回调选中的数据

      var otherPageValue = []; // 有max的时候，如果不在当前的data里面都是不进行操作的

      if (max > 0 && needCheckAll) {
        var dataValueList = [];
        data.forEach(function (dataItem) {
          if (dataItem && typeof dataItem[target] !== 'undefined') {
            dataValueList.push(dataItem[target]);
          }
        });
        value.forEach(function (valueItem) {
          if (typeof valueItem !== 'undefined' && !dataValueList.includes(valueItem)) {
            otherPageValue.push(valueItem);
          }
        });
      }

      if (event.target.checked) {
        checkboxChangeValue = [].concat(otherPageValue);

        if (max > 0 && needCheckAll) {
          value.forEach(function (valueItem) {
            if (!checkboxChangeValue.includes(valueItem)) {
              checkboxChangeValue.push(valueItem);
            }
          });
        } // 全选中的逻辑，添加数据到回调


        data.map(function (dataItem, dataIndex) {
          if (dataItem) {
            // 没选中或者没禁选，就添加进来
            if (!(0, _spauiUtils.isUndefined)(dataItem[target])) {
              if ((0, _spauiUtils.indexOf)(value, dataItem[target]) > -1 || (0, _spauiUtils.indexOf)(disabledValue, dataItem[target]) < 0) {
                if (max > 0 && needCheckAll) {
                  if (checkboxChangeValue.length < max && !checkboxChangeValue.includes(dataItem[target])) {
                    checkboxChangeValue.push(dataItem[target]);
                  }
                } else {
                  checkboxChangeValue.push(dataItem[target]);
                }
              }
            } else if (target === '_index') {
              if ((0, _spauiUtils.indexOf)(value, dataIndex) > -1 || (0, _spauiUtils.indexOf)(disabledValue, dataIndex) < 0) {
                if (max > 0 && needCheckAll) {
                  if (checkboxChangeValue.length < max && !checkboxChangeValue.includes(dataIndex)) {
                    checkboxChangeValue.push(dataIndex);
                  }
                } else {
                  checkboxChangeValue.push(dataIndex);
                }
              }
            }
          }

          return dataItem;
        });
      } else {
        // 没有选中的话，只保留之前被禁用选中的部分
        value.map(function (valueItem) {
          if ((0, _spauiUtils.indexOf)(disabledValue, valueItem) > -1) {
            checkboxChangeValue.push(valueItem);
          }

          return valueItem;
        });
      } // 对于不在本页的都需要保留


      value.map(function (valueItem) {
        var targetData = getTargetData(data, target, supportingHierarchicalData);

        if (typeof valueItem !== 'undefined' && (0, _spauiUtils.indexOf)(targetData, valueItem) < 0 && (0, _spauiUtils.indexOf)(checkboxChangeValue, valueItem) < 0) {
          checkboxChangeValue.push(valueItem);
        }

        return valueItem;
      });

      if ((0, _spauiUtils.isFunction)(onOptionalChange)) {
        var dataMap = (0, _index.genDataMap)(target, data, checkboxChangeValue);
        onOptionalChange(event, checkboxChangeValue, {}, dataMap, 'head');
      }
    };

    var disabledValue = (0, _spauiUtils.cloneArray)((0, _spauiUtils.isArray)(props.disabledValue) ? props.disabledValue : []); // 如果存在canOptional重新设置下disabledValue

    if ((0, _spauiUtils.isFunction)(canOptional)) {
      data.map(function (rowData, index) {
        var rowValue = '';
        var rowDisabled = false;
        var rowDisabledIndex = -1;

        if (rowData) {
          // 选项对应的值是哪个
          if (!(0, _spauiUtils.isUndefined)(rowData[target])) {
            rowValue = rowData[target];
          } else if (target === '_index') {
            // 如果taget是_index，说明要的是索引位置
            rowValue = index;
          }

          rowDisabledIndex = (0, _spauiUtils.indexOf)(disabledValue, rowValue);
          rowDisabled = !canOptional(rowData, rowDisabledIndex > -1, loading);

          if (rowDisabled) {
            if (rowDisabledIndex < 0) {
              disabledValue.push(rowValue);
            }
          } else {
            if (rowDisabledIndex > -1) {
              disabledValue.splice(rowDisabledIndex, 1);
            }
          }
        }

        return rowData;
      });
    }

    var checkboxClassName = '';

    if ((0, _spauiUtils.isFunction)(CheckboxProps.className)) {
      checkboxClassName = CheckboxProps.className({
        value: value,
        isAll: true
      });
    } else {
      checkboxClassName = CheckboxProps.className || '';
    }

    var isChecked = (0, _spauiUtils.isFunction)(setChecked) ? setChecked({
      value: value,
      isAll: true
    }) : isAllChecked(data, target, disabledValue, value, supportingHierarchicalData);

    if (!isChecked && max > 0 && needCheckAll && value.length === max) {
      var haveCurrentPageValue = false;
      data.some(function (rowData) {
        if (value.includes(rowData[target])) {
          haveCurrentPageValue = true;
        }

        return haveCurrentPageValue;
      });

      if (haveCurrentPageValue) {
        isChecked = true;
      }
    }

    var indeterminate = (0, _spauiUtils.isFunction)(setIndeterminate) ? setIndeterminate({
      value: value,
      isAll: true
    }) : isIndeterminate(data, target, disabledValue, value, supportingHierarchicalData);
    var labelClassName = (0, _classnames["default"])("spaui-table-optional-input spaui-checkbox ".concat(checkboxClassName), {
      'pre-check': indeterminate
    });
    var disabled = isDisabled(data, target, disabledValue, supportingHierarchicalData);

    if (!disabled && !isChecked) {
      if (max > 0 && needCheckAll && value.length >= max) {
        disabled = true;
      }
    }

    return _react["default"].createElement(_spauiCheckbox["default"], {
      className: labelClassName,
      onClick: CheckboxProps.onClick,
      value: "all",
      checked: isChecked,
      indeterminate: indeterminate,
      disabled: disabled,
      onChange: onCheckboxChange
    }); // return (
    //     <label
    //         className={labelClassName}
    //         onClick={CheckboxProps.onClick}
    //     >
    //         <input
    //             type="checkbox"
    //             className="check"
    //             value="all"
    //             checked={isChecked}
    //             indeterminate={indeterminate.toString()}
    //             disabled={isDisabled(data, target, disabledValue, supportingHierarchicalData)}
    //             onChange={onCheckboxChange}
    //         />
    //         <span className="ico"></span>
    //     </label>
    // );
  }; // 获取单元格的style


  var getCellStyle = function getCellStyle() {
    var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var index = arguments.length > 1 ? arguments[1] : undefined;
    var _item$props2 = item.props,
        props = _item$props2 === void 0 ? {} : _item$props2;
    var name = props.name,
        type = props.type,
        _props$minWidth = props.minWidth,
        minWidth = _props$minWidth === void 0 ? 0 : _props$minWidth,
        _props$width = props.width,
        width = _props$width === void 0 ? 0 : _props$width;
    var maxWidth = (0, _get_node.getColumnMaxWidth)(props);
    var rowAndColSpanProps = headRowAndColSpanData[0][index] || {};
    var result = {};
    var customStyle = {};
    var cellWidth = width;
    var cellMinWidth = minWidth || minColumnWidth || 0; // 最小宽度，先看列自己的，再看全局的设置
    // 如果最小宽度大于最大宽度的设置，那就要调整最小宽度

    if (maxWidth > 0 && cellMinWidth > maxWidth) {
      cellMinWidth = maxWidth;
    } // 获取下单元格的style


    result = (0, _spauiUtils.objectAssign)({}, props.style, rowAndColSpanProps.style); // 没有固定列的时候，宽度是设置在thead的

    if (!isFixedThead) {
      if (cellMinWidth > 0 && (0, _spauiUtils.isUndefined)(result.minWidth)) {
        result.minWidth = "".concat(cellMinWidth, "px");
      }

      if (maxWidth > 0 && (0, _spauiUtils.isUndefined)(result.maxWidth)) {
        result.maxWidth = "".concat(maxWidth, "px");
      } // optional宽度太小，就设置下宽度


      if (type === 'optional' && cellWidth < 1) {
        if (minWidth > defaultOptionalCellWidth) {
          cellWidth = minWidth;
          result.minWidth = "".concat(minWidth, "px");
        } else {
          cellWidth = defaultOptionalCellWidth;
          result.minWidth = "".concat(defaultOptionalCellWidth, "px");
        }
      }

      if (cellWidth > 0) {
        result.width = "".concat(cellWidth, "px");
      }
    } // 如果自定义了setCellStyle，可以自己重置下


    if ((0, _spauiUtils.isFunction)(setCellStyle)) {
      customStyle = setCellStyle(trType, name, '', 0, index, {});

      if (_typeof(customStyle) === 'object') {
        result = (0, _spauiUtils.objectAssign)({}, result, customStyle);
      }
    }

    return result;
  };

  var style = {};
  var result = '';
  var eventClickPageX = 0;
  var eventClickPageY = 0; // 隐藏头部就是none

  if (hideThead) {
    style.display = 'none';
  }

  result = _react["default"].createElement("thead", {
    ref: htmlRef,
    style: style
  }, _react["default"].createElement("tr", {
    "data-spaui-table-tr": "1",
    className: "spaui-table-thead-tr spaui-table-thead-column-tr ".concat(setTrClassName(trType) || ''),
    onClick: function onClick(event) {
      return _onClickTr(event, trType);
    },
    onMouseOver: function onMouseOver(event) {
      return onMouseOverTr(event, trType);
    },
    onMouseOut: function onMouseOut(event) {
      return onMouseOutTr(event, trType);
    },
    onMouseDown: function onMouseDown(event) {
      return _onMouseDownTr(event, trType);
    }
  }, columns.map(function () {
    var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var index = arguments.length > 1 ? arguments[1] : undefined;
    var _item$props3 = item.props,
        props = _item$props3 === void 0 ? {} : _item$props3;
    var name = props.name,
        render = props.render,
        children = props.children,
        type = props.type,
        multiple = props.multiple,
        value = props.value,
        CustomTdComponent = props.component,
        isFixed = props.isFixed,
        _props$fixedable = props.fixedable,
        propsFixedable = _props$fixedable === void 0 ? false : _props$fixedable,
        max = props.max,
        _props$fixedAlign = props.fixedAlign,
        fixedAlign = _props$fixedAlign === void 0 ? '' : _props$fixedAlign,
        customScope = props.customScope,
        _props$fixedBorder = props.fixedBorder,
        fixedBorder = _props$fixedBorder === void 0 ? true : _props$fixedBorder,
        _props$needCheckAll = props.needCheckAll,
        needCheckAll = _props$needCheckAll === void 0 ? false : _props$needCheckAll;
    var isFixedColumn = !(0, _spauiUtils.isUndefined)(isFixed) ? isFixed : propsFixedable;
    var rowAndColSpanProps = headRowAndColSpanData[0][index] || {}; // 最后一列即使指定了拖动也是不能拖动的

    var columnAdjustWidth = index < columns.length - 1 && props.adjustWidth;
    var cellChildren = children;
    var cellStyle = {};
    var cellMinWidth = 0;
    var cellMaxWidth = 0;
    var thResult = null;
    var useCustomTdComponent = false; // 检验下格式，多选了就必须是数组

    if (multiple && !(0, _spauiUtils.isArray)(value)) {
      throw new Error("\u3010spaui-table\u3011Column\u4E3Atype=optional\u4E14multiple=true\u65F6\uFF0Cvalue\u9700\u8981\u4E3A\u6570\u7EC4\uFF0C\u76EE\u524Dvalue=".concat(value));
    } else {
      cellStyle = getCellStyle(item, index);

      if (!isFixedThead) {
        cellMinWidth = parseInt(cellStyle.minWidth, 10) || 0;
        cellMaxWidth = parseInt(cellStyle.maxWidth, 10) || 0;

        if (cellMinWidth > 0) {
          rowAndColSpanProps['data-min-width'] = cellMinWidth;
        }

        if (cellMaxWidth) {
          rowAndColSpanProps['data-max-width'] = cellMaxWidth;
        }
      } // max大于0的时候，是没有全选的


      if (type === 'optional' && multiple && (typeof max === 'undefined' || max < 1 || needCheckAll)) {
        cellChildren = getOptionalChildren(item);
      } // 自定义render


      if (typeof CustomTdComponent !== 'undefined') {
        // 如果指定了作用域，在作用域内的就用render处理下，不在作用域内的就用默认的方式
        if ((0, _spauiUtils.isArray)(customScope) && customScope.length > 0) {
          if ((0, _spauiUtils.indexOf)(customScope, trType) > -1) {
            useCustomTdComponent = true;
          }
        } else {
          useCustomTdComponent = true;
        }

        if (useCustomTdComponent) {
          if ((0, _spauiUtils.isReactNode)(CustomTdComponent)) {
            cellChildren = CustomTdComponent;
          } else {
            cellChildren = _react["default"].createElement(CustomTdComponent, _extends({}, props, {
              checkedValue: props.value,
              type: trType,
              name: name,
              value: cellChildren,
              data: item,
              disabled: cellChildren && cellChildren.props && cellChildren.props.disabled
            }));
          }
        }
      } else if (render && (0, _spauiUtils.isFunction)(render)) {
        // 如果指定了作用域，在作用域内的就用render处理下，不在作用域内的就用默认的方式
        if ((0, _spauiUtils.isArray)(customScope) && customScope.length > 0) {
          if ((0, _spauiUtils.indexOf)(customScope, trType) > -1) {
            cellChildren = render(trType, name, cellChildren, item, value, cellChildren && cellChildren.props && cellChildren.props.disabled);
          }
        } else {
          // 没有指定作用域的，全部都是render
          cellChildren = render(trType, name, cellChildren, item, value, cellChildren && cellChildren.props && cellChildren.props.disabled);
        }
      }

      _id += 1;
      thResult = _react["default"].createElement(_column["default"], _extends({}, props, {
        columnIndex: index,
        key: "".concat(index, "spaui-table-thead").concat(_id),
        data: data,
        sortName: sortName,
        sortOrder: sortOrder,
        onSort: onSort,
        multiFieldSort: multiFieldSort,
        maxMultiFieldSortSize: maxMultiFieldSortSize,
        filterName: filterName,
        filterValue: filterValue,
        onFilter: onFilter,
        cellInnerClassName: cellInnerClassName
      }, rowAndColSpanProps, {
        style: cellStyle,
        className: (0, _classnames["default"])(props.className, rowAndColSpanProps.className, props.headClassName, thClickFocusKey && thClickFocusKey == "".concat(index, "_").concat(name) ? thClickFocusClassName : ''),
        adjustWidth: columnAdjustWidth,
        filterSelectOpen: filterSelectOpen,
        supportingHierarchicalData: supportingHierarchicalData,
        onFilterSelectOpen: onFilterSelectOpen,
        onClick: function onClick(event) {
          if ((0, _spauiUtils.isFunction)(props.onClick)) {
            props.onClick(event);
          }

          if ((0, _spauiUtils.isFunction)(theProps.onThClick)) {
            theProps.onThClick(event, index, _objectSpread({}, props, {
              adjustWidth: columnAdjustWidth
            }));
          }
        },
        onMouseOver: function onMouseOver(event) {
          return onThMouseOver(event, index, _objectSpread({}, props, {
            adjustWidth: columnAdjustWidth
          }));
        },
        onMouseOut: function onMouseOut(event) {
          return onThMouseOut(event, index, _objectSpread({}, props, {
            adjustWidth: columnAdjustWidth
          }));
        }
      }), cellChildren, isFixedColumn ? _react["default"].createElement(_index2.FixedColumnBorder, {
        fixedAlign: fixedAlign,
        show: fixedBorder
      }) : null);
    }

    return thResult;
  })), _react["default"].createElement("tr", {
    "data-spaui-table-tr": "1",
    ref: titleRef,
    className: "spaui-table-thead-tr ".concat(setTrClassName(trType) || '', " ").concat(fixedTitleClassName),
    style: (0, _spauiUtils.objectAssign)(fixedTitleStyle, {
      display: 'none'
    })
  }));
  return result;
};

exports["default"] = _default;