"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.setTableWidthAndClassName = exports.setWrapWidth = exports.setTheadTableCellWidthEqualTbodyTable = exports.setCalWidth = exports.setRealWidthIsLargeThanTheCalWidth = exports.setCustomWidth = exports.setCellWidthForTable = exports.clearTableWidth = exports.setNodeWidthByAttr = exports.setNodeWidth = exports.setCellWidth = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

var _number = require("./number");

var _index = require("../config/index");

var _get_node = require("./get_node");

var _get_size = require("./get_size");

var _bool = require("./bool");

var _get_data = require("./get_data");

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 设置表格宽高相关的方法
 * @author kirkzheng
 */

/**
 * 设置单元格的宽度
 * @property {string} type='' 设置哪部分
 * @property {number} index=-1 设置哪个索引
 * @property {number} width=0 宽度
 * @property {bool} isCustomStyleWidth=false 是否自定义宽度
 * @property {object} nodeMap 节点配置
 */
var setCellWidth = function setCellWidth() {
  var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var index = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : -1;
  var width = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  var isCustomStyleWidth = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  var nodeMap = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
  var colThead = nodeMap.colThead,
      theadTh = nodeMap.theadTh,
      colTbody = nodeMap.colTbody,
      tbodyTd = nodeMap.tbodyTd,
      colTfoot = nodeMap.colTfoot,
      tfootTd = nodeMap.tfootTd,
      tbodyAllTd = nodeMap.tbodyAllTd;
  var colCellNode = null;
  var tdCellNode = null; // 根据类型，看看需要设置哪个部分

  if (type === 'thead') {
    colCellNode = colThead[index] || null;
    tdCellNode = theadTh[index] || null;
  } else if (type === 'tbody') {
    colCellNode = colTbody[index] || null;

    if ((0, _spauiUtils.isArray)(tbodyAllTd) && tbodyAllTd.length > 0) {
      tdCellNode = tbodyAllTd.map(function (item) {
        return item[index] || null;
      });
    } else {
      tdCellNode = tbodyTd[index] || null;
    }
  } else if (type === 'tfoot') {
    colCellNode = colTfoot[index] || null;
    tdCellNode = tfootTd[index] || null;
  } // 设置col的宽度


  if (colCellNode) {
    if (colCellNode.getAttribute('data-optional')) {
      colCellNode.setAttribute('width', "".concat(width, "px"));
    } else {
      colCellNode.setAttribute('width', "".concat(width, "px"));
    }

    if (colCellNode.getAttribute('data-width')) {
      colCellNode.setAttribute('data-width', "".concat(width, "px"));
    }

    if (isCustomStyleWidth) {
      colCellNode.setAttribute('data-customstyle', 1);
    }
  } // 设置td的宽度


  if (tdCellNode) {
    var tdCellList = (0, _spauiUtils.isArray)(tdCellNode) ? tdCellNode : [tdCellNode];
    tdCellList.forEach(function (item) {
      if (colCellNode && colCellNode.getAttribute('data-optional')) {
        item.style.minWidth = "".concat(colCellNode.getAttribute('data-min-width'), "px");
        item.style.maxWidth = "".concat(colCellNode.getAttribute('data-max-width'), "px");
      } else {
        // 如果节点上已经有对应的style width设置了，也要重新设置下
        if (item && item.style && item.style.width) {
          item.style.width = "".concat(width, "px");
        }

        item.style.minWidth = "".concat(width, "px");
        item.style.maxWidth = "".concat(width, "px");
      }
    });
  }
};
/**
 * 设置某节点的宽度
 * @property {object} node=null 节点
 * @property {number|string} width=0 宽度
 */


exports.setCellWidth = setCellWidth;

var setNodeWidth = function setNodeWidth() {
  var node = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  var width = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var theWidth = 0;

  if (node) {
    if (width === '100%' || width === 'auto') {
      node.style.width = width;
    } else {
      theWidth = parseFloat(width, 10) || 0;
      node.style.width = "".concat(theWidth, "px");
    }
  }
};
/**
 * 根据属性设置某节点的宽度
 * @property {object} node=null 节点
 */


exports.setNodeWidth = setNodeWidth;

var setNodeWidthByAttr = function setNodeWidthByAttr() {
  var node = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  var maxWidth = 0;
  var width = 0;

  if (node) {
    maxWidth = (0, _number.toInt)(node.getAttribute('data-max-width'));
    width = (0, _number.toInt)((0, _spauiUtils.getStyle)(node, 'width'));

    if (maxWidth > 0 && width > maxWidth) {
      node.style.width = "".concat(maxWidth, "px");
    }
  }
};
/**
 * 清除已经设置的宽度
 * @property {object} nodeMap={} 节点
 */


exports.setNodeWidthByAttr = setNodeWidthByAttr;

var clearTableWidth = function clearTableWidth() {
  var nodeMap = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  var _ref = arguments.length > 1 ? arguments[1] : undefined,
      setAllBodyCellMaxMinWidth = _ref.setAllBodyCellMaxMinWidth;

  var colThead = nodeMap.colThead,
      colTbody = nodeMap.colTbody,
      colTfoot = nodeMap.colTfoot,
      len = nodeMap.len,
      thead = nodeMap.thead,
      tbody = nodeMap.tbody,
      tfoot = nodeMap.tfoot,
      theadWrap = nodeMap.theadWrap,
      tbodyWrap = nodeMap.tbodyWrap,
      tfootWrap = nodeMap.tfootWrap,
      theadTable = nodeMap.theadTable,
      tbodyTable = nodeMap.tbodyTable,
      tfootTable = nodeMap.tfootTable;
  var theadTh = (0, _get_node.getTableCellNodes)(thead, 'th', function (result) {
    var index = 0;

    if (result && result.length > 2) {
      index = result.length - 2;
    }

    return index;
  });
  var tbodyAllTd = (0, _get_node.getTableCellNodes)(tbody, 'td', undefined, false);
  var tbodyTd = tbodyAllTd[0] || [];
  var tfootTd = (0, _get_node.getTableCellNodes)(tfoot);

  var setMinAndMaxWidthAuto = function setMinAndMaxWidthAuto(index) {
    var setWidth = function setWidth(theNode) {
      if (theNode && theNode[index]) {
        theNode[index].style.minWidth = '';
        theNode[index].style.maxWidth = '';
      }
    };

    setWidth(theadTh);

    if (setAllBodyCellMaxMinWidth) {
      tbodyAllTd.forEach(function (item) {
        setWidth(item);
      });
    } else {
      setWidth(tbodyTd);
    }

    setWidth(tfootTd);
  };

  var i = 0;

  if (theadWrap && (0, _spauiUtils.getStyle)(theadWrap, 'width')) {
    theadWrap.style.width = '';
  }

  if (tbodyWrap && (0, _spauiUtils.getStyle)(tbodyWrap, 'width')) {
    tbodyWrap.style.width = '';
  }

  if (tfootWrap && (0, _spauiUtils.getStyle)(tfootWrap, 'width')) {
    tfootWrap.style.width = '';
  }

  if (theadTable && (0, _spauiUtils.getStyle)(theadTable, 'width')) {
    theadTable.style.width = '';
  }

  if (tbodyTable && (0, _spauiUtils.getStyle)(tbodyTable, 'width')) {
    tbodyTable.style.width = '';
  }

  if (tfootTable && (0, _spauiUtils.getStyle)(tfootTable, 'width')) {
    tfootTable.style.width = '';
  }

  for (; i < len; i++) {
    if (colThead[i]) {
      colThead[i].setAttribute('width', '');
      colThead[i].setAttribute('data-customstyle', '');
    }

    if (colTbody[i]) {
      colTbody[i].setAttribute('width', '');
      colTbody[i].setAttribute('data-customstyle', '');
    }

    if (colTfoot[i]) {
      colTfoot[i].setAttribute('width', '');
      colTfoot[i].setAttribute('data-customstyle', '');
    }

    setMinAndMaxWidthAuto(i);
  }
};
/**
 * 设置整个表格-表头/表对应单元格的宽度
 */


exports.clearTableWidth = clearTableWidth;

var setCellWidthForTable = function setCellWidthForTable(index, colWidth) {
  var isCustomStyleWidth = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var maxWidth = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
  var nodes = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
  var props = arguments.length > 5 ? arguments[5] : undefined;
  var isUpdate = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : true;
  var options = arguments.length > 7 && arguments[7] !== undefined ? arguments[7] : {};
  var _options$onlySetHead = options.onlySetHead,
      onlySetHead = _options$onlySetHead === void 0 ? false : _options$onlySetHead;
  var colThead = nodes.colThead,
      colTbody = nodes.colTbody,
      colTfoot = nodes.colTfoot,
      thead = nodes.thead,
      tbody = nodes.tbody,
      tfoot = nodes.tfoot;
  var setAllBodyCellMaxMinWidth = props.setAllBodyCellMaxMinWidth;
  var rowAndColSpanData = (0, _get_data.getRowAndColSpanData)(props);
  var theadRowAndColSpanData = rowAndColSpanData.head || [];

  var _maxWidth = (0, _number.toInt)(maxWidth);

  var theadTh = (0, _get_node.getTableCellNodes)(thead, 'th', function (result) {
    var theIndex = 0;

    if (result && result.length > 2) {
      theIndex = result.length - 2;
    }

    return theIndex;
  });
  var tbodyAllTd = (0, _get_node.getTableCellNodes)(tbody, 'td', undefined, false);
  var tbodyTd = tbodyAllTd[0] || [];
  var tfootTd = (0, _get_node.getTableCellNodes)(tfoot);
  var nodeMap = {
    colThead: colThead,
    theadTh: theadTh,
    colTbody: colTbody,
    tbodyTd: tbodyTd,
    colTfoot: colTfoot,
    tfootTd: tfootTd,
    tbodyAllTd: setAllBodyCellMaxMinWidth ? tbodyAllTd : null
  };
  var totalTfootTd = tfootTd[0];
  var width = (0, _number.toInt)(colWidth);
  var totalColSpan = 0;
  var totalMinWidth = 0;
  var totalMaxWidth = 0;
  var newTotalMinWidth = 0;
  var i = 0;

  if ((colThead.length > 0 || colTfoot.length > 0) && colTbody.length > 0 || onlySetHead) {
    if (_maxWidth > 0 && width > _maxWidth) {
      width = _maxWidth;
    }

    if (theadRowAndColSpanData[0][index].colSpan > 1) {
      // 给后面合并单元格留点空间
      if (index > 0) {
        width = width - 1;
      }
    }

    width = (0, _number.toInt)(width); // 是否需要更新宽度

    if (isUpdate) {
      setCellWidth('thead', index, width, isCustomStyleWidth, nodeMap);

      if (!onlySetHead) {
        setCellWidth('tbody', index, width, isCustomStyleWidth, nodeMap);
      }

      if (totalTfootTd && totalTfootTd.style) {
        totalColSpan = totalTfootTd.getAttribute('colspan');
        totalMinWidth = parseInt(totalTfootTd.style.minWidth, 10) || 0;
        totalMaxWidth = parseInt(totalTfootTd.style.maxWidth, 10) || 0;
      } // 如果是总计合并单元格的，设置的应该是第一列


      if (totalColSpan > 1 && totalMinWidth > 0 && totalMinWidth === totalMaxWidth && props.totalLabelColSpan && props.totalLabelColSpan > 1 && index < props.totalLabelColSpan) {
        for (i = 0; i < totalColSpan; i++) {
          if (tbodyTd[i] && tbodyTd[i].style) {
            newTotalMinWidth += parseInt(tbodyTd[i].style.minWidth, 10) || 0;
          }
        }

        setCellWidth('tfoot', 0, newTotalMinWidth, isCustomStyleWidth, nodeMap);
      } else {
        setCellWidth('tfoot', index, width, isCustomStyleWidth, nodeMap);
      }
    }
  }

  return width;
}; // 设置用户自定义的表格单元格宽度


exports.setCellWidthForTable = setCellWidthForTable;

var setCustomWidth = function setCustomWidth() {
  var _setedWidth = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;

  var _setedLen = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;

  var nodeMap = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var props = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  var clientWidth = nodeMap.clientWidth,
      colThead = nodeMap.colThead,
      colTbody = nodeMap.colTbody,
      colTfoot = nodeMap.colTfoot,
      len = nodeMap.len;
  var cellList = [];
  var setedWidth = _setedWidth;
  var setedLen = _setedLen;
  var i = 0;
  var itemThead = null;
  var itemWidth = '';
  var itemMinWidth = 0;
  var itemMaxWidth = 0;
  var percent = '';
  var percentWidth = 0;

  for (i = 0; i < len; i++) {
    itemThead = colTbody[i] || colThead[i] || colTfoot[i];
    itemWidth = itemThead.getAttribute('data-width');
    itemMinWidth = (0, _number.getAttributeToNumber)(itemThead, 'data-min-width');
    itemMaxWidth = (0, _number.getAttributeToNumber)(itemThead, 'data-max-width'); // 处理一下已经设置宽度➕百分比的情况

    if (itemWidth || _index.percentReg.test(itemWidth)) {
      if ("".concat(itemWidth).indexOf('%') > -1) {
        percent = parseFloat(itemWidth, 10) || 0;
        percentWidth = (0, _number.toInt)(clientWidth * percent / 100);

        if (itemMinWidth > 0 && percentWidth < itemMinWidth) {
          percentWidth = itemMinWidth;
        }

        setedWidth += setCellWidthForTable(i, percentWidth, false, itemMaxWidth, nodeMap, props, false);
        setedLen += 1;
        cellList.push({
          index: i,
          colWidth: percentWidth,
          isCustomStyleWidth: false,
          maxWidth: itemMaxWidth,
          nodes: nodeMap,
          props: props
        });
      } else {
        itemWidth = parseFloat(itemWidth) || 0;

        if (itemWidth > 0) {
          setedWidth += setCellWidthForTable(i, itemWidth, false, itemMaxWidth, nodeMap, props, false);
          setedLen += 1;
          cellList.push({
            index: i,
            colWidth: itemWidth,
            isCustomStyleWidth: false,
            maxWidth: itemMaxWidth,
            nodes: nodeMap,
            props: props
          });
        }
      }
    }
  }

  return {
    setedWidth: setedWidth,
    setedLen: setedLen,
    cellList: cellList
  };
}; // 设置实际宽度比计算的大的情况


exports.setCustomWidth = setCustomWidth;

var setRealWidthIsLargeThanTheCalWidth = function setRealWidthIsLargeThanTheCalWidth() {
  var _setedWidth = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;

  var _setedLen = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;

  var nodeMap = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var props = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  var loading = props.loading,
      data = props.data;
  var clientWidth = nodeMap.clientWidth,
      colThead = nodeMap.colThead,
      colTbody = nodeMap.colTbody,
      colTfoot = nodeMap.colTfoot,
      thThead = nodeMap.thThead,
      tdTbody = nodeMap.tdTbody,
      tdTfoot = nodeMap.tdTfoot,
      len = nodeMap.len;
  var hasTableData = data && data.length > 0;
  var setedWidth = _setedWidth;
  var setedLen = _setedLen;
  var i = 0;
  var itemThead = null;
  var itemThThead = null;
  var itemTdTbody = null;
  var itemWidth = '';
  var itemMinWidth = '';
  var itemMaxWidth = '';
  var calWidth = 0;
  var realWidth = 0;
  var cellList = [];

  for (i = 0; i < len; i++) {
    itemThead = colTbody[i] || colThead[i] || colTfoot[i];
    itemThThead = thThead[i] || tdTfoot[i];
    itemTdTbody = tdTbody[i];
    itemWidth = itemThead.getAttribute('data-width');
    itemMinWidth = itemThead.getAttribute('data-min-width');
    itemMaxWidth = (0, _number.toInt)(itemThead.getAttribute('data-max-width'));
    calWidth = (0, _number.toInt)((clientWidth - setedWidth) / (len - setedLen)); // 处理一下还没有设置宽度的

    if ((!itemWidth || !_index.percentReg.test(itemWidth)) && (0, _bool.isSetedCustomStyle)(i, nodeMap)) {
      if (itemThThead && (hasTableData && itemTdTbody || !hasTableData) || itemThThead && loading) {
        if (itemThThead && loading) {
          realWidth = (0, _get_size.getNodeOuterWidth)(itemThThead);
        } else {
          // 看下实际情况下，这一列的最大宽度
          if (hasTableData) {
            var thTheadNodeWidth = (0, _get_size.getNodeOuterWidth)(itemThThead);
            var tdTbodyNodeWidth = (0, _get_size.getNodeOuterWidth)(itemTdTbody);

            if (tdTbodyNodeWidth < thTheadNodeWidth) {
              realWidth = thTheadNodeWidth;
            } else {
              realWidth = Math.min(thTheadNodeWidth, tdTbodyNodeWidth);
            }
          } else {
            realWidth = (0, _get_size.getNodeOuterWidth)(itemThThead);
          }
        }

        if (itemMinWidth > realWidth) {
          setedLen += 1;
          setedWidth += setCellWidthForTable(i, itemMinWidth, true, itemMaxWidth, nodeMap, props, false);
          cellList.push({
            index: i,
            colWidth: itemMinWidth,
            isCustomStyleWidth: true,
            maxWidth: itemMaxWidth,
            nodes: nodeMap,
            props: props
          });
        } else if (realWidth >= calWidth && calWidth < 300) {
          setedLen += 1;
          setedWidth += setCellWidthForTable(i, realWidth, true, itemMaxWidth, nodeMap, props, false);
          cellList.push({
            index: i,
            colWidth: realWidth,
            isCustomStyleWidth: true,
            maxWidth: itemMaxWidth,
            nodes: nodeMap,
            props: props
          });
        }
      }
    }
  }

  return {
    setedWidth: setedWidth,
    setedLen: setedLen,
    cellList: cellList
  };
}; // 给没有指定宽度或实际跨度大于计算宽度的单元格，添加计算的宽度，剩下的平均


exports.setRealWidthIsLargeThanTheCalWidth = setRealWidthIsLargeThanTheCalWidth;

var setCalWidth = function setCalWidth() {
  var _setedWidth = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;

  var _setedLen = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;

  var nodeMap = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var props = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  var hasSetWidthIndexList = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : [];
  var clientWidth = nodeMap.clientWidth,
      colThead = nodeMap.colThead,
      colTbody = nodeMap.colTbody,
      colTfoot = nodeMap.colTfoot,
      len = nodeMap.len,
      thThead = nodeMap.thThead,
      tdTfoot = nodeMap.tdTfoot,
      tdTbody = nodeMap.tdTbody;
  var loading = props.loading,
      data = props.data;
  var hasTableData = data && data.length > 0;
  var setedWidth = _setedWidth;
  var setedLen = _setedLen;
  var i = 0;
  var itemThead = null;
  var itemThThead = null;
  var itemTdTbody = null;
  var itemWidth = '';
  var itemMaxWidth = 0;
  var itemMinWidth = 0;
  var customstyle = false;
  var realWidth = 0;
  var calWidth = 0;
  var cellList = [];

  for (i = 0; i < len; i++) {
    itemThead = colTbody[i] || colThead[i] || colTfoot[i];
    itemThThead = thThead[i] || tdTfoot[i];
    itemTdTbody = tdTbody[i];
    itemWidth = itemThead.getAttribute('data-width');
    itemMinWidth = itemThead.getAttribute('data-min-width');
    itemMaxWidth = (0, _number.getAttributeToNumber)(itemThead, 'data-max-width');
    customstyle = itemThead.getAttribute('data-customstyle');
    calWidth = (0, _number.toInt)((clientWidth - setedWidth) / (len - setedLen)); // 处理一下还没有设置宽度的

    if ((!itemWidth || !_index.percentReg.test(itemWidth)) && !customstyle && itemMaxWidth && calWidth > itemMaxWidth) {
      setedLen += 1;

      if (setedLen === len) {
        calWidth = clientWidth - setedWidth;
      }

      setedWidth += setCellWidthForTable(i, calWidth, false, itemMaxWidth, nodeMap, props, false);
      cellList.push({
        index: i,
        colWidth: calWidth,
        isCustomStyleWidth: false,
        maxWidth: itemMaxWidth,
        nodes: nodeMap,
        props: props
      }); // 处理下还没有设置的列的宽度，宽度取实际宽度和最小宽度的最大值
    } else if ((!itemWidth || !_index.percentReg.test(itemWidth)) && (0, _bool.isSetedCustomStyle)(i, nodeMap) && !hasSetWidthIndexList.includes(i)) {
      if (itemThThead && (hasTableData && itemTdTbody || !hasTableData) || itemThThead && loading) {
        if (itemThThead && loading) {
          realWidth = (0, _get_size.getNodeOuterWidth)(itemThThead);
        } else {
          // 看下实际情况下，这一列的最大宽度
          if (hasTableData) {
            var thTheadNodeWidth = (0, _get_size.getNodeOuterWidth)(itemThThead);
            var tdTbodyNodeWidth = (0, _get_size.getNodeOuterWidth)(itemTdTbody);

            if (tdTbodyNodeWidth < thTheadNodeWidth) {
              realWidth = thTheadNodeWidth;
            } else {
              realWidth = Math.min(thTheadNodeWidth, tdTbodyNodeWidth);
            }
          } else {
            realWidth = (0, _get_size.getNodeOuterWidth)(itemThThead);
          }
        }

        realWidth = Math.max(realWidth, itemMinWidth);

        if (realWidth > 0) {
          setedLen += 1;
          setedWidth += setCellWidthForTable(i, realWidth, true, itemMaxWidth, nodeMap, props, false);
          cellList.push({
            index: i,
            colWidth: realWidth,
            isCustomStyleWidth: true,
            maxWidth: itemMaxWidth,
            nodes: nodeMap,
            props: props
          });
        }
      }
    }
  }

  for (i = 0; i < len; i++) {
    itemThead = colTbody[i] || colThead[i] || colTfoot[i];
    itemWidth = itemThead.getAttribute('data-width');
    itemMaxWidth = (0, _number.getAttributeToNumber)(itemThead, 'data-max-width');
    customstyle = itemThead.getAttribute('data-customstyle');
    calWidth = (0, _number.toInt)((clientWidth - setedWidth) / (len - setedLen)); // 处理一下还没有设置宽度的

    if ((!itemWidth || !_index.percentReg.test(itemWidth)) && !customstyle && calWidth > 0) {
      setedLen += 1;

      if (setedLen === len) {
        calWidth = clientWidth - setedWidth;
      }

      setedWidth += setCellWidthForTable(i, calWidth, false, itemMaxWidth, nodeMap, props, false);
      cellList.push({
        index: i,
        colWidth: calWidth,
        isCustomStyleWidth: false,
        maxWidth: itemMaxWidth,
        nodes: nodeMap,
        props: props
      });
    }
  }

  return {
    setedWidth: setedWidth,
    setedLen: setedLen,
    cellList: cellList
  };
}; // 设置表头单元格的宽度跟数据表格一致


exports.setCalWidth = setCalWidth;

var setTheadTableCellWidthEqualTbodyTable = function setTheadTableCellWidthEqualTbodyTable(nodeMap, props) {
  var data = props.data;
  var trTbody = nodeMap.trTbody,
      thead = nodeMap.thead,
      colThead = nodeMap.colThead;
  var trThead = thead && thead.getElementsByTagName('tr');
  var tdList = null;
  var i = 0;
  var len = 0;
  var itemWidth = 0;
  var itemMaxWidth = 0; // 如果存在表格数据

  if (trThead && trThead.length > 0 && trTbody && trTbody.length > 0 && data && data.length > 0) {
    tdList = trTbody[0].getElementsByTagName('td');
    len = tdList.length;

    for (; i < len; i++) {
      if (tdList[i]) {
        // 如果col已经指明了宽度，就用属性上的
        if (colThead && colThead[i] && colThead[i].getAttribute('data-width')) {
          itemWidth = (0, _number.toInt)(colThead[i].getAttribute('data-width'));
        } else {
          itemWidth = 0;
        }

        if (!itemWidth) {
          itemWidth = (0, _number.toInt)((0, _spauiUtils.getStyle)(tdList[i], 'width'));
        }

        itemMaxWidth = (0, _number.getAttributeToNumber)(tdList[i], 'data-max-width');

        if (itemWidth) {
          setCellWidthForTable(i, itemWidth, false, itemMaxWidth, nodeMap, props);
        }
      }
    }
  }
}; // 设置表格外层的宽度


exports.setTheadTableCellWidthEqualTbodyTable = setTheadTableCellWidthEqualTbodyTable;

var setWrapWidth = function setWrapWidth(width) {
  var nodeMap = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var clientWidth = nodeMap.clientWidth,
      theadWrap = nodeMap.theadWrap,
      tbodyWrap = nodeMap.tbodyWrap,
      tfootWrap = nodeMap.tfootWrap;
  var result = 0;

  if (width !== clientWidth) {
    result = width;
  } else {
    // 如果是跟可是窗口宽度一样，那就用100%
    result = '100%';
  }

  setNodeWidth(theadWrap, result);
  setNodeWidth(tbodyWrap, result);
  setNodeWidth(tfootWrap, result);
}; // 设置表格的宽度跟className


exports.setWrapWidth = setWrapWidth;

var setTableWidthAndClassName = function setTableWidthAndClassName() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var nodeMap = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var colThead = nodeMap.colThead,
      colTfoot = nodeMap.colTfoot,
      clientWidth = nodeMap.clientWidth,
      tableWrap = nodeMap.tableWrap,
      tbodyWrap = nodeMap.tbodyWrap,
      tableTheadWraper = nodeMap.tableTheadWraper,
      tableTfootWraper = nodeMap.tableTfootWraper,
      tbodyTable = nodeMap.tbodyTable,
      tableTbodyWraper = nodeMap.tableTbodyWraper;
  var isFixedTableTheadByCssOrNot = (0, _bool.isFixedTableTheadByCss)(props);
  var cssFixedTotalAble = (0, _bool.isFixedTableTotalByCss)(props);
  var isFixedTableTheadOrNot = (0, _bool.isFixedTableThead)(props);
  var isFixedTableColumnOrNot = (0, _bool.isFixedTableColumn)(props);
  var isNotFixedDom = !isFixedTableTheadOrNot && !cssFixedTotalAble && !isFixedTableColumnOrNot;
  var tableWrapClassName = (tableWrap.className || '').split(' ');
  var lastMomentScrollLeft = tableWrap.scrollLeft;

  var getTotalWidth = function getTotalWidth() {
    var domNodes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    // 先设置下比较小的宽度，以便能够避免每一列单元格有冗余空间
    setWrapWidth(500, nodeMap);
    return (0, _get_size.getNodesTotalWidth)(domNodes, clientWidth, isNotFixedDom);
  };

  var addScrollClassName = function addScrollClassName() {
    // 在没有css固定尾部且没有添加滚动class的时候添加一下class
    if ((0, _spauiUtils.indexOf)(tableWrapClassName, _index.tableScrollClassName) < 0 && (0, _spauiUtils.indexOf)(tableWrapClassName, _index.tableFixedTheadClassName) < 0) {
      tableWrapClassName.push(_index.tableScrollClassName);
    }
  };

  var removeScrollClassName = function removeScrollClassName() {
    var scrollIndex = (0, _spauiUtils.indexOf)(tableWrapClassName, _index.tableScrollClassName);

    if (scrollIndex > -1) {
      tableWrapClassName.splice(scrollIndex, 1);
    }
  };

  var totalWidth = 0;
  var thead = null;
  var i = 0;
  var len = 0;
  var tr = [];
  var th = []; // 固定了thead或者tfooter

  if (isFixedTableTheadOrNot || cssFixedTotalAble) {
    // 记录下原始 body 区域的宽度和滚动宽度，便于后面进行还原
    var originScrollLeft = tableTbodyWraper && tableTbodyWraper.scrollLeft;
    var originTbodyWidth = tbodyWrap && (0, _number.toInt)((0, _spauiUtils.getStyle)(tbodyWrap, 'width')); // 获取总宽度，先看thead再看foot

    totalWidth = getTotalWidth(colThead) || getTotalWidth(colTfoot); // 如果是通过css固定头部的，thead的外层需要设置下宽度

    if (isFixedTableTheadByCssOrNot) {
      setNodeWidth(tableTheadWraper, clientWidth);
    } // 如果是通过css固定头部的，tfoot的外层需要设置下宽度


    if (cssFixedTotalAble) {
      setNodeWidth(tableTfootWraper, clientWidth);
    }

    if (totalWidth >= clientWidth) {
      // 设置下全部外层的宽度
      setWrapWidth(totalWidth, nodeMap);
      addScrollClassName();
    } else {
      // 如果总宽度小于视窗宽度，就去除下滚动条的class
      removeScrollClassName();
    } // 如果原先的 scrollLeft 在当前场景下能够放得下，则需要进行还原


    if (tbodyWrap && tableTbodyWraper) {
      if (originTbodyWidth > 0) {
        tbodyWrap.style.width = "".concat(originTbodyWidth, "px");
      }

      var _maxScrollLeft = tbodyWrap.clientWidth - tableTbodyWraper.clientWidth;

      if (originScrollLeft <= _maxScrollLeft) {
        tableTbodyWraper.scrollLeft = originScrollLeft;
      }
    }
  } else if (tbodyTable && clientWidth > 0) {
    // 看下是否有thead
    if (tbodyTable.getElementsByTagName('thead').length > 0) {
      thead = tbodyTable.getElementsByTagName('thead')[0];
    } // 如果有thead的话


    if (thead) {
      tr = thead.getElementsByTagName('tr');

      if (tr && tr.length > 0) {
        if (tr.length > 1) {
          // 如果有两个tr以上的，用倒数第二行的th作为长度的计算
          th = tr[tr.length - 2].getElementsByTagName('th');
        } else {
          th = tr[0].getElementsByTagName('th');
        }

        len = th.length;
      }

      if (len > 0) {
        // 先把th设置下宽度，然后再计算宽度
        for (; i < len; i++) {
          setNodeWidthByAttr(th[i]);
        }

        totalWidth = getTotalWidth(th);

        if (totalWidth > clientWidth) {
          // 没有固定thead,tfooter就只有一个wrap
          setNodeWidth(tbodyWrap, totalWidth);
          addScrollClassName();
        } else {
          setNodeWidth(tbodyWrap, '100%');
          removeScrollClassName();
        }
      }
    }
  } // 由于重新计算宽度的时候，原先滚动条的 scrollLeft 无法继续保持。所以这里如果原先的 scrollLeft 在当前场景下能够放得下，则需要进行还原


  var maxScrollLeft = totalWidth - tableWrap.clientWidth;

  if (lastMomentScrollLeft <= maxScrollLeft) {
    tableWrap.scrollLeft = lastMomentScrollLeft;
  } // 设置下className


  tableWrap.className = tableWrapClassName.join(' ');
};

exports.setTableWidthAndClassName = setTableWidthAndClassName;
var _default = {
  setWrapWidth: setWrapWidth,
  setCellWidth: setCellWidth,
  setNodeWidth: setNodeWidth,
  setNodeWidthByAttr: setNodeWidthByAttr,
  clearTableWidth: clearTableWidth,
  setCustomWidth: setCustomWidth,
  setRealWidthIsLargeThanTheCalWidth: setRealWidthIsLargeThanTheCalWidth,
  setCalWidth: setCalWidth,
  setTheadTableCellWidthEqualTbodyTable: setTheadTableCellWidthEqualTbodyTable,
  setTableWidthAndClassName: setTableWidthAndClassName
};
exports["default"] = _default;