"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var EmptyComponent = function EmptyComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _props$useFileAPI = props.useFileAPI,
      useFileAPI = _props$useFileAPI === void 0 ? false : _props$useFileAPI,
      drag = props.drag,
      _props$useNewUI = props.useNewUI,
      useNewUI = _props$useNewUI === void 0 ? false : _props$useNewUI;
  var otherProps = {};
  var key = '';

  for (key in props) {
    if (!['EmptyComponent', 'useFileAPI', 'drag', 'useNewUI', 'componentProps'].includes(key)) {
      otherProps[key] = props[key];
    }
  }

  if (useNewUI) {
    return _react["default"].createElement("span", _extends({
      className: "bg-tips-w"
    }, otherProps), _react["default"].createElement("span", {
      className: "w-bold"
    }, "\u70B9\u51FB", useFileAPI && drag ? '或拖拽' : '', "\u4E0A\u4F20"));
  }

  return _react["default"].createElement("span", {
    className: "bg-tips-w"
  }, _react["default"].createElement("span", {
    className: "w-bold"
  }, "\u70B9\u51FB", useFileAPI && drag ? '或拖拽' : '', "\u4E0A\u4F20"));
};

var _default = EmptyComponent;
exports["default"] = _default;