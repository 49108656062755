"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * 展开收起图标
 */
var TitleComponent = function TitleComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _props$fieldNames = props.fieldNames,
      fieldNames = _props$fieldNames === void 0 ? {} : _props$fieldNames,
      _props$data = props.data,
      data = _props$data === void 0 ? {} : _props$data,
      onClick = props.onClick,
      className = props.className;
  var _fieldNames$labelFiel = fieldNames.labelFieldName,
      labelFieldName = _fieldNames$labelFiel === void 0 ? 'desc' : _fieldNames$labelFiel;
  return _react["default"].createElement("span", {
    className: className,
    onClick: onClick
  }, data[labelFieldName] || '');
};

var _default = TitleComponent;
exports["default"] = _default;