"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _index = require("../../utils/index");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
var HeadComponent = function HeadComponent(props) {
  var _props$info = props.info,
      info = _props$info === void 0 ? {} : _props$info,
      download = props.download,
      className = props.className,
      closeClassName = props.closeClassName,
      onClose = props.onClose,
      titleBar = props.titleBar,
      _props$style = props.style,
      style = _props$style === void 0 ? {} : _props$style,
      _props$locale = props.locale,
      locale = _props$locale === void 0 ? {} : _props$locale;
  return _react["default"].createElement("div", {
    className: (0, _classnames["default"])('viewer-head clearfix', className),
    style: style
  }, titleBar ? _react["default"].createElement("div", {
    className: "viewer-titlebar"
  }, _react["default"].createElement("div", {
    className: "viewer-title"
  }, _react["default"].createElement("i", {
    className: "viewer-icon viewer-icon-mid viewer-icon-pic"
  }), _react["default"].createElement("span", {
    className: "viewer-txt"
  }, (0, _index.getItemName)(info) || (0, _index.getItemThumbUrl)(info) || ''))) : null, download ? _react["default"].createElement("div", {
    className: "viewer-action"
  }, _react["default"].createElement("div", {
    className: "action-item"
  }, _react["default"].createElement("a", {
    href: (0, _index.getDownloadUrl)(info),
    target: "_blank",
    download: true
  }, _react["default"].createElement("div", {
    className: "viewer-action-btn"
  }, _react["default"].createElement("svg", {
    width: "16px",
    height: "16px",
    viewBox: "0 0 16 16",
    version: "1.1",
    xmlns: "http://www.w3.org/2000/svg",
    xmlnsXlink: "http://www.w3.org/1999/xlink"
  }, _react["default"].createElement("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd"
  }, _react["default"].createElement("path", {
    /* eslint-disable-next-line */
    d: "M8.50125122,10.8253533 L12.9098747,7.29845448 C13.1255057,7.12594971 13.4401519,7.1609104 13.6126566,7.37654137 C13.7851614,7.59217233 13.7502007,7.90681852 13.5345697,8.07932329 L8.31234752,12.2571011 C8.1297382,12.4031885 7.8702618,12.4031885 7.68765248,12.2571011 L2.46543025,8.07932329 C2.24979929,7.90681852 2.2148386,7.59217233 2.38734337,7.37654137 C2.55984815,7.1609104 2.87449433,7.12594971 3.0901253,7.29845448 L7.50125122,10.8273552 L7.50125122,1.54461666 C7.50125122,1.24383318 7.72510885,1 8.00125122,1 C8.2773936,1 8.50125122,1.24383318 8.50125122,1.54461666 L8.50125122,10.8253533 Z M1.5,14 L14.5,14 C14.7761424,14 15,14.2238576 15,14.5 C15,14.7761424 14.7761424,15 14.5,15 L1.5,15 C1.22385763,15 1,14.7761424 1,14.5 C1,14.2238576 1.22385763,14 1.5,14 Z",
    fill: "CurrentColor"
  }))), _react["default"].createElement("span", {
    className: "viewer-txt"
  }, locale.downloadLabel))))) : null, _react["default"].createElement("div", {
    title: "\u5173\u95ED",
    className: closeClassName,
    onClick: onClose
  }, _react["default"].createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    xmlnsXlink: "http://www.w3.org/1999/xlink",
    width: "24px",
    height: "24px",
    viewBox: "0 0 24 24",
    version: "1.1"
  }, _react["default"].createElement("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd"
  }, _react["default"].createElement("rect", {
    x: "0",
    y: "0",
    width: "20",
    height: "20"
  }), _react["default"].createElement("g", {
    transform: "translate(-5.000000, -5.000000)",
    fill: "CurrentColor"
  }, _react["default"].createElement("polygon", {
    /* eslint-disable-next-line */
    transform: "translate(17.000000, 17.000000) rotate(45.000000) translate(-17.000000, -17.000000) ",
    points: "18 16 29 16 29 18 18 18 18 29 16 29 16 18 5 18 5 16 16 16 16 5 18 5"
  }))))));
};

var _default = HeadComponent;
exports["default"] = _default;