"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.getScrollLeft = exports.getScrollTop = exports.haveJQuery = exports.getScrollBarVisibleWidth = exports.getTextSize = exports.getNodesTotalWidth = exports.getNodeOuterWidth = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

var _number = require("./number");

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

/**
 * 获取节点的outerwidth
 * @property {object} node=null 节点
 */
var getNodeOuterWidth = function getNodeOuterWidth() {
  var node = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  var width = 0;
  var paddingLeft = 0;
  var paddingRight = 0;
  width = (0, _number.toInt)((0, _spauiUtils.getStyle)(node, 'width')); // paddingLeft = parseInt(getStyle(node, 'paddingLeft'), 10) || 0;
  // paddingRight = parseInt(getStyle(node, 'paddingRight'), 10) || 0;

  return width + paddingLeft + paddingRight;
};
/**
 * 获取节点的outerwidth
 * @property {object} node=null 节点
 * @property {number} clientWidth = 0 目前这些节点的可视宽度
 */


exports.getNodeOuterWidth = getNodeOuterWidth;

var getNodesTotalWidth = function getNodesTotalWidth() {
  var nodes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var clientWidth = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var isStatic = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var len = nodes.length || 0;
  var i = 0;
  var result = 0;
  var domPaddingLeft = 0;
  var domPaddingRight = 0;
  var domWidth = 0;
  var domMinWidth = 0;
  var domRealWidth = 0;
  var domTdWidth = 0;
  var isNotSetWidth = true;
  var totalMinWidth = 0;

  for (; i < len; i++) {
    if (nodes[i]) {
      domPaddingLeft = (0, _number.toInt)((0, _spauiUtils.getStyle)(nodes[i], 'paddingLeft'));
      domPaddingRight = (0, _number.toInt)((0, _spauiUtils.getStyle)(nodes[i], 'paddingRight'));
      domRealWidth = (0, _number.toInt)((0, _spauiUtils.getStyle)(nodes[i], 'width'));
      domWidth = (0, _number.toInt)(nodes[i].getAttribute('width'));
      domMinWidth = (0, _number.toInt)(nodes[i].getAttribute('data-min-width'));
      domTdWidth = domPaddingLeft + domPaddingRight + domRealWidth;

      if (isNotSetWidth && (domWidth > 0 || domMinWidth > 0 && domRealWidth < domMinWidth)) {
        isNotSetWidth = false;
      }

      if (domWidth > 0 && domWidth >= domMinWidth) {
        domTdWidth = domWidth;
      } else if (domMinWidth > domTdWidth) {
        domTdWidth = domMinWidth;
      }

      totalMinWidth += domMinWidth;
      result += domTdWidth;
    }
  }

  if (isNotSetWidth && isStatic) {
    result = 0;
  }

  if (result < clientWidth) {
    result = clientWidth;
  } // 如果最小宽度大于结果宽度，就用最小宽度


  if (totalMinWidth > result) {
    result = totalMinWidth;
  }

  return result;
};

exports.getNodesTotalWidth = getNodesTotalWidth;

var getTextSize = function getTextSize() {
  var text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var span = document.createElement('span');
  var result = {};
  result.width = span.offsetWidth;
  result.height = span.offsetHeight;
  span.style.visibility = 'hidden';
  span.style.display = 'inline-block';
  document.body.appendChild(span);

  if (typeof span.textContent !== 'undefined') {
    span.textContent = text;
  } else {
    span.innerText = text;
  }

  result.width = Math.ceil(parseFloat(window.getComputedStyle(span).width) - result.width);
  result.height = Math.ceil(parseFloat(window.getComputedStyle(span).height) - result.height);
  return result;
};

exports.getTextSize = getTextSize;

var getScrollbarWidth = function getScrollbarWidth(node) {
  var odiv = document.createElement('div');
  var styles = {
    width: '100px',
    height: '100px',
    overflowY: 'scroll'
  };
  var i;

  for (i in styles) {
    if (styles[i]) {
      odiv.style[i] = styles[i];
    }
  }

  node.appendChild(odiv); // 把div添加到body中

  var result = odiv.offsetWidth - odiv.clientWidth; // 相减

  if (odiv && odiv.remove) {
    odiv.remove(); // 移除创建的div
  }

  return result; // 返回滚动条宽度
};

var getScrollBarVisibleWidth = function getScrollBarVisibleWidth() {
  var parentNode = document.createElement('div');
  var childNode = document.createElement('div');
  var parentNodeWidth = 0;
  var childNodeWidth = 0;
  var scrollBarWidth = 0;
  var result = 0;
  parentNode.style.height = '1px';
  parentNode.style.overflow = '100%';
  childNode.style.height = '2px';
  parentNode.appendChild(childNode);
  document.body.appendChild(parentNode);
  parentNodeWidth = parseFloat((0, _spauiUtils.getStyle)(parentNode, 'width'), 10) || 0;
  childNodeWidth = parseFloat((0, _spauiUtils.getStyle)(childNode, 'width'), 10) || 0;
  scrollBarWidth = parseFloat(getScrollbarWidth(parentNode), 10) || 0;

  if (parentNodeWidth === childNodeWidth + scrollBarWidth) {
    result = scrollBarWidth;
  }

  if (parentNode && parentNode.remove) {
    parentNode.remove();
  }

  return result;
}; // 是否有jquery


exports.getScrollBarVisibleWidth = getScrollBarVisibleWidth;
var haveJQuery = (typeof window === "undefined" ? "undefined" : _typeof(window)) === 'object' && typeof window.jQuery !== 'undefined'; // scrollTop

exports.haveJQuery = haveJQuery;

var getScrollTop = function getScrollTop(target) {
  var result = 0;

  if (target) {
    result = target.scrollTop;

    if (result < 1 && haveJQuery) {
      result = jQuery(target).scrollTop();
    }
  } else {
    if ((typeof document === "undefined" ? "undefined" : _typeof(document)) === 'object') {
      if (document.getElementsByTagName('html')[0].scrollTop) {
        result = document.getElementsByTagName('html')[0].scrollTop;
      } else if (document.getElementsByTagName('body')[0].scrollTop) {
        result = document.getElementsByTagName('body')[0].scrollTop;
      }
    }

    if (result < 1 && haveJQuery) {
      result = jQuery('html').scrollTop() || jQuery('body').scrollTop();
    }
  }

  return result;
}; // scrollLeft


exports.getScrollTop = getScrollTop;

var getScrollLeft = function getScrollLeft(target) {
  var result = 0;

  if (target) {
    result = target.scrollLeft;

    if (result < 1 && haveJQuery) {
      result = jQuery(target).scrollLeft();
    }
  } else {
    if ((typeof document === "undefined" ? "undefined" : _typeof(document)) === 'object') {
      if (document.getElementsByTagName('html')[0].scrollLeft) {
        result = document.getElementsByTagName('html')[0].scrollLeft;
      } else if (document.getElementsByTagName('body')[0].scrollLeft) {
        result = document.getElementsByTagName('body')[0].scrollLeft;
      }
    }

    if (result < 1 && haveJQuery) {
      result = jQuery('html').scrollLeft() || jQuery('body').scrollLeft();
    }
  }

  return result;
};

exports.getScrollLeft = getScrollLeft;
var _default = {
  getNodeOuterWidth: getNodeOuterWidth,
  getNodesTotalWidth: getNodesTotalWidth,
  getScrollBarVisibleWidth: getScrollBarVisibleWidth,
  getScrollTop: getScrollTop,
  getScrollLeft: getScrollLeft
};
exports["default"] = _default;