"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _icon_component = _interopRequireDefault(require("./icon_component"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var PlaceholderBranchComponent = function PlaceholderBranchComponent(props) {
  var classNameMap = props.classNameMap,
      className = props.className,
      style = props.style,
      content = props.content,
      isLoading = props.isLoading;
  return _react["default"].createElement("div", {
    className: (0, _classnames["default"])(className, classNameMap.TreeEmptyBranchClassName),
    style: style
  }, isLoading ? _react["default"].createElement(_icon_component["default"], {
    type: "loading-blue"
  }) : null, content);
};

var _default = PlaceholderBranchComponent;
exports["default"] = _default;