"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
var SelectedTipComponent = function SelectedTipComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _props$selectionTitle = props.selectionTitle,
      selectionTitle = _props$selectionTitle === void 0 ? '' : _props$selectionTitle;
  return _react["default"].createElement("h3", {
    className: "title"
  }, selectionTitle);
};

var _default = SelectedTipComponent;
exports["default"] = _default;