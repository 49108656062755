"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.removeResize = exports.addResize = exports.toInt = exports.getDefaultValue = exports.getComponentType = exports.setNodeAtTop = exports.addZero = exports.genList = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

// 生成时间列表
var genList = function genList() {
  var maxNumber = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 60;
  var result = [];
  var i = 0;

  for (; i < maxNumber; i++) {
    result.push(i);
  }

  return result;
}; // 添加0


exports.genList = genList;

var addZero = function addZero(item) {
  var result = parseInt(item, 10) || 0;

  if (result < 10) {
    result = "0".concat(result);
  } else {
    result = "".concat(result);
  }

  return result;
}; // 子元素默认设置在顶部


exports.addZero = addZero;

var setNodeAtTop = function setNodeAtTop() {
  var node = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  var parentNode = null;

  if (node) {
    parentNode = node.parentNode || null;

    if (parentNode) {
      parentNode.scrollTop = node.offsetTop;
    }
  }
}; // 获取组件的蕾西


exports.setNodeAtTop = setNodeAtTop;

var getComponentType = function getComponentType() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _props$config = props.config,
      config = _props$config === void 0 ? {} : _props$config,
      timeList = props.timeList;
  var _config$hour = config.hour,
      propsHour = _config$hour === void 0 ? false : _config$hour,
      _config$minute = config.minute,
      propsMinute = _config$minute === void 0 ? false : _config$minute,
      _config$second = config.second,
      propsSecond = _config$second === void 0 ? false : _config$second;
  var result = {
    onlyCustomTime: false,
    onlyHour: false,
    onlyHourAndMinute: false,
    hourAndMinuteAndSecond: false
  };

  if (!(0, _spauiUtils.isUndefined)(timeList)) {
    result.onlyCustomTime = true;
  } else if (propsHour && !propsMinute && !propsSecond) {
    result.onlyHour = true;
  } else if (propsHour && propsMinute && !propsSecond) {
    result.onlyHourAndMinute = true;
  } else if (propsHour && propsMinute && propsSecond) {
    result.hourAndMinuteAndSecond = true;
  }

  return result;
}; // 获取默认的时间数据


exports.getComponentType = getComponentType;

var getDefaultValue = function getDefaultValue(value) {
  var valueList = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  return value || valueList[0] || '00';
}; // 转化为数字


exports.getDefaultValue = getDefaultValue;

var toInt = function toInt(value) {
  var defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  return parseInt(value, 10) || defaultValue;
};

exports.toInt = toInt;
var haveJQuery = (typeof window === "undefined" ? "undefined" : _typeof(window)) === 'object' && typeof window.jQuery === 'function'; // 添加resize

var addResize = function addResize(resizeCallback) {
  var target = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : window;

  if (target) {
    if (haveJQuery) {
      jQuery(target).on('resize', resizeCallback);
    } else if (_typeof(target) === 'object' && (0, _spauiUtils.isFunction)(target.addEventListener)) {
      target.addEventListener('resize', resizeCallback);
    }
  }
}; // 去除resize


exports.addResize = addResize;

var removeResize = function removeResize(resizeCallback) {
  var target = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : window;

  if (target) {
    if (haveJQuery) {
      jQuery(target).off('resize', resizeCallback);
    } else if (_typeof(target) === 'object' && (0, _spauiUtils.isFunction)(target.removeEventListener)) {
      target.removeEventListener('resize', resizeCallback);
    }
  }
};

exports.removeResize = removeResize;
var _default = {
  genList: genList,
  addZero: addZero,
  setNodeAtTop: setNodeAtTop,
  getComponentType: getComponentType,
  getDefaultValue: getDefaultValue,
  toInt: toInt,
  addResize: addResize,
  removeResize: removeResize
};
exports["default"] = _default;