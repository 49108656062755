"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
var ActionComponent = function ActionComponent(props) {
  var _props$type = props.type,
      type = _props$type === void 0 ? '' : _props$type,
      canImport = props.canImport,
      onShowImport = props.onShowImport,
      importLabel = props.importLabel,
      onClear = props.onClear,
      _props$data = props.data,
      data = _props$data === void 0 ? [] : _props$data,
      disabled = props.disabled;

  if (disabled) {
    return null;
  }

  return _react["default"].createElement("div", {
    className: (0, _classnames["default"])('titlebar-action', {
      'spaui-links': type !== 'count'
    })
  }, canImport && type !== 'count' ? _react["default"].createElement("a", {
    className: "spaui-cursor-pointer",
    onClick: onShowImport
  }, importLabel) : null, data.length > 0 && type !== 'count' ? _react["default"].createElement("a", {
    className: "spaui-cursor-pointer",
    onClick: onClear
  }, "\u6E05\u7A7A") : null, type === 'count' ? _react["default"].createElement("small", null, "\u5171", data.length, "\u4E2A") : null);
};

var _default = ActionComponent;
exports["default"] = _default;