"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.childIsSelect = exports.nodeIsOpen = exports.nodeIsSelected = exports.addEndSlashToHref = exports.uniqueId = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

// 生成唯一id
var uniqueId = function uniqueId() {
  return (0, _spauiUtils.uniqueId)('spaui-menu');
}; // 对路径添加斜杠后缀


exports.uniqueId = uniqueId;

var addEndSlashToHref = function addEndSlashToHref() {
  var href = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

  if ((href || '').endsWith('/')) {
    return href;
  }

  return "".concat(href, "/");
}; // 节点是否选中


exports.addEndSlashToHref = addEndSlashToHref;

var nodeIsSelected = function nodeIsSelected(value) {
  var nodeData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  var _nodeData$href = nodeData.href,
      href = _nodeData$href === void 0 ? '' : _nodeData$href,
      _nodeData$key = nodeData.key,
      key = _nodeData$key === void 0 ? '' : _nodeData$key,
      _nodeData$data = nodeData.data,
      data = _nodeData$data === void 0 ? [] : _nodeData$data,
      _nodeData$exact = nodeData.exact,
      exact = _nodeData$exact === void 0 ? true : _nodeData$exact;
  var result = value && (value === href || value === key || !exact && (value || '').startsWith(addEndSlashToHref(href))); // 如果是缩小且没有选中的还得看看子节点是否选中

  if (!result && type === 'mini') {
    data.map(function () {
      var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      if (item) {
        if (!result && value && (value === item.href || value === item.key || !exact && (value || '').startsWith(addEndSlashToHref(item.href)))) {
          result = true;
        }
      }

      return item;
    });
  }

  return result;
}; // 节点是否展开


exports.nodeIsSelected = nodeIsSelected;

var nodeIsOpen = function nodeIsOpen(value, open) {
  var nodeData = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

  // const { href, key, data } = nodeData;
  var isOpen = function isOpen() {
    var currentNodeData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var href = currentNodeData.href,
        key = currentNodeData.key,
        _currentNodeData$data = currentNodeData.data,
        data = _currentNodeData$data === void 0 ? [] : _currentNodeData$data,
        _currentNodeData$exac = currentNodeData.exact,
        exact = _currentNodeData$exac === void 0 ? true : _currentNodeData$exac;
    var openResult = false; // 先看第一层

    if (typeof open === 'undefined' && (value === href || value === key || !exact && (value || '').startsWith(addEndSlashToHref(href))) || (0, _spauiUtils.indexOf)(open, href) > -1 || (0, _spauiUtils.indexOf)(open, key) > -1) {
      openResult = true; // 在看第二层
    } else if (data.length > 0) {
      data.map(function (item) {
        if (!openResult) {
          openResult = isOpen(item);
        }

        return item;
      });

      if (openResult) {
        openResult = true;
      }
    } else {
      openResult = false;
    }

    return openResult;
  };

  var result = false;

  if (value || open) {
    result = isOpen(nodeData);
  }

  return result;
}; // 子节点是否选中


exports.nodeIsOpen = nodeIsOpen;

var childIsSelect = function childIsSelect(value, nodeData, currentNodeIsSelected) {
  var getChildIsSelectresult = function getChildIsSelectresult(item) {
    if (_typeof(item) === 'object' && item) {
      // 判断下是否有子级选中
      childData = item._origin && item._origin.data || item.data || [];

      if ((0, _spauiUtils.isArray)(childData)) {
        childData.map(function (childItem) {
          if (childItem && !result) {
            if (currentNodeIsSelected(value, childItem, 'mini')) {
              result = true;
            } else {
              getChildIsSelectresult(childItem);
            }
          }

          return childData;
        });
      }
    }
  };

  var result = false;
  var childData = []; // 递归每一层

  getChildIsSelectresult(nodeData);
  return result;
};

exports.childIsSelect = childIsSelect;
var _default = {
  uniqueId: uniqueId,
  nodeIsSelected: nodeIsSelected,
  nodeIsOpen: nodeIsOpen,
  childIsSelect: childIsSelect
};
exports["default"] = _default;