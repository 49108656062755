"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.RANGE_DATE_SEPARATOR = exports.getRangeDefaultShortcuts = exports.getSingleDefaultShortCuts = exports.ONE_DAY_TIME = exports.MONTH_LIST = void 0;

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
// 月份数据表
var MONTH_LIST = [{
  value: 0
}, {
  value: 1
}, {
  value: 2
}, {
  value: 3
}, {
  value: 4
}, {
  value: 5
}, {
  value: 6
}, {
  value: 7
}, {
  value: 8
}, {
  value: 9
}, {
  value: 10
}, {
  value: 11
}]; // 一天的时间

exports.MONTH_LIST = MONTH_LIST;
var ONE_DAY_TIME = 24 * 60 * 60 * 1000; // 单日期默认快捷选择配置

exports.ONE_DAY_TIME = ONE_DAY_TIME;

var getSingleDefaultShortCuts = function getSingleDefaultShortCuts() {
  var locale = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return [{
    relativeDate: 0,
    label: locale.todayLabel
  }, {
    relativeDate: -1,
    label: locale.yesterdayLabel
  }, {
    relativeDate: -6,
    label: locale.daysAgoLabel.replace('{value}', 7)
  }, {
    relativeDate: -29,
    label: locale.daysAgoLabel.replace('{value}', 30)
  }];
}; // 双日期默认快捷选择配置


exports.getSingleDefaultShortCuts = getSingleDefaultShortCuts;

var getRangeDefaultShortcuts = function getRangeDefaultShortcuts() {
  var locale = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return [{
    startRelativeDate: 0,
    endRelativeDate: 0,
    label: locale.todayLabel
  }, {
    startRelativeDate: -1,
    endRelativeDate: -1,
    label: locale.yesterdayLabel
  }, {
    startRelativeDate: -6,
    endRelativeDate: 0,
    label: locale.lastDaysLabel.replace('{value}', 7)
  }, {
    startRelativeDate: -29,
    endRelativeDate: 0,
    label: locale.lastDaysLabel.replace('{value}', 30)
  }, {
    startRelativeDate: -89,
    endRelativeDate: 0,
    label: locale.lastDaysLabel.replace('{value}', 90)
  }, // 设置了dynamicDate为true 会在选择了startDate但endDate没选时生效，并以startDate为相对日期
  {
    startRelativeDate: 0,
    endRelativeDate: 6,
    dynamicDate: true,
    label: locale.fromNowDaysLabel.replace('{value}', 7)
  }, {
    startRelativeDate: 0,
    endRelativeDate: 29,
    dynamicDate: true,
    label: locale.fromNowDaysLabel.replace('{value}', 30)
  }, {
    startRelativeDate: 0,
    endRelativeDate: 89,
    dynamicDate: true,
    label: locale.fromNowDaysLabel.replace('{value}', 90)
  }];
}; // 日期范围的分隔符


exports.getRangeDefaultShortcuts = getRangeDefaultShortcuts;
var RANGE_DATE_SEPARATOR = '   -   ';
exports.RANGE_DATE_SEPARATOR = RANGE_DATE_SEPARATOR;
var _default = {
  MONTH_LIST: MONTH_LIST,
  ONE_DAY_TIME: ONE_DAY_TIME,
  getSingleDefaultShortCuts: getSingleDefaultShortCuts,
  getRangeDefaultShortcuts: getRangeDefaultShortcuts,
  RANGE_DATE_SEPARATOR: RANGE_DATE_SEPARATOR
};
exports["default"] = _default;