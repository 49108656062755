"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.getCurrentDataForValueHolder = exports.getDataSet = exports.getUniqueKey = exports.isShowReverseCheck = exports.isShowCheckAll = exports.getShowData = exports.isEqual = exports.getTheContentOfTheValueAreaComponent = exports.haveJQuery = exports.emphasize = exports.filter = exports.getTheSignOfTheItemComponent = exports.getTheContentOfTheItemComponent = void 0;

var _react = _interopRequireDefault(require("react"));

var _spauiUtils = require("@tencent/spaui-utils");

var _classnames = _interopRequireDefault(require("classnames"));

var _spauiIcon = _interopRequireDefault(require("@tencent/spaui-icon"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

// 匹配一个汉字或字符的正则
var charMatchRegexString = /(?:[\0-\uD7FF\uE000-\uFFFF]|[\uD800-\uDBFF][\uDC00-\uDFFF]|[\uD800-\uDBFF](?![\uDC00-\uDFFF])|(?:[^\uD800-\uDBFF]|^)[\uDC00-\uDFFF])/.source; // 根据是否html来区分获取label的结构

var getLabelDom = function getLabelDom() {
  var label = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var html = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var className = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'name';
  var result = null;

  if (!html) {
    result = _react["default"].createElement("span", {
      className: className
    }, label);
  } else {
    result = _react["default"].createElement("span", {
      className: className,
      dangerouslySetInnerHTML: {
        __html: label
      }
    });
  }

  return result;
}; // 获取当前选项的值与文案


var getItemInfo = function getItemInfo() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var props = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var _props$labelFieldName = props.labelFieldName,
      labelFieldName = _props$labelFieldName === void 0 ? '' : _props$labelFieldName,
      _props$valueFieldName = props.valueFieldName,
      valueFieldName = _props$valueFieldName === void 0 ? '' : _props$valueFieldName,
      _props$originData = props.originData,
      originData = _props$originData === void 0 ? {} : _props$originData;
  var itemValue = data[valueFieldName];
  var itemLabel = data[labelFieldName]; // 如果有data不为对象，另外两个都是空的话，就替换下

  if (_typeof(data) !== 'object' && (data || typeof data === 'number')) {
    if (!itemValue) {
      itemValue = data;
    }

    if (!itemLabel) {
      itemLabel = data;
    }
  } else if (!itemLabel && _typeof(data) === 'object' && data && (data.$$typeof || data.props)) {
    itemLabel = data;
  } else if (!itemLabel && (0, _spauiUtils.isArray)(data)) {
    itemLabel = data;
  } // 如果最后变成了一个object，为了避免可能是jsx，直接用原数据进行覆盖


  if (_typeof(data) === 'object' && _typeof(originData) === 'object' && _typeof(itemValue) === 'object' && typeof originData[valueFieldName] !== 'undefined') {
    itemValue = originData[valueFieldName];
  }

  return {
    itemValue: String(itemValue),
    itemLabel: itemLabel
  };
}; // 获取选项节点的内容


var getTheContentOfTheItemComponent = function getTheContentOfTheItemComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var value = props.value,
      data = props.data,
      multiple = props.multiple,
      propsDisabled = props.disabled,
      html = props.html,
      labelDomClassName = props.labelDomClassName,
      max = props.max,
      onClick = props.onClick,
      _props$classNameMap = props.classNameMap,
      classNameMap = _props$classNameMap === void 0 ? {} : _props$classNameMap;

  var _getItemInfo = getItemInfo(data, props),
      itemValue = _getItemInfo.itemValue,
      itemLabel = _getItemInfo.itemLabel;

  var checkedValue = _typeof(value) !== 'object' ? String(value) : value;
  var disabled = propsDisabled;
  var checked = false;

  if (multiple) {
    checked = (0, _spauiUtils.indexOf)((0, _spauiUtils.isArray)(checkedValue) ? checkedValue : [], itemValue) > -1;
  } else {
    checked = checkedValue === itemValue;
  } // 指定的数量，如果选中的数据到达了数量，对于没有选中的就都要disabled了


  if (typeof max !== 'undefined' && max > 0 && multiple) {
    if (max <= value.length && !checked && !data.unmax) {
      disabled = true;
    }
  }

  return {
    label: itemLabel,
    labelDom: getLabelDom(itemLabel, html, labelDomClassName),
    className: (0, _classnames["default"])(classNameMap.SelectItemClassName, {
      'checked selected': checked,
      disabled: disabled
    }),
    onClick: (0, _spauiUtils.isFunction)(onClick) && !disabled ? onClick : function () {},
    checked: checked
  };
}; // 获取选项内容标记


exports.getTheContentOfTheItemComponent = getTheContentOfTheItemComponent;

var getTheSignOfTheItemComponent = function getTheSignOfTheItemComponent() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var props = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var _props$labelFieldName2 = props.labelFieldName,
      labelFieldName = _props$labelFieldName2 === void 0 ? '' : _props$labelFieldName2;
  var haveLabel = false;
  var haveDescription = false;
  var haveImg = false;
  var haveTime = false;
  data.map(function () {
    var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    if (item[labelFieldName]) {
      haveLabel = true;
    }

    if (item.description) {
      haveDescription = true;
    }

    if (item.img) {
      haveImg = true;
    }

    if (item.time) {
      haveTime = true;
    }

    return item;
  });
  return {
    haveLabel: haveLabel,
    haveDescription: haveDescription,
    haveImg: haveImg,
    haveTime: haveTime
  };
}; // 搜索筛选


exports.getTheSignOfTheItemComponent = getTheSignOfTheItemComponent;

var filter = function filter() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var filterSearchValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var searchFields = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  var propsConvertPYs = arguments.length > 3 ? arguments[3] : undefined;
  var search = arguments.length > 4 ? arguments[4] : undefined;
  var starMatch = arguments.length > 5 ? arguments[5] : undefined;
  var reg = /^[a-zA-Z\s]*$/gi; // 只有纯拼音或者空才能够搜索

  var starReg = /\*/gi; // 匹配输入的 '*' 字符

  var searchValue = (0, _spauiUtils.trim)(filterSearchValue) || '';
  var searchLabel = '';
  var searchLabelPinyin = '';
  var searchValuePinyin = '';
  var result = false;
  var convertPYs = propsConvertPYs || null;
  var starMatchReg = null; // 如果通过全局引入也加上

  if (!convertPYs && typeof SPAPinYin !== 'undefined' && typeof SPAPinYin.convertPYs !== 'undefined') {
    convertPYs = SPAPinYin.convertPYs;
  } // 需要搜索的节点


  searchFields.map(function (item) {
    searchLabel += data[item] || '';
    return item;
  }); // 如果没有搜索文案，默认就用整个data的

  if (!searchLabel && _typeof(data) !== 'object') {
    searchLabel = data;
  }

  if (convertPYs && reg.test(searchValue)) {
    searchLabelPinyin = convertPYs("".concat(searchLabel))[0];
    searchValuePinyin = convertPYs("".concat(searchValue))[0]; // 可以转化为大小写的话拼音都用小写匹配

    if (searchLabelPinyin && searchLabelPinyin.toLocaleLowerCase) {
      searchLabelPinyin = searchLabelPinyin.toLocaleLowerCase();
    }

    if (searchValuePinyin && searchValuePinyin.toLocaleLowerCase) {
      searchValuePinyin = searchValuePinyin.toLocaleLowerCase();
    }
  }

  if (starMatch && starReg.test(searchValue)) {
    // 对搜索输入框中的值进行转义（‘*’ 符号不进行转义）
    var escapedSearchValue = searchValue.replace(/[|\\{}()[\]^$+?.]/g, '\\$&'); // 将 '*' 符号替换成匹配一个字符或汉字的正则表达式

    starMatchReg = new RegExp(escapedSearchValue.replace(starReg, charMatchRegexString), 'gi');
  } // 如果指明了search为false，那就不筛选了，都是true


  if (typeof search !== 'undefined' && !search || !searchValue || "".concat(searchLabel).indexOf(searchValue) > -1 || searchLabelPinyin && searchLabelPinyin.indexOf(searchValuePinyin) > -1 || starMatchReg && starMatchReg.test("".concat(searchLabel))) {
    result = true;
  }

  return result;
}; // 获取经过html渲染后的text


exports.filter = filter;

var getInnerText = function getInnerText() {
  var string = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var div = document.createElement('div');
  div.innerHTML = string;
  return div.innerText;
}; // 搜索匹配强调


var emphasize = function emphasize(data, _ref) {
  var searchValue = _ref.searchValue,
      html = _ref.html,
      _ref$labelFieldName = _ref.labelFieldName,
      labelFieldName = _ref$labelFieldName === void 0 ? '' : _ref$labelFieldName,
      _ref$valueFieldName = _ref.valueFieldName,
      valueFieldName = _ref$valueFieldName === void 0 ? '' : _ref$valueFieldName,
      starMatch = _ref.starMatch;
  // 默认的搜索值的正则表达式（需要对特殊的正则符号进行转义）
  var reg = new RegExp(searchValue.replace(/[|\\{}()[\]^$+?.*]/g, '\\$&'), 'gi'); // '*' 符号匹配一个字符或汉字的正则表达式 （对 '*' 以外的其它特殊字符进行转义）

  var starMatchReg = new RegExp(searchValue.replace(/[|\\{}()[\]^$+?.]/g, '\\$&').replace(/\*/gi, charMatchRegexString), 'gi');
  var result = data;
  var label = _typeof(data) === 'object' ? data[labelFieldName] || '' : data;
  var labelList = [];
  var jsxLabelList = []; // 只有字符串，number才能够处理，jsx的没法处理

  if ((typeof label === 'string' || typeof label === 'number') && searchValue) {
    label = label.toString(); // label 转换为 html

    if (html) {
      if (getInnerText(label).indexOf(searchValue) > -1) {
        label = label.replace(reg, function (replaceValue) {
          return "<em class=\"emphasize\">".concat(replaceValue, "</em>");
        });

        if (_typeof(data) === 'object') {
          result = _objectSpread({}, data);
          result[labelFieldName] = label;
        } else {
          result = {};
          result[valueFieldName] = data;
          result[labelFieldName] = label;
        }
      }
    } else {
      if (starMatch && starMatchReg.test(label)) {
        // 星号匹配字符成功
        var matchResult = label.match(starMatchReg) || [];
        labelList = label.split(starMatchReg);
        labelList.map(function (item, index) {
          if (typeof item !== 'undefined') {
            jsxLabelList.push(item); // 最后一个不加

            if (index < labelList.length - 1) {
              var matchSearchValue = matchResult.shift();
              jsxLabelList.push(_react["default"].createElement("em", {
                key: "".concat(index, "_").concat(matchSearchValue),
                className: "emphasize"
              }, matchSearchValue));
            }
          }
        });
        label = jsxLabelList;

        if (_typeof(data) === 'object') {
          result = _objectSpread({}, data);
          result[labelFieldName] = label;
        } else {
          result = {};
          result[valueFieldName] = data;
          result[labelFieldName] = label;
        }
      } else if (label.indexOf(searchValue) > -1) {
        labelList = label.split(searchValue);
        labelList.map(function (item, index) {
          if (typeof item !== 'undefined') {
            jsxLabelList.push(item); // 最后一个不加

            if (index < labelList.length - 1) {
              jsxLabelList.push(_react["default"].createElement("em", {
                key: "".concat(index, "_").concat(searchValue),
                className: "emphasize"
              }, searchValue));
            }
          }

          return item;
        });
        label = jsxLabelList;

        if (_typeof(data) === 'object') {
          result = _objectSpread({}, data);
          result[labelFieldName] = label;
        } else {
          result = {};
          result[valueFieldName] = data;
          result[labelFieldName] = label;
        }
      }
    }
  }

  return result;
}; // 是否有jQuery


exports.emphasize = emphasize;
var haveJQuery = (typeof window === "undefined" ? "undefined" : _typeof(window)) === 'object' && typeof window.jQuery === 'function'; // 获取ValueArea的一些内容

exports.haveJQuery = haveJQuery;

var getTheContentOfTheValueAreaComponent = function getTheContentOfTheValueAreaComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var clear = props.clear,
      multiple = props.multiple,
      value = props.value,
      _onClear = props.onClear,
      needArrowIcon = props.needArrowIcon,
      onOpen = props.onOpen,
      onClickValueArea = props.onClickValueArea,
      onChange = props.onChange,
      disabled = props.disabled,
      ArrowComponent = props.ArrowComponent;
  var showClearIcon = clear && !disabled && (multiple ? value && value.length > 0 : value || typeof value === 'number');
  var arrowDom = null;

  if (needArrowIcon) {
    arrowDom = _react["default"].createElement("div", {
      className: "selection-arrow"
    }, _react["default"].createElement(_spauiIcon["default"], {
      name: "dropdown-arrow-outlined"
    }));

    if (typeof ArrowComponent !== 'undefined') {
      if ((0, _spauiUtils.isReactNode)(ArrowComponent)) {
        arrowDom = ArrowComponent;
      } else {
        arrowDom = _react["default"].createElement(ArrowComponent, null);
      }
    }
  }

  return {
    onClear: function onClear(event) {
      event.stopPropagation();

      if ((0, _spauiUtils.isFunction)(_onClear)) {
        _onClear(event);
      } else if ((0, _spauiUtils.isFunction)(onChange)) {
        // 如果没传onClear就用onChange代替下
        onChange(event, multiple ? [] : '', true, false, false);
      }
    },
    onValueAreaClick: function onValueAreaClick(event) {
      // console.log('onValueAreaClick', event);
      if ((0, _spauiUtils.isFunction)(onOpen)) {
        onOpen(event);
      }

      if ((0, _spauiUtils.isFunction)(onClickValueArea)) {
        onClickValueArea(event);
      }
    },
    arrowDom: arrowDom,
    showClearIcon: showClearIcon
  };
}; // value是否等于data或者在data中


exports.getTheContentOfTheValueAreaComponent = getTheContentOfTheValueAreaComponent;

var isEqual = function isEqual(data, key, value) {
  return "".concat(data) === "".concat(value) || "".concat(data && data[key]) === "".concat(value);
}; // 根据搜索条件等获取展示等data


exports.isEqual = isEqual;

var getShowData = function getShowData() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var value = props.value,
      _props$data = props.data,
      data = _props$data === void 0 ? [] : _props$data,
      convertPYs = props.convertPYs,
      starMatch = props.starMatch,
      useSearchValue = props.useSearchValue,
      useSearchValueData = props.useSearchValueData,
      propsSearchValueData = props.searchValueData,
      search = props.search,
      searchValue = props.searchValue,
      propsSearchFields = props.searchFields,
      onSearchFilter = props.onSearchFilter,
      valueFieldName = props.valueFieldName,
      _props$labelFieldName3 = props.labelFieldName,
      labelFieldName = _props$labelFieldName3 === void 0 ? '' : _props$labelFieldName3,
      _props$closeFilter = props.closeFilter,
      closeFilter = _props$closeFilter === void 0 ? false : _props$closeFilter,
      _props$useValue = props.useValue,
      useValue = _props$useValue === void 0 ? false : _props$useValue;
  var searchFields = typeof propsSearchFields === 'undefined' ? [labelFieldName] : propsSearchFields;
  var searchValueData = [];
  var tempData = {};
  var showData = [];
  var valueData = []; // 搜索词数据

  if (useSearchValue || useSearchValueData) {
    tempData = {};
    tempData[labelFieldName] = searchValue;
    tempData[valueFieldName] = searchValue;
    searchValueData = propsSearchValueData || tempData; // searchValueData目前是object，只支持一个需要转成数组

    if (searchValueData && !(0, _spauiUtils.isArray)(searchValueData)) {
      searchValueData = [searchValueData];
    }
  } // 筛选下要展示的数据


  if (!(0, _spauiUtils.trim)(searchValue) || closeFilter) {
    data.map(function (item) {
      showData.push(item);
      return item;
    });
  } else if (!useSearchValueData) {
    data.map(function (item) {
      // 搜索排除
      if (onSearchFilter(item, searchValue, searchFields, convertPYs, search, starMatch)) {
        // 如果有数据的话，就不使用附带的搜索词
        searchValueData = [];
        showData.push(item);
      }

      return item;
    });
  } // 有搜索对应的数据都进行展示且放在最后面


  showData = showData.concat(searchValueData);

  if (useValue) {
    var showDataValue = [];
    showData.forEach(function (subShowData) {
      if (_typeof(subShowData) === 'object') {
        if (subShowData && typeof subShowData.value !== 'undefined') {
          showDataValue.push(subShowData.value);
        }
      } else {
        showDataValue.push(subShowData);
      }
    });

    if ((0, _spauiUtils.isArray)(value)) {
      value.forEach(function (subValue) {
        if (typeof subValue !== 'undefined' && !showDataValue.includes(subValue)) {
          valueData.push({
            label: subValue,
            value: subValue
          });
        }
      });
    } else {
      if (typeof value !== 'undefined' && !showDataValue.includes(value)) {
        valueData.push({
          label: value,
          value: value
        });
      }
    }
  } // 如果有value不在data里面作为选项的话也加上


  showData = showData.concat(valueData);
  return showData;
};

exports.getShowData = getShowData;

var isShowCheckAll = function isShowCheckAll() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var multiple = props.multiple,
      max = props.max,
      checkAll = props.checkAll,
      searchCheckAll = props.searchCheckAll,
      _props$stickCheckAll = props.stickCheckAll,
      stickCheckAll = _props$stickCheckAll === void 0 ? false : _props$stickCheckAll;

  if (stickCheckAll) {
    return multiple && (checkAll || searchCheckAll);
  }

  return multiple && (checkAll || searchCheckAll) && (typeof max === 'undefined' || max < 1);
};

exports.isShowCheckAll = isShowCheckAll;

var isShowReverseCheck = function isShowReverseCheck() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var multiple = props.multiple,
      max = props.max,
      reverseCheck = props.reverseCheck;
  return multiple && reverseCheck && (typeof max === 'undefined' || max < 1);
}; // 获取元素数据唯一的key


exports.isShowReverseCheck = isShowReverseCheck;

var getUniqueKey = function getUniqueKey() {
  var config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _config$list = config.list,
      list = _config$list === void 0 ? [] : _config$list,
      _config$data = config.data,
      data = _config$data === void 0 ? {} : _config$data,
      valueFieldName = config.valueFieldName,
      index = config.index,
      _config$useDatakey = config.useDatakey,
      useDatakey = _config$useDatakey === void 0 ? false : _config$useDatakey;

  var getValue = function getValue(itemData) {
    return typeof itemData[valueFieldName] !== 'undefined' ? itemData[valueFieldName] : itemData;
  };

  var result = '';
  var valueIsUnique = true;
  var valueList = [];
  var prepareKeyList = [];
  var prepareKeyMap = {};
  list.forEach(function (item, itemIndex) {
    if (typeof item !== 'undefined') {
      var value = getValue(item);
      var prepareKey = value;

      if (!useDatakey) {
        // 是否唯一key判断
        if ((0, _spauiUtils.indexOf)(valueList, value) < 0) {
          valueList.push(value);
        } else {
          valueIsUnique = false;
        }
      } else {
        valueIsUnique = false; // 如果useDataKey为true的时候，就优先用item.key

        prepareKey = item.key || value;
      } // 生成预准备key(优先用value来替换，如果有冲突，就加上后缀跟index)


      if ((0, _spauiUtils.indexOf)(prepareKeyList, prepareKey) < 0) {
        prepareKeyList.push(prepareKey);
      } else {
        prepareKey = "".concat(value, "__spaui_select_key_").concat(itemIndex);
        prepareKeyList.push(prepareKey);
      }

      prepareKeyMap["".concat(value, "_").concat(itemIndex)] = prepareKey;
    }
  }); // 如果值是唯一的，key就用value

  if (valueIsUnique) {
    result = getValue(data);
  } else {
    result = prepareKeyMap["".concat(getValue(data), "_").concat(index)] || getValue(data);
  }

  return result;
};

exports.getUniqueKey = getUniqueKey;

var getDataSet = function getDataSet() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var result = {};
  var key = '';

  if (_typeof(data) === 'object' && data) {
    for (key in data) {
      if (key && key.indexOf('data-') === 0) {
        result[key] = data[key];
      }
    }
  }

  return result;
};
/**
 * 获取展示区域的显示数据
 */


exports.getDataSet = getDataSet;

var getCurrentDataForValueHolder = function getCurrentDataForValueHolder() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var labelData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var value = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  var labelDataItem = null;
  var result = data[value];

  if (_typeof(result) === 'object' && result) {
    // 复制下
    result = _objectSpread({}, result);

    if ((0, _spauiUtils.isArray)(labelData)) {
      labelData.some(function (item) {
        if ((value || typeof value === 'number' || typeof value === 'boolean') && item.value === value) {
          labelDataItem = item;
        }

        return labelDataItem;
      });
    }

    if (labelDataItem) {
      // labelData有值的时候，用来覆盖下
      Object.keys(labelDataItem).forEach(function (fieldName) {
        if (!result[fieldName] && labelDataItem[fieldName]) {
          result[fieldName] = labelDataItem[fieldName];
        }
      });
    }
  }

  return result;
};

exports.getCurrentDataForValueHolder = getCurrentDataForValueHolder;
var _default = {
  getTheContentOfTheItemComponent: getTheContentOfTheItemComponent,
  getTheSignOfTheItemComponent: getTheSignOfTheItemComponent,
  filter: filter,
  emphasize: emphasize,
  haveJQuery: haveJQuery,
  getTheContentOfTheValueAreaComponent: getTheContentOfTheValueAreaComponent,
  isEqual: isEqual,
  getShowData: getShowData,
  isShowCheckAll: isShowCheckAll,
  getUniqueKey: getUniqueKey,
  getDataSet: getDataSet
};
exports["default"] = _default;