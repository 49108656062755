"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var RightPanelComponent = function RightPanelComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var panelClassName = props.panelClassName,
      headerComponent = props.headerComponent,
      titleComponent = props.titleComponent,
      data = props.data,
      EmptyComponent = props.EmptyComponent,
      ItemComponent = props.ItemComponent,
      InfoComponent = props.InfoComponent,
      ImgComponent = props.ImgComponent,
      disabled = props.disabled,
      searchValue = props.searchValue,
      convertPYs = props.convertPYs,
      onItemChange = props.onItemChange,
      ellipsisCount = props.ellipsisCount,
      addonContent = props.addonContent,
      selectionEmptyLabel = props.selectionEmptyLabel;
  return _react["default"].createElement("div", {
    className: panelClassName
  }, headerComponent, titleComponent, data.length > 0 ? _react["default"].createElement("div", {
    className: "user-picker-body spaui-userpicker-body selection-list"
  }, _react["default"].createElement("ul", {
    className: "feeds spaui-userpicker-feeds"
  }, data.map(function () {
    var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var index = arguments.length > 1 ? arguments[1] : undefined;
    return _react["default"].createElement(ItemComponent, _extends({}, props, {
      type: "selection-list",
      InfoComponent: InfoComponent,
      ImgComponent: ImgComponent,
      disabled: item.disabled || disabled,
      key: index,
      data: item,
      searchValue: searchValue,
      convertPYs: convertPYs,
      highlightable: false,
      status: "delete",
      onClick: function onClick(event) {
        onItemChange(event, 'delete', item);
      },
      ellipsisCount: ellipsisCount
    }));
  })), addonContent) : _react["default"].createElement(EmptyComponent, _extends({}, props, {
    type: "selection-list",
    label: selectionEmptyLabel
  })));
};

var _default = RightPanelComponent;
exports["default"] = _default;