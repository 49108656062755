"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultProps = exports.propTypes = exports.config = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件参数配置
 */

/**
 * Dropdown 接口参数
 * @alias DropdownProps
 * @description 下拉功能组件。
 * @property {titleline} 基础属性
 * @property {string} [trigger='click'] 打开的方式，click or hover
 * @property {bool} [open=false] 是否打开
 * @property {func} [onOpen] 打开回调 onOpen = (event, open)
 * @property {number} [showDelayTime=0] trigger=hover显示延迟时间(单位毫秒)
 * @property {number} [hideDelayTime=100] trigger=hover消失延迟时间（单位毫秒）
 * @property {bool} [documentClickAutoHide=true] 点击document自动关闭
 * @property {string} [placement=''] 下拉面板方向*placement可选值：bottom, top, left, right, auto*
 * @property {string} [defaultPlacement='bottom'] 默认方向*defaultPlacement可选值：bottom, top, left, right*
 * @property {object} [offsetData={}] 浮层偏移位置 offsetData = {left, top}
 * @property {func} [setLocation] 设置位置*setLocation=(toggleNode, contentNode, targetNode, props) => return {top, left}*
 * @property {string} [alignment=''] 对齐*可选值: left, center, right, top, bottom*
 * @property {any} [dropdownContent] 下拉面板内容
 * @property {bool} [disabled=false] 是否禁用
 * @property {object|string} [target] 往对应位置添加展示的结构（原生浏览器节点结构）可以是原生结构或者target='parentNode'指明放在OverlayTrigger的父元素上
 * @property {object} [classNameMap={}] 组件内对应结构的默认className(可以针对对应位置进行替换,具体参数见案例)
 * @property {string} [motionName=''] 动画名称
 *
 * @see
 *
 * onOpen typings:
 *     (event: any, isOpen: any) => any
 *
 * ====
 *
 * setLocation typings:
 *     (toggle: any, content: any, target: any, props: any) => any
 *
 * @example
 * classNameMap配置可见于：https://git.woa.com/ads/spaui-basic_ui_components/tree/master/src/spaui-dropdown/src/js/config/classname_map.js
 */
var config = {
  trigger: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'click'
  },
  open: {
    type: _spauiUtils.PropTypes.bool
  },
  onOpen: {
    type: _spauiUtils.PropTypes.func
  },
  showDelayTime: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.number, _spauiUtils.PropTypes.string]),
    defaultValue: 0
  },
  hideDelayTime: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.number, _spauiUtils.PropTypes.string]),
    defaultValue: 100
  },
  documentClickAutoHide: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  placement: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  defaultPlacement: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'bottom'
  },
  offsetData: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  setLocation: {
    type: _spauiUtils.PropTypes.func
  },
  alignment: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  dropdownContent: {
    type: _spauiUtils.PropTypes.any
  },
  disabled: {
    type: _spauiUtils.PropTypes.bool
  },
  target: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.object, _spauiUtils.PropTypes.string])
  },
  classNameMap: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  motionName: {
    type: _spauiUtils.PropTypes.string
  }
}; // 转化成PureComponent组件的配置

exports.config = config;
var configData = (0, _spauiUtils.formatProps)(config); // 导出

var propTypes = configData.propTypes;
exports.propTypes = propTypes;
var defaultProps = configData.defaultProps;
exports.defaultProps = defaultProps;
var _default = {
  config: config,
  propTypes: propTypes,
  defaultProps: defaultProps
};
exports["default"] = _default;