/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2024, a Tencent company. All rights reserved.
 */
export default {
    componentName: 'spaui',
    componentVersion: '3.0.352-beta.0',
    name: 'spaui',
    version: '3.0.352-beta.0',
};
