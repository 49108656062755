"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var DeleteComponent = function DeleteComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var item = props.item,
      _props$itemDeleteProp = props.itemDeleteProps,
      itemDeleteProps = _props$itemDeleteProp === void 0 ? {} : _props$itemDeleteProp,
      onDelete = props.onDelete;
  return _react["default"].createElement("i", _extends({}, itemDeleteProps, {
    className: (0, _classnames["default"])('icon del', itemDeleteProps.className || ''),
    onClick: function onClick(event) {
      return onDelete(event, item);
    }
  }), _react["default"].createElement("i", null));
};

var _default = DeleteComponent;
exports["default"] = _default;