"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

// 兼容props，因为有些名称被废弃了但是要兼容
var _default = function _default() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var activeBsStyle = props.activeBsStyle,
      activeDisplayType = props.activeDisplayType,
      data = props.data,
      _props$undisabledInFr = props.undisabledInFront,
      undisabledInFront = _props$undisabledInFr === void 0 ? false : _props$undisabledInFr;
  var disabledList = [];
  var undisabledList = [];
  var newData;

  if ((0, _spauiUtils.isArray)(data) && data.length > 1 && undisabledInFront) {
    newData = [];
    data.forEach(function (item) {
      if (item) {
        if (_typeof(item) === 'object' && item && typeof item.disabled !== 'undefined' && item.disabled) {
          disabledList.push(item);
        } else {
          undisabledList.push(item);
        }
      }
    });
    newData = undisabledList.concat(disabledList);
  } else {
    newData = data;
  }

  return _objectSpread({}, props, {
    data: newData,
    activeDisplayType: typeof activeBsStyle !== 'undefined' ? activeBsStyle : activeDisplayType
  });
};

exports["default"] = _default;