"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.CascadeTree = exports.Tree = void 0;

var _tree = _interopRequireDefault(require("./tree"));

var _cascade_tree = _interopRequireDefault(require("./cascade_tree"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */
// 兼容旧模式，导出一个唯一的Tree
var defaultData = _tree["default"];
var Tree = _tree["default"];
exports.Tree = Tree;
var CascadeTree = _cascade_tree["default"]; // 存放到default数据中

exports.CascadeTree = CascadeTree;
defaultData.Tree = Tree;
defaultData.CascadeTree = CascadeTree; // 调试信息
// if (process.env.NODE_ENV !== 'production') {
//     const {whyDidYouUpdate} = require('why-did-you-update');
//     whyDidYouUpdate(React);
// }

var _default = defaultData;
exports["default"] = _default;