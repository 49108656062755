"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultProps = exports.propTypes = exports.config = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

var _separator_component = _interopRequireDefault(require("./component/separator_component"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件参数配置
 */

/**
 * RadioGroup 接口参数
 * @alias RadioGroupProps
 * @description 单选框组。
 * @property {titleline} 默认支持参数
 * @property {blankline} className;style;id;data-*;accessKey;dir;draggable;tabIndex;title;htmlRef;
 * @property {titleline} 基本属性
 * @property {string|number|bool} value='' 值
 * @property {array} data=[]] 选项数据
 * @property {func} onChange 切换状态回调函数<br />onChange = (event, value)
 * @property {string} [name=''] 字段名
 * @property {bool} [disabled=false] 禁用
 * @property {bool} [vertical=false] 是否垂直
 * @property {string} [theme='default'] 按钮主题*可选：default, check*
 * @property {separator} [showSeparator=false] 每个选项之间是否要显示分割符*仅在水平方向显示时才有效*
 * @property {element} [SeparatorComponent] 分隔符组件
 * @property {object} [RadioProps={}] 应用于Radio的props属性
 * @property {string} [tipFieldName] 指定提示字段名
 *
 * @see
 *
 * onChange typings:
 *     (event: any, value: any) => any
 */
var config = {
  useNewUI: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  value: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number, _spauiUtils.PropTypes.bool]).isRequired,
    defaultValue: '',
    visualData: {
      type: 'input',
      description: '选中值'
    }
  },
  data: {
    type: _spauiUtils.PropTypes.array,
    defaultValue: [],
    visualData: {
      type: 'array',
      description: '选项数据'
    }
  },
  onChange: {
    type: _spauiUtils.PropTypes.func
  },
  name: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: '',
    visualData: {
      type: 'input',
      description: '字段名'
    }
  },
  disabled: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false,
    visualData: {
      type: 'switch',
      description: '是否禁用'
    }
  },
  vertical: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false,
    visualData: {
      type: 'switch',
      description: '是否垂直'
    }
  },
  RadioProps: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {},
    visualData: {
      type: 'object',
      description: '应用于Radio的props属性'
    }
  },
  className: {
    type: _spauiUtils.PropTypes.string,
    visualData: {
      type: 'input'
    }
  },
  style: {
    type: _spauiUtils.PropTypes.object,
    visualData: {
      type: 'object'
    }
  },
  id: {
    type: _spauiUtils.PropTypes.string,
    visualData: {
      type: 'input'
    }
  },
  theme: {
    type: _spauiUtils.PropTypes.oneOf(['default', 'check']),
    defaultValue: 'default'
  },
  showSeparator: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  SeparatorComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _separator_component["default"]
  },
  tipFieldName: {
    type: _spauiUtils.PropTypes.string
  }
}; // 转化成PureComponent组件的配置

exports.config = config;
var configData = (0, _spauiUtils.formatProps)(config); // 导出

var propTypes = configData.propTypes;
exports.propTypes = propTypes;
var defaultProps = configData.defaultProps;
exports.defaultProps = defaultProps;
var _default = {
  config: config,
  propTypes: propTypes,
  defaultProps: defaultProps
};
exports["default"] = _default;