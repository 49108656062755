"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _classnames = _interopRequireDefault(require("classnames"));

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var LinkComponent = function LinkComponent() {
  var _classNames;

  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var children = props.children,
      name = props.name,
      onClick = props.onClick,
      style = props.style,
      textSize = props.textSize,
      textType = props.textType,
      active = props.active,
      buttonClassName = props.buttonClassName,
      buttonActiveClassName = props.buttonActiveClassName,
      _props$disabled = props.disabled,
      disabled = _props$disabled === void 0 ? false : _props$disabled,
      href = props.href,
      target = props.target;
  var dataMap = {};
  var key = '';

  for (key in props) {
    if (key && typeof key === 'string' && key.indexOf('data-') > -1) {
      dataMap[key] = props[key];
    }
  }

  var otherProps = {};

  if (!disabled) {
    if (href) {
      otherProps.href = href;
      otherProps.target = target;
    }
  }

  return _react["default"].createElement("a", _extends({}, dataMap, otherProps, {
    style: style,
    onClick: onClick,
    "data-name": name,
    className: (0, _classnames["default"])((_classNames = {}, _defineProperty(_classNames, "spaui-text-".concat(textSize), textSize), _defineProperty(_classNames, "spaui-text-".concat(textType), textType), _defineProperty(_classNames, buttonClassName, buttonClassName), _defineProperty(_classNames, buttonActiveClassName, active && buttonActiveClassName), _classNames))
  }), children);
};

var _default = LinkComponent;
exports["default"] = _default;