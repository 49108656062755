"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var result = {};
var index = 0; // account-outlined.svg

var Accountoutlined = function Accountoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M5.973 8.854a3.5 3.5 0 114.041.01 5.226 5.226 0 013.267 3.731 7 7 0 10-10.627-.077c.412-1.725 1.668-3.06 3.319-3.664zm6.43 4.588C12.207 11.161 10.35 9.5 8 9.5c-2.354 0-4.28 1.601-4.475 3.883A6.972 6.972 0 008 15a6.97 6.97 0 004.403-1.558zM8 8.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM8 16A8 8 0 118 0a8 8 0 010 16z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['account-outlined'] = Accountoutlined; // account.svg

var Account = function Account() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("path", {
    d: "M5.973 8.854a3.5 3.5 0 114.041.01 5.226 5.226 0 013.267 3.731 7 7 0 10-10.627-.077c.412-1.725 1.668-3.06 3.319-3.664zm6.43 4.588C12.207 11.161 10.35 9.5 8 9.5c-2.354 0-4.28 1.601-4.475 3.883A6.972 6.972 0 008 15a6.97 6.97 0 004.403-1.558zM8 8.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM8 16A8 8 0 118 0a8 8 0 010 16z"
  }));
};

result.account = Account; // ai-drawing-outlined.svg

var Aidrawingoutlined = function Aidrawingoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M10.555 1a1 1 0 01.698.284l2.445 2.383a1 1 0 01.302.716V14a1 1 0 01-1 1H3a1 1 0 01-1-1V2a1 1 0 011-1h7.555zm0 1H3v12h10V4.383L10.555 2zM7.654 5.574L10.111 12H8.986l-.585-1.611H5.71L5.125 12H4l2.457-6.426h1.197zm4.14 0V12h-1.053V5.574h1.053zM7.078 6.708h-.036L6.025 9.525h2.061L7.078 6.708z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['ai-drawing-outlined'] = Aidrawingoutlined; // allocate-outlined.svg

var Allocateoutlined = function Allocateoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M8.5 5v2H14a1 1 0 011 1v1.5a.5.5 0 11-1 0V8H8.5v1.5a.5.5 0 01-1 0V8H2v1.5a.5.5 0 01-1 0V8a1 1 0 011-1h5.5V5H5a1 1 0 01-1-1V1a1 1 0 011-1h6a1 1 0 011 1v3a1 1 0 01-1 1H8.5zM5 1v3h6V1H5zm9 14a2 2 0 110-4 2 2 0 010 4zm0-1a1 1 0 100-2 1 1 0 000 2zm-6 1a2 2 0 110-4 2 2 0 010 4zm0-1a1 1 0 100-2 1 1 0 000 2zm-6 1a2 2 0 110-4 2 2 0 010 4zm0-1a1 1 0 100-2 1 1 0 000 2z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['allocate-outlined'] = Allocateoutlined; // arrow-left-filled.svg

var Arrowleftfilled = function Arrowleftfilled() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, _react["default"].createElement("circle", {
    fill: "currentColor",
    cx: "8",
    cy: "8",
    r: "8"
  }), _react["default"].createElement("path", {
    d: "M6 4L3 8l3 4h2.5L6.25 9h6.78V7H6.25L8.5 4H6z",
    fill: "#FFF",
    fillRule: "nonzero"
  })));
};

result['arrow-left-filled'] = Arrowleftfilled; // arrow-left.svg

var Arrowleft = function Arrowleft() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return props.solid || false ? _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("g", {
    fill: "none"
  }, _react["default"].createElement("circle", {
    fill: "currentColor",
    cx: "8",
    cy: "8",
    r: "8"
  }), _react["default"].createElement("path", {
    d: "M6.25 9h6.78V7H6.25L8.5 4H6L3 8l3 4h2.5L6.25 9z",
    fill: "#FFF"
  }))) : _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("g", {
    fill: "none"
  }, _react["default"].createElement("circle", {
    fill: "#000",
    cx: "8",
    cy: "8",
    r: "8"
  }), _react["default"].createElement("path", {
    d: "M6.25 9h6.78V7H6.25L8.5 4H6L3 8l3 4h2.5L6.25 9z",
    fill: "#FFF"
  })));
};

result['arrow-left'] = Arrowleft; // arrow-right-filled.svg

var Arrowrightfilled = function Arrowrightfilled() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, _react["default"].createElement("circle", {
    fill: "currentColor",
    cx: "8",
    cy: "8",
    r: "8"
  }), _react["default"].createElement("path", {
    d: "M10.03 4l3 4-3 4h-2.5l2.25-3H3V7h6.78L7.53 4h2.5z",
    fill: "#FFF",
    fillRule: "nonzero"
  })));
};

result['arrow-right-filled'] = Arrowrightfilled; // arrow-right.svg

var Arrowright = function Arrowright() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return props.solid || false ? _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("g", {
    fill: "none"
  }, _react["default"].createElement("circle", {
    fill: "currentColor",
    cx: "8",
    cy: "8",
    r: "8"
  }), _react["default"].createElement("path", {
    d: "M9.78 9H3V7h6.78L7.53 4h2.5l3 4-3 4h-2.5l2.25-3z",
    fill: "#FFF"
  }))) : _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("g", {
    fill: "none"
  }, _react["default"].createElement("circle", {
    fill: "#000",
    cx: "8",
    cy: "8",
    r: "8"
  }), _react["default"].createElement("path", {
    d: "M9.78 9H3V7h6.78L7.53 4h2.5l3 4-3 4h-2.5l2.25-3z",
    fill: "#FFF"
  })));
};

result['arrow-right'] = Arrowright; // back-outlined.svg

var Backoutlined = function Backoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    fill: "currentColor",
    d: "M6.732 7.876l5.06 5.572-1.48 1.345L4.03 7.874 10.314.971l1.479 1.347z",
    fillRule: "evenodd"
  }));
};

result['back-outlined'] = Backoutlined; // badge-colored.svg

var Badgecolored = function Badgecolored() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var id = "spaui-icon-badge-colored-".concat(index += 1);
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg",
    xmlnsXlink: "http://www.w3.org/1999/xlink"
  }, props), _react["default"].createElement("defs", null, _react["default"].createElement("linearGradient", {
    x1: "79.945%",
    y1: "31.4%",
    x2: "16.245%",
    y2: "67.014%",
    id: "c"
  }, _react["default"].createElement("stop", {
    stopColor: "#FFF",
    offset: "0%"
  }), _react["default"].createElement("stop", {
    stopColor: "#FFF",
    stopOpacity: ".068",
    offset: "100%"
  })), _react["default"].createElement("circle", {
    id: id,
    cx: "6",
    cy: "6",
    r: "6"
  })), _react["default"].createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, _react["default"].createElement("path", {
    fill: "#DB5037",
    d: "M4.518 8.835l3.464 2-2.5 4.33-1.219-1.858-2.245-.142zM11.464 9L8 11l2.5 4.33 1.219-1.858 2.245-.142z"
  }), _react["default"].createElement("g", {
    transform: "translate(2)"
  }, _react["default"].createElement("mask", {
    id: "".concat(id, "-b"),
    fill: "#fff"
  }, _react["default"].createElement("use", {
    xlinkHref: "#".concat(id)
  })), _react["default"].createElement("circle", {
    stroke: "#F7AD18",
    cx: "6",
    cy: "6",
    r: "5.5"
  }), _react["default"].createElement("path", {
    fill: "#FFF",
    mask: "url(#".concat(id, "-b)"),
    d: "M6 8L3.649 9.236l.449-2.618-1.902-1.854 2.628-.382L6 2l1.176 2.382 2.628.382-1.902 1.854.45 2.618z"
  }), _react["default"].createElement("path", {
    fill: "url(#c)",
    opacity: ".6",
    mask: "url(#".concat(id, "-b)"),
    d: "M1.683 9.395L-5 7.105l1.811-9.73L11.613-4l3.366 2.484L13.05.294z"
  }))));
};

result['badge-colored'] = Badgecolored; // batch-processing-outlined.svg

var Batchprocessingoutlined = function Batchprocessingoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M14 4H4v10h10V4zm-2-1V2H2v10h1V4a1 1 0 011-1h8zm2 0a1 1 0 011 1v10a1 1 0 01-1 1H4a1 1 0 01-1-1v-1H2a1 1 0 01-1-1V2a1 1 0 011-1h10a1 1 0 011 1v1h1zm-5.707 7.121l3.182-3.182a.5.5 0 01.707.707l-3.536 3.536a.5.5 0 01-.707 0L5.818 9.061a.5.5 0 01.707-.707l1.768 1.767z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['batch-processing-outlined'] = Batchprocessingoutlined; // bronze-colored.svg

var Bronzecolored = function Bronzecolored() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("g", {
    fill: "none"
  }, _react["default"].createElement("path", {
    fill: "#FDAC5D",
    d: "M11.96 5.616L8 2 4.04 5.616 0 3.62 2.064 14h11.872L16 3.619z"
  }), _react["default"].createElement("path", {
    fill: "#F18731",
    d: "M11.964 5.616L8.008 2 8 2.007V14h5.938L16 3.619z"
  })));
};

result['bronze-colored'] = Bronzecolored; // chart-outlined.svg

var Chartoutlined = function Chartoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M1.5 14h13a.5.5 0 110 1h-13a.5.5 0 110-1zM2 5v7h2V5H2zm0-1h2a1 1 0 011 1v7a1 1 0 01-1 1H2a1 1 0 01-1-1V5a1 1 0 011-1zm5-2v10h2V2H7zm0-1h2a1 1 0 011 1v10a1 1 0 01-1 1H7a1 1 0 01-1-1V2a1 1 0 011-1zm5 6v5h2V7h-2zm0-1h2a1 1 0 011 1v5a1 1 0 01-1 1h-2a1 1 0 01-1-1V7a1 1 0 011-1z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['chart-outlined'] = Chartoutlined; // chart.svg

var Chart = function Chart() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("path", {
    d: "M1.5 14h13a.5.5 0 110 1h-13a.5.5 0 110-1zM2 5v7h2V5H2zm0-1h2a1 1 0 011 1v7a1 1 0 01-1 1H2a1 1 0 01-1-1V5a1 1 0 011-1zm5-2v10h2V2H7zm0-1h2a1 1 0 011 1v10a1 1 0 01-1 1H7a1 1 0 01-1-1V2a1 1 0 011-1zm5 6v5h2V7h-2zm0-1h2a1 1 0 011 1v5a1 1 0 01-1 1h-2a1 1 0 01-1-1V7a1 1 0 011-1z"
  }));
};

result.chart = Chart; // check-bold-outlined.svg

var Checkboldoutlined = function Checkboldoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M2 7.778l1.414-1.414L6.95 9.899l6.365-6.363 1.414 1.414-7.778 7.778L2 7.778z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['check-bold-outlined'] = Checkboldoutlined; // check-circle.svg

var Checkcircle = function Checkcircle() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("path", {
    d: "M8 15A7 7 0 108 1a7 7 0 000 14zm0 1A8 8 0 118 0a8 8 0 010 16zm-.879-6.065l3.89-3.889a.5.5 0 01.706.707l-4.242 4.243a.5.5 0 01-.732-.026.507.507 0 01-.026-.025L3.889 8.117a.5.5 0 01.707-.707L7.12 9.935z"
  }));
};

result['check-circle'] = Checkcircle; // check-filled.svg

var Checkfilled = function Checkfilled() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, _react["default"].createElement("circle", {
    fill: "currentColor",
    cx: "8",
    cy: "8",
    r: "8"
  }), _react["default"].createElement("path", {
    d: "M7.243 12.33L3 8.088l1.414-1.415 2.829 2.829 4.95-4.95 1.414 1.414-6.364 6.364z",
    fill: "#FFF",
    fillRule: "nonzero"
  })));
};

result['check-filled'] = Checkfilled; // check-outlined.svg

var Checkoutlined = function Checkoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M8 15A7 7 0 108 1a7 7 0 000 14zm0 1A8 8 0 118 0a8 8 0 010 16zm-.879-6.065l3.89-3.889a.5.5 0 01.706.707l-4.242 4.243a.5.5 0 01-.732-.026.507.507 0 01-.026-.025L3.889 8.117a.5.5 0 01.707-.707L7.12 9.935z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['check-outlined'] = Checkoutlined; // check-square-filled.svg

var Checksquarefilled = function Checksquarefilled() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, _react["default"].createElement("rect", {
    fill: "currentColor",
    x: "1",
    y: "1",
    width: "14",
    height: "14",
    rx: "1"
  }), _react["default"].createElement("path", {
    d: "M12.596 5.995l-4.243 4.242-1.414 1.415-3.535-3.536 1.414-1.414 2.12 2.12 4.244-4.241 1.414 1.414z",
    fill: "#FFF",
    fillRule: "nonzero"
  })));
};

result['check-square-filled'] = Checksquarefilled; // check-square.svg

var Checksquare = function Checksquare() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return props.solid || false ? _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("g", {
    fill: "none"
  }, _react["default"].createElement("rect", {
    fill: "currentColor",
    x: "1",
    y: "1",
    width: "14",
    height: "14",
    rx: "1"
  }), _react["default"].createElement("path", {
    d: "M6.94 8.824l4.242-4.243 1.414 1.414-4.242 4.243-1.415 1.414-3.535-3.536 1.414-1.414 2.121 2.122z",
    fill: "#FFF"
  }))) : _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("g", {
    fill: "none"
  }, _react["default"].createElement("rect", {
    fill: "#000",
    x: "1",
    y: "1",
    width: "14",
    height: "14",
    rx: "1"
  }), _react["default"].createElement("path", {
    d: "M6.94 8.824l4.242-4.243 1.414 1.414-4.242 4.243-1.415 1.414-3.535-3.536 1.414-1.414 2.121 2.122z",
    fill: "#FFF"
  })));
};

result['check-square'] = Checksquare; // check.svg

var Check = function Check() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("g", {
    fill: "none"
  }, _react["default"].createElement("circle", {
    fill: "#000",
    cx: "8",
    cy: "8",
    r: "8"
  }), _react["default"].createElement("path", {
    d: "M7.243 9.502l4.95-4.95 1.414 1.414-4.95 4.95-1.414 1.414L3 8.088l1.414-1.415 2.829 2.829z",
    fill: "#FFF"
  })));
};

result.check = Check; // clear-outlined.svg

var Clearoutlined = function Clearoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, _react["default"].createElement("circle", {
    fill: "currentColor",
    cx: "8",
    cy: "8",
    r: "8"
  }), _react["default"].createElement("path", {
    fill: "#FFF",
    fillRule: "nonzero",
    d: "M11 4.293l.707.707-3 3 3 3-.707.707-3-3-3 3L4.293 11l3-3-3-3L5 4.293l3 3z"
  })));
};

result['clear-outlined'] = Clearoutlined; // close-bold-outlined.svg

var Closeboldoutlined = function Closeboldoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "24",
    height: "24",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, _react["default"].createElement("path", {
    d: "M0 0h20v20H0z"
  }), _react["default"].createElement("path", {
    d: "M13.414 12l7.778 7.778-1.414 1.414L12 13.414l-7.778 7.778-1.414-1.414L10.586 12 2.808 4.222l1.414-1.414L12 10.586l7.778-7.778 1.414 1.414z",
    fill: "currentColor"
  })));
};

result['close-bold-outlined'] = Closeboldoutlined; // close-filled.svg

var Closefilled = function Closefilled() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, _react["default"].createElement("circle", {
    fill: "currentColor",
    cx: "8",
    cy: "8",
    r: "8"
  }), _react["default"].createElement("path", {
    fill: "#FFF",
    fillRule: "nonzero",
    d: "M9.414 8l2.829 2.828-1.415 1.415L8 9.414l-2.828 2.829-1.415-1.415L6.586 8 3.757 5.172l1.415-1.415L8 6.586l2.828-2.829 1.415 1.415z"
  })));
};

result['close-filled'] = Closefilled; // close-medium-outlined.svg

var Closemediumoutlined = function Closemediumoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    fill: "currentColor",
    d: "M7.95 6.536l4.95-4.95L14.313 3l-4.95 4.95 4.95 4.95-1.415 1.414-4.95-4.95L3 14.314l-1.414-1.415 4.95-4.95L1.586 3 3 1.586l4.95 4.95z",
    fillRule: "evenodd"
  }));
};

result['close-medium-outlined'] = Closemediumoutlined; // close-normal-outlined.svg

var Closenormaloutlined = function Closenormaloutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M2.882 2.151L8 7.27l5.118-5.118a.517.517 0 11.73.731L8.732 8l5.118 5.118a.517.517 0 01-.731.73L8 8.732 2.882 13.85a.517.517 0 01-.73-.731L7.268 8 2.151 2.882a.517.517 0 01.731-.73z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['close-normal-outlined'] = Closenormaloutlined; // close.svg

var Close = function Close() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return props.solid || false ? _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("g", {
    fill: "none"
  }, _react["default"].createElement("circle", {
    fill: "currentColor",
    cx: "8",
    cy: "8",
    r: "8"
  }), _react["default"].createElement("path", {
    fill: "#FFF",
    d: "M9.414 8l2.829 2.828-1.415 1.415L8 9.414l-2.828 2.829-1.415-1.415L6.586 8 3.757 5.172l1.415-1.415L8 6.586l2.828-2.829 1.415 1.415z"
  }))) : _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("g", {
    fill: "none"
  }, _react["default"].createElement("circle", {
    fill: "#000",
    cx: "8",
    cy: "8",
    r: "8"
  }), _react["default"].createElement("path", {
    fill: "#FFF",
    d: "M9.414 8l2.829 2.828-1.415 1.415L8 9.414l-2.828 2.829-1.415-1.415L6.586 8 3.757 5.172l1.415-1.415L8 6.586l2.828-2.829 1.415 1.415z"
  })));
};

result.close = Close; // collection-outlined.svg

var Collectionoutlined = function Collectionoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M11.757 1c-1.61 0-3.016.917-3.752 2.26A4.285 4.285 0 004.243 1C1.897 1 0 2.908 0 5.264c0 1.12.426 2.132 1.13 2.9l6.513 6.544c.107.096.234.15.362.15a.546.546 0 00.363-.15l6.513-6.545A4.288 4.288 0 0016 5.263C16 2.909 14.103 1 11.757 1zm2.314 6.47l-6.066 6.162L1.94 7.47a3.204 3.204 0 01-.874-2.206 3.189 3.189 0 013.177-3.198c1.417 0 2.643.938 3.07 2.228.106.277.383.48.692.48a.76.76 0 00.704-.47 3.213 3.213 0 013.048-2.238 3.182 3.182 0 013.177 3.198c.01.853-.32 1.63-.863 2.206z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['collection-outlined'] = Collectionoutlined; // collection.svg

var Collection = function Collection() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("path", {
    d: "M11.757 1c-1.61 0-3.016.917-3.752 2.26A4.285 4.285 0 004.243 1C1.897 1 0 2.908 0 5.264c0 1.12.426 2.132 1.13 2.9l6.513 6.544c.107.096.234.15.362.15a.546.546 0 00.363-.15l6.513-6.545A4.288 4.288 0 0016 5.263C16 2.909 14.103 1 11.757 1zm2.314 6.47l-6.066 6.162L1.94 7.47a3.204 3.204 0 01-.874-2.206 3.189 3.189 0 013.177-3.198c1.417 0 2.643.938 3.07 2.228.106.277.383.48.692.48a.76.76 0 00.704-.47 3.213 3.213 0 013.048-2.238 3.182 3.182 0 013.177 3.198c.01.853-.32 1.63-.863 2.206z"
  }));
};

result.collection = Collection; // contract-outlined.svg

var Contractoutlined = function Contractoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M12 15v-1.05a2.5 2.5 0 111 0V15h1.5a.5.5 0 110 1h-4a.5.5 0 110-1H12zm1.5-7a.5.5 0 01-.5-.5V1H3v14h4.5a.5.5 0 110 1H3a1 1 0 01-1-1V1a1 1 0 011-1h10a1 1 0 011 1v6.5a.5.5 0 01-.5.5zm-1 5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm-7-9h5a.5.5 0 110 1h-5a.5.5 0 010-1zm0 3h5a.5.5 0 110 1h-5a.5.5 0 010-1z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['contract-outlined'] = Contractoutlined; // contrast-outlined.svg

var Contrastoutlined = function Contrastoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M14 1a1 1 0 011 1v12a1 1 0 01-1 1H2a1 1 0 01-1-1V2a1 1 0 011-1h12zm0 1H2v12h12V2zM4.5 6a.5.5 0 01.5.5v5a.5.5 0 11-1 0v-5a.5.5 0 01.5-.5zm5-2a.5.5 0 01.5.5v7a.5.5 0 11-1 0v-7a.5.5 0 01.5-.5zm-3 4a.5.5 0 01.5.5v3a.5.5 0 11-1 0v-3a.5.5 0 01.5-.5zm5-1a.5.5 0 01.5.5v4a.5.5 0 11-1 0v-4a.5.5 0 01.5-.5z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['contrast-outlined'] = Contrastoutlined; // copy-outlined.svg

var Copyoutlined = function Copyoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M2 4v11h9V4H2zm0-1h9a1 1 0 011 1v11a1 1 0 01-1 1H2a1 1 0 01-1-1V4a1 1 0 011-1zm4.5-3h7.49c.553 0 1 .383 1 .856v7.547A.45.45 0 0115 8.5v2a.5.5 0 11-1 0v-.552h-.01V1H6.5a.5.5 0 010-1z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['copy-outlined'] = Copyoutlined; // copy.svg

var Copy = function Copy() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("path", {
    d: "M2 4v11h9V4H2zm0-1h9a1 1 0 011 1v11a1 1 0 01-1 1H2a1 1 0 01-1-1V4a1 1 0 011-1zm4.5-3h7.49c.553 0 1 .383 1 .856v7.547A.45.45 0 0115 8.5v2a.5.5 0 11-1 0v-.552h-.01V1H6.5a.5.5 0 010-1z"
  }));
};

result.copy = Copy; // cross.svg

var Cross = function Cross() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("path", {
    d: "M8.5 7.5H15a.5.5 0 110 1H8.5V15a.5.5 0 11-1 0V8.5H1a.5.5 0 010-1h6.5V1a.5.5 0 011 0v6.5z"
  }));
};

result.cross = Cross; // crowd-outlined.svg

var Crowdoutlined = function Crowdoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, _react["default"].createElement("g", {
    transform: "translate(1 1)"
  }, _react["default"].createElement("circle", {
    cx: "9",
    cy: "4",
    r: "3"
  }), _react["default"].createElement("path", {
    d: "M6.035.183a3 3 0 10-1.413 5.793M14 12a5 5 0 00-10 0c0 2.761 10 2.761 10 0zM4.532 6.022A5 5 0 000 11c0 .854.956 1.443 2.276 1.77"
  })), _react["default"].createElement("path", {
    d: "M5.8 7.444a.498.498 0 01-.222.075A4.5 4.5 0 001.5 12c0 .496.663.98 1.896 1.284a.5.5 0 11-.24.97C1.526 13.853.5 13.105.5 12a5.504 5.504 0 013.501-5.126A3.501 3.501 0 017.208.714a.5.5 0 11-.345.939A2.5 2.5 0 105.685 6.48a.5.5 0 01.115.963zm2.202.43a3.5 3.5 0 113.996 0A5.502 5.502 0 0115.5 13c0 3.428-11 3.428-11 0a5.502 5.502 0 013.502-5.126zM10 7.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5zm4.5 5.5a4.5 4.5 0 10-9 0c0 2.095 9 2.095 9 0z",
    fill: "currentColor"
  })));
};

result['crowd-outlined'] = Crowdoutlined; // crowd.svg

var Crowd = function Crowd() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, _react["default"].createElement("g", {
    transform: "translate(1 1)",
    stroke: "#2B75F6"
  }, _react["default"].createElement("circle", {
    cx: "9",
    cy: "4",
    r: "3"
  }), _react["default"].createElement("path", {
    d: "M6.035.183a3 3 0 10-1.413 5.793",
    strokeLinecap: "round"
  }), _react["default"].createElement("path", {
    d: "M14 12a5 5 0 00-10 0c0 2.761 10 2.761 10 0z"
  }), _react["default"].createElement("path", {
    d: "M4.532 6.022A5 5 0 000 11c0 .854.956 1.443 2.276 1.77",
    strokeLinecap: "round"
  })), _react["default"].createElement("path", {
    d: "M5.8 7.444a.498.498 0 01-.222.075A4.5 4.5 0 001.5 12c0 .496.663.98 1.896 1.284a.5.5 0 11-.24.97C1.526 13.853.5 13.105.5 12a5.504 5.504 0 013.501-5.126A3.501 3.501 0 017.208.714a.5.5 0 11-.345.939A2.5 2.5 0 105.685 6.48a.5.5 0 01.115.963zm2.202.43a3.5 3.5 0 113.996 0A5.502 5.502 0 0115.5 13c0 3.428-11 3.428-11 0a5.502 5.502 0 013.502-5.126zM10 7.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5zm4.5 5.5a4.5 4.5 0 10-9 0c0 2.095 9 2.095 9 0z",
    fill: "#000",
    fillRule: "nonzero"
  })));
};

result.crowd = Crowd; // cursor-arrow-outlined.svg

var Cursorarrowoutlined = function Cursorarrowoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "24",
    height: "24",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    xmlnsXlink: "http://www.w3.org/1999/xlink"
  }, props), _react["default"].createElement("defs", null, _react["default"].createElement("filter", {
    x: "-75%",
    y: "-45.8%",
    width: "267.7%",
    height: "195.8%",
    filterUnits: "objectBoundingBox",
    id: "a"
  }, _react["default"].createElement("feMorphology", {
    radius: "1",
    operator: "dilate",
    "in": "SourceAlpha",
    result: "shadowSpreadOuter1"
  }), _react["default"].createElement("feOffset", {
    dy: "1",
    "in": "shadowSpreadOuter1",
    result: "shadowOffsetOuter1"
  }), _react["default"].createElement("feGaussianBlur", {
    stdDeviation: "1.5",
    "in": "shadowOffsetOuter1",
    result: "shadowBlurOuter1"
  }), _react["default"].createElement("feComposite", {
    "in": "shadowBlurOuter1",
    in2: "SourceAlpha",
    operator: "out",
    result: "shadowBlurOuter1"
  }), _react["default"].createElement("feColorMatrix", {
    values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0",
    "in": "shadowBlurOuter1"
  })), _react["default"].createElement("path", {
    d: "M8 5v12l3-3 2.5 5h1s.646-.65.5-1c-.688-1.65-2.5-5-2.5-5H16L8 5z",
    id: "b"
  })), _react["default"].createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, _react["default"].createElement("path", {
    d: "M0 0h20v20H0z"
  }), _react["default"].createElement("g", {
    fill: "#000"
  }, _react["default"].createElement("use", {
    filter: "url(#a)",
    xlinkHref: "#b"
  }), _react["default"].createElement("path", {
    stroke: "#FFF",
    d: "M7.5 3.793v14.414l3.363-3.363 2.328 4.656 1.664-.148s.816-1.043.607-1.544c-.492-1.18-1.551-3.218-2.128-4.308h3.873L7.5 3.793z"
  }))));
};

result['cursor-arrow-outlined'] = Cursorarrowoutlined; // cursor-hand-outlined.svg

var Cursorhandoutlined = function Cursorhandoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "24",
    height: "24",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, _react["default"].createElement("path", {
    d: "M0 0h20v20H0z"
  }), _react["default"].createElement("path", {
    d: "M16.654 9.78c-.048-.255-.119-.5-.234-.688-.192-.316-.48-.523-1.183-.524-.612-.007-1.013.047-1.272.248-.16.125-.297.303-.373.603a1.526 1.526 0 00-.136-.319c-.19-.32-.477-.53-.926-.53-.499 0-.887.075-1.204.277-.19.12-.36.286-.515.572-.023-.656-.085-1.413-.306-2.077a18.097 18.097 0 01-.257-.853c-.181-.64-.342-1.135-.598-1.456-.254-.317-.56-.568-1.734-.362a1.1 1.1 0 00-.354.432c-.135.279-.205.684-.205 1.161 0 1.055.323 2.493.64 3.367.27.74.502 1.771.58 2.6.03.333.038.63.235.91-.16-.163-.321-.452-.503-.762-.273-.466-.578-.974-.925-1.32a1.99 1.99 0 00-.777-.498 1.49 1.49 0 00-.9-.022c-.517.152-.977.6-1.162 1.34-.216.866.541 2.138 1.377 3.35.244.355.484.703.68 1.028.814 1.358 3.124 3.14 3.26 3.243l3.64-.335s.119-.665.528-.665c.203 0 .308.225.429.364.324.374.656.636 1.035.636.194 0 .358-.058.818-.523.136-.254.188-.59.207-.795L17.93 16.3c.673-.897.68-1.193.632-2.425-.014-.352-.032-.794-.032-1.375 0-1.101-.114-1.758-.272-2.153-.131-.33-.302-.52-.475-.64-.208-.145-.434-.196-1.129.074z",
    stroke: "#000",
    fill: "#FFF"
  })));
};

result['cursor-hand-outlined'] = Cursorhandoutlined; // custom-outlined.svg

var Customoutlined = function Customoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M4 1a1 1 0 011 1v12a1 1 0 01-1 1H2a1 1 0 01-1-1V2a1 1 0 011-1h2zm5 0a1 1 0 011 1v12a1 1 0 01-1 1H7a1 1 0 01-1-1V2a1 1 0 011-1h2zm5 0a1 1 0 011 1v12a1 1 0 01-1 1h-2a1 1 0 01-1-1V2a1 1 0 011-1h2zM4 2H2v12h2V2zm5 0H7v12h2V2zm5 0h-2v12h2V2z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['custom-outlined'] = Customoutlined; // dashboard-outlined.svg

var Dashboardoutlined = function Dashboardoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, _react["default"].createElement("path", {
    d: "M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 15c-3.9 0-7-3.1-7-7s3.1-7 7-7 7 3.1 7 7-3.1 7-7 7z",
    fill: "#2B75F6",
    fillRule: "nonzero"
  }), _react["default"].createElement("path", {
    d: "M8 2.5C5 2.5 2.5 5 2.5 8c0 .3.2.5.5.5s.5-.2.5-.5c0-2.5 2-4.5 4.5-4.5s4.5 2 4.5 4.5c0 .3.2.5.5.5s.5-.2.5-.5c0-3-2.5-5.5-5.5-5.5z",
    fill: "#2B75F6",
    fillRule: "nonzero"
  }), _react["default"].createElement("path", {
    d: "M11.225 7.47a.5.5 0 01.012.707L9.501 9.975l-.72-.694 1.737-1.799a.5.5 0 01.707-.012z",
    fill: "#2B75F6"
  }), _react["default"].createElement("circle", {
    stroke: "#2B75F6",
    cx: "8",
    cy: "11",
    r: "2"
  }), _react["default"].createElement("path", {
    d: "M9.982 9.477a2.5 2.5 0 11-.757-.656l1.293-1.339a.5.5 0 01.72.695l-1.256 1.3zM8 0c4.4 0 8 3.6 8 8s-3.6 8-8 8-8-3.6-8-8 3.6-8 8-8zm0 15c3.9 0 7-3.1 7-7s-3.1-7-7-7-7 3.1-7 7 3.1 7 7 7zM8 2.5c3 0 5.5 2.5 5.5 5.5 0 .3-.2.5-.5.5s-.5-.2-.5-.5c0-2.5-2-4.5-4.5-4.5S3.5 5.5 3.5 8c0 .3-.2.5-.5.5s-.5-.2-.5-.5C2.5 5 5 2.5 8 2.5zm0 10a1.5 1.5 0 100-3 1.5 1.5 0 000 3z",
    fill: "currentColor"
  })));
};

result['dashboard-outlined'] = Dashboardoutlined; // dashboard.svg

var Dashboard = function Dashboard() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, _react["default"].createElement("path", {
    d: "M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 15c-3.9 0-7-3.1-7-7s3.1-7 7-7 7 3.1 7 7-3.1 7-7 7z",
    fill: "#2B75F6",
    fillRule: "nonzero"
  }), _react["default"].createElement("path", {
    d: "M8 2.5C5 2.5 2.5 5 2.5 8c0 .3.2.5.5.5s.5-.2.5-.5c0-2.5 2-4.5 4.5-4.5s4.5 2 4.5 4.5c0 .3.2.5.5.5s.5-.2.5-.5c0-3-2.5-5.5-5.5-5.5z",
    fill: "#2B75F6",
    fillRule: "nonzero"
  }), _react["default"].createElement("path", {
    d: "M11.225 7.47a.5.5 0 01.012.707L9.501 9.975l-.72-.694 1.737-1.799a.5.5 0 01.707-.012z",
    fill: "#2B75F6"
  }), _react["default"].createElement("circle", {
    stroke: "#2B75F6",
    cx: "8",
    cy: "11",
    r: "2"
  }), _react["default"].createElement("path", {
    d: "M9.982 9.477a2.5 2.5 0 11-.757-.656l1.293-1.339a.5.5 0 01.72.695l-1.256 1.3zM8 0c4.4 0 8 3.6 8 8s-3.6 8-8 8-8-3.6-8-8 3.6-8 8-8zm0 15c3.9 0 7-3.1 7-7s-3.1-7-7-7-7 3.1-7 7 3.1 7 7 7zM8 2.5c3 0 5.5 2.5 5.5 5.5 0 .3-.2.5-.5.5s-.5-.2-.5-.5c0-2.5-2-4.5-4.5-4.5S3.5 5.5 3.5 8c0 .3-.2.5-.5.5s-.5-.2-.5-.5C2.5 5 5 2.5 8 2.5zm0 10a1.5 1.5 0 100-3 1.5 1.5 0 000 3z",
    fill: "#000",
    fillRule: "nonzero"
  })));
};

result.dashboard = Dashboard; // data-outlined.svg

var Dataoutlined = function Dataoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M1.096 3.132L6.896.74C7.53.478 8.49.478 9.123.739l5.8 2.393c.924.38.923 1.305 0 1.685l-5.8 2.394c-.632.261-1.593.261-2.225 0L1.096 4.818c-.923-.381-.922-1.305 0-1.686zm.58.843l5.602 2.312c.388.16 1.074.16 1.463 0l5.602-2.312-5.602-2.312c-.388-.16-1.075-.16-1.463 0L1.675 3.975zm-.87 4.486a.5.5 0 01.386-.922l6.046 2.524c.386.162 1.08.163 1.46.006l6.105-2.53a.5.5 0 11.382.923l-6.105 2.53c-.625.26-1.596.258-2.228-.006L.807 8.461zm0 4a.5.5 0 11.386-.922l6.046 2.524c.386.162 1.08.163 1.46.006l6.105-2.53a.5.5 0 11.382.923l-6.105 2.53c-.625.26-1.596.258-2.228-.006L.807 12.461z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['data-outlined'] = Dataoutlined; // data.svg

var Data = function Data() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("path", {
    d: "M1.096 3.132L6.896.74C7.53.478 8.49.478 9.123.739l5.8 2.393c.924.38.923 1.305 0 1.685l-5.8 2.394c-.632.261-1.593.261-2.225 0L1.096 4.818c-.923-.381-.922-1.305 0-1.686zm.58.843l5.602 2.312c.388.16 1.074.16 1.463 0l5.602-2.312-5.602-2.312c-.388-.16-1.075-.16-1.463 0L1.675 3.975zm-.87 4.486a.5.5 0 01.386-.922l6.046 2.524c.386.162 1.08.163 1.46.006l6.105-2.53a.5.5 0 11.382.923l-6.105 2.53c-.625.26-1.596.258-2.228-.006L.807 8.461zm0 4a.5.5 0 11.386-.922l6.046 2.524c.386.162 1.08.163 1.46.006l6.105-2.53a.5.5 0 11.382.923l-6.105 2.53c-.625.26-1.596.258-2.228-.006L.807 12.461z"
  }));
};

result.data = Data; // date-outlined.svg

var Dateoutlined = function Dateoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M11.997 3v.467a.5.5 0 11-1 0V3h-6v.467a.5.5 0 11-1 0V3H2.005l.003 2H14l-.003-2h-2zm0-1H14c.547 0 .997.444.997.993L15 14.007a.998.998 0 01-.997.993H2.005a.997.997 0 01-.997-.993L1.005 2.993C1.005 2.449 1.45 2 2 2h1.996v-.75a.5.5 0 011 0V2h6v-.75a.5.5 0 111 0V2zM14 6H2.008v8H14V6zM4.235 9a.5.5 0 110-1h7.544a.5.5 0 110 1H4.235zm0 3a.5.5 0 110-1h7.544a.5.5 0 110 1H4.235z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['date-outlined'] = Dateoutlined; // date.svg

var Date = function Date() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("path", {
    d: "M12 2.75v.467a.5.5 0 11-1 0V2.75H5v.467a.5.5 0 01-1 0V2.75H1.75V5h12.5V2.75H12zm0-1h2.253c.547 0 .997.444.997.993v11.514a.998.998 0 01-.997.993H1.747a.997.997 0 01-.997-.993V2.743c0-.544.446-.993.997-.993H4V1a.5.5 0 011 0v.75h6V1a.5.5 0 111 0v.75zM14.25 6H1.75v8.25h12.5V6zM4.235 9a.5.5 0 110-1h7.544a.5.5 0 110 1H4.235zm0 3a.5.5 0 110-1h7.544a.5.5 0 110 1H4.235z"
  }));
};

result.date = Date; // delete-outlined.svg

var Deleteoutlined = function Deleteoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M1.5 2h13a.5.5 0 110 1h-13a.5.5 0 010-1zm3-2h7a.5.5 0 110 1h-7a.5.5 0 010-1zM13 15V5.552a.5.5 0 111 0v9.592c0 .473-.448.856-1 .856H3c-.552 0-1-.383-1-.856V5.539a.5.5 0 011 .001V15h10zM6.5 6a.5.5 0 01.5.5v4a.5.5 0 11-1 0v-4a.5.5 0 01.5-.5zm3 0a.5.5 0 01.5.5v4a.5.5 0 11-1 0v-4a.5.5 0 01.5-.5z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['delete-outlined'] = Deleteoutlined; // delete.svg

var Delete = function Delete() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("path", {
    d: "M14 15.144c0 .473-.448.856-1 .856H3c-.552 0-1-.383-1-.856v-5.105l1 .001V15h10V5.5a.5.5 0 111 0V10c0 .018 0 .035-.003.052L14 15.144zm-.5-4.644a.5.5 0 00.497-.448L14 15.144c0 .473-.448.856-1 .856H3c-.552 0-1-.383-1-.856V10l.01-4.5a.5.5 0 011 0L3 10c0 .014 0 .027-.002.04L3 15h10v-4.948a.505.505 0 00.5.448zM1.5 2h13a.5.5 0 110 1h-13a.5.5 0 010-1zm3-2h7a.5.5 0 110 1h-7a.5.5 0 010-1zM13 15.144V15c.031 0 0 .027 0 .144zm-10 0V15c-.031 0 0 .027 0 .144zm0 0V15c-.031 0 0 .027 0 .144zm10 0c0-.117.031-.144 0-.144v.144zM6.5 6a.5.5 0 01.5.5v5a.5.5 0 11-1 0v-5a.5.5 0 01.5-.5zm3 0a.5.5 0 01.5.5v5a.5.5 0 11-1 0v-5a.5.5 0 01.5-.5z"
  }));
};

result["delete"] = Delete; // details-outlined.svg

var Detailsoutlined = function Detailsoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M13 0a1 1 0 011 1v14a1 1 0 01-1 1H3a1 1 0 01-1-1V1a1 1 0 011-1h10zm0 1H3v14h10V1zm-4.5 9a.5.5 0 110 1h-3a.5.5 0 110-1h3zm2-3a.5.5 0 110 1h-5a.5.5 0 010-1h5zm0-3a.5.5 0 110 1h-5a.5.5 0 010-1h5z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['details-outlined'] = Detailsoutlined; // download-center-outlined.svg

var Downloadcenteroutlined = function Downloadcenteroutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M2 2v12h12V2H2zm0-1h12a1 1 0 011 1v12a1 1 0 01-1 1H2a1 1 0 01-1-1V2a1 1 0 011-1zm6.501 9.431l2.179-1.815a.5.5 0 11.64.768l-3 2.5a.5.5 0 01-.64 0l-3-2.5a.5.5 0 11.64-.768l2.181 1.818V5a.5.5 0 011 0v5.431z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['download-center-outlined'] = Downloadcenteroutlined; // download-center.svg

var Downloadcenter = function Downloadcenter() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("path", {
    d: "M2 2v12h12V2H2zm0-1h12a1 1 0 011 1v12a1 1 0 01-1 1H2a1 1 0 01-1-1V2a1 1 0 011-1zm6.501 9.431l2.179-1.815a.5.5 0 11.64.768l-3 2.5a.5.5 0 01-.64 0l-3-2.5a.5.5 0 11.64-.768l2.181 1.818V5a.5.5 0 011 0v5.431z"
  }));
};

result['download-center'] = Downloadcenter; // download-outlined.svg

var Downloadoutlined = function Downloadoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M8.501 10.825l4.409-3.527a.5.5 0 11.625.781l-5.223 4.178a.5.5 0 01-.624 0L2.465 8.08a.5.5 0 01.625-.78l4.411 3.528V1.545c0-.301.224-.545.5-.545s.5.244.5.545v9.28zM1.5 14h13a.5.5 0 110 1h-13a.5.5 0 110-1z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['download-outlined'] = Downloadoutlined; // download.svg

var Download = function Download() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("path", {
    d: "M8.501 10.825l4.409-3.527a.5.5 0 11.625.781l-5.223 4.178a.5.5 0 01-.624 0L2.465 8.08a.5.5 0 01.625-.78l4.411 3.528V1.545c0-.301.224-.545.5-.545s.5.244.5.545v9.28zM1.5 14h13a.5.5 0 110 1h-13a.5.5 0 110-1z"
  }));
};

result.download = Download; // downward.svg

var Downward = function Downward() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16"
  }, props), _react["default"].createElement("path", {
    d: "M8.207 9.5L3.707 5 3 5.707l5.207 5.207 5.207-5.207L12.707 5z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result.downward = Downward; // dropdown-arrow-outlined.svg

var Dropdownarrowoutlined = function Dropdownarrowoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M12.42 5.92a.25.25 0 00-.354 0L8 9.984 3.934 5.92a.25.25 0 00-.353 0l-.354.354a.25.25 0 000 .354L7.47 10.87l.353.354a.25.25 0 00.354 0l.353-.354 4.243-4.242a.25.25 0 000-.354l-.354-.354z",
    fill: "currentColor"
  }));
};

result['dropdown-arrow-outlined'] = Dropdownarrowoutlined; // drp-outlined.svg

var Drpoutlined = function Drpoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M8 7.5V3a1 1 0 011-1h2V1a1 1 0 011-1h3a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1V3H9v4.5h2V7a1 1 0 011-1h3a1 1 0 011 1v2a1 1 0 01-1 1h-3a1 1 0 01-1-1v-.5H9V13h2v-1a1 1 0 011-1h3a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1H9a1 1 0 01-1-1V8.5H6V14a1 1 0 01-1 1H1a1 1 0 01-1-1V2a1 1 0 011-1h4a1 1 0 011 1v5.5h2zM1 2v12h4V2H1zm11-1v3h3V1h-3zm0 6v2h3V7h-3zm0 5v3h3v-3h-3z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['drp-outlined'] = Drpoutlined; // edit-outlined.svg

var Editoutlined = function Editoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M13 14V6.5a.5.5 0 111 0V14a1 1 0 01-1 1H2a1 1 0 01-1-1V2a1 1 0 011-1h6.5a.5.5 0 010 1H2v12h11zm1.132-12.646a.5.5 0 010 .707L7.06 9.13a.5.5 0 11-.707-.706l7.07-7.071a.5.5 0 01.708 0z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['edit-outlined'] = Editoutlined; // edit.svg

var Edit = function Edit() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("path", {
    d: "M13 14V6.5a.5.5 0 111 0V14a1 1 0 01-1 1H2a1 1 0 01-1-1V2a1 1 0 011-1h6.5a.5.5 0 010 1H2v12h11zm1.132-12.646a.5.5 0 010 .707L7.06 9.13a.5.5 0 11-.707-.706l7.07-7.071a.5.5 0 01.708 0z"
  }));
};

result.edit = Edit; // effect-outlined.svg

var Effectoutlined = function Effectoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M7.205 2.139a4.8 4.8 0 116.657 6.657 6.933 6.933 0 11-6.657-6.657zm1.226.157a6.943 6.943 0 015.273 5.273 3.733 3.733 0 10-5.273-5.273zm-1.76.91a5.867 5.867 0 106.123 6.123 4.8 4.8 0 01-6.123-6.123zm1.126.057a3.733 3.733 0 004.94 4.94 5.87 5.87 0 00-4.94-4.94z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['effect-outlined'] = Effectoutlined; // effect.svg

var Effect = function Effect() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("path", {
    d: "M7.205 2.139a4.8 4.8 0 116.657 6.657 6.933 6.933 0 11-6.657-6.657zm1.226.157a6.943 6.943 0 015.273 5.273 3.733 3.733 0 10-5.273-5.273zm-1.76.91a5.867 5.867 0 106.123 6.123 4.8 4.8 0 01-6.123-6.123zm1.126.057a3.733 3.733 0 004.94 4.94 5.87 5.87 0 00-4.94-4.94z"
  }));
};

result.effect = Effect; // ellipsis-outlined.svg

var Ellipsisoutlined = function Ellipsisoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("g", {
    transform: "translate(1 7)",
    fill: "currentColor",
    fillRule: "evenodd"
  }, _react["default"].createElement("circle", {
    cx: "1",
    cy: "1",
    r: "1"
  }), _react["default"].createElement("circle", {
    cx: "7",
    cy: "1",
    r: "1"
  }), _react["default"].createElement("circle", {
    cx: "13",
    cy: "1",
    r: "1"
  })));
};

result['ellipsis-outlined'] = Ellipsisoutlined; // enterprise-outlined.svg

var Enterpriseoutlined = function Enterpriseoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M9 12V2H2v10h7zm1 0h4V7h-4v5zm3.8 1H2.125C1.504 13 1 12.552 1 12V2c0-.552.504-1 1.125-1h6.75C9.496 1 10 1.448 10 2v4.014c.065-.01.132-.014.2-.014h3.6c.663 0 1.2.448 1.2 1v5c0 .552-.537 1-1.2 1zM1.5 14h13a.5.5 0 110 1h-13a.5.5 0 110-1zm2-10h4a.5.5 0 010 1h-4a.5.5 0 010-1zm0 3h4a.5.5 0 010 1h-4a.5.5 0 010-1z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['enterprise-outlined'] = Enterpriseoutlined; // enterprise.svg

var Enterprise = function Enterprise() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("path", {
    d: "M9 12V2H2v10h7zm1 0h4V7h-4v5zm3.8 1H2.125C1.504 13 1 12.552 1 12V2c0-.552.504-1 1.125-1h6.75C9.496 1 10 1.448 10 2v4.014c.065-.01.132-.014.2-.014h3.6c.663 0 1.2.448 1.2 1v5c0 .552-.537 1-1.2 1zM1.5 14h13a.5.5 0 110 1h-13a.5.5 0 110-1zm2-10h4a.5.5 0 010 1h-4a.5.5 0 010-1zm0 3h4a.5.5 0 010 1h-4a.5.5 0 010-1z"
  }));
};

result.enterprise = Enterprise; // exclamation-filled.svg

var Exclamationfilled = function Exclamationfilled() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var id = "spaui-icon-exclamation-filled-".concat(index += 1);
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg",
    xmlnsXlink: "http://www.w3.org/1999/xlink"
  }, props), _react["default"].createElement("defs", null, _react["default"].createElement("circle", {
    id: id,
    cx: "8",
    cy: "8",
    r: "8"
  })), _react["default"].createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, _react["default"].createElement("mask", {
    id: "".concat(id, "-b"),
    fill: "#fff"
  }, _react["default"].createElement("use", {
    xlinkHref: "#".concat(id)
  })), _react["default"].createElement("use", {
    fill: "currentColor",
    xlinkHref: "#".concat(id)
  }), _react["default"].createElement("path", {
    d: "M7 3h2v6.5H7V3zm0 8h2v2H7v-2z",
    fill: "#FFF",
    fillRule: "nonzero",
    mask: "url(#".concat(id, "-b)")
  })));
};

result['exclamation-filled'] = Exclamationfilled; // exclamation.svg

var Exclamation = function Exclamation() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var id = "spaui-icon-exclamation-".concat(index += 1);
  return props.solid || false ? _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg",
    xmlnsXlink: "http://www.w3.org/1999/xlink"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("defs", null, _react["default"].createElement("circle", {
    id: id,
    cx: "8",
    cy: "8",
    r: "8"
  })), _react["default"].createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, _react["default"].createElement("mask", {
    id: "".concat(id, "-b"),
    fill: "#fff"
  }, _react["default"].createElement("use", {
    xlinkHref: "#".concat(id)
  })), _react["default"].createElement("use", {
    fill: "currentColor",
    fillRule: "nonzero",
    xlinkHref: "#".concat(id)
  }), _react["default"].createElement("path", {
    d: "M7 3h2v6.5H7V3zm0 8h2v2H7v-2z",
    fill: "#FFF",
    fillRule: "nonzero",
    mask: "url(#".concat(id, "-b)")
  }))) : _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg",
    xmlnsXlink: "http://www.w3.org/1999/xlink"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("defs", null, _react["default"].createElement("circle", {
    id: id,
    cx: "8",
    cy: "8",
    r: "8"
  })), _react["default"].createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, _react["default"].createElement("mask", {
    id: "".concat(id, "-b"),
    fill: "#fff"
  }, _react["default"].createElement("use", {
    xlinkHref: "#".concat(id)
  })), _react["default"].createElement("use", {
    fill: "#000",
    fillRule: "nonzero",
    xlinkHref: "#".concat(id)
  }), _react["default"].createElement("path", {
    d: "M7 3h2v6.5H7V3zm0 8h2v2H7v-2z",
    fill: "#FFF",
    fillRule: "nonzero",
    mask: "url(#".concat(id, "-b)")
  })));
};

result.exclamation = Exclamation; // explain-outlined.svg

var Explainoutlined = function Explainoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M12 1a1 1 0 011 1v2h1a1 1 0 011 1v9a1 1 0 01-1 1H2a1 1 0 01-1-1V2a1 1 0 011-1h10zm0 1H2v12h10V2zm2 3h-1v9h1V5zm-6.5 5a.5.5 0 110 1h-3a.5.5 0 110-1h3zm2-3a.5.5 0 010 1h-5a.5.5 0 010-1h5zm0-3a.5.5 0 010 1h-5a.5.5 0 010-1h5z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['explain-outlined'] = Explainoutlined; // fall-colored.svg

var Fallcolored = function Fallcolored() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M8.283 13.47l4.034-4.035a.4.4 0 00-.283-.683H10v-5.42a.4.4 0 00-.4-.4H6.4a.4.4 0 00-.4.4v5.42H3.966a.4.4 0 00-.283.683l4.034 4.034a.4.4 0 00.566 0z",
    fill: "#E63D2E",
    fillRule: "evenodd"
  }));
};

result['fall-colored'] = Fallcolored; // fast-forward-outlined.svg

var Fastforwardoutlined = function Fastforwardoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M6.09 7.876L1.029 2.318 2.507.97l6.286 6.903-6.284 6.919-1.48-1.345 5.06-5.572zm6 0L7.029 2.318 8.507.97l6.286 6.903-6.284 6.919-1.48-1.345 5.06-5.572z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['fast-forward-outlined'] = Fastforwardoutlined; // feedback-outlined.svg

var Feedbackoutlined = function Feedbackoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M6.8 13.009l-3.16 2.757A.995.995 0 013 16c-.552 0-1-.452-1-1.01v-1.98H1c-.552 0-1-.452-1-1.01V2.01C0 1.452.448 1 1 1h14c.552 0 1 .452 1 1.01V12c0 .557-.448 1.009-1 1.009H6.8zm-.375-1H15c-.002 0 0-.002 0-.01V2.01c0-.008-.002-.01 0-.01H1c.002 0 0 .002 0 .01V12c0 .007.002.009 0 .009h2v2.988l3.425-2.988zM4.5 5h7a.5.5 0 110 1h-7a.5.5 0 010-1zm0 3h7a.5.5 0 110 1h-7a.5.5 0 010-1z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['feedback-outlined'] = Feedbackoutlined; // file-outlined.svg

var Fileoutlined = function Fileoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M9.022 1.778v2.719a.5.5 0 00.5.5h2.794l-3.294-3.22zm-1-.775H3.497c-.248 0-.5.253-.5.5V14.5c0 .248.252.5.5.5h9.013c.248 0 .5-.252.5-.5V5.997H9.522a1.5 1.5 0 01-1.5-1.5V1.003zm-6.025.5c0-.8.7-1.5 1.5-1.5h5.411l5.102 5.274V14.5c0 .8-.7 1.5-1.5 1.5H3.497c-.8 0-1.5-.7-1.5-1.5V1.503z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['file-outlined'] = Fileoutlined; // file.svg

var File = function File() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("path", {
    d: "M9.614 1.778V4.47a.5.5 0 00.5.5h2.692L9.614 1.778zm-1-.528H3c-.248 0-.5.252-.5.5v12.5c0 .248.252.5.5.5h10c.248 0 .5-.252.5-.5V5.97h-3.386a1.5 1.5 0 01-1.5-1.5V1.25zm-7.114.5c0-.8.7-1.5 1.5-1.5h6.5l5 5v9c0 .8-.7 1.5-1.5 1.5H3c-.8 0-1.5-.7-1.5-1.5V1.75z"
  }));
};

result.file = File; // flow-outlined.svg

var Flowoutlined = function Flowoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M14.98 8.529h-2.56l-1.252 1.353a.5.5 0 01-.82-.13L8.734 6.269l-1.67 4.216a.5.5 0 01-.876.1L4.444 8.069l-.977 1.057a.5.5 0 01-.367.16H1.118A7.002 7.002 0 0014.98 8.53zm.004-1a7 7 0 00-13.978.757H2.88l1.252-1.354a.5.5 0 01.778.055l1.568 2.261 1.756-4.432a.5.5 0 01.919-.026l1.786 3.865.893-.966a.5.5 0 01.367-.16h2.784zM8 16A8 8 0 118 0a8 8 0 010 16z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['flow-outlined'] = Flowoutlined; // flow.svg

var Flow = function Flow() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("path", {
    d: "M14.98 8.529h-2.56l-1.252 1.353a.5.5 0 01-.82-.13L8.734 6.269l-1.67 4.216a.5.5 0 01-.876.1L4.444 8.069l-.977 1.057a.5.5 0 01-.367.16H1.118A7.002 7.002 0 0014.98 8.53zm.004-1a7 7 0 00-13.978.757H2.88l1.252-1.354a.5.5 0 01.778.055l1.568 2.261 1.756-4.432a.5.5 0 01.919-.026l1.786 3.865.893-.966a.5.5 0 01.367-.16h2.784zM8 16A8 8 0 118 0a8 8 0 010 16z"
  }));
};

result.flow = Flow; // form-outlined.svg

var Formoutlined = function Formoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M5 2H2v3h3V2zm1 0v3h8V2H6zM5 14V6H2v8h3zm1 0h8V6H6v8zM2 1h12a1 1 0 011 1v12a1 1 0 01-1 1H2a1 1 0 01-1-1V2a1 1 0 011-1z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['form-outlined'] = Formoutlined; // form.svg

var Form = function Form() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("path", {
    d: "M5 2H2v3h3V2zm1 0v3h8V2H6zM5 14V6H2v8h3zm1 0h8V6H6v8zM2 1h12a1 1 0 011 1v12a1 1 0 01-1 1H2a1 1 0 01-1-1V2a1 1 0 011-1z"
  }));
};

result.form = Form; // forward-outlined.svg

var Forwardoutlined = function Forwardoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    fill: "currentColor",
    d: "M9.09 7.876l-5.061 5.572 1.48 1.345 6.284-6.919L5.507.971 4.03 2.318z",
    fillRule: "evenodd"
  }));
};

result['forward-outlined'] = Forwardoutlined; // from-auth-outlined.svg

var Fromauthoutlined = function Fromauthoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M8 8.682V10.5a.5.5 0 11-1 0v-3a.5.5 0 01.5-.5h3a.5.5 0 110 1H8.732l5.432 5.431a.5.5 0 01-.708.707L8 8.682zm6-.182V2H2v12h6.5a.5.5 0 110 1H2a1 1 0 01-1-1V2a1 1 0 011-1h12a1 1 0 011 1v6.5a.5.5 0 11-1 0z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['from-auth-outlined'] = Fromauthoutlined; // from-authorization.svg

var Fromauthorization = function Fromauthorization() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("path", {
    d: "M8 8.682V10.5a.5.5 0 11-1 0v-3a.5.5 0 01.5-.5h3a.5.5 0 110 1H8.732l5.432 5.431a.5.5 0 01-.708.707L8 8.682zm6-.182V2H2v12h6.5a.5.5 0 110 1H2a1 1 0 01-1-1V2a1 1 0 011-1h12a1 1 0 011 1v6.5a.5.5 0 11-1 0z"
  }));
};

result['from-authorization'] = Fromauthorization; // golden-colored.svg

var Goldencolored = function Goldencolored() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("g", {
    fill: "none"
  }, _react["default"].createElement("path", {
    fill: "#FED331",
    d: "M11.96 5.616L8 2 4.04 5.616 0 3.62 2.064 14h11.872L16 3.619z"
  }), _react["default"].createElement("path", {
    fill: "#EBC228",
    d: "M11.964 5.616L8.008 2 8 2.007V14h5.938L16 3.619z"
  })));
};

result['golden-colored'] = Goldencolored; // group-outlined.svg

var Groupoutlined = function Groupoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M6 9H2v5h12V5H2v3h4a1 1 0 011-1h2a1 1 0 011 1h4v1h-4a1 1 0 01-1 1H7a1 1 0 01-1-1zM3.968 4a4.5 4.5 0 018.064 0H14a1 1 0 011 1v9a1 1 0 01-1 1H2a1 1 0 01-1-1V5a1 1 0 011-1h1.968zm1.159 0h5.746a3.5 3.5 0 00-5.746 0zM7 8v1h2V8H7z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['group-outlined'] = Groupoutlined; // group.svg

var Group = function Group() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("path", {
    d: "M6 9H2v5h12V5H2v3h4a1 1 0 011-1h2a1 1 0 011 1h4v1h-4a1 1 0 01-1 1H7a1 1 0 01-1-1zM3.968 4a4.5 4.5 0 018.064 0H14a1 1 0 011 1v9a1 1 0 01-1 1H2a1 1 0 01-1-1V5a1 1 0 011-1h1.968zm1.159 0h5.746a3.5 3.5 0 00-5.746 0zM7 8v1h2V8H7z"
  }));
};

result.group = Group; // home-outlined.svg

var Homeoutlined = function Homeoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M10.824 15.063h1.88a.468.468 0 00.472-.465v-9.15L8 2.03 2.824 5.447v9.15c0 .254.214.466.472.466h1.88V11.78A2.818 2.818 0 018 8.97a2.818 2.818 0 012.824 2.812v3.281zm-.942 0V11.78A1.879 1.879 0 008 9.906c-1.04 0-1.882.84-1.882 1.875v3.281h3.764zm-8-8.995l-1.151.76a.472.472 0 01-.653-.132.468.468 0 01.133-.65l7.53-4.968a.472.472 0 01.519 0l7.53 4.969a.468.468 0 01.132.65.472.472 0 01-.653.131l-1.151-.76v8.53c0 .776-.634 1.402-1.414 1.402H3.296a1.412 1.412 0 01-1.414-1.402v-8.53z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['home-outlined'] = Homeoutlined; // home.svg

var Home = function Home() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("path", {
    d: "M10.824 15.063h1.88a.468.468 0 00.472-.465v-9.15L8 2.03 2.824 5.447v9.15c0 .254.214.466.472.466h1.88V11.78A2.818 2.818 0 018 8.97a2.818 2.818 0 012.824 2.812v3.281zm-.942 0V11.78A1.879 1.879 0 008 9.906c-1.04 0-1.882.84-1.882 1.875v3.281h3.764zm-8-8.995l-1.151.76a.472.472 0 01-.653-.132.468.468 0 01.133-.65l7.53-4.968a.472.472 0 01.519 0l7.53 4.969a.468.468 0 01.132.65.472.472 0 01-.653.131l-1.151-.76v8.53c0 .776-.634 1.402-1.414 1.402H3.296a1.412 1.412 0 01-1.414-1.402v-8.53z"
  }));
};

result.home = Home; // in-decrease-outlined.svg

var Indecreaseoutlined = function Indecreaseoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M8.207 14.5l-4.5-4.5-.707.707 5.207 5.207 5.207-5.207-.707-.707zm0-13.086l-4.5 4.5L3 5.207 8.207 0l5.207 5.207-.707.707z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['in-decrease-outlined'] = Indecreaseoutlined; // income-outlined.svg

var Incomeoutlined = function Incomeoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M7 12.376c-.602-.184-1.137-.533-1.604-1a4.585 4.585 0 01-.845-1.134.5.5 0 11.894-.447 4.04 4.04 0 00.658.873c.54.54 1.171.857 1.9.857 1.587 0 2.505-.69 2.505-1.506 0-.816-.918-1.506-2.505-1.506-2.086 0-3.505-1.066-3.505-2.507 0-1.195.977-2.133 2.502-2.417V3a.5.5 0 011 0v.5h.003c1.024 0 1.895.436 2.608 1.15.419.42.697.838.845 1.133a.5.5 0 01-.895.447 4.04 4.04 0 00-.658-.873c-.54-.54-1.17-.857-1.9-.857-1.586 0-2.505.69-2.505 1.506 0 .816.919 1.507 2.505 1.507 2.087 0 3.505 1.065 3.505 2.506 0 1.44-1.418 2.506-3.505 2.506H8V13a.5.5 0 11-1 0v-.624zM8 15A7 7 0 108 1a7 7 0 000 14zm0 1A8 8 0 118 0a8 8 0 010 16z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['income-outlined'] = Incomeoutlined; // income.svg

var Income = function Income() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("path", {
    d: "M7 12.376c-.602-.184-1.137-.533-1.604-1a4.585 4.585 0 01-.845-1.134.5.5 0 11.894-.447 4.04 4.04 0 00.658.873c.54.54 1.171.857 1.9.857 1.587 0 2.505-.69 2.505-1.506 0-.816-.918-1.506-2.505-1.506-2.086 0-3.505-1.066-3.505-2.507 0-1.195.977-2.133 2.502-2.417V3a.5.5 0 011 0v.5h.003c1.024 0 1.895.436 2.608 1.15.419.42.697.838.845 1.133a.5.5 0 01-.895.447 4.04 4.04 0 00-.658-.873c-.54-.54-1.17-.857-1.9-.857-1.586 0-2.505.69-2.505 1.506 0 .816.919 1.507 2.505 1.507 2.087 0 3.505 1.065 3.505 2.506 0 1.44-1.418 2.506-3.505 2.506H8V13a.5.5 0 11-1 0v-.624zM8 15A7 7 0 108 1a7 7 0 000 14zm0 1A8 8 0 118 0a8 8 0 010 16z"
  }));
};

result.income = Income; // info-filled.svg

var Infofilled = function Infofilled() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var id = "spaui-icon-info-filled-".concat(index += 1);
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg",
    xmlnsXlink: "http://www.w3.org/1999/xlink"
  }, props), _react["default"].createElement("defs", null, _react["default"].createElement("circle", {
    id: id,
    cx: "8",
    cy: "8",
    r: "8"
  })), _react["default"].createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, _react["default"].createElement("mask", {
    id: "".concat(id, "-b"),
    fill: "#fff"
  }, _react["default"].createElement("use", {
    xlinkHref: "#".concat(id)
  })), _react["default"].createElement("use", {
    fill: "currentColor",
    xlinkHref: "#".concat(id)
  }), _react["default"].createElement("path", {
    d: "M7 3h2v6.5H7V3zm0 8h2v2H7v-2z",
    fill: "#FFF",
    fillRule: "nonzero",
    mask: "url(#".concat(id, "-b)"),
    transform: "matrix(1 0 0 -1 0 16)"
  })));
};

result['info-filled'] = Infofilled; // info.svg

var Info = function Info() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var id = "spaui-icon-info-".concat(index += 1);
  return props.solid || false ? _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg",
    xmlnsXlink: "http://www.w3.org/1999/xlink"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("defs", null, _react["default"].createElement("circle", {
    id: id,
    cx: "8",
    cy: "8",
    r: "8"
  })), _react["default"].createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, _react["default"].createElement("mask", {
    id: "".concat(id, "-b"),
    fill: "#fff"
  }, _react["default"].createElement("use", {
    xlinkHref: "#".concat(id)
  })), _react["default"].createElement("use", {
    fill: "currentColor",
    fillRule: "nonzero",
    xlinkHref: "#".concat(id)
  }), _react["default"].createElement("path", {
    d: "M7 3h2v6.5H7V3zm0 8h2v2H7v-2z",
    fill: "#FFF",
    fillRule: "nonzero",
    mask: "url(#".concat(id, "-b)"),
    transform: "matrix(1 0 0 -1 0 16)"
  }))) : _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg",
    xmlnsXlink: "http://www.w3.org/1999/xlink"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("defs", null, _react["default"].createElement("circle", {
    id: id,
    cx: "8",
    cy: "8",
    r: "8"
  })), _react["default"].createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, _react["default"].createElement("mask", {
    id: "".concat(id, "-b"),
    fill: "#fff"
  }, _react["default"].createElement("use", {
    xlinkHref: "#".concat(id)
  })), _react["default"].createElement("use", {
    fill: "#000",
    fillRule: "nonzero",
    xlinkHref: "#".concat(id)
  }), _react["default"].createElement("path", {
    d: "M7 3h2v6.5H7V3zm0 8h2v2H7v-2z",
    fill: "#FFF",
    fillRule: "nonzero",
    mask: "url(#".concat(id, "-b)"),
    transform: "matrix(1 0 0 -1 0 16)"
  })));
};

result.info = Info; // label-outlined.svg

var Labeloutlined = function Labeloutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M7.704 1.97L1.583 7.99l6.31 6.31 6.111-6.112V1.971h-6.3zm0-1h6.3a1 1 0 011 1v6.217a1 1 0 01-.293.707L8.6 15.006a1 1 0 01-1.415 0l-6.31-6.31a1 1 0 010-1.414l.007-.006 6.12-6.018A1 1 0 017.705.97zm1.944 5.306a2 2 0 112.829-2.828 2 2 0 01-2.829 2.828zm.708-.707a1 1 0 101.414-1.414 1 1 0 00-1.414 1.414z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['label-outlined'] = Labeloutlined; // label.svg

var Label = function Label() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("path", {
    d: "M7.704 1.97L1.583 7.99l6.31 6.31 6.111-6.112V1.971h-6.3zm0-1h6.3a1 1 0 011 1v6.217a1 1 0 01-.293.707L8.6 15.006a1 1 0 01-1.415 0l-6.31-6.31a1 1 0 01.007-1.42l6.12-6.018A1 1 0 017.705.97zm1.944 5.306a2 2 0 112.829-2.828 2 2 0 01-2.829 2.828zm.708-.707a1 1 0 101.414-1.414 1 1 0 00-1.414 1.414z"
  }));
};

result.label = Label; // left-arrow-bold-outlined.svg

var Leftarrowboldoutlined = function Leftarrowboldoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "24",
    height: "24",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, _react["default"].createElement("path", {
    d: "M0 0h20v20H0z"
  }), _react["default"].createElement("path", {
    d: "M4.893 12.5l1.415 1.414 9.192 9.193 1.414-1.415L7.722 12.5l9.192-9.192L15.5 1.893l-9.192 9.193z",
    fill: "currentColor"
  })));
};

result['left-arrow-bold-outlined'] = Leftarrowboldoutlined; // left-arrow-double-outlined.svg

var Leftarrowdoubleoutlined = function Leftarrowdoubleoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("g", {
    fill: "currentColor",
    fillRule: "evenodd"
  }, _react["default"].createElement("path", {
    d: "M8.414 1.707L7.707 1 1 7.707l6.707 6.707.707-.707-6-6z"
  }), _react["default"].createElement("path", {
    d: "M13.121 1.707L12.414 1 5.707 7.707l6.707 6.707.707-.707-6-6z"
  })));
};

result['left-arrow-double-outlined'] = Leftarrowdoubleoutlined; // left-arrow-medium-outlined.svg

var Leftarrowmediumoutlined = function Leftarrowmediumoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    fill: "currentColor",
    d: "M11.707 2L11 1.293 4.293 8 11 14.707l.707-.707-6-6z",
    fillRule: "evenodd"
  }));
};

result['left-arrow-medium-outlined'] = Leftarrowmediumoutlined; // left-arrow-outlined.svg

var Leftarrowoutlined = function Leftarrowoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    fill: "currentColor",
    d: "M6.664 7.957l4.5-4.5-.707-.707L5.25 7.957l5.207 5.207.707-.707z",
    fillRule: "evenodd"
  }));
};

result['left-arrow-outlined'] = Leftarrowoutlined; // link-outlined.svg

var Linkoutlined = function Linkoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M7.605 4.246a.533.533 0 010-.754l1.428-1.427C10.428.669 12.74.72 14.199 2.178c1.458 1.458 1.509 3.771.113 5.166L12.147 9.51c-1.395 1.395-3.708 1.344-5.166-.114a.533.533 0 010-.754l.014-.014a.468.468 0 01.673.01l.034.037c1.042 1.041 2.694 1.077 3.69.08l2.166-2.165c.997-.996.96-2.649-.08-3.69-1.042-1.041-2.694-1.078-3.691-.081L8.359 4.246a.533.533 0 01-.754 0zm.79 7.508a.533.533 0 010 .754l-1.428 1.427c-1.395 1.396-3.708 1.345-5.166-.113C.343 12.364.292 10.05 1.688 8.656L3.853 6.49c1.395-1.395 3.708-1.344 5.166.114a.533.533 0 010 .754l-.014.014a.468.468 0 01-.673-.01 1.463 1.463 0 00-.034-.037c-1.042-1.041-2.694-1.077-3.69-.08L2.441 9.41c-.997.996-.96 2.649.08 3.69 1.042 1.041 2.694 1.078 3.691.081l1.428-1.427a.533.533 0 01.754 0z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['link-outlined'] = Linkoutlined; // link.svg

var Link = function Link() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("path", {
    d: "M7.605 4.246a.533.533 0 010-.754l1.428-1.427C10.428.669 12.74.72 14.199 2.178c1.458 1.458 1.509 3.771.113 5.166L12.147 9.51c-1.395 1.395-3.708 1.344-5.166-.114a.533.533 0 010-.754l.014-.014a.468.468 0 01.673.01l.034.037c1.042 1.041 2.694 1.077 3.69.08l2.166-2.165c.997-.996.96-2.649-.08-3.69-1.042-1.041-2.694-1.078-3.691-.081L8.359 4.246a.533.533 0 01-.754 0zm.79 7.508a.533.533 0 010 .754l-1.428 1.427c-1.395 1.396-3.708 1.345-5.166-.113C.343 12.364.292 10.05 1.688 8.656L3.853 6.49c1.395-1.395 3.708-1.344 5.166.114a.533.533 0 010 .754l-.014.014a.468.468 0 01-.673-.01 1.463 1.463 0 00-.034-.037c-1.042-1.041-2.694-1.077-3.69-.08L2.441 9.41c-.997.996-.96 2.649.08 3.69 1.042 1.041 2.694 1.078 3.691.081l1.428-1.427a.533.533 0 01.754 0z"
  }));
};

result.link = Link; // mailbox-outlined.svg

var Mailboxoutlined = function Mailboxoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M15 2a1 1 0 011 1v10a1 1 0 01-1 1H1a1 1 0 01-1-1V3a1 1 0 011-1h14zM9.635 8.262l-.66.563a1.5 1.5 0 01-1.95-.002l-.604-.519L1.705 13h12.614L9.635 8.262zM1 3.654v8.636l4.66-4.639L1 3.654zm14 .032l-4.603 3.926L15 12.267V3.686zM14.263 3H1.773l5.903 5.064a.5.5 0 00.576.053l.074-.052L14.263 3z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['mailbox-outlined'] = Mailboxoutlined; // mailbox.svg

var Mailbox = function Mailbox() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("path", {
    d: "M15 12.266v-8.58l-4.602 3.926L15 12.266zm-.68.734L9.634 8.263l-.66.562a1.5 1.5 0 01-1.95-.002l-.604-.518L1.705 13H14.32zm-.056-10H1.773l5.903 5.064a.5.5 0 00.65 0L14.264 3zM1 3.654v8.637l4.66-4.64L1 3.655zM1 2h14a1 1 0 011 1v10a1 1 0 01-1 1H1a1 1 0 01-1-1V3a1 1 0 011-1z"
  }));
};

result.mailbox = Mailbox; // mapping-outlined.svg

var Mappingoutlined = function Mappingoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M8 5.5a.5.5 0 01-.5.5H6v8h9V6h-1.5a.5.5 0 110-1H15a1 1 0 011 1v8a1 1 0 01-1 1H6a1 1 0 01-1-1V6a1 1 0 011-1h1.5a.5.5 0 01.5.5zm-5 5a.5.5 0 01-.5.5H1a1 1 0 01-1-1V2a1 1 0 011-1h9a1 1 0 011 1v8a1 1 0 01-1 1H8.5a.5.5 0 110-1H10V2H1v8h1.5a.5.5 0 01.5.5z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['mapping-outlined'] = Mappingoutlined; // mapping2-outlined.svg

var Mapping2outlined = function Mapping2outlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M6 7.5h4.308L9.022 6.214a.5.5 0 11.707-.707l2.122 2.121a.498.498 0 010 .708l-2.122 2.12a.5.5 0 01-.707-.706l1.25-1.25H6V14a1 1 0 01-1 1H1a1 1 0 01-1-1V2a1 1 0 011-1h4a1 1 0 011 1v5.5zM1 2v12h4V2H1zm9.5 2a.5.5 0 01-.5-.5V2a1 1 0 011-1h4a1 1 0 011 1v12a1 1 0 01-1 1h-4a1 1 0 01-1-1v-1.5a.5.5 0 111 0V14h4V2h-4v1.5a.5.5 0 01-.5.5z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['mapping2-outlined'] = Mapping2outlined; // marketing-app-outlined.svg

var Marketingappoutlined = function Marketingappoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M8.626 5.355a.5.5 0 11.703-.71l2.012 1.992a.5.5 0 01-.352.855H5a.5.5 0 110-1h4.773L8.626 5.355zm-1.262 5.282a.5.5 0 01-.704.71L4.65 9.355A.5.5 0 015 8.5h5.989a.5.5 0 110 1H6.216l1.148 1.137zM2 2v12h12V2H2zm0-1h12a1 1 0 011 1v12a1 1 0 01-1 1H2a1 1 0 01-1-1V2a1 1 0 011-1z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['marketing-app-outlined'] = Marketingappoutlined; // marketing-application.svg

var Marketingapplication = function Marketingapplication() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("path", {
    d: "M8.626 5.355a.5.5 0 11.703-.71l2.012 1.992a.5.5 0 01-.352.855H5a.5.5 0 110-1h4.773L8.626 5.355zm-1.262 5.282a.5.5 0 01-.704.71L4.65 9.355A.5.5 0 015 8.5h5.989a.5.5 0 110 1H6.216l1.148 1.137zM2 2v12h12V2H2zm0-1h12a1 1 0 011 1v12a1 1 0 01-1 1H2a1 1 0 01-1-1V2a1 1 0 011-1z"
  }));
};

result['marketing-application'] = Marketingapplication; // merge-outlined.svg

var Mergeoutlined = function Mergeoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M14 1a1 1 0 011 1v12a1 1 0 01-1 1h-4a1 1 0 01-1-1v-1.5a.5.5 0 111 0V14h4V8.5h-3.272l1.25 1.25a.5.5 0 01-.638.765l-.07-.058-2.12-2.121a.498.498 0 01-.065-.63l.064-.078 2.122-2.12a.5.5 0 01.765.637l-.058.07L10.692 7.5H14V2h-4v1.5a.5.5 0 01-1 0V2a1 1 0 011-1h4zM2 14h4v-1.5a.5.5 0 01.992-.09L7 12.5V14a1 1 0 01-.883.993L6 15H2a1 1 0 01-.993-.883L1 14V2a1 1 0 01.883-.993L2 1h4a1 1 0 01.993.883L7 2v1.5a.5.5 0 01-.992.09L6 3.5V2H2v5.5h3.31L4.026 6.214a.5.5 0 11.707-.707l2.122 2.121a.498.498 0 010 .708l-2.122 2.12a.5.5 0 01-.707-.706l1.25-1.25H2V14z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['merge-outlined'] = Mergeoutlined; // minus-bold-outlined.svg

var Minusboldoutlined = function Minusboldoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    fill: "currentColor",
    d: "M1 7h14v2H1z",
    fillRule: "evenodd"
  }));
};

result['minus-bold-outlined'] = Minusboldoutlined; // minus-filled.svg

var Minusfilled = function Minusfilled() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, _react["default"].createElement("circle", {
    fill: "currentColor",
    cx: "8",
    cy: "8",
    r: "8"
  }), _react["default"].createElement("path", {
    fill: "#FFF",
    fillRule: "nonzero",
    d: "M9 7h3.5v2h-9V7H7z"
  })));
};

result['minus-filled'] = Minusfilled; // minus-square-filled.svg

var Minussquarefilled = function Minussquarefilled() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, _react["default"].createElement("rect", {
    fill: "currentColor",
    x: "1",
    y: "1",
    width: "14",
    height: "14",
    rx: "1"
  }), _react["default"].createElement("path", {
    fill: "#FFF",
    fillRule: "nonzero",
    d: "M9 7h3.5v2h-9V7H7z"
  })));
};

result['minus-square-filled'] = Minussquarefilled; // minus-square.svg

var Minussquare = function Minussquare() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return props.solid || false ? _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("g", {
    fill: "none"
  }, _react["default"].createElement("rect", {
    fill: "currentColor",
    x: "1",
    y: "1",
    width: "14",
    height: "14",
    rx: "1"
  }), _react["default"].createElement("path", {
    fill: "#FFF",
    d: "M9 7h3.5v2h-9V7H7z"
  }))) : _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("g", {
    fill: "none"
  }, _react["default"].createElement("rect", {
    fill: "#000",
    x: "1",
    y: "1",
    width: "14",
    height: "14",
    rx: "1"
  }), _react["default"].createElement("path", {
    fill: "#FFF",
    d: "M9 7h3.5v2h-9V7H7z"
  })));
};

result['minus-square'] = Minussquare; // minus.svg

var Minus = function Minus() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return props.solid || false ? _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("g", {
    fill: "none"
  }, _react["default"].createElement("circle", {
    fill: "currentColor",
    cx: "8",
    cy: "8",
    r: "8"
  }), _react["default"].createElement("path", {
    fill: "#FFF",
    d: "M9 7h3.5v2h-9V7H7z"
  }))) : _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("g", {
    fill: "none"
  }, _react["default"].createElement("circle", {
    fill: "#000",
    cx: "8",
    cy: "8",
    r: "8"
  }), _react["default"].createElement("path", {
    fill: "#FFF",
    d: "M9 7h3.5v2h-9V7H7z"
  })));
};

result.minus = Minus; // model-outlined.svg

var Modeloutlined = function Modeloutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M15 8.062L11.5 2h-7L1 8.062l3.5 6.062h7L15 8.062zm.866.5l-3.5 6.062a1 1 0 01-.866.5h-7a1 1 0 01-.866-.5l-3.5-6.062a1 1 0 010-1l3.5-6.062A1 1 0 014.5 1h7a1 1 0 01.866.5l3.5 6.062a1 1 0 010 1zm-11.366-1h7a.5.5 0 110 1h-7a.5.5 0 010-1zm2-3h3a.5.5 0 110 1h-3a.5.5 0 010-1zm0 6h3a.5.5 0 010 1h-3a.5.5 0 110-1z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['model-outlined'] = Modeloutlined; // model.svg

var Model = function Model() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("path", {
    d: "M15 8.062L11.5 2h-7L1 8.062l3.5 6.062h7L15 8.062zm.866.5l-3.5 6.062a1 1 0 01-.866.5h-7a1 1 0 01-.866-.5l-3.5-6.062a1 1 0 010-1l3.5-6.062A1 1 0 014.5 1h7a1 1 0 01.866.5l3.5 6.062a1 1 0 010 1zm-11.366-1h7a.5.5 0 110 1h-7a.5.5 0 010-1zm2-3h3a.5.5 0 110 1h-3a.5.5 0 010-1zm0 6h3a.5.5 0 010 1h-3a.5.5 0 110-1z"
  }));
};

result.model = Model; // more-outlined.svg

var Moreoutlined = function Moreoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M1.583 2h12.834c.322 0 .583.224.583.5s-.261.5-.583.5H1.583C1.261 3 1 2.776 1 2.5s.261-.5.583-.5zm0 6h12.834c.322 0 .583.224.583.5s-.261.5-.583.5H1.583C1.261 9 1 8.776 1 8.5s.261-.5.583-.5zm0 6h12.834c.322 0 .583.224.583.5s-.261.5-.583.5H1.583C1.261 15 1 14.776 1 14.5s.261-.5.583-.5z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['more-outlined'] = Moreoutlined; // new-colored.svg

var Newcolored = function Newcolored() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, _react["default"].createElement("rect", {
    fill: "#FA8E00",
    width: "16",
    height: "16",
    rx: "2"
  }), _react["default"].createElement("text", {
    fontFamily: "PingFangSC-Regular, PingFang SC",
    fontSize: "12",
    fill: "#FFF"
  }, _react["default"].createElement("tspan", {
    x: "2",
    y: "12"
  }, "\u65B0"))));
};

result['new-colored'] = Newcolored; // operation-auth-outlined.svg

var Operationauthoutlined = function Operationauthoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M6.644 8.55a.498.498 0 01-.23.081 5.424 5.424 0 00-4.914 5.4.5.5 0 11-1 0 6.428 6.428 0 014.257-6.05A4.056 4.056 0 018.322.749a.5.5 0 11-.345.939 3.054 3.054 0 10-1.44 5.897.5.5 0 01.107.966zm3.222.496A4 4 0 0111.717 1.5a4 4 0 011.811 7.568 6.398 6.398 0 012.004 1.021.5.5 0 01-.603.798 5.423 5.423 0 00-8.69 4.328.5.5 0 11-1 0 6.426 6.426 0 014.627-6.169zm1.851-.546a3 3 0 100-6 3 3 0 000 6zm-1.035 5.53h4.086a.5.5 0 010 1h-5a.5.5 0 01-.147-.021.5.5 0 01-.5-.832l2.122-2.121a.5.5 0 01.707.707l-1.268 1.267z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['operation-auth-outlined'] = Operationauthoutlined; // operation-authorization.svg

var Operationauthorization = function Operationauthorization() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("path", {
    d: "M6.644 8.55a.498.498 0 01-.23.081 5.424 5.424 0 00-4.914 5.4.5.5 0 11-1 0 6.428 6.428 0 014.257-6.05A4.056 4.056 0 018.322.749a.5.5 0 11-.345.939 3.054 3.054 0 10-1.44 5.897.5.5 0 01.107.966zm3.222.496A4 4 0 0111.717 1.5a4 4 0 011.811 7.568 6.398 6.398 0 012.004 1.021.5.5 0 01-.603.798 5.423 5.423 0 00-8.69 4.328.5.5 0 11-1 0 6.426 6.426 0 014.627-6.169zm1.851-.546a3 3 0 100-6 3 3 0 000 6zm-1.035 5.53h4.086a.5.5 0 010 1h-5a.5.5 0 01-.147-.021.5.5 0 01-.5-.832l2.122-2.121a.5.5 0 01.707.707l-1.268 1.267z"
  }));
};

result['operation-authorization'] = Operationauthorization; // pen-outlined.svg

var Penoutlined = function Penoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M13.5 14h-11a.5.5 0 000 1h11a.5.5 0 000-1zM9.981 3.289l2.315 2.315-.71.705-2.312-2.313.707-.707z"
  }), _react["default"].createElement("path", {
    d: "M13.59 3.121a1 1 0 010 1.415L6.1 12.024l-3.495.91a.2.2 0 01-.244-.243l.91-3.495 7.492-7.489a1 1 0 011.414 0l1.414 1.414h-.001zm-.707.707l-1.414-1.414L4.172 9.71l-.499 1.913 1.913-.499 7.297-7.296z"
  }));
};

result['pen-outlined'] = Penoutlined; // phone-outlined.svg

var Phoneoutlined = function Phoneoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M6.925 9.075l-.354.353c2.06 2.058 3.446 2.96 4.331 2.078l.26-.263a7.35 7.35 0 01.23-.225c.632-.584 1.072-.566 2.313.432.65.522.852.929.782 1.29-.06.305-.293.617-.785 1.093a46.67 46.67 0 00-.453.435c-.912.912-4.639-.493-7.832-3.686C2.225 7.388.82 3.662 1.732 2.75c.098-.098.454-.471.435-.452.475-.492.788-.726 1.093-.785.362-.07.768.133 1.29.783.998 1.24 1.015 1.681.432 2.313a6.3 6.3 0 01-.225.23l-.26.259c-.887.884.018 2.277 2.074 4.33l.354-.353zm0 0l.353-.354C5.76 7.204 4.988 6.019 5.203 5.805l.258-.256c.105-.103.183-.183.256-.262.943-1.02.902-2.013-.387-3.617C4.596.756 3.844.38 3.07.532c-.572.11-1.01.438-1.623 1.072.011-.012-.334.35-.423.44C-.43 3.5 1.172 7.75 4.71 11.288c3.54 3.539 7.79 5.142 9.246 3.686.089-.088.454-.437.44-.423.634-.612.961-1.05 1.072-1.621.15-.774-.223-1.526-1.136-2.26-1.605-1.29-2.598-1.331-3.618-.387a8.26 8.26 0 00-.263.255l-.255.259c-.214.212-1.396-.557-2.918-2.077l-.353.354z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['phone-outlined'] = Phoneoutlined; // phone.svg

var Phone = function Phone() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("path", {
    d: "M6.925 9.075l-.354.353c2.06 2.058 3.446 2.96 4.331 2.078l.26-.263a7.35 7.35 0 01.23-.225c.632-.584 1.072-.566 2.313.432.65.522.852.929.782 1.29-.06.305-.293.617-.785 1.093a46.67 46.67 0 00-.453.435c-.912.912-4.639-.493-7.832-3.686C2.225 7.388.82 3.662 1.732 2.75c.098-.098.454-.471.435-.452.475-.492.788-.726 1.093-.785.362-.07.768.133 1.29.783.998 1.24 1.015 1.681.432 2.313a6.3 6.3 0 01-.225.23l-.26.259c-.887.884.018 2.277 2.074 4.33l.354-.353zm0 0l.353-.354C5.76 7.204 4.988 6.019 5.203 5.805l.258-.256c.105-.103.183-.183.256-.262.943-1.02.902-2.013-.387-3.617C4.596.756 3.844.38 3.07.532c-.572.11-1.01.438-1.623 1.072.011-.012-.334.35-.423.44C-.43 3.5 1.172 7.75 4.71 11.288c3.54 3.539 7.79 5.142 9.246 3.686.089-.088.454-.437.44-.423.634-.612.961-1.05 1.072-1.621.15-.774-.223-1.526-1.136-2.26-1.605-1.29-2.598-1.331-3.618-.387a8.26 8.26 0 00-.263.255l-.255.259c-.214.212-1.396-.557-2.918-2.077l-.353.354z"
  }));
};

result.phone = Phone; // picture-outlined.svg

var Pictureoutlined = function Pictureoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M7.27 10.211c1.046-.542 3.134-1.925 3.454-2.073.967-.448 2.414-.42 4.276.151V2H1v7.042c2.242 1.986 3.966 2.36 6.27 1.17zM1 14h14V9.337c-1.84-.591-3.115-.635-3.855-.292-.235.109-2.298 1.476-3.416 2.054-2.456 1.27-4.39 1.004-6.729-.765V14zM1 1h14a1 1 0 011 1v12a1 1 0 01-1 1H1a1 1 0 01-1-1V2a1 1 0 011-1zm4.5 7.5a2.5 2.5 0 110-5 2.5 2.5 0 010 5zm0-1a1.5 1.5 0 100-3 1.5 1.5 0 000 3z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['picture-outlined'] = Pictureoutlined; // picture.svg

var Picture = function Picture() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("path", {
    d: "M7.27 10.211c1.046-.542 3.134-1.925 3.454-2.073.967-.448 1.914-.42 3.776.151V2h-13v7.042c2.242 1.986 3.466 2.36 5.77 1.17zM1.5 14h13V9.337c-1.84-.591-2.615-.635-3.355-.292-.235.109-2.298 1.476-3.416 2.054-2.456 1.27-3.89 1.004-6.229-.765V14zm0-13h13a1 1 0 011 1v12a1 1 0 01-1 1h-13a1 1 0 01-1-1V2a1 1 0 011-1zm4 7.5a2.5 2.5 0 110-5 2.5 2.5 0 010 5zm0-1a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
  }));
};

result.picture = Picture; // plus-bold-outlined.svg

var Plusboldoutlined = function Plusboldoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    fill: "currentColor",
    d: "M9 7h6v2H9v6H7V9H1V7h6V1h2z",
    fillRule: "evenodd"
  }));
};

result['plus-bold-outlined'] = Plusboldoutlined; // plus-box.svg

var Plusbox = function Plusbox() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("path", {
    d: "M7.501 7.5V3.998a.5.5 0 011 0V7.5H12a.5.5 0 110 1H8.501v3.508a.5.5 0 11-1 0V8.5H4a.5.5 0 010-1h3.501zM2 2v12h12V2H2zm0-1h12a1 1 0 011 1v12a1 1 0 01-1 1H2a1 1 0 01-1-1V2a1 1 0 011-1z"
  }));
};

result['plus-box'] = Plusbox; // plus-filled.svg

var Plusfilled = function Plusfilled() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, _react["default"].createElement("circle", {
    fill: "currentColor",
    cx: "8",
    cy: "8",
    r: "8"
  }), _react["default"].createElement("path", {
    fill: "#FFF",
    fillRule: "nonzero",
    d: "M9 7h3.5v2H9v3.5H7V9H3.5V7H7V3.5h2z"
  })));
};

result['plus-filled'] = Plusfilled; // plus-outlined.svg

var Plusoutlined = function Plusoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M8.5 7.5h6a.5.5 0 110 1h-6v6a.5.5 0 11-1 0v-6h-6a.5.5 0 010-1h6v-6a.5.5 0 011 0v6z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['plus-outlined'] = Plusoutlined; // plus-square-filled.svg

var Plussquarefilled = function Plussquarefilled() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, _react["default"].createElement("rect", {
    fill: "currentColor",
    x: "1",
    y: "1",
    width: "14",
    height: "14",
    rx: "1"
  }), _react["default"].createElement("path", {
    fill: "#FFF",
    fillRule: "nonzero",
    d: "M9 7h3.5v2H9v3.5H7V9H3.5V7H7V3.5h2z"
  })));
};

result['plus-square-filled'] = Plussquarefilled; // plus-square-outlined.svg

var Plussquareoutlined = function Plussquareoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M7.501 7.5V3.998a.5.5 0 011 0V7.5H12a.5.5 0 110 1H8.501v3.508a.5.5 0 11-1 0V8.5H4a.5.5 0 010-1h3.501zM2 2v12h12V2H2zm0-1h12a1 1 0 011 1v12a1 1 0 01-1 1H2a1 1 0 01-1-1V2a1 1 0 011-1z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['plus-square-outlined'] = Plussquareoutlined; // plus-square.svg

var Plussquare = function Plussquare() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return props.solid || false ? _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("g", {
    fill: "none"
  }, _react["default"].createElement("rect", {
    fill: "currentColor",
    x: "1",
    y: "1",
    width: "14",
    height: "14",
    rx: "1"
  }), _react["default"].createElement("path", {
    fill: "#FFF",
    d: "M9 7h3.5v2H9v3.5H7V9H3.5V7H7V3.5h2z"
  }))) : _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("g", {
    fill: "none"
  }, _react["default"].createElement("rect", {
    fill: "#000",
    x: "1",
    y: "1",
    width: "14",
    height: "14",
    rx: "1"
  }), _react["default"].createElement("path", {
    fill: "#FFF",
    d: "M9 7h3.5v2H9v3.5H7V9H3.5V7H7V3.5h2z"
  })));
};

result['plus-square'] = Plussquare; // plus.svg

var Plus = function Plus() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return props.solid || false ? _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("g", {
    fill: "none"
  }, _react["default"].createElement("circle", {
    fill: "currentColor",
    cx: "8",
    cy: "8",
    r: "8"
  }), _react["default"].createElement("path", {
    fill: "#FFF",
    d: "M9 7h3.5v2H9v3.5H7V9H3.5V7H7V3.5h2z"
  }))) : _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("g", {
    fill: "none"
  }, _react["default"].createElement("circle", {
    fill: "#000",
    cx: "8",
    cy: "8",
    r: "8"
  }), _react["default"].createElement("path", {
    fill: "#FFF",
    d: "M9 7h3.5v2H9v3.5H7V9H3.5V7H7V3.5h2z"
  })));
};

result.plus = Plus; // price-competition-outlined.svg

var Pricecompetitionoutlined = function Pricecompetitionoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M9.9 7.071l4.828 4.828a2 2 0 01-2.829 2.829L7.071 9.899l-.767.768a1.5 1.5 0 01-2.415 1.707L1.061 9.546A1.5 1.5 0 012.768 7.13l4.363-4.363A1.5 1.5 0 019.546 1.06l2.828 2.828a1.5 1.5 0 01-1.707 2.415l-.768.767zm.56-1.975l.638.305a.5.5 0 00.57-.805l-2.83-2.828a.5.5 0 00-.804.569l.305.638L2.975 8.34l-.638-.305a.5.5 0 00-.57.805l2.83 2.828a.5.5 0 00.804-.569l-.305-.638 1.975-1.975 5.536 5.536a1 1 0 001.414-1.414L8.485 7.07l1.975-1.975zm-8.889 9.39h6.93a.5.5 0 110 1H1.57a.5.5 0 010-1z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['price-competition-outlined'] = Pricecompetitionoutlined; // question-filled.svg

var Questionfilled = function Questionfilled() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var id = "spaui-icon-question-filled-".concat(index += 1);
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg",
    xmlnsXlink: "http://www.w3.org/1999/xlink"
  }, props), _react["default"].createElement("defs", null, _react["default"].createElement("path", {
    d: "M8 0a8 8 0 110 16A8 8 0 018 0zm.714 11.429h-2v1.714h2v-1.714zM8 2.857c-1.21 0-2.14.4-2.784 1.073-.74.773-.645 1.6-.645 2.356h1.715v-.32c0-.321.064-.808.571-1.142.245-.161.692-.248 1.144-.253.436-.004.874.069 1.142.253.537.371.571.77.571 1.176 0 .443-.384.872-1.143 1.429-1.15.843-1.714 1.163-1.714 1.714v1.422h1.717l-.002-.54v-.31c0-.234.242-.417 1.142-1.144.823-.664 1.715-1.196 1.715-2.571 0-1.011-.367-1.803-1.005-2.319-.65-.526-1.399-.824-2.424-.824z",
    id: id
  })), _react["default"].createElement("use", {
    fill: "currentColor",
    xlinkHref: "#".concat(id),
    fillRule: "evenodd"
  }));
};

result['question-filled'] = Questionfilled; // question.svg

var Question = function Question() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var id = "spaui-icon-question-".concat(index += 1);
  return props.solid || false ? _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg",
    xmlnsXlink: "http://www.w3.org/1999/xlink"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("defs", null, _react["default"].createElement("path", {
    fill: "currentColor",
    d: "M0 8a8 8 0 1116 0A8 8 0 010 8zm8.571 1.714c0-.233.243-.416 1.143-1.143.823-.664 1.715-1.196 1.715-2.571 0-1.011-.367-1.803-1.005-2.319-.65-.526-1.399-.824-2.424-.824-1.21 0-2.14.4-2.784 1.073-.74.773-.645 1.6-.645 2.356h1.715v-.32c0-.321.064-.808.571-1.142.245-.161.692-.248 1.144-.253.436-.004.874.069 1.142.253.537.371.571.77.571 1.176 0 .443-.384.872-1.143 1.429-1.15.843-1.714 1.163-1.714 1.714v1.422h1.717s-.003-.563-.003-.85zm-1.857 3.429h2v-1.714h-2v1.714z",
    id: id
  })), _react["default"].createElement("use", {
    fill: "currentColor",
    xlinkHref: "#".concat(id)
  })) : _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg",
    xmlnsXlink: "http://www.w3.org/1999/xlink"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("defs", null, _react["default"].createElement("path", {
    d: "M0 8a8 8 0 1116 0A8 8 0 010 8zm8.571 1.714c0-.233.243-.416 1.143-1.143.823-.664 1.715-1.196 1.715-2.571 0-1.011-.367-1.803-1.005-2.319-.65-.526-1.399-.824-2.424-.824-1.21 0-2.14.4-2.784 1.073-.74.773-.645 1.6-.645 2.356h1.715v-.32c0-.321.064-.808.571-1.142.245-.161.692-.248 1.144-.253.436-.004.874.069 1.142.253.537.371.571.77.571 1.176 0 .443-.384.872-1.143 1.429-1.15.843-1.714 1.163-1.714 1.714v1.422h1.717s-.003-.563-.003-.85zm-1.857 3.429h2v-1.714h-2v1.714z",
    id: id
  })), _react["default"].createElement("use", {
    xlinkHref: "#".concat(id)
  }));
};

result.question = Question; // quick-drawing-outlined.svg

var Quickdrawingoutlined = function Quickdrawingoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M10.555 1a1 1 0 01.698.284l2.445 2.383a1 1 0 01.302.716V14a1 1 0 01-1 1H3a1 1 0 01-1-1V2a1 1 0 011-1h7.555zm0 1H3v12h10V4.383L10.555 2zm-.892 2.501a.704.704 0 01.52.2.73.73 0 01.192.71l-.035.1-.606 1.386.605.007.086.008a.71.71 0 01.565.486l.025.098.01.102a.731.731 0 01-.096.388l-.073.103-3.843 4.138a.706.706 0 01-.526.272.715.715 0 01-.737-.696l.006-.108L6.2 9.65l-.961.002a.708.708 0 01-.704-.498l-.025-.102-.01-.098a.735.735 0 01.023-.203l.032-.093L6.06 4.976l.059-.12a.827.827 0 01.522-.346l.087-.008h2.936zm-.411 1H6.924l-1.287 3.15h1.807l-.477 2.157 2.704-2.912-1.458-.015 1.039-2.38z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['quick-drawing-outlined'] = Quickdrawingoutlined; // recommend-colored.svg

var Recommendcolored = function Recommendcolored() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, _react["default"].createElement("path", {
    fill: "#F5323C",
    d: "M2 1h12v14l-5.978-3L2 15z"
  }), _react["default"].createElement("path", {
    fill: "#FFF",
    d: "M8 9l-2.351 1.236.449-2.618-1.902-1.854 2.628-.382L8 3l1.176 2.382 2.628.382-1.902 1.854.45 2.618z"
  })));
};

result['recommend-colored'] = Recommendcolored; // right-arrow-bold-outlined.svg

var Rightarrowboldoutlined = function Rightarrowboldoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "24",
    height: "24",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, _react["default"].createElement("path", {
    d: "M0 0h20v20H0z"
  }), _react["default"].createElement("path", {
    d: "M19.107 12.5l-1.415 1.414L8.5 23.107l-1.414-1.415 9.192-9.192-9.192-9.192L8.5 1.893l9.192 9.193z",
    fill: "currentColor"
  })));
};

result['right-arrow-bold-outlined'] = Rightarrowboldoutlined; // right-arrow-double-outlined.svg

var Rightarrowdoubleoutlined = function Rightarrowdoubleoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("g", {
    fill: "currentColor",
    fillRule: "evenodd"
  }, _react["default"].createElement("path", {
    d: "M2 1.707L2.707 1l6.707 6.707-6.707 6.707L2 13.707l6-6z"
  }), _react["default"].createElement("path", {
    d: "M6.707 1.707L7.414 1l6.707 6.707-6.707 6.707-.707-.707 6-6z"
  })));
};

result['right-arrow-double-outlined'] = Rightarrowdoubleoutlined; // right-arrow-medium-outlined.svg

var Rightarrowmediumoutlined = function Rightarrowmediumoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    fill: "currentColor",
    d: "M4.293 2L5 1.293 11.707 8 5 14.707 4.293 14l6-6z",
    fillRule: "evenodd"
  }));
};

result['right-arrow-medium-outlined'] = Rightarrowmediumoutlined; // right-arrow-outlined.svg

var Rightarrowoutlined = function Rightarrowoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    fill: "currentColor",
    d: "M9.75 7.957l-4.5-4.5.707-.707 5.207 5.207-5.207 5.207-.707-.707z",
    fillRule: "evenodd"
  }));
};

result['right-arrow-outlined'] = Rightarrowoutlined; // rise-colored.svg

var Risecolored = function Risecolored() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M8.283 3.048l4.034 4.035a.4.4 0 01-.283.682H10v5.422a.4.4 0 01-.4.4H6.4a.4.4 0 01-.4-.4V7.765H3.966a.4.4 0 01-.283-.682l4.034-4.035a.4.4 0 01.566 0z",
    fill: "#67C23A",
    fillRule: "evenodd"
  }));
};

result['rise-colored'] = Risecolored; // sapui-icon-dropdown.svg

var Sapuiicondropdown = function Sapuiicondropdown() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("path", {
    fill: "#B2B2B2",
    d: "M8.207 9.5L3.707 5 3 5.707l5.207 5.207 5.207-5.207L12.707 5z",
    fillRule: "evenodd"
  }));
};

result['sapui-icon-dropdown'] = Sapuiicondropdown; // sapui-icon-saveas.svg

var Sapuiiconsaveas = function Sapuiiconsaveas() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M12 2v3.5A1.5 1.5 0 0110.5 7h-5A1.5 1.5 0 014 5.5V2H2v12h7.5a.5.5 0 110 1H2a1 1 0 01-1-1V2a1 1 0 011-1h12a1 1 0 011 1v3.5a.5.5 0 11-1 0V2h-2zm-1 0h-1v2.5a.5.5 0 01-1 0V2H5v3.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5V2zm1.12 6.82a.5.5 0 01.065-.704.502.502 0 01.705.064l1.994 2a.5.5 0 010 .64l-1.994 2.001a.502.502 0 01-.705.064.5.5 0 01-.065-.704L13.43 11H8.502a.5.5 0 110-1h4.926L12.12 8.82z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['sapui-icon-saveas'] = Sapuiiconsaveas; // save-as-copy-outlined.svg

var Saveascopyoutlined = function Saveascopyoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M12 3V2H2v10h1V4a1 1 0 011-1h8zm2 0a1 1 0 011 1v10a1 1 0 01-1 1H4a1 1 0 01-1-1v-1H2a1 1 0 01-1-1V2a1 1 0 011-1h10a1 1 0 011 1v1h1zM5 4H4v10h10V4h-1v2.5A1.5 1.5 0 0111.5 8h-5A1.5 1.5 0 015 6.5V4zm5 0H6v2.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5V4h-1v1.5a.5.5 0 11-1 0V4z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['save-as-copy-outlined'] = Saveascopyoutlined; // save-as-outlined.svg

var Saveasoutlined = function Saveasoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M12 2v3.5A1.5 1.5 0 0110.5 7h-5A1.5 1.5 0 014 5.5V2H2v12h7.5a.5.5 0 110 1H2a1 1 0 01-1-1V2a1 1 0 011-1h12a1 1 0 011 1v3.5a.5.5 0 11-1 0V2h-2zm-1 0h-1v2.5a.5.5 0 01-1 0V2H5v3.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5V2zm1.12 6.82a.5.5 0 01.065-.704.502.502 0 01.705.064l1.994 2a.5.5 0 010 .64l-1.994 2.001a.502.502 0 01-.705.064.5.5 0 01-.065-.704L13.43 11H8.502a.5.5 0 110-1h4.926L12.12 8.82z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['save-as-outlined'] = Saveasoutlined; // save-outlined.svg

var Saveoutlined = function Saveoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M4 2H2v12h12V2h-2v3.5A1.5 1.5 0 0110.5 7h-5A1.5 1.5 0 014 5.5V2zM2 1h12a1 1 0 011 1v12a1 1 0 01-1 1H2a1 1 0 01-1-1V2a1 1 0 011-1zm7 1H5v3.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5V2h-1v2.5a.5.5 0 01-1 0V2z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['save-outlined'] = Saveoutlined; // save-section-outlined.svg

var Savesectionoutlined = function Savesectionoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M12 2v3.5A1.5 1.5 0 0110.5 7h-5A1.5 1.5 0 014 5.5V2H2v12h7.5a.5.5 0 110 1H2a1 1 0 01-1-1V2a1 1 0 011-1h12a1 1 0 011 1v3.5a.5.5 0 11-1 0V2h-2zm-1 0h-1v2.5a.5.5 0 01-1 0V2H5v3.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5V2zm1 8V8.5a.5.5 0 111 0V10h1.5a.5.5 0 110 1H13v1.5a.5.5 0 11-1 0V11h-1.5a.5.5 0 110-1H12z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['save-section-outlined'] = Savesectionoutlined; // save-selected.svg

var Saveselected = function Saveselected() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M12 2v3.5A1.5 1.5 0 0110.5 7h-5A1.5 1.5 0 014 5.5V2H2v12h7.5a.5.5 0 110 1H2a1 1 0 01-1-1V2a1 1 0 011-1h12a1 1 0 011 1v3.5a.5.5 0 11-1 0V2h-2zm-1 0h-1v2.5a.5.5 0 01-1 0V2H5v3.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5V2zm1 8V8.5a.5.5 0 111 0V10h1.5a.5.5 0 110 1H13v1.5a.5.5 0 11-1 0V11h-1.5a.5.5 0 110-1H12z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['save-selected'] = Saveselected; // save.svg

var Save = function Save() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M4 2H2v12h12V2h-2v3.5A1.5 1.5 0 0110.5 7h-5A1.5 1.5 0 014 5.5V2zM2 1h12a1 1 0 011 1v12a1 1 0 01-1 1H2a1 1 0 01-1-1V2a1 1 0 011-1zm7 1H5v3.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5V2h-1v2.5a.5.5 0 01-1 0V2z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result.save = Save; // saveas-copy.svg

var Saveascopy = function Saveascopy() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M13 3V2H2v11h1V4a1 1 0 011-1h9zm1 0a1 1 0 011 1v10a1 1 0 01-1 1H4a1 1 0 01-1-1H2a1 1 0 01-1-1V2a1 1 0 011-1h11a1 1 0 011 1v1zM5 4H4v10h10V4h-1v2.5A1.5 1.5 0 0111.5 8h-5A1.5 1.5 0 015 6.5V4zm5 0H6v2.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5V4h-1v1.5a.5.5 0 11-1 0V4z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['saveas-copy'] = Saveascopy; // saveas.svg

var Saveas = function Saveas() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M12 2v3.5A1.5 1.5 0 0110.5 7h-5A1.5 1.5 0 014 5.5V2H2v12h7.5a.5.5 0 110 1H2a1 1 0 01-1-1V2a1 1 0 011-1h12a1 1 0 011 1v3.5a.5.5 0 11-1 0V2h-2zm-1 0h-1v2.5a.5.5 0 01-1 0V2H5v3.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5V2zm1.12 6.82a.5.5 0 01.065-.704.502.502 0 01.705.064l1.994 2a.5.5 0 010 .64l-1.994 2.001a.502.502 0 01-.705.064.5.5 0 01-.065-.704L13.43 11H8.502a.5.5 0 110-1h4.926L12.12 8.82z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result.saveas = Saveas; // search-outlined.svg

var Searchoutlined = function Searchoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M12.575 11.83l2.925 2.9a.5.5 0 11-.704.711l-2.921-2.896a6.933 6.933 0 11.7-.714zm-5.29 1.45a5.933 5.933 0 100-11.866 5.933 5.933 0 000 11.867z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['search-outlined'] = Searchoutlined; // search.svg

var Search = function Search() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("path", {
    d: "M12.575 11.83l2.925 2.9a.5.5 0 11-.704.711l-2.921-2.896a6.933 6.933 0 11.7-.714zm-5.29 1.45a5.933 5.933 0 100-11.866 5.933 5.933 0 000 11.867z"
  }));
};

result.search = Search; // shield-outlined.svg

var Shieldoutlined = function Shieldoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M12 3V2H2v10h1V4a1 1 0 011-1h8zM3 13H2a1 1 0 01-1-1V2a1 1 0 011-1h10a1 1 0 011 1v1h1a1 1 0 011 1v2.5a.5.5 0 11-1 0V4H4v10h2.5a.5.5 0 110 1H4a1 1 0 01-1-1v-1zm8.5 1a2.5 2.5 0 001.658-4.371l-2.45 4.243c.249.083.515.128.792.128zm.792-4.872a2.5 2.5 0 00-2.45 4.243l2.45-4.243zM11.5 15a3.5 3.5 0 110-7 3.5 3.5 0 010 7z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['shield-outlined'] = Shieldoutlined; // silver-colored.svg

var Silvercolored = function Silvercolored() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("g", {
    fill: "none"
  }, _react["default"].createElement("path", {
    fill: "#DDD",
    d: "M11.96 5.616L8 2 4.04 5.616 0 3.62 2.064 14h11.872L16 3.619z"
  }), _react["default"].createElement("path", {
    fill: "#C2C2C2",
    d: "M11.964 5.616L8.008 2 8 2.007V14h5.938L16 3.619z"
  })));
};

result['silver-colored'] = Silvercolored; // sort-outlined.svg

var Sortoutlined = function Sortoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M15.147 11.146a.5.5 0 01.706.708l-3 2.989a.5.5 0 01-.853-.354V2.5a.5.5 0 111 0v10.785l2.147-2.14zM.5 3h9a.5.5 0 010 1h-9a.5.5 0 010-1zm0 5h9a.5.5 0 010 1h-9a.5.5 0 010-1zm0 5h9a.5.5 0 110 1h-9a.5.5 0 110-1z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['sort-outlined'] = Sortoutlined; // sort.svg

var Sort = function Sort() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("path", {
    d: "M15.147 11.146a.5.5 0 01.706.708l-3 2.989a.5.5 0 01-.853-.354V2.5a.5.5 0 111 0v10.785l2.147-2.14zM.5 3h9a.5.5 0 010 1h-9a.5.5 0 010-1zm0 5h9a.5.5 0 010 1h-9a.5.5 0 010-1zm0 5h9a.5.5 0 110 1h-9a.5.5 0 110-1z"
  }));
};

result.sort = Sort; // sound-off-outlined.svg

var Soundoffoutlined = function Soundoffoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M3.996 3.346a4.67 4.67 0 00.325-.192c.358-.228.86-.585 1.525-1.096A62.04 62.04 0 007.496.743 1.026 1.026 0 018.619.61c.358.18.581.55.58.953v12.875c0 .414-.234.792-.605.966a1.02 1.02 0 01-1.13-.177 50.53 50.53 0 00-1.481-1.311 19.155 19.155 0 00-1.362-1.078 4.053 4.053 0 00-.282-.183H.943A.953.953 0 010 11.69V4.31c0-.528.418-.963.943-.963H1.9l.047-.002h2.05zm2.46-.495c-1.23.944-1.888 1.362-2.193 1.468l-.163.027H1.962l-.047.002H1v7.306h3.452l.176.032c.298.112.903.534 2.001 1.467a51.506 51.506 0 011.511 1.338c.007.006.014.009.019.009a.02.02 0 00.008-.002c.017-.008.031-.031.031-.06V1.56c0-.028-.013-.05-.029-.058-.008-.005-.016-.004-.026.003-.19.162-1.03.84-1.688 1.345zM14.071 8l1.768 1.767a.5.5 0 01-.707.707l-1.768-1.768-1.767 1.768a.5.5 0 11-.708-.707L12.658 8 10.89 6.232a.5.5 0 01.708-.707l1.767 1.768 1.768-1.768a.5.5 0 01.707.707L14.072 8z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['sound-off-outlined'] = Soundoffoutlined; // sound-off.svg

var Soundoff = function Soundoff() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("path", {
    d: "M3.996 3.346a4.67 4.67 0 00.325-.192c.358-.228.86-.585 1.525-1.096A62.04 62.04 0 007.496.743 1.026 1.026 0 018.619.61c.358.18.581.55.58.953v12.875c0 .414-.234.792-.605.966a1.02 1.02 0 01-1.13-.177 50.53 50.53 0 00-1.481-1.311 19.155 19.155 0 00-1.362-1.078 4.053 4.053 0 00-.282-.183H.943A.953.953 0 010 11.69V4.31c0-.528.418-.963.943-.963H1.9l.047-.002h2.05zm2.46-.495c-1.23.944-1.888 1.362-2.193 1.468l-.163.027H1.962l-.047.002H1v7.306h3.452l.176.032c.298.112.903.534 2.001 1.467a51.506 51.506 0 011.511 1.338c.007.006.014.009.019.009a.02.02 0 00.008-.002c.017-.008.031-.031.031-.06V1.56c0-.028-.013-.05-.029-.058-.008-.005-.016-.004-.026.003-.19.162-1.03.84-1.688 1.345zM14.071 8l1.768 1.767a.5.5 0 01-.707.707l-1.768-1.768-1.767 1.768a.5.5 0 11-.708-.707L12.658 8 10.89 6.232a.5.5 0 01.708-.707l1.767 1.768 1.768-1.768a.5.5 0 01.707.707L14.072 8z"
  }));
};

result['sound-off'] = Soundoff; // sound-on-outlined.svg

var Soundonoutlined = function Soundonoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M9.118.609c.358.18.581.55.58.953v12.875c0 .414-.234.792-.605.966a1.02 1.02 0 01-1.13-.177 50.53 50.53 0 00-1.481-1.311 19.155 19.155 0 00-1.362-1.078 4.053 4.053 0 00-.282-.183H1.443A.953.953 0 01.5 11.69V4.31c0-.528.418-.963.943-.963H2.4l.047-.002h2.05a4.72 4.72 0 00.324-.192c.358-.228.86-.585 1.525-1.096A62.04 62.04 0 007.996.743 1.026 1.026 0 019.119.61zm-.475.897c-.19.162-1.03.84-1.688 1.345-1.23.944-1.887 1.362-2.192 1.468l-.163.027H2.462l-.047.002H1.5v7.306h3.452l.176.032c.298.112.903.534 2.001 1.467a51.506 51.506 0 011.511 1.338c.007.006.014.009.019.009a.02.02 0 00.008-.002c.017-.008.031-.031.031-.06V1.56c0-.028-.013-.05-.029-.058-.008-.005-.016-.004-.026.003zm5.296 1.632a6.482 6.482 0 012.023 4.712 6.482 6.482 0 01-2.018 4.707.5.5 0 01-.69-.724 5.482 5.482 0 001.708-3.983 5.482 5.482 0 00-1.712-3.988.5.5 0 11.689-.724zm-2.1 2.144a3.491 3.491 0 011.123 2.568c0 .984-.41 1.904-1.115 2.561a.5.5 0 11-.682-.731c.505-.47.797-1.126.797-1.83a2.49 2.49 0 00-.802-1.834.5.5 0 11.68-.734z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['sound-on-outlined'] = Soundonoutlined; // sound-on.svg

var Soundon = function Soundon() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("path", {
    d: "M4.496 3.346a4.67 4.67 0 00.325-.192c.358-.228.86-.585 1.525-1.096A62.04 62.04 0 007.996.743 1.026 1.026 0 019.119.61c.358.18.581.55.58.953v12.875c0 .414-.234.792-.605.966a1.02 1.02 0 01-1.13-.177 50.53 50.53 0 00-1.481-1.311 19.155 19.155 0 00-1.362-1.078 4.053 4.053 0 00-.282-.183H1.443A.953.953 0 01.5 11.69V4.31c0-.528.418-.963.943-.963H2.4l.047-.002h2.05zm2.46-.495c-1.23.944-1.888 1.362-2.193 1.468l-.163.027H2.462l-.047.002H1.5v7.306h3.452l.176.032c.298.112.903.534 2.001 1.467a51.506 51.506 0 011.511 1.338c.007.006.014.009.019.009a.02.02 0 00.008-.002c.017-.008.031-.031.031-.06V1.56c0-.028-.013-.05-.029-.058-.008-.005-.016-.004-.026.003-.19.162-1.03.84-1.688 1.345zm4.891 7.56a.5.5 0 11-.682-.731c.505-.47.797-1.126.797-1.83a2.49 2.49 0 00-.802-1.834.5.5 0 11.68-.734 3.491 3.491 0 011.122 2.568c0 .984-.41 1.904-1.115 2.561zm2.097 2.146a.5.5 0 01-.69-.724 5.482 5.482 0 001.708-3.983 5.482 5.482 0 00-1.712-3.988.5.5 0 11.689-.724 6.482 6.482 0 012.023 4.712 6.482 6.482 0 01-2.018 4.707z"
  }));
};

result['sound-on'] = Soundon; // spaui-icon-big-error.svg

var Spauiiconbigerror = function Spauiiconbigerror() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "40",
    height: "40",
    viewBox: "0 0 40 40",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("mask", {
    id: "a",
    maskUnits: "userSpaceOnUse",
    x: "0",
    y: "0",
    width: "40",
    height: "40"
  }, _react["default"].createElement("path", {
    d: "M20 40c11.046 0 20-8.954 20-20S31.046 0 20 0 0 8.954 0 20s8.954 20 20 20z",
    fill: "#fff"
  })), _react["default"].createElement("g", {
    mask: "url(#a)"
  }, _react["default"].createElement("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M40 20c0 11.046-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0s20 8.954 20 20zM12.5 9.672l1.414 1.414L20 17.172l6.086-6.086L27.5 9.672l2.828 2.828-1.414 1.414L22.828 20l6.086 6.086 1.414 1.414-2.828 2.828-1.414-1.414L20 22.828l-6.086 6.086-1.414 1.414L9.672 27.5l1.414-1.414L17.172 20l-6.086-6.086L9.672 12.5 12.5 9.672z",
    fill: "#F64B4B"
  })));
};

result['spaui-icon-big-error'] = Spauiiconbigerror; // spaui-icon-big-remind.svg

var Spauiiconbigremind = function Spauiiconbigremind() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "40",
    height: "40",
    viewBox: "0 0 40 40",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M40 20c0 11.046-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0s20 8.954 20 20zm-18-9V9h-4v14h4V11zm0 17v-2h-4v5h4v-3z",
    fill: "#F9BA00"
  }));
};

result['spaui-icon-big-remind'] = Spauiiconbigremind; // spaui-icon-big-success.svg

var Spauiiconbigsuccess = function Spauiiconbigsuccess() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "40",
    height: "40",
    viewBox: "0 0 40 40",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M40 20c0 11.046-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0s20 8.954 20 20zm-21.086 6.664l12.5-12.5-2.828-2.828L17.52 22.4l-6.605-6.795-2.868 2.788 8.019 8.25 1.414 1.455 1.434-1.435z",
    fill: "#42B847"
  }));
};

result['spaui-icon-big-success'] = Spauiiconbigsuccess; // spaui-icon-big-tip.svg

var Spauiiconbigtip = function Spauiiconbigtip() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "40",
    height: "40",
    viewBox: "0 0 40 40",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M40 20c0 11.046-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0s20 8.954 20 20zm-18-9V9h-4v5h4v-3zm0 8v-2h-4v14h4V19z",
    fill: "#296bef"
  }));
};

result['spaui-icon-big-tip'] = Spauiiconbigtip; // spaui-icon-big-warning.svg

var Spauiiconbigwarning = function Spauiiconbigwarning() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "40",
    height: "40",
    viewBox: "0 0 40 40",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M40 20c0 11.046-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0s20 8.954 20 20zm-18 1V11h-4v10h4zm0 7v-2h-4v5h4v-3z",
    fill: "#F64B4B"
  }));
};

result['spaui-icon-big-warning'] = Spauiiconbigwarning; // spaui-icon-checkmark.svg

var Spauiiconcheckmark = function Spauiiconcheckmark() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "13",
    viewBox: "0 0 16 13",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M1.735 5.167L.265 6.524l5.497 5.95 9.973-10.795-1.47-1.357-8.503 9.204-4.027-4.36z",
    fill: "#296bef"
  }));
};

result['spaui-icon-checkmark'] = Spauiiconcheckmark; // spaui-icon-clear.svg

var Spauiiconclear = function Spauiiconclear() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    viewBox: "0 0 16 16",
    width: "16",
    height: "16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("circle", {
    fill: "currentColor",
    fillRule: "evenodd",
    cx: "8",
    cy: "8",
    r: "8"
  }), _react["default"].createElement("path", {
    d: "M5.333 5.333l5.334 5.334m0-5.334l-5.334 5.334",
    stroke: "#FFF",
    strokeLinecap: "square"
  }));
};

result['spaui-icon-clear'] = Spauiiconclear; // spaui-icon-date.svg

var Spauiicondate = function Spauiicondate() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "17",
    height: "17",
    viewBox: "0 0 17 17",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("path", {
    d: "M12 2V0h2v2h3v15H0V2h3V0h2v2h7zM1 4v12h15V4H1zm13 2v2h-3V6h3zm-4 0v2H7V6h3zm4 3v2h-3V9h3zm-4 0v2H7V9h3zM6 9v2H3V9h3zm8 3v2h-3v-2h3zm-4 0v2H7v-2h3zm-4 0v2H3v-2h3z",
    fill: "#B8BCC2"
  }));
};

result['spaui-icon-date'] = Spauiicondate; // spaui-icon-loading-ani-blue.svg

var Spauiiconloadinganiblue = function Spauiiconloadinganiblue() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    viewBox: "0 0 64 64",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("defs", null, _react["default"].createElement("linearGradient", {
    id: "a",
    gradientUnits: "userSpaceOnUse",
    x1: "55",
    y1: "46",
    x2: "2",
    y2: "46"
  }, _react["default"].createElement("stop", {
    offset: ".1",
    stopColor: "#1b90ee",
    stopOpacity: "0"
  }), _react["default"].createElement("stop", {
    offset: "1",
    stopColor: "#1b90ee"
  }))), _react["default"].createElement("g", {
    strokeWidth: "4",
    strokeLinecap: "round",
    fill: "none",
    transform: "rotate(318.635 32 32)",
    stroke: "#1b90ee"
  }, _react["default"].createElement("path", {
    stroke: "url(#a)",
    d: "M4 32c0 15 12 28 28 28 8 0 16-4 21-9"
  }), _react["default"].createElement("path", {
    d: "M60 32C60 16 47.464 4 32 4S4 16 4 32"
  }), _react["default"].createElement("animateTransform", {
    values: "0,32,32;360,32,32",
    attributeName: "transform",
    type: "rotate",
    repeatCount: "indefinite",
    dur: "750ms"
  })));
};

result['spaui-icon-loading-ani-blue'] = Spauiiconloadinganiblue; // spaui-icon-loading-ani-white.svg

var Spauiiconloadinganiwhite = function Spauiiconloadinganiwhite() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    viewBox: "0 0 64 64",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("defs", null, _react["default"].createElement("linearGradient", {
    id: "a",
    gradientUnits: "userSpaceOnUse",
    x1: "55",
    y1: "46",
    x2: "2",
    y2: "46"
  }, _react["default"].createElement("stop", {
    offset: ".1",
    stopColor: "#fff",
    stopOpacity: "0"
  }), _react["default"].createElement("stop", {
    offset: "1",
    stopColor: "#fff"
  }))), _react["default"].createElement("g", {
    strokeWidth: "4",
    strokeLinecap: "round",
    fill: "none",
    transform: "rotate(318.635 32 32)",
    stroke: "#fff"
  }, _react["default"].createElement("path", {
    stroke: "url(#a)",
    d: "M4 32c0 15 12 28 28 28 8 0 16-4 21-9"
  }), _react["default"].createElement("path", {
    d: "M60 32C60 16 47.464 4 32 4S4 16 4 32"
  }), _react["default"].createElement("animateTransform", {
    values: "0,32,32;360,32,32",
    attributeName: "transform",
    type: "rotate",
    repeatCount: "indefinite",
    dur: "750ms"
  })));
};

result['spaui-icon-loading-ani-white'] = Spauiiconloadinganiwhite; // spaui-icon-save-selected.svg

var Spauiiconsaveselected = function Spauiiconsaveselected() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M12 2v3.5A1.5 1.5 0 0110.5 7h-5A1.5 1.5 0 014 5.5V2H2v12h7.5a.5.5 0 110 1H2a1 1 0 01-1-1V2a1 1 0 011-1h12a1 1 0 011 1v3.5a.5.5 0 11-1 0V2h-2zm-1 0h-1v2.5a.5.5 0 01-1 0V2H5v3.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5V2zm1 8V8.5a.5.5 0 111 0V10h1.5a.5.5 0 110 1H13v1.5a.5.5 0 11-1 0V11h-1.5a.5.5 0 110-1H12z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['spaui-icon-save-selected'] = Spauiiconsaveselected; // spaui-icon-save.svg

var Spauiiconsave = function Spauiiconsave() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("path", {
    d: "M4 2H2v12h12V2h-2v3.5A1.5 1.5 0 0110.5 7h-5A1.5 1.5 0 014 5.5V2zM2 1h12a1 1 0 011 1v12a1 1 0 01-1 1H2a1 1 0 01-1-1V2a1 1 0 011-1zm7 1H5v3.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5V2h-1v2.5a.5.5 0 01-1 0V2z",
    fill: "#333",
    fillRule: "evenodd"
  }));
};

result['spaui-icon-save'] = Spauiiconsave; // spaui-icon-saveas-copy.svg

var Spauiiconsaveascopy = function Spauiiconsaveascopy() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M13 3V2H2v11h1V4a1 1 0 011-1h9zm1 0a1 1 0 011 1v10a1 1 0 01-1 1H4a1 1 0 01-1-1H2a1 1 0 01-1-1V2a1 1 0 011-1h11a1 1 0 011 1v1zM5 4H4v10h10V4h-1v2.5A1.5 1.5 0 0111.5 8h-5A1.5 1.5 0 015 6.5V4zm5 0H6v2.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5V4h-1v1.5a.5.5 0 11-1 0V4z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['spaui-icon-saveas-copy'] = Spauiiconsaveascopy; // spaui-icon-time.svg

var Spauiicontime = function Spauiicontime() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "18",
    height: "18",
    viewBox: "0 0 18 18",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("g", {
    stroke: "#B8BCC2",
    fill: "none",
    fillRule: "evenodd"
  }, _react["default"].createElement("circle", {
    cx: "9",
    cy: "9",
    r: "8.5"
  }), _react["default"].createElement("path", {
    strokeWidth: "2",
    strokeLinecap: "square",
    d: "M9 5v4l3 3"
  })));
};

result['spaui-icon-time'] = Spauiicontime; // spaui-icon-viewer.svg

var Spauiiconviewer = function Spauiiconviewer() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    xmlns: "http://www.w3.org/2000/svg",
    width: "15",
    height: "15"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("g", {
    fill: "none",
    fillRule: "evenodd",
    stroke: "#848A93"
  }, _react["default"].createElement("circle", {
    cx: "6.5",
    cy: "6.5",
    r: "6"
  }), _react["default"].createElement("path", {
    strokeLinecap: "square",
    d: "M11 11l3.5 3.5"
  })));
};

result['spaui-icon-viewer'] = Spauiiconviewer; // special-colored.svg

var Specialcolored = function Specialcolored() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, _react["default"].createElement("rect", {
    fill: "#296BEF",
    width: "16",
    height: "16",
    rx: "2"
  }), _react["default"].createElement("text", {
    fontFamily: "PingFangSC-Regular, PingFang SC",
    fontSize: "12",
    fill: "#FFF"
  }, _react["default"].createElement("tspan", {
    x: "2",
    y: "12"
  }, "\u4E13"))));
};

result['special-colored'] = Specialcolored; // spline-outlined.svg

var Splineoutlined = function Splineoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M2 1h12a1 1 0 011 1v12a1 1 0 01-1 1H2a1 1 0 01-1-1V2a1 1 0 011-1zm0 1v12h12V2H2zm6.584 8.277L6.976 7.865 4.4 11.3a.5.5 0 01-.8-.6l3-4a.5.5 0 01.816.023l1.562 2.342 2.593-4.322a.5.5 0 01.858.514l-3 5a.5.5 0 01-.845.02z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['spline-outlined'] = Splineoutlined; // split-outlined.svg

var Splitoutlined = function Splitoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M6.003 1a1 1 0 011 1v12a1 1 0 01-1 1h-4a1 1 0 01-1-1v-1.5a.5.5 0 111 0V14h4V8.5H2.73l1.25 1.25a.5.5 0 01-.638.765l-.07-.058-2.12-2.121a.498.498 0 01-.065-.63l.064-.078 2.121-2.12a.5.5 0 01.765.637l-.057.07L2.695 7.5h3.308V2h-4v1.5a.5.5 0 01-1 0V2a1 1 0 011-1h4zM10 14h4v-1.5a.5.5 0 01.992-.09l.008.09V14a1 1 0 01-.883.993L14 15h-4a1 1 0 01-.993-.883L9 14V2a1 1 0 01.883-.993L10 1h4a1 1 0 01.993.883L15 2v1.5a.5.5 0 01-.992.09L14 3.5V2h-4v5.5h3.31l-1.285-1.286a.5.5 0 11.707-.707l2.122 2.121a.498.498 0 010 .708l-2.122 2.12a.5.5 0 11-.707-.706l1.25-1.25H10V14z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['split-outlined'] = Splitoutlined; // star-colored.svg

var Starcolored = function Starcolored() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg",
    color: "#FA8E00"
  }, props), _react["default"].createElement("path", {
    d: "M5.593 5.249L7.59.802c.225-.502.589-.506.816 0l1.997 4.447 4.882.608c.551.068.666.415.248.781l-3.669 3.217 1.395 5.123c.145.532-.117.736-.6.445l-4.66-2.801-4.66 2.801c-.478.287-.745.081-.601-.445l1.394-5.123L.463 6.638c-.414-.363-.308-.712.248-.781l4.882-.608z",
    fill: "currentColor"
  }));
};

result['star-colored'] = Starcolored; // sunburst-outlined.svg

var Sunburstoutlined = function Sunburstoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M14.127 11.387l-2.599-1.5A4 4 0 118 4V.999a7 7 0 106.127 10.387zm.422-.91A7.002 7.002 0 009 1.071l.001 6.2 5.55 3.204zM8.002 7.541A.507.507 0 018 7.5V5a3 3 0 102.661 4.386l-2.41-1.391a.5.5 0 01-.25-.453zm.66-7.515a8 8 0 11-.26-.016.502.502 0 01.262.017z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['sunburst-outlined'] = Sunburstoutlined; // temporary-colored.svg

var Temporarycolored = function Temporarycolored() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, _react["default"].createElement("rect", {
    fill: "#E63D2E",
    width: "16",
    height: "16",
    rx: "2"
  }), _react["default"].createElement("text", {
    fontFamily: "PingFangSC-Regular, PingFang SC",
    fontSize: "12",
    fill: "#FFF"
  }, _react["default"].createElement("tspan", {
    x: "2",
    y: "12"
  }, "\u4E34"))));
};

result['temporary-colored'] = Temporarycolored; // time-outlined.svg

var Timeoutlined = function Timeoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M8.5 8h4.009a.5.5 0 110 1H8a.5.5 0 01-.5-.527V3.5a.5.5 0 011 0V8zM8 15A7 7 0 108 1a7 7 0 000 14zm0 1A8 8 0 118 0a8 8 0 010 16z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['time-outlined'] = Timeoutlined; // time.svg

var Time = function Time() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("path", {
    d: "M8.5 8h4.009a.5.5 0 110 1H8a.5.5 0 01-.5-.527V3.5a.5.5 0 011 0V8zM8 15A7 7 0 108 1a7 7 0 000 14zm0 1A8 8 0 118 0a8 8 0 010 16z"
  }));
};

result.time = Time; // transfer-accounts-outlined.svg

var Transferaccountsoutlined = function Transferaccountsoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M6.997 12.38c-.602-.185-1.137-.534-1.605-1.002a4.585 4.585 0 01-.844-1.133.5.5 0 11.894-.447 4.04 4.04 0 00.658.873c.54.54 1.171.857 1.9.857 1.586 0 2.505-.69 2.505-1.506 0-.817-.919-1.507-2.505-1.507-2.087 0-3.505-1.065-3.505-2.506 0-1.196.977-2.133 2.502-2.417v-.59a.5.5 0 011 0v.5H8c1.024 0 1.895.437 2.608 1.15.418.42.697.839.845 1.134a.5.5 0 11-.895.446A4.04 4.04 0 009.9 5.36c-.54-.54-1.17-.856-1.9-.856-1.586 0-2.505.69-2.505 1.506 0 .816.919 1.506 2.505 1.506 2.087 0 3.505 1.066 3.505 2.507 0 1.44-1.418 2.506-3.505 2.506h-.003v.475a.5.5 0 11-1 0v-.624zm7.62-2.085c.79-2.489.232-5.28-1.695-7.206C10.924 1.09 7.998.564 5.442 1.487a.507.507 0 01-.564-.1A.514.514 0 014.89.66c.08-.081.18-.13.284-.149 3.075-1.03 6.112-.545 8.491 1.834 2.414 2.414 2.95 6.013 1.634 9.043a.537.537 0 01-.128.204.518.518 0 01-.732.012l-1.44-1.44a.518.518 0 01.01-.732.518.518 0 01.733-.012l.874.875zM1.303 5.95a7 7 0 009 8.652.497.497 0 11.275.947c-2.955.95-5.898.434-8.234-1.902A8.003 8.003 0 01.628 4.883a.5.5 0 01.828-.195L2.87 6.102a.5.5 0 11-.707.707l-.859-.858z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['transfer-accounts-outlined'] = Transferaccountsoutlined; // transfer-rmb-outlined.svg

var Transferrmboutlined = function Transferrmboutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M8.345 6l1.472-2.549a.5.5 0 01.866.5L9.5 6h2a.5.5 0 010 1h-3v2h3a.5.5 0 010 1h-3v2.5a.5.5 0 01-1 0V10h-3a.5.5 0 010-1h3V7h-3a.5.5 0 110-1h2L5.317 3.951a.5.5 0 11.866-.5L7.655 6h.69zm6.273 4.292c.791-2.489.233-5.28-1.694-7.206C10.926 1.088 8 .56 5.444 1.484a.507.507 0 01-.564-.1.514.514 0 01.012-.727c.08-.08.18-.13.284-.149 3.075-1.03 6.112-.545 8.491 1.834 2.414 2.414 2.949 6.013 1.634 9.043a.537.537 0 01-.129.204.518.518 0 01-.732.012L13 10.161a.518.518 0 01.012-.732.518.518 0 01.731-.012l.875.875zM1.306 5.948a7 7 0 009 8.652.497.497 0 11.275.947c-2.956.95-5.899.434-8.234-1.902A8.003 8.003 0 01.63 4.88a.5.5 0 01.828-.195L2.872 6.1a.5.5 0 11-.707.707l-.859-.858z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['transfer-rmb-outlined'] = Transferrmboutlined; // triangle-down-filled.svg

var Triangledownfilled = function Triangledownfilled() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M7.414 10.268a.75.75 0 001.172 0l2.44-3.05A.75.75 0 0010.44 6H5.56a.75.75 0 00-.585 1.219l2.44 3.049z",
    fill: "currentColor"
  }));
};

result['triangle-down-filled'] = Triangledownfilled; // triangle-down-outlined.svg

var Triangledownoutlined = function Triangledownoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M7.414 10.268a.75.75 0 001.172 0l2.44-3.05A.75.75 0 0010.44 6H5.56a.75.75 0 00-.585 1.219l2.44 3.049z",
    fill: "currentColor"
  }));
};

result['triangle-down-outlined'] = Triangledownoutlined; // triangle-left-outlined.svg

var Triangleleftoutlined = function Triangleleftoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    fill: "currentColor",
    d: "M5 8l5-4v8z",
    fillRule: "evenodd"
  }));
};

result['triangle-left-outlined'] = Triangleleftoutlined; // triangle-right-outlined.svg

var Trianglerightoutlined = function Trianglerightoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    fill: "currentColor",
    d: "M11 8L6 4v8z",
    fillRule: "evenodd"
  }));
};

result['triangle-right-outlined'] = Trianglerightoutlined; // update-logs-outlined.svg

var Updatelogsoutlined = function Updatelogsoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M2.043 1H14a1 1 0 011 1v12a1 1 0 01-1 1H2.043a1 1 0 01-1-1V2a1 1 0 011-1zm0 1v12H14V2H2.043zM4.5 5h4a.5.5 0 010 1h-4a.5.5 0 010-1zm0 3h3a.5.5 0 010 1h-3a.5.5 0 010-1zm0 3h2a.5.5 0 110 1h-2a.5.5 0 110-1zm7.312-6.87a.5.5 0 01.354.612l-1.812 6.761a.5.5 0 11-.966-.259L11.2 4.483a.5.5 0 01.612-.354z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['update-logs-outlined'] = Updatelogsoutlined; // upload-outlined.svg

var Uploadoutlined = function Uploadoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M7.5 2.746L3.646 6.6a.5.5 0 01-.707-.707l4.707-4.707a.5.5 0 01.708 0l4.707 4.707a.5.5 0 01-.707.707L8.5 2.746v8.397a.5.5 0 11-1 0V2.746zM14 14v-2a.5.5 0 111 0v2.5a.5.5 0 01-.5.5h-13a.5.5 0 01-.5-.5V12a.5.5 0 111 0v2h12z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['upload-outlined'] = Uploadoutlined; // video-outlined.svg

var Videooutlined = function Videooutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M13.7 2c.345 0 .675.126.92.351.243.226.38.53.38.849v9.6c0 .318-.137.623-.38.849a1.357 1.357 0 01-.92.351H2.3a1.36 1.36 0 01-.92-.351A1.155 1.155 0 011 12.8V3.2c0-.318.137-.623.38-.849A1.36 1.36 0 012.3 2h11.4zM2 5v7.8c0 .036.018.076.059.114a.34.34 0 00.17.08L2.3 13h11.4a.359.359 0 00.241-.086.186.186 0 00.053-.077L14 12.8V5H2zm4.5 1.936a.5.5 0 01.705-.457l.072.041 3 2a.5.5 0 01.074.772l-.074.06-3 2a.5.5 0 01-.77-.333l-.007-.083v-4zm1 .935V10l1.597-1.064L7.5 7.871zM14 4v-.8c0-.036-.018-.076-.059-.114a.34.34 0 00-.17-.08L13.7 3H12v1h2zM2 4h9V3H8.5v1h-1V3H5v1H4V3H2.3a.359.359 0 00-.241.086.186.186 0 00-.053.077L2 3.2V4z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['video-outlined'] = Videooutlined; // video-play-outlined.svg

var Videoplayoutlined = function Videoplayoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M8 15A7 7 0 108 1a7 7 0 000 14zm0 1A8 8 0 118 0a8 8 0 010 16zm3-8L6 5v6l5-3zm.537.808l-5.031 3.047a1.003 1.003 0 01-1.356-.303.931.931 0 01-.15-.505V4.953C5 4.427 5.44 4 5.984 4c.185 0 .365.05.522.145l5.031 3.047a.936.936 0 010 1.616z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['video-play-outlined'] = Videoplayoutlined; // video-play.svg

var Videoplay = function Videoplay() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("path", {
    d: "M8 15A7 7 0 108 1a7 7 0 000 14zm0 1A8 8 0 118 0a8 8 0 010 16zm3-8L6 5v6l5-3zm.537.808l-5.031 3.047a1.003 1.003 0 01-1.356-.303.931.931 0 01-.15-.505V4.953C5 4.427 5.44 4 5.984 4c.185 0 .365.05.522.145l5.031 3.047a.936.936 0 010 1.616z"
  }));
};

result['video-play'] = Videoplay; // video-screeshots-outlined.svg

var Videoscreeshotsoutlined = function Videoscreeshotsoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M15 13v1h-1v1h-1v-1H2.99a.996.996 0 01-.986-.883L1.997 13l.008-10H1V2h1.006l.001-1H3v2h-.002L2.99 13H15zM6 5.5a.5.5 0 01.692-.462l.073.038 4 2.5a.5.5 0 01.072.794l-.072.054-4 2.5a.5.5 0 01-.759-.342L6 10.5v-5zM13 2a1 1 0 01.993.883L14 3v7.996h-1V3H5.034V2H13zM7 6.401v3.197L9.557 8 7 6.401z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['video-screeshots-outlined'] = Videoscreeshotsoutlined; // voucher-outlined.svg

var Voucheroutlined = function Voucheroutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M5.5 0h5a1.5 1.5 0 011.415 1H13a1 1 0 011 1v13a1 1 0 01-1 1H3.043a1 1 0 01-1-1V2a1 1 0 011-1h1.042A1.5 1.5 0 015.5 0zM3.043 2v13H13V2h-1.085A1.5 1.5 0 0110.5 3h-5a1.5 1.5 0 01-1.415-1H3.043zM5.5 1a.5.5 0 000 1h5a.5.5 0 100-1h-5zm0 4h5a.5.5 0 110 1h-5a.5.5 0 010-1zm0 3h5a.5.5 0 110 1h-5a.5.5 0 010-1zm0 3h3a.5.5 0 110 1h-3a.5.5 0 110-1z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['voucher-outlined'] = Voucheroutlined; // welfare-outlined.svg

var Welfareoutlined = function Welfareoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M4.41 5c-.852-.491-1.354-1.203-1.354-2.177C3.056 1.356 4.278.488 5.79.528c.905.024 1.715.366 2.209.94.494-.574 1.304-.916 2.21-.94 1.512-.04 2.734.828 2.734 2.295 0 .974-.502 1.686-1.355 2.177h2.404C14.55 5 15 5.448 15 6v3a1 1 0 01-1 1v4.997A.996.996 0 0112.998 16H3.002A1.007 1.007 0 012 14.997V10c-.554-.004-1-.45-1-1V6a1 1 0 011.007-1H4.41zM7.5 2.823c0-.733-.737-1.27-1.735-1.295-1.007-.027-1.71.471-1.71 1.295 0 .818.66 1.377 1.859 1.715.454.129.946.212 1.438.258l.148.016V2.823zm1 0V4.81l.148-.013c.492-.046.984-.13 1.438-.258 1.199-.338 1.858-.897 1.858-1.715 0-.824-.702-1.322-1.709-1.295-.998.026-1.735.562-1.735 1.295zM7.513 9V6H2v3h5.513zm1 0H14V6H8.513v3zm-1 5.998v-4.997L3.003 10 3 14.997l4.513.001zm1 0l4.485.002.002-4.997-4.487-.001v4.997z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['welfare-outlined'] = Welfareoutlined; // welfare.svg

var Welfare = function Welfare() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("title", null, props.title || ''), _react["default"].createElement("path", {
    d: "M4.41 5c-.852-.491-1.354-1.203-1.354-2.177C3.056 1.356 4.278.488 5.79.528c.905.024 1.715.366 2.209.94.494-.574 1.304-.916 2.21-.94 1.512-.04 2.734.828 2.734 2.295 0 .974-.502 1.686-1.355 2.177h2.404C14.55 5 15 5.448 15 6v3a1 1 0 01-1 1v4.997A.996.996 0 0112.998 16H3.002A1.007 1.007 0 012 14.997V10c-.554-.004-1-.45-1-1V6a1 1 0 011.007-1H4.41zM7.5 2.823c0-.733-.737-1.27-1.735-1.295-1.007-.027-1.71.471-1.71 1.295 0 .818.66 1.377 1.859 1.715.454.129.946.212 1.438.258l.148.016V2.823zm1 0V4.81l.148-.013c.492-.046.984-.13 1.438-.258 1.199-.338 1.858-.897 1.858-1.715 0-.824-.702-1.322-1.709-1.295-.998.026-1.735.562-1.735 1.295zM7.513 9V6H2v3h5.513zm1 0H14V6H8.513v3zm-1 5.998v-4.997L3.003 10 3 14.997l4.513.001zm1 0l4.485.002.002-4.997-4.487-.001v4.997z"
  }));
};

result.welfare = Welfare; // zoom-outlined.svg

var Zoomoutlined = function Zoomoutlined() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _react["default"].createElement("svg", _extends({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), _react["default"].createElement("path", {
    d: "M7.5 0a7.5 7.5 0 015.156 12.946l2.103 2.086a.5.5 0 01-.635.767l-.07-.057-2.173-2.154A7.5 7.5 0 117.5 0zm0 1a6.5 6.5 0 100 13 6.5 6.5 0 000-13zm0 4a.5.5 0 01.5.5V7h1.5a.5.5 0 010 1H8v1.5a.5.5 0 01-1 0V8H5.5a.5.5 0 010-1H7V5.5a.5.5 0 01.5-.5z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

result['zoom-outlined'] = Zoomoutlined;
var _default = result;
exports["default"] = _default;