"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

var SliderInnerComponent = function SliderInnerComponent(props) {
  var onBarClick = props.onBarClick,
      _props$classNameMap = props.classNameMap,
      classNameMap = _props$classNameMap === void 0 ? {} : _props$classNameMap;

  var htmlRef = props.htmlRef,
      SliderTrack = props.SliderTrack,
      sliderTrackProps = props.sliderTrackProps,
      otherProps = _objectWithoutProperties(props, ["htmlRef", "SliderTrack", "sliderTrackProps"]);

  return _react["default"].createElement("div", {
    className: classNameMap.SliderInnerClassName,
    ref: htmlRef
  }, _react["default"].createElement("div", {
    className: classNameMap.SliderRailClassName,
    onMouseDown: onBarClick
  }), typeof SliderTrack !== 'undefined' ? _react["default"].createElement(SliderTrack, _extends({}, otherProps, sliderTrackProps, {
    classNameMap: classNameMap,
    leftPopOver: null,
    rightPopOver: null,
    onChange: null
  })) : null);
};

var _default = SliderInnerComponent;
exports["default"] = _default;