"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2022, a Tencent company. All rights reserved.
 */
var ArrowComponent = function ArrowComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var className = props.className,
      onClick = props.onClick;
  return _react["default"].createElement("i", {
    className: className,
    onClick: onClick
  });
};

var _default = ArrowComponent;
exports["default"] = _default;